import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppState } from 'src/app/App.state';
import { AnaliseDeRiscoCliente } from 'src/app/base/domain/analise-de-risco-cliente.model';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { AnaliseDeRiscoClienteService } from 'src/app/base/services/analise-de-risco-cliente.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import {
  AnaliseDeRiscoAcaoEvent,
  FilterOptions,
} from 'src/app/global/lists/analise-de-risco-cliente-list/analise-de-risco-cliente-list.component';
import { DialogService } from 'src/app/global/services/dialog.service';

@Component({
  selector: 'app-analise-de-risco-cliente-main',
  templateUrl: './analise-de-risco-cliente-main.component.html',
  styleUrls: ['./analise-de-risco-cliente-main.component.css'],
})
export class AnaliseDeRiscoClienteMainComponent implements OnInit {
  arquivos: AnaliseDeRiscoCliente[] = [];
  queryOptions = new QueryOptions({ pageSize: 25 });
  totalRecords = 0;
  loading = false;
  id: number;
  action: string = '';
  options: string[] = ['atualizar', 'filtros', 'save', 'select', 'legenda'];

  private postoSelecionado: number = null;

  constructor(
    public dialogService: DialogService,
    private service: AnaliseDeRiscoClienteService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    public appState: AppState,
    private router: Router
  ) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params.id;
    });

    if (this.activatedRoute.snapshot.queryParamMap.has('action')) {
      this.action = this.activatedRoute.snapshot.queryParamMap.get('action');
      if (this.action === 'update') {
        this.options = ['save'];
      }
    }
  }

  ngOnInit(): void {
    if (this.action === 'update') {
      this.queryOptions.params = {
        arquivoId: this.id,
      };
      this.listAnalises();
    }
  }

  listAnalises(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.queryOptions.params = { ...this.activatedRoute.snapshot.queryParams };
    this.service.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<AnaliseDeRiscoCliente>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.arquivos = response.object.content;
        this.mapearAcoes(response.object.content);
      } else {
        this.arquivos = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
    });
  }

  list(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.queryOptions.params = { ...this.queryOptions.params };
    if (this.action === 'update') {
      this.listAnalises();
    } else {
      this.service.findAllPageableInCliente(this.queryOptions).subscribe((response: ReturnAPI<Page<AnaliseDeRiscoCliente>>) => {
        if (response.success) {
          this.totalRecords = response.object.totalElements;
          this.arquivos = response.object.content;
          this.mapearAcoes(response.object.content);
        } else {
          this.arquivos = [];
          this.dialogService.feedbackReturnAPI(response, '');
        }
        this.loading = false;
      });
    }
  }

  mapearAcoes(arquivos: AnaliseDeRiscoCliente[]): void {
    const mapearAcoes = (arquivo: AnaliseDeRiscoCliente): AnaliseDeRiscoCliente => {
      const acoes = [];
      if (this.action === 'update') {
        arquivo.selecionado = true;
      }
      return { ...arquivo, acoes };
    };

    this.arquivos = arquivos?.map(mapearAcoes) ?? [];
  }

  onAcaoClick(event: AnaliseDeRiscoAcaoEvent): void {
    const actions = new Map<string, (arquivo: AnaliseDeRiscoCliente) => void>();

    // actions.get(event.acao)(event.);
  }

  onLoadPage(page: number): void {
    this.list(page);
  }

  onFilter(options: FilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    this.queryOptions.params = {
      postoId: postoId,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
      valorInicial: options.valorInicial ? options.valorInicial : null,
      valorFinal: options.valorFinal ? options.valorFinal : null,
    };
    this.router.navigate(['/base/clientes/analise-de-risco'], {queryParams: this.queryOptions.params}).then(() => this.listAnalises());
    this.list();
  }
}
