import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Posto } from 'src/app/base/domain/posto.model';
import { PendenciaFechamentoCaixa } from 'src/app/base/domain/pendencia-fechamento-caixa.model';
import { LazyLoadEvent } from 'primeng/api';
import { SituacaoPendenciaFechamentoCaixa } from 'src/app/base/domain/enum/situacao-pendencia-fechamento.enum';

export interface PendenciaFechamentoCaixaAcaoEvent {
  acao: string;
  pendenciaFechamentoCaixa: PendenciaFechamentoCaixa;
}
export interface PendenciaFechamentoCaixaListFilterOptions {
  postoSelecionado: Posto;
  situacao: SituacaoPendenciaFechamentoCaixa[];
  dataInicial: Date;
  dataFinal: Date;
}

@Component({
  selector: 'app-pendencia-fechamento-list',
  templateUrl: './pendencia-fechamento-list.component.html',
  styleUrls: ['./pendencia-fechamento-list.component.css'],
})
export class PendenciaFechamentoListComponent implements OnInit {
  @Input() title: string;
  @Input() pendenciasFechamentoCaixa: PendenciaFechamentoCaixa[];
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() loading: boolean;
  @Input() filtros: string[];
  @Input() options: string[];

  @Output() loadPendenciasFechamentoCaixaPage = new EventEmitter<number>();
  @Output() filter = new EventEmitter<PendenciaFechamentoCaixaListFilterOptions>();
  @Output() acaoClick = new EventEmitter<PendenciaFechamentoCaixaAcaoEvent>();

  private currentPage = 0;

  situacoes: SituacaoPendenciaFechamentoCaixa[] = Object.values(SituacaoPendenciaFechamentoCaixa);

  private readonly defaultFilterOptions: PendenciaFechamentoCaixaListFilterOptions = {
    postoSelecionado: null,
    situacao: [],
    dataInicial: null,
    dataFinal: null,
  };

  filterOptions: PendenciaFechamentoCaixaListFilterOptions;

  constructor() {
    this.onLimparFiltros();
  }

  ngOnInit(): void {}

  loadPendenciasFechamentoCaixa(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadPendenciasFechamentoCaixaPage.emit(pageNumber);
  }

  onAcaoClick(acao: string, domain: PendenciaFechamentoCaixa): void {
    this.acaoClick.emit({ acao, pendenciaFechamentoCaixa: domain });
  }

  onAtualizar(): void {
    this.loadPendenciasFechamentoCaixaPage.emit(this.currentPage);
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }
}
