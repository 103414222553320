import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContratoDTO } from 'src/app/base/domain/dto/contrato.dto';
import { Contrato } from 'src/app/base/domain/contrato.model';
import { AuditoriaContrato } from 'src/app/base/domain/auditoria-contrato.model';

interface DataType {
  contrato: Contrato;
  action: String;
}

@Component({
  selector: 'app-cancelar-contrato-form',
  templateUrl: './cancelar-contrato-form.component.html',
  styleUrls: ['./cancelar-contrato-form.component.css'],
})
export class CancelarContratoFormComponent implements OnInit {
  auditoria = new AuditoriaContrato();
  contrato: Contrato;
  title: String;
  cancelarSemRetornoSaldo = false;

  constructor(public dialogRef: MatDialogRef<CancelarContratoFormComponent>, @Inject(MAT_DIALOG_DATA) public data: DataType) {
    this.auditoria.idContrato = data.contrato.id;
    this.auditoria.uidContrato = data.contrato.uid;
    this.contrato = data.contrato;

    if (data.action === 'CANCELAR_CONTRATO') {
      this.title = 'Parecer do cancelamento';
    }

    if (data.action === 'DESISTIR_CONTRATO') {
      this.title = 'Parecer da Desistência';
    }

    if (data.action === 'DESISTIR_CONTRATO_SEM_RETORNO') {
      this.cancelarSemRetornoSaldo = true;
      this.auditoria.cancelarSemRetornoSaldo = true;
    }
  }

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(this.auditoria);
  }
}
