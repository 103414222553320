import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Resource } from '../domain/resource.model';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

import { QueryOptions } from '../domain/query.options';
import { Serializer } from '../serializables/serializer.model';

export class ResourceService<T extends Resource> {


  constructor(
    protected httpClient: HttpClient,
    protected url: string,
    protected endpoint: string,
    protected serializer: Serializer,
  ) {
  }

  public create(item: T): Observable<T> {
    return (
      this.httpClient
        .post<T>(`${this.url}/${this.endpoint}`, this.serializer.toJson(item))
        // tslint:disable-next-line: typedef
        .map((data) => this.serializer.fromJson(data) as T)
        .catch(this.handleError)
    );
  }

  public update(item: T): Observable<T> {
    return (
      this.httpClient
        .put<T>(`${this.url}/${this.endpoint}/${item.id}`, this.serializer.toJson(item))
        // tslint:disable-next-line: typedef
        .map((data) => this.serializer.fromJson(data) as T)
        .catch(this.handleError)
    );
  }

  public createOrUpdate(item: T): Observable<T> {
    if (!item.id) {
      return this.create(item);
    } else {
      return this.update(item);
    }
  }

  public read(id: number): Observable<T> {
    return (
      this.httpClient
        .get(`${this.url}/${this.endpoint}/${id}`)
        // tslint:disable-next-line: no-any
        .map((data: any) => this.serializer.fromJson(data) as T)
    );
  }

  public list(queryOptions: QueryOptions): Observable<T[]> {
    return (
      this.httpClient
        .get(`${this.url}/${this.endpoint}?${queryOptions.toQueryString()}`)
        // tslint:disable-next-line: no-any
        .map((data: any) => this.convertData(data))
    );
  }

  // tslint:disable-next-line: no-any
  public listPageable(queryOptions: QueryOptions): Observable<any> {
    return this.httpClient.get(`${this.url}/${this.endpoint}/pageable?${queryOptions.toQueryString()}`);
  }

  public delete(item: T): Observable<T> {
    return this.httpClient.delete<T>(`${this.url}/${this.endpoint}/${item.id}`);
  }

  public consulta(queryOptions: QueryOptions): Observable<T[]> {
    return (
      this.httpClient
        .get(`${this.url}/${this.endpoint}/consulta?${queryOptions.toQueryString()}`)
        // tslint:disable-next-line: no-any
        .map((data: any) => {
          return this.convertData(data.content);
        })
    );
  }

  public search(term: string): Observable<T[]> {
    return (
      this.httpClient
        .get(`${this.url}/${this.endpoint}/consulta?${term}`)
        // tslint:disable-next-line: no-any
        .map((data: any) => {
          return this.convertData(data);
        })
    );
  }

  // tslint:disable-next-line: no-any
  public convertData(data: any): T[] {
    // tslint:disable-next-line: no-any
    return data.map((item: any) => this.serializer.fromJson(item));
  }

  // tslint:disable-next-line: no-any
  private handleError(error: Response | any): any {
    console.error(error.message || error);
    // tslint:disable-next-line: deprecation
    return Observable.throw(error.status);
  }
}
