import { Component } from '@angular/core';
import {
  TransferenciaAcaoEvent,
  TransferenciaListFilterOptions,
} from 'src/app/global/lists/transferencia-list/transferencia-list.component';
import { TransferenciaService } from '../../../services/transferencia.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ReturnAPI, Page } from '../../../domain/return-api.model';
import { Transferencia } from '../../../domain/transferencia.model';
import { Router } from '@angular/router';
import { ValoresTransferenciaRequestDTO } from 'src/app/base/domain/dto/valores-transferencia-request.dto';

@Component({
  selector: 'app-acompanhamento-sangria',
  templateUrl: './acompanhamento-sangria.component.html',
  styleUrls: ['./acompanhamento-sangria.component.css'],
})
export class AcompanhamentoSangriaComponent {
  sangrias: Transferencia[] = [];

  queryOptions = new QueryOptions({ pageSize: 10, pageSort: '-id', params: { type: 'tcosa' } });

  loading = false;
  totalRecords = 0;

  totalizador = 0;

  private postoSelecionado: number = null;
  private dataInicialSelecionada: Date = null;
  private dataFinalSelecionada: Date = null;

  constructor(private router: Router, private transferenciaService: TransferenciaService) {}

  mapearAcoesDeSangrias(transferencias: Transferencia[]): void {
    const mapearAcoes = (transferencia: Transferencia): Transferencia => {
      const acoes = [];
      acoes.push('Detalhes');
      return { ...transferencia, acoes };
    };

    this.sangrias = transferencias?.map(mapearAcoes) ?? [];
  }

  listarSangrias(page: number = 0, valores: Partial<ValoresTransferenciaRequestDTO> = {}): void {
    this.queryOptions.pageNumber = page + 1;
    this.loading = true;
    this.transferenciaService.pageTransferencias(this.queryOptions).subscribe((response: ReturnAPI<Page<Transferencia>>) => {
      if (response.success) {
        this.mapearAcoesDeSangrias(response.object.content);
        this.totalRecords = response.object.totalElements;
        this.loading = false;
        valores.tipo = 'TCOSA';
        valores.dataInicial = valores.dataInicial ? valores.dataInicial : null;
        valores.dataFinal = valores.dataFinal ? valores.dataFinal : null;
        valores.nome = valores.nome ? valores.nome : '';
        valores.descricaoRota = valores.descricaoRota ? valores.descricaoRota : '';
        this.getValorTotalPorTipoTransferencia({ ...valores, postoId: this.postoSelecionado });
      }
    });
  }

  onLoadSangrias(pageNumber: number): void {
    this.listarSangrias(pageNumber);
  }

  onAcaoClick(event: TransferenciaAcaoEvent): void {
    this.router.navigate([`/base/gestao/acompanhamento-sangria/${event.transferencia.id}`]);
  }

  onFilter(options: TransferenciaListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    const dataInicial = options.dataInicial || null;
    const dataFinal = options.dataFinal || null;

    this.queryOptions.params = {
      ...this.queryOptions.params,
      nomeFuncionario: options.nome || null,
      banco: options.banco || null,
      conta: options.conta || null,
      agencia: options.agencia || null,
      situacao: options.situacoesTransferencia?.length ? `in:${options.situacoesTransferencia.join(',')}` : null,
      dataInicio: dataInicial?.toLocaleString(),
      dataFinal: dataFinal?.toLocaleString(),
      postoId,
      rota: options.descricaoRota || null,
    };
    if (dataInicial !== this.dataInicialSelecionada || dataFinal !== this.dataFinalSelecionada) {
      this.dataInicialSelecionada = dataInicial;
      this.dataFinalSelecionada = dataFinal;
    }

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }

    this.listarSangrias(0, {
      nome: options.nome,
      descricaoRota: options.descricaoRota,
      dataInicial: options?.dataInicial,
      dataFinal: options?.dataFinal,
    });
  }

  getValorTotalPorTipoTransferencia(valores: Partial<ValoresTransferenciaRequestDTO>): void {
    this.transferenciaService.getValorTotalPorTipoTransferencia(valores).subscribe((response: ReturnAPI<number>) => {
      this.totalizador = response.object || 0;
    });
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
