import { Component, OnInit, Input } from '@angular/core';
import { Transferencia } from 'src/app/base/domain/transferencia.model';
import { TransferenciaAcaoEvent } from 'src/app/global/lists/transferencia-list/transferencia-list.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConfirmacaoDialogComponent } from 'src/app/global/components/confirmacao-dialog/confirmacao-dialog.component';
import { Params, ActivatedRoute } from '@angular/router';
import { CaixaService } from 'src/app/base/services/caixa.service';
import { DialogService } from 'src/app/global/services/dialog.service';
import { TransferenciaService } from 'src/app/base/services/transferencia.service';
import { Caixa } from 'src/app/base/domain/caixa.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { AuditoriaClienteDTO } from 'src/app/base/domain/dto/auditoria-cliente.dto';
import { AuditoriaDespesaDTO } from 'src/app/base/domain/dto/auditoria-despesa.dto';
import { CategoriaDespesa } from 'src/app/base/domain/enum/categoria-despesa.enum';
import { TipoDespesa } from 'src/app/base/domain/enum/tipo-despesa.enum';

@Component({
  selector: 'app-caixa-despesa-funcionario-movimentacoes',
  templateUrl: './caixa-despesa-funcionario-movimentacoes.component.html',
  styleUrls: ['./caixa-despesa-funcionario-movimentacoes.component.css'],
})
export class CaixaDespesaFuncionarioMovimentacoesComponent implements OnInit {
  private queryOptions = new QueryOptions({ pageSize: 10 });

  caixaId: number;
  transferencias: Transferencia[];
  tipoMovimentacoes: 'entradas' | 'saidas' = 'entradas';
  totalRecords: number;
  loading = false;
  caixa: Caixa;
  optionalColumns = ['descricao', 'categoria', 'situacao'];

  constructor(
    private route: ActivatedRoute,
    private transferenciaService: TransferenciaService,
    public dialog: MatDialog,
    public dialogService: DialogService,
    private caixaService: CaixaService,
  ) {
    this.route.params.subscribe((params: Params) => {
      this.caixaId = params.id;
    });
  }

  ngOnInit(): void {
    this.listarTransferencias();
    this.caixaService.findById(this.caixaId).subscribe((response: ReturnAPI<Caixa>) => {
      this.caixa = response.object;
    });
  }

  onLoadTransferencias(event: number): void {
    this.listarTransferencias(event);
  }

  mapearAcoesDeTransferencias(transferencias: Transferencia[]): void {
    const mapearAcoes = (transferencia: Transferencia): Transferencia => {
      const acoes = [];
      return { ...transferencia, acoes };
    };

    this.transferencias = transferencias?.map(mapearAcoes) ?? [];
  }

  listarTransferencias(page: number = 0): void {
    this.queryOptions.pageNumber = page + 1;
    this.loading = true;

    if (this.tipoMovimentacoes === 'entradas') {
      this.transferenciaService
        .pageTransferenciaByDestinoId(this.queryOptions, this.caixaId)
        .subscribe((response: ReturnAPI<Page<Transferencia>>) => {
          this.totalRecords = response.object.totalElements;
          this.mapearAcoesDeTransferencias(response.object.content);
          this.loading = false;
        });
    } else if (this.tipoMovimentacoes === 'saidas') {
      this.transferenciaService
        .pageTransferenciaByOrigemId(this.queryOptions, this.caixaId)
        .subscribe((response: ReturnAPI<Page<Transferencia>>) => {
          this.totalRecords = response.object.totalElements;
          this.mapearAcoesDeTransferencias(response.object.content);
          this.loading = false;
        });
    }
  }

  confimarTransferencia(transferencia: Transferencia): void {
    const dialogRef = this.dialog.open(ConfirmacaoDialogComponent, {
      width: '300px',
    });
  }

  onChangeTabView(id: number): void {
    this.tipoMovimentacoes = id === 0 ? 'entradas' : 'saidas';
    this.listarTransferencias();
  }

  onAcaoClick(event: TransferenciaAcaoEvent): void {
    const actions = new Map<string, (transferencia: Transferencia) => void>();

    actions.get(event.acao)(event.transferencia);
  }
}
