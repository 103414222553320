import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { Posto } from 'src/app/base/domain/posto.model';
import { PostoService } from 'src/app/base/services/posto.service';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { Funcionario } from 'src/app/base/domain/funcionario.model';
import { QueryOptions } from '../../domain/query.options';
import { FuncionarioService } from 'src/app/base/services/funcionario.service';
import { Contrato } from 'src/app/base/domain/contrato.model';
import { HttpErrorResponse } from '@angular/common/http';

export interface TransferirResponsavelFormData {
  title: string;
  infoText?: string;
  checkboxText?: string;
  noCheckbox?: boolean;
  dataPicker?: boolean;
  checked?: boolean;
  inputPlaceholder?: string;
  inputPlaceholderPosto?: string;
  postoDestino?: Posto;
  selectableOptions: Funcionario[];
  contratos?: Contrato[];
  onAsyncSelectableFilter?: (value: string) => Promise<Funcionario[]>;
}

export interface TransferirContrato {
  contrato: number[];
  responsavelAtual: number;
  responsavelNovo: number;
  todasRotas: boolean;
  inativosIncluded: boolean;
}

@Component({
  selector: 'app-transferir-responsavel-form',
  templateUrl: './transferir-responsavel-form.component.html',
  styleUrls: ['./transferir-responsavel-form.component.css'],
})
export class TransferirResponsavelFormComponent {
  selectableFilteredOptions: Funcionario[] = [];
  selectableInputOrigem = '';
  selectableInputDestino = '';
  selectableInputPosto = '';

  optionSelectedOrigem?: Funcionario;
  optionSelectedDestino?: Funcionario;
  optionSelectedPosto?: Posto;
  checked?: boolean;
  dataSelecionada?: Date;
  postos: Posto[];
  inativosIncluded = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TransferirResponsavelFormData,
    public dialogRef: MatDialogRef<TransferirResponsavelFormComponent>,
    private postoService: PostoService,
    private funcionarioService: FuncionarioService,
  ) {
    this.checked = this.checked || false;
    this.postoService.listarPostos().subscribe((response: ReturnAPI<Posto[]>) => {
      if (response.success) {
        this.postos = response.object;
      }
    });

    if (this.data.contratos[0] && this.data.contratos[0].posto) {
      this.optionSelectedPosto = this.data.contratos[0].posto;
      const postoId = this.data.contratos[0].posto.id;
      const options = new QueryOptions({ pageSize: 10, pageNumber: 1, params: { nomeFuncionario: name, postoId } });
      funcionarioService.findAll(options).subscribe((response: ReturnAPI<Page<Funcionario>>) => {
        if (response.success) {
          this.selectableFilteredOptions = response.object.content;
        }
      });
    }
  }

  private transferirContrato: TransferirContrato = {
    contrato: [],
    responsavelAtual: null,
    responsavelNovo: null,
    todasRotas: false,
    inativosIncluded: false,
  };

  async doFilterOrigem(): Promise<void> {
    this.selectableFilteredOptions = await this._filtrar(this.selectableInputOrigem);
  }

  async doFilterDestino(): Promise<void> {
    this.selectableFilteredOptions = await this._filtrar(this.selectableInputDestino);
  }

  async doFilterPosto(): Promise<void> {
    this.postos = await this._filtrarPosto(this.selectableInputPosto);
  }

  onSelectOrigem(value: Funcionario): void {
    this.optionSelectedOrigem = value;
    this.selectableInputOrigem = value.rota?.descricao + ' ' + value.pessoa.nomePessoa;
  }

  onSelectDestino(value: Funcionario): void {
    this.optionSelectedDestino = value;
    this.selectableInputDestino = value.rota?.descricao + ' ' + value.pessoa.nomePessoa;
  }

  onSelectPosto(value: Posto): void {
    this.optionSelectedPosto = value;
    this.selectableInputPosto = value.descricao;
    const postoId = this.optionSelectedPosto.id;
    const options = new QueryOptions({ pageSize: 10, pageNumber: 1, params: { nomeFuncionario: name, postoId } });
    this.funcionarioService.findAll(options).subscribe((response: ReturnAPI<Page<Funcionario>>) => {
      if (response.success) {
        this.selectableFilteredOptions = response.object.content;
      }
    });
  }

  onPickerEvent(event: MatDatepickerInputEvent<Moment>): void {
    this.dataSelecionada = event.value?.toDate() || null;
  }

  onConfirm(): void {
    if (this.checked) {
      this.transferirContrato.contrato = [];
      this.transferirContrato.responsavelAtual = this.optionSelectedOrigem ? this.optionSelectedOrigem.id : null;
      this.transferirContrato.responsavelNovo = this.optionSelectedDestino ? this.optionSelectedDestino.id : null;
      this.transferirContrato.todasRotas = this.checked;
      this.transferirContrato.inativosIncluded = this.inativosIncluded;
    } else {
      this.transferirContrato.contrato = this.data.contratos.map((contrato: Contrato) => contrato.id);
      this.transferirContrato.responsavelAtual = this.data.contratos[0] ? this.data.contratos[0].responsavel.id : null;
      this.transferirContrato.responsavelNovo = this.optionSelectedDestino ? this.optionSelectedDestino.id : null;
      this.transferirContrato.todasRotas = this.checked;
    }

    this.dialogRef.close(Object.assign(this.transferirContrato));
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  private async _filtrar(value: string): Promise<Funcionario[]> {
    return this.consultaFuncionarios(value, this.optionSelectedPosto.id);
  }

  private async _filtrarPosto(value: string): Promise<Posto[]> {
    const filterValue = value?.toLowerCase();
    return this.postos.filter((e: Posto) => e.descricao.toLowerCase().indexOf(filterValue) === 0);
  }

  async consultaFuncionarios(name: string, postoId: number): Promise<Funcionario[]> {
    const options = new QueryOptions({ pageSize: 10, pageNumber: 1, params: { nomeFuncionario: name, postoId } });
    const response = await this.funcionarioService.findAll(options).toPromise();
    return response.success ? response.object.content : [];
  }
}
