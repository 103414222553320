import { Component, OnInit } from '@angular/core';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { Captado } from '../../../domain/captado.model';
import { DialogService } from 'src/app/global/services/dialog.service';
import { CaptadoService } from '../../../services/captado.service';
import { Page, ReturnAPI } from '../../../domain/return-api.model';
import { Posto } from '../../../domain/posto.model';
import { CaptadoListFilterOptions } from 'src/app/global/lists/captado-list/captado-list.component';
import { AmostraCaptadosVisitadosAnoDTO } from '../../../domain/dto/amostra-captados-visitados-ano.dto';
import { QuantidadeCaptadosVisitadosDTO } from '../../../domain/dto/quantidade-captados-visitados.dto';

@Component({
  selector: 'app-visitacao-captacao-main',
  templateUrl: './visitacao-captacao-main.component.html',
  styleUrls: ['./visitacao-captacao-main.component.css'],
})
export class VisitacaoCaptacaoMainComponent implements OnInit {
  captados: Captado[] = [];
  view = 'VISITADO_CAPTADO';

  labels: string[] = [];
  series: number[][] = [];
  quantidadeVisitados = 0;
  quantidadeCaptados = 0;

  counterDescription = 'Todos as Datas';
  counterSubDescription = 'Todos os Postos';
  queryOptions = new QueryOptions({ pageSize: 10 });
  totalRecords = 0;
  loading = false;

  private postoSelecionado: number = null;

  constructor(private captadoService: CaptadoService, public dialogService: DialogService) {}

  ngOnInit(): void {
    this.amostrarCaptadosVisitados();
    this.consultarCaptadosVisitadosPeriodoAgrupadosPorSituacao();
  }

  listarClientesCaptados(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.captadoService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Captado>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.captados = response.object.content;
      } else {
        this.captados = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
    });
  }

  amostrarCaptadosVisitados(postoID?: number): void {
    this.captadoService.amostrarCaptadosDoAno(postoID).subscribe((response: ReturnAPI<AmostraCaptadosVisitadosAnoDTO>) => {
      if (response.success) {
        this.labels = response.object.meses;
        this.series = [response.object.captados, response.object.visitados];
      }
    });
  }

  consultarCaptadosVisitadosPeriodoAgrupadosPorSituacao(dataInicial?: Date, dataFinal?: Date, posto?: Posto): void {
    const options = new QueryOptions({
      params: {
        dataInicio: dataInicial?.toLocaleString(),
        dataFinal: dataFinal?.toLocaleString(),
        postoId: posto?.id,
      },
    });

    this.captadoService.captadoPeriodoAgrupadosPorSituacao(options).subscribe((response: ReturnAPI<QuantidadeCaptadosVisitadosDTO>) => {
      if (response.success) {
        this.quantidadeVisitados = response.object.quantidadeVisitados;
        this.quantidadeCaptados = response.object.quantidadeCaptados;
        this.updateCounterDescription(dataInicial, dataFinal, posto);
      }
    });
  }

  private updateCounterDescription(dataInicial?: Date, dataFinal?: Date, posto?: Posto): void {
    let description = '';
    if (dataInicial && dataFinal) {
      description += `Entre ${dataInicial.toLocaleDateString()} e ${dataFinal.toLocaleDateString()}`;
    } else if (dataInicial) {
      description += `A partir de ${dataInicial.toLocaleDateString()}`;
    } else if (dataFinal) {
      description += `Até ${dataFinal.toLocaleDateString()}`;
    }
    this.counterDescription = description || 'Todos as Datas';
    this.counterSubDescription = `${posto ? posto.descricao : 'Todos os Postos'}`;
  }

  loadCaptadosPage(page: number): void {
    this.listarClientesCaptados(page);
    this.amostrarCaptadosVisitados();
    this.consultarCaptadosVisitadosPeriodoAgrupadosPorSituacao();
  }

  onFilter(options: CaptadoListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;

    this.queryOptions.params = {
      nomeCliente: options.nomeCaptado ? `like:${options.nomeCaptado}` : null,
      nomeCaptador: options.nomeCaptador ? `${options.nomeCaptador}` : null,
      situacoesCaptado: options.situacoesCaptado?.length ? `in:${options.situacoesCaptado.join(',')}` : null,
      dataInicio: options?.dataInicial?.toLocaleString(),
      dataFinal: options?.dataFinal?.toLocaleString(),
      postoId,
    };

    this.listarClientesCaptados();
    if (postoId !== this.postoSelecionado) {
      this.amostrarCaptadosVisitados(postoId);
      this.consultarCaptadosVisitadosPeriodoAgrupadosPorSituacao(options.dataInicial, options.dataFinal, options.postoSelecionado);
      this.postoSelecionado = postoId;
    }

    if (options?.dataInicial || options?.dataFinal || postoId !== this.postoSelecionado) {
      this.consultarCaptadosVisitadosPeriodoAgrupadosPorSituacao(options.dataInicial, options.dataFinal, options.postoSelecionado);
    }
  }
}
