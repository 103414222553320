import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GlobalModule } from 'src/app/global/global.module';
import { AcompanhamentoCaptacaoMainComponent } from './acompanhamento-captacao-main/acompanhamento-captacao-main.component';

@NgModule({
  declarations: [AcompanhamentoCaptacaoMainComponent],
  imports: [CommonModule, GlobalModule],
})
export class AcompanhamentoCaptacaoModule {}
