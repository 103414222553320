import { Resource } from 'src/app/global/domain/resource.model';
import { Produto } from './produto.model';
import { Estado } from './estado.model';

export class Usuario extends Resource {
  public nome: string;
  public email: string;
  public password: string;
  public profile: string;
  public version = 0;
  public estados: Estado[];
  public cpf: string;
  constructor() {
    super();
  }
}
