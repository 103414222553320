export class ReturnAPI<T> {
  success: boolean;
  messages: MessageAPI[];
  object: T;
}

export class MessageAPI {
  text: string;
}

export class Page<T> {
  content: T[];
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
}
