import { Component, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef } from '@angular/material/dialog';
import { Moment } from 'moment';
import { AppState } from 'src/app/App.state';
import { ParcelaService } from 'src/app/base/services/parcela.service';

@Component({
  selector: 'app-corrigir-pagamento-dia-form',
  templateUrl: './corrigir-pagamento-dia-form.component.html',
  styleUrls: ['./corrigir-pagamento-dia-form.component.css'],
})
export class CorrigirPagamentoDiaFormComponent implements OnInit {
  dataProcessamento = new Date();
  dataParcela = new Date();

  constructor(
    private conciliacaoBancariaService: ParcelaService,
    private appState: AppState,
    private dialogRef: MatDialogRef<CorrigirPagamentoDiaFormComponent>,
  ) {}

  ngOnInit(): void {}

  public onConfirm() {
    this.appState.isLoading = true;
    this.conciliacaoBancariaService.corrigirPagamentoGeral(this.dataProcessamento).subscribe(
      (_) => {
        this.appState.isLoading = false;
        this.dialogRef.close(true);
      },
      (_) => {
        this.appState.isLoading = false;
        this.dialogRef.close(false);
      },
    );
  }

  onPickerEvent(event: MatDatepickerInputEvent<Moment>): void {
    this.dataProcessamento = event.value?.toDate() || null;
  }

  onCancel() {
    this.appState.isLoading = false;
    this.dialogRef.close(false);
  }
}
