import { Input, Component } from '@angular/core';
import { ArquivoContrato } from 'src/app/base/domain/arquivo-contrato.model';
import { WEB_API } from '../../services/web-api';

@Component({
  selector: 'app-carousel-arquivos-contrato',
  templateUrl: './carousel-arquivos-contrato.component.html',
  styleUrls: ['./carousel-arquivos-contrato.component.css'],
})
export class CarouselArquivosContratoComponent {
  private static STORAGE_URL = `${WEB_API}/storage/sgf/contrato`;

  @Input() arquivos: ArquivoContrato[];

  get _arquivos(): { head: ArquivoContrato; tail: ArquivoContrato[] } {
    const [head, ...tail] =
      this.arquivos.map((arq: ArquivoContrato) => {
        arq.filePath = `${CarouselArquivosContratoComponent.STORAGE_URL}/${this.produtoId}/${arq.codigo}`;
        return arq;
      }) ?? [];
    return { head, tail };
  }

  get produtoId(): number {
    if (sessionStorage.getItem('produto')) {
      const produto = JSON.parse(sessionStorage.getItem('produto'));
      return parseInt(produto.id, 10);
    }
  }
}
