import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contrato } from 'src/app/base/domain/contrato.model';
import { AuditoriaContrato } from 'src/app/base/domain/auditoria-contrato.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { TimeScale } from 'chart.js';

interface DataType {
  contrato: Contrato;
  action: String;
}

@Component({
  selector: 'app-renegociar-contrato-form',
  templateUrl: './renegociar-contrato-form.component.html',
  styleUrls: ['./renegociar-contrato-form.component.css'],
})
export class RenegociarContratoFormComponent implements OnInit {
  auditoria = new AuditoriaContrato();
  contrato: Contrato;
  title: String;
  cancelarSemRetornoSaldo = false;
  valorRenegociado: String;
  valorJuros: number;
  novoValorContrato: number;

  constructor(public dialogRef: MatDialogRef<RenegociarContratoFormComponent>, @Inject(MAT_DIALOG_DATA) public data: DataType) {
    this.auditoria.idContrato = data.contrato.id;
    this.auditoria.uidContrato = data.contrato.uid;
    this.contrato = data.contrato;
    this.auditoria.quantidadeParcelas = data.contrato.visita.quantidadeParcelas;
    this.valorJuros = (this.contrato.saldoDevedor * 25) / 100;
    this.novoValorContrato = this.contrato.saldoDevedor + this.valorJuros;
    this.auditoria.valorFinal = this.novoValorContrato;
  }

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(this.auditoria);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  onPickerEvent(): void {
    this.auditoria.dataProrrogacao = this.auditoria.dataProrrogacao || null;
    this.auditoria.dataProrrogacao = new Date(this.auditoria.dataProrrogacao);
    const dias = this.calculateDiff(this.auditoria.dataProrrogacao);
    const valorPago = this.data.contrato.listParcela
      .filter((p) => p.ordem >= this.contrato.visita.quantidadeParcelas)
      .reduce((acc, par) => acc + par.valorPago, 0);
    (this.contrato.valorBruto - valorPago) * 0.0083;
    const valorJurosAMenos = dias * (this.contrato.valorBruto - valorPago) * 0.0083;
    console.log(valorJurosAMenos.toFixed(2));
    this.auditoria.valorFinal = (this.novoValorContrato - valorJurosAMenos);
    this.auditoria.valorAcordado = this.auditoria.valorFinal;
  }

  calculateDiff(data) {
    let date = new Date(data);
    let currentDate = new Date();

    let days = Math.floor((currentDate.getTime() - date.getTime()) / 1000 / 60 / 60 / 24);
    return days;
  }
}
