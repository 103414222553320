import { Component, OnInit, ViewChild } from '@angular/core';
import { AprovacoesDeCreditoPendentesDTO } from 'src/app/base/domain/dto/aprovacoes-de-credito-pendentes-dto';
import { Posto } from 'src/app/base/domain/posto.model';
import { ReturnAPI } from 'src/app/base/domain/return-api.model';
import { Visita } from 'src/app/base/domain/visita.model';
import { GaleryComponent } from 'src/app/global/components/galery/galery.component';
import { AprovacaoDeCreditoListComponent } from 'src/app/global/lists/aprovacao-de-credito-list/aprovacao-de-credito-list.component';

export interface QueryFilter {
  posto: Posto;
  nome: string;
  situacao: string;
  dataInicial: Date;
  dataFinal: Date;
}

@Component({
  selector: 'app-aprovacao-de-credito-main',
  templateUrl: './aprovacao-de-credito-main.component.html',
  styleUrls: ['./aprovacao-de-credito-main.component.css']
})
export class AprovacaoDeCreditoMainComponent implements OnInit {

  @ViewChild(AprovacaoDeCreditoListComponent) aprovacaoList: AprovacaoDeCreditoListComponent;
  @ViewChild(GaleryComponent) galery: GaleryComponent;

  idGallery: number;

  counterMetaData = {
    description: 'Hoje',
    subDescription: 'Todos os Postos',
  };

  labels: string[] = [];
  series: number[][] = [];

  showView = false;
  visita: Visita = new Visita();

  quantidadeAprovados = 0;
  quantidadeEmAnalise = 0;
  quantidadeRejeitados = 0;

  constructor() { }

  ngOnInit(): void { }

  configureLabels(event: QueryFilter): void {
    this.counterMetaData = {
      description: (event.dataInicial || event.dataFinal)
        ? `${event.dataInicial ? event.dataInicial.toLocaleDateString('pt-BR') : 'Hoje'}
        à ${event.dataFinal ? event.dataFinal.toLocaleDateString('pt-BR') : 'Hoje'}`
        : 'Hoje',
      subDescription: event.posto ? `Posto:${event.posto.id} - ${event.posto.descricao}` : 'Todos os Postos',
    };
  }

  configureStatistics(event: ReturnAPI<AprovacoesDeCreditoPendentesDTO[]>): void {
    this.quantidadeAprovados = event.object.map((p: AprovacoesDeCreditoPendentesDTO) => p.aprovados)
      .reduce((a: number, b: number) => a + b);
    this.quantidadeEmAnalise = event.object.map((p: AprovacoesDeCreditoPendentesDTO) => p.emAnalise)
      .reduce((a: number, b: number) => a + b);
    this.quantidadeRejeitados = event.object.map((p: AprovacoesDeCreditoPendentesDTO) => p.rejeitados)
      .reduce((a: number, b: number) => a + b);
    this.labels = event.object.map((p: AprovacoesDeCreditoPendentesDTO) => p.posto);
    this.series = [ event.object.map((p: AprovacoesDeCreditoPendentesDTO) => p.aprovados)
      , event.object.map((p: AprovacoesDeCreditoPendentesDTO) => p.rejeitados)
      , event.object.map((p: AprovacoesDeCreditoPendentesDTO) => p.emAnalise)
    ];
  }

  openEditAprovacao(event: Visita): void {
    this.showView = true;
    this.visita = event;
  }

  closeEditAprovacao(): void {
    this.showView = false;
    this.visita = new Visita();
  }

  openGalery(event: number): void {
    this.idGallery = event;
    this.galery.abrirGaleria();
  }

  reload(event: boolean): void {
    if (event) {
      this.aprovacaoList.load();
    }
  }

  rejeitarVisita(event: number): void {
    this.aprovacaoList.rejeitar(event);
    this.closeEditAprovacao();
  }
}
