import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PessoaService } from '../../services/pessoa.service';
import { Pessoa } from '../../domain/pessoa.model';
import { CardListComponent } from 'src/app/global/components/card-list/card-list.component';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { MessageService } from 'src/app/global/components/message/message.service';

@Component({
  selector: 'app-pessoa-list',
  templateUrl: './pessoa-list.component.html',
  styleUrls: ['./pessoa-list.component.css'],
})
export class PessoaListComponent implements OnInit {
  @ViewChild('searchForm', { static: false }) searchForm: NgForm;
  @ViewChild('dataTable', { static: false }) dataTable: Table;
  @ViewChild(CardListComponent, { static: true }) cardList: CardListComponent;
  first = 0;
  loading = false;
  totalRecords: number;
  pessoas: Pessoa[] = [];
  queryOptions = new QueryOptions();

  constructor(
    private pessoaService: PessoaService,
    private messageService: MessageService,
    protected router: Router,
    protected route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.list();
  }

  list(): void {
    this.loading = true;
    // tslint:disable-next-line: no-any
    this.pessoaService.list(this.queryOptions).subscribe((data: any) => {
      this.pessoas = data;
      this.totalRecords = this.pessoas.length;
      this.loading = false;
    });
  }

  buscar(form: NgForm): void {
    this.queryOptions = new QueryOptions();
    this.consultar(form);
  }

  consultar(form: NgForm): void {
    this.queryOptions.search = `nome=${form.value.name}`;
    // tslint:disable-next-line: no-any
    this.pessoaService.consulta(this.queryOptions).subscribe((data: any) => (this.pessoas = data));
  }

  limpar(): void {
    this.loading = true;
    this.searchForm.reset();
    this.dataTable.reset();
    this.list();
  }
  // tslint:disable-next-line: no-any
  remover(orgao: any): void {
    this.pessoaService.delete(orgao).subscribe(() => this.list());
    this.messageService.messageSuccess('Remoção realizada com sucesso');
  }
  // tslint:disable-next-line: no-any
  paginarList(event: any): void {
    this.loading = true;
    this.queryOptions.pageNumber = event.first / event.rows;
    this.queryOptions.pageSize = event.rows;
    if (this.cardList.isShowFiltros) {
      this.consultar(this.searchForm);
    } else {
      this.list();
    }
  }
}
