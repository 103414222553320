import { FuncionarioFormComponent } from './../../../../global/forms/funcionario-form/funcionario-form.component';
import { MatDialog } from '@angular/material/dialog';
import { FuncionarioMenuAcaoEvent } from './../../../../global/lists/funcionario-list/funcionario-list.component';
import { FuncionarioService } from 'src/app/base/services/funcionario.service';
import { Component } from '@angular/core';
import { Funcionario } from 'src/app/base/domain/funcionario.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { FuncionarioAcaoEvent, FuncionarioListFilterOptions } from 'src/app/global/lists/funcionario-list/funcionario-list.component';
import { DialogService } from 'src/app/global/services/dialog.service';
import { AppState } from 'src/app/App.state';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';

@Component({
  selector: 'app-operadores-externos-main',
  templateUrl: './operadores-externos-main.component.html',
  styleUrls: ['./operadores-externos-main.component.css'],
})
export class OperadoresExternosMainComponent {
  static NOVO_OPERADOR = 'Novo Operador';
  static DISCLOSURE = 'disclosure';

  operadores: Funcionario[] = [];

  menuAcoes = [OperadoresExternosMainComponent.NOVO_OPERADOR];

  queryOptions = new QueryOptions({ pageSize: 10 });
  totalRecords = 0;

  private postoSelecionado: number = null;

  constructor(
    private matDialog: MatDialog,
    private funcionarioService: FuncionarioService,
    private dialogService: DialogService,
    public appState: AppState,
  ) {}

  listarOperadoresFiscais(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.appState.isLoading = true;
    this.queryOptions.params = { ...this.queryOptions.params, cargosDescricao: 'OPERADOR EXTERNO' };
    this.funcionarioService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Funcionario>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.mapearAcoes(response.object.content);
      } else {
        this.operadores = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.appState.isLoading = false;
    });
  }

  mapearAcoes(funcionarios: Funcionario[]): void {
    const mapearAcoes = (funcionario: Funcionario): Funcionario => {
      const acoes = [];
      acoes.push(OperadoresExternosMainComponent.DISCLOSURE);
      return { ...funcionario, acoes };
    };

    this.operadores = funcionarios?.map(mapearAcoes) ?? [];
  }

  exibirFormularioParaNovoOperador() {
    const dialog = this.matDialog.open(FuncionarioFormComponent, {
      data: {
        titulo: 'Novo Operador',
        cargoFixo: 'OPERADOR EXTERNO',
      },
      width: '1000px',
    });
    dialog.afterClosed().subscribe((funcionario?: Funcionario) => {
      if (funcionario) {
        this.adicionarNovoOperadorExterno(funcionario);
      }
    });
  }

  adicionarNovoOperadorExterno(funcionario: Funcionario) {
    this.appState.isLoading = true;
    this.funcionarioService.insert(funcionario).subscribe(
      (response: ReturnAPI<Funcionario>) => {
        this.dialogService.feedbackReturnAPI(response, 'Novo Operador Externo cadastrado com sucesso');
        if (response.success) {
          this.listarOperadoresFiscais();
        }
        this.appState.isLoading = false;
      },
      () => {
        this.dialogService.feedbackError('Erro ao cadastrar novo operador externo');
        this.appState.isLoading = true;
      },
    );
  }

  onPage(page: number): void {
    this.listarOperadoresFiscais(page);
  }

  onAcaoClick(event: FuncionarioAcaoEvent): void {
    const actions = new Map<string, (funcionario: Funcionario) => void>();
    this.exibirFormularioParaEditarOperador(event.funcionario);
  }



  exibirFormularioParaEditarOperador(funcionario: Funcionario): void {
    const dialog = this.matDialog.open(FuncionarioFormComponent, {
      data: {
        titulo: 'Editar Operador',
        cargoFixo: 'OPERADOR EXTERNO',
        funcionario: funcionario,
      },
      width: '1000px',
    });
    dialog.afterClosed().subscribe((funcionario?: Funcionario) => {
      if (funcionario) {
        this.adicionarNovoOperadorExterno(funcionario);
      }
    });
  }



  onMenuAcaoClick(event: FuncionarioMenuAcaoEvent): void {
    const actions = new Map<string, () => void>().set(
      OperadoresExternosMainComponent.NOVO_OPERADOR,
      this.exibirFormularioParaNovoOperador.bind(this),
    );

    actions.get(event.acao)();
  }

  onDisclosure(funcionario: Funcionario): void {}

  onFilter(options: FuncionarioListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    this.queryOptions.params = {
      postoId,
      nomeFuncionario: options.nome ? `${options.nome}` : null,
    };
    this.listarOperadoresFiscais();
    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }
  }
}
