import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppState } from 'src/app/App.state';
import { SituacaoParcela } from 'src/app/base/domain/enum/situacao-parcela.enum';
import { ParcelaCobrancaJudicial } from 'src/app/base/domain/parcela-cobranca-judicial.model';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { ParcelaCobrancaJudicialService } from 'src/app/base/services/parcela-cobranca-judicial.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { BaixaParcelaCobrancaJudicialFormComponent } from 'src/app/global/forms/baixa-parcela-cobranca-judicial-form/baixa-parcela-cobranca-judicial-form.component';
import { CobrancaJudicialAcaoEvent } from 'src/app/global/lists/cobranca-judicial-list/cobranca-judicial-list.component';
import { ParcelaCobrancaJudicialAcaoEvent } from 'src/app/global/lists/parcela-cobranca-judicial-list/parcela-cobranca-judicial-list.component';
import { DialogService } from 'src/app/global/services/dialog.service';

@Component({
  selector: 'app-cobranca-judicial-detail',
  templateUrl: './cobranca-judicial-detail.component.html',
  styleUrls: ['./cobranca-judicial-detail.component.css']
})
export class CobrancaJudicialDetailComponent implements OnInit {

  private static BAIXA_PARCELA_ACTION = 'Dar Baixa Na Parcela';

  cobrancaId: number;
  parcela: ParcelaCobrancaJudicial;
  title = 'Parcelas Cobranca Judicial';

  parcelas: ParcelaCobrancaJudicial[] = [];

  queryOptions = new QueryOptions({ pageSize: 10 });
  totalRecords = 0;
  loading = false;

  constructor(
    private route: ActivatedRoute,
    private service: ParcelaCobrancaJudicialService,
    private dialogService: DialogService,
    public dialog: MatDialog,
    public appState: AppState,
  ) {
    this.route.params.subscribe((params: Params) => {
      this.cobrancaId = params.id;
    });

    this.queryOptions.params = {
      cobrancaJudicialId: this.cobrancaId || null,
    };
  }

  ngOnInit(): void { }

  listar(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.service.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<ParcelaCobrancaJudicial>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.parcelas = response.object.content;
        this.mapearAcoes(this.parcelas);
      } else {
        this.parcelas = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
    });
  }

  onAcaoClick(event: ParcelaCobrancaJudicialAcaoEvent): void {
    const actions = new Map<string, (parcela: ParcelaCobrancaJudicial) => void>()
      .set(CobrancaJudicialDetailComponent.BAIXA_PARCELA_ACTION, (parcela: ParcelaCobrancaJudicial) => {
        this.baixaParcela(parcela);
      });

    actions.get(event.acao)(event.parcelaCobranca);
  }

  baixaParcela(parcela: ParcelaCobrancaJudicial): void {
    const dialogRef = this.dialog.open(BaixaParcelaCobrancaJudicialFormComponent, {
      data: {
        parcela,
        action: 'novo'
      },
      width: '750px',
    });

    dialogRef.afterClosed().subscribe((parcela: ParcelaCobrancaJudicial) => {
      this.appState.isLoading = true;
      if (parcela) {
        this.service.baixaParcela(parcela).subscribe(
          (response: ReturnAPI<ParcelaCobrancaJudicial>) => {
            this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
            this.appState.isLoading = false;
            this.listar();
          },
          (e: HttpErrorResponse) => {
            this.appState.isLoading = false;
            this.dialogService.feedbackError(`Houve um erro ao dar baixa na parcela.\n ${e.message}`);
          },
        );
      } else {
        this.appState.isLoading = false;
      }
    });
  }

  mapearAcoes(parcelas: ParcelaCobrancaJudicial[]): void {
    const mapearAcoes = (parcela: ParcelaCobrancaJudicial): ParcelaCobrancaJudicial => {
      const acoes = [];
      if (parcela.situacao === SituacaoParcela.GERADA) {
        acoes.push(CobrancaJudicialDetailComponent.BAIXA_PARCELA_ACTION);
      }
      return { ...parcela, acoes };
    };

    this.parcelas = parcelas?.map(mapearAcoes) ?? [];
  }

  onLoadPage(page: number): void {
    this.listar(page);
  }

}
