import { Component, OnInit } from '@angular/core';
import { ReturnAPI, Page } from '../../../domain/return-api.model';
import { Transferencia } from '../../../domain/transferencia.model';
import { TransferenciaAcaoEvent } from 'src/app/global/lists/transferencia-list/transferencia-list.component';
import { Params, ActivatedRoute } from '@angular/router';
import { DialogService } from 'src/app/global/services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { TransferenciaService } from '../../../services/transferencia.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { CaixaService } from '../../../services/caixa.service';
import { Caixa } from '../../../domain/caixa.model';

@Component({
  selector: 'app-caixa-funcionario-movimentacoes',
  templateUrl: './caixa-funcionario-movimentacoes.component.html',
  styleUrls: ['./caixa-funcionario-movimentacoes.component.css'],
})
export class CaixaFuncionarioMovimentacoesComponent implements OnInit {
  private queryOptions = new QueryOptions({ pageSize: 10 });

  caixaId: number;
  transferencias: Transferencia[];
  tipoMovimentacoes: 'entradas' | 'saidas' = 'entradas';
  totalRecords: number;
  loading = false;
  caixa: Caixa;
  optionalColumns = ['descricao', 'natureza', 'cliente', 'origem', 'destino', 'situacao'];
  totalizadorCobranca = 0;
  totalizadorRepasse = 0;

  constructor(
    private route: ActivatedRoute,
    private transferenciaService: TransferenciaService,
    public dialog: MatDialog,
    public dialogService: DialogService,
    private caixaService: CaixaService,
  ) {
    this.route.params.subscribe((params: Params) => {
      this.caixaId = params.id;
    });
  }

  ngOnInit(): void {
    this.listarTransferencias();
    this.caixaService.findById(this.caixaId).subscribe((response: ReturnAPI<Caixa>) => {
      this.caixa = response.object;
    });
    this.transferenciaService.getTotalCobrancaByFuncionarioId(this.caixaId).subscribe((response: ReturnAPI<number>) => {
      this.totalizadorCobranca = response.object;
    });

    this.transferenciaService.getTotalRepasseByFuncionarioId(this.caixaId).subscribe((response: ReturnAPI<number>) => {
      this.totalizadorRepasse = response.object;
    });
  }

  onLoadTransferencias(event: number): void {
    this.listarTransferencias(event);
  }

  mapearAcoesDeTransferencias(transferencias: Transferencia[]): void {
    const mapearAcoes = (transferencia: Transferencia): Transferencia => {
      const acoes = [];
      return { ...transferencia, acoes };
    };

    this.transferencias = transferencias?.map(mapearAcoes) ?? [];
  }

  listarTransferencias(page: number = 0): void {
    this.queryOptions.pageNumber = page + 1;
    this.loading = true;

    if (this.tipoMovimentacoes === 'entradas') {
      this.transferenciaService
        .pageTransferenciaByDestinoId(this.queryOptions, this.caixaId)
        .subscribe((response: ReturnAPI<Page<Transferencia>>) => {
          this.totalRecords = response.object.totalElements;
          this.mapearAcoesDeTransferencias(response.object.content);
          this.loading = false;
        });
    } else if (this.tipoMovimentacoes === 'saidas') {
      this.transferenciaService
        .pageTransferenciaByOrigemId(this.queryOptions, this.caixaId)
        .subscribe((response: ReturnAPI<Page<Transferencia>>) => {
          this.totalRecords = response.object.totalElements;
          this.mapearAcoesDeTransferencias(response.object.content);
          this.loading = false;
        });
    }
  }

  onChangeTabView(id: number): void {
    this.tipoMovimentacoes = id === 0 ? 'entradas' : 'saidas';
    this.listarTransferencias();
  }

  onAcaoClick(event: TransferenciaAcaoEvent): void {
    const actions = new Map<string, (transferencia: Transferencia) => void>();

    actions.get(event.acao)(event.transferencia);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
