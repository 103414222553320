import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArquivoExtratoMainComponent } from './arquivo-extrato-main/arquivo-extrato-main.component';
import { GlobalModule } from 'src/app/global/global.module';
import { PaginatorModule, TableModule } from 'primeng';
import { ComponentsModule } from 'src/app/global/components/components.module';
import { ArquivoExtratoService } from '../services/arquivo-extrato.service';

@NgModule({
  declarations: [ArquivoExtratoMainComponent],
  imports: [CommonModule, GlobalModule, TableModule, PaginatorModule, ComponentsModule],
  providers: [ArquivoExtratoService],
})
export class ArquivoExtratoModule {}
