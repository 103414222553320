import { ParcelaComprovantePixDTO } from './../../../domain/dto/parcela-comprovante-pix.dto';
import { Component, OnInit } from '@angular/core';
import { AcompanhamentoComprovantesFilterOptions } from 'src/app/global/lists/acompanhamento-comprovantes-pix-list/acompanhamento-comprovantes-pix-list.component';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ArquivoComprovantePixService } from 'src/app/base/services/arquivo-comprovante-pix.service';
import { AppState } from 'src/app/App.state';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import * as FileSaver from 'file-saver';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { SharedService } from 'src/app/auth/shared.service';
import * as moment from 'moment';

@Component({
  selector: 'app-acompanhamento-comprovantes-pix-main',
  templateUrl: './acompanhamento-comprovantes-pix-main.component.html',
  styleUrls: ['./acompanhamento-comprovantes-pix-main.component.css'],
})
export class AcompanhamentoComprovantesPixMainComponent implements OnInit {
  private static DOWNLOAD_COMPROVANTE = 'Realizar Download do Comprovante';
  private static DELETAR_COMPROVANTE = 'Deletar Comprovante';

  queryOptions = new QueryOptions({ pageSize: 10, pageSort: '-acp.data_criacao' });
  comprovantes: ParcelaComprovantePixDTO[] = [];
  totalRecords = 0;
  usuario: Usuario;

  constructor(
    private arquivoComprovantePixService: ArquivoComprovantePixService,
    public appState: AppState,
    private sharedService: SharedService,
  ) {}

  ngOnInit(): void {
    const shared = SharedService.getInstance();
    this.usuario = shared.usuario;
  }

  mapearAcoes(comprovantes: ParcelaComprovantePixDTO[]): void {
    const mapearAcoes = (comprovante: ParcelaComprovantePixDTO): ParcelaComprovantePixDTO => {
      const acoes = [];
      if (this.usuario.profile === 'ROLE_ADMIN') {
        acoes.push(AcompanhamentoComprovantesPixMainComponent.DELETAR_COMPROVANTE);
      }
      acoes.push(AcompanhamentoComprovantesPixMainComponent.DOWNLOAD_COMPROVANTE);
      return { ...comprovante, acoes };
    };
    this.comprovantes = comprovantes?.map(mapearAcoes) ?? [];
  }

  onLoad(page: number = 0): void {
    this.queryOptions.pageNumber = page + 1;
    this.appState.isLoading = true;
    this.arquivoComprovantePixService.findDaoPageable(this.queryOptions).subscribe(
      (response: ReturnAPI<Page<ParcelaComprovantePixDTO>>) => {
        if (response.success) {
          this.mapearAcoes(response.object.content);
          this.totalRecords = response.object.totalElements;
        }
        this.appState.isLoading = false;
      },
      () => (this.appState.isLoading = false),
    );
  }

  onDownload(options: AcompanhamentoComprovantesFilterOptions): void {
    const posto = options.posto ? options.posto.id : null;
    const rota = options.rota ? options.rota.descricao : null;

    this.queryOptions.params = {
      ...this.queryOptions.params,
      posto,
      rota,
      dataInicio: options.dataInicial ? options.dataInicial.getTime() : new Date().getTime(),
      dataFim: options.dataFinal ? options.dataFinal.getTime() : new Date().getTime(),
      nome: options.nome,
    };

    this.appState.isLoading = true;
    this.arquivoComprovantePixService.downloadAll(this.queryOptions).subscribe((response) => {
      this.appState.isLoading = false;
      const hoje = new Date();
      FileSaver.saveAs(
        response,
        `comprovantes-${moment(options.dataInicial).format('DDMMYY')}-${moment(options.dataFinal).format('DDMMYY')}.zip`,
      );
    });
  }

  onFilter(options: AcompanhamentoComprovantesFilterOptions): void {
    const posto = options.posto ? options.posto.id : null;
    const rota = options.rota ? options.rota.descricao : null;

    this.queryOptions.params = {
      ...this.queryOptions.params,
      posto,
      rota,
      dataInicio: options.dataInicial ? options.dataInicial.getTime() : new Date().getTime(),
      dataFim: options.dataFinal ? options.dataFinal.getTime() : new Date().getTime(),
      nome: options.nome,
    };

    this.onLoad();
  }

  onAcaoClick(event: { acao: string; domain: ParcelaComprovantePixDTO }): void {
    const actions = new Map<string, (comprovante: ParcelaComprovantePixDTO) => void>().set(
      AcompanhamentoComprovantesPixMainComponent.DOWNLOAD_COMPROVANTE,
      (comprovante: ParcelaComprovantePixDTO) => {
        this.downloadComprovante(comprovante);
      },
    );

    actions.get(event.acao)(event.domain);
  }

  downloadComprovante(comprovante: ParcelaComprovantePixDTO): void {
    this.arquivoComprovantePixService
      .findByCodigoInContext(comprovante.transferenciaId, comprovante.dataConfirmacao)
      .subscribe((response) => {
        FileSaver.saveAs(response, `${comprovante.nomeCliente}.${comprovante.pathArquivo.split('.').pop()}`);
      });
  }
}
