import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contrato } from 'src/app/base/domain/contrato.model';
import { AlterarSobraDTO } from 'src/app/base/domain/dto/alterar-sobra.dto';
import { Funcionario } from 'src/app/base/domain/funcionario.model';
import { Parcela } from 'src/app/base/domain/parcela.model';
import { Posto } from 'src/app/base/domain/posto.model';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { Transferencia } from 'src/app/base/domain/transferencia.model';
import { ContratoService } from 'src/app/base/services/contrato.service';
import { FuncionarioService } from 'src/app/base/services/funcionario.service';
import { PostoService } from 'src/app/base/services/posto.service';
import { QueryOptions } from '../../domain/query.options';

interface DataType {
    transferencia: Transferencia;
    action: String;
}

@Component({
    selector: 'app-cancelar-sobra-form',
    templateUrl: './cancelar-sobra-form.component.html',
    styleUrls: ['./cancelar-sobra-form.component.css']
})
export class CancelarSobraFormComponent implements OnInit {

    sobraDTO = new AlterarSobraDTO();
    transferencia: Transferencia;
    title: string;

    postoSelecionado: Posto;
    funcionarios: Funcionario[] = [];
    funcionarioSelecionado: Funcionario;
    contratos: Contrato[] = [];
    contratoSelecionado: Contrato;
    parcelaSelecionada: Parcela;
    parcelas: Parcela[] = [];

    constructor(
        public dialogRef: MatDialogRef<CancelarSobraFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DataType,
        private postoService: PostoService,
        private contratoService: ContratoService,
        private funcionarioService: FuncionarioService,
    ) {
        this.sobraDTO.transferenciaId = data.transferencia.id;
        this.sobraDTO.postoId = data.transferencia.posto.id;
        this.transferencia = data.transferencia;
        this.postoSelecionado = data.transferencia.posto;

        if (data.action === 'ALTERAR_SOBRA') {
            this.title = 'Alterar sobra';
        }
    }

    ngOnInit(): void {
        const options = new QueryOptions({ pageSize: 10, pageNumber: 1, params: { postoId: this.data.transferencia.posto.id } });
        this.funcionarioService.findAll(options).subscribe((response: ReturnAPI<Page<Funcionario>>) => {
            this.funcionarios = response.object.content;
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onConfirm(): void {
        this.dialogRef.close(this.sobraDTO);
    }

    funcionarioSelecionadoEvent(funcionario: Funcionario): void {
        this.funcionarioSelecionado = funcionario;
        const options = new QueryOptions({
            pageSize: 10, pageNumber: 1,
            params: {
                postoId: this.postoSelecionado.id,
                responsavelContrato: funcionario.pessoa.nomePessoa,
                situacaoContrato: 'in:EM_ANDAMENTO,FINALIZADO,FINALIZADO_COM_DIVIDA,FINALIZADO_COM_ATRASO,FINALIZADO_COM_ANTECIPACAO,EM_ATRASO,PENDENTE,ANTECIPADO_BORDERO,LIQUIDADO_ADMINISTRACAO,RENEGOCIADO'
            }
        });

        this.contratoService.findAll(options).subscribe((response: ReturnAPI<Page<Contrato>>) => {
            this.contratos = response.object.content;
        });
    }

    contratoSelecionadoEvent(contrato: Contrato): void {
        this.contratoSelecionado = contrato;
        this.sobraDTO.contratoId = contrato.id;
        contrato.listParcela.sort((a,b) => b.ordem - a.ordem);
        this.parcelas = contrato.listParcela;
    }

    parcelaSelecionadoEvent(parcela: Parcela): void {
        this.parcelaSelecionada = parcela;
        this.sobraDTO.parcelaId = parcela.id;
    }
}
