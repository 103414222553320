import { Component, OnInit } from '@angular/core';
import { ContratoService } from '../../../services/contrato.service';

@Component({
  selector: 'app-cobranca-cliente-regular-fds-main',
  templateUrl: './cobranca-cliente-regular-fds-main.component.html',
  styleUrls: ['./cobranca-cliente-regular-fds-main.component.css'],
})
export class CobrancaClienteRegularFdsMainComponent implements OnInit {
  monitoramento: any;

  constructor(
    private contratoService: ContratoService,
  ) {
  }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.contratoService.listarCobrancasClientesRegularesfimDeSemana().subscribe(data => {
      this.monitoramento = data.object;
    });
  }

  reloadPage() {
    this.load();
  }

  onAcaoClick(idParcela: any): void {
    this.contratoService.enviarCobrancaClienteFDS(idParcela).subscribe(data => this.load());
  }

}
