import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { QueryOptions } from "src/app/global/domain/query.options";
import { Resource } from "src/app/global/domain/resource.model";
import { ResourceService } from "src/app/global/services/resource.service";
import { WEB_API } from "src/app/global/services/web-api";
import { ArquivoImportacaoFinalizado } from "../domain/arquivo-importacao-finalizado.model";
import { Produto } from "../domain/produto.model";
import { Page, ReturnAPI } from "../domain/return-api.model";
import { ArquivoImportacaoFinalizadoSerializer } from "../serializables/arquivo-importacao-finalizado-serializer";

@Injectable()
export class ArquivoImportacaoFinalizadoService extends ResourceService<ArquivoImportacaoFinalizado> {
    static RESOURCE = 'arquivo-importacao-finalizado';
    produto: Produto;
    produtoId: string;

    constructor(private http: HttpClient) {
        super(http, `${WEB_API}`, `${ArquivoImportacaoFinalizadoService.RESOURCE}`, new ArquivoImportacaoFinalizadoSerializer(ArquivoImportacaoFinalizado))
    }

    findAll(queryOptions: QueryOptions): Observable<ReturnAPI<Page<ArquivoImportacaoFinalizado>>> {
        this.getProduto();
        return this.httpClient.get<ReturnAPI<Page<ArquivoImportacaoFinalizado>>>(
            `${WEB_API}/${ArquivoImportacaoFinalizadoService.RESOURCE}?${queryOptions.toQueryString()}&${this.produtoId}`,
        );
    }

    getProduto(): void {
        if (sessionStorage.getItem('produto')) {
            this.produto = JSON.parse(sessionStorage.getItem('produto'));
            this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
        }
    }

}