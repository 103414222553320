import { QueryOptions } from './../../global/domain/query.options';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { WEB_API } from 'src/app/global/services/web-api';
import { ResourceService } from '../../global/services/resource.service';
import { DashboardCrecimentoSustentavel } from '../domain/dashboard-crescimento-sustentavel.model';
import { DashBoardCrescimentoSustentavelSerializer } from '../serializables/dashboard-crescimento-sustentavel.serializer';
import { DashBoardEstadoFaturamento } from '../domain/dashboard-estado-faturamento.model';
import { Injectable } from '@angular/core';

@Injectable()
export class DashboardCrescimentoSustentavelService extends ResourceService<DashboardCrecimentoSustentavel> {
    static RESOURCE = 'crescimento-sustentavel';

    constructor(private http: HttpClient) {
        super(http, `${WEB_API}`, `${DashboardCrescimentoSustentavelService.RESOURCE}`,
            new DashBoardCrescimentoSustentavelSerializer(DashboardCrecimentoSustentavel));
    }

    getDashboard(queryOptions: QueryOptions): Observable<DashboardCrecimentoSustentavel> {
        return this.http.get<DashboardCrecimentoSustentavel>(
            `${WEB_API}/${DashboardCrescimentoSustentavelService.RESOURCE}/dashboard?${queryOptions.toQueryString(false)}`);
    }

    getDashboardWithoutRefresh(queryOptions: QueryOptions): Observable<DashboardCrecimentoSustentavel> {
        return this.http.get<DashboardCrecimentoSustentavel>(
            `${WEB_API}/${DashboardCrescimentoSustentavelService.RESOURCE}/dashboard-without-refresh?${queryOptions.toQueryString(false)}`);
    }

    getDashboardEstado(estadoId: number): Observable<DashBoardEstadoFaturamento> {
        return this.http.get<DashBoardEstadoFaturamento>(
            `${WEB_API}/${DashboardCrescimentoSustentavelService.RESOURCE}/dashboard/estado/${estadoId}`);
    }

    getDashboardEstadoSustentavel(): Observable<DashBoardEstadoFaturamento[]> {
        return this.http.get<DashBoardEstadoFaturamento[]>(`${WEB_API}/${DashboardCrescimentoSustentavelService.RESOURCE}/resumo-estado`);
    }
}
