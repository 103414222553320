import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisitacaoCaptacaoMainComponent } from './visitacao-captacao-main/visitacao-captacao-main.component';
import { GlobalModule } from 'src/app/global/global.module';

@NgModule({
  declarations: [VisitacaoCaptacaoMainComponent],
  imports: [CommonModule, GlobalModule],
})
export class VisitacaoCaptacaoModule {}
