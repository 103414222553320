import { Component, Inject, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Moment } from 'moment';



@Component({
  selector: 'app-alterar-parcela-contrato',
  templateUrl: './alterar-parcela-contrato.component.html',
  styleUrls: ['./alterar-parcela-contrato.component.css']
})

export class AlterarParcelaComponent implements OnInit {

  dataParcela = new Date();
  valorParcela = 0; 
  
  constructor(public dialogRef: MatDialogRef<AlterarParcelaComponent>) { }

  ngOnInit(): void { 
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    const data = {
      dataParcela: this.dataParcela,
      valor: this.valorParcela
    }
    this.dialogRef.close(Object.assign(data));  
  }

  onPickerEvent(event: MatDatepickerInputEvent<Moment>): void {
    this.dataParcela = event.value?.toDate() || null;
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

}
