import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoriaPlanoContaMainComponent } from './categoria-plano-conta-main/categoria-plano-conta-main.component';
import { GlobalModule } from 'src/app/global/global.module';

@NgModule({
  declarations: [CategoriaPlanoContaMainComponent],
  imports: [CommonModule, GlobalModule],
})
export class CategoriaPlanoContaModule {}
