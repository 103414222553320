import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { Produto } from '../domain/produto.model';
import { Page } from '../domain/return-api.model';
import { ConciliacaoBancariaDoc } from '../domain/conciliacao-bancaria-doc.model';
import { ConciliacaoBancariaDocSerializer } from '../serializables/conciliacao-bancaria-doc.serializer';
import { ParcelaClienteDOCView } from '../domain/parcela-cliente-doc-view.model';
@Injectable()
export class ConciliacaoBancariaDocService extends ResourceService<ConciliacaoBancariaDoc> {
  private static RESOURCE = 'conciliacao-bancaria-doc';

  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${ConciliacaoBancariaDocService.RESOURCE}`, new ConciliacaoBancariaDocSerializer(ConciliacaoBancariaDoc));
  }

  findAllPageable(queryOptions: QueryOptions): Observable<Page<ConciliacaoBancariaDoc>> {
    return this.http.get<Page<ConciliacaoBancariaDoc>>(
      `${WEB_API}/${ConciliacaoBancariaDocService.RESOURCE}/pageable?${queryOptions.toQueryString()}`,
    );
  }

  associar(data: {}): Observable<any> {
    return this.http.post<any>(`${WEB_API}/${ConciliacaoBancariaDocService.RESOURCE}/associar`, data);
  }

  getValorTotal(queryOptions: QueryOptions): Observable<any> {
    return this.http.get<number>(`${WEB_API}/${ConciliacaoBancariaDocService.RESOURCE}/get-valor-total?${queryOptions.toQueryString()}`);
  }

  findAllParcela(data: Date): Observable<ParcelaClienteDOCView[]> {
    return this.http.get<ParcelaClienteDOCView[]>(`${WEB_API}/${ConciliacaoBancariaDocService.RESOURCE}/list-parcela?data=${data}`);
  }

  vincular(dto: any): Observable<ConciliacaoBancariaDoc> {
    return this.http.post<ConciliacaoBancariaDoc>(`${WEB_API}/${ConciliacaoBancariaDocService.RESOURCE}/vincular-parcela`, dto);
  }
}
