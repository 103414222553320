import { ActivatedRoute } from '@angular/router';
import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { Posto } from 'src/app/base/domain/posto.model';
import { PostoService } from 'src/app/base/services/posto.service';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { MatInput } from '@angular/material/input';
import { Funcionario } from '../../../base/domain/funcionario.model';
import { FuncionarioService } from '../../../base/services/funcionario.service';
import { QueryOptions } from '../../domain/query.options';
import { Rota } from 'src/app/base/domain/rota.model';
import { RotaService } from 'src/app/base/services/rota.service';

export interface SituacaoGenerico {
  descricao: string;
  valor: number;
}
@Component({
  selector: 'app-global-filter',
  templateUrl: './global-filter.component.html',
  styleUrls: ['./global-filter.component.css'],
})
export class GlobalFilterComponent implements OnInit {
  @Input() filtros: string[];
  @Input() idClientePlaceholder: string;
  @Input() nomePlaceholder: string;
  @Input() situacoesPlaceholder: string;
  @Input() situacoes: string[];
  @Input() dataPlaceholder: string;
  @Input() seletorGenerico: { value: boolean, descricao: string }[];
  @Input() seletorGenericoPlaceholder: string;
  @Input() checkQueryParamsChange = false;
  @Input() situacoesGenericas1: SituacaoGenerico[];
  @Input() situacoesGenericas1Placeholder: string;
  @Input() situacoesGenericas2: SituacaoGenerico[];
  @Input() situacoesGenericas2Placeholder: string;
  @Input() maxDate: Date = null;
  @Input() dateReadOnly: boolean = false;
  @Input() idPlaceholder: string;

  @Output() postoSelecionadoClick = new EventEmitter<Posto>();
  @Output() funcionarioSelecionadoClick = new EventEmitter<Funcionario>();
  @Output() seletorSelecionadoClick = new EventEmitter<{ value: boolean, descricao: string }>();

  postos: Posto[] = [];
  postosSemEletro: Posto[] = [];
  rotas: Rota[] = [];
  funcionarios: Funcionario[];

  constructor(
    private postoService: PostoService,
    private activatedRoute: ActivatedRoute,
    private funcionarioService: FuncionarioService,
    private rotaService: RotaService
  ) { }

  // Binding id cliente Input
  private _idClienteInput: number;
  @Output() idClienteInputChange = new EventEmitter<number>();

  @Input()
  get idClienteInput(): number {
    return this._idClienteInput;
  }

  set idClienteInput(val: number) {
    this._idClienteInput = val;
    this.idClienteInputChange.emit(this._idClienteInput);
  }
  
  //Binding id Contrato Input
  private _idInput: number;
  @Output() idInputChange = new EventEmitter<number>();

  @Input()
  get idInput(): number {
    return this._idInput;
  }

  set idInput(val: number) {
    this._idInput = val;
    this.idInputChange.emit(this._idInput);
  }

  // Binding nomeInput
  private _nomeInput: string;
  @Output() nomeInputChange = new EventEmitter<string>();

  @Input()
  get nomeInput(): string {
    return this._nomeInput;
  }

  set nomeInput(val: string) {
    this._nomeInput = val;
    this.nomeInputChange.emit(this._nomeInput);
  }

  // Binding situacoesSelecionadas
  private _situacoesSelecionadas: string[];
  @Output() situacoesSelecionadasChange = new EventEmitter<string[]>();

  @Input()
  get situacoesSelecionadas(): string[] {
    return this._situacoesSelecionadas;
  }

  set situacoesSelecionadas(val: string[]) {
    this._situacoesSelecionadas = val;
    this.situacoesSelecionadasChange.emit(this._situacoesSelecionadas);
  }

  // Binding situacoesGenericas1
  private _situacoesGenericas1Selecionadas: number[];
  @Output() situacoesGenericas1SelecionadasChange = new EventEmitter<number[]>();

  @Input()
  get situacoesGenericas1Selecionadas(): number[] {
    return this._situacoesGenericas1Selecionadas;
  }

  set situacoesGenericas1Selecionadas(val: number[]) {
    this._situacoesGenericas1Selecionadas = val;
    this.situacoesGenericas1SelecionadasChange.emit(this._situacoesGenericas1Selecionadas);
  }

  // Binding situacoesGenericas2
  private _situacoesGenericas2Selecionadas: number[];
  @Output() situacoesGenericas2SelecionadasChange = new EventEmitter<number[]>();

  @Input()
  get situacoesGenericas2Selecionadas(): number[] {
    return this._situacoesGenericas2Selecionadas;
  }

  set situacoesGenericas2Selecionadas(val: number[]) {
    this._situacoesGenericas2Selecionadas = val;
    this.situacoesGenericas2SelecionadasChange.emit(this._situacoesGenericas2Selecionadas);
  }

  // Binding postoSelecionado
  private _postoSelecionado: Posto;
  @Output() postoSelecionadoChange = new EventEmitter<Posto>();

  @Input()
  get postoSelecionado(): Posto {
    return this._postoSelecionado;
  }

  set postoSelecionado(val: Posto) {
    this._postoSelecionado = val;
    this.postoSelecionadoChange.emit(this._postoSelecionado);
  }

  // Binding rotaSelecionada
  private _rotaSelecionada: Rota;
  @Output() rotaSelecionadaChange = new EventEmitter<Rota>();

  @Input()
  get rotaSelecionada(): Rota {
    return this._rotaSelecionada;
  }

  set rotaSelecionada(val: Rota) {
    this._rotaSelecionada = val;
    this.rotaSelecionadaChange.emit(this._rotaSelecionada);
  }

  // Binding SeletorSelecionado
  private _seletorSelecionado: { value: boolean, description: string };
  @Output() seletorSelecionadoChange = new EventEmitter<{ value: boolean, description: string }>();

  @Input()
  get seletorSelecionado(): { value: boolean, description: string } {
    return this._seletorSelecionado;
  }

  set seletorSelecionado(val: { value: boolean, description: string }) {
    this._seletorSelecionado = val;
    this.seletorSelecionadoChange.emit(this._seletorSelecionado);
  }


  // Binding dataInicial
  private _dataInicial: Date;
  @Input() dataInicialLegend: string;
  @Output() dataInicialChange = new EventEmitter<Date>();
  @ViewChild('dataInicial', { read: MatInput }) dataInicialInput: MatInput;

  @Input()
  get dataInicial(): Date {
    return this._dataInicial;
  }

  set dataInicial(val: Date) {
    if (val === null && this.dataInicialInput) {
      this.dataInicialInput.value = '';
    }
    this._dataInicial = val;
    this.dataInicialChange.emit(this._dataInicial);
  }

  // Binding dataFinal
  private _dataFinal: Date;
  @Input() dataFinalLegend: string;
  @Output() dataFinalChange = new EventEmitter<Date>();
  @ViewChild('dataFinal', { read: MatInput }) dataFinalInput: MatInput;

  @Input()
  get dataFinal(): Date {
    return this._dataFinal;
  }

  set dataFinal(val: Date) {
    if (val === null && this.dataFinalInput) {
      this.dataFinalInput.value = '';
    }
    this._dataFinal = val;
    this.dataFinalChange.emit(this._dataFinal);
  }

  // Binding funcionarioSelecionado
  private _funcionarioSelecionado: Funcionario;
  @Output() funcionarioSelecionadoChange = new EventEmitter<Funcionario>();

  @Input()
  get funcionarioSelecionado(): Funcionario {
    return this._funcionarioSelecionado;
  }

  set funcionarioSelecionado(val: Funcionario) {
    this._funcionarioSelecionado = val;
    this.funcionarioSelecionadoChange.emit(this._funcionarioSelecionado);
  }


  ngOnInit(): void {
    this.postoService.listarPostos().subscribe((response: ReturnAPI<Posto[]>) => {
      if (response.success) {
        this.postos = response.object;
      }
     
    this.postoService.listarPostosSemEletro().subscribe((response: ReturnAPI<Posto[]>) => {
      if (response.success) {
        this.postos = response.object;
      }
    })
    });
    if (this.checkQueryParamsChange) {
      this.subscribeInQueryParamsChange();
    }
  }

  listarFuncionarioPorPosto(postoId: number): void {
    const options = new QueryOptions({ pageSize: 50, pageNumber: 1, params: { postoId: postoId }, pageSort: '-id' });
    this.funcionarioService.findAll(options).subscribe((response: ReturnAPI<Page<Funcionario>>) => {
      if (response.success) {
        this.funcionarios = response.object.content;
      }
    });
  }

  listarRotas(postoId: number): void {
    const options = new QueryOptions({ pageSize: 50, pageNumber: 1, params: { postoId: postoId }, pageSort: '-id' });
    this.rotaService.findAll(options).subscribe((response: ReturnAPI<Page<Rota>>) => {
      if (response.success) {
        this.rotas = response.object.content;
      }
    });
  }

  subscribeInQueryParamsChange(): void {
    console.log('subscribeInQueryParamsChange');
    this.activatedRoute.queryParams.subscribe((params: { [key: string]: string }) => {
      if ('postoId' in params) {
        const posto = this.postos.find(posto => posto.id === parseInt(params['postoId'], null));
        this.postoSelecionado = posto ?? null;
      }
    });
  }

  postoSelecionadoEvent(posto: Posto): void {
    this.postoSelecionadoClick.emit(posto);
    this.listarFuncionarioPorPosto(posto.id);
    this.listarRotas(posto.id);
  }

  funcionarioSelecionadoEvent(funcionario: Funcionario): void {
    this.funcionarioSelecionadoClick.emit(funcionario);
  }

  seletorSelecionadoEvent(seletor: { value: boolean, descricao: string }): void {
    this.seletorSelecionadoClick.emit(seletor);
  }

  onPickerEvent(picker: 'inicial' | 'final', event: MatDatepickerInputEvent<Moment>): void {
    if (picker === 'inicial') {
      this.dataInicial = event.value?.toDate() || null;
    }
    if (picker === 'final') {
      this.dataFinal = event.value?.toDate() || null;
    }
  }
}
