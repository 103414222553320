import { Resource } from 'src/app/global/domain/resource.model';
import { Endereco } from './endereco.model';

export class Posto extends Resource {
  public descricao: string;

  public uid: string;

  public endereco: Endereco;

  constructor() {
    super();
  }
}
