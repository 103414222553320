import { Reagendamento } from '../domain/reagendamento.model';
import { ResourceService } from 'src/app/global/services/resource.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WEB_API } from 'src/app/global/services/web-api';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { Observable } from 'rxjs';
import { ReturnAPI, Page } from '../domain/return-api.model';
import { ReagendamentoSerializer } from '../serializables/reagendamento.serializer';
import { QuantidadeReagendamentosFuncionarioDTO } from '../domain/dto/quantidade-reagendamentos-funcionario.dto';
import { QuantidadeReagendamentosClienteDTO } from '../domain/dto/quantidade-reagendamentos-cliente.dto';
import { Produto } from '../domain/produto.model';
import { ValoresContratoRequestDTO } from '../domain/dto/valores-contrato-requesto.dto';

@Injectable()
export class ReagendamentoService extends ResourceService<Reagendamento> {
  static RESOURCE = 'reagendamento';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, ReagendamentoService.RESOURCE, new ReagendamentoSerializer(Reagendamento));
  }

  pageAll(queryOption: QueryOptions): Observable<ReturnAPI<Page<Reagendamento>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Reagendamento>>>(
      `${WEB_API}/${ReagendamentoService.RESOURCE}?${queryOption.toQueryString()}&${this.produtoId}`,
    );
  }

  getFuncionarioComMaisReagendamentos(postoID?: number): Observable<ReturnAPI<QuantidadeReagendamentosFuncionarioDTO>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<QuantidadeReagendamentosFuncionarioDTO>>(
      `${WEB_API}/${ReagendamentoService.RESOURCE}/funcionario-com-mais-reagendamentos?&${this.produtoId}${
        postoID ? `&postoID=${postoID}` : ''
      }`,
    );
  }

  getClienteComMaisReagendamentos(postoID?: number): Observable<ReturnAPI<QuantidadeReagendamentosClienteDTO>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<QuantidadeReagendamentosClienteDTO>>(
      `${WEB_API}/${ReagendamentoService.RESOURCE}/cliente-com-mais-reagendamentos?&${this.produtoId}${
        postoID ? `&postoID=${postoID}` : ''
      }`,
    );
  }

  getFuncionarioComMaisReagendamentosByFuncionario(funcionarioId: number): Observable<ReturnAPI<QuantidadeReagendamentosFuncionarioDTO>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<QuantidadeReagendamentosFuncionarioDTO>>(
      `${WEB_API}/${ReagendamentoService.RESOURCE}/funcionario-com-mais-reagendamentos-por-funcionario?${this.produtoId}${
        funcionarioId ? `&funcionarioId=${funcionarioId}` : ''
      }`,
    );
  }

  getClienteComMaisReagendamentosByFuncionario(funcionarioId: number): Observable<ReturnAPI<QuantidadeReagendamentosClienteDTO>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<QuantidadeReagendamentosClienteDTO>>(
      `${WEB_API}/${ReagendamentoService.RESOURCE}/cliente-com-mais-reagendamentos-por-funcionario?${this.produtoId}${
        funcionarioId ? `&funcionarioId=${funcionarioId}` : ''
      }`,
    );
  }

  getClientesComMaisReagendamentosDeUmFuncionario(funcionarioId: number): Observable<ReturnAPI<QuantidadeReagendamentosClienteDTO[]>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<QuantidadeReagendamentosClienteDTO[]>>(
      `${WEB_API}/${ReagendamentoService.RESOURCE}/clientes-com-mais-reagendamentos-de-um-funcionario?${this.produtoId}${
        funcionarioId ? `&funcionarioId=${funcionarioId}` : ''
      }`,
    );
  }

  getValorTotalBruto(valoresContrato: Partial<ValoresContratoRequestDTO>): Observable<ReturnAPI<number>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<number>>(
      `${WEB_API}/${ReagendamentoService.RESOURCE}/valor-bruto-total-contratos?${this.produtoId}`,
      valoresContrato,
    );
  }

  getValorTotalLiquido(valoresContrato: Partial<ValoresContratoRequestDTO>): Observable<ReturnAPI<number>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<number>>(
      `${WEB_API}/${ReagendamentoService.RESOURCE}/valor-liquido-total-contratos?${this.produtoId}`,
      valoresContrato,
    );
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }
}
