import { Component, OnInit, Inject } from '@angular/core';
import { Produto } from 'src/app/base/domain/produto.model';
import { Estado } from 'src/app/base/domain/estado.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EstadoService } from 'src/app/base/services/estado.service';
import { QueryOptions } from '../../domain/query.options';

export interface ProdutoFormData {
  produto: Produto;
  action: string;
}

@Component({
  selector: 'app-produto-form',
  templateUrl: './produto-form.component.html',
  styleUrls: ['./produto-form.component.css'],
})
export class ProdutoFormComponent implements OnInit {
  produto = new Produto();
  title = '';
  estados: Estado[] = [];
  filteredEstados: Estado[] = [];
  estadoInput = '';
  sigla = '';
  url = `http://api.____.____.gjbl.com.br/`;
  isHomol = false;

  queryOptions = new QueryOptions();

  constructor(
    public dialogRef: MatDialogRef<ProdutoFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProdutoFormData,
    private estadoService: EstadoService,
  ) {
    this.estadoService.list(this.queryOptions).subscribe((response: Estado[]) => {
      this.estados = response;
      this.filteredEstados = this.estados;
      this.title = 'Novo Produto';
    });

    if (data.action === 'update') {
      this.title = 'Editar Produto';
      this.produto = data.produto;
      this.url = this.produto.url;
      this.isHomol = this.produto.isHomol;
      this.estadoInput = this.produto.estado?.nome ? this.produto.estado?.nome : '';
    }
  }

  ngOnInit(): void {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.produto.url = this.url;
    this.produto.isHomol = this.isHomol;
    this.dialogRef.close(this.produto);
  }

  onSelected(estadoNome: string): void {
    const estadoSelecionado = this.estados.find((estado: Estado) => estado.nome === estadoNome);
    this.produto.estado = estadoSelecionado;
    this.sigla = estadoSelecionado.sigla;
    this.url = `http://api.${this.produto.descricao?.toLowerCase()}.${this.sigla.toLowerCase()}.gjbl.com.br/`;
  }

  buildUrl(value: string): void {
    const filterValue = value?.toLowerCase();
    this.produto.descricao = filterValue;
    this.url = `http://api.${this.produto.descricao}.${this.sigla.toLowerCase()}.gjbl.com.br/`;
  }

  checkUrl(): void {
    if (this.isHomol) {
      this.url = `http://api.${this.produto.descricao}.${this.sigla.toLowerCase()}.homol.gjbl.com.br/`;
    } else {
      this.url = `http://api.${this.produto.descricao}.${this.sigla.toLowerCase()}.gjbl.com.br/`;
    }
  }

  doFilter(value: string): void {
    if (value === 'estado') {
      this.filteredEstados = this._filtrar(this.estadoInput, this.estados, 'nome');
    }
  }

  private _filtrar<T>(value: string, list: T[], field: string): T[] {
    const filterValue = value?.toLowerCase();
    return list.filter((e: T) => e[field].toLowerCase().indexOf(filterValue) === 0);
  }
}
