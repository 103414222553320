import { ContaTev } from '../../../base/domain/conta-tev.model';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ContaTevFormData {
  conta: ContaTev;
  action: string;
}

@Component({
  selector: 'app-conta-tev-form',
  templateUrl: './conta-tev-form.component.html',
  styleUrls: ['./conta-tev-form.component.css']
})
export class ContaTevFormComponent implements OnInit {

  conta = new ContaTev();
  title = '';

  constructor(
    public dialogRef: MatDialogRef<ContaTevFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ContaTevFormData
  ) {

    if (data.action === 'update') {
      this.title = 'Editar';
      this.conta = data.conta;
    } else {
      this.title = 'Novo';
    }
  }

  ngOnInit(): void { }

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(this.conta);
  }

}
