import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalModule } from 'src/app/global/global.module';
import { CaixaFuncionarioMainComponent } from './caixa-funcionario-main/caixa-funcionario-main.component';
import { CaixaFuncionarioMovimentacoesComponent } from './caixa-funcionario-movimentacoes/caixa-funcionario-movimentacoes.component';

@NgModule({
  declarations: [CaixaFuncionarioMainComponent, CaixaFuncionarioMovimentacoesComponent],
  imports: [CommonModule, GlobalModule],
})
export class CaixaFuncionarioModule {}
