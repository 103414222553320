import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { ArquivoImportacaoFinalizado } from 'src/app/base/domain/arquivo-importacao-finalizado.model';
import { ArquivoImportacaoNovo } from 'src/app/base/domain/arquivo-importacao-novo.model';
import { CarteiraCliente } from 'src/app/base/domain/carteira-cliente.model';
import { Posto } from 'src/app/base/domain/posto.model';
import { ArquivoImportacaoNovoService } from 'src/app/base/services/arquivo-importacao-novo.service';
import { CardListComponent } from '../../components/card-list/card-list.component';
import { DialogService } from '../../services/dialog.service';

export interface CarteiraClienteAcaoEvent {
  acao: string;
  carteira: CarteiraCliente;
}

export interface CarteiraClienteMenuAcaoEvent {
  acao: string;
}

export interface CarteiraClienteListFilterOptions {
  descricao: string;
  dataCadastro: Date;
}

@Component({
  selector: 'app-carteira-cliente-list',
  templateUrl: './carteira-cliente-list.component.html',
  styleUrls: ['./carteira-cliente-list.component.css'],
})
export class CarteiraClienteListComponent implements OnInit {
  @ViewChild('searchForm', { static: false }) searchForm: NgForm;
  @ViewChild('dataTable', { static: false }) dataTable: Table;
  @ViewChild(CardListComponent, { static: true }) cardList: CardListComponent;

  @Input() carteiras: CarteiraCliente[];
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() loading: boolean;
  @Input() title: string;
  @Input() nomePlaceholder: string;
  @Input() dataPlaceholder: string;
  @Input() filtros: string[];
  @Input() options: string[];
  @Input() optionalColumns: string[];
  @Input() menuAcoes: string[];

  @Output() loadCarteirasPage = new EventEmitter<number>();
  @Output() disclosureArquivo = new EventEmitter<CarteiraCliente>();
  @Output() filter = new EventEmitter<CarteiraClienteListFilterOptions>();
  @Output() menuAcaoClickEvent = new EventEmitter<CarteiraClienteMenuAcaoEvent>();
  @Output() acaoClick = new EventEmitter<CarteiraClienteAcaoEvent>();

  private currentPage = 0;

  private readonly defaultFilterOptions: CarteiraClienteListFilterOptions = {
    descricao: null,
    dataCadastro: null,
  };

  filterOptions: CarteiraClienteListFilterOptions;

  constructor(
    private arquivoImportacaoNovoService: ArquivoImportacaoNovoService,
    public dialog: MatDialog,
    public dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.onLimparFiltros();
  }

  loadCarteiras(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadCarteirasPage.emit(pageNumber);
  }

  onDisclosure(carteira: CarteiraCliente): void {
    this.disclosureArquivo.emit(carteira);
  }

  onAtualizar(): void {
    this.loadCarteirasPage.emit(this.currentPage);
  }

  onLimparFiltros(): void {
    if (this.filterOptions) {
      this.filterOptions.descricao = null;
      this.filterOptions.dataCadastro = null;
    }
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  onAcaoClick(acao: string, domain: CarteiraCliente): void {
    this.acaoClick.emit({ acao, carteira: domain });
  }

  public onMenuAcaoClick(acao: string): void {
    this.menuAcaoClickEvent.emit({ acao });
  }
}
