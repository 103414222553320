import { MatSelectModule } from '@angular/material/select';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalModule } from 'src/app/global/global.module';
import { ComponentsModule } from 'src/app/global/components/components.module';
import { AprovacaoDeCreditoMainComponent } from './aprovacao-de-credito-main/aprovacao-de-credito-main.component';
import { AprovacaoDeCreditoDetailsComponent } from './aprovacao-de-credito-details/aprovacao-de-credito-details.component';
import { DialogModule } from 'primeng/dialog';
import { TabMenuModule } from 'primeng/tabmenu';
import { ButtonModule } from 'primeng/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatOptionModule } from '@angular/material/core';



@NgModule({
  declarations: [AprovacaoDeCreditoMainComponent, AprovacaoDeCreditoDetailsComponent],
  imports: [
    CommonModule,
    GlobalModule,
    ComponentsModule,
    DialogModule,
    TabMenuModule,
    ButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    MatOptionModule,
    MatSelectModule
  ],
})
export class AprovacaoDeCreditoModule { }
