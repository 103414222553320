import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { Caixa } from 'src/app/base/domain/caixa.model';
import { ReturnAPI } from 'src/app/base/domain/return-api.model';
import { Transferencia } from 'src/app/base/domain/transferencia.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { TransferenciaService } from 'src/app/base/services/transferencia.service';
import {
  TransferenciaAcaoEvent,
  TransferenciaListFilterOptions,
} from 'src/app/global/lists/transferencia-list/transferencia-list.component';
import { DialogService } from 'src/app/global/services/dialog.service';
import { ValoresTransferenciaRequestDTO } from 'src/app/base/domain/dto/valores-transferencia-request.dto';

@Component({
  selector: 'app-acompanhamento-sangria-geral-main',
  templateUrl: './acompanhamento-sangria-geral-main.component.html',
  styleUrls: ['./acompanhamento-sangria-geral-main.component.css'],
})
export class AcompanhamentoSangriaGeralMainComponent implements OnInit {
  private queryOptions = new QueryOptions({ pageSize: 25 });

  caixaId: number;
  transferencias: Transferencia[];
  transferenciasCobrancaDespesaFuncionario: Transferencia[];
  transferenciasGeralDespesaFuncionario: Transferencia[];
  totalRecords = 0;
  loading = false;
  caixa: Caixa;
  private postoSelecionado: number = null;
  optionalColumns = ['descricao', 'categoria', 'funcionario', 'acoes', 'situacao'];

  totalizador = 0;

  constructor(
    private route: ActivatedRoute,
    private transferenciaService: TransferenciaService,
    public dialog: MatDialog,
    public dialogService: DialogService,
  ) {
    this.route.params.subscribe((params: Params) => {
      this.caixaId = params.id;
    });
  }

  ngOnInit(): void {
    this.queryOptions.params = {
      situacao: `in:CONFIRMADA`,
    };
  }

  onLoad(event: number): void {
    this.list(event);
  }

  mapearAcoesDeTransferencias(transferencias: Transferencia[]): void {
    const mapearAcoes = (transferencia: Transferencia): Transferencia => {
      const acoes = [];
      return { ...transferencia, acoes };
    };

    this.transferencias = transferencias?.map(mapearAcoes) ?? [];
  }

  list(page: number = 0, valores: Partial<ValoresTransferenciaRequestDTO> = {}): void {
    this.queryOptions.pageNumber = page + 1;
    this.loading = true;
    this.queryOptions.pageSize = 15;

    this.transferenciaService.pageSangriaGraphQL(this.queryOptions).subscribe((response: any) => {
      this.totalRecords = this.totalRecords + response.data.listarTransferenciasTipoSangria.totalElements;
      this.loading = false;
      valores.dataInicial = valores.dataInicial ? valores.dataInicial : null;
      valores.dataFinal = valores.dataFinal ? valores.dataFinal : null;
      valores.nome = valores.nome ? valores.nome : null;
      valores.descricaoRota = valores.descricaoRota ? valores.descricaoRota : null;
      valores.tipo = 'TCOSA';
      this.mapearAcoesDeTransferencias(response.data.listarTransferenciasTipoSangria.content);
      this.getValorTotalPorTipoTransferencia({ ...valores, postoId: this.postoSelecionado });
    });
  }

  onAcaoClick(event: TransferenciaAcaoEvent): void {
    const actions = new Map<string, (transferencia: Transferencia) => void>();

    actions.get(event.acao)(event.transferencia);
  }

  onFilter(options: TransferenciaListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;

    this.queryOptions.params = {
      ...this.queryOptions.params,
      nomeFuncionario: options.nome || null,
      situacao: options.situacoesTransferencia?.length ? `in:${options.situacoesTransferencia.join(',')}` : null,
      dataInicio: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
      postoId,
      descricaoRota: options.descricaoRota || null,
    };

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }

    this.list(0, {
      nome: options.nome,
      descricaoRota: options.descricaoRota,
      dataInicial: options?.dataInicial,
      dataFinal: options?.dataFinal,
    });
  }

  getValorTotalPorTipoTransferencia(valores: Partial<ValoresTransferenciaRequestDTO>): void {
    this.transferenciaService.getValorTotalSangria(valores).subscribe((response: ReturnAPI<number>) => {
      this.totalizador = response.object || 0;
    });
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
