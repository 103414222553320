import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PotencialClienteMainComponent } from './potencial-cliente-main/potencial-cliente-main.component';
import { GlobalModule } from 'src/app/global/global.module';

@NgModule({
  declarations: [PotencialClienteMainComponent],
  imports: [CommonModule, GlobalModule],
})
export class PotencialClienteModule {}
