import { Component, OnInit } from '@angular/core';
import { QueryOptions } from '../../../global/domain/query.options';
import { DialogService } from '../../../global/services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { Page, ReturnAPI } from '../../domain/return-api.model';
import { ReagendamentoListFilterOptions } from '../../../global/lists/reagendamento-list/reagendamento-list.component';
import { AuditoriaAlterarParcela } from '../../domain/AuditoriaAlterarParcela.model';
import { AuditoriaAlterarParcelaService } from '../../services/auditoria-alterar-parcela.service';

@Component({
  selector: 'app-auditoria-alterar-parcela',
  templateUrl: './auditoria-alterar-parcela-main.component.html',
  styleUrls: ['./auditoria-alterar-parcela-main.component.css'],
})
export class AuditoriaAlterarParcelaMainComponent implements OnInit {
  auditoriaAlterarParcela: AuditoriaAlterarParcela[];
  queryOptions = new QueryOptions({ pageSize: 10 });
  totalRecords = 0;
  loading = false;
  byFuncionario = false;

  private postoSelecionado: number = null;

  constructor(
    public dialogService: DialogService,
    private auditoriaAlterarParcelaService: AuditoriaAlterarParcelaService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {}

  listarAuditoriaAlterarParcela(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.auditoriaAlterarParcelaService.pageAll(this.queryOptions).subscribe((response: ReturnAPI<Page<AuditoriaAlterarParcela>>) => {
      if (response.success) {
        this.loading = false;
        this.totalRecords = response.object.totalElements;
        this.auditoriaAlterarParcela = response.object.content;
      } else {
        this.auditoriaAlterarParcela = [];
        this.dialogService.feedbackReturnAPI(response, '');
        this.loading = false;
      }
    });
  }

  onLoadAuditoriaAlterarParcelaPage(page: number): void {
    this.listarAuditoriaAlterarParcela(page);
  }

  onFilter(options: ReagendamentoListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    this.queryOptions.params = {
      responsavelNome: options.funcionarioSelecionado ? options.funcionarioSelecionado : null,
      clienteNome: options.clienteSelecionado ? options.clienteSelecionado : null,
      postoId,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
    };
    this.listarAuditoriaAlterarParcela();
    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }
    if (options.funcionarioSelecionado != null) {
      this.byFuncionario = true;
    } else {
      this.byFuncionario = false;
    }
  }
}
