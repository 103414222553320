import { DashboardConciliacaoDTO } from '../../domain/dto/dashboard-conciliacao.dto';
import { QueryOptions } from '../../../global/domain/query.options';
import { ConciliacaoBancaria } from '../../domain/conciliacao-bancaria.model';
import { ConciliacaoBancariaService } from '../../services/conciliacao-bancaria.service';
import { AppState } from '../../../App.state';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DashBoardFilterOptions } from 'src/app/global/conciliacao-dashboard/conciliacao-dashboard.component';

@Component({
  selector: 'app-dashboard-conciliacao',
  templateUrl: './dashboard-conciliacao.component.html',
  styleUrls: ['./dashboard-conciliacao.component.css']
})
export class DashboardConciliacaoComponent implements OnInit {

  dashboardBradesco: DashboardConciliacaoDTO = new DashboardConciliacaoDTO();
  dashboardItau: DashboardConciliacaoDTO = new DashboardConciliacaoDTO();

  data = new Date();

  queryOptions = new QueryOptions({ pageSize: 10 });

  constructor(private service: ConciliacaoBancariaService, private router: Router, public appState: AppState) { }

  ngOnInit(): void {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    this.data = date;
    this.queryOptions.params = {
      dataInicial: date.getTime(),
      dataFinal: date.getTime(),
    };

    this.getDashBoards();
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  onReload(): void {
    this.getDashBoardBradesco();
    this.getDashBoardItau();
  }

  getDashBoards(): void {
    this.getDashBoardBradesco();
    this.getDashBoardItau();
  }

  getDashBoardBradesco(): void {
    this.data.setHours(0, 0, 0, 0);
    this.appState.isLoading = true;
    this.queryOptions.params.banco = 0;
    this.service.getDashboard(this.queryOptions).subscribe((response: DashboardConciliacaoDTO) => {
      this.appState.isLoading = false;
      this.dashboardBradesco = response;
    });
  }
  getDashBoardItau(): void {
    this.data.setHours(0, 0, 0, 0);
    this.appState.isLoading = true;
    this.queryOptions.params.banco = 1;
    this.service.getDashboard(this.queryOptions).subscribe((response: DashboardConciliacaoDTO) => {
      this.appState.isLoading = false;
      this.dashboardItau = response;
    });
  }


  onFilter(options: DashBoardFilterOptions): void {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    this.queryOptions.params = {
      dataInicio: options.dataInicial ? options.dataInicial.getTime() : date.getTime(),
      dataFim: options.dataFinal ? options.dataFinal.getTime() : date.getTime(),
    };
    this.getDashBoards();
  }

}
