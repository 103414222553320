import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { QueryOptions } from "src/app/global/domain/query.options";
import { ResourceService } from "src/app/global/services/resource.service";
import { WEB_API } from "src/app/global/services/web-api";
import { AcompanhamentoVenda } from "../domain/acompanhamento-venda.model";
import { Produto } from "../domain/produto.model";
import { AcompanhamentoVendaSerializer } from "../serializables/acompanhamento-venda.serializer";

@Injectable()
export class AcompanhamentoVendaService extends ResourceService<AcompanhamentoVenda> {
    static RESOURCE = 'acompanhamento-vendas';
    produto: Produto;
    produtoId: string;

    constructor(private http: HttpClient) {
        super(http, `${WEB_API}`, `${AcompanhamentoVendaService.RESOURCE}`, new AcompanhamentoVendaSerializer(AcompanhamentoVenda));
    }

    findAll(queryOptions: QueryOptions): Observable<AcompanhamentoVenda[]> {
        this.getProduto();
        return this.httpClient.get<AcompanhamentoVenda[]>(
            `${WEB_API}/${AcompanhamentoVendaService.RESOURCE}?${queryOptions.toQueryString()}&${this.produtoId}`,
        );
    }

    getProduto(): void {
        if (sessionStorage.getItem('produto')) {
            this.produto = JSON.parse(sessionStorage.getItem('produto'));
            this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
        }
    }
}