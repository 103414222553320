import { AfterViewChecked, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface DataType {
  cobrancas: any;
}

@Component({
  selector: 'app-lista-clientes-rota',
  templateUrl: './lista-clientes-rota.component.html',
  styleUrls: ['./lista-clientes-rota.component.css']
})
export class ListaClientesRotaComponent implements OnInit, AfterViewChecked {

  dados: any[];
  constructor(
    public dialogRef: MatDialogRef<ListaClientesRotaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataType
  ) {
    this.dados = data.cobrancas;

  }

  ngOnInit(): void {
  }

  ngAfterViewChecked(): void {

  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

}
