import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EstadoFaturamentoDigitalComponent } from './estado-faturamento-digital/estado-faturamento-digital.component';
import { PostoFaturamentoDigitalComponent } from './posto-faturamento-digital/posto-faturamento-digital.component';
import { FaturamentoDigitalMainComponent } from './faturamento-digital-main/faturamento-digital-main.component';
import { GlobalModule } from 'src/app/global/global.module';

@NgModule({
  declarations: [EstadoFaturamentoDigitalComponent, PostoFaturamentoDigitalComponent, FaturamentoDigitalMainComponent],
  imports: [CommonModule, GlobalModule],
})
export class AcompanhamentoFaturamentoDigitalModule {}
