import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ParcelaCobrancaVale } from 'src/app/base/domain/parcela-cobranca-vale.model';
import { LazyLoadEvent } from 'primeng';

export interface ParcelaCobrancaValeAcaoEvent {
  acao: string;
  parcelaCobranca: ParcelaCobrancaVale;
}

@Component({
  selector: 'app-parcela-cobranca-vale-list',
  templateUrl: './parcela-cobranca-vale-list.component.html',
  styleUrls: ['./parcela-cobranca-vale-list.component.css'],
})
export class ParcelaCobrancaValeListComponent implements OnInit {
  @Input() title: string;
  @Input() parcelas: ParcelaCobrancaVale[];
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() filtros: string[];
  @Input() options: string[];
  @Input() loading: boolean;

  @Output() loadPage = new EventEmitter<number>();
  @Output() acaoClick = new EventEmitter<ParcelaCobrancaValeAcaoEvent>();

  private currentPage = 0;

  ngOnInit(): void {}

  load(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadPage.emit(pageNumber);
  }

  onAcaoClick(acao: string, domain: ParcelaCobrancaVale): void {
    this.acaoClick.emit({ acao, parcelaCobranca: domain });
  }

  onAtualizar(): void {
    this.loadPage.emit(this.currentPage);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
