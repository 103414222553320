import { Funcionario } from './funcionario.model';
import { Posto } from './posto.model';
import { Resource } from 'src/app/global/domain/resource.model';
import { Captado } from './captado.model';
import { SituacaoClientePlanejamento } from './enum/situacao-cliente-planejamento.enum';

export class ClientePlanejamento extends Resource {
  tipoPessoa: string; // TipoPessoaEnum
  nomeCliente: string;
  endereco: string;
  data: Date;
  observacao: string;
  situacao: SituacaoClientePlanejamento;
  funcionario: Funcionario;
  posto: Posto;
  // equipe: Equipe;
  captado: Captado;
}
