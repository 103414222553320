import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DashboardCrecimentoSustentavel } from 'src/app/base/domain/dashboard-crescimento-sustentavel.model';
import { DashBoardEstadoFaturamento } from 'src/app/base/domain/dashboard-estado-faturamento.model';
import { Estado } from 'src/app/base/domain/estado.model';
import { Posto } from 'src/app/base/domain/posto.model';
import { Rota } from 'src/app/base/domain/rota.model';

export interface DashBoardCrescimentoSustentavelFilterOptions {
  dataInicial: Date;
  estadoSelecinado: Estado;
  postoSelecionado: Posto;
  rotaSelecionada: Rota;
}

@Component({
  selector: 'app-crescimento-sustentavel-dashboard',
  templateUrl: './crescimento-sustentavel-dashboard.component.html',
  styleUrls: ['./crescimento-sustentavel-dashboard.component.css']
})
export class CrescimentoSustentavelDashboardComponent implements OnInit {

  private readonly defaultFilters: DashBoardCrescimentoSustentavelFilterOptions = {
    dataInicial: new Date(),
    estadoSelecinado: null,
    postoSelecionado: null,
    rotaSelecionada: null
  };

  filterOptions: DashBoardCrescimentoSustentavelFilterOptions = Object.assign({}, this.defaultFilters);

  @Input() typeDashboard: string;
  @Input() dashboard: DashboardCrecimentoSustentavel;
  @Input() list: DashBoardEstadoFaturamento[];

  @Output() disclosure: EventEmitter<DashBoardEstadoFaturamento> = new EventEmitter<DashBoardEstadoFaturamento>();
  @Output() filter = new EventEmitter<DashBoardCrescimentoSustentavelFilterOptions>();
  @Output() reload = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  onAtualizar(): void {
    this.reload.emit();
  }

  onLimparFiltros(): void {

  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  onDisclosure(event: DashBoardEstadoFaturamento): void {
    this.disclosure.emit(event);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
}
