import { Equipe } from './../../../base/domain/equipe.model';
import { FuncionarioService } from './../../../base/services/funcionario.service';
import { Funcionario } from './../../../base/domain/funcionario.model';
import { Component, Inject, OnInit } from '@angular/core';
import { Endereco } from 'src/app/base/domain/endereco.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Posto } from 'src/app/base/domain/posto.model';
import { PostoService } from 'src/app/base/services/posto.service';
import { QueryOptions } from '../../domain/query.options';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';

export interface EquipeVisitadorFormData {
  posto?: Posto;
  enableDateSelection?: boolean
  datePlaceholder?: string
  title?: string;
}

export interface EquipeVisitadorFormComponentResult {
  posto: Posto;
  equipe: Equipe;
  visitador: Funcionario;
  data: Date;
}

@Component({
  selector: 'app-equipe-visitador-form',
  templateUrl: './equipe-visitador-form.component.html',
  styleUrls: ['./equipe-visitador-form.component.css'],
})
export class EquipeVisitadorFormComponent implements OnInit {

  postos: Posto[] = [];
  equipes: Equipe[] = [];
  visitadores: Funcionario[] = [];

  filteredPostos: Posto[] = [];
  filteredEquipes: Equipe[] = [];
  filteredVisitadores: Funcionario[] = [];

  postoInput = '';
  equipeInput = '';
  visitadorInput = '';

  private postoSelecionado: Posto = null;
  private equipeSelecionada: Equipe = null;
  private visitadorSelecionado: Funcionario = null;
  private dataSelecionada: Date = null;

  private readonly endereco: Endereco;

  constructor(
    public dialogRef: MatDialogRef<EquipeVisitadorFormComponent, EquipeVisitadorFormComponentResult>,
    @Inject(MAT_DIALOG_DATA) public data: EquipeVisitadorFormData,
    private postoService: PostoService,
    private funcionarioService: FuncionarioService
  ) { }

  ngOnInit(): void {
    this.postoService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Posto>>) => {
      if (response.success) {
        this.postos = response.object.content;
        this.filteredPostos = this.postos;
        if (this.data.posto) {
          this.postoInput = this.data.posto.descricao
          this.onPostoSelected(this.data.posto.descricao)
        }
      }
    });
  }

  onPostoSelected(descricao: string): void {
    const postoSelecionado = this.postos.find((posto: Posto) => posto.descricao === descricao);
    this.postoService.buscarEquipesPorPosto(postoSelecionado.id).subscribe((response: ReturnAPI<Page<Equipe>>) => {
      if (response.success) {
        this.postoSelecionado = postoSelecionado;
        this.equipes = response.object.content;
        this.visitadores = [];
        this.filteredEquipes = this.equipes;
        this.filteredVisitadores = [];
        this.equipeInput = '';
        this.visitadorInput = '';
      }
    });
  }

  async onEquipeSelected(descricao: string): Promise<void> {
    this.equipeSelecionada = this.equipes.find((equipe: Equipe) => equipe.descricao === descricao);
    const res = await this.funcionarioService.listarVisitadoresPorPosto(this.equipeSelecionada.posto.id).toPromise();
    if (res.success) {
      this.visitadores = res.object;
      this.filteredVisitadores = this.visitadores;
    }
    this.visitadorInput = '';
  }

  onVisitadorSelected(nomePessoa: string): void {
    this.visitadorSelecionado = this.visitadores.find((visitador: Funcionario) => visitador.pessoa.nomePessoa === nomePessoa);
  }

  onPickerEvent(event: MatDatepickerInputEvent<Moment>): void {
    this.dataSelecionada = event.value?.toDate() || null;
  }

  doFilter(value: string): void {
    if (value === 'posto') {
      this.filteredPostos = this._filtrar(this.postoInput, this.postos, 'descricao');
    }
    if (value === 'equipe') {
      this.filteredEquipes = this._filtrar(this.equipeInput, this.equipes, 'descricao');
    }
    if (value === 'visitador') {
      this.filteredVisitadores = this.visitadores.filter(
        (e: Funcionario) => e.pessoa.nomePessoa.toLowerCase().indexOf(this.visitadorInput?.toLowerCase()) === 0
      );
    }
  }

  onConfirm(): void {
    this.dialogRef.close({
      posto: this.postoSelecionado,
      equipe: this.equipeSelecionada,
      visitador: this.visitadorSelecionado,
      data: this.dataSelecionada
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  private _filtrar<T>(value: string, elements: T[], key: string): T[] {
    const filterValue = value?.toLowerCase();
    return elements.filter((e: T) => e[key].toLowerCase().indexOf(filterValue) === 0);
  }

  get queryOptions(): QueryOptions {
    return new QueryOptions({
      pageNumber: 1,
      pageSize: 1000
    });
  }
}
