import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArquivoAnaliseDeRisco } from 'src/app/base/domain/arquivo-analise-de-risco.model';
import { CategoriaAnaliseDeRisco } from 'src/app/base/domain/enum/categoria-analise-risco.enum';

export interface ArquivoAnaliseDeRiscoFormData {
  arquivoAnalise: ArquivoAnaliseDeRisco;
  action: string;
}

@Component({
  selector: 'app-arquivo-analise-de-risco-form',
  templateUrl: './arquivo-analise-de-risco-form.component.html',
  styleUrls: ['./arquivo-analise-de-risco-form.component.css'],
})
export class ArquivoAnaliseDeRiscoFormComponent implements OnInit {
  arquivoAnalise = new ArquivoAnaliseDeRisco();

  title = '';

  categorias: CategoriaAnaliseDeRisco[] = Object.values(CategoriaAnaliseDeRisco);

  constructor(
    public dialogRef: MatDialogRef<ArquivoAnaliseDeRiscoFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ArquivoAnaliseDeRiscoFormData,
  ) {
    if (data.action === 'update') {
      this.title = 'Editar Analise de Risco';
      this.arquivoAnalise = data.arquivoAnalise;
    } else {
      this.title = 'Cadastro de Analise de Risco';
    }
  }

  ngOnInit(): void {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(this.arquivoAnalise);
  }
}
