import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcompanhamentoFaturamentoMainComponent } from './acompanhamento-faturamento-main/acompanhamento-faturamento-main.component';
import { GlobalModule } from 'src/app/global/global.module';
import { AcompanhamentoEstadoFaturamentoComponent } from './acompanhamento-estado-faturamento/acompanhamento-estado-faturamento.component';
import { AcompanhamentoPostoFaturamentoComponent } from './acompanhamento-posto-faturamento/acompanhamento-posto-faturamento.component';

@NgModule({
  declarations: [AcompanhamentoFaturamentoMainComponent, AcompanhamentoEstadoFaturamentoComponent, AcompanhamentoPostoFaturamentoComponent],
  imports: [CommonModule, GlobalModule],
})
export class AcompanhamentoFaturamentoModule {}
