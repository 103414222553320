import { Posto } from './posto.model';
import { Resource } from 'src/app/global/domain/resource.model';

export class Feriado extends Resource {
  titulo: string;
  descricao: string;
  data: Date;
  naoProgramado: boolean;
  tipoFeriado: string;
  posto: Posto;

  constructor(feriado?: Partial<Feriado>) {
    super();
    Object.assign(this, feriado);
  }
}
