import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from 'src/app/App.state';
import { PlanejamentoSemanaCaptacao } from 'src/app/base/domain/planejamento-semana-captacao.model';
import { Page } from 'src/app/base/domain/return-api.model';
import { PlanejamentoSemanaCaptacaoService } from 'src/app/base/services/planejamento-semana-captacao.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { PlanejamentoSemanaCaptacaoFormComponent } from 'src/app/global/forms/planejamento-semana-captacao-form/planejamento-semana-captacao-form.component';
import { PlanejamentoSemanaCaptacaoAcaoEvent } from 'src/app/global/lists/planejamento-semana-captacao-list/planejamento-semana-captacao-list.component';
import { DialogService } from 'src/app/global/services/dialog.service';

@Component({
  selector: 'app-planejamento-semana-captacao-main',
  templateUrl: './planejamento-semana-captacao-main.component.html',
  styleUrls: ['./planejamento-semana-captacao-main.component.css'],
})
export class PlanejamentoSemanaCaptacaoMainComponent {
  private static DELETE_ACTION = 'Deletar Planejamento';
  private static EDITAR_ACTION = 'Editar Planejamento';

  planejamentos: PlanejamentoSemanaCaptacao[];
  totalRecords = 0;

  queryOptions = new QueryOptions({ pageSize: 10, pageSort: '-id' });

  private postoSelecionado: number = null;

  constructor(
    private service: PlanejamentoSemanaCaptacaoService,
    public dialog: MatDialog,
    public appState: AppState,
    public dialogService: DialogService,
  ) {}

  mapearAcoes(planejamentos: PlanejamentoSemanaCaptacao[]): void {
    const mapearAcoes = (planejamento: PlanejamentoSemanaCaptacao): PlanejamentoSemanaCaptacao => {
      const acoes = [];
      acoes.push(PlanejamentoSemanaCaptacaoMainComponent.EDITAR_ACTION);
      return { ...planejamento, acoes };
    };
    this.planejamentos = planejamentos?.map(mapearAcoes) ?? [];
  }

  list(page: number = 0): void {
    this.queryOptions.pageNumber = page;
    this.appState.isLoading = true;
    this.service.listPageable(this.queryOptions).subscribe(
      (response: Page<PlanejamentoSemanaCaptacao>) => {
        if (response) {
          this.mapearAcoes(response.content);
          this.totalRecords = response.totalElements;
        }
        this.appState.isLoading = false;
      },
      () => (this.appState.isLoading = false),
    );
  }

  onLoad(pageNumber: number): void {
    this.list(pageNumber);
  }

  onDelete(planejamento: PlanejamentoSemanaCaptacao): void {}

  onAcaoClick(event: PlanejamentoSemanaCaptacaoAcaoEvent): void {
    const actions = new Map<string, (planejamento: PlanejamentoSemanaCaptacao) => void>()
      .set(PlanejamentoSemanaCaptacaoMainComponent.DELETE_ACTION, (planejamento: PlanejamentoSemanaCaptacao) => {
        this.dialogService.confirmDialog('Deseja realmente deletar esse plamejamento?', () => this.onDelete(planejamento));
      })
      .set(PlanejamentoSemanaCaptacaoMainComponent.EDITAR_ACTION, (planejamento: PlanejamentoSemanaCaptacao) => {
        this.onEdit(planejamento);
      });

    actions.get(event.acao)(event.planejamento);
  }

  onNovo(): void {
    this.dialog
      .open(PlanejamentoSemanaCaptacaoFormComponent, {
        data: {
          action: 'novo',
          planejamento: null,
        },
        width: '300px',
      })
      .afterClosed()
      .subscribe((result: PlanejamentoSemanaCaptacao) => {
        if (result) {
          this.service.create(result).subscribe(
            () => {
              this.appState.isLoading = false;
              this.dialogService.feedbackSuccess('Operação realizada com sucesso!');
              this.list();
            },
            // tslint:disable-next-line: typedef
            (error) => {
              this.appState.isLoading = false;
              console.error(error);
              this.dialogService.feedbackError('Ocorreu um erro ao criar usuário!');
              this.list();
            },
          );
        }
      });
  }

  onEdit(planejamento: PlanejamentoSemanaCaptacao): void {
    console.log(planejamento);
    this.dialog
      .open(PlanejamentoSemanaCaptacaoFormComponent, {
        data: {
          action: 'update',
          planejamento: planejamento,
        },
        width: '300px',
      })
      .afterClosed()
      .subscribe((result: PlanejamentoSemanaCaptacao) => {
        if (result) {
          this.service.update(result).subscribe(
            () => {
              this.appState.isLoading = false;
              this.dialogService.feedbackSuccess('Operação realizada com sucesso!');
              this.list();
            },
            // tslint:disable-next-line: typedef
            (error) => {
              this.appState.isLoading = false;
              console.error(error);
              this.dialogService.feedbackError('Ocorreu um erro ao atualizar planejamento!');
              this.list();
            },
          );
        }
      });
  }
}
