import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Equipe } from 'src/app/base/domain/equipe.model';
import { QueryOptions } from '../../domain/query.options';
import { Posto } from 'src/app/base/domain/posto.model';
import { LazyLoadEvent } from 'primeng/api';

export interface EquipeAcaoEvent {
  acao: string;
  equipe: Equipe;
}

export interface EquipeListFilterOptions {
  nomeEquipe: string;
  nomeFiscal: string;
  postoSelecionado: Posto;
}

@Component({
  selector: 'app-equipe-list',
  templateUrl: './equipe-list.component.html',
  styleUrls: ['./equipe-list.component.css'],
})
export class EquipeListComponent {
  @Input() title: string;
  @Input() equipes: Equipe[];
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() filtros: string[];

  @Output() filter = new EventEmitter<EquipeListFilterOptions>();
  @Output() loadEquipesPage = new EventEmitter<number>();
  @Output() acaoClick = new EventEmitter<EquipeAcaoEvent>();
  @Output() disclosureEquipe = new EventEmitter<Equipe>();

  private currentPage = 0;
  queryOptions = new QueryOptions();

  private readonly defaultFilterOptions: EquipeListFilterOptions = {
    nomeEquipe: '',
    nomeFiscal: '',
    postoSelecionado: null,
  };

  filterOptions: EquipeListFilterOptions;

  ngOnInit(): void {
    this.onLimparFiltros();
  }

  loadEquipes(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadEquipesPage.emit(pageNumber);
  }

  onDisclosure(equipe: Equipe): void {
    this.disclosureEquipe.emit(equipe);
  }

  onAcaoClick(acao: string, domain: Equipe): void {
    this.acaoClick.emit({ acao, equipe: domain });
  }

  onAtualizar(): void {
    this.loadEquipesPage.emit(this.currentPage);
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }
}
