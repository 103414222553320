import { Component, OnInit, Input } from '@angular/core';
import { Contrato } from 'src/app/base/domain/contrato.model';
import { Cliente } from 'src/app/base/domain/cliente.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClienteService } from 'src/app/base/services/cliente.service';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ContratoService } from 'src/app/base/services/contrato.service';
import { DialogService } from 'src/app/global/services/dialog.service';
import { ContratoListFilterOptions } from 'src/app/global/lists/contrato-list/contrato-list.component';
import { ContaCliente } from 'src/app/base/domain/conta-cliente.model';

@Component({
  selector: 'app-cliente-detail',
  templateUrl: './cliente-detail.component.html',
  styleUrls: ['./cliente-detail.component.css'],
})
export class ClienteDetailComponent implements OnInit {
  clienteId: number;
  cliente: Cliente;
  title = 'Contratos do Cliente';

  contratos: Contrato[] = [];

  contas: ContaCliente[] = [];
  contaCorrente: ContaCliente;
  tipoContas: string[] = [];

  queryOptions = new QueryOptions({ pageSize: 10 });
  totalRecords = 0;
  loading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private clienteService: ClienteService,
    private contratoService: ContratoService,
    private dialogService: DialogService,
  ) {
    this.route.params.subscribe((params: Params) => {
      this.clienteId = params.id;
    });
  }

  ngOnInit(): void {
    this.queryOptions.params = {
      idCliente: this.clienteId || null,
    };
    this.consultarDetalhesCliente();
    this.listarContratos();
  }

  private consultarDetalhesCliente(): void {
    this.clienteService.findOne(this.clienteId).subscribe((response: ReturnAPI<Cliente>) => {
      if (response.success) {
        this.cliente = response.object;
        this.contas = this.cliente.contas;
        this.listarContas();
      }
    });
  }

  listarContratos(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.queryOptions.pageSort = '-id';
    this.loading = true;
    this.contratoService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Contrato>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.contratos = response.object.content;
      } else {
        this.contratos = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
    });
  }

  listarContas(): void {
    this.contas?.forEach((conta: ContaCliente) => {
      if (conta.tipo === 'CORRENTE') {
        this.contaCorrente = conta;
        this.tipoContas.push('Conta Corrente');
      }
    });
  }

  onClienteUpdated(_: Cliente): void {
    this.consultarDetalhesCliente();
    this.listarContratos();
  }

  onContratosPage(page: number): void {
    this.listarContratos(page);
  }

  onDisclosure(contrato: Contrato): void {
    this.router.navigate(['base/contratos/acompanhamento-contrato-detail/' + contrato.id]);
  }

  onFilter(options: ContratoListFilterOptions): void {
    this.queryOptions.params = {
      nomeCliente: options.nomeCliente,
      situacaoContrato: options.situacoesContrato?.length ? `in:${options.situacoesContrato.join(',')}` : null,
      postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
      repassePendente: options.repassePendente || null,
    };
    this.listarContratos();
  }
}
