import { Observable } from 'rxjs/Observable';
import { QueryOptions } from './../../../../global/domain/query.options';
import { ContratoService } from 'src/app/base/services/contrato.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Posto } from 'src/app/base/domain/posto.model';
import { Funcionario } from '../../../domain/funcionario.model';

export interface QueryFilter {
  dataInicial: Date;
  dataFinal: Date;
  posto: Posto;
  funcionario: Funcionario
}

export interface MotivoCancelamento {
  qtdContratos: number;
  valorTotal: number;
  percentual: number;
  motivoDesistenciaContrato: MotivoDesistenciaContrato;
}

export interface MotivoDesistenciaContrato {
  id: number;
  descricao: string;
}

export interface DashBoardMotivos {
  qtdTotal: number;
  valorTotal: number;
  motivos: MotivoCancelamento[];
}

@Component({
  selector: 'app-motivo-contrato-cancelado-main',
  templateUrl: './motivo-contrato-cancelado-main.component.html',
  styleUrls: ['./motivo-contrato-cancelado-main.component.css']
})
export class MotivoContratoCanceladoMainComponent implements OnInit {

  dataInicial: Date;
  dataFinal: Date;

  private readonly defaultQueryFilter: QueryFilter = {
    dataFinal: null,
    dataInicial: null,
    posto: null,
    funcionario: null
  }

  dashboard: DashBoardMotivos = {
    qtdTotal: 0,
    valorTotal: 0,
    motivos: []
  };

  queryOptions: QueryOptions = new QueryOptions();

  filterOptions: QueryFilter = this.defaultQueryFilter;

  constructor(private contratoService: ContratoService) { }

  ngOnInit(): void {
    this.setDefaultFilter();
    this.getMotivoDesistenciaDashboard(this.queryOptions);
  }

  onLimparFiltros(): void {
    this.setDefaultFilter();
    this.getMotivoDesistenciaDashboard(this.queryOptions);
  }

  onAplicarFiltro(): void {
    this.queryOptions.params = {
      dataInicial: this.filterOptions.dataInicial ? this.filterOptions.dataInicial.getTime() : new Date().getTime(),
      dataFinal: this.filterOptions.dataFinal ? this.filterOptions.dataFinal.getTime() : new Date().getTime(),
      postoId: this.filterOptions.posto ? this.filterOptions.posto.id : null,
    }
    this.getMotivoDesistenciaDashboard(this.queryOptions);
    this.dataInicial = this.filterOptions.dataInicial;
    this.dataFinal = this.filterOptions.dataFinal;
  }

  getMotivoDesistenciaDashboard(options: QueryOptions) {
    this.contratoService.getMotivoDesistenciaContratosPorPeriodo(options).subscribe(data => {
      if(data.success) {
        this.dashboard.motivos = data.object;
        this.dashboard.motivos = this.dashboard.motivos.filter(m => m.qtdContratos > 0);
        this.dashboard.qtdTotal = this.dashboard.motivos.length > 0 ? this.dashboard.motivos.map(m => m.qtdContratos).reduce((acc, cur) => acc + cur) : 0;
        this.dashboard.valorTotal = this.dashboard.motivos.length > 0 ? this.dashboard.motivos.map(m => m.valorTotal).reduce((acc, cur) => acc + cur) : 0;
      }
    });
  }

  setDefaultFilter() {
    this.filterOptions = Object.assign({}, this.defaultQueryFilter);
    this.queryOptions.params = {
      dataInicial: this.filterOptions.dataInicial ? this.filterOptions.dataInicial.getTime() : new Date().getTime(),
      dataFinal: this.filterOptions.dataFinal ? this.filterOptions.dataFinal.getTime() : new Date().getTime(),
      postoId: this.filterOptions.posto ? this.filterOptions.posto.id : null,
    }
    this.dataInicial = new Date();
    this.dataFinal = new Date();
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  dateMask(value: Date) {
    return value ? value.toLocaleDateString('pt-BR') : new Date().toLocaleDateString('pt-BR');
  }
}
