import { SharedService } from './../../../auth/shared.service';
import { AlterarValorContratoDTO } from './../../../base/domain/dto/alterar-valor-contrato-dto';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contrato } from 'src/app/base/domain/contrato.model';
import { CalculoValorLiquidoDTO } from 'src/app/base/domain/dto/calculo-valor-liquido.dto';
import { ContratoService } from 'src/app/base/services/contrato.service';
import { ReturnAPI } from 'src/app/base/domain/return-api.model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-alterar-valor-contrato-form',
  templateUrl: './alterar-valor-contrato-form.component.html',
  styleUrls: ['./alterar-valor-contrato-form.component.css']
})
export class AlterarValorContratoFormComponent implements OnInit {

  dto: AlterarValorContratoDTO = { idUsuarioHeimdall: null, idContrato: null, valorBruto: null, valorLiquido: null, quantidadeParcelas: null };
  contrato: Contrato;

  inputPlaceholder = 'Aumento';
  diferenca = 0;

  calcularDiferencaSubject: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Contrato,
    public dialogRef: MatDialogRef<AlterarValorContratoFormComponent>,
    private contratoService: ContratoService) {
    this.calcularDiferencaSubject.pipe(debounceTime(1500)).subscribe(() => this.processNovoValor());
  }

  ngOnInit(): void {
    this.contrato = this.data;
    const shared = SharedService.getInstance();

    this.dto.idUsuarioHeimdall = shared.usuario.id;
    this.dto.idContrato = this.contrato.id;
    this.dto.valorBruto = this.contrato.valorBruto;
    this.dto.valorLiquido = this.contrato.valorLiquido;
    this.dto.quantidadeParcelas = this.contrato.visita.quantidadeParcelas;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(this.dto);
  }

  formChange(): void {
    this.calcularDiferencaSubject.next();
  }

  async processNovoValor(): Promise<void> {
    const requestBody: CalculoValorLiquidoDTO = {
      valorAprovado: this.dto.valorBruto,
      tipoContrato: this.contrato.visita.tipoContrato,
      quantidadeParcelas: this.dto.quantidadeParcelas,
      pagamentoBoleto: this.contrato.visita.pagamentoBoleto,
      pagamentoCartao: this.contrato.visita.pagamentoCartao,
      pagamentoDinheiro: this.contrato.visita.pagamentoDinheiro,
      pagamentoPix: this.contrato.visita.pagamentoPix,
      taxaJuros: this.contrato.visita.taxaJuros
    };

    await this.contratoService.calcularValorLiquido(requestBody).toPromise()
      .then((response: ReturnAPI<number>) => {
        if (response) {
          this.dto.valorLiquido = parseFloat(response.object.toFixed(2));
        }
      })
      .then(() => {
        const diferenca = parseFloat((this.dto.valorLiquido - this.contrato.valorLiquido).toFixed(2));
        if (diferenca === 0) {
          this.inputPlaceholder = 'Diferença: ';
          this.diferenca = diferenca;
        } else if (diferenca > 0) {
          this.inputPlaceholder = `Aumento: R$${this.contrato.valorLiquido} > R$${this.dto.valorLiquido} = R$${diferenca}`;
          this.diferenca = diferenca;
        } else {
          this.inputPlaceholder = `Diminuição: R$${this.contrato.valorLiquido} > R$${this.dto.valorLiquido} = R$${diferenca * -1}`;
          this.diferenca = diferenca * -1;
        }
      });
  }
}
