import { Resource } from '../../global/domain/resource.model';
import { Funcionario } from './funcionario.model';
import { Cliente } from './cliente.model';
import { Caixa } from './caixa.model';
import { Contrato } from './contrato.model';
import { Posto } from './posto.model';
import { Parcela } from './parcela.model';

export class AuditoriaAlterarParcela extends Resource {
  dataOperacao: Date;
  funcionario: Funcionario;
  operador: Funcionario;
  cliente: Cliente;
  caixaFuncionario: Caixa;
  caixaRepasse: Caixa;
  contrato: Contrato;
  contratoOrigem: Contrato;
  posto: Posto;
  parcela: Parcela;
  quantidadeAlteracoesDessaParcela: number;
  totalAlterado: number;
}
