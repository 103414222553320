import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { WEB_API } from 'src/app/global/services/web-api';
import { Bairro } from '../domain/bairro.model';
import { EnderecoAPIDTO } from '../domain/dto/endereco-api.dto';
import { Estado } from '../domain/estado.model';
import { Feriado } from '../domain/feriado.model';
import { Municipio } from '../domain/municipio.model';
import { Produto } from '../domain/produto.model';
import { Page, ReturnAPI } from '../domain/return-api.model';

@Injectable()
export class PublicService {
  static RESOURCE = 'public';
  produto: Produto;
  produtoId: string;

  constructor(private httpClient: HttpClient) { }

  listarFeriados(queryOption?: QueryOptions): Observable<ReturnAPI<Page<Feriado>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Feriado>>>(
      `${WEB_API}/${PublicService.RESOURCE}/feriado?${this.produtoId}&${queryOption?.toQueryString() || ''}`,
    );
  }

  listarEstados(queryOption?: QueryOptions): Observable<ReturnAPI<Page<Estado>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Estado>>>(
      `${WEB_API}/${PublicService.RESOURCE}/estado?${this.produtoId}&${queryOption?.toQueryString() || ''}`,
    );
  }

  listarMunicipios(queryOption?: QueryOptions): Observable<ReturnAPI<Page<Municipio>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Municipio>>>(
      `${WEB_API}/${PublicService.RESOURCE}/municipio?${this.produtoId}&${queryOption?.toQueryString() || ''}`,
    );
  }

  listarBairros(queryOption?: QueryOptions): Observable<ReturnAPI<Page<Bairro>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Bairro>>>(
      `${WEB_API}/${PublicService.RESOURCE}/bairro?${this.produtoId}&${queryOption?.toQueryString() || ''}`,
    );
  }

  findEnderecoByCep(cep: string): Observable<ReturnAPI<EnderecoAPIDTO>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<EnderecoAPIDTO>>(`${WEB_API}/${PublicService.RESOURCE}/endereco-por-cep?${this.produtoId}&cep=${cep}`);
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }
}
