import { Funcionario } from '../funcionario.model';
import { Rota } from '../rota.model';

export class AlterarContratoDTO {
  quantidadeParcelas: number;
  valorBruto: number;
  contratoId: number;
  uidContrato: string;
  rota: Rota;
  dataEmissao: Date;
  validarAumentoCemPorCento: boolean;
  idFuncionario: number;
  nomeFuncionario: string;
  dataOperacao: Date;
}
