import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppState } from 'src/app/App.state';
import { DashboardCobranca } from 'src/app/base/domain/dashboard-cobranca.model';
import { DashBoardEstadoFaturamento } from 'src/app/base/domain/dashboard-estado-faturamento.model';
import { PostoDTO } from 'src/app/base/domain/dto/posto.dto';
import { DashBoardCobrancaService } from 'src/app/base/services/dashboard-cobranca.service';
import { DashboardCobrancaFilterOptions } from 'src/app/global/cobranca-dashboard/cobranca-dashboard.component';
import { QueryOptions } from 'src/app/global/domain/query.options';

@Component({
  selector: 'app-acompanhamento-estado-cobranca',
  templateUrl: './acompanhamento-estado-cobranca.component.html',
  styleUrls: ['./acompanhamento-estado-cobranca.component.css'],
})
export class AcompanhamentoEstadoCobrancaComponent implements OnInit {
  dashboard: DashboardCobranca = new DashboardCobranca();

  data = new Date();
  estadoId: number;
  nomeEstado = '';
  title = '';

  dashboardEstado: DashBoardEstadoFaturamento = new DashBoardEstadoFaturamento();
  postoList: PostoDTO[] = [];

  queryOptions = new QueryOptions({ pageSize: 10 });

  constructor(private service: DashBoardCobrancaService, private router: Router, private route: ActivatedRoute, public appState: AppState) {
    this.route.params.subscribe((params: Params) => {
      this.estadoId = params.id;
    });

    this.nomeEstado = this.route.snapshot.queryParamMap.get('nomeEstado').toString();
  }

  ngOnInit(): void {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    this.data = date;
    this.queryOptions.params = {
      dataInicial: this.route.snapshot.queryParamMap.get('dataInicial')
        ? this.route.snapshot.queryParamMap.get('dataInicial')
        : date.getTime(),
      dataFinal: this.route.snapshot.queryParamMap.get('dataFinal') ? this.route.snapshot.queryParamMap.get('dataFinal') : date.getTime(),
      estadoId: this.estadoId,
    };

    this.getDashboard();
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  onReload(): void {
    this.data.setHours(0, 0, 0, 0);
    this.appState.isLoading = true;
    this.service.getDashboard(this.queryOptions).subscribe((response: DashboardCobranca) => {
      this.dashboard = response;
      this.title = `Acompanhamento de Cobrança do ${this.nomeEstado}.`;
      this.getDashboardEstado();
    });
  }

  getDashboard(): void {
    this.data.setHours(0, 0, 0, 0);
    this.appState.isLoading = true;
    this.service.getDashBoardWithoutRefresh(this.queryOptions).subscribe((response: DashboardCobranca) => {
      this.dashboard = response;
      this.title = `Acompanhamento de Cobrança do ${this.nomeEstado}.`;
      this.getDashboardEstado();
    });
  }

  getDashboardEstado(): void {
    this.service.getDashboardEstado(this.estadoId).subscribe((response: DashBoardEstadoFaturamento) => {
      this.dashboardEstado = response;
      this.postoList = this.dashboardEstado.postos;
      this.appState.isLoading = false;
    });
  }

  onDisclosure(dashboard: PostoDTO): void {
    this.queryOptions.params = {
      ...this.queryOptions.params,
      descricaoPosto: dashboard.descricaoPosto ? dashboard.descricaoPosto : null,
    };

    this.router.navigate(['base/gestao/acompanhamento-cobranca/geral-cobranca/acompanhamento-posto-cobranca/' + dashboard.id], {
      queryParams: this.queryOptions.params
    });
  }

  onFilter(options: DashboardCobrancaFilterOptions): void {
    this.queryOptions.params = {
      postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
      estadoId: options.estadoSelecionado ? options.estadoSelecionado.id : this.estadoId,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
      tipos: options.situacoes.length ? options.situacoes.toString() : null
    };

    this.getDashboard();
  }
}
