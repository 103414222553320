import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalModule } from 'src/app/global/global.module';
import { AcompanhamentoPendenciaMainComponent } from './acompanhamento-pendencia-main/acompanhamento-pendencia-main.component';

@NgModule({
  declarations: [AcompanhamentoPendenciaMainComponent],
  imports: [CommonModule, GlobalModule],
})
export class AcompanhamentoPendenciaModule {}
