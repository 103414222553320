import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ArquivoVisita } from 'src/app/base/domain/arquivo-visita.model';
import { ImagemGalery } from 'src/app/base/domain/imagem-galery.model';
import { ReturnAPI } from 'src/app/base/domain/return-api.model';
import { ArquivoVisitaService } from 'src/app/base/services/arquivo-visita.service';

@Component({
  selector: 'app-galery',
  templateUrl: './galery.component.html',
  styleUrls: ['./galery.component.css'],
})
export class GaleryComponent implements OnInit, OnChanges {

  @Input() idGalery: number;
  configuredData: ImagemGalery[];

  constructor(private arquivoVisitaService: ArquivoVisitaService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.idGalery.currentValue != null) {
      this.configuredData = [];
      this.arquivoVisitaService.findByVisita(this.idGalery).subscribe(
        (response: ReturnAPI<ArquivoVisita[]>) => {
        this.mounthItems(response.object).then((r: ImagemGalery[]) => {
          this.configuredData = r;
          this.abrirGaleria();
        });
      });
    }
  }

  abrirGaleria(): void {
    document.getElementById('principal').style.display = 'block';
  }

  restore(): void {
    document.getElementById('principal').style.display = 'none';
  }

  async mounthItems(arquivo: ArquivoVisita[]): Promise<ImagemGalery[]> {
    const paths: string[] = await arquivo.map((obj: ArquivoVisita) => this.arquivoVisitaService.findByCodigoInContext(obj.codigo));
    return paths.map((path: string) => ({ previewImageSrc: path, thumbnailImageSrc: path }) as ImagemGalery);
  }

}
