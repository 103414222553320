import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from 'src/app/App.state';
import { AuditoriaConferenciaCaixaGeral } from 'src/app/base/domain/auditoria-conferencia-caixa-geral.model';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { AuditoriaConferenciaCaixaGeralService } from 'src/app/base/services/auditoria-conferencia-caixa-geral.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import {
  AuditoriaConferenciaCaixaGeralListFilterOptions,
  AuditoriaConferenciaCaixaGeralAcaoEvent,
} from 'src/app/global/lists/auditoria-conferencia-caixa-geral-list/auditoria-conferencia-caixa-geral-list.component';
import { DialogService } from 'src/app/global/services/dialog.service';

@Component({
  selector: 'app-acompanhamento-conferencia-caixa-geral-main',
  templateUrl: './acompanhamento-conferencia-caixa-geral-main.component.html',
  styleUrls: ['./acompanhamento-conferencia-caixa-geral-main.component.css'],
})
export class AcompanhamentoConferenciaCaixaGeralMainComponent implements OnInit {
  auditorias: AuditoriaConferenciaCaixaGeral[];
  totalRecords = 0;

  queryOptions = new QueryOptions({ pageSize: 10, pageSort: '-id' });

  private postoSelecionado: number = null;
  private dataInicialSelecionada: Date = null;
  private dataFinalSelecionada: Date = null;

  constructor(
    private service: AuditoriaConferenciaCaixaGeralService,
    public dialog: MatDialog,
    public appState: AppState,
    public dialogService: DialogService,
  ) {}

  ngOnInit(): void {}

  mapearAcoes(auditorias: AuditoriaConferenciaCaixaGeral[]): void {
    const mapearAcoes = (feriado: AuditoriaConferenciaCaixaGeral): AuditoriaConferenciaCaixaGeral => {
      const acoes = [];
      return { ...feriado, acoes };
    };
    this.auditorias = auditorias?.map(mapearAcoes) ?? [];
  }

  list(page: number = 0): void {
    this.queryOptions.pageNumber = page + 1;
    this.appState.isLoading = true;
    this.service.findAll(this.queryOptions).subscribe(
      (response: ReturnAPI<Page<AuditoriaConferenciaCaixaGeral>>) => {
        if (response.success) {
          this.mapearAcoes(response.object.content);
          this.totalRecords = response.object.totalElements;
        }
        this.appState.isLoading = false;
      },
      () => (this.appState.isLoading = false),
    );
  }

  onLoad(pageNumber: number): void {
    this.list(pageNumber);
  }

  onAcaoClick(event: AuditoriaConferenciaCaixaGeralAcaoEvent): void {
    const actions = new Map<string, (auditoria: AuditoriaConferenciaCaixaGeral) => void>();

    actions.get(event.acao)(event.auditoriaConferenciaCaixaGeral);
  }

  onFilter(options: AuditoriaConferenciaCaixaGeralListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    const dataInicial = options.dataInicial || null;
    const dataFinal = options.dataFinal || null;
    this.queryOptions.params = {
      ...this.queryOptions.params,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
      postoId,
    };

    this.list();

    if (dataInicial !== this.dataInicialSelecionada || dataFinal !== this.dataFinalSelecionada) {
      this.dataInicialSelecionada = dataInicial;
      this.dataFinalSelecionada = dataFinal;
    }

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }
  }
}
