import { Observable } from 'rxjs/Observable';
import { SharedService } from 'src/app/auth/shared.service';
import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from 'src/app/App.state';
import { AcompanhamentoCobrancasTurnoDTO } from 'src/app/base/domain/dto/acompanhamento-cobrancas-turno.dto';
import { DashBoardCobrancaService } from 'src/app/base/services/dashboard-cobranca.service';
import { MixedChartTemplate } from 'src/app/global/components/graficos/mixed-chart/mixed-chart.component';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { DialogService } from 'src/app/global/services/dialog.service';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-acompanhamento-cobranca-turno-nacional',
  templateUrl: './acompanhamento-cobranca-turno-nacional.component.html',
  styleUrls: ['./acompanhamento-cobranca-turno-nacional.component.css']
})
export class AcompanhamentoCobrancaTurnoNacionalComponent implements OnInit, AfterViewChecked {

  filterOptions = { dataInicial: new Date(), dataFinal: new Date() };

  labels = ['Manhã', 'Tarde', 'Noite'];
  barChartLabel = 'Quantidade de Clientes';
  lineChartLabel = 'Quantidade Cobrados';

  templates: MixedChartTemplate[] = [];

  rgbaColors: string[] = [
    'rgba(255, 0, 0, 0.4)',
    'rgba(255, 100, 0, 0.4)',
    'rgba(255, 220, 0, 0.4)',
    'rgba(110, 225, 0, 0.4)',
    'rgba(95, 210, 215, 0.4)',
    'rgba(10, 55, 235, 0.4)'
  ];

  rgbColors: string[] = [
    'rgb(255, 0, 0)',
    'rgb(255, 100, 0)',
    'rgb(255, 220, 0)',
    'rgb(110, 225, 0)',
    'rgb(95, 210, 215)',
    'rgb(10, 55, 235)'
  ];

  usuario: Usuario;

  constructor(
    private service: DashBoardCobrancaService,
    private router: Router,
    private appState: AppState,
    private dialogService: DialogService) {
    this.usuario = SharedService.getInstance().usuario;
  }

  ngOnInit(): void {
    this.loadDashBoardNacional();
    if (this.usuario.profile === 'ROLE_RETAGUARDA' || this.usuario.profile === 'ROLE_FISCAL') {
      this.navigateToDetails(this.usuario.estados[0].id);
    }
  }

  ngAfterViewChecked(): void {
    document.querySelectorAll<HTMLDivElement>('div.card-icon').forEach((o: HTMLDivElement, index: number) => {
      o.style.background = this.rgbColors[index];
    });
  }

  loadDashBoardNacional(): void {
    const templates = [];
    this.appState.isLoading = true;
    const options: QueryOptions = new QueryOptions({
      params: {
        datas: `${this.filterOptions.dataInicial.getTime()},${this.filterOptions.dataFinal.getTime()}`
      }
    });
    this.service.getDashBoardTurnoNacional(options).subscribe((response: AcompanhamentoCobrancasTurnoDTO[]) => {
      if (response) {
        response.forEach((o: AcompanhamentoCobrancasTurnoDTO, index: number) => {
          const estado: MixedChartTemplate = {
            canvas: `${o.estado}`,
            elementIndex: index,
            labels: this.labels,
            heigth: 90,
            maxHeigth: '300px',
            barChartLabel: this.barChartLabel,
            barData: [o.clientesManha, o.clientesTarde, o.clientesNoite],
            barBorderColor: this.rgbColors[index],
            barBackgroundColor: this.rgbaColors[index],
            lineChartLabel: this.lineChartLabel,
            lineData: [o.cobradosManha, o.cobradosTarde, o.cobradosNoite],
            lineBorderColor: 'rgb(0, 0, 0)',
            estadoId: o.estadoId,
            estado: o.estado
          };
          templates.push(estado);
        });
      }
      this.templates = templates;
      this.appState.isLoading = false;
    });
  }

  onAplicarFiltro(): void {
    this.loadDashBoardNacional();
  }

  onLimparFiltros(): void {
    this.filterOptions.dataInicial = new Date();
    this.filterOptions.dataFinal = new Date();
    this.loadDashBoardNacional();
  }

  onAtualizar(): void {
    this.appState.isLoading = true;
    this.service.refreshViewCobrancasTurno().subscribe((response: boolean) => {
      if (response) {
        this.loadDashBoardNacional();
      } else {
        this.dialogService.feedbackError(
          'Ocorreu um erro ao atualizar dados'
          + ' Tente novamente mais tarde!'
        );
      }
      this.appState.isLoading = false;
    });
  }

  navigateToDetails(estadoId: number): void {
    const colorIndex = this.templates.filter((o: MixedChartTemplate) => o.estadoId === estadoId).map((o: MixedChartTemplate) => o.elementIndex);
    const estado = this.templates.filter((o: MixedChartTemplate) => o.estadoId === estadoId).map((o: MixedChartTemplate) => o.estado);
    this.router.navigate([`base/gestao/acompanhamento-cobranca/turno-cobranca/${estadoId}`],
      {
        queryParams:
        {
          idx: colorIndex.length > 0 ? colorIndex : 1,
          estado: estado.length > 0 ? estado : this.usuario.estados[0].nome,
          datas: `${this.filterOptions.dataInicial.getTime()},${this.filterOptions.dataFinal.getTime()}`
        }
      }
    );
  }
}
