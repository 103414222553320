import { RegistroAcaoDeVendaDTO } from './../domain/dto/registro-acao-de-venda.dto';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WEB_API } from 'src/app/global/services/web-api';
import { ResourceService } from 'src/app/global/services/resource.service';
import { Contrato } from '../domain/contrato.model';
import { ContratoSerializer } from '../serializables/contrato.serializer';
import { Observable } from 'rxjs';
import { Visita } from '../domain/visita.model';
import { TitulacaoCCB } from '../domain/titulacaoCCB.model';
import { Page, ReturnAPI } from '../domain/return-api.model';
import { Produto } from '../domain/produto.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { AprovacoesDeCreditoPendentesDTO } from '../domain/dto/aprovacoes-de-credito-pendentes-dto';
import { VisitaAgendamentoAcaoDeVendaDTO } from '../domain/dto/visita-agendamento-acao-de-venda.dto';

@Injectable()
export class VisitaService extends ResourceService<Visita> {
  static RESOURCE = 'visita';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, 'contrato', new ContratoSerializer(Contrato));
  }

  alterarTitulacaoCCB(visitaID: number, titulacaoCCB: TitulacaoCCB): Observable<ReturnAPI<Visita>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<Visita>>(
      `${WEB_API}/visita/alterar-titulacao-ccb?visitaId=${visitaID}&${this.produtoId}`,
      titulacaoCCB,
    );
  }

  listarAprovacoesPendentes(options: QueryOptions): Observable<ReturnAPI<AprovacoesDeCreditoPendentesDTO[]>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<AprovacoesDeCreditoPendentesDTO[]>>(
      `${WEB_API}/${VisitaService.RESOURCE}/listar-aprovacoes-pendentes?${options.toQueryString(false)}&${this.produtoId}`,
    );
  }

  pageAprovacoesPendentes(options: QueryOptions): Observable<ReturnAPI<Page<Visita>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Visita>>>(
      `${WEB_API}/${VisitaService.RESOURCE}/page-aprovacoes-pendentes?${options.toQueryString()}&${this.produtoId}`,
    );
  }

  aprovarCredito(visita: Visita): Observable<ReturnAPI<Visita>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<Visita>>(`${WEB_API}/${VisitaService.RESOURCE}/aprovarcredito?${this.produtoId}`, visita);
  }

  preAprovarVisita(visitaId: number): Observable<ReturnAPI<Visita>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<Visita>>(
      `${WEB_API}/${VisitaService.RESOURCE}/pre-aprovar?visitaId=${visitaId}&${this.produtoId}`,
      null,
    );
  }

  rejeitarVisita(visitaId: number): Observable<ReturnAPI<Visita>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<Visita>>(
      `${WEB_API}/${VisitaService.RESOURCE}/rejeitar?visitaId=${visitaId}&${this.produtoId}`,
      null,
    );
  }

  gerarAuditoriaPreAprovacao(visitaId: number): Observable<ReturnAPI<{}>> {
    this.getProduto();
    const user = this.getUsuarioHeimdall();
    return this.httpClient.post<ReturnAPI<{}>>(
      `${WEB_API}/${VisitaService.RESOURCE}/gerar-auditoria-pre-aprovacao-heimdall?visitaId=${visitaId}&${this.produtoId}`,
      user,
    );
  }

  registrarAcoesDeVenda(registro: RegistroAcaoDeVendaDTO): Observable<ReturnAPI<boolean>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<boolean>>(`${WEB_API}/${VisitaService.RESOURCE}/acoes-de-venda?${this.produtoId}`, registro);
  }

  agendarVisitarPorAcaoDeVenda(agendamento: VisitaAgendamentoAcaoDeVendaDTO): Observable<ReturnAPI<Visita>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<Visita>>(
      `${WEB_API}/${VisitaService.RESOURCE}/agendamento-por-acao-de-venda?${this.produtoId}`,
      agendamento,
    );
  }

  agendarMultiplasVisitarPorAcaoDeVenda(agendamentoList: VisitaAgendamentoAcaoDeVendaDTO[]): Observable<ReturnAPI<Visita[]>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<Visita[]>>(
      `${WEB_API}/${VisitaService.RESOURCE}/multiplos-agendamentos-por-acao-de-venda?${this.produtoId}`,
      agendamentoList,
    );
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }

  getUsuarioHeimdall(): { heimdallId: string; heimdallNome: string; heimdallEmail: string } {
    if (sessionStorage.getItem('valhalla-web-st-auth-user')) {
      const data: { id: string; nome: string; email: string } = JSON.parse(sessionStorage.getItem('valhalla-web-st-auth-user'));
      const AuditoriaHeimdall = { heimdallId: data.id, heimdallNome: data.nome, heimdallEmail: data.email };
      return AuditoriaHeimdall;
    }
  }

  gerarFichaCadastral(options: QueryOptions): Observable<Blob> {
    return this.httpClient.get(
      `${WEB_API}/${VisitaService.RESOURCE}/gerar-ficha-cadastral?${options.toQueryString(false)}`,
      {
        responseType: 'blob',
      },
    );
  }

  gerarRelatorioClientesVisitados(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${VisitaService.RESOURCE}/relatorio-cliente-visitado?${options.toQueryString(false)}&${this.produtoId}`, { responseType: 'blob' }
    );
  }

  gerarRelatorioComissao(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${VisitaService.RESOURCE}/relatorio-comissao?${options.toQueryString(false)}&${this.produtoId}`, { responseType: 'blob' }
    );
  }

}
