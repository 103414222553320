import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { QueryOptions } from "src/app/global/domain/query.options";
import { Resource } from "src/app/global/domain/resource.model";
import { ResourceService } from "src/app/global/services/resource.service";
import { WEB_API } from "src/app/global/services/web-api";
import { ArquivoImportacaoNovo } from "../domain/arquivo-importacao-novo.model";
import { Produto } from "../domain/produto.model";
import { Page, ReturnAPI } from "../domain/return-api.model";
import { ArquivoImportacaoNovoSerializer } from "../serializables/arquivo-importacao-novo-serializer";

@Injectable()
export class ArquivoImportacaoNovoService extends ResourceService<ArquivoImportacaoNovo> {
    static RESOURCE = 'arquivo-importacao-novo';
    produto: Produto;
    produtoId: string;

    constructor(private http: HttpClient) {
        super(http, `${WEB_API}`, `${ArquivoImportacaoNovoService.RESOURCE}`, new ArquivoImportacaoNovoSerializer(ArquivoImportacaoNovo))
    }

    findAll(queryOptions: QueryOptions): Observable<ReturnAPI<Page<ArquivoImportacaoNovo>>> {
        this.getProduto();
        return this.httpClient.get<ReturnAPI<Page<ArquivoImportacaoNovo>>>(
            `${WEB_API}/${ArquivoImportacaoNovoService.RESOURCE}?${queryOptions.toQueryString()}&${this.produtoId}`,
        );
    }

    getProduto(): void {
        if (sessionStorage.getItem('produto')) {
            this.produto = JSON.parse(sessionStorage.getItem('produto'));
            this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
        }
    }

}