import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Produto } from 'src/app/base/domain/produto.model';
import { Posto } from 'src/app/base/domain/posto.model';
import { QueryOptions } from '../../domain/query.options';
import { LazyLoadEvent } from 'primeng/api';

export interface AcaoEvent {
  acao: string;
  produto: Produto;
}
export interface ListFilterOptions {
  postoSelecionado: Posto;
}

@Component({
  selector: 'app-produto-list',
  templateUrl: './produto-list.component.html',
  styleUrls: ['./produto-list.component.css'],
})
export class ProdutoListComponent implements OnInit {
  @Input() title: string;
  @Input() nomePlaceholder: string;
  @Input() rowsNumber: number;
  @Input() totalRecords: number;
  @Input() loading: boolean;
  @Input() filtros: string[];
  @Input() options: string[];
  @Input() produtos: Produto[];

  @Output() novo = new EventEmitter<void>();
  @Output() loadPage = new EventEmitter<number>();
  @Output() acaoClick = new EventEmitter<AcaoEvent>();
  @Output() filter = new EventEmitter<ListFilterOptions>();

  private currentPage = 0;

  private readonly defaultFilterOptions: ListFilterOptions = {
    postoSelecionado: null,
  };
  filterOptions: ListFilterOptions;

  queryOptions = new QueryOptions();

  constructor() {
    this.onLimparFiltros();
  }

  ngOnInit(): void {}

  load(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadPage.emit(pageNumber);
  }

  onAcaoClick(acao: string, domain: Produto): void {
    this.acaoClick.emit({ acao, produto: domain });
  }

  onAtualizar(): void {
    this.loadPage.emit(this.currentPage);
  }

  onNovo(): void {
    this.novo.emit();
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
