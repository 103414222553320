import { Injectable } from '@angular/core';
import { Caixa } from '../domain/caixa.model';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { CaixaSerializer } from '../serializables/caixa.serializer';
import { HttpClient } from '@angular/common/http';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { Observable } from 'rxjs';
import { ReturnAPI, Page } from '../domain/return-api.model';
import { PendenciaNaoRegularizadaDTO } from '../domain/dto/pendencia-nao-regularizada.dto';
import { CaixaDTO } from '../domain/dto/caixa.dto';
import { Produto } from '../domain/produto.model';
import { ValoresCaixaRequestDTO } from '../domain/dto/valores-caixa-request.dto';
import { Posto } from '../domain/posto.model';
import { TransferenciaOdin } from '../domain/transferencia.model';

@Injectable()
export class CaixaService extends ResourceService<Caixa> {
  static RESOURCE = 'caixa';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${CaixaService.RESOURCE}`, new CaixaSerializer(Caixa));
  }

  abrirCaixaGeralRetroativo(caixa: CaixaDTO): Observable<ReturnAPI<Caixa>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<Caixa>>(
      `${WEB_API}/${CaixaService.RESOURCE}/abrir-caixa-geral-retroativo?${this.produtoId}`,
      caixa,
    );
  }

  zerarSaldoCaixaCobranca(queryOptions: QueryOptions): Observable<ReturnAPI<Caixa>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<Caixa>>(
      `${WEB_API}/${CaixaService.RESOURCE}/zerar-caixa-cobranca?${queryOptions.toQueryString()}&${this.produtoId}`, null
    );
  }

  pageAll(queryOptions: QueryOptions): Observable<ReturnAPI<Page<Caixa>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Caixa>>>(
      `${WEB_API}/${CaixaService.RESOURCE}?${queryOptions.toQueryString()}&${this.produtoId}`,
    );
  }

  findById(id: number): Observable<ReturnAPI<Caixa>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Caixa>>(`${WEB_API}/${CaixaService.RESOURCE}/findOne?caixaId=${id}&${this.produtoId}`);
  }

  getValorTotalPorTipoCaixa(valoresCaixa: Partial<ValoresCaixaRequestDTO>): Observable<ReturnAPI<number>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<number>>(
      `${WEB_API}/${CaixaService.RESOURCE}/valor-total-por-tipo-caixa?${this.produtoId}`,
      valoresCaixa,
    );
  }

  marcarCaixaDividaComoNaoRegularizado(pendenciaNaoRegularizadaDTO: PendenciaNaoRegularizadaDTO): Observable<ReturnAPI<Caixa>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<Caixa>>(
      `${WEB_API}/${CaixaService.RESOURCE}/marcar-nao-regularizado?${this.produtoId}`,
      pendenciaNaoRegularizadaDTO,
    );
  }

  realizarDepositoParaPosto(posto: Posto, valor: number): Observable<ReturnAPI<TransferenciaOdin>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<TransferenciaOdin>>(`${WEB_API}/${CaixaService.RESOURCE}/deposito-para-posto?${this.produtoId}`, {
      postoUid: posto.uid,
      valor,
    });
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }

  relatorioDespesasDiarias(queryOption: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${CaixaService.RESOURCE}/relatorio-despesa-diaria?${queryOption.toQueryString()}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  monitoramentoDeRepasses(number: number): Observable<any> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<any>>(
      `${WEB_API}/${CaixaService.RESOURCE}/monitoramento-de-repasses/${number}?${this.produtoId}`
    );
  }

  monitoramentoDeLancamentoDeEntrada(idPosto: number): Observable<any> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<any>>(
      `${WEB_API}/${CaixaService.RESOURCE}/dashboard-lancamento-entrada/${idPosto}?${this.produtoId}`
    );
  }
}
