import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Caixa } from 'src/app/base/domain/caixa.model';
import { CaixaService } from 'src/app/base/services/caixa.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { CaixaAcaoEvent, CaixaListFilterOptions } from 'src/app/global/lists/caixa-list/caixa-list.component';
import { DialogService } from 'src/app/global/services/dialog.service';
import { Page, ReturnAPI, MessageAPI } from '../../../domain/return-api.model';
import { Posto } from 'src/app/base/domain/posto.model';
import {
  CheckboxSelectFormComponent,
  CheckboxSelectFormData,
  CheckboxSelectFormResultData,
} from 'src/app/global/forms/checkbox-select-form/checkbox-select-form.component';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { PostoService } from 'src/app/base/services/posto.service';
import { AppState } from 'src/app/App.state';
import { CaixaDTO } from 'src/app/base/domain/dto/caixa.dto';
import { ValoresCaixaRequestDTO } from 'src/app/base/domain/dto/valores-caixa-request.dto';
import { TipoCaixa } from 'src/app/base/domain/enum/tipo-caixa.enum';
import { TransferenciaService } from 'src/app/base/services/transferencia.service';
import { TransferenciaOdin } from 'src/app/base/domain/transferencia.model';

@Component({
  selector: 'app-caixa-geral-main',
  templateUrl: './caixa-geral-main.component.html',
  styleUrls: ['./caixa-geral-main.component.css'],
})
export class CaixaGeralMainComponent implements OnInit {

  private static DISCLOSURE = 'disclosure';
  private static DEPOSITO_PARA_POSTO = 'Depósito para Posto';
  private static ABRIR_CAIXA_GERAL_RETROATIVO = 'Abrir Caixa Geral Retroativo';

  caixas: Caixa[] = [];
  queryOptions = new QueryOptions({ pageSize: 10 });
  totalRecords = 0;
  loading = false;
  type = 'cge';
  menuAcoes: string[] = [];

  totalizador: number;

  private postoSelecionado: number = null;

  data = new Date();

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public appState: AppState,
    public dialogService: DialogService,
    private caixaService: CaixaService,
    private postoService: PostoService,
    private transferenciaService: TransferenciaService,
    private activatedRoute: ActivatedRoute
  ) {
    this.menuAcoes.push(CaixaGeralMainComponent.ABRIR_CAIXA_GERAL_RETROATIVO);
  }

  ngOnInit(): void {
    const date = new Date();
    this.data = date;
    this.queryOptions.params = {
      type: `${this.type}`,
      dataInicial: date.getTime(),
      dataFinal: date.getTime(),
      ...this.activatedRoute.snapshot.queryParams
    };
  }

  list(pageNumber: number = 0, valores: Partial<ValoresCaixaRequestDTO> = {}): void {
    this.loading = true;
    this.appState.isLoading = true;
    this.queryOptions.pageNumber = pageNumber + 1;
    this.queryOptions.pageSort = '-dataHoraAbertura';
    this.data.setHours(0, 0, 0, 0);
    this.caixaService.pageAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Caixa>>) => {
      if (response.success) {
        this.caixas = response.object.content;
        this.totalRecords = response.object.totalElements;
        this.mapearAcoes(response.object.content);
      } else {
        this.caixas = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }

      valores.dataInicial = valores.dataInicial ? valores.dataInicial : this.data;
      valores.dataFinal = valores.dataFinal ? valores.dataFinal : this.data;

      this.getValorTotalPorTipoCaixa({ ...valores, postoId: this.postoSelecionado, tipo: TipoCaixa.CAIXA_GERAL });
      this.loading = false;
      this.appState.isLoading = false;
    });
  }

  mapearAcoes(caixas: Caixa[]): void {
    const mapearAcoes = (caixa: Caixa): Caixa => {
      const acoes = [];
      acoes.push(CaixaGeralMainComponent.DISCLOSURE);
      acoes.push(CaixaGeralMainComponent.DEPOSITO_PARA_POSTO);
      return { ...caixa, acoes };
    };

    this.caixas = caixas?.map(mapearAcoes) ?? [];
  }

  onAcaoClick(event: CaixaAcaoEvent): void {
    const actions = new Map<string, (caixa: Caixa) => void>()
      .set(CaixaGeralMainComponent.DISCLOSURE, (caixa: Caixa) => {
        this.caixaDetail(caixa);
      })
      .set(CaixaGeralMainComponent.DEPOSITO_PARA_POSTO, (caixa: Caixa) => {
        this.realizarDepositoParaPosto(caixa);
      })
      .set(CaixaGeralMainComponent.ABRIR_CAIXA_GERAL_RETROATIVO, (caixa: Caixa) => {
        this.realizarAberturaDoCaixaGeralRetroativo(caixa);
      });

    actions.get(event.acao)(event.caixa);
  }

  caixaDetail(caixa: Caixa): void {
    this.router.navigate(['base/caixas/geral-movimentacoes/' + caixa.id]);
  }

  onLoadCaixaPage(page: number): void {
    this.list(page);
  }

  onFilter(options: CaixaListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    this.queryOptions.params = {
      situacao: options.situacaoCaixa?.length ? `in:${options.situacaoCaixa.join(',')}` : null,
      postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
      type: `${this.type}`,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : date.getTime(),
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
    };
    this.router.navigate(['/base/caixas/geral'], { queryParams: this.queryOptions.params }).then(() => this.list());
    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }
  }

  async realizarDepositoParaPosto(caixa: Caixa): Promise<void> {
    const [valor, file] = await this.dialogService.currencyDialog({
      title: 'Depósito',
      message: `Realizar depósito para o posto ${caixa.posto.descricao} no valor de:`,
      comArquivo: true,
    });
    if (valor) {
      this.appState.isLoading = true;

      const _feedback = async (response: ReturnAPI<TransferenciaOdin>) => {
        if (response.success) {
          this.list();
          if (!file) {
            this.appState.isLoading = false;
            return this.dialogService.feedbackSuccess('Depósito efetuado com sucesso');
          }
          const transferenciaId = response.object.id;
          try {
            const res = await this.transferenciaService.adicionarComprovanteParaTransferenciaOdin(transferenciaId, file).toPromise();
            res.success
              ? this.dialogService.feedbackSuccess('Depósito efetuado com sucesso')
              : this.dialogService.feedbackInfo('Ocorreu um problema ao anexar arquivo, porém o depósito foi efetuado corretamente');
          } catch (error) {
            this.dialogService.feedbackInfo('Ocorreu um problema ao anexar arquivo, porém o depósito foi efetuado corretamente');
          } finally {
            this.appState.isLoading = false;
          }
        } else {
          this.appState.isLoading = false;
          response.messages?.length > 0
            ? this.dialogService.feedbackInfo(response.messages.reduce((prev: string, msg: MessageAPI) => `${prev}\n${msg.text}`, ''))
            : this.dialogService.feedbackError('Houve um erro ao realizar depósito');
        }
      };

      this.caixaService.realizarDepositoParaPosto(caixa.posto, valor).subscribe(_feedback, () => (this.appState.isLoading = false));
    }
  }

  async realizarAberturaDoCaixaGeralRetroativo(caixa: Caixa): Promise<void> {
    let postosEmBusca: Posto[] = await this.consultaPostos('');
    this.dialog
      .open<CheckboxSelectFormComponent, CheckboxSelectFormData, CheckboxSelectFormResultData>(CheckboxSelectFormComponent, {
        data: {
          title: `Selecionar posto do Caixa e o Dia de Abertura.`,
          noCheckbox: true,
          dataPicker: true,
          inputPlaceholder: 'Posto',

          selectableOptions: postosEmBusca.map((posto: Posto) => posto.descricao),
          onAsyncSelectableFilter: async (value: string): Promise<string[]> => {
            postosEmBusca = await this.consultaPostos(value);
            return postosEmBusca.map((posto: Posto) => posto.descricao);
          },
        },
        width: '500px',
      })
      .afterClosed()
      .subscribe((result: CheckboxSelectFormResultData) => {
        this.appState.isLoading = true;
        if (result) {
          const caixaDTO: CaixaDTO = new CaixaDTO();
          caixaDTO.posto = postosEmBusca[result.optionSelected];
          caixaDTO.dataHoraAbertura = result.dataSelecionada.getTime();

          this.caixaService.abrirCaixaGeralRetroativo(caixaDTO).subscribe(
            (response: ReturnAPI<Caixa>) => {
              this.dialogService.feedbackReturnAPI(response, 'Transferencia realizada com sucesso.');
              this.list();
              this.appState.isLoading = false;
            },
            (e: HttpErrorResponse) => {
              this.dialogService.feedbackError(`Houve um erro ao transferir complemento.\n ${e.error.message}`);
              this.appState.isLoading = false;
            },
          );
          this.appState.isLoading = false;
        } else {
          this.appState.isLoading = false;
        }
      });
  }

  async consultaPostos(name: string): Promise<Posto[]> {
    const options = new QueryOptions({ pageSize: 10, pageNumber: 1, params: { descricao: name } });
    const response = await this.postoService.findAll(options).toPromise();
    return response.success ? response.object.content : [];
  }

  getValorTotalPorTipoCaixa(valoresCaixa: Partial<ValoresCaixaRequestDTO>): void {
    this.caixaService.getValorTotalPorTipoCaixa(valoresCaixa).subscribe((response: ReturnAPI<number>) => {
      this.totalizador = response.object || 0;
    });
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
