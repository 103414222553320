import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DashboardCobranca } from 'src/app/base/domain/dashboard-cobranca.model';
import { DashBoardEstadoFaturamento } from 'src/app/base/domain/dashboard-estado-faturamento.model';
import { PostoDTO } from 'src/app/base/domain/dto/posto.dto';
import { Estado } from 'src/app/base/domain/estado.model';
import { Posto } from 'src/app/base/domain/posto.model';

export interface DashboardCobrancaFilterOptions {
  postoSelecionado: Posto;
  estadoSelecionado: Estado;
  dataInicial: Date;
  dataFinal: Date;
  situacoes: string[];
}

@Component({
  selector: 'app-cobranca-dashboard',
  templateUrl: './cobranca-dashboard.component.html',
  styleUrls: ['./cobranca-dashboard.component.css'],
})
export class CobrancaDashboardComponent implements OnInit {
  @Input() title: string;
  @Input() dashboard: DashboardCobranca;
  @Input() list: DashBoardEstadoFaturamento[];
  @Input() typeDashboard: string;

  @Output() filter = new EventEmitter<DashboardCobrancaFilterOptions>();
  @Output() reloadEvent = new EventEmitter<number>();

  @Output() disclosure = new EventEmitter<DashBoardEstadoFaturamento>();
  @Output() disclosureDashBoardEstado = new EventEmitter<PostoDTO>();

  private readonly defaultFilterOptions: DashboardCobrancaFilterOptions = {
    postoSelecionado: null,
    estadoSelecionado: null,
    dataInicial: null,
    dataFinal: null,
    situacoes: ['Físico', 'Digital']
  };

  filterOptions: DashboardCobrancaFilterOptions;

  constructor() {
    this.onLimparFiltros();
  }

  ngOnInit(): void {}

  onAtualizar(): void {
    this.reloadEvent.emit();
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' , minimumFractionDigits: 2, maximumFractionDigits: 2});
  }

  onDisclosure(dashboard: DashBoardEstadoFaturamento): void {
    this.disclosure.emit(dashboard);
  }
}
