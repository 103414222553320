import { AcompanhamentoCobrancasTurnoDTO } from './../domain/dto/acompanhamento-cobrancas-turno.dto';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { DashboardCobranca } from '../domain/dashboard-cobranca.model';
import { DashBoardEstadoFaturamento } from '../domain/dashboard-estado-faturamento.model';
import { DashBoardFaturamento } from '../domain/dashboard-faturamento';
import { DashBoardFaturamentoSerializer } from '../serializables/dashboard-faturamento.serializer';
import { AcompanhamentoCobrancaTurnoCaixaDTO } from '../domain/dto/acompanhamento-cobrancas-turno-caixa.dto';

@Injectable()
export class DashBoardCobrancaService extends ResourceService<DashboardCobranca> {
  static RESOURCE = 'relatorio-cobranca-view';
  static RESOURCE_TURNO = 'acompanhamento-cobrancas-turno';

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${DashBoardCobrancaService.RESOURCE}`, new DashBoardFaturamentoSerializer(DashBoardFaturamento));
  }

  getDashboard(queryOption: QueryOptions): Observable<DashboardCobranca> {
    return this.httpClient.get<DashboardCobranca>(
      `${WEB_API}/${DashBoardCobrancaService.RESOURCE}/dashboard-faturamento?${queryOption.toQueryString()}`,
    );
  }

  getDashBoardWithoutRefresh(queryOption: QueryOptions): Observable<DashboardCobranca> {
    return this.httpClient.get<DashboardCobranca>(
      `${WEB_API}/${DashBoardCobrancaService.RESOURCE}/dashboard-faturamento-without-refresh?${queryOption.toQueryString()}`,
    );
  }

  getDashboardEstado(estadoId: number): Observable<DashBoardEstadoFaturamento> {
    return this.httpClient.get<DashBoardEstadoFaturamento>(
      `${WEB_API}/${DashBoardCobrancaService.RESOURCE}/dashboard-estado-faturamento/${estadoId}`,
    );
  }

  getDashboardEstadoFaturamento(): Observable<DashBoardEstadoFaturamento[]> {
    return this.httpClient.get<DashBoardEstadoFaturamento[]>(`${WEB_API}/${DashBoardCobrancaService.RESOURCE}/resumo-estado-faturamento`);
  }

  refreshViewCobrancasTurno(): Observable<boolean> {
    return this.httpClient.post<boolean>(
      `${WEB_API}/${DashBoardCobrancaService.RESOURCE_TURNO}/refresh`, null);
  }

  getDashBoardTurnoNacional(options: QueryOptions): Observable<AcompanhamentoCobrancasTurnoDTO[]> {
    return this.httpClient.get<AcompanhamentoCobrancasTurnoDTO[]>(
      `${WEB_API}/${DashBoardCobrancaService.RESOURCE_TURNO}?${options.toQueryString(false)}`
    );
  }

  getDashBoardTurnoEstadual(options: QueryOptions, estadoId: number): Observable<AcompanhamentoCobrancasTurnoDTO[]> {
    return this.httpClient.get<AcompanhamentoCobrancasTurnoDTO[]>(
      `${WEB_API}/${DashBoardCobrancaService.RESOURCE_TURNO}/${estadoId}?${options.toQueryString(false)}`
    );
  }

  getDashBoardTurnoRota(options: QueryOptions, estadoId: number, postoId: number, rota: string): Observable<AcompanhamentoCobrancasTurnoDTO> {
    return this.httpClient.get<AcompanhamentoCobrancasTurnoDTO>(
      `${WEB_API}/${DashBoardCobrancaService.RESOURCE_TURNO}/${estadoId}/${postoId}/${rota}?${options.toQueryString(false)}`
    );
  }

  getDashBoardTurnoSintetico(options: QueryOptions): Observable<AcompanhamentoCobrancasTurnoDTO> {
    return this.httpClient.get<AcompanhamentoCobrancasTurnoDTO>(
      `${WEB_API}/${DashBoardCobrancaService.RESOURCE_TURNO}/sintetico?${options.toQueryString(false)}`
    );
  }

  getCobrancasTurno(options: QueryOptions): Observable<AcompanhamentoCobrancaTurnoCaixaDTO[]> {
    return this.httpClient.get<AcompanhamentoCobrancaTurnoCaixaDTO[]>(
      `${WEB_API}/${DashBoardCobrancaService.RESOURCE_TURNO}/cobrancas-turno?${options.toQueryString(false)}`
    );
  }
}
