import { ReturnAPI, Page } from '../../domain/return-api.model';
import { QueryOptions } from '../../../global/domain/query.options';
import { ConciliacaoBancariaTev } from '../../domain/conciliacao-bancaria-tev.model';
import { AppState } from '../../../App.state';
import { DialogService } from '../../../global/services/dialog.service';
import { ParcelaService } from '../../services/parcela.service';
import { ConciliacaoBancariaTevService } from '../../services/conciliacao-bancaria-tev.service';
import {
  ConciliacaoBancariaTevEvent,
  VisualizarConciliacaoBancariaTevFilterOptions,
} from '../../../global/lists/visualizar-conciliacao-tev-list/visualizar-conciliacao-tev-list.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConciliacaoFormComponent } from 'src/app/global/forms/conciliacao-form/conciliacao-form.component';
import { ConciliacaoDTO } from '../../domain/dto/conciliacao.dto';

@Component({
  selector: 'app-conciliacao-tev',
  templateUrl: './conciliacao-tev.component.html',
  styleUrls: ['./conciliacao-tev.component.css'],
})
export class ConciliacaoTevComponent implements OnInit {
  private static DELETE_ACTION = 'Deletar';
  private static EDITAR_ACTION = 'Editar';
  queryOptions = new QueryOptions({ pageSize: 25 });

  page: Page<ConciliacaoBancariaTev> = new Page();
  totalRecords: number = 0;
  valorTotalEntrada: number = 0;
  valorTotalSaida: number = 0;

  loading: boolean = false;

  constructor(
    private service: ConciliacaoBancariaTevService,
    public appState: AppState,
    private dialogService: DialogService,
    private dialog: MatDialog,
  ) {
    const date = new Date();
    date.setHours(0, 0, 0);
    this.queryOptions.params = {
      dataInicio: date.getTime(),
      dataFim: new Date().getTime(),
    };
  }

  ngOnInit(): void { }

  list(pageNumber: number = 0): void {
    this.loading = true;
    this.appState.isLoading = true;
    this.queryOptions.pageNumber = pageNumber;

    const dataInicio: Date = new Date(this.queryOptions.params.dataInicio as number);
    dataInicio.setHours(0, 0, 0);
    const dataFim = new Date(this.queryOptions.params.dataFim as number);
    dataFim.setHours(23, 59, 59);

    const estadoId = this.queryOptions.params.estadoId as number;
    const postoUID = this.queryOptions.params.postoUID as string;
    const processado = this.queryOptions.params.processado as boolean;
    const rotaId = this.queryOptions.params.rotaId as number;

    this.queryOptions.params = {
      dataInicio: dataInicio.getTime(),
      dataFim: dataFim.getTime(),
      estadoId,
      postoUID,
      processado,
      rotaId,
    };

    this.service.findAllPageable(this.queryOptions).subscribe((response: Page<ConciliacaoBancariaTev>) => {
      this.loading = false;
      this.appState.isLoading = false;

      this.totalRecords = response.totalElements;
      this.service.getValorTotalEntrada(this.queryOptions).subscribe((resp: number) => {
        this.valorTotalEntrada = resp;
      });

      this.service.getValorTotalSaida(this.queryOptions).subscribe((resp: number) => {
        this.valorTotalSaida = resp;
      });

      response.content = this.mapearAcoes(response.content);
      this.page = response;
    });
  }

  mapearAcoes(arquivos: ConciliacaoBancariaTev[]): ConciliacaoBancariaTev[] {
    const mapearAcoes = (arquivo: ConciliacaoBancariaTev): ConciliacaoBancariaTev => {
      const acoes = [];
      acoes.push(ConciliacaoTevComponent.EDITAR_ACTION);
      acoes.push(ConciliacaoTevComponent.DELETE_ACTION);
      return { ...arquivo, acoes };
    };

    return arquivos?.map(mapearAcoes) ?? [];
  }

  onFilter(options: VisualizarConciliacaoBancariaTevFilterOptions): void {
    const date = new Date();
    date.setHours(0, 0, 0, 0);

    this.queryOptions.params = {
      dataInicio: options.dataInicial ? options.dataInicial.getTime() : date.getTime(),
      dataFim: options.dataFinal ? options.dataFinal.getTime() : date.getTime(),
      estadoId: options.estadoId,
      postoUID: options.postoUID,
      processado: options.processado,
      rotaId: options.rotaId,
    };

    this.list();
  }

  async onAcaoClick(event: ConciliacaoBancariaTevEvent) {
    const actions = new Map<string, (conciliacao: ConciliacaoBancariaTev) => void>()
      .set(ConciliacaoTevComponent.DELETE_ACTION, (conciliacao: ConciliacaoBancariaTev) => {
        this.dialogService.confirmDialog('Deseja realmente deletar esse registro?', () => this.onDelete(conciliacao));
      })
      .set(ConciliacaoTevComponent.EDITAR_ACTION, (conciliacao: ConciliacaoBancariaTev) => {
        this.onEdit(conciliacao);
      });

    actions.get(event.acao)(event.conciliacao);
  }

  onMenuAcaoClick(event: string) { }

  onEdit(conciliacao: ConciliacaoBancariaTev): void {
    this.dialog
      .open(ConciliacaoFormComponent, {
        data: {
          action: 'update',
          type: 'tev',
          conciliacao: ConciliacaoBancariaTev.toConciliacaoDTO(conciliacao),
        },
        width: '300px',
      })
      .afterClosed()
      .subscribe((result: ConciliacaoDTO) => {
        if (result) {
          let tev = ConciliacaoDTO.toConciliacaoTev(result);

          this.service.create(tev).subscribe(
            () => {
              this.appState.isLoading = false;
              this.dialogService.feedbackSuccess('Operação realizada com sucesso!');
              this.list();
            },
            // tslint:disable-next-line: typedef
            (error) => {
              this.appState.isLoading = false;
              console.error(error);
              this.dialogService.feedbackError('Ocorreu um erro ao criar registro!');
              this.list();
            },
          );
        }
      });
  }

  onDelete(conciliacao: ConciliacaoBancariaTev): void {
    this.service.delete(conciliacao).subscribe((response) => {
      this.dialogService.feedbackInfo('Arquivo apagado.'), this.list();
      (error: HttpErrorResponse) => {
        this.dialogService.feedbackError('Ocorreu um erro ao apagar o registro: ' + error.message);
      };
    });
  }

  onNovo(): void {
    this.dialog
      .open(ConciliacaoFormComponent, {
        data: {
          action: 'novo',
          type: 'tev',
        },
        width: '300px',
      })
      .afterClosed()
      .subscribe((result: ConciliacaoDTO) => {
        if (result) {
          let tev = new ConciliacaoBancariaTev();
          tev.data = result.data;
          tev.name = result.name;
          tev.tipoConciliacaoBancaria = result.tipoConciliacaoBancaria;
          tev.banco = result.banco;
          tev.valor = result.valor;
          tev.identificador = result.identificador;
          tev.conta = result.conta;

          this.service.create(tev).subscribe(
            () => {
              this.appState.isLoading = false;
              this.dialogService.feedbackSuccess('Operação realizada com sucesso!');
              this.list();
            },
            // tslint:disable-next-line: typedef
            (error) => {
              this.appState.isLoading = false;
              console.error(error);
              this.dialogService.feedbackError('Ocorreu um erro ao atualizar registro!');
              this.list();
            },
          );
        }
      });
  }

  onLoadArquivosPage(pageNumber: number): void {
    this.list(pageNumber);
  }
}
