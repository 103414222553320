import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { ClientePlanejamento } from 'src/app/base/domain/planejamento-cliente.model';
import { SituacaoClientePlanejamento } from 'src/app/base/domain/enum/situacao-cliente-planejamento.enum';
import { Posto } from 'src/app/base/domain/posto.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker/datepicker-input-base';
import { Moment } from 'moment';

export interface ClientePlanejamentoListFilterOptions {
  nomeClientePlanejamento: string;
  situacoesClientePlanejamento: string[];
  postoSelecionado: Posto;
  dataInicial: Date;
  dataFinal: Date;
}

@Component({
  selector: 'app-cliente-planejamento-list',
  templateUrl: './cliente-planejamento-list.component.html',
  styleUrls: ['./cliente-planejamento-list.component.css'],
})
export class ClientePlanejamentoListComponent implements OnInit {
  @Input() title: string;
  @Input() clientePlanejamentos: ClientePlanejamento[];
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() loading: boolean;
  @Input() filtros: string[];
  @Input() options: string[];

  @Output() loadClientePlanejamentosPage = new EventEmitter<number>();
  @Output() filter = new EventEmitter<ClientePlanejamentoListFilterOptions>();

  private currentPage = 0;
  private readonly defaultFilterOptions: ClientePlanejamentoListFilterOptions = {
    nomeClientePlanejamento: '',
    situacoesClientePlanejamento: [],
    postoSelecionado: null,
    dataInicial: null,
    dataFinal: null,
  };
  filterOptions: ClientePlanejamentoListFilterOptions;

  situacoes: SituacaoClientePlanejamento[] = Object.values(SituacaoClientePlanejamento);

  constructor() {
    this.onLimparFiltros();
  }

  ngOnInit(): void {}

  loadClientePlanejamentos(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadClientePlanejamentosPage.emit(pageNumber);
  }

  onAtualizar(): void {
    this.loadClientePlanejamentosPage.emit(this.currentPage);
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  onPickerEvent(picker: 'inicial' | 'final', event: MatDatepickerInputEvent<Moment>): void {
    if (picker === 'inicial') {
      this.filterOptions.dataInicial = event.value?.toDate() || null;
    }
    if (picker === 'final') {
      this.filterOptions.dataFinal = event.value?.toDate() || null;
    }
  }
}
