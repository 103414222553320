import { Resource } from 'src/app/global/domain/resource.model';
import { TipoReferenciaArquivoVisita } from './enum/tipo-referencia-arquivo-visita.enum';
import { Visita } from './visita.model';

export class ArquivoVisita extends Resource {
    id: number;
    codigo: string;
    nome: string;
    filePath: string;
    visita: Visita;
    tipoReferenciaArquivoVisita: TipoReferenciaArquivoVisita;
    dataCriacao: Date;
}
