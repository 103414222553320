import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CobrancaClienteRegularFdsMainComponent } from './cobranca-cliente-regular-fds-main/cobranca-cliente-regular-fds-main.component';
import { GlobalModule } from '../../../global/global.module';
import { ComponentsModule } from '../../../global/components/components.module';
import { ButtonModule, SharedModule, TableModule } from 'primeng';
import { MatMenuModule } from '@angular/material/menu';


@NgModule({
  declarations: [CobrancaClienteRegularFdsMainComponent],
  imports: [
    CommonModule, GlobalModule, ComponentsModule, ButtonModule, SharedModule, TableModule, MatMenuModule,
  ],
})
export class CobrancaClienteRegularFdsModule { }
