import { Component, OnInit } from '@angular/core';
import { CategoriaPlanoConta } from 'src/app/base/domain/categoria-plano-conta.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { CategoriaPlanoContaService } from 'src/app/base/services/categoria-plano-conta.service';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from 'src/app/App.state';
import { DialogService } from 'src/app/global/services/dialog.service';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import {
  CategoriaPlanoContaListFilterOptions,
  CategoriaPlanoContaAcaoEvent,
} from 'src/app/global/lists/categoria-plano-conta-list/categoria-plano-conta-list.component';
import {
  CategoriaPlanoContaFormComponent,
  DataType,
} from 'src/app/global/forms/categoria-plano-conta-form/categoria-plano-conta-form.component';
import { CheckboxSelectFormComponent } from 'src/app/global/forms/checkbox-select-form/checkbox-select-form.component';

@Component({
  selector: 'app-categoria-plano-conta-main',
  templateUrl: './categoria-plano-conta-main.component.html',
  styleUrls: ['./categoria-plano-conta-main.component.css'],
})
export class CategoriaPlanoContaMainComponent implements OnInit {
  private static DELETE_ACTION = 'Deletar Categoria';
  private static EDITAR_ACTION = 'Editar Categoria';

  categorias: CategoriaPlanoConta[];
  totalRecords = 0;

  queryOptions = new QueryOptions({ pageSize: 10, pageSort: '-id' });

  private postoSelecionado: number = null;

  constructor(
    private service: CategoriaPlanoContaService,
    public dialog: MatDialog,
    public appState: AppState,
    public dialogService: DialogService,
  ) {}

  ngOnInit(): void {}

  mapearAcoes(categorias: CategoriaPlanoConta[]): void {
    const mapearAcoes = (categoria: CategoriaPlanoConta): CategoriaPlanoConta => {
      const acoes = [];
      acoes.push(CategoriaPlanoContaMainComponent.EDITAR_ACTION);

      return { ...categoria, acoes };
    };
    this.categorias = categorias?.map(mapearAcoes) ?? [];
  }

  listarCategorias(page: number = 0): void {
    this.queryOptions.pageNumber = page + 1;
    this.appState.isLoading = true;
    this.service.pageAll(this.queryOptions).subscribe(
      (response: ReturnAPI<Page<CategoriaPlanoConta>>) => {
        if (response.success) {
          this.mapearAcoes(response.object.content);
          this.totalRecords = response.object.totalElements;
        }
        this.appState.isLoading = false;
      },
      () => (this.appState.isLoading = false),
    );
  }

  onLoadCategorias(pageNumber: number): void {
    this.listarCategorias(pageNumber);
  }

  onDelete(categoria: CategoriaPlanoConta): void {
    this.appState.isLoading = true;
    this.service.delete(categoria).subscribe(
      () => {
        this.appState.isLoading = false;
        this.dialogService.feedbackSuccess('Operação realizada com sucesso!');
        this.listarCategorias();
      },
      // tslint:disable-next-line: typedef
      (error) => {
        this.appState.isLoading = false;
        console.error(error);
        this.dialogService.feedbackError('Ocorreu um erro ao deletar categoria!');
        this.listarCategorias();
      },
    );

    this.appState.isLoading = false;
  }

  onAcaoClick(event: CategoriaPlanoContaAcaoEvent): void {
    const actions = new Map<string, (categoria: CategoriaPlanoConta) => void>()
      .set(CategoriaPlanoContaMainComponent.DELETE_ACTION, (categoria: CategoriaPlanoConta) => {
        this.dialogService.confirmDialog('Deseja realmente deletar essa Categoria?', () => this.onDelete(categoria));
      })
      .set(CategoriaPlanoContaMainComponent.EDITAR_ACTION, (categoria: CategoriaPlanoConta) => {
        this.onEdit(categoria);
      });

    actions.get(event.acao)(event.categoriaPlanoConta);
  }

  onFilter(options: CategoriaPlanoContaListFilterOptions): void {
    this.queryOptions.params = {
      ...this.queryOptions.params,
      codigo: options.codigo ? `${options.codigo}` : null,
      descricao: options.descricao ? `${options.descricao}` : null,
      descricaoPai: options.descricaoPai ? `${options.descricaoPai}` : null,
    };

    this.listarCategorias();
  }

  onEdit(categoria: CategoriaPlanoConta): void {
    this.dialog
      .open(CategoriaPlanoContaFormComponent, {
        data: {
          action: 'update',
          categoria: categoria,
        },
        width: '300px',
      })
      .afterClosed()
      .subscribe((result: CategoriaPlanoConta) => {
        if (result) {
          this.service.update(result).subscribe(
            () => {
              this.appState.isLoading = false;
              this.dialogService.feedbackSuccess('Operação realizada com sucesso!');
              this.listarCategorias();
            },
            // tslint:disable-next-line: typedef
            (error) => {
              this.appState.isLoading = false;
              console.error(error);
              this.dialogService.feedbackError('Ocorreu um erro ao atualizar categoria!');
              this.listarCategorias();
            },
          );
        }
      });
  }

  onNovo(): void {
    this.dialog
      .open(CategoriaPlanoContaFormComponent, {
        data: {
          action: 'novo',
          categoria: null,
        },
        width: '300px',
      })
      .afterClosed()
      .subscribe((result: CategoriaPlanoConta) => {
        if (result) {
          this.service.create(result).subscribe(
            () => {
              this.appState.isLoading = false;
              this.dialogService.feedbackSuccess('Operação realizada com sucesso!');
              this.listarCategorias();
            },
            // tslint:disable-next-line: typedef
            (error) => {
              this.appState.isLoading = false;
              console.error(error);
              this.dialogService.feedbackError('Ocorreu um erro ao deletar categoria!');
              this.listarCategorias();
            },
          );
        }
      });
  }
}
