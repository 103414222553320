import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcompanhamentoCaixasPostosMainComponent } from './acompanhamento-caixas-postos-main/acompanhamento-caixas-postos-main.component';
import { GlobalModule } from 'src/app/global/global.module';

@NgModule({
  declarations: [AcompanhamentoCaixasPostosMainComponent],
  imports: [CommonModule, GlobalModule],
})
export class AcompanhamentoCaixasPostosModule {}
