import { _getOptionScrollPosition } from '@angular/material/core';
import { DomainSerializer } from 'src/app/global/serializables/domain.serializer';
import { PlanejamentoSemanaCaptacao } from '../domain/planejamento-semana-captacao.model';
import { PlanejamentoSemanaCaptacaoService } from '../services/planejamento-semana-captacao.service';

export class PlanejamentoSemanaCaptacaoSerializer extends DomainSerializer<PlanejamentoSemanaCaptacao> {
  fromJson(json: any): PlanejamentoSemanaCaptacao {
    const planejamentoSemana = new PlanejamentoSemanaCaptacao();
    Object.keys(json).forEach((k) => (planejamentoSemana[k] = json[k]));
    return planejamentoSemana;
  }

  toJson(planejamentoSemana: PlanejamentoSemanaCaptacao): any {
    const json: any = {};
    Object.keys(planejamentoSemana).forEach((k) => (json[k] = planejamentoSemana[k]));
    return json;
  }
}
