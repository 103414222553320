import { AfterViewChecked, Component, Input, OnInit } from '@angular/core';
import { ContratoService } from '../../../../services/contrato.service';
import { MatDialog } from '@angular/material/dialog';
import { ListaClientesRotaComponent } from '../lista-clientes-rota/lista-clientes-rota.component';


@Component({
  selector: 'app-dashboard-cobranca-semanal-list',
  templateUrl: './dashboard-cobranca-semanal-list.component.html',
  styleUrls: ['./dashboard-cobranca-semanal-list.component.css'],
})
export class DashboardCobrancaSemanalListComponent implements OnInit, AfterViewChecked {

  @Input() recebimentos;

  private ocultar: string;

  constructor(
    private service: ContratoService,
    public dialog: MatDialog
  ) {
    this.ocultar = 'block';
  }

  ngOnInit(): void {

  }

  reloadPage(): void {
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  semanaSelecionada(estado: string, posto: string, rota: string, diaSemana: string) {
    console.log(`${estado} - ${posto} - ${rota} - ${diaSemana}`);
    this.service.dashboardRecebimentoSemanalListarRecebimentos(estado, posto, rota, diaSemana).subscribe(data => {
      this.dialog.open(ListaClientesRotaComponent, {
        data: {
          cobrancas: data.object,
        },
        width: '800px',
      });
    });
  }

  ngAfterViewChecked(): void {
  }
}
