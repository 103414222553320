import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmacaoDialogComponent } from 'src/app/global/components/confirmacao-dialog/confirmacao-dialog.component';
import { CorrigirPagamentoDiaFormComponent } from 'src/app/global/forms/corrigir-pagamento-dia-form/corrigir-pagamento-dia-form.component';
import { Parcela } from '../../domain/parcela.model';
import { ParcelaService } from '../../services/parcela.service';
import { AppState } from './../../../App.state';
import { QueryOptions } from './../../../global/domain/query.options';
import { ConciliacaoAssociacaoFormComponent } from './../../../global/forms/conciliacao-associacao-form/conciliacao-associacao-form.component';
import { ConciliacaoBancariaEvent } from './../../../global/lists/arquivo-conciliacao-list/arquivo-conciliacao-list.component';
import {
  VisualizarConciliacaoBancariaFilterOptions,
  VisualizarConciliacaoListComponent,
} from './../../../global/lists/visualizar-conciliacao-list/visualizar-conciliacao-list.component';
import { DialogService } from './../../../global/services/dialog.service';
import { ConciliacaoBancaria } from './../../domain/conciliacao-bancaria.model';
import { Page, ReturnAPI } from './../../domain/return-api.model';
import { ConciliacaoBancariaService } from './../../services/conciliacao-bancaria.service';

export interface VisualizarConciliacaoBancariaData {
  conciliacaoBancaria: ConciliacaoBancariaEvent;
}

@Component({
  selector: 'app-visualizar-conciliacao-bancaria',
  templateUrl: './visualizar-conciliacao-bancaria.component.html',
  styleUrls: ['./visualizar-conciliacao-bancaria.component.css'],
})
export class VisualizarConciliacaoBancariaComponent implements OnInit {
  private static REALIZAR_ASSOCIACAO = 'Realizar associação';
  private static CORRIGIR_PAGAMENTO = 'Corrigir pagamento';

  queryOptions = new QueryOptions({ pageSize: 25 });

  page: Page<ConciliacaoBancaria> = new Page();
  totalRecords: number = 0;
  valorTotal: number = 0;

  loading: boolean = false;

  constructor(
    private service: ConciliacaoBancariaService,
    public appState: AppState,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private parcelaService: ParcelaService,
  ) {
    const date = new Date();
    date.setHours(0, 0, 0);
    this.queryOptions.params = {
      dataInicio: date.getTime(),
      dataFim: new Date().getTime(),
    };
  }

  ngOnInit(): void {}

  list(pageNumber: number = 0): void {
    this.loading = true;
    this.appState.isLoading = true;
    this.queryOptions.pageNumber = pageNumber;

    const dataInicio: Date = new Date(this.queryOptions.params.dataInicio as number);
    dataInicio.setHours(0, 0, 0);
    const dataFim = new Date(this.queryOptions.params.dataFim as number);
    dataFim.setHours(23, 59, 59);

    const estadoId = this.queryOptions.params.estadoId as number;
    const postoUID = this.queryOptions.params.postoUID as string;
    const processado = this.queryOptions.params.processado as boolean;
    const rotaId = this.queryOptions.params.rotaId as number;

    this.queryOptions.params = {
      dataInicio: dataInicio.getTime(),
      dataFim: dataFim.getTime(),
      estadoId,
      postoUID,
      processado,
      rotaId,
    };

    this.service.findAllPageable(this.queryOptions).subscribe((response: Page<ConciliacaoBancaria>) => {
      this.loading = false;
      this.appState.isLoading = false;

      this.totalRecords = response.totalElements;
      this.service.getValorTotal(this.queryOptions).subscribe((resp: number) => {
        this.valorTotal = resp;
      });

      response.content = this.mapearAcoes(response.content);
      this.page = response;
    });
  }

  mapearAcoes(arquivos: ConciliacaoBancaria[]): ConciliacaoBancaria[] {
    const mapearAcoes = (arquivo: ConciliacaoBancaria): ConciliacaoBancaria => {
      const acoes = [];
      if (!arquivo.processado) {
        acoes.push(VisualizarConciliacaoBancariaComponent.REALIZAR_ASSOCIACAO);
      }

      if (arquivo.processado && arquivo.valor !== arquivo.valorPago) {
        acoes.push(VisualizarConciliacaoBancariaComponent.CORRIGIR_PAGAMENTO);
      }

      return { ...arquivo, acoes };
    };

    return arquivos?.map(mapearAcoes) ?? [];
  }

  onFilter(options: VisualizarConciliacaoBancariaFilterOptions): void {
    const date = new Date();
    date.setHours(0, 0, 0, 0);

    this.queryOptions.params = {
      dataInicio: options.dataInicial ? options.dataInicial.getTime() : date.getTime(),
      dataFim: options.dataFinal ? options.dataFinal.getTime() : date.getTime(),
      estadoId: options.estadoId,
      postoUID: options.postoUID,
      processado: options.processado,
      rotaId: options.rotaId,
    };

    this.list();
  }

  async onAcaoClick(conciliacaoBancaria: ConciliacaoBancariaEvent) {
    const actions = new Map<string, (conciliacaoBancaria: ConciliacaoBancariaEvent) => void>()
      .set(VisualizarConciliacaoBancariaComponent.REALIZAR_ASSOCIACAO, (conciliacaoBancaria: ConciliacaoBancariaEvent) =>
        this.realizarAssociacao.call(this, conciliacaoBancaria),
      )
      .set(VisualizarConciliacaoBancariaComponent.CORRIGIR_PAGAMENTO, (conciliacaoBancaria: ConciliacaoBancariaEvent) =>
        this.corrigirPagamento.call(this, conciliacaoBancaria),
      );

    actions.get(conciliacaoBancaria.acao)(conciliacaoBancaria);
  }

  async realizarAssociacao(conciliacaoBancaria: ConciliacaoBancariaEvent): Promise<void> {
    const result = await this.dialog
      .open<ConciliacaoAssociacaoFormComponent, VisualizarConciliacaoBancariaData, boolean>(ConciliacaoAssociacaoFormComponent, {
        width: '800px',
        data: {
          conciliacaoBancaria,
        },
      })
      .afterClosed()
      .toPromise();

    if (result) {
      this.dialogService.feedbackSuccess('Arquivo associado com sucesso!', () => {
        this.list();
      });
    }
  }

  async corrigirPagamento(conciliacaoBancaria: ConciliacaoBancariaEvent): Promise<void> {
    const result = await this.dialog
      .open(ConfirmacaoDialogComponent, {
        width: '300px',
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        this.appState.isLoading = true;
        if (result) {
          this.parcelaService.corrigirPagamento(conciliacaoBancaria.arquivo).subscribe(
            (response: ReturnAPI<Parcela>) => {
              this.dialogService.feedbackReturnAPI(response, 'Correção realizado com sucesso.');
              this.appState.isLoading = false;
              this.list();
            },
            (e: HttpErrorResponse) => {
              this.dialogService.feedbackError(`Houve um erro ao corrigir uma lançamento.\n ${e.message}`);
              this.appState.isLoading = false;
            },
          );
        } else {
          this.appState.isLoading = false;
        }
      });
  }

  onMenuAcaoClick(event: string) {
    if (event === VisualizarConciliacaoListComponent.CORRIGIR_PAGAMENTO_DIA) {
      this.dialog
        .open(CorrigirPagamentoDiaFormComponent, {
          width: '500px',
        })
        .afterClosed()
        .subscribe((documento) => {
          if (documento) {
            this.dialogService.feedbackSuccess('Pagamentos corrigidos com sucesso!', () => {
              this.list();
            });
          }
        });
    }
  }

  onLoadArquivosPage(pageNumber: number): void {
    this.list(pageNumber);
  }
}
