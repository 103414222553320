import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Cliente } from 'src/app/base/domain/cliente.model';
import { Socio } from 'src/app/base/domain/socio.model';
import { Telefone } from 'src/app/base/domain/telefone.model';
import { MatDialog } from '@angular/material/dialog';
import { EnderecoFormComponent, EnderecoFormData } from '../../forms/endereco-form/endereco-form.component';
import { Endereco } from 'src/app/base/domain/endereco.model';
import { EquipeRotaFormComponent, EquipeRotaFormData } from '../../forms/equipe-rota-form/equipe-rota-form.component';
import { Rota } from 'src/app/base/domain/rota.model';
import { ClienteService } from 'src/app/base/services/cliente.service';
import { ReturnAPI } from 'src/app/base/domain/return-api.model';
import { AppState } from 'src/app/App.state';
import { DialogService } from '../../services/dialog.service';
import { Equipe } from 'src/app/base/domain/equipe.model';
import { SharedService } from 'src/app/auth/shared.service';
import { Usuario } from 'src/app/base/domain/usuario.model';

type ClienteAction = [string, string];

@Component({
  selector: 'app-cliente-info-geral',
  templateUrl: './cliente-info-geral.component.html',
  styleUrls: ['./cliente-info-geral.component.css'],
})
export class ClienteInfoGeralComponent {
  private static ALTERAR_ENDERECO_ACTION = 'Alterar Endereço';

  @Input() cliente: Cliente;
  @Output() clienteUpdated = new EventEmitter<Cliente>();

  clienteActions: ClienteAction[] = [
    [ClienteInfoGeralComponent.ALTERAR_ENDERECO_ACTION, 'Alterar endereço principal do cliente. A rota do cliente pode ser alterada.'],
  ];

  shared: SharedService;
  usuario: Usuario;

  constructor(
    public dialog: MatDialog,
    public appState: AppState,
    public dialogService: DialogService,
    private clienteService: ClienteService,
  ) {
    const shared = SharedService.getInstance();
    this.usuario = shared.usuario;
  }

  async onAlterarEndereco(): Promise<void> {
    const [enderecoAtual, index] = await this.selecionarEnderecoParaAlteracao();
    const endereco = await this.openEnderecoForm(enderecoAtual);

    if (endereco && JSON.stringify(enderecoAtual) !== JSON.stringify(endereco)) {
      const alterarRota = await this.dialogService.questionDialog(
        'Deseja alterar a rota do cliente? ',
        'Essa operação pode vir a realizar a transferência do cliente e seus contratos para outro posto.',
      );

      if (alterarRota) {
        const rota = await this.openEquipeRotaForm(endereco);
        if (rota) {
          const { equipe } = rota;
          this.alterarEnderecoComRota(endereco, rota, equipe);
        }
      } else {
        this.alterarEndereco(endereco, index);
      }
    }
  }

  private async selecionarEnderecoParaAlteracao(): Promise<[Endereco, number]> {
    let defaultEnderecoIndex = 0;
    if (this.cliente.pessoa.listEndereco.length > 0) {
      const enderecos = this.cliente.pessoa.listEndereco.map((e: Endereco) => `${e.logradouro}, ${e.numero} - ${e.bairro.descricao}`);
      const [_, i] = await this.dialogService.selectDialog('Selecione o endereco a ser alterado', enderecos);
      defaultEnderecoIndex = i;
    }

    const enderecoAtual = this.cliente.pessoa.listEndereco[defaultEnderecoIndex];
    return [enderecoAtual, defaultEnderecoIndex];
  }

  alterarEndereco(endereco: Endereco, index: number): void {
    this.appState.isLoading = true;
    this.cliente.pessoa.listEndereco.splice(index, 1, endereco);
    this.clienteService
      .atualizarEnderecoComRota({ clienteId: this.cliente.id, endereco, alterarRota: false })
      .subscribe((response: ReturnAPI<Cliente>) => {
        this.appState.isLoading = false;
        this.cliente = response.object;
        this.clienteUpdated.emit(this.cliente);
        this.dialogService.feedbackReturnAPI(response, 'Endereço alterado com sucesso!');
      });
  }

  alterarEnderecoComRota(endereco: Endereco, rota: Rota, equipe: Equipe): void {
    this.appState.isLoading = true;
    this.clienteService.atualizarEnderecoComRota({ clienteId: this.cliente.id, endereco, rota, equipe, alterarRota: true }).subscribe(
      (response: ReturnAPI<Cliente>) => {
        this.appState.isLoading = false;
        if (response.success) {
          this.cliente = response.object;
          this.clienteUpdated.emit(this.cliente);
          this.dialogService.feedbackSuccess(
            `Alteração realizada com sucesso. O Cliente agora está sendo administrado pelo posto ${this.cliente.posto.descricao}`,
          );
        } else {
          this.dialogService.feedbackReturnAPI(response, '');
        }
      },
      () => {
        this.appState.isLoading = false;
        this.dialogService.feedbackError('Houve um erro ao realizar a operação');
      },
    );
  }

  private openEquipeRotaForm(endereco: Endereco): Promise<Rota> {
    return this.dialog
      .open<EquipeRotaFormComponent, EquipeRotaFormData, Rota>(EquipeRotaFormComponent, {
        data: { endereco },
        width: '500px',
      })
      .afterClosed()
      .toPromise();
  }

  private openEnderecoForm(enderecoAtual: Endereco): Promise<Endereco> {
    return this.dialog
      .open<EnderecoFormComponent, EnderecoFormData, Endereco>(EnderecoFormComponent, {
        data: {
          endereco: enderecoAtual,
          toEdit: true,
        },
        width: '800px',
      })
      .afterClosed()
      .toPromise();
  }

  onAction(action: ClienteAction): void {
    const actions = new Map<string, () => void>().set(ClienteInfoGeralComponent.ALTERAR_ENDERECO_ACTION, () => this.onAlterarEndereco());
    actions.get(action[0])();
  }

  get tipoPessoa(): string {
    return this.cliente?.pessoa?.type ?? 'pf';
  }

  listTelefoneSocio(socio: Socio): string {
    return socio.listTelefone.reduce((p: string, t: Telefone) => `${p} ${t.numero}`, '');
  }
}
