import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { AnaliseDeRiscoCliente } from '../domain/analise-de-risco-cliente.model';
import { UpdateArquivoAnaliseRiscoDTO } from '../domain/dto/update-arquivo-analise.dto';
import { Produto } from '../domain/produto.model';
import { Page, ReturnAPI } from '../domain/return-api.model';
import { AnaliseDeRiscoClienteSerializer } from '../serializables/analise-de-risco-cliente.serializer';

@Injectable()
export class AnaliseDeRiscoClienteService extends ResourceService<AnaliseDeRiscoCliente> {
  static RESOURCE = 'analise-de-risco-cliente';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${AnaliseDeRiscoClienteService.RESOURCE}`, new AnaliseDeRiscoClienteSerializer(AnaliseDeRiscoCliente));
  }

  save(listAnaliseDeRisco: AnaliseDeRiscoCliente[]): Observable<ReturnAPI<AnaliseDeRiscoCliente>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<AnaliseDeRiscoCliente>>(
      `${WEB_API}/${AnaliseDeRiscoClienteService.RESOURCE}/save?${this.produtoId}`,
      listAnaliseDeRisco,
    );
  }

  saveBulk(analises: UpdateArquivoAnaliseRiscoDTO): Observable<ReturnAPI<AnaliseDeRiscoCliente[]>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<AnaliseDeRiscoCliente[]>>(
      `${WEB_API}/${AnaliseDeRiscoClienteService.RESOURCE}/bulk?${this.produtoId}`,
      analises,
    );
  }

  findAll(queryOptions: QueryOptions): Observable<ReturnAPI<Page<AnaliseDeRiscoCliente>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<AnaliseDeRiscoCliente>>>(
      `${WEB_API}/${AnaliseDeRiscoClienteService.RESOURCE}?${queryOptions.toQueryString()}&${this.produtoId}`,
    );
  }

  findAllPageableInCliente(queryOptions: QueryOptions): Observable<ReturnAPI<Page<AnaliseDeRiscoCliente>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<AnaliseDeRiscoCliente>>>(
      `${WEB_API}/cliente/find-analise-cliente?${queryOptions.toQueryString()}&${this.produtoId}`,
    );
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }
}
