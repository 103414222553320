import { Resource } from "src/app/global/domain/resource.model";
import { CobrancaJudicial } from "./cobranca-judicial.model";
import { SituacaoParcela } from "./enum/situacao-parcela.enum";

export class ParcelaCobrancaJudicial extends Resource {

    ordem: number;

    valor: number;

    valorPago: number;

    dataVencimento: Date;

    dataBaixa: Date;

    situacao: SituacaoParcela;

    cobrancaJudicial: CobrancaJudicial;

}