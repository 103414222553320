import { Resource } from 'src/app/global/domain/resource.model';
import { ContaTev } from './conta-tev.model';
import { ConciliacaoDTO } from './dto/conciliacao.dto';
import { TipoConciliacaoBancaria } from './enum/tipo-conciliacao-bancaria.enum';

export class ConciliacaoBancariaTev extends Resource {
  data: Date;

  name: string;

  identificador: string;

  tipoConciliacaoBancaria: TipoConciliacaoBancaria;

  banco: number;

  valor: number;

  valorPago: number;

  conta: ContaTev;

  processado: boolean;

  public static toConciliacaoDTO(conciliacao: ConciliacaoBancariaTev): ConciliacaoDTO {
    let dto: ConciliacaoDTO = {
      id: conciliacao.id,
      version: conciliacao.version,
      ativo: conciliacao.ativo,
      selecionado: conciliacao.selecionado,
      acoes: conciliacao.acoes,
      data: conciliacao.data,
      name: conciliacao.name,
      identificador: conciliacao.identificador,
      tipoConciliacaoBancaria: conciliacao.tipoConciliacaoBancaria,
      banco: conciliacao.banco,
      valor: conciliacao.valor,
      conta: conciliacao.conta,
      agencia: null,
      processado: conciliacao.processado,
      valorPago: conciliacao.valorPago,
      responsavel: null,
      contaDestino: null,
      contaOrigem: null,
    };
    return dto;
  }
}
