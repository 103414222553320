import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { Produto } from '../domain/produto.model';
import { Page } from '../domain/return-api.model';
import { ConciliacaoBancariaTev } from '../domain/conciliacao-bancaria-tev.model';
import {
    ConciliacaoBancariaTevSerializer
} from '../serializables/conciliacao-bancaria-tev.serializer';
@Injectable()
export class ConciliacaoBancariaTevService extends ResourceService<ConciliacaoBancariaTev> {
  private static RESOURCE = 'conciliacao-bancaria-tev';

  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${ConciliacaoBancariaTevService.RESOURCE}`, new ConciliacaoBancariaTevSerializer(ConciliacaoBancariaTev));
  }

  findAllPageable(queryOptions: QueryOptions): Observable<Page<ConciliacaoBancariaTev>> {
    return this.http.get<Page<ConciliacaoBancariaTev>>(
      `${WEB_API}/${ConciliacaoBancariaTevService.RESOURCE}/pageable?${queryOptions.toQueryString()}`,
    );
  }

  associar(data: {}): Observable<any> {
    return this.http.post<any>(`${WEB_API}/${ConciliacaoBancariaTevService.RESOURCE}/associar`, data);
  }

  getValorTotalEntrada(queryOptions: QueryOptions): Observable<any> {
    return this.http.get<number>(`${WEB_API}/${ConciliacaoBancariaTevService.RESOURCE}/get-valor-total-entrada?${queryOptions.toQueryString()}`);
  }

  getValorTotalSaida(queryOptions: QueryOptions): Observable<any> {
    return this.http.get<number>(`${WEB_API}/${ConciliacaoBancariaTevService.RESOURCE}/get-valor-total-saida?${queryOptions.toQueryString()}`);
  }
}
