import { OrchestratorCliente } from './../../../domain/orchestrator-cliente.model';
import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cliente } from 'src/app/base/domain/cliente.model';
import { ClienteService } from 'src/app/base/services/cliente.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-verificacao-cliente-detail',
  templateUrl: './verificacao-cliente-detail.component.html',
  styleUrls: ['./verificacao-cliente-detail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class VerificacaoClienteDetailComponent implements OnInit {

  nome: string;
  cpf: string;
  cliente: Cliente;

  tokenPEP = null;
  reponsePEP = null;
  temRegistroPEP = false;
  processadoPEP = false;
  errorPEP = false;
  startedPEP = false;
  dataUltimoPEP: Date = null;

  tokenINTERPOL = null;
  responseINTERPOL = null;
  temRegistroINTERPOL = false;
  processadoINTERPOL = false;
  errorINTERPOL = false;
  startedINTERPOL = false;
  dataUltimoINTERPOL: Date = null;

  constructor(
    private dialogRef: MatDialogRef<VerificacaoClienteDetailComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { cliente: Cliente, action: string },
    private clienteService: ClienteService) {
    this.cliente = data.cliente;
    this.nome = data.cliente.nomeCliente;
    this.cpf = data.cliente.pessoa.cpf;
  }

  ngOnInit(): void {
    const options = new QueryOptions({ params: { clienteId: this.cliente.id, clienteUid: this.cliente.uid } });
    this.clienteService.verificarClienteOrchestrator(options).subscribe((response: OrchestratorCliente[]) => {
      response.forEach((o: OrchestratorCliente) => {
        if (o.serviceId === 'pep_search_v2') {
          this.temRegistroPEP = true;
          this.dataUltimoPEP = new Date(o.dataUltimaResposta);
          this.tokenPEP = o.requestToken;
          if (o.statusResponse === 'PROCESSED') {
            this.processadoPEP = true;
            this.reponsePEP = o.response;
          } else if (o.statusResponse === 'ERROR') {
            this.errorPEP = true;
          } else if (o.statusResponse === 'STARTED') {
            this.startedPEP = true;
          }
        } else if (o.serviceId === 'red_notice_interpol') {
          this.temRegistroINTERPOL = true;
          this.dataUltimoINTERPOL = new Date(o.dataUltimaResposta);
          this.tokenINTERPOL = o.requestToken;
          if (o.statusResponse === 'PROCESSED') {
            this.processadoINTERPOL = true;
            this.responseINTERPOL = o.response;
          } else if (o.statusResponse === 'ERROR') {
            this.errorINTERPOL = true;
          } else if (o.statusResponse === 'STARTED') {
            this.startedINTERPOL = true;
          }
        }
      });
    },
      (error: HttpErrorResponse) => {
        this.dialogRef.close(error);
      }
    );
  }

  pesquisar(): void {
    const queryOptions = new QueryOptions({
      params: {
        clienteId: this.cliente.id,
        services: 'pep_search_v2,red_notice_interpol'
      }
    });
    this.clienteService.requestOrchestrator(queryOptions).subscribe((response: OrchestratorCliente[]) => {
      this.dialogRef.close(response);
    });
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  dateMask(date: number): string {
    return new Date(date).toLocaleString();
  }
}
