import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalModule } from 'src/app/global/global.module';
import { EstadoFaturamentoNacionalComponent } from './estado-faturamento-nacional/estado-faturamento-nacional.component';
import { PostoFaturamentoNacionalComponent } from './posto-faturamento-nacional/posto-faturamento-nacional.component';
import { FaturamentoNacionalMainComponent } from './faturamento-nacional-main/faturamento-nacional-main.component';

@NgModule({
  declarations: [EstadoFaturamentoNacionalComponent, PostoFaturamentoNacionalComponent, FaturamentoNacionalMainComponent],
  imports: [CommonModule, GlobalModule],
})
export class AcompanhamentoFaturamentoNacionalModule { }
