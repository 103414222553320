import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from 'src/app/App.state';
import { SharedService } from 'src/app/auth/shared.service';
import { CobrancaJudicial } from 'src/app/base/domain/cobranca-judicial.model';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { CobrancaJudicialService } from 'src/app/base/services/cobranca-judicial.service';
import { ConfirmacaoDialogComponent } from 'src/app/global/components/confirmacao-dialog/confirmacao-dialog.component';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { AlterarEmissaoCobrancaJudicialFormComponent } from 'src/app/global/forms/alterar-emissao-cobranca-judicial-form/alterar-emissao-cobranca-judicial-form.component';
import { AlterarValorCobrancaJudicialFormComponent } from 'src/app/global/forms/alterar-valor-cobranca-judicial-form/alterar-valor-cobranca-judicial-form.component';
import { CobrancaJudicialAcaoEvent, CobrancaJudicialListFilterOptions } from 'src/app/global/lists/cobranca-judicial-list/cobranca-judicial-list.component';
import { DialogService } from 'src/app/global/services/dialog.service';

@Component({
  selector: 'app-cobranca-judicial-main',
  templateUrl: './cobranca-judicial-main.component.html',
  styleUrls: ['./cobranca-judicial-main.component.css']
})
export class CobrancaJudicialMainComponent implements OnInit {

  cobrancas: CobrancaJudicial[] = [];

  shared: SharedService;
  usuario: Usuario;

  queryOptions = new QueryOptions({
    pageSize: 25,
  });

  private static CANCELAR_COBRANCA_JUDICIAL = 'Cancelar uma cobrança judicial';
  private static ALTERAR_DATA_EMISSAO = 'Alterar a data de emissão da cobrança judicial'
  private static ALTERAR_VALOR = 'Alterar o valor da cobrança judicial';

  totalRecords = 0;
  loading = false;

  private postoSelecionado: number = null;

  menuAcoes: string[] = [];

  constructor(
    public appState: AppState,
    public dialogService: DialogService,
    private service: CobrancaJudicialService,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cobrancaJudicialService: CobrancaJudicialService,
    private cdr: ChangeDetectorRef
  ) {
    const shared = SharedService.getInstance();
    this.usuario = shared.usuario;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.activatedRoute.snapshot.queryParams = this.queryOptions.params;
  }

  listar(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.queryOptions.params = { ...this.activatedRoute.snapshot.queryParams, joinRota: true };
    this.service.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<CobrancaJudicial>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.cobrancas = response.object.content;
        this.mapearAcoes(response.object.content);
      } else {
        this.cobrancas = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
    });
  }

  onLoadPage(page: number): void {
    this.listar(page);
  }

  mapearAcoes(cobrancas: CobrancaJudicial[]): void {
    const mapearAcoes = (cobranca: CobrancaJudicial): CobrancaJudicial => {
      const acoes = [];
      if (this.usuario.email == 'admin@gmail.com') {
        acoes.push(CobrancaJudicialMainComponent.CANCELAR_COBRANCA_JUDICIAL,
          CobrancaJudicialMainComponent.ALTERAR_DATA_EMISSAO,
          CobrancaJudicialMainComponent.ALTERAR_VALOR);
        return { ...cobranca, acoes };
      }
    };

    this.cobrancas = cobrancas?.map(mapearAcoes) ?? [];
  }

  onAcaoClick(event: CobrancaJudicialAcaoEvent): void {
    const actions = new Map<string, (cobranca: CobrancaJudicial) => void>()
      .set(CobrancaJudicialMainComponent.CANCELAR_COBRANCA_JUDICIAL, (cobranca: CobrancaJudicial) => {
        this.cancelarCobranca(cobranca);
      })
      .set(CobrancaJudicialMainComponent.ALTERAR_VALOR, (cobranca: CobrancaJudicial) => {
        this.alterarValor(cobranca);
      })
      .set(CobrancaJudicialMainComponent.ALTERAR_DATA_EMISSAO, (cobranca: CobrancaJudicial) => {
        this.alterarDataEmissao(cobranca);
      });

    actions.get(event.acao)(event.cobranca);
  }

  onDisclosure(cobranca: CobrancaJudicial): void {
    this.router.navigate(['base/clientes/cobranca-judicial/detail/' + cobranca.id]);
  }

  onFilter(options: CobrancaJudicialListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    this.queryOptions.params = {
      nomeCliente: options.nomeCliente ? `${options.nomeCliente}` : null,
      postoId,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
    };

    this.router.navigate(['/base/clientes/cobranca-judicial'], { queryParams: this.queryOptions.params }).then(() => this.listar());

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }
  }

  cancelarCobranca(cobranca: CobrancaJudicial): void {
    const dialogRef = this.dialog.open(ConfirmacaoDialogComponent, {
      data: {
        cobranca,
        action: 'novo'
      },
      width: '750px',
    });

    dialogRef.afterClosed().subscribe((cobrancaJudicial: CobrancaJudicial) => {
      this.appState.isLoading = true;
      if (cobrancaJudicial) {
        this.cobrancaJudicialService.cancelarCobrancaJudicial(cobranca.id).subscribe(
          (response: ReturnAPI<CobrancaJudicial>) => {
            this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
            this.appState.isLoading = false;
            this.listar();
          },
          (e: HttpErrorResponse) => {
            this.appState.isLoading = false;
            this.dialogService.feedbackError(`Houve um erro ao cadastrar pequena causa.\n ${e.message}`);
          },
        );
      } else {
        this.appState.isLoading = false;
      }
    });
  }

  alterarValor(cobranca: CobrancaJudicial): void {
    const dialogRef = this.dialog.open(AlterarValorCobrancaJudicialFormComponent, {
      data: {
        cobranca,
        action: 'novo'
      },
      width: '350px',
    });
    dialogRef.afterClosed().subscribe((cobrancaJudicial: CobrancaJudicial) => {
      this.appState.isLoading = true;
      if (cobrancaJudicial) {
        this.cobrancaJudicialService.alterarValorCobrancaJudicial(cobranca).subscribe(
          (response: ReturnAPI<CobrancaJudicial>) => {
            this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
            this.appState.isLoading = false;
            this.listar();
          },
          (e: HttpErrorResponse) => {
            this.appState.isLoading = false;
            this.dialogService.feedbackError(`Houve um erro ao cadastrar pequena causa.\n ${e.message}`);
          },
        );
      } else {
        this.appState.isLoading = false;
      }
    });
  }

  alterarDataEmissao(cobranca: CobrancaJudicial): void {
    const dialogRef = this.dialog.open(AlterarEmissaoCobrancaJudicialFormComponent, {
      data: {
        cobranca,
        action: 'novo'
      },
      width: '350px',
    });
    dialogRef.afterClosed().subscribe((cobrancaJudicial: CobrancaJudicial) => {
      this.appState.isLoading = true;
      if (cobrancaJudicial) {
        this.cobrancaJudicialService.alterarEmissaoCobrancaJudicial(cobrancaJudicial).subscribe(
          (response: ReturnAPI<CobrancaJudicial>) => {
            this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
            this.appState.isLoading = false;
            this.listar();
          },
          (e: HttpErrorResponse) => {
            this.appState.isLoading = false;
            this.dialogService.feedbackError(`Houve um erro ao cadastrar pequena causa.\n ${e.message}`);
          },
        );
      } else {
        this.appState.isLoading = false;
      }
    });
  }

  checkUsuarioAdmin(email: string): boolean {
    email = this.usuario.email;
    if (email == 'admin@gmail.com') {
      return true;
    } else {
      return false;
    }
  }
}
