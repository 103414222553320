import { OnInit, Component } from '@angular/core';
import { Caixa } from 'src/app/base/domain/caixa.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { CaixaService } from 'src/app/base/services/caixa.service';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import { DialogService } from 'src/app/global/services/dialog.service';
import { AppState } from 'src/app/App.state';
import { CaixaListFilterOptions, CaixaAcaoEvent } from 'src/app/global/lists/caixa-list/caixa-list.component';
import { PendenciaNaoRegularizadaDTO } from 'src/app/base/domain/dto/pendencia-nao-regularizada.dto';
import { Funcionario } from 'src/app/base/domain/funcionario.model';
import { MatDialog } from '@angular/material/dialog';
import {
  CheckboxSelectFormComponent,
  CheckboxSelectFormData,
  CheckboxSelectFormResultData,
} from 'src/app/global/forms/checkbox-select-form/checkbox-select-form.component';
import { FuncionarioService } from 'src/app/base/services/funcionario.service';
import { ValoresCaixaRequestDTO } from 'src/app/base/domain/dto/valores-caixa-request.dto';
import { TipoCaixa } from 'src/app/base/domain/enum/tipo-caixa.enum';

@Component({
  selector: 'app-acompanhamento-pendencia-main',
  templateUrl: './acompanhamento-pendencia-main.component.html',
  styleUrls: ['./acompanhamento-pendencia-main.component.css'],
})
export class AcompanhamentoPendenciaMainComponent {
  private static NAO_REGULARIZAR_ACTION = 'Marcar como Não-Regularizado';

  caixas: Caixa[] = [];
  queryOptions = new QueryOptions({ pageSize: 10, pageSort: '-id', params: { type: 'cdf', situacao: `in:PENDENTE` } });
  totalRecords = 0;
  totalizador: number;
  private postoSelecionado: number = null;

  constructor(
    private caixaService: CaixaService,
    private funcionarioService: FuncionarioService,
    public dialog: MatDialog,
    public dialogService: DialogService,
    public appState: AppState,
  ) {}

  private mapearAcoes(caixas: Caixa[]): void {
    const mapearAcoes = (caixa: Caixa): Caixa => {
      const acoes = [];
      if (!caixa.dataHoraFechamento && caixa.situacao === 'PENDENTE') {
        acoes.push(AcompanhamentoPendenciaMainComponent.NAO_REGULARIZAR_ACTION);
      }
      return { ...caixa, acoes };
    };

    this.caixas = caixas?.map(mapearAcoes) ?? [];
  }

  list(pageNumber: number = 0, valores: Partial<ValoresCaixaRequestDTO> = {}): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.appState.isLoading = true;
    this.caixaService.pageAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Caixa>>) => {
      if (response.success) {
        this.caixas = response.object.content;
        this.totalRecords = response.object.totalElements;
        this.mapearAcoes(response.object.content);
      } else {
        this.caixas = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }

      valores.dataInicial = valores.dataInicial ? valores.dataInicial : null;
      valores.dataFinal = valores.dataFinal ? valores.dataFinal : null;

      this.getValorTotalPorTipoCaixa({ ...valores, postoId: this.postoSelecionado, tipo: TipoCaixa.CAIXA_DIVIDA_FUNCIONARIO });

      this.appState.isLoading = false;
    });
  }

  marcarPendenciaComoNaoRegularizado(caixa: Caixa, novoResponsavel: Funcionario, alterarResponsavelContrato: boolean): void {
    this.appState.isLoading = true;
    const dto: PendenciaNaoRegularizadaDTO = {
      caixaDividaFuncionarioId: caixa.id,
      novoResponsavelId: novoResponsavel.id,
      transferirResponsavelContrato: alterarResponsavelContrato,
    };
    this.caixaService.marcarCaixaDividaComoNaoRegularizado(dto).subscribe(
      (response: ReturnAPI<Caixa>) => {
        this.dialogService.feedbackReturnAPI(response, 'Pendência marcada como Não-Regularizado', (success: boolean) => {
          if (success) {
            this.list(this.queryOptions.pageNumber - 1);
          }
        });
        this.appState.isLoading = false;
      },
      () => {
        this.appState.isLoading = false;
        this.dialogService.feedbackError('Ocorreu um erro ao realizar a operação!');
      },
    );
  }

  onLoadCaixaPage(page: number): void {
    this.list(page);
  }

  onAcaoClick(event: CaixaAcaoEvent): void {
    const actions = new Map<string, (caixa: Caixa) => void>().set(
      AcompanhamentoPendenciaMainComponent.NAO_REGULARIZAR_ACTION,
      (caixa: Caixa) => this.naoRegularizarAction.call(this, caixa),
    );

    actions.get(event.acao)(event.caixa);
  }

  async consultaFuncionarios(name: string, postoId: number): Promise<Funcionario[]> {
    const options = new QueryOptions({ pageSize: 10, pageNumber: 1, params: { nomeFuncionario: name, postoId } });
    const response = await this.funcionarioService.findAll(options).toPromise();
    return response.success ? response.object.content : [];
  }

  async naoRegularizarAction(caixa: Caixa): Promise<void> {
    let funcionariosEmBusca: Funcionario[] = await this.consultaFuncionarios('', caixa.posto.id);
    this.dialog
      .open<CheckboxSelectFormComponent, CheckboxSelectFormData, CheckboxSelectFormResultData>(CheckboxSelectFormComponent, {
        data: {
          title: `Selecionar novo responsável das cobranças de hoje do(a) ${caixa.funcionario.pessoa.nomePessoa}`,
          checkboxText: 'Alterar definitivamente o responsável dos contratos?',
          inputPlaceholder: 'Funcionário',
          selectableOptions: funcionariosEmBusca.map((funcionario: Funcionario) => funcionario.pessoa.nomePessoa),
          onAsyncSelectableFilter: async (value: string): Promise<string[]> => {
            funcionariosEmBusca = await this.consultaFuncionarios(value, caixa.posto.id);
            return funcionariosEmBusca.map((funcionario: Funcionario) => funcionario.pessoa.nomePessoa);
          },
        },
        width: '500px',
      })
      .afterClosed()
      .subscribe((result: CheckboxSelectFormResultData) => {
        this.marcarPendenciaComoNaoRegularizado(caixa, funcionariosEmBusca[result.optionSelected], result.checked);
      });
  }

  onFilter(options: CaixaListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    this.queryOptions.params = {
      ...this.queryOptions.params,
      nomeFuncionario: options.nomeFuncionario || null,
      situacao: `in:PENDENTE`,
      postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
    };
    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }

    this.list(0, {
      dataInicial: options?.dataInicial,
      dataFinal: options?.dataFinal,
    });
  }

  getValorTotalPorTipoCaixa(valoresCaixa: Partial<ValoresCaixaRequestDTO>): void {
    this.caixaService.getValorTotalPorTipoCaixa(valoresCaixa).subscribe((response: ReturnAPI<number>) => {
      this.totalizador = response.object || 0;
    });
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
