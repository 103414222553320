import { Injectable } from '@angular/core';
import { ResourceService } from '../../global/services/resource.service';
import { HttpClient } from '@angular/common/http';
import { WEB_API } from '../../global/services/web-api';
import { AuditoriaAlterarParcelaSerializer } from '../serializables/AuditoriaAlterarParcela.serializer';
import { AuditoriaAlterarParcela } from '../domain/AuditoriaAlterarParcela.model';
import { QueryOptions } from '../../global/domain/query.options';
import { Observable } from 'rxjs';
import { Page, ReturnAPI } from '../domain/return-api.model';
import { Caixa } from '../domain/caixa.model';
import { CaixaSerializer } from '../serializables/caixa.serializer';
import { Produto } from '../domain/produto.model';

@Injectable()
export class AuditoriaAlterarParcelaService extends ResourceService<AuditoriaAlterarParcela> {
  static RESOURCE = '';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, 'auditorias', new AuditoriaAlterarParcelaSerializer(AuditoriaAlterarParcela));
  }

  pageAll(queryOptions: QueryOptions): Observable<ReturnAPI<Page<AuditoriaAlterarParcela>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<AuditoriaAlterarParcela>>>(
      `${WEB_API}/auditorias/alterar-parcela?${queryOptions.toQueryString()}&${this.produtoId}`,
    );
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }
}
