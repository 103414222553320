import { GlobalModule } from 'src/app/global/global.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RealocarFuncionarioMainComponent } from './realocar-funcionario-main/realocar-funcionario-main.component';

@NgModule({
  declarations: [RealocarFuncionarioMainComponent],
  imports: [
    CommonModule,
    GlobalModule,
  ]
})
export class RealocarFuncionarioModule { }
