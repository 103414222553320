import { Component, OnInit } from '@angular/core';
import { DashBoardEstadoFaturamento } from 'src/app/base/domain/dashboard-estado-faturamento.model';
import { DashBoardFaturamento } from 'src/app/base/domain/dashboard-faturamento';
import { DashBoardFaturamentoService } from 'src/app/base/services/dashboard-faturamento.service';
import { Router } from '@angular/router';
import { AppState } from 'src/app/App.state';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { DashBoardFilterOptions } from 'src/app/global/faturamento-dashboard/faturamento-dashboard.component';

@Component({
  selector: 'app-faturamento-digital-main',
  templateUrl: './faturamento-digital-main.component.html',
  styleUrls: ['./faturamento-digital-main.component.css'],
})
export class FaturamentoDigitalMainComponent implements OnInit {
  dashboard: DashBoardFaturamento = new DashBoardFaturamento();
  dashboardBoleto: DashBoardFaturamento = new DashBoardFaturamento();
  valorTotalGeral = 0;
  quantidadeTotalGeral = 0;

  data = new Date();
  dashboardEstadoList: DashBoardEstadoFaturamento[] = [];

  queryOptions = new QueryOptions({ pageSize: 10 });

  constructor(private service: DashBoardFaturamentoService, private router: Router, public appState: AppState) { }

  ngOnInit(): void {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    this.data = date;
    this.queryOptions.params = {
      dataInicial: date.getTime(),
      dataFinal: date.getTime(),
    };

    this.getDashBoard();
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  onReload(): void {
    this.getDashBoard();
  }

  getDashBoard(): void {
    this.data.setHours(0, 0, 0, 0);
    this.appState.isLoading = true;
    this.valorTotalGeral = 0;
    this.quantidadeTotalGeral = 0;
    this.service.getDashBoardDigital(this.queryOptions).subscribe((response: DashBoardFaturamento) => {
      this.getDashBoardEstado();
      this.dashboard = response;
      this.valorTotalGeral = this.valorTotalGeral + this.dashboard.valorTotal;
      this.quantidadeTotalGeral = this.quantidadeTotalGeral + this.dashboard.quantidadeTotal;
      this.service.getDashBoardBoleto(this.queryOptions).subscribe((responseBoleto: DashBoardFaturamento) => {
        this.getDashBoardEstado();
        this.dashboardBoleto = responseBoleto;
        this.valorTotalGeral = this.valorTotalGeral + this.dashboardBoleto.valorTotal;
        this.quantidadeTotalGeral = this.quantidadeTotalGeral + this.dashboardBoleto.quantidadeTotal;
      });
    });
  }

  getDashBoardEstado(): void {
    this.service.getDashboardEstadoFaturamentoDigital().subscribe((response: DashBoardEstadoFaturamento[]) => {
      this.dashboardEstadoList = response;
      this.appState.isLoading = false;
    });
  }

  onDisclosure(dashboard: DashBoardEstadoFaturamento): void {
    this.queryOptions.params = {
      ...this.queryOptions.params,
      nomeEstado: dashboard.nomeEstado ? dashboard.nomeEstado : null,
    };

    this.router.navigate(['base/gestao/faturamento/estado-faturamento-digital/' + dashboard.estadoId], {
      queryParams: this.queryOptions.params,
    });
  }

  onFilter(options: DashBoardFilterOptions): void {
    this.queryOptions.params = {
      postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
      estadoId: options.estadoSelecionado ? options.estadoSelecionado.id : null,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
    };

    this.getDashBoard();
  }
}
