import { SituacaoGenerico } from './../../widgets/global-filter/global-filter.component';
import { ActivatedRoute } from '@angular/router';
import { AcaoDeVenda } from './../../../base/domain/acao-de-venda.model';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CardListComponent } from '../../components/card-list/card-list.component';
import { NgForm } from '@angular/forms';
import { Table } from 'primeng/table';
import { QueryOptions } from '../../domain/query.options';
import { LazyLoadEvent } from 'primeng/api';
import { Funcionario } from 'src/app/base/domain/funcionario.model';
import { Posto } from 'src/app/base/domain/posto.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Cliente } from 'src/app/base/domain/cliente.model';

export interface AcaoDeVendaListFilterOptions {
  postoSelecionado: Posto;
  situacao: number[];
  situacaoCliente: number[];
  nome: string;
  smsEnviado: boolean;
}

export interface AcaoDeVendaAcaoEvent {
  acao: string;
  acaoDeVenda: AcaoDeVenda;
}

@Component({
  selector: 'app-acao-de-venda-list',
  templateUrl: './acao-de-venda-list.component.html',
  styleUrls: ['./acao-de-venda-list.component.css'],
})
export class AcaoDeVendaListComponent implements OnInit {
  @ViewChild('searchForm', { static: false }) searchForm: NgForm;
  @ViewChild('dataTable', { static: false }) dataTable: Table;
  @ViewChild(CardListComponent, { static: true }) cardList: CardListComponent;

  fiscais: Funcionario[] = [];
  AGENDAR_MULTIPLAS_VISITA = 'Agendar Multiplas Visitas';
  REENVIAR_MULTIPLOS_SMS = 'Reenviar Multiplos SMS';
  PREAPROVAR_MULTIPLAS_ACOES = 'Pré-Aprovar Multiplas Ações';

  @Input() title: string;
  @Input() acoesDeVenda: AcaoDeVenda[];
  @Input() type: string;
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() loading: boolean;
  @Input() filtros: string[];
  @Input() optionalColumns: string[];
  @Input() options: string[];
  @Input() menuAcoes: string[];

  @Output() filter = new EventEmitter<AcaoDeVendaListFilterOptions>();
  @Output() loadPage = new EventEmitter<number>();
  @Output() acaoClick = new EventEmitter<AcaoDeVendaAcaoEvent>();
  @Output() selectAll = new EventEmitter<boolean>();
  @Output() agendarMultiplasVisitas = new EventEmitter<AcaoDeVenda[]>();
  @Output() reenviarMultiplosSMS = new EventEmitter<AcaoDeVenda[]>();
  @Output() preAprovarMultiplosClientes = new EventEmitter<AcaoDeVenda[]>();

  private currentPage = 0;
  queryOptions = new QueryOptions();

  filterOptions: AcaoDeVendaListFilterOptions;

  private readonly defaultFilterOptions: AcaoDeVendaListFilterOptions = {
    postoSelecionado: null,
    situacao: null,
    situacaoCliente: null,
    nome: null,
    smsEnviado: null,
  };

  situacoesGenericas1: SituacaoGenerico[] = [
    {
      descricao: 'Novo',
      valor: 0,
    },
    {
      descricao: 'Inativo',
      valor: 2,
    },
  ];

  situacoesGenericas2: SituacaoGenerico[] = [
    {
      descricao: 'AGUARDANDO PRE APROVACAO',
      valor: 0,
    },
    {
      descricao: 'EM ANALISE PELO CLIENTE',
      valor: 1,
    },
    {
      descricao: 'APROVADO',
      valor: 2,
    },
    {
      descricao: 'REJEITADO',
      valor: 3,
    },
  ];

  selecionados: AcaoDeVenda[] = [];

  onMenuAcaoClick(event: string): void {
    if (event === this.AGENDAR_MULTIPLAS_VISITA) {
      this.agendarMultiplasVisitas.emit(this.selecionados);
    } else if (event === this.REENVIAR_MULTIPLOS_SMS) {
      this.reenviarMultiplosSMS.emit(this.selecionados);
    } else if (event === this.PREAPROVAR_MULTIPLAS_ACOES) {
      this.preAprovarMultiplosClientes.emit(this.selecionados);
    } else {
      this.onAcaoClick(event, null);
    }
  }

  loadContent(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadPage.emit(pageNumber);
  }

  onAcaoClick(acao: string, domain: AcaoDeVenda): void {
    this.acaoClick.emit({ acao, acaoDeVenda: domain });
  }

  constructor() {
    this.onLimparFiltros();
  }

  ngOnInit(): void { }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  onAtualizar(): void {
    this.loadPage.emit(this.currentPage);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  obterDescricaoDoUltimoContrato(acaoDeVenda: AcaoDeVenda): string {
    if (!acaoDeVenda.ultimoContrato) {
      return `${this.generateMask(0)}/0`;
    }
    const parcelas = acaoDeVenda.ultimoContrato.visita.quantidadeParcelas;
    const valor = acaoDeVenda.ultimoContrato.valorBruto;
    return `${this.generateMask(valor)}/${parcelas}`;
  }

  onCheckboxChange($event: MatCheckboxChange): void {
    this.selecionados = this.acoesDeVenda.filter((acaoDeVenda: AcaoDeVenda) => acaoDeVenda.selecionado);
  }

  onSelectAll(): void {
    this.acoesDeVenda.forEach((acaoDeVenda: AcaoDeVenda) => (acaoDeVenda.selecionado = true));
    this.selecionados = this.acoesDeVenda.filter(
      (acaoDeVenda: AcaoDeVenda) => acaoDeVenda.selecionado && acaoDeVenda.situacao !== 'VISITA_AGENDADA',
    );
  }

  onUnselectAll(): void {
    this.acoesDeVenda.forEach((acaoDeVenda: AcaoDeVenda) => (acaoDeVenda.selecionado = false));
    this.selecionados = [];
  }

  getNumeroTelefone(cliente: Cliente): string {
    const numero = cliente?.pessoa.listTelefone.length > 0 ? cliente?.pessoa.listTelefone[0].numero : 'sem telefone';
    return numero;
  }
}
