import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcompanhamentoComprovantesPixMainComponent } from './acompanhamento-comprovantes-pix-main/acompanhamento-comprovantes-pix-main.component';
import { GlobalModule } from 'src/app/global/global.module';



@NgModule({
  declarations: [AcompanhamentoComprovantesPixMainComponent],
  imports: [
    CommonModule,
    GlobalModule
  ]
})
export class AcompanhamentoComprovantesPixModule { }
