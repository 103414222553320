import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LazyLoadEvent } from 'primeng/api';
import { ArquivoAnaliseDeRisco } from 'src/app/base/domain/arquivo-analise-de-risco.model';
import { CategoriaAnaliseDeRisco } from 'src/app/base/domain/enum/categoria-analise-risco.enum';
import { DialogService } from '../../services/dialog.service';

export interface ArquivoAnaliseDeRiscoAcaoEvent {
  acao: string;
  arquivo: ArquivoAnaliseDeRisco;
}

export interface ArquivoAnaliseDeRiscoListFilterOptions {
  nome: string;
  categoria: CategoriaAnaliseDeRisco[];
  dataInicial: Date;
  dataFinal: Date;
}

@Component({
  selector: 'app-arquivo-analise-de-risco-list',
  templateUrl: './arquivo-analise-de-risco-list.component.html',
  styleUrls: ['./arquivo-analise-de-risco-list.component.css'],
})
export class ArquivoAnaliseDeRiscoListComponent implements OnInit {
  @Input() itens: ArquivoAnaliseDeRisco[];
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() loading: boolean;
  @Input() title: string;
  @Input() nomePlaceholder: string;
  @Input() dataPlaceholder: string;
  @Input() filtros: string[];
  @Input() options: string[];
  @Input() optionalColumns: string[];
  @Input() menuAcoes: string[];
  @Input() lazy: boolean;

  @Output() loadEvent = new EventEmitter<number>();
  @Output() filter = new EventEmitter<ArquivoAnaliseDeRiscoListFilterOptions>();
  @Output() acaoClick = new EventEmitter<ArquivoAnaliseDeRiscoAcaoEvent>();
  @Output() novo = new EventEmitter<void>();

  private currentPage = 0;

  categorias: CategoriaAnaliseDeRisco[] = Object.values(CategoriaAnaliseDeRisco);

  private readonly defaultFilterOptions: ArquivoAnaliseDeRiscoListFilterOptions = {
    nome: '',
    categoria: null,
    dataInicial: null,
    dataFinal: null,
  };

  filterOptions: ArquivoAnaliseDeRiscoListFilterOptions;

  constructor(public dialog: MatDialog, public dialogService: DialogService) {}

  ngOnInit(): void {
    this.onLimparFiltros();
  }

  load(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadEvent.emit(pageNumber);
  }

  onAtualizar(): void {
    this.loadEvent.emit(this.currentPage);
  }

  onLimparFiltros(): void {
    if (this.filterOptions) {
      this.filterOptions.dataInicial = null;
      this.filterOptions.dataFinal = null;
    }
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onNovo(): void {
    this.novo.emit();
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  onAcaoClick(acao: string, domain: ArquivoAnaliseDeRisco): void {
    this.acaoClick.emit({ acao, arquivo: domain });
  }
}
