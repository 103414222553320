import { CommonModule } from '@angular/common';
import { GlobalModule } from 'src/app/global/global.module';
import { NgModule } from '@angular/core';
import { OperadoresExternosMainComponent } from './operadores-externos-main/operadores-externos-main.component';

@NgModule({
  declarations: [OperadoresExternosMainComponent],
  imports: [CommonModule, GlobalModule],
})
export class OperadoresExternosModule {}
