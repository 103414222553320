import { ParcelaComprovantePixDTO } from './../../../base/domain/dto/parcela-comprovante-pix.dto';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LazyLoadEvent } from 'primeng';
import { Posto } from 'src/app/base/domain/posto.model';
import { Rota } from 'src/app/base/domain/rota.model';

export interface AcompanhamentoComprovantesFilterOptions {
  posto: Posto;
  rota: Rota;
  dataInicial: Date;
  dataFinal: Date;
  nome: string;
}

@Component({
  selector: 'app-acompanhamento-comprovantes-pix-list',
  templateUrl: './acompanhamento-comprovantes-pix-list.component.html',
  styleUrls: ['./acompanhamento-comprovantes-pix-list.component.css'],
})
export class AcompanhamentoComprovantesPixListComponent implements OnInit {
  private static readonly defaultFilter: AcompanhamentoComprovantesFilterOptions = {
    posto: null,
    rota: null,
    dataInicial: new Date(),
    dataFinal: new Date(),
    nome: null,
  };

  private currentPage = 0;
  filterOptions: AcompanhamentoComprovantesFilterOptions;

  @Output() onLoadevent: EventEmitter<number> = new EventEmitter<number>();
  @Output() onDownloadevent: EventEmitter<AcompanhamentoComprovantesFilterOptions> = new EventEmitter<AcompanhamentoComprovantesFilterOptions>();
  @Output() onFilterEvent: EventEmitter<AcompanhamentoComprovantesFilterOptions> = new EventEmitter<AcompanhamentoComprovantesFilterOptions>();
  @Output() acaoClick: EventEmitter<{ acao: string; domain: ParcelaComprovantePixDTO }> = new EventEmitter<{acao: string; domain: ParcelaComprovantePixDTO;}>();

  @Input() options: string[];
  @Input() filtros: string[];
  @Input() comprovantes: any[] = [];
  @Input() totalRecords: number;
  @Input() rowsNumber: number;

  constructor() {}

  ngOnInit(): void {
    this.onLimparFiltros();
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, AcompanhamentoComprovantesPixListComponent.defaultFilter);
    this.onFilterEvent.emit(this.filterOptions);
  }

  onAplicarFiltro(): void {
    this.onFilterEvent.emit(this.filterOptions);
  }

  onAtualizar(): void {
    this.onLoadevent.emit(this.currentPage);
  }

  onDownload(): void {
    this.onDownloadevent.emit(this.filterOptions);
  }

  loadList(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.onLoadevent.emit(pageNumber);
  }

  onAcaoClick(acao: string, comprovante: ParcelaComprovantePixDTO): void {
    this.acaoClick.emit({ acao, domain: comprovante });
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
