import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { Posto } from 'src/app/base/domain/posto.model';

export interface CheckboxSelectFormData {
  title: string;
  infoText?: string;
  checkboxText?: string;
  noCheckbox?: boolean;
  dataPicker?: boolean;
  checked?: boolean;
  inputPlaceholder?: string;
  postoDestino?: Posto;
  selectableOptions: string[];
  onAsyncSelectableFilter?: (value: string) => Promise<string[]>;
}

export interface CheckboxSelectFormResultData {
  optionSelected: number;
  checked: boolean;
  dataSelecionada: Date;
}

@Component({
  selector: 'app-checkbox-select-form',
  templateUrl: './checkbox-select-form.component.html',
  styleUrls: ['./checkbox-select-form.component.css'],
})
export class CheckboxSelectFormComponent {
  selectableFilteredOptions: string[] = [];
  selectableInput = '';

  optionSelected?: string;
  checked?: boolean;
  dataSelecionada?: Date;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CheckboxSelectFormData,
    public dialogRef: MatDialogRef<CheckboxSelectFormComponent, CheckboxSelectFormResultData>,
  ) {
    this.checked = this.checked || false;
    this.selectableFilteredOptions = this.data.selectableOptions;
    if (this.data.postoDestino) {
      this.selectableInput = this.data.postoDestino.descricao;
      this.optionSelected = this.data.postoDestino.descricao;
      // this.doFilter();
    }
  }

  async doFilter(): Promise<void> {
    this.selectableFilteredOptions = await this._filtrar(this.selectableInput);
  }

  onSelect(value: string): void {
    this.optionSelected = value;
  }

  onPickerEvent(event: MatDatepickerInputEvent<Moment>): void {
    this.dataSelecionada = event.value?.toDate() || null;
  }

  onConfirm(): void {
    this.dialogRef.close({
      checked: this.checked || false,
      dataSelecionada: this.dataSelecionada || null,
      optionSelected: this.selectableFilteredOptions.indexOf(this.optionSelected || ''),
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  private async _filtrar(value: string): Promise<string[]> {
    if (this.data.onAsyncSelectableFilter) {
      return this.data.onAsyncSelectableFilter(value);
    }

    const filterValue = value?.toLowerCase();
    return this.selectableFilteredOptions.filter((e: string) => e.toLowerCase().indexOf(filterValue) === 0);
  }
}
