import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalModule } from '../../../global/global.module';
import { ComponentsModule } from '../../../global/components/components.module';
import { ButtonModule, SharedModule, TableModule } from 'primeng';
import { MatButtonModule } from '@angular/material/button';
import { MonitoramentoLancamentoEntradaMainComponent } from './monitoramento-lancamento-entrada-main/monitoramento-lancamento-entrada-main.component';



@NgModule({
  declarations: [MonitoramentoLancamentoEntradaMainComponent],
  imports: [
    CommonModule, GlobalModule, ComponentsModule, ButtonModule, SharedModule, TableModule, MatButtonModule,
  ]
})
export class MonitoramentoLancamentoEntradaModule { }
