import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Posto } from 'src/app/base/domain/posto.model';
import { QueryOptions } from '../../domain/query.options';
import { LazyLoadEvent } from 'primeng/api';

export interface AcaoEvent {
  acao: string;
  posto: Posto;
}

export interface ListFilterOptions {
  postoSelecionado: Posto;
}
@Component({
  selector: 'app-posto-list',
  templateUrl: './posto-list.component.html',
  styleUrls: ['./posto-list.component.css'],
})
export class PostoListComponent implements OnInit {
  @Input() titulo: string;
  @Input() postos: Posto[];
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() filtros: string[];

  @Output() filter = new EventEmitter<ListFilterOptions>();
  @Output() loadPage = new EventEmitter<number>();
  @Output() acaoClick = new EventEmitter<AcaoEvent>();

  private currentPage = 0;
  queryOptions = new QueryOptions();

  private readonly defaultFilterOptions: ListFilterOptions = {
    postoSelecionado: null,
  };

  filterOptions: ListFilterOptions;

  ngOnInit(): void {
    this.onLimparFiltros();
  }

  load(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadPage.emit(pageNumber);
  }

  onAcaoClick(acao: string, domain: Posto): void {
    this.acaoClick.emit({ acao, posto: domain });
  }

  onAtualizar(): void {
    this.loadPage.emit(this.currentPage);
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }
}
