import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Cargo } from 'src/app/base/domain/cargo.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { Produto } from '../domain/produto.model';
import { Page, ReturnAPI } from '../domain/return-api.model';
import { CargoSerializer } from '../serializables/cargo.serializer';

@Injectable()
export class CargoService extends ResourceService<Cargo> {
  static RESOURCE = 'cargo';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, CargoService.RESOURCE, new CargoSerializer(Cargo));
  }

  findAll(queryOption: QueryOptions): Observable<ReturnAPI<Page<Cargo>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Cargo>>>(
      `${WEB_API}/${CargoService.RESOURCE}?${queryOption.toQueryString()}&${this.produtoId}`,
    );
  }

  listarCargos(): Observable<ReturnAPI<Cargo[]>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Cargo[]>>(`${WEB_API}/${CargoService.RESOURCE}?${this.produtoId}`);
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }
}
