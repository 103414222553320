import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from 'src/app/App.state';
import { ArquivoAnaliseDeRisco } from 'src/app/base/domain/arquivo-analise-de-risco.model';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { ArquivoAnaliseDeRiscoService } from 'src/app/base/services/arquivo-analise-de-risco.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ArquivoAnaliseDeRiscoFormComponent } from 'src/app/global/forms/arquivo-analise-de-risco-form/arquivo-analise-de-risco-form.component';
import {
  ArquivoAnaliseDeRiscoAcaoEvent,
  ArquivoAnaliseDeRiscoListFilterOptions,
} from 'src/app/global/lists/arquivo-analise-de-risco-list/arquivo-analise-de-risco-list.component';
import { DialogService } from 'src/app/global/services/dialog.service';

@Component({
  selector: 'app-arquivo-analise-de-risco-main',
  templateUrl: './arquivo-analise-de-risco-main.component.html',
  styleUrls: ['./arquivo-analise-de-risco-main.component.css'],
})
export class ArquivoAnaliseDeRiscoMainComponent implements OnInit {
  private static ADICIONAR_CLIENTE_ACTION = 'Realizar Analise de Risco';
  private static EDITAR_ARQUIVO_ACTION = 'Editar Arquivo de Analise';

  arquivos: ArquivoAnaliseDeRisco[] = [];
  queryOptions = new QueryOptions({ pageSize: 25 });
  totalRecords = 0;
  loading = false;

  private postoSelecionado: number = null;

  constructor(
    public dialogService: DialogService,
    private service: ArquivoAnaliseDeRiscoService,
    public dialog: MatDialog,
    private router: Router,
    public appState: AppState,
  ) {}

  ngOnInit(): void {}

  list(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.queryOptions.params = { ...this.queryOptions.params, joinRota: true };
    this.service.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<ArquivoAnaliseDeRisco>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.arquivos = response.object.content;
        this.mapearAcoes(response.object.content);
      } else {
        this.arquivos = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
    });
  }

  mapearAcoes(arquivos: ArquivoAnaliseDeRisco[]): void {
    const mapearAcoes = (arquivo: ArquivoAnaliseDeRisco): ArquivoAnaliseDeRisco => {
      const acoes = [];
      acoes.push(ArquivoAnaliseDeRiscoMainComponent.ADICIONAR_CLIENTE_ACTION);
      acoes.push(ArquivoAnaliseDeRiscoMainComponent.EDITAR_ARQUIVO_ACTION);
      return { ...arquivo, acoes };
    };

    this.arquivos = arquivos?.map(mapearAcoes) ?? [];
  }

  onAcaoClick(event: ArquivoAnaliseDeRiscoAcaoEvent): void {
    const actions = new Map<string, (arquivoAnaliseDeRisco: ArquivoAnaliseDeRisco) => void>()
      .set(ArquivoAnaliseDeRiscoMainComponent.ADICIONAR_CLIENTE_ACTION, (arquivoAnaliseDeRisco: ArquivoAnaliseDeRisco) => {
        this.onRealizarAnalise(arquivoAnaliseDeRisco);
      })
      .set(ArquivoAnaliseDeRiscoMainComponent.EDITAR_ARQUIVO_ACTION, (arquivoAnaliseDeRisco: ArquivoAnaliseDeRisco) => {
        this.onEditarAnalise(arquivoAnaliseDeRisco);
      });

    actions.get(event.acao)(event.arquivo);
  }

  onLoadPage(page: number): void {
    this.list(page);
  }

  onRealizarAnalise(arquivoAnaliseDeRisco: ArquivoAnaliseDeRisco): void {
    this.router.navigate(['base/clientes/analise-de-risco-clientes/' + arquivoAnaliseDeRisco.id], { queryParams: { action: 'create' } });
  }

  onEditarAnalise(arquivoAnaliseDeRisco: ArquivoAnaliseDeRisco): void {
    this.router.navigate(['base/clientes/analise-de-risco-clientes/' + arquivoAnaliseDeRisco.id], { queryParams: { action: 'update' } });
  }

  onFilter(options: ArquivoAnaliseDeRiscoListFilterOptions): void {
    this.queryOptions.params = {
      nome: options.nome ? `${options.nome}` : null,
      categoria: options.categoria?.length ? `in:${options.categoria.join(',')}` : null,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
    };
    this.list();
  }

  onNovo(): void {
    this.dialog
      .open(ArquivoAnaliseDeRiscoFormComponent, {
        data: {
          action: 'novo',
          produto: null,
        },
        width: '300px',
      })
      .afterClosed()
      .subscribe((result: ArquivoAnaliseDeRisco) => {
        if (result) {
          this.service.save(result).subscribe(
            () => {
              this.appState.isLoading = false;
              this.dialogService.feedbackSuccess('Operação realizada com sucesso!');
              this.list();
            },
            // tslint:disable-next-line: typedef
            (error) => {
              this.appState.isLoading = false;
              console.error(error);
              this.dialogService.feedbackError('Ocorreu um erro ao criar produto!');
              this.list();
            },
          );
        }
      });
  }
}
