import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AppState } from "src/app/App.state";
import { SharedService } from "src/app/auth/shared.service";
import { ValoresArquivoNovoRequestDTO } from "src/app/base/domain/dto/valores-arquivo-novo-request-dto";
import { Page, ReturnAPI } from "src/app/base/domain/return-api.model";
import { Usuario } from "src/app/base/domain/usuario.model";
import { ContratoService } from "src/app/base/services/contrato.service";
import { QueryOptions } from "src/app/global/domain/query.options";
import { DialogService } from "src/app/global/services/dialog.service";
import * as FileSaver from 'file-saver';
import { ArquivoImportacaoFinalizado } from "src/app/base/domain/arquivo-importacao-finalizado.model";
import { ArquivoImportacaoFinalizadoService } from "src/app/base/services/arquivo-importacao-finalizado.service ";
import { ArquivoImportacaoAcaoEvent } from "src/app/global/lists/arquivo-importacao-novo-list/arquivo-importacao-novo-list.component";
import { ArquivoImportacaoFinalizadoAcaoEvent, ArquivoImportacaoFinalizadoListFilterOptions } from "src/app/global/lists/arquivo-importacao-finalizado-list/arquivo-importacao-finalizado-list.component";
import * as moment from 'moment';

@Component({
    selector: 'app-contas-encerradas-arquivo',
    templateUrl: './contas-encerradas-arquivo.component.html',
    styleUrls: ['./contas-encerradas-arquivo.component.css'],
})
export class ContasEncerradasArquivosComponent implements OnInit {

    private static IMPRIMIR_LOTE = 'Imprimir Lote';
    private static VISUALIZAR_LOTE = 'Visualizar Lote';
    arquivos: ArquivoImportacaoFinalizado[] = [];
    menuAcoes: string[] = [];

    shared: SharedService;
    usuario: Usuario;

    queryOptions = new QueryOptions({ pageSize: 20 });
    totalRecords = 0;
    loading = false;
    private postoSelecionado: number = null;

    constructor(
        private router: Router,
        private arquivoFinalizadoService: ArquivoImportacaoFinalizadoService,
        private contratoService: ContratoService,
        private dialogService: DialogService,
        public dialog: MatDialog,
        public appState: AppState,
    ) {
        const shared = SharedService.getInstance();
        this.usuario = shared.usuario;
    }

    ngOnInit(): void { }

    listarArquivos(pageNumber: number = 0, valoresArquivo: Partial<ValoresArquivoNovoRequestDTO> = {}): void {
        this.queryOptions.pageNumber = pageNumber + 1;
        this.loading = true;
        this.arquivoFinalizadoService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<ArquivoImportacaoFinalizado>>) => {
            if (response.success) {
                this.totalRecords = response.object.totalElements;
                this.mapearAcoes(response.object.content);
            } else {
                this.arquivos = [];
                this.dialogService.feedbackReturnAPI(response, '');
            }
            this.loading = false;
            valoresArquivo.dataInicial = valoresArquivo.dataInicial ? valoresArquivo.dataInicial : null;
            valoresArquivo.dataFinal = valoresArquivo.dataFinal ? valoresArquivo.dataFinal : null;
        });
    }

    onArquivosPage(page: number): void {
        this.listarArquivos(page);
    }

    onFilter(options: ArquivoImportacaoFinalizadoListFilterOptions): void {
        const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
        this.queryOptions.params = {
            postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
            dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
            dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
        };

        if (postoId !== this.postoSelecionado) {
            this.postoSelecionado = postoId;
        }

        this.listarArquivos(0, {
            dataInicial: options?.dataInicial,
            dataFinal: options?.dataFinal,
        });
    }

    mapearAcoes(arquivos: ArquivoImportacaoFinalizado[]): void {
        const mapearAcoes = (arquivo: ArquivoImportacaoFinalizado): ArquivoImportacaoFinalizado => {
            const acoes = [];
            acoes.push(ContasEncerradasArquivosComponent.IMPRIMIR_LOTE);
            acoes.push(ContasEncerradasArquivosComponent.VISUALIZAR_LOTE);
            return { ...arquivo, acoes };
        }

        this.arquivos = arquivos?.map(mapearAcoes) ?? [];
    }

    onAcaoClick(event: ArquivoImportacaoFinalizadoAcaoEvent): void {
        const actions = new Map<string, (arquivo: ArquivoImportacaoFinalizado) => void>()
            .set(ContasEncerradasArquivosComponent.IMPRIMIR_LOTE, (arquivo: ArquivoImportacaoFinalizado) => {
                this.imprimirLote(event.arquivo);
            })
            .set(ContasEncerradasArquivosComponent.VISUALIZAR_LOTE, (arquivo: ArquivoImportacaoFinalizado) => {
                this.visualizarLote(event.arquivo);
            })
        actions.get(event.acao)(event.arquivo);
    }

    imprimirLote(arquivo: ArquivoImportacaoFinalizado): void {
        this.contratoService.getRelatorioFinalizado(arquivo).subscribe(response => {
            const hoje = new Date(arquivo.dataCadastro);
            FileSaver.saveAs(response, `relatorio-finalizado-${arquivo.posto.descricao}-${moment(arquivo.dataInicial).format('DDMMYY')}_${moment(arquivo.dataFinal).format('DDMMYY')}.xlsx`);
            this.listarArquivos();
        });
    }

    visualizarLote(arquivo: ArquivoImportacaoFinalizado): void {
        this.router.navigate(['base/exportacao/contas-encerradas-arquivos/' + arquivo.id]);
    }
}