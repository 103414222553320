import { Component, OnInit } from '@angular/core';
import { Reagendamento } from '../../../domain/reagendamento.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ReagendamentoService } from '../../../services/reagendamento.service';
import { DialogService } from 'src/app/global/services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import {
  ReagendamentoAcaoEvent,
  ReagendamentoAcao,
  ReagendamentoListFilterOptions,
} from 'src/app/global/lists/reagendamento-list/reagendamento-list.component';
import { Page, ReturnAPI } from '../../../domain/return-api.model';
import { QuantidadeReagendamentosFuncionarioDTO } from '../../../domain/dto/quantidade-reagendamentos-funcionario.dto';
import { QuantidadeReagendamentosClienteDTO } from '../../../domain/dto/quantidade-reagendamentos-cliente.dto';
import { ValoresContratoRequestDTO } from 'src/app/base/domain/dto/valores-contrato-requesto.dto';

@Component({
  selector: 'app-acompanhamento-reagendamento-main',
  templateUrl: './acompanhamento-reagendamento-main.component.html',
  styleUrls: ['./acompanhamento-reagendamento-main.component.css'],
})
export class AcompanhamentoReagendamentoMainComponent implements OnInit {
  reagendamentos: Reagendamento[] = [];
  queryOptions = new QueryOptions({ pageSize: 10 });
  totalRecords = 0;
  loading = false;
  funcionarioReagendamentosDTO: QuantidadeReagendamentosFuncionarioDTO;
  clienteReagendamentosDTO: QuantidadeReagendamentosClienteDTO;
  listClienteReagendamentosDTO: QuantidadeReagendamentosClienteDTO[];
  byFuncionario = false;
  private postoSelecionado: number = null;
  totalizadorBruto: number;
  totalizadorLiquido: number;

  constructor(public dialogService: DialogService, private reagendamentoService: ReagendamentoService, public dialog: MatDialog) {
    this.getFuncionarioComMaisReagendamentos();
    this.getClienteComMaisReagendamentos();
    this.queryOptions.pageSort = '-dataOperacao';
  }

  ngOnInit(): void {}

  listarReagendamentos(pageNumber: number = 0, valoresContrato: Partial<ValoresContratoRequestDTO> = {}): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.reagendamentoService.pageAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Reagendamento>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.reagendamentos = response.object.content;
        this.mapearAcoes(response.object.content);

        if (this.byFuncionario) {
          this.getFuncionarioComMaisReagendamentosByFuncionario(this.reagendamentos[0].funcionario.id);
          this.getClienteComMaisReagendamentosByFuncionario(this.reagendamentos[0].funcionario.id);
          this.getClientesComMaisReagendamentosDeUmFuncionario(this.reagendamentos[0].funcionario.id);
        }
      } else {
        this.reagendamentos = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
      this.getValorTotalBruto({ ...valoresContrato, postoId: this.postoSelecionado });
      this.getValorTotalLiquido({ ...valoresContrato, postoId: this.postoSelecionado });
    });
  }

  mapearAcoes(reagendamentos: Reagendamento[]): void {
    const mapearAcoes = (reagendamento: Reagendamento): ReagendamentoAcao => {
      const acoes = [];
      return { ...reagendamento, acoes };
    };

    this.reagendamentos = reagendamentos?.map(mapearAcoes) ?? [];
  }

  onAcaoClick(event: ReagendamentoAcaoEvent): void {
    const actions = new Map<string, (reagendamento: Reagendamento) => void>();

    actions.get(event.acao)(event.reagendamento);
  }

  onLoadReagendamentoPage(page: number): void {
    this.listarReagendamentos(page);
  }

  onFilter(options: ReagendamentoListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    this.queryOptions.params = {
      clienteNome: options.clienteSelecionado ? options.clienteSelecionado : null,
      responsavelNome: options.funcionarioSelecionado ? options.funcionarioSelecionado : null,
      postoId,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
      descricaoRota: options.descricaoRota,
      dataGeradoInicial: options.dataGeradoInicial ? options.dataGeradoInicial.getTime() : null,
      dataGeradoFinal: options.dataGeradoFinal ? options.dataGeradoFinal.getTime() : null,
      dataReagendadoInicial: options.dataReagendadoInicial ? options.dataReagendadoInicial.getTime() : null,
      dataReagendadoFinal: options.dataReagendadoFinal ? options.dataReagendadoFinal.getTime() : null,
    };

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }

    if (options.funcionarioSelecionado != null) {
      this.byFuncionario = true;
    } else {
      this.byFuncionario = false;
      this.getFuncionarioComMaisReagendamentos(postoId);
      this.getClienteComMaisReagendamentos(postoId);
    }

    this.listarReagendamentos(0, {
      nome: options.clienteSelecionado ? options.clienteSelecionado : null,
      descricaoRota: options.descricaoRota,
      situacoesContrato: null,
      dataInicial: options?.dataInicial,
      dataFinal: options?.dataFinal,
      dataGeradoInicial: options?.dataGeradoInicial,
      dataCanceladoFinal: options?.dataGeradoFinal,
      dataReagendadoInicial: options?.dataGeradoInicial,
      dataReagendadoFinal: options?.dataReagendadoFinal
    });
  }

  getFuncionarioComMaisReagendamentos(postoId?: number): void {
    this.reagendamentoService
      .getFuncionarioComMaisReagendamentos(postoId)
      .subscribe((response: ReturnAPI<QuantidadeReagendamentosFuncionarioDTO>) => {
        this.funcionarioReagendamentosDTO = response.object;
      });
  }

  getClienteComMaisReagendamentos(postoId?: number): void {
    this.reagendamentoService
      .getClienteComMaisReagendamentos(postoId)
      .subscribe((response: ReturnAPI<QuantidadeReagendamentosClienteDTO>) => {
        this.clienteReagendamentosDTO = response.object;
      });
  }

  getFuncionarioComMaisReagendamentosByFuncionario(funcionarioId: number): void {
    this.reagendamentoService
      .getFuncionarioComMaisReagendamentosByFuncionario(funcionarioId)
      .subscribe((response: ReturnAPI<QuantidadeReagendamentosFuncionarioDTO>) => {
        this.funcionarioReagendamentosDTO = response.object;
      });
  }

  getClienteComMaisReagendamentosByFuncionario(funcionarioId: number): void {
    this.reagendamentoService
      .getClienteComMaisReagendamentosByFuncionario(funcionarioId)
      .subscribe((response: ReturnAPI<QuantidadeReagendamentosClienteDTO>) => {
        this.clienteReagendamentosDTO = response.object;
      });
  }

  getClientesComMaisReagendamentosDeUmFuncionario(funcionarioId: number): void {
    this.reagendamentoService
      .getClientesComMaisReagendamentosDeUmFuncionario(funcionarioId)
      .subscribe((response: ReturnAPI<QuantidadeReagendamentosClienteDTO[]>) => {
        this.listClienteReagendamentosDTO = response.object;
      });
  }

  getValorTotalBruto(valoresContrato: Partial<ValoresContratoRequestDTO>): void {
    this.reagendamentoService.getValorTotalBruto(valoresContrato).subscribe((response: ReturnAPI<number>) => {
      this.totalizadorBruto = response.object || 0;
    });
  }

  getValorTotalLiquido(valoresContrato: Partial<ValoresContratoRequestDTO>): void {
    this.reagendamentoService.getValorTotalLiquido(valoresContrato).subscribe((response: ReturnAPI<number>) => {
      this.totalizadorLiquido = response.object || 0;
    });
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
