import { Component, Inject, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Moment } from 'moment';
import { Cliente } from 'src/app/base/domain/cliente.model';
import { CobrancaJudicial } from 'src/app/base/domain/cobranca-judicial.model';
import { Periodicidade } from 'src/app/base/domain/enum/periodocidade.enum';
import { SituacaoCobrancaJudicial } from 'src/app/base/domain/enum/situacao-cobranca-judicial.enum';

export interface PequenaCausaFormData {
  cliente: Cliente;
  action: string;
}

@Component({
  selector: 'app-cadastrar-pequena-causa-form',
  templateUrl: './cadastrar-pequena-causa-form.component.html',
  styleUrls: ['./cadastrar-pequena-causa-form.component.css']
})
export class CadastrarPequenaCausaFormComponent implements OnInit {

  cobrancaJudicial = new CobrancaJudicial();
  title: string;
  dataEmissao: Date;

  periodicidades: Periodicidade[] = Object.values(Periodicidade);

  constructor(
    public dialogRef: MatDialogRef<CadastrarPequenaCausaFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PequenaCausaFormData
  ) {
    if (data.action === 'novo') {
      this.title = 'Cadastrar Pequena Causa';
    } else {
      this.title = 'Editar Pequena Causa';
    }

  }

  ngOnInit(): void { }

  onConfirm(): void {
    this.cobrancaJudicial.cliente = this.data.cliente;
    this.cobrancaJudicial.dataCadastro = this.dataEmissao;
    this.cobrancaJudicial.situacao = SituacaoCobrancaJudicial.EM_ANDAMENTO;
    this.cobrancaJudicial.posto = this.data.cliente.posto;

    if (this.data.action === 'novo') {
      this.cobrancaJudicial.valorPago = 0;
      this.cobrancaJudicial.valorRestante = this.cobrancaJudicial.valor;
    }

    this.dialogRef.close(this.cobrancaJudicial);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onPickerEventEmissao(event: MatDatepickerInputEvent<Moment>): void {
    this.cobrancaJudicial.dataCadastro = event.value?.toDate() || null;
    this.dataEmissao = new Date(this.cobrancaJudicial.dataCadastro);
  }

  fdsFilter = (d: Date | null): boolean => {
    const date = d ? new Date(d) : new Date();
    const day = date.getDay();
    return day !== 0 && day !== 6;
  }

}
