import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppState } from 'src/app/App.state';
import { DashboardCobranca } from 'src/app/base/domain/dashboard-cobranca.model';
import { DashBoardCobrancaService } from 'src/app/base/services/dashboard-cobranca.service';
import { DashboardCobrancaFilterOptions } from 'src/app/global/cobranca-dashboard/cobranca-dashboard.component';
import { QueryOptions } from 'src/app/global/domain/query.options';

@Component({
  selector: 'app-acompanhamento-posto-cobranca',
  templateUrl: './acompanhamento-posto-cobranca.component.html',
  styleUrls: ['./acompanhamento-posto-cobranca.component.css'],
})
export class AcompanhamentoPostoCobrancaComponent implements OnInit {
  dashboard: DashboardCobranca = new DashboardCobranca();

  data = new Date();
  postoId: number;
  estadoId: number;
  descricaoPosto = '';
  title = '';

  queryOptions = new QueryOptions({ pageSize: 10 });

  constructor(private service: DashBoardCobrancaService, private route: ActivatedRoute, public appState: AppState) {
    this.descricaoPosto = this.route.snapshot.queryParamMap.get('descricaoPosto').toString();

    this.route.params.subscribe((params: Params) => {
      this.postoId = params.id;
      if (this.route.snapshot.queryParamMap.has('estadoId')) {
        this.estadoId = +this.route.snapshot.queryParamMap.get('estadoId');
      }
    });
  }

  ngOnInit(): void {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    this.data = date;
    this.queryOptions.params = {
      dataInicial: this.route.snapshot.queryParamMap.get('dataInicial')
        ? this.route.snapshot.queryParamMap.get('dataInicial')
        : date.getTime(),
      dataFinal: this.route.snapshot.queryParamMap.get('dataFinal') ? this.route.snapshot.queryParamMap.get('dataFinal') : date.getTime(),
      estadoId: this.estadoId,
      postoId: this.postoId
    };

    this.getDashboard();
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  onReload(): void {
    this.data.setHours(0, 0, 0, 0);
    this.appState.isLoading = true;
    this.service.getDashboard(this.queryOptions).subscribe((response: DashboardCobranca) => {
      this.dashboard = response;
      this.title = `Acompanhamento de Cobrança do ${this.descricaoPosto}.`;
      this.appState.isLoading = false;
    });
  }

  getDashboard(): void {
    this.data.setHours(0, 0, 0, 0);
    this.appState.isLoading = true;
    this.service.getDashBoardWithoutRefresh(this.queryOptions).subscribe((response: DashboardCobranca) => {
      this.dashboard = response;
      this.title = `Acompanhamento de Cobrança do ${this.descricaoPosto}.`;
      this.appState.isLoading = false;
    });
  }

  onFilter(options: DashboardCobrancaFilterOptions): void {
    this.queryOptions.params = {
      postoId: options.postoSelecionado ? options.postoSelecionado.id : this.postoId,
      estadoId: options.estadoSelecionado ? options.estadoSelecionado.id : this.estadoId,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
      tipos: options.situacoes.length ? options.situacoes.toString() : null
    };

    this.getDashboard();
  }
}
