import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Caixa } from 'src/app/base/domain/caixa.model';
import { TransferenciaAcaoEvent } from '../../lists/transferencia-list/transferencia-list.component';
import { Transferencia } from 'src/app/base/domain/transferencia.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '../../services/dialog.service';
import { ConferirCaixaFormComponent } from '../../forms/conferir-caixa-form/conferir-caixa-form.component';
import { ResponseApi } from '../../domain/response.api.model';
import { ReturnAPI } from 'src/app/base/domain/return-api.model';
import { TransferenciaService } from 'src/app/base/services/transferencia.service';
import { DivergenciaCaixaGeralTO } from 'src/app/base/domain/dto/divergencia-caixa-geral.dto';

type CaixaAction = [string, string];
@Component({
  selector: 'app-caixa-movimentacoes',
  templateUrl: './caixa-movimentacoes.component.html',
  styleUrls: ['./caixa-movimentacoes.component.css'],
})
export class CaixaMovimentacoesComponent implements OnInit {
  private static CONFERENCIA_CAIXA_ACTION = 'Conferir Caixa';

  @Input() rowsNumber: number;
  @Input() totalRecords: number;
  @Input() transferencias: Transferencia[];
  @Input() loading: boolean;
  @Input() caixa: Caixa;
  @Input() optionalColumns: string;

  @Output() tabViewChange = new EventEmitter<number>();
  @Output() loadTransferenciasPage = new EventEmitter<number>();
  @Output() acaoClick = new EventEmitter<TransferenciaAcaoEvent>();

  divergenciaCaixaGeral = new DivergenciaCaixaGeralTO();

  constructor(public dialog: MatDialog, public dialogService: DialogService, private transferenciaService: TransferenciaService) { }

  caixaActions: CaixaAction[] = [
    [CaixaMovimentacoesComponent.CONFERENCIA_CAIXA_ACTION, 'Conferir caixa geral'],
  ];

  ngOnInit(): void { }

  tabViewDidChanged(event: number): void {
    this.tabViewChange.emit(event);
  }

  onLoadTransferencias(event: number): void {
    this.loadTransferenciasPage.emit(event);
  }

  onAcaoClick(event: TransferenciaAcaoEvent): void {
    this.acaoClick.emit(event);
  }

  lancarPendenciaCaixaGeral(): void {
    this.dialog.open(ConferirCaixaFormComponent, {
      width: '400px',
      data: {
        caixa: this.caixa,
      }
    }).afterClosed().subscribe((caixa: Caixa) => {
      this.divergenciaCaixaGeral.idPosto = caixa.posto.id;
      this.divergenciaCaixaGeral.idCaixa = caixa.id;
      this.divergenciaCaixaGeral.valor = caixa.valorDivergente;

      this.transferenciaService.lancarPendenciaCaixaGeral(this.divergenciaCaixaGeral).subscribe((response: ReturnAPI<Caixa>) => {
        this.dialogService.feedbackReturnAPI(response, 'Divergência lançada com sucesso!');
      },
        () => this.dialogService.feedbackError('Houve um erro na solicitacao!'));
    });
  }

  onAction(action: CaixaAction): void {
    const actions = new Map<string, () => void>().set(CaixaMovimentacoesComponent.CONFERENCIA_CAIXA_ACTION, () =>
      this.lancarPendenciaCaixaGeral(),
    );

    actions.get(action[0])();
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
