import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcompanhamentoRepassesPeriodoMainComponent } from './acompanhamento-repasses-periodo-main/acompanhamento-repasses-periodo-main.component';
import { GlobalModule } from 'src/app/global/global.module';



@NgModule({
  declarations: [AcompanhamentoRepassesPeriodoMainComponent],
  imports: [
    CommonModule, GlobalModule
  ]
})
export class AcompanhamentosRepassesPeriodoModule { }
