import { DashboardConciliacaoDTO } from '../../base/domain/dto/dashboard-conciliacao.dto';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface DashBoardFilterOptions {
  dataInicial: Date;
  dataFinal: Date;
  banco: number;
}

@Component({
  selector: 'app-conciliacao-dashboard',
  templateUrl: './conciliacao-dashboard.component.html',
  styleUrls: ['./conciliacao-dashboard.component.css']
})
export class ConciliacaoDashboardComponent implements OnInit {

  banco: number;
  @Input() title: string;
  @Input() dashboardBradesco: DashboardConciliacaoDTO;
  @Input() dashboardItau: DashboardConciliacaoDTO;
  @Input() filtros: string[];

  @Output() filter = new EventEmitter<DashBoardFilterOptions>();
  @Output() reloadEvent = new EventEmitter<number>();

  private readonly defaultFilterOptions: DashBoardFilterOptions = {
    dataInicial: null,
    dataFinal: null,
    banco: null,
  };

  filterOptions: DashBoardFilterOptions;

  constructor() {
    this.onLimparFiltros();
  }

  ngOnInit(): void { }

  onAtualizar(): void {
    this.reloadEvent.emit();
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

}
