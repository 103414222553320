import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonitoramentoRepassesMainComponent } from './monitoramento-repasses-main/monitoramento-repasses-main.component';
import { GlobalModule } from '../../../global/global.module';
import { ComponentsModule } from '../../../global/components/components.module';
import { ButtonModule, SharedModule, TableModule } from 'primeng';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [MonitoramentoRepassesMainComponent],
  imports: [
    CommonModule, GlobalModule, ComponentsModule, ButtonModule, SharedModule, TableModule, MatButtonModule,
  ],
})
export class MonitoramentoRepassesModule { }
