import { Resource } from 'src/app/global/domain/resource.model';
import { Usuario } from './usuario.model';
import { Endereco } from './endereco.model';
import { Telefone } from './telefone.model';
import { Socio } from './socio.model';

export class Pessoa extends Resource {
  public id: number;
  public assignedUser: Usuario;
  public user: Usuario;
  public ativo: boolean;
  public dataNascimento: Date;
  public date: string;
  public documento: string;
  public email: string;
  public name: string;
  public nome: string;
  public cpf: string;
  public nomeFantasia: string;
  public cnpj: string;
  public sexo: string;
  public rg: string;
  public tipoPessoa: string;
  public nomePessoa: string;
  public identificador: string;
  public nomeFantasiaPessoa: string;
  public type: 'pf' | 'pj';
  public listEndereco: Endereco[];
  public listTelefone: Telefone[];
  public listContaBancaria: any[]
  public listSocio?: Socio[];

  constructor() {
    super();
  }
}
