import { Resource } from '../../../global/domain/resource.model';
export class DashboardConciliacaoDTO extends Resource {
  valorTotalDoc: number;
  valorTotalTed: number;
  valorTotalTevEntrada: number;
  valorTotalTevSaida: number;
  valorTotalDinheiro: number;
  valorTotalPix: number;
  valorTotal: number;
  totalDoc: number;
  totalTed: number;
  totalTevEntrada: number;
  totalTevSaida: number;
  totalDinheiro: number;
  totalPix: number;
  total: number;
}
