export enum SituacaoInativarRenovacaoAutomatica {
  TODAS = 'TODAS',
  SEM_COMERCIO = 'SEM_COMERCIO',
  COMERCIO_INCOMPATIVEL = 'COMERCIO_INCOMPATIVEL',
  CREDITO_NAO_LIBERADO_PELA_ANALISE = 'CREDITO_NAO_LIBERADO_PELA_ANALISE',
  CLIENTE_NAO_E_O_PROPRIETARIO_DO_COMERCIO = 'CLIENTE_NAO_E_O_PROPRIETARIO_DO_COMERCIO',
  NAO_INATIVADO = 'CLIENTE_INATIVO',
  REVISITAR_COMERCIO = 'REVISITAR_COMERCIO',
  OBITO = 'OBITO',
  COMERCIO_ARRENDADO = 'COMERCIO_ARRENDADO',
}
