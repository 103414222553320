import { Resource } from 'src/app/global/domain/resource.model';
import { Caixa } from './caixa.model';
import { Posto } from './posto.model';
import { Funcionario } from './funcionario.model';

export class Transferencia extends Resource {
  caixaDividaFuncionario: Caixa;
  caixaFuncionario: Caixa;
  descricao: String;
  destino: Caixa;
  origem: Caixa;
  natureza: String;
  posto: Posto;
  postoDestino: Posto;
  saldoSobra: number;
  situacao: String;
  categoria: String;
  tipo: String;
  type: String;
  valorPrevisto: number;
  valorRealizado: number;
  urlComprovante: string;
  funcionario: Funcionario;
  postoDestinoUID?: string;

  constructor() {
    super();
  }
}

export class TransferenciaOdin {
  id: bigint;
  valor: number;
  caixaOrigemUID: string;
  caixaDestinoUID: string;
  referencia: bigint;
}
