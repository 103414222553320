import { Resource } from 'src/app/global/domain/resource.model';
import { Posto } from './posto.model';
import { Cliente } from './cliente.model';

export class ContaCliente extends Resource {
  public uid: String;

  public posto: Posto;

  public saldo: Number;

  public dataHoraAbertura: Date;

  public dataHoraEncerramento: Date;

  public situacao: String;

  public titular: Cliente;

  public tipo: String;
}
