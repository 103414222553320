import { Component } from '@angular/core';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { SharedService } from '../shared.service';
import { UserService } from 'src/app/base/services/user.service';
import { Router } from '@angular/router';
import { MessageService } from '../../global/components/message/message.service';
import { CurrentUser } from '../../global/domain/current-user.model';
import { AppState } from 'src/app/App.state';
import { DialogService } from 'src/app/global/services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { SelecionarProdutoFormComponent } from 'src/app/global/forms/selecionar-produto-form/selecionar-produto-form.component';
import { Produto } from 'src/app/base/domain/produto.model';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  usuario = new Usuario();
  shared: SharedService;
  message: string;

  constructor(
    private userService: UserService,
    private router: Router,
    private notificador: MessageService,
    public appState: AppState,
    public dialogService: DialogService,
    public dialog: MatDialog,
  ) {
    this.shared = SharedService.getInstance();
  }

  login(): void {
    this.message = '';
    this.appState.isLoading = true;
    this.userService.login(this.usuario).subscribe(
      (userAuthentication: CurrentUser) => {
        this.appState.isLoading = false;
        this.shared.logando(userAuthentication);
        this.usuario = userAuthentication.usuario;

        if (this.usuario.email === 'luis@gjbl.com.br') {
          const produto = new Produto();
          produto.url = 'http://api.valores.ce.gjbl.com.br/';
          produto.descricao = 'valores';
          produto.ativo = true;
          produto.id = 14;

          sessionStorage.setItem('produto', JSON.stringify(produto));
          this.router.navigate(['/base/gestao/faturamento/acompanhamento-faturamento']);
        } else {
          this.selecionarProduto(this.usuario);
        }
      },
      () => {
        this.appState.isLoading = false;
        this.shared.token = null;
        this.shared.usuario = null;
        this.shared.showTemplate.emit(false);
        this.notificador.messageErro('Email ou Senha Inválido!');
        this.dialogService.feedbackInfo('Email ou Senha Inválido!');
      },
    );
  }

  selecionarProduto(user: Usuario): void {
    this.dialog
      .open(SelecionarProdutoFormComponent, {
        data: {
          usuario: user,
        },
        width: '300px',
      })
      .afterClosed()
      .subscribe((result: Produto) => {
        if (result) {
          sessionStorage.setItem('produto', JSON.stringify(result));
          this.router.navigate(['/base']);
        }
      });
  }
}
