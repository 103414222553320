import { Component, OnInit, Inject } from '@angular/core';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Estado } from 'src/app/base/domain/estado.model';
import { QueryOptions } from '../../domain/query.options';
import { EstadoService } from 'src/app/base/services/estado.service';

export interface UsuarioFormData {
  usuario: Usuario;
  action: string;
}

@Component({
  selector: 'app-usuario-form',
  templateUrl: './usuario-form.component.html',
  styleUrls: ['./usuario-form.component.css'],
})
export class UsuarioFormComponent implements OnInit {
  usuario = new Usuario();
  title = '';
  estados: Estado[] = [];
  queryOptions = new QueryOptions();

  Profiles = ['ROLE_ADMIN', 'ROLE_DIRETOR', 'ROLE_PRESTADOR', 'ROLE_GESTOR', 'ROLE_IMPORTACAO', 'ROLE_CAPTADOR', 'ROLE_RETAGUARDA', 'ROLE_FISCAL'];

  constructor(
    public dialogRef: MatDialogRef<UsuarioFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UsuarioFormData,
    private estadoService: EstadoService,
  ) {
    this.estadoService.list(this.queryOptions).subscribe((response: Estado[]) => {
      this.estados = response;
    });

    if (data.action === 'update') {
      this.title = 'Editar';
      this.usuario = data.usuario;
    } else {
      this.title = 'Novo';
    }
  }

  ngOnInit(): void { }

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(this.usuario);
  }
}
