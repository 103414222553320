import { ClienteSelectDTO } from './../domain/dto/cliente-select.dto';
import { OrchestratorCliente } from './../domain/orchestrator-cliente.model';
import { ResourceService } from 'src/app/global/services/resource.service';
import { Cliente } from '../domain/cliente.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WEB_API } from 'src/app/global/services/web-api';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ReturnAPI, Page } from '../domain/return-api.model';
import { Observable } from 'rxjs';
import { ClienteSerializer } from '../serializables/cliente.serializer';
import { AuditoriaClienteDTO } from '../domain/dto/auditoria-cliente.dto';
import { ClienteEnderecoRotaDTO } from '../domain/dto/cliente-endereco-rota.dto';
import { Produto } from '../domain/produto.model';
import { ClientePostoDTO } from '../domain/dto/cliente-posto.dto';
import { EditarTelefoneDTO } from '../domain/dto/editar-telefone.dto';

@Injectable()
export class ClienteService extends ResourceService<Cliente> {
  static RESOURCE = 'cliente';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, ClienteService.RESOURCE, new ClienteSerializer(Cliente));
  }

  findAll(queryOption: QueryOptions): Observable<ReturnAPI<Page<Cliente>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Cliente>>>(
      `${WEB_API}/${ClienteService.RESOURCE}?${queryOption.toQueryString()}&${this.produtoId}`,
    );
  }

  findOne(clienteID: number): Observable<ReturnAPI<Cliente>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Cliente>>(
      `${WEB_API}/${ClienteService.RESOURCE}/findOne?clienteId=${clienteID}&${this.produtoId}`,
    );
  }

  findAllInativos(queryOption: QueryOptions): Observable<ReturnAPI<Cliente>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Cliente>>(
      `${WEB_API}/${ClienteService.RESOURCE}/listar-clientes-inativos?${queryOption.toQueryString()}&${this.produtoId}`,
    );
  }

  desbloquearCliente(auditoriaClienteDTO: AuditoriaClienteDTO): Observable<ReturnAPI<Cliente>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<Cliente>>(
      `${WEB_API}/${ClienteService.RESOURCE}/desbloquear?${this.produtoId}`,
      auditoriaClienteDTO,
    );
  }

  atualizarPostoCliente(dto: ClientePostoDTO): Observable<ReturnAPI<Cliente>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<Cliente>>(`${WEB_API}/${ClienteService.RESOURCE}/atualizar-posto-cliente?${this.produtoId}`, dto);
  }

  atualizarSituacaoCliente(auditoriaClienteDTO: AuditoriaClienteDTO): Observable<ReturnAPI<Cliente>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<Cliente>>(
      `${WEB_API}/${ClienteService.RESOURCE}/atualizar-situacao-cliente?${this.produtoId}`,
      auditoriaClienteDTO,
    );
  }

  atualizarEnderecoComRota(clienteEnderecoRotaDTO: Partial<ClienteEnderecoRotaDTO>): Observable<ReturnAPI<Cliente>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<Cliente>>(
      `${WEB_API}/${ClienteService.RESOURCE}/atualizar-endereco-com-rota?${this.produtoId}`,
      clienteEnderecoRotaDTO,
    );
  }

  saveClienteVendedorExterno(cliente: Cliente): Observable<ReturnAPI<Cliente>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<Cliente>>(
      `${WEB_API}/${ClienteService.RESOURCE}/save-cliente-vendedor-externo?${this.produtoId}`,
      cliente,
    );
  }

  editarTelefoneCliente(dto: EditarTelefoneDTO): Observable<ReturnAPI<Cliente>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<Cliente>>(`${WEB_API}/${ClienteService.RESOURCE}/editar-telefone-cliente?${this.produtoId}`, dto);
  }

  getRelatorioDeClientesCobrancaJudicial(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ClienteService.RESOURCE}/relatorio-clientes-cobranca-judicial?${options.toQueryString(false)}&${this.produtoId}`,
      { responseType: 'blob' },
    );
  }

  verificarClienteOrchestrator(options: QueryOptions): Observable<OrchestratorCliente[]> {
    this.getProduto();
    return this.httpClient.get<OrchestratorCliente[]>(
      `${WEB_API}/inovamind/orchestrator-cliente?${options.toQueryString(false)}&${this.produtoId}`,
    );
  }

  requestOrchestrator(options: QueryOptions): Observable<OrchestratorCliente[]> {
    this.getProduto();
    return this.httpClient.post<OrchestratorCliente[]>(
      `${WEB_API}/inovamind/orchestrator-request?${options.toQueryString(false)}&${this.produtoId}`,
      null,
    );
  }

  desbloquearClienteDivida(auditoriaClienteDTO: AuditoriaClienteDTO): Observable<ReturnAPI<Cliente>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<Cliente>>(
      `${WEB_API}/${ClienteService.RESOURCE}/desbloquear-cliente-divida?${this.produtoId}`,
      auditoriaClienteDTO,
    );
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }

  getRelatorioAuditoriaClienteDuvidoso(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ClienteService.RESOURCE}/relatorio-auditoria-devedor-duvidoso?${options.toQueryString(false)}&${this.produtoId}`,
      { responseType: 'blob' },
    );
  }

  getSelect(queryOption: QueryOptions): Observable<ClienteSelectDTO[]> {
    return this.httpClient.get<ClienteSelectDTO[]>(`${WEB_API}/${ClienteService.RESOURCE}/select?${queryOption.toQueryString(false)}`);
  }
}
