import { ComponentsModule } from './../../../global/components/components.module';
import { AcompanhamentoPostoDespesaComponent } from './acompanhamento-posto-despesa/acompanhamento-posto-despesa.component';
import { AcompanhamentoEstadoDespesasComponent } from './acompanhamento-estado-despesa/acompanhamento-estado-despesa.component';
import { AcompanhamentoDespesaMainComponent } from './acompanhamento-despesa-main/acompanhamento-despesa-main.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GlobalModule } from 'src/app/global/global.module';

@NgModule({
    imports: [
        CommonModule,
        GlobalModule,
        ComponentsModule
    ],
    declarations: [
        AcompanhamentoDespesaMainComponent,
        AcompanhamentoEstadoDespesasComponent,
        AcompanhamentoPostoDespesaComponent
    ]
})
export class AcompanhamentoDespesaModule { }
