import { Usuario } from './../domain/usuario.model';
import { AcaoDeVenda } from './../domain/acao-de-venda.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { Produto } from '../domain/produto.model';
import { AcaoDeVendaSerializer } from '../serializables/acao-de-venda.serializer';
import { Page, ReturnAPI } from '../domain/return-api.model';

@Injectable()
export class AcaoDeVendaService extends ResourceService<AcaoDeVenda> {
  static RESOURCE = 'acao-de-venda';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${AcaoDeVendaService.RESOURCE}`, new AcaoDeVendaSerializer(AcaoDeVenda));
  }

  findAll(queryOptions: QueryOptions): Observable<AcaoDeVenda[]> {
    this.getProduto();
    return this.httpClient.get<AcaoDeVenda[]>(
      `${WEB_API}/${AcaoDeVendaService.RESOURCE}?${queryOptions.toQueryString()}&${this.produtoId}`,
    );
  }

  findPage(queryOptions: QueryOptions): Observable<ReturnAPI<Page<AcaoDeVenda>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<AcaoDeVenda>>>(
      `${WEB_API}/${AcaoDeVendaService.RESOURCE}?${queryOptions.toQueryString()}&${this.produtoId}`,
    );
  }

  inserirAcaoDeVendaParaCliente(clienteId: number, usuario: Usuario): Observable<ReturnAPI<AcaoDeVenda>> {
    this.getProduto();
    const body = {
      cliente: {
        id: clienteId,
      },
      usuarioHeimdallId: usuario.id,
      captadorNome: usuario.nome,
    };
    return this.httpClient.post<ReturnAPI<AcaoDeVenda>>(`${WEB_API}/${AcaoDeVendaService.RESOURCE}?${this.produtoId}`, body);
  }

  atualizar(acaoDeVenda: AcaoDeVenda): Observable<ReturnAPI<AcaoDeVenda>> {
    return this.httpClient.put<ReturnAPI<AcaoDeVenda>>(`${WEB_API}/${AcaoDeVendaService.RESOURCE}?${this.produtoId}`, acaoDeVenda);
  }

  reenviarSMS(acaoDeVenda: AcaoDeVenda): Observable<ReturnAPI<AcaoDeVenda>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<AcaoDeVenda>>(
      `${WEB_API}/${AcaoDeVendaService.RESOURCE}/reenviar-sms?${this.produtoId}`,
      acaoDeVenda,
    );
  }

  reenviarMultiplosSMS(acaoDeVenda: AcaoDeVenda[], options: QueryOptions): Observable<ReturnAPI<AcaoDeVenda>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<AcaoDeVenda>>(
      `${WEB_API}/${AcaoDeVendaService.RESOURCE}/reenviar-multiplos-sms?${options.toQueryString(false)}&${this.produtoId}`,
      acaoDeVenda,
    );
  }

  preAprovarMultiplo(options: QueryOptions): Observable<ReturnAPI<AcaoDeVenda>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<AcaoDeVenda>>(
      `${WEB_API}/${AcaoDeVendaService.RESOURCE}/pre-aprovar-multiplo?${options.toQueryString(false)}&${this.produtoId}`, null
    );
  }

  gerarRelatorioDeAcaoDeVendas(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${AcaoDeVendaService.RESOURCE}/imprimir-relatorio-acao-de-vendas?${options.toQueryString(false)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }
}
