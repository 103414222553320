import { Resource } from 'src/app/global/domain/resource.model';
import { Posto } from './posto.model';
import { Usuario } from './usuario.model';

export class PlanejamentoSemanaCaptacao extends Resource {
  usuario: Usuario;
  dataInicial: Date;
  dataFinal: Date;
  dataCadastro: Date;
  posto: Posto;
  idPosto: number;
  nomePosto: string;
}
