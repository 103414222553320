import { Resource } from 'src/app/global/domain/resource.model';
import { Cliente } from './cliente.model';
import { Periodicidade } from './enum/periodocidade.enum';
import { SituacaoCobrancaJudicial } from './enum/situacao-cobranca-judicial.enum';
import { Posto } from './posto.model';

export class CobrancaJudicial extends Resource {
  advogado: string;

  observacao: string;

  numeroProcesso: string;

  vara: string;

  quantidadeParcelas: number;

  valor: number;

  valorPago: number;

  valorRestante: number;

  vencimento: Date;

  dataCadastro: Date;

  periodicidade: Periodicidade;

  situacao: SituacaoCobrancaJudicial;

  cliente: Cliente;

  posto: Posto;
}
