import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SituacaoParcela } from 'src/app/base/domain/enum/situacao-parcela.enum';
import { ParcelaCobrancaJudicial } from 'src/app/base/domain/parcela-cobranca-judicial.model';

export interface BaixaParcelaFormData {
  parcela: ParcelaCobrancaJudicial;
  action: string;
}

@Component({
  selector: 'app-baixa-parcela-cobranca-judicial-form',
  templateUrl: './baixa-parcela-cobranca-judicial-form.component.html',
  styleUrls: ['./baixa-parcela-cobranca-judicial-form.component.css']
})
export class BaixaParcelaCobrancaJudicialFormComponent implements OnInit {

  parcela: ParcelaCobrancaJudicial = new ParcelaCobrancaJudicial();

  constructor(
    public dialogRef: MatDialogRef<BaixaParcelaCobrancaJudicialFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BaixaParcelaFormData
  ) {
    this.parcela = data.parcela;
  }

  ngOnInit(): void { }

  onConfirm(): void {
    this.parcela.dataBaixa = new Date();
    this.parcela.situacao = SituacaoParcela.QUITADA;
    this.dialogRef.close(this.parcela);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

}
