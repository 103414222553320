import { Resource } from 'src/app/global/domain/resource.model';
import { Posto } from './posto.model';
import { Funcionario } from './funcionario.model';

export class Caixa extends Resource {
  public uid: String;

  public posto: Posto;

  public saldo: number;

  public dataHoraAbertura: Date;

  public dataHoraFechamento: Date;

  public situacao: String;

  public type: String;

  public tipo: String;

  public funcionario: Funcionario;

  public valorDivergente: number;

  constructor() {
    super();
  }
}
