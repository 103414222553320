export enum SituacaoCliente {
  NOVO = 'NOVO',
  ATIVO = 'ATIVO',
  INATIVO = 'INATIVO',
  INATIVO_COM_PENDENCIA = 'INATIVO_COM_PENDENCIA',
  REJEITADO = 'REJEITADO',
  ADICIONADO_AO_SPC = 'ADICIONADO_AO_SPC',
  BLOQUEADO = 'BLOQUEADO',
  DEVEDOR_DUVIDOSO = 'DEVEDOR_DUVIDOSO',
  REATIVADO = 'REATIVADO',
  COBRANCA_JUDICIAL = 'COBRANCA_JUDICIAL',
  EM_TRANSFERENCIA = 'EM_TRANSFERENCIA',
  BLOQUEADO_DIVIDA = 'BLOQUEADO COM DIVIDA',
}
