import { Component } from '@angular/core';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { AppState } from 'src/app/App.state';
import { Feriado } from 'src/app/base/domain/feriado.model';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import { FeriadoListFilterOptions, FeriadoAcaoEvent } from 'src/app/global/lists/feriado-list/feriado-list.component';
import { MatDialog } from '@angular/material/dialog';
import { FeriadoFormComponent, FeriadoFormData } from 'src/app/global/forms/feriado-form/feriado-form.component';
import { FeriadoService } from 'src/app/base/services/feriado.service';
import { DialogService } from 'src/app/global/services/dialog.service';

@Component({
  selector: 'app-acompanhamento-feriado',
  templateUrl: './acompanhamento-feriado-main.component.html',
  styleUrls: ['./acompanhamento-feriado-main.component.css'],
})
export class AcompanhamentoFeriadoMainComponent {
  private static DELETE_ACTION = 'Deletar Feriado';

  feriados: Feriado[];
  totalRecords = 0;

  queryOptions = new QueryOptions({ pageSize: 10, pageSort: '-id' });

  private postoSelecionado: number = null;
  private dataInicialSelecionada: Date = null;
  private dataFinalSelecionada: Date = null;

  constructor(
    private feriadoService: FeriadoService,
    public dialog: MatDialog,
    public appState: AppState,
    public dialogService: DialogService,
  ) {}

  mapearAcoesDeFeriados(feriados: Feriado[]): void {
    const mapearAcoes = (feriado: Feriado): Feriado => {
      const acoes = [];
      if (feriado.tipoFeriado === 'NAO_PROGRAMADO') {
        acoes.push(AcompanhamentoFeriadoMainComponent.DELETE_ACTION);
      }
      return { ...feriado, acoes };
    };
    this.feriados = feriados?.map(mapearAcoes) ?? [];
  }

  listarFeriados(page: number = 0): void {
    this.queryOptions.pageNumber = page + 1;
    this.appState.isLoading = true;
    this.feriadoService.findAll(this.queryOptions).subscribe(
      (response: ReturnAPI<Page<Feriado>>) => {
        if (response.success) {
          this.mapearAcoesDeFeriados(response.object.content);
          this.totalRecords = response.object.totalElements;
        }
        this.appState.isLoading = false;
      },
      () => (this.appState.isLoading = false),
    );
  }

  async adicionarNovosFeriados(feriados: Feriado[]): Promise<void> {
    this.appState.isLoading = true;
    try {
      const response = await this.feriadoService.saveBulk(feriados).toPromise();
      this.appState.isLoading = false;
      this.dialogService.feedbackReturnAPI(response, 'Feriado adicionado com sucesso!');
      if (response.success) {
        this.listarFeriados();
      }
    } catch {
      this.appState.isLoading = false;
      this.dialogService.feedbackError('Não foi possível adicionar feriado!');
    }
  }

  onLoadFeriados(pageNumber: number): void {
    this.listarFeriados(pageNumber);
  }

  async onNovo(): Promise<void> {
    const novosFeriados = await this.dialog
      .open<FeriadoFormComponent, FeriadoFormData, Feriado[]>(FeriadoFormComponent, {
        width: '800px',
        data: { toEdit: true },
      })
      .afterClosed()
      .toPromise();

    if (novosFeriados.length) {
      this.adicionarNovosFeriados(novosFeriados);
    }
  }

  async onDelete(feriado: Feriado): Promise<void> {
    this.appState.isLoading = true;
    try {
      const response = await this.feriadoService.deleteById(feriado.id).toPromise();
      this.appState.isLoading = false;
      this.dialogService.feedbackReturnAPI(response, 'Feriado removido com sucesso!');
      if (response.success) {
        this.listarFeriados();
      }
    } catch {
      this.appState.isLoading = false;
      this.dialogService.feedbackError('Ocorreu um erro ao deletar feriado!');
    }
  }

  onAcaoClick(event: FeriadoAcaoEvent): void {
    const actions = new Map<string, (feriado: Feriado) => void>().set(
      AcompanhamentoFeriadoMainComponent.DELETE_ACTION,
      (feriado: Feriado) => {
        this.dialogService.confirmDialog('Deseja realmente deletar esse feriado?', () => this.onDelete(feriado));
      },
    );

    actions.get(event.acao)(event.feriado);
  }

  onFilter(options: FeriadoListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    const dataInicial = options.dataInicial || null;
    const dataFinal = options.dataFinal || null;
    this.queryOptions.params = {
      ...this.queryOptions.params,
      nome: options.nome ? `like:${options.nome}` : null,
      dataInicio: dataInicial?.toLocaleString(),
      dataFinal: dataFinal?.toLocaleString(),
      tipoFeriado: options.tipoFeriado?.length ? `in:${options.tipoFeriado.join(',')}` : null,
      postoId,
    };

    this.listarFeriados();

    if (dataInicial !== this.dataInicialSelecionada || dataFinal !== this.dataFinalSelecionada) {
      this.dataInicialSelecionada = dataInicial;
      this.dataFinalSelecionada = dataFinal;
    }

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }
  }
}
