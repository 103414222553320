import { Resource } from 'src/app/global/domain/resource.model';
import { Posto } from './posto.model';
import { Caixa } from './caixa.model';
import { TipoPendenciaFechamentoCaixa } from './enum/tipo-pendencia-fechamento-caixa.enum';

export class PendenciaFechamentoCaixa extends Resource {
  dataCriacao: Date;

  tipoPendenciaFechamentoCaixa: TipoPendenciaFechamentoCaixa;

  mensagem: string;

  posto: Posto;

  caixaFuncionario: Caixa;

  situacao: string;
}
