import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CardListComponent } from '../../components/card-list/card-list.component';
import { NgForm } from '@angular/forms';
import { Table } from 'primeng/table';
import { Contrato } from 'src/app/base/domain/contrato.model';
import { LazyLoadEvent } from 'primeng/api';
import { SituacaoContrato } from 'src/app/base/domain/enum/situacao-contrato.enum';
import { Posto } from 'src/app/base/domain/posto.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DialogService } from '../../services/dialog.service';
import { FuncionarioService } from '../../../base/services/funcionario.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker/datepicker-input-base';
import { Moment } from 'moment';

export interface ContratoAcaoEvent {
  acao: string;
  contrato: Contrato;
}

export interface ContratoListFilterOptions {
  idCliente: string;
  nomeCliente: string;
  situacoesContrato: SituacaoContrato[];
  postoSelecionado: Posto;
  repassePendente: boolean;
  dataInicial: Date;
  dataFinal: Date;
  dataGeradoInicial: Date;
  dataGeradoFinal: Date;
  dataCanceladoInicial: Date;
  dataCanceladoFinal: Date;
  descricaoRota: string;
  id: string;
}

@Component({
  selector: 'app-contrato-list',
  templateUrl: './contrato-list.component.html',
  styleUrls: ['./contrato-list.component.css'],
})
export class ContratoListComponent implements OnInit {
  @ViewChild('searchForm', { static: false }) searchForm: NgForm;
  @ViewChild('dataTable', { static: false }) dataTable: Table;
  @ViewChild(CardListComponent, { static: true }) cardList: CardListComponent;

  @Input() contratos: Contrato[];
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() loading: boolean;
  @Input() title: string;
  @Input() nomePlaceholder: string;
  @Input() dataPlaceholder: string;
  @Input() filtros: string[];
  @Input() options: string[];
  @Input() optionalColumns: string[];
  @Input() menuAcoes: string[];
  @Input() lazy: boolean;
  @Input() maxDate: Date = null;
  @Input() dateReadOnly: boolean = false;

  @Output() loadContratosPage = new EventEmitter<number>();
  @Output() disclosureContrato = new EventEmitter<Contrato>();
  @Output() acaoClick = new EventEmitter<ContratoAcaoEvent>();
  @Output() filter = new EventEmitter<ContratoListFilterOptions>();
  @Output() cancelarContratos = new EventEmitter<Contrato[]>();
  @Output() transferirContratos = new EventEmitter<Contrato[]>();
  @Output() transferirCobrancas = new EventEmitter<Contrato[]>();
  @Output() exportarLote = new EventEmitter<Contrato[]>();
  @Output() selectAllContratos = new EventEmitter<boolean>();
  @Output() visualizarLote = new EventEmitter<boolean>();

  private currentPage = 0;
  isAllContratoSelected = false;

  situacoesContrato: SituacaoContrato[] = Object.values(SituacaoContrato);

  contratosSelecionados: Contrato[] = [];

  filterOptions: ContratoListFilterOptions;

  private readonly defaultFilterOptions: ContratoListFilterOptions = {
    idCliente: null,
    nomeCliente: '',
    situacoesContrato: [],
    postoSelecionado: null,
    repassePendente: null,
    dataInicial: null,
    dataFinal: null,
    descricaoRota: '',
    dataGeradoInicial: null,
    dataGeradoFinal: null,
    dataCanceladoInicial: null,
    dataCanceladoFinal: null,
    id: null,
  };

  constructor(private funcionarioService: FuncionarioService, public dialog: MatDialog, public dialogService: DialogService) { }

  ngOnInit(): void {
    this.onLimparFiltros();
  }

  loadContratos(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadContratosPage.emit(pageNumber);
  }

  onDisclosure(contrato: Contrato): void {
    this.disclosureContrato.emit(contrato);
  }

  onAcaoClick(acao: string, domain: Contrato): void {
    this.acaoClick.emit({ acao, contrato: domain });
  }

  onAtualizar(): void {
    this.loadContratosPage.emit(this.currentPage);
  }

  onLimparFiltros(): void {
    if (this.filterOptions) {
      this.filterOptions.dataInicial = null;
      this.filterOptions.dataFinal = null;
      this.filterOptions.dataGeradoInicial = null;
      this.filterOptions.dataGeradoFinal = null;
      this.filterOptions.dataCanceladoInicial = null;
      this.filterOptions.dataCanceladoFinal = null;
      this.filterOptions.id = null;
    }
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  onCheckboxChange($event: MatCheckboxChange): void {
    this.contratosSelecionados = this.contratos.filter((contrato: Contrato) => contrato.selecionado).map((contrato: Contrato) => contrato);
    if (this.isAllContratoSelected) {
      this.isAllContratoSelected = false;
      this.selectAllContratos.emit(false);
    }
  }

  selectAll(): void {
    this.contratos.forEach((element: Contrato) => {
      element.selecionado = true;
    });

    this.contratosSelecionados = this.contratos.filter((contrato: Contrato) => contrato.selecionado).map((contrato: Contrato) => contrato);

    this.isAllContratoSelected = true;
    this.selectAllContratos.emit(true);
  }

  unselectAll(): void {
    this.contratos.forEach((element: Contrato) => {
      element.selecionado = false;
    });

    this.contratosSelecionados = [];

    this.isAllContratoSelected = false;
    this.selectAllContratos.emit(false);
  }

  public onMenuAcaoClick(acao: string): void {
    if (acao === 'Cancelar Contratos Selecionados') {
      this.cancelarContratos.emit(this.contratosSelecionados);
    } else if (acao === 'Transferir Responsavel') {
      this.transferirContratos.emit(this.contratosSelecionados);
    } else if (acao === 'Transferir Cobranças') {
      this.transferirCobrancas.emit(this.contratosSelecionados);
    } else if (acao === 'Exportar Lote') {
      this.exportarLote.emit(this.contratosSelecionados);
    } else if (acao === 'Visualizar Lote') {
      this.visualizarLote.emit(true);
    }
  }

  onPickerEvent(dataGeracao: string, evento: MatDatepickerInputEvent<Moment>): void {
    if (dataGeracao === 'dataContratoGeracaoInicial') {
      this.filterOptions.dataGeradoInicial = evento.value?.toDate();
    }
    if (dataGeracao === 'dataContratoGeracaoFinal') {
      this.filterOptions.dataGeradoFinal = evento.value?.toDate();
    }
    if (dataGeracao === 'dataCancelamentoInicial') {
      this.filterOptions.dataCanceladoInicial = evento.value?.toDate();
    }
    if (dataGeracao === 'dataCancelamentoFinal') {
      this.filterOptions.dataCanceladoFinal = evento.value?.toDate();
    }
  }
}
