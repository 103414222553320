import { Component, OnInit } from '@angular/core';
import { QueryFilter } from '../../motivo-contrato-cancelado/motivo-contrato-cancelado-main/motivo-contrato-cancelado-main.component';
import { CaixaService } from '../../../services/caixa.service';

@Component({
  selector: 'app-monitoramento-lancamento-entrada-main',
  templateUrl: './monitoramento-lancamento-entrada-main.component.html',
  styleUrls: ['./monitoramento-lancamento-entrada-main.component.css']
})
export class MonitoramentoLancamentoEntradaMainComponent implements OnInit {

  private readonly defaultQueryFilter: QueryFilter = {
    dataFinal: null,
    dataInicial: null,
    posto: null,
    funcionario: null
  };

  qtdCobrancasAReceber = 0;
  qtdCobrancasRecebidas = 0;
  valorAReceber = 0;
  valorRecebido = 0;
  valorDespesa = 0;
  valorComplemento = 0;
  monitoramento: any[];
  filterOptions: QueryFilter = this.defaultQueryFilter;

  constructor(
    private caixaService: CaixaService,
  ) {  }

  ngOnInit(): void {  }

  reloadPage(): void {
    if (this.filterOptions.posto.id != null) {
      this.caixaService.monitoramentoDeLancamentoDeEntrada(this.filterOptions.posto.id).subscribe(data => {
        this.monitoramento = data.object;
        this.montagemDosCrds(data.object);
      });
    }
  }

  private montagemDosCrds(object) {

    this.qtdCobrancasAReceber = 0;
    this.qtdCobrancasRecebidas = 0;
    this.valorAReceber = 0;
    this.valorRecebido = 0;
    this.valorDespesa = 0;
    this.valorComplemento = 0;

    this.qtdCobrancasAReceber = object.map(m=>m.qtdCobrancasAReceber).reduce((soma, acumulador) => soma + acumulador, 0);
    this.qtdCobrancasRecebidas = object.map(m=>m.qtdCobrancasRecebidas).reduce((soma, acumulador) => soma + acumulador, 0);
    this.valorAReceber = object.map(m=>m.valorAReceber).reduce((soma, acumulador) => soma + acumulador, 0);
    this.valorRecebido = object.map(m=>m.valorRecebido).reduce((soma, acumulador) => soma + acumulador, 0);
    this.valorDespesa = object.map(m=>m.valorDespesa).reduce((soma, acumulador) => soma + acumulador, 0);
    this.valorComplemento = object.map(m=>m.valorComplemento).reduce((soma, acumulador) => soma + acumulador, 0);
    }

}
