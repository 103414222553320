import { Component, OnInit } from '@angular/core';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { Transferencia } from 'src/app/base/domain/transferencia.model';
import { TransferenciaService } from 'src/app/base/services/transferencia.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/global/services/dialog.service';
import { AppState } from 'src/app/App.state';
import {
  TransferenciaAcaoEvent,
  TransferenciaListFilterOptions,
} from 'src/app/global/lists/transferencia-list/transferencia-list.component';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import { ValoresTransferenciaRequestDTO } from 'src/app/base/domain/dto/valores-transferencia-request.dto';

@Component({
  selector: 'app-acompanhamento-transferencia-entre-postos-main',
  templateUrl: './acompanhamento-transferencia-entre-postos-main.component.html',
  styleUrls: ['./acompanhamento-transferencia-entre-postos-main.component.css'],
})
export class AcompanhamentoTransferenciaEntrePostosMainComponent implements OnInit {
  private queryOptions = new QueryOptions({ pageSize: 10, pageSort: '-id', params: { type: 'tctct' } });

  transferencias: Transferencia[];
  totalRecords: number;
  loading = false;
  optionalColumns = ['descricao', 'natureza', 'origem', 'destino', 'situacao'];

  private postoSelecionado: number = null;
  private dataInicialSelecionada: Date = null;
  private dataFinalSelecionada: Date = null;

  totalizador = 0;

  constructor(
    private transferenciaService: TransferenciaService,
    public dialog: MatDialog,
    public dialogService: DialogService,
    public appState: AppState,
  ) {}

  ngOnInit(): void {
    this.listarTransferencias();
  }

  onLoadTransferencias(event: number): void {
    this.listarTransferencias(event);
  }

  listarTransferencias(page: number = 0, valores: Partial<ValoresTransferenciaRequestDTO> = {}): void {
    this.queryOptions.pageNumber = page + 1;
    this.loading = true;
    this.transferenciaService.pageTransferencias(this.queryOptions).subscribe((response: ReturnAPI<Page<Transferencia>>) => {
      this.totalRecords = response.object.totalElements;
      this.transferencias = response.object.content;
      valores.tipo = 'TCTCT';
      valores.dataInicial = valores.dataInicial ? valores.dataInicial : null;
      valores.dataFinal = valores.dataFinal ? valores.dataFinal : null;
      valores.nome = valores.nome ? valores.nome : '';
      valores.descricaoRota = valores.descricaoRota ? valores.descricaoRota : '';
      this.getValorTotalPorTipoTransferencia({ ...valores, postoId: this.postoSelecionado });
      this.loading = false;
    });
  }

  onAcaoClick(event: TransferenciaAcaoEvent): void {
    const actions = new Map<string, (transferencia: Transferencia) => void>();

    actions.get(event.acao)(event.transferencia);
  }

  onFilter(options: TransferenciaListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    const postoDestinoId = options.postoDestino ? options.postoDestino.id : null;
    const postoOrigemId = options.postoOrigem ? options.postoOrigem.id : null;
    const dataInicial = options.dataInicial || null;
    const dataFinal = options.dataFinal || null;

    this.queryOptions.params = {
      ...this.queryOptions.params,
      nomeFuncionario: options.nome || null,
      situacao: options.situacoesTransferencia?.length ? `in:${options.situacoesTransferencia.join(',')}` : null,
      dataInicio: dataInicial?.toLocaleString(),
      dataFinal: dataFinal?.toLocaleString(),
      postoId,
      postoDestinoId,
      postoOrigemId,
    };

    if (dataInicial !== this.dataInicialSelecionada || dataFinal !== this.dataFinalSelecionada) {
      this.dataInicialSelecionada = dataInicial;
      this.dataFinalSelecionada = dataFinal;
    }

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }

    this.listarTransferencias(0, {
      descricaoRota: options.descricaoRota,
      dataInicial: options?.dataInicial,
      dataFinal: options?.dataFinal,
      origem: postoOrigemId,
      destino: postoDestinoId,
    });
  }

  getValorTotalPorTipoTransferencia(valores: Partial<ValoresTransferenciaRequestDTO>): void {
    this.transferenciaService.getValorTotalPorTipoTransferencia(valores).subscribe((response: ReturnAPI<number>) => {
      this.totalizador = response.object || 0;
    });
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
