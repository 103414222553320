import { Component } from '@angular/core';
import { Contrato } from 'src/app/base/domain/contrato.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ContratoService } from 'src/app/base/services/contrato.service';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import { ContratoAcaoEvent, ContratoListFilterOptions } from 'src/app/global/lists/contrato-list/contrato-list.component';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/auth/shared.service';
import { Usuario } from 'src/app/base/domain/usuario.model';

@Component({
  selector: 'app-contratos-em-finalizacao-main',
  templateUrl: './contratos-em-finalizacao-main.component.html',
  styleUrls: ['./contratos-em-finalizacao-main.component.css'],
})
export class ContratosEmFinalizacaoMainComponent {
  contratos: Contrato[] = [];

  queryOptions = new QueryOptions({ pageSize: 10, pageSort: '-dataFinalizacao' });
  totalRecords = 0;
  loading = false;
  totalizadorBruto = 0;
  totalizadorLiquido = 0;
  shared: SharedService;
  usuario: Usuario;

  private postoSelecionado: number = null;

  constructor(private contratoService: ContratoService, public router: Router) {
    const shared = SharedService.getInstance();
    this.usuario = shared.usuario;
  }

  listarContratos(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.contratoService.findContratosEncerrandoHojePorPosto(this.queryOptions).subscribe((response: ReturnAPI<Page<Contrato>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        if (this.usuario.profile !== 'ROLE_RETAGUARDA') {
          this.mapearAcoes(response.object.content);
        }
      } else {
        this.contratos = [];
      }
      this.loading = false;
    });

    this.contratoService.getValorBrutoContratosEncerrandoHojePorPosto(this.queryOptions).subscribe((response: ReturnAPI<number>) => {
      this.totalizadorBruto = response.object || 0;
    });

    this.contratoService.getValorLiquidoContratosEncerrandoHojePorPosto(this.queryOptions).subscribe((response: ReturnAPI<number>) => {
      this.totalizadorLiquido = response.object || 0;
    });
  }

  mapearAcoes(contratos: Contrato[]): void {
    const mapearAcoes = (contrato: Contrato): Contrato => {
      const acoes = [];
      return { ...contrato, acoes };
    };

    this.contratos = contratos?.map(mapearAcoes) ?? [];
  }

  onAcaoClick(event: ContratoAcaoEvent): void {
    const actions = new Map<string, (contrato: Contrato) => void>();
    actions.get(event.acao)(event.contrato);
  }

  onContratosPage(page: number): void {
    this.listarContratos(page);
  }

  onDisclosure(contrato: Contrato): void {
    this.router.navigate(['base/contratos/em-finalizacao-detail/' + contrato.id]);
  }

  onFilter(options: ContratoListFilterOptions): void {
    const idPosto = options.postoSelecionado ? options.postoSelecionado.id : null;
    this.queryOptions.params = {
      apelidoCliente: options.nomeCliente || null,
      descricaoRota: options.descricaoRota || null,
      dataInicial: options.dataInicial?.getTime(),
      dataFinal: options.dataFinal?.getTime(),
      postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
    };

    if (idPosto !== this.postoSelecionado) {
      this.postoSelecionado = idPosto;
    }

    this.listarContratos();
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
