import { ArquivoConciliacaoBancariaSerializer } from './../serializables/arquivo-conciliacao-bancaria.serializer';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { ArquivoConciliacaoBancaria } from '../domain/arquivo-conciliacao-bancaria.model';
import { Produto } from '../domain/produto.model';
import { Page } from '../domain/return-api.model';
@Injectable()
export class ArquivoConciliacaoBancariaService extends ResourceService<ArquivoConciliacaoBancaria> {
  static RESOURCE = 'arquivo-conciliacao';
  static APAGAR_ARQUIVO = 'apagar-arquivo';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(
      http,
      `${WEB_API}`,
      `${ArquivoConciliacaoBancariaService.RESOURCE}`,
      new ArquivoConciliacaoBancariaSerializer(ArquivoConciliacaoBancaria),
    );
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }

  getUsuarioHeimdall(): { heimdallId: string; heimdallNome: string; heimdallEmail: string } {
    if (sessionStorage.getItem('valhalla-web-st-auth-user')) {
      const data: { id: string; nome: string; email: string } = JSON.parse(sessionStorage.getItem('valhalla-web-st-auth-user'));
      const AuditoriaHeimdall = { heimdallId: data.id, heimdallNome: data.nome, heimdallEmail: data.email };
      return AuditoriaHeimdall;
    }
  }

  findAllPageable(queryOptions: QueryOptions): Observable<Page<ArquivoConciliacaoBancaria>> {
    this.getProduto();
    return this.http.get<Page<ArquivoConciliacaoBancaria>>(
      `${WEB_API}/${ArquivoConciliacaoBancariaService.RESOURCE}/pageable?${queryOptions.toQueryString()}`,
    );
  }

  importFile(form: any): Observable<any> {
    const formData = new FormData();
    formData.append('banco', form.banco.toString());
    formData.append('file', form.documento);

    return this.http.post<any>(`${WEB_API}/${ArquivoConciliacaoBancariaService.RESOURCE}`, formData);
  }

  requestToSgf(arquivoId: number): Observable<any> {
    return this.http.post<any>(`${WEB_API}/${ArquivoConciliacaoBancariaService.RESOURCE}/${arquivoId}`, null);
  }

  apagarArquivo(arquivoId: number): Observable<any> {
    return this.http.post<any>(
      `${WEB_API}/${ArquivoConciliacaoBancariaService.RESOURCE}/${ArquivoConciliacaoBancariaService.APAGAR_ARQUIVO}/${arquivoId}`,
      null,
    );
  }

  relatorioListaPagamentosAprovadosConciliacao(options: QueryOptions): Observable<Blob> {
    return this.httpClient.get(
      `${WEB_API}/${ArquivoConciliacaoBancariaService.RESOURCE}/relatorio-lista-pagamentos-aprovados?${options.toQueryString(false)}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioListaDePagamentosNaoProcessadosConciliacao(options: QueryOptions): Observable<Blob> {
    return this.httpClient.get(
      `${WEB_API}/${ArquivoConciliacaoBancariaService.RESOURCE}/relatorio-lista-pagamentos-nao-processados?${options.toQueryString(false)}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioListaGeralDePagamentosConciliacao(options: QueryOptions): Observable<Blob> {
    return this.httpClient.get(
      `${WEB_API}/${ArquivoConciliacaoBancariaService.RESOURCE}/relatorio-lista-pagamentos-conciliacao?${options.toQueryString(false)}`,
      {
        responseType: 'blob',
      },
    );
  }
}
