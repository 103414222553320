import { VisitaService } from 'src/app/base/services/visita.service';
import { AppState } from './../../../../App.state';
import {
  EquipeVisitadorFormComponent,
  EquipeVisitadorFormComponentResult,
} from './../../../../global/forms/equipe-visitador-form/equipe-visitador-form.component';
import { EquipeRotaFormComponent } from './../../../../global/forms/equipe-rota-form/equipe-rota-form.component';
import { Cliente } from './../../../domain/cliente.model';
import { ClienteService } from './../../../services/cliente.service';
import { MatDialog } from '@angular/material/dialog';
import { AcaoDeVenda } from './../../../domain/acao-de-venda.model';
import { Component, OnInit } from '@angular/core';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { AcaoDeVendaAcaoEvent, AcaoDeVendaListFilterOptions } from 'src/app/global/lists/acao-de-venda-list/acao-de-venda-list.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/global/services/dialog.service';
import { MessageAPI, Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { AcaoDeVendaService } from 'src/app/base/services/acao-de-venda.service';
import {
  CheckboxSelectFormComponent,
  CheckboxSelectFormData,
  CheckboxSelectFormResultData,
} from 'src/app/global/forms/checkbox-select-form/checkbox-select-form.component';
import { SharedService } from 'src/app/auth/shared.service';
import { VisitaAgendamentoAcaoDeVendaDTO } from 'src/app/base/domain/dto/visita-agendamento-acao-de-venda.dto';
import { EditarTelefoneFormComponent } from 'src/app/global/forms/editar-telefone-form/editar-telefone-form.component';
import { EditarTelefoneDTO } from 'src/app/base/domain/dto/editar-telefone.dto';

@Component({
  selector: 'app-acoes-de-venda-main',
  templateUrl: './acoes-de-venda-main.component.html',
  styleUrls: ['./acoes-de-venda-main.component.css'],
})
export class AcoesDeVendaMainComponent implements OnInit {
  static NOVO = 'Nova Ação de Venda';
  static PRE_APROVAR = 'Pré-aprovar Ação de Venda';
  static AGENDAR_VISITA = 'Agendar Visita';
  static AGENDAR_MULTIPLAS_VISITA = 'Agendar Multiplas Visitas';
  static REENVIAR_SMS = 'Reenviar SMS';
  static REENVIAR_MULTIPLOS_SMS = 'Reenviar Multiplos SMS';
  static EDITAR_TELEFONE = 'Editar Telefone';
  static PREAPROVAR_MULTIPLAS_ACOES = 'Pré-Aprovar Multiplas Ações';

  acoesDeVenda: AcaoDeVenda[] = [];
  queryOptions = new QueryOptions({ pageSize: 10, pageSort: '-dataCadastro' });
  totalRecords = 0;
  loading = false;

  menuAcoes: string[] = [
    AcoesDeVendaMainComponent.NOVO,
    AcoesDeVendaMainComponent.AGENDAR_MULTIPLAS_VISITA,
    AcoesDeVendaMainComponent.REENVIAR_MULTIPLOS_SMS,
    AcoesDeVendaMainComponent.PREAPROVAR_MULTIPLAS_ACOES
  ];

  constructor(
    private acaoDeVendaService: AcaoDeVendaService,
    private clienteService: ClienteService,
    private visitaService: VisitaService,
    private activatedRoute: ActivatedRoute,
    private matDialog: MatDialog,
    public dialogService: DialogService,
    public router: Router,
    public appState: AppState,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void { }

  async listarAcoesDeVenda(pageNumber: number = 0): Promise<void> {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.appState.isLoading = true;
    this.queryOptions.params = { ...this.activatedRoute.snapshot.queryParams };
    this.acaoDeVendaService.findPage(this.queryOptions).subscribe(
      (response: ReturnAPI<Page<AcaoDeVenda>>) => {
        if (response.success) {
          this.totalRecords = response.object.totalElements;
          this.acoesDeVenda = response.object.content;
          this.mapearAcoes(response.object.content);
        } else {
          this.acoesDeVenda = [];
          this.dialogService.feedbackReturnAPI(response, '');
        }
        this.loading = false;
        this.appState.isLoading = false;
      },
      () => (this.appState.isLoading = false),
    );
  }

  mapearAcoes(acoesDeVenda: AcaoDeVenda[]): void {
    const mapearAcoes = (acaoDeVenda: AcaoDeVenda): AcaoDeVenda => {
      const acoes = [];
      if (acaoDeVenda.situacao === 'AGUARDANDO_PRE_APROVACAO') {
        acoes.push(AcoesDeVendaMainComponent.PRE_APROVAR);
      }
      if ((acaoDeVenda.situacao === 'EM_ANALISE_PELO_CLIENTE' || acaoDeVenda.situacao === 'APROVADO') && !acaoDeVenda.visitaAgendada) {
        acoes.push(AcoesDeVendaMainComponent.AGENDAR_VISITA);
      }
      if (acaoDeVenda.situacao === 'EM_ANALISE_PELO_CLIENTE') {
        acoes.push(AcoesDeVendaMainComponent.REENVIAR_SMS);
      }

      acoes.push(AcoesDeVendaMainComponent.EDITAR_TELEFONE);

      return { ...acaoDeVenda, acoes };
    };
    this.acoesDeVenda = acoesDeVenda?.map(mapearAcoes) ?? [];
  }

  async abrirNovoFormularioParaInserirAcaoDeVenda(_?: AcaoDeVenda): Promise<void> {
    const findClientes = async (value: string = '') => {
      const options = new QueryOptions({
        pageNumber: 1,
        pageSize: 10,
        params: { nomeCliente: value },
      });
      return (await this.clienteService.findAll(options).toPromise()).object.content;
    };
    let clientesEmBusca: Cliente[] = await findClientes();
    this.matDialog
      .open<CheckboxSelectFormComponent, CheckboxSelectFormData>(CheckboxSelectFormComponent, {
        data: {
          title: 'Adicionar uma ação de venda para um cliente',
          selectableOptions: clientesEmBusca.map((cliente: Cliente) => cliente.pessoa.nomePessoa || cliente.pessoa.nomeFantasiaPessoa),
          noCheckbox: true,
          onAsyncSelectableFilter: async (value: string): Promise<string[]> => {
            clientesEmBusca = await findClientes(value);
            return clientesEmBusca.map((cliente: Cliente) => cliente.pessoa.nomePessoa || cliente.pessoa.nomeFantasiaPessoa);
          },
        },
        width: '500px',
      })
      .afterClosed()
      .subscribe((result: CheckboxSelectFormResultData) => {
        if (typeof result.optionSelected === 'number' && result.optionSelected > -1) {
          const cliente = clientesEmBusca[result.optionSelected];
          this.inserirAcaoDeVendaParaCliente(cliente);
        }
      });
  }

  async inserirAcaoDeVendaParaCliente(cliente: Cliente): Promise<void> {
    try {
      const usuario = SharedService.getInstance().usuario;
      const response = await this.acaoDeVendaService.inserirAcaoDeVendaParaCliente(cliente.id, usuario).toPromise();
      this.dialogService.feedbackReturnAPI(response, 'Ação de Venda registrada com sucesso');
      if (response.success) {
        this.listarAcoesDeVenda();
      }
    } catch (error) {
      this.dialogService.feedbackError('Ocorreu uma falha ao inserir a ação de venda');
    }
  }

  async requisitarPreAprovacao(acaoDeVenda: AcaoDeVenda): Promise<void> {
    const res = await this.dialogService.confirmDialog('Deseja pré-aprovar Ação de Venda');
    if (res) {
      const acao: AcaoDeVenda = { ...acaoDeVenda, situacao: 'EM_ANALISE_PELO_CLIENTE' };
      try {
        const response = await this.acaoDeVendaService.atualizar(acao).toPromise();
        this.dialogService.feedbackReturnAPI(response, 'Ação pré-aprovada com sucesso');
        if (response.success) {
          this.listarAcoesDeVenda();
        }
      } catch (error) {
        this.dialogService.feedbackError('Erro ao pré-aprovar Ação de Venda');
      }
    }
  }

  async agendarVisitaParaAcaoAprovada(acaoDeVenda: AcaoDeVenda): Promise<void> {
    this.matDialog
      .open(EquipeVisitadorFormComponent, {
        data: {
          title: 'Agendar Visita',
          posto: acaoDeVenda.cliente.posto,
          enableDateSelection: true,
          datePlaceholder: 'Data da Visita',
        },
        width: '500px',
      })
      .afterClosed()
      .subscribe((params: EquipeVisitadorFormComponentResult) => {
        this.requisitarAgendamento(acaoDeVenda, params);
      });
  }

  async requisitarAgendamento(acaoDeVenda: AcaoDeVenda, params: EquipeVisitadorFormComponentResult): Promise<void> {
    this.appState.isLoading = true;
    try {
      const result = await this.visitaService
        .agendarVisitarPorAcaoDeVenda({
          acaoDeVendaId: acaoDeVenda.id,
          data: params.data,
          equipe: params.equipe,
          visitador: params.visitador,
        })
        .toPromise();
      this.dialogService.feedbackReturnAPI(result, 'Visita agendada com sucesso!');
      if (result.success) {
        this.appState.isLoading = false;
        this.listarAcoesDeVenda();
      }
    } catch (error) {
      this.dialogService.feedbackError('Não foi possível agendar visita!');
      this.appState.isLoading = false;
    }
  }

  async reenviarSMS(acaoVenda: AcaoDeVenda): Promise<void> {
    const res = await this.dialogService.confirmDialog('Deseja reenviar SMS');
    if (res) {
      try {
        const response = await this.acaoDeVendaService.reenviarSMS(acaoVenda).toPromise();
        this.dialogService.feedbackReturnAPI(response, 'SMS reenviado com sucesso');
        if (response.success) {
          this.listarAcoesDeVenda();
        }
      } catch (error) {
        this.dialogService.feedbackError('Erro ao reenviar SMS');
      }
    }
  }

  editarTelefone(acaoVenda: AcaoDeVenda): void {
    this.dialog
      .open(EditarTelefoneFormComponent, {
        data: {
          cliente: acaoVenda.cliente,
        },
        width: '20%',
      })
      .afterClosed()
      .subscribe((result: EditarTelefoneDTO) => {
        this.clienteService.editarTelefoneCliente(result).subscribe((response: ReturnAPI<Cliente>) => {
          if (response.success) {
            this.dialogService.feedbackReturnAPI(response, 'Telefone Atualizado com sucesso!');
            this.listarAcoesDeVenda();
          } else {
            this.appState.isLoading = false;
            this.dialogService.feedbackError(response.messages.map((msg: MessageAPI) => msg.text).join(','));
          }
        });
      });
  }

  onLoadPage(page: number): void {
    this.listarAcoesDeVenda(page);
  }

  onAcaoClick(event: AcaoDeVendaAcaoEvent): void {
    const actions = new Map<string, (acaoDeVenda: AcaoDeVenda) => void>()
      .set(AcoesDeVendaMainComponent.NOVO, this.abrirNovoFormularioParaInserirAcaoDeVenda.bind(this))
      .set(AcoesDeVendaMainComponent.PRE_APROVAR, this.requisitarPreAprovacao.bind(this))
      .set(AcoesDeVendaMainComponent.AGENDAR_VISITA, this.agendarVisitaParaAcaoAprovada.bind(this))
      .set(AcoesDeVendaMainComponent.REENVIAR_SMS, this.reenviarSMS.bind(this))
      .set(AcoesDeVendaMainComponent.EDITAR_TELEFONE, this.editarTelefone.bind(this))
      .set(AcoesDeVendaMainComponent.PREAPROVAR_MULTIPLAS_ACOES, this.preAprovarMultiplo.bind(this));
    actions.get(event.acao)(event.acaoDeVenda);
  }

  async agendarMultiplasVisitaParaAcaoAprovada(acaoDeVenda: AcaoDeVenda[]): Promise<void> {
    this.matDialog
      .open(EquipeVisitadorFormComponent, {
        data: {
          title: 'Agendar Visita',
          posto: acaoDeVenda[0].cliente.posto,
          enableDateSelection: true,
          datePlaceholder: 'Data da Visita',
        },
        width: '500px',
      })
      .afterClosed()
      .subscribe((params: EquipeVisitadorFormComponentResult) => {
        if (params) {
          this.requisitarMultiplosAgendamento(acaoDeVenda, params);
        }
      });
  }

  // criar lista de chupeta para enviar requests;
  async requisitarMultiplosAgendamento(acaoDeVendaList: AcaoDeVenda[], params: EquipeVisitadorFormComponentResult): Promise<void> {
    this.appState.isLoading = true;
    const agendamentoList = [];
    acaoDeVendaList.forEach((acaoDeVenda: AcaoDeVenda) => {
      const agendamento = new VisitaAgendamentoAcaoDeVendaDTO();
      agendamento.acaoDeVendaId = acaoDeVenda.id;
      agendamento.data = params.data;
      agendamento.equipe = params.equipe;
      agendamento.visitador = params.visitador;
      agendamentoList.push(agendamento);
    });

    try {
      const result = await this.visitaService.agendarMultiplasVisitarPorAcaoDeVenda(agendamentoList).toPromise();
      this.dialogService.feedbackReturnAPI(result, 'Visita agendada com sucesso!');
      if (result.success) {
        this.listarAcoesDeVenda();
      }
    } catch (error) {
      this.dialogService.feedbackError('Não foi possível agendar visita!');
    } finally {
      this.appState.isLoading = false;
    }
  }

  onAgendarMultiplasVisitas(acaoDeVenda: AcaoDeVenda[]): void {
    this.agendarMultiplasVisitaParaAcaoAprovada(acaoDeVenda);
  }

  onFilter(options: AcaoDeVendaListFilterOptions): void {
    this.queryOptions.params = {
      postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
      situacao: options.situacao != null && options.situacao.length ? options.situacao.toString() : null,
      situacaoCliente: options.situacaoCliente != null && options.situacaoCliente.length ? options.situacaoCliente.toString() : null,
      nome: options.nome != null ? options.nome : null,
      smsEnviado: options.smsEnviado != null ? `eq:${options.smsEnviado}` : 'eq: false',
    };

    if (options.postoSelecionado) {
      this.router
        .navigate(['base/clientes/acoes-de-venda'], { queryParams: this.queryOptions.params })
        .then(() => this.listarAcoesDeVenda());
    } else {
      this.dialogService.feedbackError('Filtro de Posto é Obrigatório!');
    }
  }

  onReenviarMultiplosSMS(acaoDeVenda: AcaoDeVenda[]): void {
    if (acaoDeVenda.length > 0) {
      this.reenviarMultiplosSMS(acaoDeVenda);
    } else {
      this.dialogService.feedbackInfo('Selecione pelo menos uma ação de venda!');
    }
  }

  async reenviarMultiplosSMS(acaoDeVenda: AcaoDeVenda[]): Promise<void> {
    const res = await this.dialogService.confirmDialog('Deseja reenviar SMS para as ações de venda selecionadas?');
    if (res) {
      this.requisitarReenvioMultiplosSMS(acaoDeVenda);
    }
  }

  async requisitarReenvioMultiplosSMS(acaoDeVendaList: AcaoDeVenda[]): Promise<void> {
    this.appState.isLoading = true;
    this.queryOptions.params = { ...this.queryOptions.params, situacao: '1', size: 2000 };

    if (!this.queryOptions.params?.postoId) {
      this.appState.isLoading = false;
      this.dialogService.feedbackError('Filtro de Posto é Obrigatório!');
      return;
    }

    try {
      const result = await this.acaoDeVendaService.reenviarMultiplosSMS(acaoDeVendaList, this.queryOptions).toPromise();
      this.dialogService.feedbackReturnAPI(result, 'SMS reenviado com sucesso!');
      if (result.success) {
        this.listarAcoesDeVenda();
      } else {
        this.appState.isLoading = false;
        this.dialogService.feedbackError(result.messages.map((msg: MessageAPI) => msg.text).join(','));
      }
    } catch (error) {
      this.dialogService.feedbackError('Não foi possível reenviar SMS!');
    } finally {
      this.appState.isLoading = false;
    }
  }

  onPreAprovarMultiplo(): void {
    this.preAprovarMultiplo();
  }

  async preAprovarMultiplo(): Promise<void> {
    this.appState.isLoading = true;
    this.queryOptions.params = { ...this.queryOptions.params, situacao: '0', size: 2000 };

    if (!this.queryOptions.params?.postoId) {
      this.appState.isLoading = false;
      this.dialogService.feedbackError('Filtro de Posto é Obrigatório!');
      return;
    }

    try {
      const result = await this.acaoDeVendaService.preAprovarMultiplo(this.queryOptions).toPromise();
      this.dialogService.feedbackReturnAPI(result, 'SMS enviado com sucesso!');
      if (result.success) {
        this.listarAcoesDeVenda();
      } else {
        this.appState.isLoading = false;
        this.dialogService.feedbackError(result.messages.map((msg: MessageAPI) => msg.text).join(','));
      }
    } catch (error) {
      this.dialogService.feedbackError('Não foi possível enviar SMS!');
    } finally {
      this.appState.isLoading = false;
    }
  }
}
