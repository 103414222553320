import { Injectable } from '@angular/core';
import { ResourceService } from 'src/app/global/services/resource.service';
import { HttpClient } from '@angular/common/http';
import { WEB_API } from 'src/app/global/services/web-api';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ReturnAPI, Page } from '../domain/return-api.model';
import { Observable } from 'rxjs';
import { AuditoriaDespesa } from '../domain/auditoria-despesa.model';
import { AuditoriaDespesaSerializer } from '../serializables/auditoria-despesa.serializer';

@Injectable()
export class AuditoriaDespesaService extends ResourceService<AuditoriaDespesa> {
  static RESOURCE = 'auditoria-despesa';

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${AuditoriaDespesaService.RESOURCE}`, new AuditoriaDespesaSerializer(AuditoriaDespesa));
  }

  findAll(queryOption: QueryOptions): Observable<Page<AuditoriaDespesa>> {
    return this.httpClient.get<Page<AuditoriaDespesa>>(`${WEB_API}/${AuditoriaDespesaService.RESOURCE}?${queryOption.toQueryString()}`,
    );
  }
}
