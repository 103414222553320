import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Reagendamento } from 'src/app/base/domain/reagendamento.model';
import { Posto } from 'src/app/base/domain/posto.model';
import { Cliente } from 'src/app/base/domain/cliente.model';
import { Funcionario } from 'src/app/base/domain/funcionario.model';
import { QueryOptions } from '../../domain/query.options';
import { LazyLoadEvent } from 'primeng/api';
import { MatDatepickerInputEvent } from '@angular/material/datepicker/datepicker-input-base';
import { Moment } from 'moment';

export type ReagendamentoAcao = Reagendamento & { acoes: string[] };

export interface ReagendamentoAcaoEvent {
  acao: string;
  reagendamento: Reagendamento;
}

export interface ReagendamentoListFilterOptions {
  postoSelecionado: Posto;
  clienteSelecionado: string;
  funcionarioSelecionado: string;
  dataInicial: Date;
  dataFinal: Date;
  descricaoRota: string;
  dataGeradoInicial: Date;
  dataGeradoFinal: Date;
  dataReagendadoInicial: Date;
  dataReagendadoFinal: Date;
}

@Component({
  selector: 'app-reagendamento-list',
  templateUrl: './reagendamento-list.component.html',
  styleUrls: ['./reagendamento-list.component.css'],
})
export class ReagendamentoListComponent implements OnInit {
  funcionarios: Funcionario[];
  opcaoBusca = '';

  @Input() title: string;
  @Input() reagendamentos: Reagendamento[];
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() loading: boolean;
  @Input() filtros: string[];
  @Input() options: string[];

  @Output() loadReagendamentoPage = new EventEmitter<number>();
  @Output() filter = new EventEmitter<ReagendamentoListFilterOptions>();
  @Output() acaoClick = new EventEmitter<ReagendamentoAcaoEvent>();

  private currentPage = 0;
  queryOptions = new QueryOptions();

  private readonly defaultFilterOptions: ReagendamentoListFilterOptions = {
    clienteSelecionado: null,
    funcionarioSelecionado: null,
    postoSelecionado: null,
    dataInicial: null,
    dataFinal: null,
    descricaoRota: '',
    dataGeradoInicial: null,
    dataGeradoFinal: null,
    dataReagendadoInicial: null,
    dataReagendadoFinal: null
  };

  filterOptions: ReagendamentoListFilterOptions;

  constructor() {}

  opcoes = ['Responsável', 'Cliente'];

  ngOnInit(): void {
    this.onLimparFiltros();
  }

  loadReagendamento(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadReagendamentoPage.emit(pageNumber);
  }

  onAcaoClick(acao: string, domain: Reagendamento): void {
    this.acaoClick.emit({ acao, reagendamento: domain });
  }

  onAtualizar(): void {
    this.loadReagendamentoPage.emit(this.currentPage);
  }

  onLimparFiltros(): void {
    this.opcaoBusca = '';
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  opcaoBuscaSelecionado(value: string): void {
    this.onLimparFiltros();
    this.opcaoBusca = value;
  }


  onPickerEvent(dataGeracao: string, evento: MatDatepickerInputEvent<Moment>): void {

    if (dataGeracao === 'dataContratoGeracaoInicial') {
      this.filterOptions.dataGeradoInicial = evento.value?.toDate();
    }
    if (dataGeracao === 'dataContratoGeracaoFinal') {
      this.filterOptions.dataGeradoFinal = evento.value?.toDate();
    }
    if (dataGeracao === 'dataReagendamentoInicial') {
      this.filterOptions.dataReagendadoInicial = evento.value?.toDate();
    }
    if (dataGeracao === 'dataReagendamentoFinal') {
      this.filterOptions.dataReagendadoFinal = evento.value?.toDate();
    }

  }

}
