import { Resource } from "src/app/global/domain/resource.model";
import { Posto } from "./posto.model";

export class ArquivoImportacaoNovo extends Resource {
    nomeArquivoImportacaoOperacao: string;
    nomeArquivoImportacaoSacado: string;
    posto: Posto;
    dataCadastro: Date;
    dataInicial: Date;
    dataFinal: Date;
}