import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cliente } from 'src/app/base/domain/cliente.model';
import { PostoService } from 'src/app/base/services/posto.service';
import { Posto } from 'src/app/base/domain/posto.model';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { QueryOptions } from '../../domain/query.options';
import { Endereco } from 'src/app/base/domain/endereco.model';
import { Rota } from 'src/app/base/domain/rota.model';
import { RotaService } from 'src/app/base/services/rota.service';
import { ClientePostoDTO } from 'src/app/base/domain/dto/cliente-posto.dto';
import { ThrowStmt } from '@angular/compiler';

interface DataType {
  cliente: Cliente;
  action: String;
}

@Component({
  selector: 'app-alterar-posto-cliente-form',
  templateUrl: './alterar-posto-cliente-form.component.html',
  styleUrls: ['./alterar-posto-cliente-form.component.css'],
})
export class AlterarPostoClienteFormComponent implements OnInit {
  cliente: Cliente;
  title: String;
  postos: Posto[];
  rotas: Rota[];
  postoSelecionado: Posto;
  rotaSelecionado: Rota;
  filteredPostos: Posto[] = [];
  filteredRotas: Rota[] = [];
  hideFields: string[];
  private readonly endereco: Endereco;

  constructor(
    public dialogRef: MatDialogRef<AlterarPostoClienteFormComponent>,
    private postoService: PostoService,
    private rotaService: RotaService,
    @Inject(MAT_DIALOG_DATA) public data: DataType) {
    this.cliente = data.cliente;
    this.title = 'Alterar Posto do Cliente';

    this.hideFields = this.cliente.rota ? [] : ['rota'];

    this.postoService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Posto>>) => {
      if (response.success) {
        this.postos = response.object.content;
        this.filteredPostos = this.postos;
      }
    });
  }

  ngOnInit(): void { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    let rota = new Rota();
    rota.id = this.rotaSelecionado.id;
    const dto: ClientePostoDTO = {
      postoId: this.postoSelecionado.id,
      rota: rota,
      clienteId: this.cliente.id,
    };

    this.dialogRef.close(dto);
  }

  onSelectPosto(value: Posto): void {
    this.postoSelecionado = value;
    const postoId = this.postoSelecionado.id;
    const options = new QueryOptions({ pageSize: 100, pageNumber: 1, params: { postoId } });
    this.rotaService.findAll(options).subscribe((response: ReturnAPI<Page<Rota>>) => {
      if (response.success) {
        this.rotas = response.object.content;
      }
    });
  }

  onSelectRota(value: Rota): void {
    this.rotaSelecionado = value;
  }

  get queryOptions(): QueryOptions {
    return new QueryOptions({
      pageNumber: 1
    });
  }
}
