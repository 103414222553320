import { Resource } from 'src/app/global/domain/resource.model';
import { Contrato } from './contrato.model';
import { Posto } from './posto.model';
import { SituacaoCobrancaVale } from './enum/situacao-cobranca-vale.enum';
import { Funcionario } from './funcionario.model';

export class CobrancaVale extends Resource {
  quantidadeParcelas: number;

  valor: number;

  valorPago: number;

  valorRestante: number;

  vencimento: Date;

  dataCadastro: Date;

  situacao: SituacaoCobrancaVale;

  contrato: Contrato;

  posto: Posto;

  funcionario: Funcionario;
}
