import { Component, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef } from '@angular/material/dialog';
import { Moment } from 'moment';

@Component({
  selector: 'app-gerar-parcela-form',
  templateUrl: './gerar-parcela-form.component.html',
  styleUrls: ['./gerar-parcela-form.component.css']
})
export class GerarParcelaFormComponent implements OnInit {

  data = new Date();

  constructor(public dialogRef: MatDialogRef<GerarParcelaFormComponent>) { }

  ngOnInit(): void { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(this.data);
  }

  onPickerEvent(event: MatDatepickerInputEvent<Moment>): void {
    this.data = event.value?.toDate() || null;
  }
}
