import { Pessoa } from './pessoa.model';
import { Posto } from './posto.model';
import { Resource } from 'src/app/global/domain/resource.model';
import { ContaCliente } from './conta-cliente.model';
import { Rota } from './rota.model';
import { AtividadeComercial } from './atividade-comercial.model';
import { CarteiraCliente } from './carteira-cliente.model';

export class Cliente extends Resource {
  pessoa: Pessoa;
  // listReferencia: Referencia[];
  situacaoCliente: string; // SituacaoClienteEnum;
  turno: string; // TurnoEnum;
  atividadeComercial: AtividadeComercial;
  numeroCartao: string;
  vencimentoCartao: string;
  posto: Posto;
  contas: ContaCliente[];
  dataInativacao: Date;
  rota?: Rota;
  analiseDeRisco: boolean;
  carteiraCliente: CarteiraCliente;
  nomeCliente: string;
  uid?: string;
}
