import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QueryOptions } from '../../domain/query.options';
import { LazyLoadEvent } from 'primeng/api';
import { Posto } from 'src/app/base/domain/posto.model';
import { Feriado } from 'src/app/base/domain/feriado.model';
import { TipoFeriado } from 'src/app/base/domain/enum/tipo-feriado.enum';

export interface FeriadoAcaoEvent {
  acao: string;
  feriado: Feriado;
}
export interface FeriadoListFilterOptions {
  nome: string;
  tipoFeriado: TipoFeriado[];
  postoSelecionado: Posto;
  dataInicial: Date;
  dataFinal: Date;
}

@Component({
  selector: 'app-feriado-list',
  templateUrl: './feriado-list.component.html',
  styleUrls: ['./feriado-list.component.css'],
})
export class FeriadoListComponent implements OnInit {
  @Input() title: string;
  @Input() nomePlaceholder: string;
  @Input() rowsNumber: number;
  @Input() totalRecords: number;
  @Input() loading: boolean;
  @Input() filtros: string[];
  @Input() options: string[];
  @Input() feriados: Feriado[];

  @Output() novo = new EventEmitter<void>();
  @Output() loadFeriadosPage = new EventEmitter<number>();
  @Output() acaoClick = new EventEmitter<FeriadoAcaoEvent>();
  @Output() filter = new EventEmitter<FeriadoListFilterOptions>();

  private currentPage = 0;

  tipoFeriado: TipoFeriado[] = Object.values(TipoFeriado);

  private readonly defaultFilterOptions: FeriadoListFilterOptions = {
    nome: '',
    tipoFeriado: [],
    postoSelecionado: null,
    dataInicial: null,
    dataFinal: null,
  };
  filterOptions: FeriadoListFilterOptions;

  queryOptions = new QueryOptions();

  constructor() {
    this.onLimparFiltros();
  }

  ngOnInit(): void {}

  loadFeriados(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadFeriadosPage.emit(pageNumber);
  }

  onAcaoClick(acao: string, domain: Feriado): void {
    this.acaoClick.emit({ acao, feriado: domain });
  }

  onAtualizar(): void {
    this.loadFeriadosPage.emit(this.currentPage);
  }

  onNovo(): void {
    this.novo.emit();
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
