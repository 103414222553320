import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { Produto } from '../domain/produto.model';
import { Page } from '../domain/return-api.model';
import { ConciliacaoBancariaTed } from '../domain/conciliacao-bancaria-ted.model';
import { ConciliacaoBancariaTedSerializer } from '../serializables/conciliacao-bancaria-ted.serializer';
import { ParcelaClienteTEDView } from '../domain/parcela-cliente-ted-view.model';
@Injectable()
export class ConciliacaoBancariaTedService extends ResourceService<ConciliacaoBancariaTed> {
  private static RESOURCE = 'conciliacao-bancaria-ted';

  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${ConciliacaoBancariaTedService.RESOURCE}`, new ConciliacaoBancariaTedSerializer(ConciliacaoBancariaTed));
  }

  findAllPageable(queryOptions: QueryOptions): Observable<Page<ConciliacaoBancariaTed>> {
    return this.http.get<Page<ConciliacaoBancariaTed>>(
      `${WEB_API}/${ConciliacaoBancariaTedService.RESOURCE}/pageable?${queryOptions.toQueryString()}`,
    );
  }

  associar(data: {}): Observable<any> {
    return this.http.post<any>(`${WEB_API}/${ConciliacaoBancariaTedService.RESOURCE}/associar`, data);
  }

  getValorTotal(queryOptions: QueryOptions): Observable<any> {
    return this.http.get<number>(`${WEB_API}/${ConciliacaoBancariaTedService.RESOURCE}/get-valor-total?${queryOptions.toQueryString()}`);
  }

  findAllParcela(data: Date): Observable<ParcelaClienteTEDView[]> {
    return this.http.get<ParcelaClienteTEDView[]>(`${WEB_API}/${ConciliacaoBancariaTedService.RESOURCE}/list-parcela?data=${data}`);
  }

  vincular(dto: any): Observable<ConciliacaoBancariaTed> {
    return this.http.post<ConciliacaoBancariaTed>(`${WEB_API}/${ConciliacaoBancariaTedService.RESOURCE}/vincular-parcela`, dto);
  }
}
