import { Resource } from 'src/app/global/domain/resource.model';

export class TransferenciaDTO {
  idPostoOrigem: number;
  idPostoDestino: number;
  postoOrigemUID: string;
  postoDestinoUID: string;
  idTransferencia: number;
  valor: number;
}
