import { Contrato } from './contrato.model';
import { Cliente } from './cliente.model';
import { Resource } from 'src/app/global/domain/resource.model';
import { Visita } from './visita.model';

export class AcaoDeVenda extends Resource {
  cliente: Cliente;
  ultimoContrato: Contrato;
  situacao: string;
  visitaAgendada: Visita;
  usuarioHeimdallId: number;
  captadorNome: string;
  smsEnviado: boolean;
}
