import { Component, OnInit } from '@angular/core';
import { TransferenciaService } from 'src/app/base/services/transferencia.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/global/services/dialog.service';
import { Transferencia } from 'src/app/base/domain/transferencia.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { TransferenciaListFilterOptions } from 'src/app/global/lists/transferencia-list/transferencia-list.component';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import { ValoresTransferenciaRequestDTO } from 'src/app/base/domain/dto/valores-transferencia-request.dto';

@Component({
  selector: 'app-acompanhamento-sangria-posto-main',
  templateUrl: './acompanhamento-sangria-posto-main.component.html',
  styleUrls: ['./acompanhamento-sangria-posto-main.component.css'],
})
export class AcompanhamentoSangriaPostoMainComponent implements OnInit {
  transferencias: Transferencia[] = [];

  queryOptions = new QueryOptions({ pageSize: 10, pageSort: '-id', params: { type: 'tctodin' } });

  loading = false;
  totalRecords = 0;

  private postoSelecionado: number = null;
  private dataInicialSelecionada: Date = null;
  private dataFinalSelecionada: Date = null;

  totalizador = 0;

  constructor(private transferenciaService: TransferenciaService, private dialog: MatDialog, public dialogService: DialogService) {}

  ngOnInit(): void {}

  list(page: number = 0, valores: Partial<ValoresTransferenciaRequestDTO> = {}): void {
    this.queryOptions.pageNumber = page + 1;
    this.loading = true;
    this.transferenciaService.pageTransferencias(this.queryOptions).subscribe((response: ReturnAPI<Page<Transferencia>>) => {
      if (response.success) {
        this.transferencias = response.object.content;
        this.totalRecords = response.object.totalElements;
        this.loading = false;
        valores.tipo = 'tctodin';
        valores.dataInicial = valores.dataInicial ? valores.dataInicial : null;
        valores.dataFinal = valores.dataFinal ? valores.dataFinal : null;
        valores.nome = valores.nome ? valores.nome : '';
        valores.descricaoRota = valores.descricaoRota ? valores.descricaoRota : '';
      }
    });
  }

  onLoad(pageNumber: number): void {
    this.list(pageNumber);
  }

  onFilter(options: TransferenciaListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    const dataInicial = options.dataInicial || null;
    const dataFinal = options.dataFinal || null;

    this.queryOptions.params = {
      ...this.queryOptions.params,
      nomeFuncionario: options.nome || null,
      dataInicio: dataInicial?.toLocaleString(),
      dataFinal: dataFinal?.toLocaleString(),
      postoId,
    };

    if (dataInicial !== this.dataInicialSelecionada || dataFinal !== this.dataFinalSelecionada) {
      this.dataInicialSelecionada = dataInicial;
      this.dataFinalSelecionada = dataFinal;
    }

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }

    this.list(0, {
      descricaoRota: options.descricaoRota,
      dataInicial: options?.dataInicial,
      dataFinal: options?.dataFinal,
    });
  }

  getValorTotalPorTipoTransferencia(valores: Partial<ValoresTransferenciaRequestDTO>): void {
    this.transferenciaService.getValorTotalPorTipoTransferencia(valores).subscribe((response: ReturnAPI<number>) => {
      this.totalizador = response.object || 0;
    });
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
