import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcompanhamentoSangriaGeralMainComponent } from './acompanhamento-sangria-geral-main/acompanhamento-sangria-geral-main.component';
import { AcompanhamentoSangriaGeralDetailComponent } from './acompanhamento-sangria-geral-detail/acompanhamento-sangria-geral-detail.component';
import { GlobalModule } from 'src/app/global/global.module';

@NgModule({
  declarations: [AcompanhamentoSangriaGeralMainComponent, AcompanhamentoSangriaGeralDetailComponent],
  imports: [CommonModule, GlobalModule],
})
export class AcompanhamentoSangriaGeralModule {}
