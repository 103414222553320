import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaixaCobrancaMainComponent } from './caixa-cobranca-main/caixa-cobranca-main.component';
import { GlobalModule } from 'src/app/global/global.module';



@NgModule({
  declarations: [CaixaCobrancaMainComponent],
  imports: [CommonModule, GlobalModule]
})
export class CaixaCobrancaModule { }
