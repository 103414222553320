import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaixaControleMainComponent } from './caixa-controle-main/caixa-controle-main.component';
import { GlobalModule } from 'src/app/global/global.module';
import { CaixaControleMovimentacoesComponent } from './caixa-controle-movimentacoes/caixa-controle-movimentacoes.component';

@NgModule({
  declarations: [CaixaControleMainComponent, CaixaControleMovimentacoesComponent],
  imports: [CommonModule, GlobalModule],
})
export class CaixaControleModule {}
