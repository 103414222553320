import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Rota } from 'src/app/base/domain/rota.model';
import { Posto } from 'src/app/base/domain/posto.model';
import { QueryOptions } from '../../domain/query.options';
import { LazyLoadEvent } from 'primeng/api';

export interface RotaAcaoEvent {
  acao: string;
  rota: Rota;
}

export interface MenuAcaoEvent {
  acao: string;
}

export interface RotaListFilterOptions {
  nomeRota: string;
  nomeCobrador: string;
  postoSelecionado: Posto;
}

@Component({
  selector: 'app-rota-list',
  templateUrl: './rota-list.component.html',
  styleUrls: ['./rota-list.component.css'],
})
export class RotaListComponent implements OnInit {
  @Input() titulo: string;
  @Input() rotas: Rota[];
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() filtros: string[];
  @Input() menuAcoes: string[];

  @Output() filter = new EventEmitter<RotaListFilterOptions>();
  @Output() loadRotasPage = new EventEmitter<number>();
  @Output() acaoClick = new EventEmitter<RotaAcaoEvent>();
  @Output() menuAcaoClickEvent = new EventEmitter<MenuAcaoEvent>();
  @Output() disclosureRota = new EventEmitter<Rota>();
  @Output() transferirRota = new EventEmitter();

  private currentPage = 0;
  queryOptions = new QueryOptions();

  private readonly defaultFilterOptions: RotaListFilterOptions = {
    nomeRota: '',
    nomeCobrador: '',
    postoSelecionado: null,
  };

  filterOptions: RotaListFilterOptions;

  ngOnInit(): void {
    this.onLimparFiltros();
  }

  loadRotas(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadRotasPage.emit(pageNumber);
  }

  onDisclosure(rota: Rota): void {
    this.disclosureRota.emit(rota);
  }

  onAcaoClick(acao: string, domain: Rota): void {
    this.acaoClick.emit({ acao, rota: domain });
  }

  onAtualizar(): void {
    this.loadRotasPage.emit(this.currentPage);
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  public onMenuAcaoClick(acao: string): void {
    this.menuAcaoClickEvent.emit({ acao });
  }
}
