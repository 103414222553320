import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CobrancaVale } from 'src/app/base/domain/cobranca-vale.model';
import { Posto } from 'src/app/base/domain/posto.model';
import { QueryOptions } from '../../domain/query.options';
import { LazyLoadEvent } from 'primeng';

export interface CobrancaValeAcaoEvent {
  acao: string;
  cobranca: CobrancaVale;
}

export interface CobrancaValeListFilterOptions {
  funcionario: string;
  postoSelecionado: Posto;
  dataInicial: Date;
  dataFinal: Date;
}

@Component({
  selector: 'app-debitos-funcionario-list',
  templateUrl: './debitos-funcionario-list.component.html',
  styleUrls: ['./debitos-funcionario-list.component.css'],
})
export class DebitosFuncionarioListComponent implements OnInit {
  @Input() title: string;
  @Input() cobrancas: CobrancaVale[];
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() filtros: string[];
  @Input() options: string[];
  @Input() loading: boolean;

  @Output() filter = new EventEmitter<CobrancaValeListFilterOptions>();
  @Output() loadPage = new EventEmitter<number>();
  @Output() acaoClick = new EventEmitter<CobrancaValeAcaoEvent>();
  @Output() disclosure = new EventEmitter<CobrancaVale>();

  private currentPage = 0;
  queryOptions = new QueryOptions();

  private readonly defaultFilterOptions: CobrancaValeListFilterOptions = {
    funcionario: '',
    postoSelecionado: null,
    dataInicial: null,
    dataFinal: null,
  };

  filterOptions: CobrancaValeListFilterOptions;

  ngOnInit(): void {
    this.onLimparFiltros();
  }

  load(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadPage.emit(pageNumber);
  }

  onDisclosure(equipe: CobrancaVale): void {
    this.disclosure.emit(equipe);
  }

  onAcaoClick(acao: string, domain: CobrancaVale): void {
    this.acaoClick.emit({ acao, cobranca: domain });
  }

  onAtualizar(): void {
    this.loadPage.emit(this.currentPage);
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
