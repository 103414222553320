import { EstadoService } from 'src/app/base/services/estado.service';
import { AfterContentChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker/datepicker-input-base';
import { Posto } from '../../domain/posto.model';
import { PostoService } from '../../services/posto.service';
import { Page, ReturnAPI } from '../../domain/return-api.model';
import { Rota } from '../../domain/rota.model';
import { RotaService } from '../../services/rota.service';
import { QueryOptions } from '../../../global/domain/query.options';
import { Moment } from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { Estado } from '../../domain/estado.model';
import { SituacaoCliente } from '../../domain/enum/situacao-cliente.enum';
import { OrigemAcaoVendaEnum } from '../../domain/enum/origem-acao-venda.enum';
import { SituacaoContrato } from '../../domain/enum/situacao-contrato.enum';
import { SituacaoVisita } from '../../domain/enum/situacao-visita.enum';
import { PostoDTO } from '../../domain/dto/posto.dto';
import { RotaDTO } from '../../domain/dto/rota.dto';
import { Funcionario } from '../../domain/funcionario.model';
import { FuncionarioService } from '../../services/funcionario.service';
import { SituacaoInativarRenovacaoAutomatica } from '../../domain/enum/situacao-inativar-renovacao-automatica.enum';
import { Periodicidade } from '../../domain/enum/periodocidade.enum';

// @ts-ignore
declare var moment = require('moment');

export interface ReportFilterEmit {
  nomeCliente: string;
  postoSelecionado: Posto;
  formatoDownload: BooleanGereric;
  rotaSelecionada: Rota;
  dataInicial: Date;
  dataFinal: Date;
  endereco: BooleanEndereco;
  regularidade: BooleanEndereco;
  inputMinimo: number;
  inputMaximo: number;
  dataSemanal: Date;
  contratosRBM: BooleanGereric;
  inputNumerico: number;
  diasAtraso: number;
  situacaoEficiencia: BooleanGereric;
  opcaoGenerico: BooleanGereric;
  turnos: string[];
  multipleOpcaoGenerico: BooleanGereric[];
  estadoId: number;
  situacoesClienteSelecionada: SituacaoCliente[];
  situacoesContratoSelecionada: SituacaoContrato[];
  situacoesVisitaSelecionada: SituacaoVisita[];
  situacaoInativarRenovacaoAutomatica: SituacaoInativarRenovacaoAutomatica[];
  periodicidadeEnum: Periodicidade[];
  situacoesAcaoVendaSelecionada: OrigemAcaoVendaEnum[];
  checkSemanal: boolean;
  contratoNaoOperado: boolean;
  contratoRepassado: boolean;
  somenteClientesNovos: boolean;
  somenteClientesReativados: boolean;
  somenteClientesRenovados: boolean;
  somenteContratosCancelados: boolean;
  fiscal: Funcionario;
  cobrador: Funcionario;
}

export interface BooleanEndereco {
  id: number;
  descricao: string;
}

export interface BooleanGereric {
  id: number;
  descricao: string;
  acao?: string;
}

@Component({
  selector: 'app-report-filters',
  templateUrl: './report-filters.component.html',
  styleUrls: ['./report-filters.component.css'],
})
export class ReportFiltersComponent implements OnInit, AfterContentChecked {
  @Input() filtros: string[];
  @Input() filtersRequired: boolean;
  @Input() dataInicialPlaceholder: string;
  @Input() dataFinalPlaceholder: string;
  @Input() inputMinimoPlaceholder: string;
  @Input() inputMaximoPlaceholder: string;
  @Input() inputNumericoPlaceholder: string;
  @Input() diasAtrasoPlaceholder: string;
  @Input() opcao_1: String;
  @Input() opcao_2: String;
  @Input() opcao_3: String;
  @Input() opcaoDescricao: String;
  @Input() opcoes: BooleanGereric[];
  @Input() multipleOpcaoDescricao: string;
  @Input() opcoesAcoes: BooleanGereric[];
  @Input() opcaoAcaoDescricao: string;
  @Input() diaSemana: number;
  @Input() descricaoBotaoEnviar: string;
  @Input() opcaoPadraoDescricao: string;

  @Output() eventEmitter: EventEmitter<ReportFilterEmit> = new EventEmitter();

  postoSelecionado: Posto;
  formatoDownload: BooleanGereric;
  opcaoGenerico: BooleanGereric;
  estadoId: number;
  situacoesClienteSelecionada: null;
  situacoesContratoSelecionada: any;
  situacoesVisitaSelecionada: any;
  situacaoInativarRenovacaoAutomatica: any;
  periodicidadeEnum: any;
  situacoesAcaoVendaSelecionada: null;
  estados: Estado[] = [];
  postos: Posto[] = [];
  postosDigitais: Posto[] = [];
  rotaSelecionado: Rota;
  rotas: Rota[] = [];
  nomeCliente: string;
  endereco: BooleanEndereco;
  regularidade: BooleanEndereco;
  contratosRBM: BooleanGereric;
  inputMinimo: number;
  inputMaximo: number;
  dataSemanal: Date;
  inputNumerico: number;
  diasAtraso: number;
  situacaoEficiencia: BooleanGereric;
  dateMes: Date;
  turnos: string[] = [];
  multipleOpcaoGenerico: BooleanGereric[] = [];
  checkSemanal = true;
  contratoPendente: boolean;
  contratoRepassado: boolean;
  somenteClientesNovos: boolean;
  somenteClientesReativados: boolean;
  somenteClientesRenovados: boolean;
  somenteContratosCancelados: boolean;
  private queryOptions = new QueryOptions({});
  private dataInicial: Date;
  private dataFinal: Date;
  bottonEnviar: string;

  fiscais: Funcionario[] = [];
  fiscalSelecionado: Funcionario;

  cobradores: Funcionario[] = [];
  cobradorSelecionado: Funcionario;

  situacoesContrato: SituacaoContrato[] = Object.values(SituacaoContrato);
  situacoesVisita: SituacaoVisita[] = Object.values(SituacaoVisita);
  situacoesInativarRenovacaoAutomatica: SituacaoInativarRenovacaoAutomatica[] = Object.values(SituacaoInativarRenovacaoAutomatica);
  periodicidadesEnum: Periodicidade[] = Object.values(Periodicidade);

  situacoes: SituacaoCliente[] = Object.values(SituacaoCliente);
  situacoesAcaoVenda: OrigemAcaoVendaEnum[] = Object.values(OrigemAcaoVendaEnum);

  get formInvalid(): boolean {
    if (this.filtros === null) {
      return true;
    }

    if (!this.filtersRequired) {
      return this.filtersRequired;
    }

    const filtros = [
      this.filtros?.includes('nomeCliente') && !this.nomeCliente,
      this.filtros?.includes('posto') && !this.postoSelecionado,
      this.filtros?.includes('rota') && !this.rotaSelecionado,
      this.filtros?.includes('dataInicial') && !this.dataInicial,
      this.filtros?.includes('dataFinal') && !this.dataFinal,
      this.filtros?.includes('endereco') && !this.endereco,
      this.filtros?.includes('regularidade') && !this.regularidade,
      this.filtros?.includes('inputMinimo') && !this.inputMinimo,
      this.filtros?.includes('inputMaximo') && !this.inputMaximo,
      this.filtros?.includes('dataSemanal') && !this.dataSemanal,
      this.filtros?.includes('contratosRBM') && !this.contratosRBM,
      this.filtros?.includes('inputNumerico') && !this.inputNumerico,
      this.filtros?.includes('diasAtraso') && !this.diasAtraso,
      this.filtros?.includes('situacaoEficiencia') && !this.situacaoEficiencia,
      this.filtros?.includes('formatoDownload') && !this.formatoDownload,
      this.filtros?.includes('opcaoGenerico') && !this.opcaoGenerico,
      this.filtros?.includes('turnos') && !this.turnos.length,
      this.filtros?.includes('estado') && !this.estados.length,
      this.filtros?.includes('situacao-cliente') && !this.situacoesClienteSelecionada,
      this.filtros?.includes('checkSemanal') && !this.dataInicial && !this.dataSemanal,
      this.filtros?.includes('contratoNaoOperado') && !this.contratoPendente,
      this.filtros?.includes('contratoRepassado') && !this.contratoRepassado,
      this.filtros?.includes('somenteClientesNovos') && !this.somenteClientesNovos,
      this.filtros?.includes('somenteClientesReativados') && !this.somenteClientesReativados,
      this.filtros?.includes('somenteClientesRenovados') && !this.somenteClientesRenovados,
      this.filtros?.includes('somenteContratosCancelados') && !this.somenteContratosCancelados,
      this.filtros?.includes('posto-digital') && !this.postoSelecionado,
      this.filtros?.includes('cobrador') && !this.cobradorSelecionado,
    ];

    return filtros.some((f: boolean) => f);
  }

  constructor(
    private postoService: PostoService,
    private rotaService: RotaService,
    private estadoService: EstadoService,
    private readonly funcionarioservice: FuncionarioService,
  ) { }

  ngOnInit(): void {
    this.bottonEnviar = this.descricaoBotaoEnviar == null ? 'Gerar Relatório' : this.descricaoBotaoEnviar;
    this.listarPostos();
    this.listarEstados();
    this.listarPostosDigitais();
  }

  ngAfterContentChecked(): void {
  }

  onPickerEvent(data: string, evento: MatDatepickerInputEvent<Moment>): void {
    if (data === 'dataInicial') {
      this.dataInicial = evento.value.toDate();
    }
    if (data === 'dataFinal') {
      this.dataFinal = evento.value?.toDate();
    }
    if (data === 'dataSemanal') {
      this.dataSemanal = evento.value?.toDate();
    }
  }

  postoSelecionadoEvent(posto: Posto): void {
    if (posto.id !== 0) {
      this.buscarFiscaisPorPostoId(posto.id);
      this.fiscalSelecionado = null;
    }

    if (posto.id !== 0) {
      this.buscarCobradoresByPosto(posto.id);
      this.cobradorSelecionado = null;
    }

    this.queryOptions.pageNumber = 1;
    this.queryOptions.params = {
      postoId: posto.id || null,
    };

    if (posto != null) {
      this.rotaService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Rota>>) => {
        this.rotas = response.object.content;
      });
    }

    this.postoSelecionado = posto;
  }

  rotaSelecionadoEvent(rota: Rota): void {
    this.rotaSelecionado = rota;
  }

  gerar(): void {
    this.eventEmitter.emit({
      nomeCliente: this.nomeCliente || null,
      postoSelecionado: this.postoSelecionado || null,
      rotaSelecionada: this.rotaSelecionado || null,
      dataInicial: this.dataInicial || null,
      dataFinal: this.dataFinal || null,
      dataSemanal: this.dataSemanal || null,
      endereco: this.endereco || null,
      regularidade: this.regularidade || null,
      inputMinimo: this.inputMinimo || null,
      inputMaximo: this.inputMaximo || null,
      contratosRBM: this.contratosRBM || null,
      inputNumerico: this.inputNumerico || null,
      diasAtraso: this.diasAtraso || null,
      situacaoEficiencia: this.situacaoEficiencia || null,
      formatoDownload: this.formatoDownload || null,
      opcaoGenerico: this.opcaoGenerico || null,
      turnos: this.turnos || null,
      multipleOpcaoGenerico: this.multipleOpcaoGenerico || null,
      estadoId: this.estadoId || null,
      situacoesClienteSelecionada: this.situacoesClienteSelecionada || null,
      situacoesAcaoVendaSelecionada: this.situacoesAcaoVendaSelecionada || null,
      situacoesContratoSelecionada: this.situacoesContratoSelecionada || null,
      situacoesVisitaSelecionada: this.situacoesVisitaSelecionada || null,
      situacaoInativarRenovacaoAutomatica: this.situacaoInativarRenovacaoAutomatica || null,
      periodicidadeEnum: this.periodicidadeEnum || null,
      checkSemanal: this.checkSemanal || null,
      somenteContratosCancelados: this.somenteContratosCancelados || null,
      contratoNaoOperado: this.contratoPendente || false,
      contratoRepassado: this.contratoRepassado || false,
      somenteClientesNovos: this.somenteClientesNovos || false,
      somenteClientesReativados: this.somenteClientesReativados || false,
      somenteClientesRenovados: this.somenteClientesRenovados || false,
      fiscal: this.fiscalSelecionado || null,
      cobrador: this.cobradorSelecionado || null,
    });
  }

  emitirValorDefault(): void {
    this.rotas = [];
  }

  checkSemanalChange(): void {
    if (this.checkSemanal) {
      this.dataInicial = null;
    } else {
      this.dataSemanal = null;
    }
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>): void {
    this.dateMes = new Date(normalizedMonth.year(), normalizedMonth.month());
    this.dataInicial = this.dateMes;
    datepicker.close();
  }

  myDateFilterSemanal = (m: Moment | null): boolean => {
    const day = (m || moment()).day();
    return day === this.diaSemana;
  };

  estadoSelecionado(estado: Estado): void {
    this.postoService.listarPostosEstadoRelatorioRespesas(estado.sigla).subscribe((data: PostoDTO[]) => {
      const postos = [];
      // tslint:disable-next-line:typedef
      data
        .filter((f) => f.estadoId === estado.id)
        .map((m) => {
          const p = new Posto();
          p.id = m.postoId;
          p.descricao = m.descricaoPosto;
          postos.push(p);
        });
      this.postos = postos;
    });
  }

  private listarPostos(): void {
    this.postoService.listarPostos().subscribe((response: ReturnAPI<Posto[]>) => {
      if (response.success) {
        this.postos = response.object;
      }
    });
  }

  private listarPostosDigitais(): void {
    this.postoService.listarPostosDigitais().subscribe((response: ReturnAPI<Posto[]>) => {
      if (response.success) {
        this.postosDigitais = response.object;
      }
    });
  }

  resetarAtributos(): void {
    this.emitirValorDefault();

    this.listarPostos();
  }

  private listarEstados(): void {
    this.estadoService.list(new QueryOptions()).subscribe((response: Estado[]) => {
      this.estados = response;
    });
  }

  buscarFiscaisPorPostoId(postoId: number): void {
    this.funcionarioservice.listarVisitadoresPorPosto(postoId).subscribe((response: ReturnAPI<Funcionario[]>) => {
      this.fiscais = response.object;
    });
  }

  buscarCobradoresByPosto(postoId: number): void {
    this.funcionarioservice.listFuncionarioByCargoAndPosto(3, postoId).subscribe((response: ReturnAPI<Funcionario[]>) => {
      this.cobradores = response.object;
    });
  }
}
