import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { Produto } from '../domain/produto.model';
import { Page, ReturnAPI } from '../domain/return-api.model';
import { ParcelaCobrancaValeSerializer } from '../serializables/parcela-cobranca-vale.serializer';
import { ParcelaCobrancaVale } from '../domain/parcela-cobranca-vale.model';

@Injectable()
export class ParcelaCobrancaValeService extends ResourceService<ParcelaCobrancaVale> {
  static RESOURCE = 'parcela-cobranca-vale';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${ParcelaCobrancaValeService.RESOURCE}`, new ParcelaCobrancaValeSerializer(ParcelaCobrancaVale));
  }

  findAll(queryOption: QueryOptions): Observable<ReturnAPI<Page<ParcelaCobrancaVale>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<ParcelaCobrancaVale>>>(
      `${WEB_API}/${ParcelaCobrancaValeService.RESOURCE}?${queryOption.toQueryString()}&${this.produtoId}`,
    );
  }

  baixaParcela(parcela: ParcelaCobrancaVale): Observable<ReturnAPI<ParcelaCobrancaVale>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<ParcelaCobrancaVale>>(
      `${WEB_API}/${ParcelaCobrancaValeService.RESOURCE}/baixa-parcela?${this.produtoId}`,
      parcela,
    );
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }
}
