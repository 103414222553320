import { AlterarValorContratoDTO } from './../../../domain/dto/alterar-valor-contrato-dto';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Contrato } from '../../../domain/contrato.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ContratoService } from '../../../services/contrato.service';
import { ReturnAPI, Page } from '../../../domain/return-api.model';
import { DialogService } from 'src/app/global/services/dialog.service';
import { ContratoAcaoEvent, ContratoListFilterOptions } from 'src/app/global/lists/contrato-list/contrato-list.component';
import { CancelarContratoFormComponent } from 'src/app/global/forms/cancelar-contrato-form/cancelar-contrato-form.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Parcela } from '../../../domain/parcela.model';
import { SituacaoContrato } from '../../../domain/enum/situacao-contrato.enum';
import { AuditoriaContrato } from 'src/app/base/domain/auditoria-contrato.model';
import { AlterarContratoFormComponent } from 'src/app/global/forms/alterar-contrato-form/alterar-contrato-form.component';
import { AlterarContratoDTO } from 'src/app/base/domain/dto/alterar-contrato-dto';
import { ValoresContratoRequestDTO } from 'src/app/base/domain/dto/valores-contrato-requesto.dto';
import {
  TransferirContrato,
  TransferirResponsavelFormComponent,
} from 'src/app/global/forms/transferir-responsavel-form/transferir-responsavel-form.component';
import { SharedService } from 'src/app/auth/shared.service';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { AppState } from 'src/app/App.state';
import { AlterarValorContratoFormComponent } from 'src/app/global/forms/alterar-valor-contrato-form/alterar-valor-contrato-form.component';
import { LiquidarContratoFormComponent } from 'src/app/global/forms/liquidar-contrato-form/liquidar-contrato-form.component';
import { FinalizarContratoComDividaComponent } from 'src/app/global/forms/finalizar-contrato-com-divida/finalizar-contrato-com-divida.component';

@Component({
  selector: 'app-acompanhamento-contrato-main',
  templateUrl: './acompanhamento-contrato-main.component.html',
  styleUrls: ['./acompanhamento-contrato-main.component.css'],
})
export class AcompanhamentoContratoMainComponent implements OnInit {
  private static CANCELAR_CONTRATO_ADMINISTRATIVO = 'Cancelar Contrato';
  private static CANCELAR_CONTRATO_LIBERADO_ADMINISTRATIVO = 'Cancelar Contrato Liberado';
  private static ALTERAR_CONTRATO = 'Alterar Contrato';
  private static ALTERAR_CONTRATO_PIX_LIBERADO = 'Alterar Contrato Liberado';
  private static PERMITIR_REPASSE_BLOQUEADO_POR_INADIMPLENCIA = 'Desbloquear Repasse (p/ Inadimplência)';
  private static ALTERAR_VALOR_CONTRATO_EM_ANDAMENTO = 'Ajuste de Contrato';
  private static CANCELAR_CONTRATO_ADMINISTRATIVO_SEM_RETORNO = 'Cancelar Contrato sem Retorno';
  private static FINALIZAR_CONTRATO_COM_DIVIDA = 'Finalizar Contrato e Gerar Vale Vendedor';

  contratos: Contrato[] = [];
  menuAcoes: string[] = [];

  shared: SharedService;
  usuario: Usuario;

  queryOptions = new QueryOptions({ pageSize: 25 });
  totalRecords = 0;
  loading = false;
  totalizadorBruto: number;
  totalizadorLiquido: number;
  private postoSelecionado: number = null;

  constructor(
    private router: Router,
    private contratoService: ContratoService,
    private dialogService: DialogService,
    public dialog: MatDialog,
    public appState: AppState,
  ) {
    const shared = SharedService.getInstance();
    this.usuario = shared.usuario;
    if (this.usuario.profile !== 'ROLE_PRESTADOR' && this.usuario.profile !== 'ROLE_RETAGUARDA') {
      this.menuAcoes.push('Transferir Responsavel');
      this.menuAcoes.push('Transferir Cobranças');
    }
  }

  ngOnInit(): void {}

  listarContratos(pageNumber: number = 0, valoresContrato: Partial<ValoresContratoRequestDTO> = {}): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.queryOptions.params = { ...this.queryOptions.params, bloqueado: 'inadimplencia' };
    this.loading = true;
    this.contratoService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Contrato>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        if (this.usuario.profile !== 'ROLE_RETAGUARDA') {
          this.mapearAcoes(response.object.content);
        }
      } else {
        this.contratos = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
      valoresContrato.dataInicial = valoresContrato.dataInicial ? valoresContrato.dataInicial : null;
      valoresContrato.dataFinal = valoresContrato.dataFinal ? valoresContrato.dataFinal : null;
      valoresContrato.nome = valoresContrato.nome ? valoresContrato.nome : '';
      valoresContrato.idCliente = valoresContrato.idCliente ? valoresContrato.idCliente : '';
      valoresContrato.descricaoRota = valoresContrato.descricaoRota ? valoresContrato.descricaoRota : '';
      valoresContrato.situacoesContrato = valoresContrato.situacoesContrato ? valoresContrato.situacoesContrato : [];
      this.getValorTotalBruto({ ...valoresContrato, postoId: this.postoSelecionado });
      this.getValorTotalLiquido({ ...valoresContrato, postoId: this.postoSelecionado });
    });
  }

  mapearAcoes(contratos: Contrato[]): void {
    const mapearAcoes = (contrato: Contrato): Contrato => {
      const acoes = [];
      const contratoParaLiberacao = [SituacaoContrato.NOVO, SituacaoContrato.GERADO, SituacaoContrato.RENOVADO].includes(
        contrato.situacaoContrato,
      );

      if (
        (contrato.situacaoContrato === SituacaoContrato.EM_ATRASO || contrato.situacaoContrato === SituacaoContrato.RENEGOCIADO) 
        && ['admin@gmail.com'].some((email: string) => this.usuario.email === email)
      ) {
        acoes.push(AcompanhamentoContratoMainComponent.FINALIZAR_CONTRATO_COM_DIVIDA);
      }

      if (
        contrato.situacaoContrato === SituacaoContrato.EM_ANDAMENTO && ['admin@gmail.com'].some((email: string) => this.usuario.email === email) &&
        !contrato.listParcela.find((parcela: Parcela) => parcela.situacaoParcela === 'PENDENTE_CONFIRMAR_REPASSE_CLIENTE')
      ) {
        acoes.push(AcompanhamentoContratoMainComponent.CANCELAR_CONTRATO_ADMINISTRATIVO);
      }

      if (
        contrato.situacaoContrato === SituacaoContrato.EM_ANDAMENTO &&
        ['admin@gmail.com'].some((email: string) => this.usuario.email === email)
      ) {
        acoes.push(AcompanhamentoContratoMainComponent.ALTERAR_VALOR_CONTRATO_EM_ANDAMENTO);
        acoes.push(AcompanhamentoContratoMainComponent.CANCELAR_CONTRATO_ADMINISTRATIVO_SEM_RETORNO);
      }

      if (contratoParaLiberacao && ['admin@gmail.com'].some((email: string) => this.usuario.email === email)) {
        acoes.push(AcompanhamentoContratoMainComponent.CANCELAR_CONTRATO_ADMINISTRATIVO);
      }

      if (
        contratoParaLiberacao &&
        (this.usuario.profile.includes('ROLE_DIRETOR') ||
          this.usuario.profile.includes('ROLE_PRESTADOR') ||
          this.usuario.profile.includes('ROLE_ADMIN'))
      ) {
        acoes.push(AcompanhamentoContratoMainComponent.ALTERAR_CONTRATO);
      }

      if (contrato.situacaoContrato === SituacaoContrato.LIBERADO && ['admin@gmail.com'].some((email: string) => this.usuario.email === email)) {
        acoes.push(AcompanhamentoContratoMainComponent.CANCELAR_CONTRATO_LIBERADO_ADMINISTRATIVO);
      }

      if (contratoParaLiberacao && contrato.repasseBloqueadoPorInadimplencia && contrato.repasseLiberadoInadimplente === false) {
        acoes.push(AcompanhamentoContratoMainComponent.PERMITIR_REPASSE_BLOQUEADO_POR_INADIMPLENCIA);
      }

      if (
        contrato.situacaoContrato === SituacaoContrato.LIBERADO &&
        contrato.visita.pagamentoPix &&
        (this.usuario.profile.includes('ROLE_DIRETOR') ||
          this.usuario.profile.includes('ROLE_PRESTADOR') ||
          this.usuario.profile.includes('ROLE_ADMIN'))
      ) {
        acoes.push(AcompanhamentoContratoMainComponent.ALTERAR_CONTRATO_PIX_LIBERADO);
      }

      return { ...contrato, acoes };
    };

    this.contratos = contratos?.map(mapearAcoes) ?? [];
  }

  onAcaoClick(event: ContratoAcaoEvent): void {
    const actions = new Map<string, (contrato: Contrato) => void>()
      .set(AcompanhamentoContratoMainComponent.CANCELAR_CONTRATO_ADMINISTRATIVO, (contrato: Contrato) => {
        this.desistirContrato(contrato);
      })
      .set(AcompanhamentoContratoMainComponent.CANCELAR_CONTRATO_LIBERADO_ADMINISTRATIVO, (contrato: Contrato) => {
        this.cancelarLiberadoContrato(contrato);
      })
      .set(AcompanhamentoContratoMainComponent.ALTERAR_CONTRATO, (contrato: Contrato) => {
        this.alterarContrato(contrato);
      })
      .set(AcompanhamentoContratoMainComponent.PERMITIR_REPASSE_BLOQUEADO_POR_INADIMPLENCIA, (contrato: Contrato) => {
        this.permitirRepasseBloqueadoPorInadimplencia(contrato);
      })
      .set(AcompanhamentoContratoMainComponent.ALTERAR_VALOR_CONTRATO_EM_ANDAMENTO, (contrato: Contrato) => {
        this.alterarValorContratoEmAndamento(contrato);
      })
      .set(AcompanhamentoContratoMainComponent.ALTERAR_CONTRATO_PIX_LIBERADO, (contrato: Contrato) => {
        this.alterarContratoPixLiberado(contrato);
      })
      .set(AcompanhamentoContratoMainComponent.CANCELAR_CONTRATO_ADMINISTRATIVO_SEM_RETORNO, (contrato: Contrato) => {
        this.desistirContratoSemRetorno(contrato);
      })
      .set(AcompanhamentoContratoMainComponent.FINALIZAR_CONTRATO_COM_DIVIDA, (contrato: Contrato) => {
        this.finalizarContratoComDivida(contrato);
      });
    actions.get(event.acao)(event.contrato);
  }

  permitirRepasseBloqueadoPorInadimplencia(contrato: Contrato): void {
    this.dialogService.confirmDialog('Deseja realmente permitir a liberação do contrato para repasses?', () => {
      this.contratoService.permitirRepasseBloqueadoPorInadimplencia(contrato.id).subscribe(
        (response: ReturnAPI<void>) => {
          this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
          this.listarContratos();
        },
        (e: HttpErrorResponse) => {
          this.dialogService.feedbackError(`Houve um erro ao realizar operação.\n ${e.message}`);
        },
      );
    });
  }

  alterarValorContratoEmAndamento(contrato: Contrato): void {
    const dialogRef = this.dialog.open(AlterarValorContratoFormComponent, {
      data: contrato,
      width: '750px',
    });

    const options = new QueryOptions({ params: { usuarioHeimdall: this.usuario.id } });

    dialogRef.afterClosed().subscribe((dto: AlterarValorContratoDTO) => {
      if (dto) {
        this.contratoService.alterarValorContratoEmAndamento(options, dto).subscribe(
          (response: ReturnAPI<Contrato>) => {
            this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
            this.listarContratos();
          },
          (e: HttpErrorResponse) => {
            this.dialogService.feedbackError(`Houve um erro ao alterar contrato.\n ${e.message}`);
          },
        );
      }
    });
  }

  desistirContrato(contrato: Contrato): void {
    const dialogRef = this.dialog.open(CancelarContratoFormComponent, {
      data: {
        contrato: contrato,
        action: 'DESISTIR_CONTRATO',
      },
      width: '350px',
    });

    dialogRef.afterClosed().subscribe((auditoria: AuditoriaContrato) => {
      if (auditoria) {
        this.contratoService.cancelarContratoAdministrativo(auditoria).subscribe(
          (response: ReturnAPI<Contrato>) => {
            this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
            this.listarContratos();
          },
          (e: HttpErrorResponse) => {
            this.dialogService.feedbackError(`Houve um erro ao cancelar contrato.\n ${e.message}`);
          },
        );
      }
    });
  }

  async finalizarContratoComDivida(contrato: Contrato): Promise<void> {
    const contratoResult = await this.dialog
      .open(FinalizarContratoComDividaComponent, {
        data: {
          contrato,
          action: 'FINALIZAR_COM_DIVIDA',
        },
      })
      .afterClosed()
      .toPromise();

    if (contratoResult) {
      const auditoriaContrato = new AuditoriaContrato();

      auditoriaContrato.idContrato = contrato.id;
      auditoriaContrato.uidContrato = contrato.uid;
      this.appState.isLoading = true;
      this.contratoService.finalizarContratoComDivida(auditoriaContrato).subscribe(
        (response: ReturnAPI<Contrato>) => {
          this.dialogService.feedbackReturnAPI(response, 'Finalizado com sucesso!');
          this.appState.isLoading = false;
        },
        () => {
          this.appState.isLoading = false;
          this.dialogService.feedbackError('Houve um erro na solicitacao!');
        },
      );
    }
  }

  desistirContratoSemRetorno(contrato: Contrato): void {
    const dialogRef = this.dialog.open(CancelarContratoFormComponent, {
      data: {
        contrato: contrato,
        action: 'DESISTIR_CONTRATO_SEM_RETORNO',
      },
      width: '350px',
    });

    dialogRef.afterClosed().subscribe((auditoria: AuditoriaContrato) => {
      if (auditoria) {
        this.contratoService.cancelarContratoAdministrativo(auditoria).subscribe(
          (response: ReturnAPI<Contrato>) => {
            this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
            this.listarContratos();
          },
          (e: HttpErrorResponse) => {
            this.dialogService.feedbackError(`Houve um erro ao cancelar contrato.\n ${e.message}`);
          },
        );
      }
    });
  }

  alterarContrato(contrato: Contrato): void {
    const dialogRef = this.dialog.open(AlterarContratoFormComponent, {
      data: { contrato },
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((alterarContratoDTO: AlterarContratoDTO) => {
      if (!alterarContratoDTO) {
        return;
      }
      alterarContratoDTO.validarAumentoCemPorCento = this.usuario.profile !== 'ROLE_ADMIN';

      const alterarContrato = () => {
        this.contratoService.alterarContrato(alterarContratoDTO).subscribe(
          (response: ReturnAPI<Contrato>) => {
            this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
            this.listarContratos();
          },
          (e: HttpErrorResponse) => {
            this.dialogService.feedbackError(`Houve um erro ao alterar contrato.\n ${e.message}`);
          },
        );
      };

      if (
        alterarContratoDTO.validarAumentoCemPorCento === false &&
        (alterarContratoDTO.valorBruto >= contrato.valorBruto * 2 || contrato.aprovacaoAnteriorCemPorCento)
      ) {
        this.dialogService.confirmDialog('Deseja realmente aprovar crédito igual ou superior a 100% do valor anterior aprovado?', () => {
          alterarContrato();
        });
      } else {
        alterarContrato();
      }
    });
  }

  alterarContratoPixLiberado(contrato: Contrato): void {
    const dialogRef = this.dialog.open(AlterarContratoFormComponent, {
      data: { contrato },
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((alterarContratoDTO: AlterarContratoDTO) => {
      if (!alterarContratoDTO) {
        return;
      }
      alterarContratoDTO.validarAumentoCemPorCento = this.usuario.profile !== 'ROLE_ADMIN';

      const alterarContrato = () => {
        this.contratoService.alterarContrato(alterarContratoDTO).subscribe(
          (response: ReturnAPI<Contrato>) => {
            this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
            this.listarContratos();
          },
          (e: HttpErrorResponse) => {
            this.dialogService.feedbackError(`Houve um erro ao alterar contrato.\n ${e.message}`);
          },
        );
      };

      alterarContrato();
    });
  }

  cancelarLiberadoContrato(contrato: Contrato): void {
    const dialogRef = this.dialog.open(CancelarContratoFormComponent, {
      data: {
        contrato: contrato,
        action: 'CANCELAR_CONTRATO',
      },
      width: '350px',
    });

    dialogRef.afterClosed().subscribe((auditoria: AuditoriaContrato) => {
      if (auditoria) {
        this.contratoService.cancelarContratoAdministrativo(auditoria).subscribe(
          (response: ReturnAPI<Contrato>) => {
            this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
            this.listarContratos();
          },
          (e: HttpErrorResponse) => {
            this.dialogService.feedbackError(`Houve um erro ao cancelar contrato.\n ${e.message}`);
          },
        );
      }
    });
  }

  onContratosPage(page: number): void {
    this.listarContratos(page);
  }

  onDisclosure(contrato: Contrato): void {
    this.router.navigate(['base/contratos/acompanhamento-contrato-detail/' + contrato.id]);
  }

  onFilter(options: ContratoListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    this.queryOptions.params = {
      idCliente: options.idCliente === '' ? null : options.idCliente,
      nomeCliente: options.nomeCliente,
      situacaoContrato: options.situacoesContrato?.length ? `in:${options.situacoesContrato.join(',')}` : null,
      postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
      repassePendente: options.repassePendente || null,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
      descricaoRota: options.descricaoRota,
      id: options.id === '' ? null : options.id,
    };

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }

    this.listarContratos(0, {
      idCliente: options.idCliente,
      nome: options.nomeCliente,
      descricaoRota: options.descricaoRota,
      situacoesContrato: options.situacoesContrato,
      dataInicial: options?.dataInicial,
      dataFinal: options?.dataFinal,
      id: options?.id,
    });
  }

  onTransferirContratos(contratosSelecionados: Contrato[]): void {
    const dialogRef = this.dialog.open(TransferirResponsavelFormComponent, {
      data: {
        title: 'Selecionar novo responsável dos contratos',
        checkboxText: 'Alterar contratos de uma rota?',
        inputPlaceholder: 'Funcionario',
        inputPlaceholderPosto: 'Posto',
        contratos: contratosSelecionados,
      },
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((transferirContrato: TransferirContrato) => {
      if (transferirContrato) {
        this.appState.isLoading = true;
        this.contratoService.transferirResponsavelContrato(transferirContrato).subscribe(
          (response: ReturnAPI<TransferirContrato>) => {
            this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
            this.listarContratos();
            this.appState.isLoading = false;
          },
          (e: HttpErrorResponse) => {
            this.dialogService.feedbackError(`Houve um erro ao transferir contrato.\n ${e.message}`);
            this.appState.isLoading = false;
          },
        );
      }
    });
  }

  onTransferirCobrancas(contratosSelecionados: Contrato[]): void {
    const dialogRef = this.dialog.open(TransferirResponsavelFormComponent, {
      data: {
        title: 'Selecionar novo responsável das cobranças',
        checkboxText: 'Alterar cobranças de uma rota?',
        inputPlaceholder: 'Funcionario',
        inputPlaceholderPosto: 'Posto',
        contratos: contratosSelecionados,
      },
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((transferirContrato: TransferirContrato) => {
      if (transferirContrato) {
        this.appState.isLoading = true;
        this.contratoService.transferirResponsavelCobrancas(transferirContrato).subscribe(
          (response: ReturnAPI<TransferirContrato>) => {
            this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
            this.appState.isLoading = false;
            this.listarContratos();
          },
          (e: HttpErrorResponse) => {
            this.dialogService.feedbackError(`Houve um erro ao transferir contrato.\n ${e.message}`);
            this.appState.isLoading = false;
          },
        );
      }
    });
  }

  getValorTotalBruto(valoresContrato: Partial<ValoresContratoRequestDTO>): void {
    this.contratoService.getValorTotalBruto(valoresContrato).subscribe((response: ReturnAPI<number>) => {
      this.totalizadorBruto = response.object || 0;
    });
  }

  getValorTotalLiquido(valoresContrato: Partial<ValoresContratoRequestDTO>): void {
    this.contratoService.getValorTotalLiquido(valoresContrato).subscribe((response: ReturnAPI<number>) => {
      this.totalizadorLiquido = response.object || 0;
    });
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
