import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from 'src/app/App.state';
import { SharedService } from 'src/app/auth/shared.service';
import { Contrato } from 'src/app/base/domain/contrato.model';
import { RelatorioContabilidadeDTO } from 'src/app/base/domain/dto/relatorio-contabilidade-dto';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { ContratoService } from 'src/app/base/services/contrato.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ContratoListFilterOptions } from 'src/app/global/lists/contrato-list/contrato-list.component';
import { DialogService } from 'src/app/global/services/dialog.service';
import * as FileSaver from 'file-saver';
import { Posto } from 'src/app/base/domain/posto.model';
import * as moment from 'moment';

@Component({
  selector: 'app-contas-encerradas-contratos',
  templateUrl: './contas-encerradas-contratos.component.html',
  styleUrls: ['./contas-encerradas-contratos.component.css'],
})
export class ContasEncerradasContratosComponent implements OnInit {
  contratos: Contrato[] = [];
  menuAcoes: String[] = [];
  selectAllContrato: boolean = false;

  shared: SharedService;
  usuario: Usuario;
  dataFinal: number;
  dataInicial: number;
  posto: Posto;

  queryOptions = new QueryOptions({
    pageSize: 25,
    params: {
      situacaoContrato: 'in:REPACTUADO,FINALIZADO,FINALIZADO_COM_ANTECIPACAO,FINALIZADO_COM_ATRASO,LIQUIDADO_ADMINISTRACAO,FINALIZADO_COM_DIVIDA,FINALIZADO_RENEGOCIADO,FINALIZADO_REPACTUADO',
      exportadoFinalizado: 'eq: false',
    },
  });
  totalRecords = 0;
  loading = false;

  private postoSelecionado: number = null;

  constructor(
    private router: Router,
    private contratoService: ContratoService,
    private dialogService: DialogService,
    public dialog: MatDialog,
    public appState: AppState,
  ) {
    const shared = SharedService.getInstance();
    this.usuario = shared.usuario;
    this.menuAcoes.push('Exportar Lote');
    this.menuAcoes.push('Visualizar Lote');
  }

  ngOnInit(): void {}

  listarContratos(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.contratoService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Contrato>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.contratos = response.object.content;
      } else {
        this.contratos = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
    });
  }

  onFilter(options: ContratoListFilterOptions): void {
    this.posto = options.postoSelecionado;
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    this.queryOptions.params = {
      postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
      dataInicialFinalizacao: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinalFinalizacao: options.dataFinal ? options.dataFinal.getTime() : null,
      nomeCliente: options.nomeCliente ? options.nomeCliente : null,
      situacaoContrato: 'in:REPACTUADO,FINALIZADO,FINALIZADO_COM_ANTECIPACAO,FINALIZADO_COM_ATRASO,LIQUIDADO_ADMINISTRACAO,FINALIZADO_COM_DIVIDA,FINALIZADO_RENEGOCIADO,FINALIZADO_REPACTUADO',
      exportadoFinalizado: 'eq: false',
    };

    this.dataFinal = options.dataFinal ? options.dataFinal.getTime() : null;
    this.dataInicial = options.dataInicial ? options.dataInicial.getTime() : null;

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }

    this.listarContratos(0);
  }

  onContratosPage(page: number): void {
    this.listarContratos(page);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  onSelectAll(value: boolean): void {
    this.selectAllContrato = value;
  }

  onExportarLote(contratosSelecionado: Contrato[]): void {
    if (!this.postoSelecionado) {
      this.dialogService.feedbackInfo('Nenhum posto foi selecionado');
    } else if (!this.dataFinal || !this.dataInicial) {
      this.dialogService.feedbackInfo('Data inicial ou final não preenchida');
    } else if (contratosSelecionado.length == 0) {
      this.dialogService.feedbackInfo('Nenhum contrato foi selecionado');
    } else {
      const relatorio: RelatorioContabilidadeDTO = {
        postoId: this.postoSelecionado,
        datas: `${this.dataInicial},${this.dataFinal}`,
        contratos: contratosSelecionado.map((contrato: Contrato) => contrato.id),
        todosContrato: this.selectAllContrato,
      };

      this.contratoService.gerarRelatorioFinalizado(relatorio).subscribe((response) => {
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-finalizado-${this.posto.descricao}-${moment(this.dataInicial).format('DDMMYY')}_${moment(this.dataFinal).format(
            'DDMMYY',
          )}.xlsx`,
        );
        this.listarContratos();
      });
    }
  }

  onDisclosure(contrato: Contrato): void {
    this.router.navigate(['base/contratos/acompanhamento-contrato-detail/' + contrato.id]);
  }

  onVisualizarLote(): void {
    this.router.navigate(['base/exportacao/contas-encerradas-arquivos/']);
  }
}
