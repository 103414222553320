import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { ArquivoExtrato } from '../domain/arquivo-extrato.model';
import { Produto } from '../domain/produto.model';
import { Page } from '../domain/return-api.model';
import { ConciliacaoBancariaSerializer } from '../serializables/conciliacao-bancaria.serializer';
import { ConciliacaoBancaria } from './../domain/conciliacao-bancaria.model';
@Injectable()
export class ArquivoExtratoService extends ResourceService<ArquivoExtrato> {
  private static RESOURCE = 'arquivo-extrato';
  private static APAGAR_ARQUIVO = 'apagar-arquivo';

  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${ArquivoExtratoService.RESOURCE}`, new ConciliacaoBancariaSerializer(ConciliacaoBancaria));
  }

  findAllPageable(queryOptions: QueryOptions): Observable<Page<ArquivoExtrato>> {
    return this.http.get<Page<ArquivoExtrato>>(`${WEB_API}/${ArquivoExtratoService.RESOURCE}/pageable?${queryOptions.toQueryString()}`);
  }

  importFile(documento: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', documento);

    return this.http.post<any>(`${WEB_API}/${ArquivoExtratoService.RESOURCE}/import-file`, formData);
  }

  apagarArquivo(arquivoId: number): Observable<any> {
    return this.http.post<any>(`${WEB_API}/${ArquivoExtratoService.RESOURCE}/${ArquivoExtratoService.APAGAR_ARQUIVO}/${arquivoId}`, null);
  }
}
