import { ConciliacaoBancariaService } from './../../../base/services/conciliacao-bancaria.service';
import { VisualizarConciliacaoBancariaData } from './../../../base/conciliacao/visualizar-conciliacao-bancaria/visualizar-conciliacao-bancaria.component';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppState } from 'src/app/App.state';
import { Posto } from 'src/app/base/domain/posto.model';
import { ClienteService } from 'src/app/base/services/cliente.service';
import { QueryOptions } from '../../domain/query.options';
import { ClienteSelectDTO } from './../../../base/domain/dto/cliente-select.dto';
import { Estado } from './../../../base/domain/estado.model';
import { EstadoService } from './../../../base/services/estado.service';
import { PostoService } from './../../../base/services/posto.service';

@Component({
  selector: 'app-conciliacao-associacao-form',
  templateUrl: './conciliacao-associacao-form.component.html',
  styleUrls: ['./conciliacao-associacao-form.component.css'],
})
export class ConciliacaoAssociacaoFormComponent implements OnInit {
  private timer: number;

  public clientesSelectDTO: ClienteSelectDTO[] = [];
  private estados: Estado[] = [];

  private queryOptionsEstado = new QueryOptions({
    pageNumber: 1,
    params: {},
  });

  private queryOptionsPosto = new QueryOptions({
    pageNumber: 1,
    params: {},
  });

  private queryOptionsCliente = new QueryOptions({
    pageNumber: 1,
    pageSize: 1000,
    params: {},
  });

  public filteredEstados: Estado[] = [];
  public postos: Posto[] = [];

  public estado: number;
  public postoId: number = 3;
  public clienteId: number;

  private estadoForm = new FormControl(null, Validators.required);
  private postoForm = new FormControl({ disabled: true }, Validators.required);
  private clienteForm = new FormControl({ value: null, disabled: true }, Validators.required);

  public enderecoControl = new FormGroup({
    estado: this.estadoForm,
    posto: this.postoForm,
    cliente: this.clienteForm,
  });

  constructor(
    private clienteService: ClienteService,
    private estadoService: EstadoService,
    private postoService: PostoService,
    private appState: AppState,
    private conciliacaoBancariaService: ConciliacaoBancariaService,
    private dialogRef: MatDialogRef<ConciliacaoAssociacaoFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: VisualizarConciliacaoBancariaData,
  ) {}

  ngOnInit(): void {
    // estados
    this.estadoService.list(this.queryOptionsEstado).subscribe((response: Estado[]) => {
      this.estados = response;
      this.filteredEstados = this.estados;
    });
  }

  public doFilterEstado(value: string): void {
    if (value === 'estado') {
      const estadoFormControl = this.enderecoControl.get('estado');
      this.filteredEstados = this._filtrarEstado(estadoFormControl.value, this.estados, 'nome');
    }
  }

  private _filtrarEstado<T>(value: string, list: T[], field: string): T[] {
    const filterValue = value?.toLowerCase();
    return list.filter((e: T) => e[field].toLowerCase().indexOf(filterValue) === 0);
  }

  public doFilterCliente(nomeCliente: string) {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.queryOptionsCliente.params = Object.assign(this.queryOptionsCliente.params, {
      nomeCliente,
    });

    this.timer = setTimeout(this.updateClientes.bind(this), 700);
  }

  public getTitleEstado(estado: Estado) {
    if (!estado) {
      return;
    }
    return estado.nome;
  }

  public getTitleCliente(clienteSelectDTO: ClienteSelectDTO) {
    if (!clienteSelectDTO) {
      return;
    }
    return clienteSelectDTO.nome;
  }

  public onCancel() {
    this.dialogRef.close(false);
  }

  public onConfirm() {
    const raw = this.enderecoControl.getRawValue();
    const data = {
      descricaoPosto: raw.posto.descricao,
      uidCliente: raw.cliente.uid,
      nomeCliente: raw.cliente.nome,
      documento: raw.cliente.documento,
      nome: this.data.conciliacaoBancaria.arquivo['name'],
      uuidPosto: raw.posto.uid,
      idEstado: raw.estado.id,
      idArquivo: this.data.conciliacaoBancaria.arquivo.id,
    };
    this.conciliacaoBancariaService.associar(data).subscribe(
      (_) => {
        this.dialogRef.close(true);
      },
      (_) => {
        this.dialogRef.close(false);
      },
    );
  }

  onSelectedEstado(estado: Estado): void {
    this.estado = estado.id;
    this.queryOptionsPosto.params = {
      produtoId: 0,
      codigoEstado: estado.id,
    };

    this.updatePostos();
  }

  onSelectPosto(posto: Posto) {
    this.queryOptionsCliente.params = Object.assign(this.queryOptionsCliente.params, {
      postoId: posto.id,
      produtoId: 0,
      codigoEstado: this.estado,
    });

    this.updateClientes();
  }

  private updatePostos() {
    this.appState.isLoading = true;
    this.clienteForm.disable();
    this.postos = [];

    this.postoService
      .findAllWithoutProduto(this.queryOptionsPosto)
      .map((result) => result.object.content)
      .subscribe(
        (postos) => {
          this.postos = postos;
          this.appState.isLoading = false;

          if (postos && postos.length > 0) {
            this.clienteForm.enable();
          }
        },
        (_) => {
          this.appState.isLoading = false;
        },
      );
  }

  private updateClientes() {
    this.appState.isLoading = true;
    this.clienteForm.disable();
    this.clientesSelectDTO = [];

    this.clienteService.getSelect(this.queryOptionsCliente).subscribe(
      (clientes) => {
        this.clientesSelectDTO = clientes;
        this.appState.isLoading = false;
        this.clienteForm.enable();
      },
      (_) => {
        this.appState.isLoading = false;
        this.clienteForm.enable();
      },
    );
  }
}
