import { NaturezaCaptado } from './../../../base/domain/enum/natureza-captado.enum';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Captado } from 'src/app/base/domain/captado.model';
import { LazyLoadEvent } from 'primeng/api';
import { Posto } from 'src/app/base/domain/posto.model';
import { SituacaoCaptado } from 'src/app/base/domain/enum/situacao-captado.enum';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SituacaoGenerico } from '../../widgets/global-filter/global-filter.component';

export interface CaptadoListFilterOptions {
  nomeCaptado: string;
  nomeCaptador: string;
  situacoesCaptado: string[];
  postoSelecionado: Posto;
  dataInicial: Date;
  dataFinal: Date;
  contratacaoSituacao: string;
  natureza: NaturezaCaptado[];
}

export interface CaptadoAcaoEvent {
  acao: string;
  captado: Captado;
}

@Component({
  selector: 'app-captado-list',
  templateUrl: './captado-list.component.html',
  styleUrls: ['./captado-list.component.css'],
})
export class CaptadoListComponent implements OnInit {
  @Input() title: string;
  @Input() captados: Captado[];
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() loading: boolean;
  @Input() filtros: string[];
  @Input() optionalColumns: string[];
  @Input() view: string;
  @Input() options: string[];
  @Input() menuAcoes: string[];
  @Input() naturezas: SituacaoGenerico[];

  @Output() loadCaptadosPage = new EventEmitter<number>();
  @Output() filter = new EventEmitter<CaptadoListFilterOptions>();
  @Output() acaoClick = new EventEmitter<CaptadoAcaoEvent>();
  @Output() novo = new EventEmitter<void>();
  @Output() selectAll = new EventEmitter<boolean>();

  selecionados: Captado[] = [];
  isAllCaptadoSelected = false;

  private currentPage = 0;
  private readonly defaultFilterOptions: CaptadoListFilterOptions = {
    nomeCaptado: '',
    nomeCaptador: '',
    situacoesCaptado: [],
    postoSelecionado: null,
    dataInicial: null,
    dataFinal: null,
    contratacaoSituacao: 'all',
    natureza: [],
  };
  filterOptions: CaptadoListFilterOptions;

  situacoes: SituacaoCaptado[] = Object.values(SituacaoCaptado);
  contratacaoSituacao = [
    { descricao: 'TODOS AS CAPTAÇÕES', flag: 'all' },
    { descricao: 'CAPTAÇÕES COM CONTRATOS GERADOS', flag: 'true' },
    { descricao: 'CAPTAÇÕES SEM CONTRATOS GERADOS', flag: 'false' },
  ];

  constructor() {
    this.onLimparFiltros();
  }

  ngOnInit(): void {}

  loadCaptados(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadCaptadosPage.emit(pageNumber);
  }

  onAtualizar(): void {
    this.loadCaptadosPage.emit(this.currentPage);
  }

  onLimparFiltros(): void {
    if (this.filterOptions) {
      this.filterOptions.dataInicial = null;
      this.filterOptions.dataFinal = null;
    }
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  onCheckboxChange($event: MatCheckboxChange): void {
    this.selecionados = this.captados.filter((captado: Captado) => captado.selecionado);
    if (this.isAllCaptadoSelected) {
      this.isAllCaptadoSelected = false;
      this.selectAll.emit(false);
    }
  }

  onSelectAll(): void {
    this.captados.forEach((captado: Captado) => (captado.selecionado = true));
    this.selecionados = this.captados.filter((captado: Captado) => captado.selecionado);
    this.isAllCaptadoSelected = true;
    this.selectAll.emit(true);
  }

  onUnselectAll(): void {
    this.captados.forEach((captado: Captado) => (captado.selecionado = false));
    this.selecionados = [];
    this.isAllCaptadoSelected = false;
    this.selectAll.emit(false);
  }

  onPickerEvent(picker: 'inicial' | 'final', event: MatDatepickerInputEvent<Moment>): void {
    if (picker === 'inicial') {
      this.filterOptions.dataInicial = event.value?.toDate() || null;
    }
    if (picker === 'final') {
      this.filterOptions.dataFinal = event.value?.toDate() || null;
    }
  }

  onAcaoClick(acao: string, domain: Captado): void {
    this.acaoClick.emit({ acao, captado: domain });
  }

  onNovo(): void {
    this.novo.emit();
  }
}
