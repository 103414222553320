import { ResourceService } from '../../global/services/resource.service';
import { ContaTev } from '../domain/conta-tev.model';
import { WEB_API } from '../../global/services/web-api';
import { ContaTevSerializer } from '../serializables/conta-tev.serializer';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class ContaTevService extends ResourceService<ContaTev> {
  static RESOURCE = 'conta-tev';

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${ContaTevService.RESOURCE}`, new ContaTevSerializer(ContaTev));
  }
}
