import { Posto } from './posto.model';
import { Funcionario } from './funcionario.model';
import { Visita } from './visita.model';
import { Parcela } from './parcela.model';
import { Resource } from 'src/app/global/domain/resource.model';
import { SituacaoContrato } from './enum/situacao-contrato.enum';
import { AnaliseRenovacaoContrato } from './analise-renovacao-contrato.model';

export class Contrato extends Resource {
  uid: string;
  situacaoContrato: SituacaoContrato;
  naturezaContrato: string; // NaturezaContratoEnum;
  dataEmissao: Date;
  dataLiberacao: Date;
  dataVencimento: Date;
  dataAlteracao: Date;
  dataFinalizacao: Date;
  parecerCancelamento: string;
  motivoDesistencia: string;
  parecerLiquidacao: string;
  valorLiquido: number;
  valorBruto: number;
  valorDivida: number;
  visita: Visita;
  responsavel: Funcionario;
  responsavelRepasse: Funcionario;
  listParcela: Parcela[];
  //  MaquinaCartao maquinaCartao;
  contratoOrigem: bigint;
  periodicidadeParcela: string; // PeriodicidadeEnum
  tipoCobranca: string; // TipoCobrancaEnum
  taxaJuros: number;
  taxaJurosMora: number;
  diasAntecipacao: number;
  antecipacao: boolean;
  //  List<MaquinaCartao> maquinas;
  valorExcedenteAnterior: number;
  adiantamento: boolean;
  valorPagoBordero: number;
  valorContratoBruto: number;
  exportado: boolean;
  cancelar: boolean;
  valorLiquidacao: number;
  posto: Posto;
  saldoDevedor: number;
  analiseRenovacao: AnaliseRenovacaoContrato;
  aprovacaoAnteriorCemPorCento: boolean;
  repasseLiberadoInadimplente: boolean;
  repasseBloqueadoPorInadimplencia: boolean;
  quantidadeParcelasPromocao: number;
}
