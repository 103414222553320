import { Resource } from 'src/app/global/domain/resource.model';
import { Funcionario } from './funcionario.model';
import { Contrato } from './contrato.model';

export class ContratoComissao extends Resource {
  funcionario: Funcionario;

  contrato: Contrato;

  valorComissao: number;

  valorLiquidoContrato: number;

  dataCriacao: Date;
}
