import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { Caixa } from 'src/app/base/domain/caixa.model';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { Transferencia } from 'src/app/base/domain/transferencia.model';
import { CaixaService } from 'src/app/base/services/caixa.service';
import { TransferenciaService } from 'src/app/base/services/transferencia.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { DialogService } from 'src/app/global/services/dialog.service';

@Component({
  selector: 'app-caixa-geral-movimentacoes',
  templateUrl: './caixa-geral-movimentacoes.component.html',
  styleUrls: ['./caixa-geral-movimentacoes.component.css'],
})
export class CaixaGeralMovimentacoesComponent implements OnInit {
  private queryOptions = new QueryOptions({ pageSize: 10 });

  caixaId: number;
  transferencias: Transferencia[];
  tipoMovimentacoes: 'entradas' | 'saidas' = 'entradas';
  totalRecords: number;
  loading = false;
  caixa: Caixa;

  optionalColumns = ['descricao', 'natureza', 'origem', 'destino', 'situacao'];

  constructor(
    private route: ActivatedRoute,
    private transferenciaService: TransferenciaService,
    public dialog: MatDialog,
    public dialogService: DialogService,
    private caixaService: CaixaService,
  ) {
    this.route.params.subscribe((params: Params) => {
      this.caixaId = params.id;
    });
  }

  ngOnInit(): void {
    this.listarTransferencias();
    this.caixaService.findById(this.caixaId).subscribe((response: ReturnAPI<Caixa>) => {
      this.caixa = response.object;
    });
  }

  onLoadTransferencias(event: number): void {
    this.listarTransferencias(event);
  }

  listarTransferencias(page: number = 0): void {
    this.queryOptions.pageNumber = page + 1;
    this.loading = true;

    if (this.tipoMovimentacoes === 'entradas') {
      this.transferenciaService
        .pageTransferenciaByDestinoId(this.queryOptions, this.caixaId)
        .subscribe((response: ReturnAPI<Page<Transferencia>>) => {
          this.totalRecords = response.object.totalElements;
          this.transferencias = response.object.content;
          this.loading = false;
        });
    } else {
      this.transferenciaService
        .pageTransferenciaByOrigemId(this.queryOptions, this.caixaId)
        .subscribe((response: ReturnAPI<Page<Transferencia>>) => {
          this.totalRecords = response.object.totalElements;
          this.transferencias = response.object.content;
          this.loading = false;
        });
    }
  }

  onChangeTabView(id: number): void {
    this.tipoMovimentacoes = id === 0 ? 'entradas' : 'saidas';
    this.listarTransferencias();
  }
}
