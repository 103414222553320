import { ContaTev } from '../../domain/conta-tev.model';
import { QueryOptions } from '../../../global/domain/query.options';
import { ContaTevService } from '../../services/conta-tev.service';
import { DialogService } from '../../../global/services/dialog.service';
import { AppState } from '../../../App.state';
import { Page } from '../../domain/return-api.model';
import { Component } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { AcaoEvent } from 'src/app/global/lists/conta-tev-list/conta-tev-list.component';
import {
    ContaTevFormComponent
} from '../../../global/forms/conta-tev-form/conta-tev-form.component';


@Component({
  selector: 'app-conta-tev',
  templateUrl: './conta-tev.component.html',
  styleUrls: ['./conta-tev.component.css']
})
export class ContaTevComponent {

  private static DELETE_ACTION = 'Deletar Usuário';
  private static EDITAR_ACTION = 'Editar Usuário';

  contas: ContaTev[];
  totalRecords = 0;

  queryOptions = new QueryOptions({ pageSize: 10, pageSort: '-id' });

  private postoSelecionado: number = null;

  constructor(private service: ContaTevService, public dialog: MatDialog, 
    public appState: AppState, public dialogService: DialogService) {}

  mapearAcoes(contas: ContaTev[]): void {
    const mapearAcoes = (contaTev: ContaTev): ContaTev => {
      const acoes = [];
      acoes.push(ContaTevComponent.EDITAR_ACTION);
      return { ...contaTev, acoes };
    };
    this.contas = contas?.map(mapearAcoes) ?? [];
  }

  list(page: number = 0): void {
    this.queryOptions.pageNumber = page;
    this.appState.isLoading = true;
    this.service.listPageable(this.queryOptions).subscribe(
      (response: Page<ContaTev>) => {
        if (response) {
          this.mapearAcoes(response.content);
          this.totalRecords = response.totalElements;
        }
        this.appState.isLoading = false;
      },
      () => (this.appState.isLoading = false),
    );
  }

  onLoad(pageNumber: number): void {
    this.list(pageNumber);
  }

  onDelete(conta: ContaTev): void {
    this.service.delete(conta).subscribe(
      () => {
        this.appState.isLoading = false;
        this.dialogService.feedbackSuccess('Operação realizada com sucesso!');
        this.list();
      },
      // tslint:disable-next-line: typedef
      (error) => {
        this.appState.isLoading = false;
        console.error(error);
        this.dialogService.feedbackError('Ocorreu um erro ao deletar conta!');
        this.list();
      },
    );
  }

  onAcaoClick(event: AcaoEvent): void {
    const actions = new Map<string, (conta: ContaTev) => void>()
      .set(ContaTevComponent.DELETE_ACTION, (conta: ContaTev) => {
        this.dialogService.confirmDialog('Deseja realmente deletar essa conta?', () => this.onDelete(conta));
      })
      .set(ContaTevComponent.EDITAR_ACTION, (conta: ContaTev) => {
        this.onEdit(conta);
      });

    actions.get(event.acao)(event.contaTev);
  }

  onNovo(): void {
    this.dialog
      .open(ContaTevFormComponent, {
        data: {
          action: 'novo',
          usuario: null,
        },
        width: '300px',
      })
      .afterClosed()
      .subscribe((result: ContaTev) => {
        if (result) {
          this.service.create(result).subscribe(
            () => {
              this.appState.isLoading = false;
              this.dialogService.feedbackSuccess('Operação realizada com sucesso!');
              this.list();
            },
            // tslint:disable-next-line: typedef
            (error) => {
              this.appState.isLoading = false;
              console.error(error);
              this.dialogService.feedbackError('Ocorreu um erro ao criar conta!');
              this.list();
            },
          );
        }
      });
  }

  onEdit(conta: ContaTev): void {
    this.dialog
      .open(ContaTevFormComponent, {
        data: {
          action: 'update',
          conta: conta,
        },
        width: '300px',
      })
      .afterClosed()
      .subscribe((result: ContaTev) => {
        if (result) {
          this.service.update(result).subscribe(
            () => {
              this.appState.isLoading = false;
              this.dialogService.feedbackSuccess('Operação realizada com sucesso!');
              this.list();
            },
            // tslint:disable-next-line: typedef
            (error) => {
              this.appState.isLoading = false;
              console.error(error);
              this.dialogService.feedbackError('Ocorreu um erro ao atualizar conta!');
              this.list();
            },
          );
        }
      });
  }

}
