import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Posto } from '../../../base/domain/posto.model';
import { ReagendamentoAcaoEvent, ReagendamentoListFilterOptions } from '../reagendamento-list/reagendamento-list.component';
import { Reagendamento } from '../../../base/domain/reagendamento.model';
import { LazyLoadEvent } from 'primeng/api';
import { QueryOptions } from '../../domain/query.options';
import { AuditoriaAlterarParcela } from '../../../base/domain/AuditoriaAlterarParcela.model';
import { AuditoriaAlterarParcelaService } from '../../../base/services/auditoria-alterar-parcela.service';

export interface AlterarParcelaListFilterOptions {
  postoSelecionado: Posto;
  funcionarioSelecionado: string;
  clienteSelecionado: string;
  dataInicial: Date;
  dataFinal: Date;
}
export type AuditoriaAlterarParcelaAcao = Reagendamento & { acoes: string[] };
@Component({
  selector: 'app-alterar-parcela-list',
  templateUrl: './alterar-parcela-list.component.html',
  styleUrls: ['./alterar-parcela-list.component.css'],
})
export class AlterarParcelaListComponent implements OnInit {
  opcaoBusca = '';
  opcoes = ['Responsável', 'Cliente'];

  @Input() title: string;
  @Input() auditoriaAlterarParcela: AuditoriaAlterarParcela[];
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() loading: boolean;
  @Input() filtros: string[];

  @Output() loadAuditoriaAlterarParcelaPage = new EventEmitter<number>();
  @Output() filter = new EventEmitter<AlterarParcelaListFilterOptions>();
  // @Output() acaoClick = new EventEmitter<AlterarParcelaAcaoEvent>();

  private currentPage = 0;
  queryOptions = new QueryOptions();

  private readonly defaultFilterOptions: AlterarParcelaListFilterOptions = {
    clienteSelecionado: null,
    funcionarioSelecionado: null,
    postoSelecionado: null,
    dataInicial: null,
    dataFinal: null,
  };

  filterOptions: AlterarParcelaListFilterOptions;

  constructor() {}

  ngOnInit(): void {
    this.onLimparFiltros();
  }
  loadReagendamento(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadAuditoriaAlterarParcelaPage.emit(pageNumber);
  }

  onAcaoClick(acao: string, domain: Reagendamento): void {
    //   this.acaoClick.emit({ acao, reagendamento: domain });
  }

  onAtualizar(): void {
    this.loadAuditoriaAlterarParcelaPage.emit(this.currentPage);
  }

  onLimparFiltros(): void {
    this.opcaoBusca = '';
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  opcaoBuscaSelecionado(value: string): void {
    this.onLimparFiltros();
    this.opcaoBusca = value;
  }
}
