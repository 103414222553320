export enum TipoCaixa {
  CAIXA_GERAL = 'CAIXA_GERAL',
  CAIXA_REPASSE = 'CAIXA_REPASSE',
  CAIXA_PROVISAO = 'CAIXA_PROVISAO',
  CAIXA_COBRANCA = 'CAIXA_COBRANCA',
  CAIXA_CONTROLE = 'CAIXA_CONTROLE',
  CAIXA_SANGRIA = 'CAIXA_SANGRIA',
  CAIXA_FUNCIONARIO = 'CAIXA_FUNCIONARIO',
  CAIXA_DIVIDA_FUNCIONARIO = 'CAIXA_DIVIDA_FUNCIONARIO',
  CAIXA_DESPESA_FUNCIONARIO = 'CAIXA_DESPESA_FUNCIONARIO',
  CAIXA_FOLHA_FUNCIONARIO = 'CAIXA_FOLHA_FUNCIONARIO',
  CAIXA_DESPESA = 'CAIXA_DESPESA',
}
