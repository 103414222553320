import { Component, OnInit } from '@angular/core';
import { SharedService } from './auth/shared.service';
import { AppState } from './App.state';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-my-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  public shared: SharedService;

  showTemplate = false;
  loading = false;

  constructor(private router: Router) {
    this.shared = SharedService.getInstance();
    if (this.shared.isLoggedIn()) {
      this.showTemplate = true;
    }
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd && val.url === '/') {
        router.navigate(['/base/gestao/faturamento/acompanhamento-faturamento']);
      }
    });
  }

  ngOnInit(): void {
    this.shared.showTemplate.subscribe((show: boolean) => (this.showTemplate = show));
    AppState.getInstance().showLoading.subscribe((show: boolean) => {
      this.loading = show;
    });
  }
}
