import { QueryOptions } from './../../global/domain/query.options';
import { Injectable } from '@angular/core';
import { Equipe } from '../domain/equipe.model';
import { ResourceService } from 'src/app/global/services/resource.service';
import { HttpClient } from '@angular/common/http';
import { WEB_API } from 'src/app/global/services/web-api';
import { EquipeSerializer } from '../serializables/equipe.serializer';
import { Observable } from 'rxjs';
import { ReturnAPI, Page } from '../domain/return-api.model';
import { Produto } from '../domain/produto.model';

@Injectable()
export class EquipeService extends ResourceService<Equipe> {
  static RESOURCE = 'equipe';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, 'equipe', new EquipeSerializer(Equipe));
  }

  deletar(id: number): Observable<ReturnAPI<void>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<void>>(
      `${WEB_API}/${EquipeService.RESOURCE}/deletar-equipe?equipeId=${id}&${this.produtoId}`,
      null,
    );
  }

  findById(id: number): Observable<ReturnAPI<Equipe>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Equipe>>(`${WEB_API}/${EquipeService.RESOURCE}/findOne?equipeId=${id}&${this.produtoId}`);
  }

  findAll(queryOption: QueryOptions): Observable<ReturnAPI<Page<Equipe>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Equipe>>>(
      `${WEB_API}/${EquipeService.RESOURCE}?${queryOption.toQueryString()}&${this.produtoId}`,
    );
  }

  buscarPorPosto(id: number): Observable<ReturnAPI<Equipe[]>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Equipe[]>>(`${WEB_API}/${EquipeService.RESOURCE}/buscar-por-posto?postoId=${id}&${this.produtoId}`);
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }

  buscarPorFiscal(queryOption: QueryOptions): Observable<ReturnAPI<Equipe>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Equipe>>(
      `${WEB_API}/${EquipeService.RESOURCE}/buscar-por-fiscal?${queryOption.toQueryString(false)}&${this.produtoId}`
    );
  }

}
