import { Page } from '../../domain/return-api.model';
import { QueryOptions } from '../../../global/domain/query.options';
import { ConciliacaoBancariaDoc } from '../../domain/conciliacao-bancaria-doc.model';
import { AppState } from '../../../App.state';
import { DialogService } from '../../../global/services/dialog.service';
import {
  ConciliacaoBancariaDocEvent,
  VisualizarConciliacaoBancariaDocFilterOptions,
} from '../../../global/lists/visualizar-conciliacao-doc-list/visualizar-conciliacao-doc-list.component';
import { ConciliacaoBancariaDocService } from '../../services/conciliacao-bancaria-doc.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConciliacaoFormComponent } from 'src/app/global/forms/conciliacao-form/conciliacao-form.component';
import { ConciliacaoDTO } from '../../domain/dto/conciliacao.dto';
import { VincularConciliacaoDocComponent } from 'src/app/global/forms/vincular-conciliacao-doc/vincular-conciliacao-doc.component';

@Component({
  selector: 'app-conciliacao-doc',
  templateUrl: './conciliacao-doc.component.html',
  styleUrls: ['./conciliacao-doc.component.css'],
})
export class ConciliacaoDocComponent implements OnInit {
  private static DELETE_ACTION = 'Deletar';
  private static EDITAR_ACTION = 'Editar';
  private static VINCULAR_ACTION = 'Vincular Parcela';

  queryOptions = new QueryOptions({ pageSize: 25 });

  page: Page<ConciliacaoBancariaDoc> = new Page();
  totalRecords: number = 0;
  valorTotal: number = 0;

  loading: boolean = false;

  constructor(
    private service: ConciliacaoBancariaDocService,
    public appState: AppState,
    private dialog: MatDialog,
    private dialogService: DialogService,
  ) {
    const date = new Date();
    date.setHours(0, 0, 0);
    this.queryOptions.params = {
      dataInicio: date.getTime(),
      dataFim: new Date().getTime(),
    };
  }

  ngOnInit(): void { }

  list(pageNumber: number = 0): void {
    this.loading = true;
    this.appState.isLoading = true;
    this.queryOptions.pageNumber = pageNumber;

    const dataInicio: Date = new Date(this.queryOptions.params.dataInicio as number);
    dataInicio.setHours(0, 0, 0);
    const dataFim = new Date(this.queryOptions.params.dataFim as number);
    dataFim.setHours(23, 59, 59);

    const estadoId = this.queryOptions.params.estadoId as number;
    const postoUID = this.queryOptions.params.postoUID as string;
    const processado = this.queryOptions.params.processado as boolean;
    const rotaId = this.queryOptions.params.rotaId as number;

    this.queryOptions.params = {
      dataInicio: dataInicio.getTime(),
      dataFim: dataFim.getTime(),
      estadoId,
      postoUID,
      processado,
      rotaId,
    };

    this.service.findAllPageable(this.queryOptions).subscribe((response: Page<ConciliacaoBancariaDoc>) => {
      this.loading = false;
      this.appState.isLoading = false;

      this.totalRecords = response.totalElements;
      this.service.getValorTotal(this.queryOptions).subscribe((resp: number) => {
        this.valorTotal = resp;
      });

      response.content = this.mapearAcoes(response.content);
      this.page = response;
    });
  }

  mapearAcoes(arquivos: ConciliacaoBancariaDoc[]): ConciliacaoBancariaDoc[] {
    const mapearAcoes = (arquivo: ConciliacaoBancariaDoc): ConciliacaoBancariaDoc => {
      const acoes = [];
      acoes.push(ConciliacaoDocComponent.DELETE_ACTION);
      acoes.push(ConciliacaoDocComponent.EDITAR_ACTION);
      acoes.push(ConciliacaoDocComponent.VINCULAR_ACTION);
      return { ...arquivo, acoes };
    };

    return arquivos?.map(mapearAcoes) ?? [];
  }

  onFilter(options: VisualizarConciliacaoBancariaDocFilterOptions): void {
    const date = new Date();
    date.setHours(0, 0, 0, 0);

    this.queryOptions.params = {
      dataInicio: options.dataInicial ? options.dataInicial.getTime() : date.getTime(),
      dataFim: options.dataFinal ? options.dataFinal.getTime() : date.getTime(),
      estadoId: options.estadoId,
      postoUID: options.postoUID,
      rotaId: options.rotaId,
    };

    this.list();
  }

  async onAcaoClick(event: ConciliacaoBancariaDocEvent) {
    const actions = new Map<string, (conciliacao: ConciliacaoBancariaDoc) => void>()
      .set(ConciliacaoDocComponent.DELETE_ACTION, (conciliacao: ConciliacaoBancariaDoc) => {
        this.dialogService.confirmDialog('Deseja realmente deletar esse registro?', () => this.onDelete(conciliacao));
      })
      .set(ConciliacaoDocComponent.EDITAR_ACTION, (conciliacao: ConciliacaoBancariaDoc) => {
        this.onEdit(conciliacao);
      })
      .set(ConciliacaoDocComponent.VINCULAR_ACTION, (conciliacao: ConciliacaoBancariaDoc) => {
        this.onVincular(conciliacao);
      });

    actions.get(event.acao)(event.conciliacao);
  }

  async realizarAssociacao(conciliacaoBancariaDoc: ConciliacaoBancariaDocEvent): Promise<void> { }

  onMenuAcaoClick(event: string) { }

  onNovo(): void {
    this.dialog
      .open(ConciliacaoFormComponent, {
        data: {
          action: 'novo',
          usuario: null,
        },
        width: '300px',
      })
      .afterClosed()
      .subscribe((result: ConciliacaoDTO) => {
        if (result) {
          let doc = new ConciliacaoBancariaDoc();
          doc.data = result.data;
          doc.agencia = result.agencia;
          doc.name = result.name;
          doc.tipoConciliacaoBancaria = result.tipoConciliacaoBancaria;
          doc.banco = result.banco;
          doc.valor = result.valor;
          doc.identificador = result.identificador;

          this.service.create(doc).subscribe(
            () => {
              this.appState.isLoading = false;
              this.dialogService.feedbackSuccess('Operação realizada com sucesso!');
              this.list();
            },
            // tslint:disable-next-line: typedef
            (error) => {
              this.appState.isLoading = false;
              console.error(error);
              this.dialogService.feedbackError('Ocorreu um erro ao criar registro!');
              this.list();
            },
          );
        }
      });
  }

  onEdit(conciliacao: ConciliacaoBancariaDoc): void {
    this.dialog
      .open(ConciliacaoFormComponent, {
        data: {
          action: 'update',
          type: 'Doc',
          conciliacao: ConciliacaoBancariaDoc.toConciliacaoDTO(conciliacao),
        },
        width: '300px',
      })
      .afterClosed()
      .subscribe((result: ConciliacaoDTO) => {
        if (result) {
          let doc = ConciliacaoDTO.toConciliacaoDoc(result);

          this.service.create(doc).subscribe(
            () => {
              this.appState.isLoading = false;
              this.dialogService.feedbackSuccess('Operação realizada com sucesso!');
              this.list();
            },
            // tslint:disable-next-line: typedef
            (error) => {
              this.appState.isLoading = false;
              console.error(error);
              this.dialogService.feedbackError('Ocorreu um erro ao atualizar registro!');
              this.list();
            },
          );
        }
      });
  }

  onDelete(conciliacao: ConciliacaoBancariaDoc): void {
    this.service.delete(conciliacao).subscribe((response) => {
      this.dialogService.feedbackInfo('Registro apagado.'), this.list();
      (error: HttpErrorResponse) => {
        this.dialogService.feedbackError('Ocorreu um erro ao apagar o registro: ' + error.message);
      };
    });
  }

  onVincular(conciliacao: ConciliacaoBancariaDoc): void {
    this.dialog
      .open(VincularConciliacaoDocComponent, {
        data: {
          conciliacao: ConciliacaoBancariaDoc.toConciliacaoDTO(conciliacao),
        },
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result) {
          this.service.vincular(result).subscribe(
            () => {
              this.appState.isLoading = false;
              this.dialogService.feedbackSuccess('Operação realizada com sucesso!');
              this.list();
            },
            // tslint:disable-next-line: typedef
            (error) => {
              this.appState.isLoading = false;
              this.dialogService.feedbackError('Ocorreu um erro ao vincular registro!');
              this.list();
            },
          );
        }
      });
  }

  onLoadArquivosPage(pageNumber: number): void {
    this.list(pageNumber);
  }
}
