import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaixaDespesaFuncionarioMainComponent } from './caixa-despesa-funcionario-main/caixa-despesa-funcionario-main.component';
import { CaixaDespesaFuncionarioMovimentacoesComponent } from './caixa-despesa-funcionario-movimentacoes/caixa-despesa-funcionario-movimentacoes.component';
import { GlobalModule } from 'src/app/global/global.module';

@NgModule({
  declarations: [CaixaDespesaFuncionarioMainComponent, CaixaDespesaFuncionarioMovimentacoesComponent],
  imports: [CommonModule, GlobalModule],
})
export class CaixaDespesaFuncionarioModule {}
