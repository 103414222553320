import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Contrato } from 'src/app/base/domain/contrato.model';
import { ValoresContratoRequestDTO } from 'src/app/base/domain/dto/valores-contrato-requesto.dto';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { ContratoService } from 'src/app/base/services/contrato.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ContratoAcaoEvent, ContratoListFilterOptions } from 'src/app/global/lists/contrato-list/contrato-list.component';
import { DialogService } from 'src/app/global/services/dialog.service';

@Component({
  selector: 'app-acompanhamento-repasses-periodo-main',
  templateUrl: './acompanhamento-repasses-periodo-main.component.html',
  styleUrls: ['./acompanhamento-repasses-periodo-main.component.css'],
})
export class AcompanhamentoRepassesPeriodoMainComponent implements OnInit {
  contratos: Contrato[] = [];

  queryOptions = new QueryOptions({ pageSize: 25 });
  totalRecords = 0;
  loading = false;
  totalizadorBruto: number;
  totalizadorLiquido: number;
  private postoSelecionado: number = null;
  dataAtual = new Date();

  constructor(
    private router: Router,
    private contratoService: ContratoService,
    private dialogService: DialogService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    const date = new Date();
    this.queryOptions.params = {
      dataInicialLiberacao: date.getTime(),
      dataFinalLiberacao: date.getTime(),
      situacao: `in:LIBERADO,EM_ANDAMENTO`,
    };
  }

  listarContratos(pageNumber: number = 0, valoresContrato: Partial<ValoresContratoRequestDTO> = {}): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.dataAtual.setHours(0, 0, 0, 0);
    this.contratoService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Contrato>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.mapearAcoes(response.object.content);
      } else {
        this.contratos = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
      valoresContrato.dataInicialLiberacao = valoresContrato.dataInicialLiberacao ? valoresContrato.dataInicialLiberacao : this.dataAtual;
      valoresContrato.dataFinalLiberacao = valoresContrato.dataFinalLiberacao ? valoresContrato.dataFinalLiberacao : this.dataAtual;
      valoresContrato.nome = valoresContrato.nome ? valoresContrato.nome : '';
      valoresContrato.descricaoRota = valoresContrato.descricaoRota ? valoresContrato.descricaoRota : '';
      this.getValorTotalBruto({ ...valoresContrato, postoId: this.postoSelecionado });
      this.getValorTotalLiquido({ ...valoresContrato, postoId: this.postoSelecionado });
    });
  }

  mapearAcoes(contratos: Contrato[]): void {
    const mapearAcoes = (contrato: Contrato): Contrato => {
      const acoes = [];

      return { ...contrato, acoes };
    };

    this.contratos = contratos?.map(mapearAcoes) ?? [];
  }

  onAcaoClick(event: ContratoAcaoEvent): void {
    const actions = new Map<string, (contrato: Contrato) => void>();
    actions.get(event.acao)(event.contrato);
  }

  onDisclosure(contrato: Contrato): void {
    this.router.navigate(['base/contratos/acompanhamento-contrato-detail/' + contrato.id]);
  }

  onContratosPage(page: number): void {
    this.listarContratos(page);
  }

  onFilter(options: ContratoListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    this.queryOptions.params = {
      nomeCliente: options.nomeCliente,
      postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
      dataInicialLiberacao: options.dataInicial ? options.dataInicial.getTime() : date.getTime(),
      dataFinalLiberacao: options.dataFinal ? options.dataFinal.getTime() : null,
      situacaoContrato: `in:LIBERADO,EM_ANDAMENTO`,
      descricaoRota: options.descricaoRota,
    };

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }

    this.listarContratos(0, {
      nome: options.nomeCliente,
      descricaoRota: options.descricaoRota,
      situacoesContrato: options.situacoesContrato,
      dataInicialLiberacao: options?.dataInicial ? options.dataInicial : date,
      dataFinalLiberacao: options?.dataFinal ? options.dataFinal : null,
    });
  }

  getValorTotalBruto(valoresContrato: Partial<ValoresContratoRequestDTO>): void {
    this.contratoService.getValorTotalBrutoPorDataLiberacao(valoresContrato).subscribe((response: ReturnAPI<number>) => {
      this.totalizadorBruto = response.object || 0;
    });
  }

  getValorTotalLiquido(valoresContrato: Partial<ValoresContratoRequestDTO>): void {
    this.contratoService.getValorTotalLiquidoPorDataLiberacao(valoresContrato).subscribe((response: ReturnAPI<number>) => {
      this.totalizadorLiquido = response.object || 0;
    });
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
