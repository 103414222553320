import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LazyLoadEvent } from 'primeng';
import { ConciliacaoBancariaTed } from 'src/app/base/domain/conciliacao-bancaria-ted.model';
import { EstadoSelectDTO } from 'src/app/base/domain/dto/estado-select.dto';
import { PostoSelectDTO } from 'src/app/base/domain/dto/posto-select.dto';
import { Page } from 'src/app/base/domain/return-api.model';
import { Rota } from 'src/app/base/domain/rota.model';
import { EstadoService } from 'src/app/base/services/estado.service';
import { PostoService } from 'src/app/base/services/posto.service';
import { RotaService } from 'src/app/base/services/rota.service';
import { QueryOptions } from '../../domain/query.options';

export interface VisualizarConciliacaoBancariaTedcFilterOptions {
  dataInicial: Date;

  dataFinal: Date;

  estadoId: number;

  postoUID: string;

  processado: boolean;

  rotaId: number;
}

export interface ConciliacaoBancariaTedEvent {
  acao: string;
  conciliacao: ConciliacaoBancariaTed;
}

@Component({
  selector: 'app-visualizar-conciliacao-ted-list',
  templateUrl: './visualizar-conciliacao-ted-list.component.html',
  styleUrls: ['./visualizar-conciliacao-ted-list.component.css'],
})
export class VisualizarConciliacaoTedListComponent implements OnInit {
  @Output() filter = new EventEmitter<VisualizarConciliacaoBancariaTedcFilterOptions>();
  @Output() loadArquivosPage = new EventEmitter<number>();
  @Output() acaoClick = new EventEmitter<ConciliacaoBancariaTedEvent>();
  @Output() menuAcaoClick = new EventEmitter<string>();
  @Output() novo = new EventEmitter<void>();

  public static CORRIGIR_PAGAMENTO_DIA = 'Corrigir pagamento por dia';
  menuAcoes: string[] = ['Corrigir pagamento por dia'];

  filterOptions: VisualizarConciliacaoBancariaTedcFilterOptions;
  private readonly defaultFilterOptions: VisualizarConciliacaoBancariaTedcFilterOptions = {
    dataInicial: null,
    dataFinal: null,
    estadoId: null,
    postoUID: null,
    processado: false,
    rotaId: null,
  };

  @Input() page: Page<ConciliacaoBancariaTed>;
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() loading: boolean;
  @Input() valorTotal: number;

  private currentPage = 0;
  queryOptions = new QueryOptions();

  public estados: EstadoSelectDTO[];
  public postos: PostoSelectDTO[];
  public rotas: Rota[];
  estadoId: number;

  constructor(private estadoService: EstadoService, private postoService: PostoService, private rotaService: RotaService) {}

  ngOnInit(): void {
    this.onLimparFiltros();
  }

  loadArquivos(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber - 1;
    this.loadArquivosPage.emit(pageNumber);
  }

  onAtualizar(): void {
    this.loadArquivosPage.emit(this.currentPage);
  }

  onMenuAcaoClick(event: string): void {
    this.menuAcaoClick.emit(event);
  }

  onAcaoClick(acao: string, domain: ConciliacaoBancariaTed): void {
    this.acaoClick.emit({ acao, conciliacao: domain });
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  onNovo(): void {
    this.novo.emit();
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
