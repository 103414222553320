import { BooleanGereric, ReportFilterEmit, ReportFiltersComponent } from '../report-filters/report-filters.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ContratoService } from '../../services/contrato.service';
import * as FileSaver from 'file-saver';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { AppState } from 'src/app/App.state';
import { SituacaoContrato } from '../../domain/enum/situacao-contrato.enum';
import { CaixaService } from '../../services/caixa.service';
import { SharedService } from '../../../auth/shared.service';
import { DialogService } from '../../../global/services/dialog.service';
import { Usuario } from '../../domain/usuario.model';
import { AcaoDeVendaService } from '../../services/acao-de-venda.service';
import { TransferenciaService } from '../../services/transferencia.service';
import { VisitaService } from '../../services/visita.service';
import { MessageService } from '../../../global/components/message/message.service';
import { ClienteService } from '../../services/cliente.service';
import { CobrancaJudicialService } from '../../services/cobranca-judicial.service';
import { ContratoComissaoService } from '../../services/contrato-comissao.service';
import { AuditoriaTransferenciaClienteService } from '../../services/auditoria-transferencia-cliente.service';
import { ArquivoConciliacaoBancariaService } from '../../services/arquivo-conciliacao-bancaria.service';
import { CaptadoService } from '../../services/captado.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

interface Report {
  filters: string[];
  id: number;
  descricao: string;
  filtersRequired: boolean;
  dataInicialPlaceholder?: string;
  dataFinalPlaceholder?: string;
  inputMinimoPlaceholder?: string;
  inputMaximoPlaceholder?: string;
  inputNumericoPlaceholder?: string;
  diasAtrasoPlaceholder?: string;
  opcaoGenerico?: string;
  opcao_1?: string;
  opcao_2?: string;
  opcao_3?: string;
  opcaoDescricao?: string;
  opcaoPadraoDescricao?: string;
  opcoes?: BooleanGereric[];
  multipleOpcaoDescricao?: string;
  diaSemana?: number;
}

@Component({
  selector: 'app-report-main',
  templateUrl: './report-main.component.html',
  styleUrls: ['./report-main.component.css'],
})
export class ReportMainComponent implements OnInit {
  @ViewChild(ReportFiltersComponent) filho: ReportFiltersComponent;

  RELATORIOS_ADMIN = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 32, 33, 34, 35, 36, 37, 38,
    39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67,68,69
  ];

  RELATORIOS_GESTAO = [
    1, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 32, 33, 34, 35, 36, 37, 39, 40, 41,
    42, 44, 45, 46, 48, 50, 62,68,69
  ];

  RELATORIOS_DIRETOR = [
    1, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 32, 33, 34, 35, 36, 37, 38,
    39, 40, 41, 42, 50, 62,68,69
  ];

  RELATORIOS_FISCAL = [10, 11, 12, 14, 17, 18, 23, 26, 29, 32, 33];

  RELATORIOS_RETAGUARDA = [7, 10, 11, 12, 13, 14, 17, 18, 22, 23, 24, 26, 29, 32, 33];

  RELATORIOS_PRESTADORES = [3, 6, 26, 43, 62, 63, 64];

  private readonly defaultReport: Report = {
    filters: [],
    id: null,
    descricao: '',
    filtersRequired: null,
    dataInicialPlaceholder: null,
    dataFinalPlaceholder: null,
    inputMinimoPlaceholder: null,
    inputMaximoPlaceholder: null,
    inputNumericoPlaceholder: null,
    diasAtrasoPlaceholder: null,
    opcaoGenerico: null,
    opcao_1: null,
    opcao_2: null,
    opcao_3: null,
    opcaoDescricao: null,
    opcoes: null,
    multipleOpcaoDescricao: null,
    diaSemana: null,
  };

  relatorios: Report[] = [
    {
      id: 1,
      descricao: 'Contratos Diários',
      filters: ['datas', 'dataInicial', 'posto'],
      filtersRequired: true,
    },
    {
      id: 2,
      descricao: 'Relatório de Faturamento',
      filters: ['datas', 'dataInicial', 'dataFinal', 'posto', 'rota', 'opcaoGenerico', 'periodicidade'],
      filtersRequired: true,
      opcaoDescricao: 'Quantidade de Parcelas',
      opcao_1: '10 Parcelas',
      opcao_2: '20 Parcelas',
      opcao_3: '2 parcelas',
      opcaoPadraoDescricao: 'Todas',
    },
    {
      id: 3,
      descricao: 'Relatório de Contratos Cancelados',
      filters: ['nomeCliente', 'datas', 'dataInicial', 'dataFinal', 'posto', 'rota'],
      filtersRequired: false,
    },
    {
      id: 4,
      descricao: 'Relatório de Repasses',
      filters: ['datas', 'dataInicial', 'dataFinal', 'posto', 'rota'],
      filtersRequired: true,
    },
    {
      id: 5,
      descricao: 'Relatório de Cobranças',
      filters: ['datas', 'dataInicial', 'dataFinal', 'posto', 'rota'],
      filtersRequired: false,
    },
    {
      id: 6,
      descricao: 'Relatório de Contratos Finalizados',
      filters: ['datas', 'dataInicial', 'dataFinal', 'posto', 'rota'],
      filtersRequired: true,
      dataInicialPlaceholder: 'Data Inicial',
      dataFinalPlaceholder: 'Data Final',
    },
    {
      id: 7,
      descricao: 'Relatório - Finalizados X Repassados X Não Repassados',
      filters: ['datas', 'dataInicial', 'dataFinal', 'posto'],
      filtersRequired: true,
    },
    {
      id: 8,
      descricao: 'Baixas de despesas diárias',
      filters: ['datas', 'dataInicial', 'posto'],
      filtersRequired: true,
    },
    {
      id: 9,
      descricao: 'Relatório de Faturamento Geral',
      filters: ['datas', 'dataInicial', 'dataFinal'],
      filtersRequired: false,
    },
    {
      id: 10,
      descricao: 'Relatório de Clientes Inativos',
      filters: ['posto', 'rota', 'datas', 'dataInicial', 'dataFinal', 'input'],
      filtersRequired: true,
    },
    {
      id: 11,
      descricao: 'Relatório de Clientes Inadimplentes Irregulares',
      filters: ['datas', 'posto', 'rota', 'divisor', 'opcaoGenerico', 'dataInicial', 'dataFinal'],
      opcaoDescricao: 'Exibir Telefone',
      opcao_1: 'Sim',
      opcao_2: 'Não',
      filtersRequired: true,
    },
    {
      id: 12,
      descricao: 'Relatório de Clientes Inadimplentes Regulares',
      filters: ['datas', 'posto', 'rota', 'divisor', 'opcaoGenerico', 'datas'],
      opcaoDescricao: 'Exibir Telefone',
      opcao_1: 'Sim',
      opcao_2: 'Não',
      filtersRequired: true,
    },
    {
      id: 13,
      descricao: 'Faltas e Sobras',
      filters: ['posto', 'rota', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 14,
      descricao: 'Relatório de Cobranças - Semanal',
      filters: ['posto', 'rota', 'datas', 'dataSemanal'],
      filtersRequired: true,
      diaSemana: 1,
    },
    {
      id: 15,
      descricao: 'Relatório de Médias de Repasses',
      filters: ['posto', 'rota', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 16,
      descricao: 'Relatório Titulos RBM',
      filters: ['posto', 'rota', 'datas', 'dataInicial', 'dataFinal', 'contratosRBM', 'formatoDownload'],
      filtersRequired: true,
    },
    {
      id: 17,
      descricao: 'Relatório de Cobranças no Sábado',
      filters: ['posto', 'rota', 'datas', 'dataSemanal'],
      filtersRequired: false,
      diaSemana: 6,
    },
    {
      id: 18,
      descricao: 'Relatório de Eficiência',
      filters: ['posto', 'rota', 'datas', 'inputNumerico', 'situacaoEficiencia', 'opcaoGenerico'],
      filtersRequired: true,
      inputNumericoPlaceholder: 'Quantidade de parcelas',
      opcao_1: 'Clientes Novos',
      opcao_2: 'Clientes Reativados',
      opcao_3: 'Clientes Renovados',
      opcaoDescricao: 'Situaçao Cliente',
      opcaoPadraoDescricao: 'Todas as Situaçoes',
    },
    {
      id: 19,
      descricao: 'Relatório de Contratos Parados',
      filters: ['posto', 'rota', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 20,
      descricao: 'Relatório de Despesas Funcionários',
      filters: ['posto', 'rota', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 21,
      descricao: 'Relatório de Caixas de Dívida',
      filters: ['posto', 'datas', 'dataInicial'],
      filtersRequired: true,
    },
    {
      id: 22,
      descricao: 'Relatório de Clientes Inadimplentes por Posto',
      filters: ['posto', 'rota', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 23,
      descricao: 'Relatório de Situação de Clientes por Rota',
      filters: ['posto', 'opcaoGenerico', 'rota', 'datas', 'divisor'],
      opcaoDescricao: 'Exibir Telefone',
      opcao_1: 'Sim',
      opcao_2: 'Não',
      filtersRequired: true,
    },
    {
      id: 24,
      descricao: 'Relatório de Cobranças Mensal',
      filters: ['posto', 'rota', 'datas', 'dataInicialMensal', 'dataFinalMensal'],
      filtersRequired: true,
    },
    {
      id: 25,
      descricao: 'Relatório de Despesas Tipo Empresa',
      filters: ['posto', 'rota', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 26,
      descricao: 'Relatório de Clientes Próximos a Liquidação',
      filters: ['posto', 'rota', 'datas', 'inputMinimo'],
      filtersRequired: true,
      inputMinimoPlaceholder: 'Quantidade de Parcelas',
    },
    {
      id: 27,
      descricao: 'Relatório de Repasses - Semanal',
      filters: ['posto', 'rota', 'datas', 'dataSemanal'],
      filtersRequired: true,
      diaSemana: 1,
    },
    {
      id: 28,
      descricao: 'Relatório de Contratos por Cliente',
      filters: ['posto', 'rota', 'datas', 'dataInicial', 'dataFinal', 'inputMinimo', 'inputMaximo', 'opcaoGenerico'],
      filtersRequired: true,
      inputMinimoPlaceholder: 'Saldo devedor Minimo',
      inputMaximoPlaceholder: 'Saldo devedor Máximo',
      opcao_1: 'Pessoa Física',
      opcao_2: 'Pessoa Jurídica',
      opcaoDescricao: 'Tipo de Pessoa',
    },
    {
      id: 29,
      descricao: 'Relatório de Cobrancas Por Turno',
      filters: ['posto', 'rota', 'datas', 'dataSemanal', 'turnos'],
      filtersRequired: true,
      diaSemana: 1,
    },
    {
      id: 30,
      descricao: 'Relatório de Ação de Vendas',
      filters: [
        'posto',
        'datas',
        'dataInicial',
        'dataFinal',
        'situacao-acao-venda',
        'situacao-cliente',
        'situacao-contrato',
        'situacao-visita',
      ],
      filtersRequired: true,
    },
    {
      id: 31,
      descricao: 'Relatório de Transferencias Controle Geral',
      filters: ['estados', 'multipleOpcaoGenerico', 'datas', 'dataInicial', 'dataFinal'],
      opcoes: [
        { id: 0, descricao: 'GERADA' },
        { id: 34, descricao: 'COMPLEMENTO_ENTRE_POSTOS' },
      ],
      multipleOpcaoDescricao: 'Situação',
      filtersRequired: true,
    },
    {
      id: 32,
      descricao: 'Ficha Cadastral',
      filters: ['nomeCliente', 'datas', 'opcaoGenerico'],
      opcao_1: 'Pessoa Física',
      opcao_2: 'Pessoa Jurídica',
      opcaoDescricao: 'Tipo de Pessoa',
      filtersRequired: true,
    },
    {
      id: 33,
      descricao: 'Relatorio de Eficiencia do Cobrador',
      filters: ['posto', 'rota', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 34,
      descricao: 'Captados por Origem',
      filters: ['posto', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 35,
      descricao: 'Relatório Periodo de Recebimentos',
      filters: ['posto', 'rota', 'datas', 'checkSemanal'],
      filtersRequired: true,
    },
    {
      id: 36,
      descricao: 'Relatório de Acompanhamento Operacional',
      filters: [
        'posto',
        'rota',
        'datas',
        'dataInicial',
        'dataFinal',
        'acompanhamento-operacional',
        'opcaoGenerico',
        'inputMinimo',
        'inputMaximo',
      ],
      filtersRequired: true,
      opcao_1: 'Sim',
      opcao_2: 'Não',
      opcaoDescricao: 'Somente Pessoa Fisica?',
      inputMinimoPlaceholder: 'Valor Mínimo',
      inputMaximoPlaceholder: 'Valor Máximo',
    },
    {
      id: 37,
      descricao: 'Despesas Nacional',
      filters: ['posto', 'datas', 'dataInicial', 'dataFinal', 'estados'],
      filtersRequired: true,
    },
    {
      id: 38,
      descricao: 'Relatório Reagendamento de Contratos',
      filters: ['datas', 'dataInicial', 'dataFinal', 'posto'],
      filtersRequired: true,
    },
    {
      id: 39,
      descricao: 'Relatório de Alteração de valor dos contratos',
      filters: ['datas', 'dataInicial', 'dataFinal', 'posto', 'opcaoGenerico'],
      opcao_1: 'ALTERAÇÃO',
      opcao_2: 'LIBERAÇÃO',
      opcaoDescricao: 'Filtrar pela data de:',
      filtersRequired: true,
    },
    {
      id: 40,
      descricao: 'Relatório de Prorrogados',
      filters: ['datas', 'dataInicial', 'dataFinal', 'posto', 'rota'],
      filtersRequired: false,
    },
    {
      id: 41,
      descricao: 'Relatório de Clientes Visitados',
      filters: ['posto', 'fiscal', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: false,
    },
    {
      id: 42,
      descricao: 'Relatório de Comissões',
      filters: ['posto', 'fiscal', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: false,
    },
    {
      id: 43,
      descricao: 'Relatório de Clientes Inativos',
      filters: ['posto', 'rota', 'datas', 'dataInicial', 'dataFinal', 'endereco', 'regularidade', 'input', 'opcaoGenerico', 'inputMinimo',
        'situacaoInativarRenovacaoAutomatica'],
      opcaoDescricao: 'Quantidade de Parcelas',
      opcao_1: '10 Parcelas',
      opcao_2: '20 Parcelas',
      opcaoPadraoDescricao: 'Todas',
      filtersRequired: true,
      inputMinimoPlaceholder: 'Valor Parcela - (0 para todos)',
      diasAtrasoPlaceholder: 'Dias de Atraso - (0 para todos)'
    },
    {
      id: 44,
      descricao: 'Relatório de Clientes em Cobrança Judicial',
      filters: ['posto', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 45,
      descricao: 'Relatório de Pagamentos em Cobrança Judicial',
      filters: ['posto', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 46,
      descricao: 'Relatório de Produtividade',
      filters: ['posto-digital', 'datas', 'dataInicial', 'dataFinal', 'cobrador'],
      filtersRequired: true,
    },
    {
      id: 47,
      descricao: 'Relatório de Transferências de Clientes',
      filters: ['posto', 'datas', 'dataInicial', 'dataFinal', 'rota'],
      filtersRequired: true,
    },
    {
      id: 48,
      descricao: 'Relatório de Assinaturas e Repasses Digitais',
      filters: ['posto', 'rota', 'situacao-contrato', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 49,
      descricao: 'Relatório de Clientes Adicionado ao SPC',
      filters: ['posto', 'rota', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 50,
      descricao: 'Relatorio de Parcelas Alteradas',
      filters: ['posto', 'rota', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 51,
      descricao: 'Relatorio de Cancelamento de Complementos',
      filters: ['posto', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 52,
      descricao: 'Relatorio de Cancelamento de Contratos',
      filters: ['posto', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 53,
      descricao: 'Relatorio de Auditoria de Devedor Duvidoso',
      filters: ['posto', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 54,
      descricao: 'Relatorio de Auditoria de Complemento Entre Postos',
      filters: ['posto', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 55,
      descricao: 'Relatorio Lista de Pagamentos Aprovados Conciliacao',
      filters: ['estados', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 56,
      descricao: 'Relatorio Lista de Pagamentos Não Processados Conciliacao',
      filters: ['estados', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 57,
      descricao: 'Relatorio Lista Geral de Pagamentos Conciliacao',
      filters: ['datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 58,
      descricao: 'Relatório de Contratos Liquidados',
      filters: ['posto', 'rota', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 59,
      descricao: 'Relatório de Cobrança das Repactuações',
      filters: ['posto', 'rota', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 60,
      descricao: 'Relatório de Contratos Repactuados',
      filters: ['posto', 'rota', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 61,
      descricao: 'Relatório de Contratos Gerados a Partir de Repactuações',
      filters: ['posto', 'rota', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },
    {
      id: 62,
      descricao: 'Relatório de Contratos por Período',
      filters: ['posto', 'rota', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },

    {
      id: 63,
      descricao: 'Relatório de Clientes Novos',
      filters: ['posto', 'rota', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },

    {
      id: 64,
      descricao: 'Relatório de Contratos Jurídicos Finalizados ',
      filters: ['posto', 'rota', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },

    {
      id: 65,
      descricao: 'Relatório de Clientes Reativados',
      filters: ['posto', 'rota', 'datas', 'dataInicial', 'dataFinal'],
      filtersRequired: true,
    },

    {
     id: 66,
     descricao: 'Relatório de Contratos Novos, Renovados e Reativados',
     filters: ['datas', 'dataInicial', 'dataFinal'],
     filtersRequired: true,
    },

    {
      id: 67,
      descricao: 'Relatório de Captados',
      filters: ['posto', 'datas', 'dataInicial', 'dataFinal', 'opcaoGenerico'],
      opcaoDescricao: 'Nível de Interesse',
      opcao_1: 'BAIXO',
      opcao_2: 'MEDIO',
      opcao_3: 'ALTO',
      opcaoPadraoDescricao: 'Todas',
      filtersRequired: true,
    },
    {
      id: 68,
      descricao: 'Relatorio Cliente Endereco',
      filters: ['rota', 'nomeCliente', 'posto'],
      filtersRequired: false,
    },
    {
      id: 69,
      descricao: 'Relatorio Contratos novos Repassados ',
      filters: ['rota','posto'],
      filtersRequired: false,
    }
];
  

  nomeUsuario = JSON.parse(sessionStorage.getItem('usuario'));

  loading = false;
  reports: Report[];
  reportSelecionado: Report;
  options: QueryOptions;
  usuario: Usuario;

  constructor(
    private contratoService: ContratoService,
    private captadoService: CaptadoService,
    private caixaService: CaixaService,
    public appState: AppState,
    public dialogService: DialogService,
    private acaoDeVendaService: AcaoDeVendaService,
    private transferenciaService: TransferenciaService,
    private visitaService: VisitaService,
    private message: MessageService,
    private clienteService: ClienteService,
    private cobrancaJudicialService: CobrancaJudicialService,
    private contratoComissaoService: ContratoComissaoService,
    private auditoriaTransferenciaClienteService: AuditoriaTransferenciaClienteService,
    private conciliacaoBancariaService: ArquivoConciliacaoBancariaService,
  ) {
    const shared = SharedService.getInstance();
    this.usuario = shared.usuario;
    this.nomeUsuario = this.usuario.nome;

    this.reports = [];
  }

  ngOnInit(): void {
    if (this.usuario.email === 'luis@gjbl.com.br' || this.usuario.email === 'sandra@gjbl.com.br') {
      this.reports = this.relatorios;
    }

    if (this.usuario.profile === 'ROLE_ADMIN' && this.usuario.email !== 'luis@gjbl.com.br' && this.usuario.email !== 'sandra@gjbl.com.br') {
      const reports = this.relatorios.filter((relatorio: Report) => {
        if (this.RELATORIOS_ADMIN.some((id: number) => relatorio.id === id)) {
          return true;
        }
        return false;
      });
      this.reports = reports;
    }

    if (
      this.usuario.profile === 'ROLE_DIRETOR' &&
      this.usuario.email !== 'luis@gjbl.com.br' &&
      this.usuario.email !== 'sandra@gjbl.com.br'
    ) {
      const reports = this.relatorios.filter((relatorio: Report) => {
        if (this.RELATORIOS_DIRETOR.some((id: number) => relatorio.id === id)) {
          return true;
        }
        return false;
      });
      this.reports = reports;
    }

    if (
      this.usuario.profile === 'ROLE_GESTOR' &&
      this.usuario.email !== 'luis@gjbl.com.br' &&
      this.usuario.email !== 'sandra@gjbl.com.br'
    ) {
      const reports = this.relatorios.filter((relatorio: Report) => {
        if (this.RELATORIOS_GESTAO.some((id: number) => relatorio.id === id)) {
          return true;
        }
        return false;
      });
      this.reports = reports;
    }

    if (
      this.usuario.profile === 'ROLE_RETAGUARDA' &&
      this.usuario.email !== 'luis@gjbl.com.br' &&
      this.usuario.email !== 'sandra@gjbl.com.br'
    ) {
      const reports = this.relatorios.filter((relatorio: Report) => {
        if (this.RELATORIOS_RETAGUARDA.some((id: number) => relatorio.id === id)) {
          return true;
        }
        return false;
      });
      this.reports = reports;
    }

    if (
      this.usuario.profile === 'ROLE_FISCAL' &&
      this.usuario.email !== 'luis@gjbl.com.br' &&
      this.usuario.email !== 'sandra@gjbl.com.br'
    ) {
      const reports = this.relatorios.filter((relatorio: Report) => {
        if (this.RELATORIOS_FISCAL.some((id: number) => id === relatorio.id)) {
          return true;
        }
        return false;
      });
      this.reports = reports;
    }

    if (
      this.usuario.profile === 'ROLE_PRESTADOR' &&
      this.usuario.email !== 'luis@gjbl.com.br' &&
      this.usuario.email !== 'sandra@gjbl.com.br'
    ) {
      const reports = this.relatorios.filter((relatorio: Report) => {
        if (this.RELATORIOS_PRESTADORES.some((id: number) => id === relatorio.id)) {
          return true;
        }
        return false;
      });
      this.reports = reports;
    }
  }

  eventEmitter(evento: ReportFilterEmit): void {
    const actions = new Map<number, (reportFilterEmit: ReportFilterEmit) => void>()
      .set(1, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioRepassesDiario(reportFilterEmit);
      })
      .set(2, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDeFaturamento(reportFilterEmit);
      })
      .set(3, (reportFilterEmit: ReportFilterEmit) => {
        this.gerarRelatorioContratoCancelado(reportFilterEmit);
      })
      .set(4, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDeContratos(reportFilterEmit);
      })
      .set(5, (reportFilterEmit: ReportFilterEmit) => {
        this.gerarRelatorioDeCobrancas(reportFilterEmit);
      })
      .set(6, (reportFilterEmit: ReportFilterEmit) => {
        this.gerarRelatorioDeContratosFinalizados(reportFilterEmit);
      })
      .set(7, (reportFilterEmit: ReportFilterEmit) => {
        this.gerarRelatorioSituacaoContrato(reportFilterEmit);
      })
      .set(8, (reportFilterEmit: ReportFilterEmit) => {
        this.despesasDiarias(reportFilterEmit);
      })
      .set(9, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDeFaturamentoGeral(reportFilterEmit);
      })
      .set(10, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDeClientesInativos(reportFilterEmit);
      })
      .set(11, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDeClientesInadimplemtesIregulares(reportFilterEmit);
      })
      .set(12, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDeClientesInadimplentesRegulares(reportFilterEmit);
      })
      .set(13, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDeFaltasESobras(reportFilterEmit);
      })
      .set(14, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDeCobrabcaSemanal(reportFilterEmit);
      })
      .set(15, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDeMediaDeRpasses(reportFilterEmit);
      })
      .set(16, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioContratosRBM(reportFilterEmit);
      })
      .set(17, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDeCobrancasSabado(reportFilterEmit);
      })
      .set(18, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioEficiencia(reportFilterEmit);
      })
      .set(19, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDeContratoParado(reportFilterEmit);
      })
      .set(20, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDespesasFuncionarios(reportFilterEmit);
      })
      .set(21, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioCaixaDivida(reportFilterEmit);
      })
      .set(22, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioClientesInadimplentesPorPosto(reportFilterEmit);
      })
      .set(23, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioClientesInadimplentesPorRota(reportFilterEmit);
      })
      .set(24, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioCobrancaMensal(reportFilterEmit);
      })
      .set(25, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDespesasTipoEmpresa(reportFilterEmit);
      })
      .set(26, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioProximosLiquidacao(reportFilterEmit);
      })
      .set(27, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioRepassesSemanal(reportFilterEmit);
      })
      .set(28, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioContratosPorCliente(reportFilterEmit);
      })
      .set(29, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioCobrancasTurno(reportFilterEmit);
      })
      .set(30, (reportFilterEmit: ReportFilterEmit) => {
        this.gerarRelatorioDeAcaoDeVendas(reportFilterEmit);
      })
      .set(31, (reportFilterEmit: ReportFilterEmit) => {
        this.gerarRelatorioControleGeral(reportFilterEmit);
      })
      .set(32, (reportFilterEmit: ReportFilterEmit) => {
        this.gerarFichaCadastral(reportFilterEmit);
      })
      .set(33, (reportFilterEmit: ReportFilterEmit) => {
        this.gerarRelatorioEficienciaCobrador(reportFilterEmit);
      })
      .set(34, (reportFilterEmit: ReportFilterEmit) => {
        this.gerarRelatorioOrigemCaptacao(reportFilterEmit);
      })
      .set(35, (reportFilterEmit: ReportFilterEmit) => {
        this.gerarRelatorioPeriodoRecebimentos(reportFilterEmit);
      })
      .set(36, (reportFilterEmit: ReportFilterEmit) => {
        this.gerarRelatorioAcompanhamentoOperacional(reportFilterEmit);
      })
      .set(37, (reportFilterEmit: ReportFilterEmit) => {
        this.gerarRelatorioDespesasNacional(reportFilterEmit);
      })
      .set(38, (reportFilterEmit: ReportFilterEmit) => {
        this.gerarRelatorioReagendamentoContrato(reportFilterEmit);
      })
      .set(39, (reportFilterEmit: ReportFilterEmit) => {
        this.gerarRelatorioAlteracaoValorContratos(reportFilterEmit);
      })
      .set(40, (reportFilterEmit: ReportFilterEmit) => {
        this.gerarRelatorioContratoProrrogados(reportFilterEmit);
      })
      .set(41, (reportFilterEmit: ReportFilterEmit) => {
        this.gerarRelatorioClientesVisitados(reportFilterEmit);
      })
      .set(42, (reportFilterEmit: ReportFilterEmit) => {
        this.gerarRelatorioComissao(reportFilterEmit);
      })
      .set(43, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDeClientesInativos(reportFilterEmit);
      })
      .set(44, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDeClientesCobrancaJudicial(reportFilterEmit);
      })
      .set(45, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDePagamentosCobrancaJudicial(reportFilterEmit);
      })
      .set(46, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDeProdutividade(reportFilterEmit);
      })
      .set(47, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDeTransferenciaDeCliente(reportFilterEmit);
      })
      .set(48, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioAssinaturasRepassesDigitais(reportFilterEmit);
      })
      .set(49, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDeClientesSpc(reportFilterEmit);
      })
      .set(50, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioParcelasAlteradas(reportFilterEmit);
      })
      .set(51, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioCancelamentoComplementos(reportFilterEmit);
      })
      .set(52, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioCancelamentoContrato(reportFilterEmit);
      })
      .set(53, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioAuditoriaDevedorDuvidoso(reportFilterEmit);
      })
      .set(54, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioAuditoriaComplementoEntrePostos(reportFilterEmit);
      })
      .set(55, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioListaDePagamentosAprovadosConciliacao(reportFilterEmit);
      })
      .set(56, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioListaDePagamentosNaoProcessadosConciliacao(reportFilterEmit);
      })
      .set(57, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioListaGeralDePagamentosConciliacao(reportFilterEmit);
      })
      .set(58, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioContratosLiquidados(reportFilterEmit);
      })
      .set(59, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioCobrancaRepactuacoes(reportFilterEmit);
      })
      .set(60, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioContratosRepactuados(reportFilterEmit);
      })
      .set(61, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioContratosGeradosRepactuacoes(reportFilterEmit);
      })
      .set(62, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioContratosPorPeriodo(reportFilterEmit);
      })
      .set(63, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDeNovosClientes(reportFilterEmit);
      })
      .set(64, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDeContratosJuridicosFinalizados(reportFilterEmit);
      })
      .set(65, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDeClientesReativados(reportFilterEmit);
      })
      .set(66, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDeContratosNovosRenovadosReativados(reportFilterEmit);
      })
      .set(67, (reportFilterEmit: ReportFilterEmit) => {
        this.relatorioDeCaptados(reportFilterEmit);
      })
      .set(68,(reportFilterEmit: ReportFilterEmit) => {
        this.relatorioClienteEndereco(reportFilterEmit);
      })
      .set(69, (reportFilterEmit: ReportFilterEmit) => {
      this.relatorioContratosNovos(reportFilterEmit);
      });
    actions.get(this.reportSelecionado.id)(evento);
  }

  private relatorioContratosNovos(reportFilter: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        postoId: reportFilter.postoSelecionado.id,
        descricaoRota: reportFilter.rotaSelecionada?.descricao,
      }}
  )
  this.contratoService.imprimirRelatorioContratoNovos(options).subscribe(
    (response: Blob) => {
      this.appState.isLoading = false;
      const hoje = new Date();
      FileSaver.saveAs(
        response,
        `relatorio-contrato-novo-repass-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
          -2,
        )}${hoje.getFullYear()}.pdf`
      );
    },
    () => (this.appState.isLoading = false),
  )
  }

  private relatorioClienteEndereco(reportFilter: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        nomeCliente: reportFilter.nomeCliente ,
        postoId: reportFilter.postoSelecionado.id,
        descricaoRota: reportFilter.rotaSelecionada?.descricao ,
    }}
  )
    this.contratoService.imprimirRelatorioClienteEndereco(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-cliente-endereco-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    )
}

  private relatorioRepassesDiario(reportFilterEmit: ReportFilterEmit): void {
    const posto = reportFilterEmit.postoSelecionado == null ? 0 : reportFilterEmit.postoSelecionado.id;
    const data = reportFilterEmit.dataInicial.getTime();

    this.contratoService.imprimirRelatorioRepassesDiarios(posto, data).subscribe((response: Blob) => {
      const hoje = new Date(new Date());
      FileSaver.saveAs(
        response,
        `relatorio-repasses-diarios-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
          -2,
        )}${hoje.getFullYear()}.pdf`,
      );
    });
  }

  private relatorioDeFaturamento(reportFilter: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        postoId: reportFilter.postoSelecionado.id,
        descricaoRota: reportFilter.rotaSelecionada && reportFilter.rotaSelecionada.id !== 0 ? reportFilter.rotaSelecionada?.descricao : '',
        dataInicial: reportFilter.dataInicial?.getTime(),
        dataFinal: reportFilter.dataFinal?.getTime(),
        quantidadeParcelas: reportFilter.opcaoGenerico.id !== null ? (reportFilter.opcaoGenerico.id === 0 ? 10 :  (reportFilter.opcaoGenerico.id === 1 ? 20 : 2)) : null,
        periodicidadeContrato: '' + reportFilter.periodicidadeEnum,
      },
    });
    this.contratoService.imprimirRelatorioDeFaturamento(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-de-faturamento-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private gerarRelatorioContratoCancelado(reportFilter: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const filters = new QueryOptions({ pageSize: 10000, pageNumber: 1 });

    filters.params = {
      usuarioHeimdall: this.nomeUsuario ? this.nomeUsuario : '',
      nomeCliente: reportFilter.nomeCliente ? reportFilter.nomeCliente : '',
      situacaoContrato: `in:${SituacaoContrato.CANCELADO}`,
      relatorioCancelados: true,
      postoId: reportFilter.postoSelecionado ? reportFilter.postoSelecionado.id : null,
      dataCanceladoInicial: reportFilter.dataInicial ? reportFilter.dataInicial.getTime() : null,
      dataCanceladoFinal: reportFilter.dataFinal ? reportFilter.dataFinal.getTime() : null,
      descricaoRota: reportFilter.rotaSelecionada && reportFilter.rotaSelecionada.id !== 0 ? reportFilter.rotaSelecionada?.descricao : '',
    };

    this.contratoService.getRelatorioContratoPorSituacao(filters).subscribe(
      (data: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();

        FileSaver.saveAs(
          data,
          `relatorio-de-contratos-cancelados-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private gerarRelatorioContratoProrrogados(reportFilter: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const filters = new QueryOptions({ pageSize: 10000, pageNumber: 1 });

    filters.params = {
      usuarioHeimdall: this.nomeUsuario ? this.nomeUsuario : '',
      nomeCliente: reportFilter.nomeCliente ? reportFilter.nomeCliente : '',
      situacaoContrato: `in:${SituacaoContrato.RENEGOCIADO},${SituacaoContrato.EM_ATRASO},${SituacaoContrato.FINALIZADO_COM_ATRASO}`,
      relatorioProrrogados: true,
      postoId: reportFilter.postoSelecionado ? reportFilter.postoSelecionado.id : null,
      dataProrrogadoInicial: reportFilter.dataInicial ? reportFilter.dataInicial.getTime() : null,
      dataProrrogadoFinal: reportFilter.dataFinal ? reportFilter.dataFinal.getTime() : null,
      descricaoRota: reportFilter.rotaSelecionada && reportFilter.rotaSelecionada.id !== 0 ? reportFilter.rotaSelecionada?.descricao : '',
    };

    this.contratoService.getRelatorioContratoProrrogacao(filters).subscribe(
      (data: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();

        FileSaver.saveAs(
          data,
          `relatorio-de-contratos-prorrogados-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioDeContratos(reportFilter: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuario: 0,
        posto: reportFilter.postoSelecionado.id,
        rota:
          !reportFilter.rotaSelecionada || reportFilter.rotaSelecionada?.descricao.includes('Todas')
            ? ''
            : reportFilter.rotaSelecionada?.descricao,
        datas: `${reportFilter.dataInicial?.getTime()},${reportFilter.dataFinal?.getTime()}`,
      },
    });
    this.contratoService.getRelatorioHistoricoDeRepasses(options).subscribe(
      (data: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          data,
          `relatorio-de-repasses-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(-2)}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private gerarRelatorioDeCobrancas(reportFilter: ReportFilterEmit): void {
    const filters = new QueryOptions();
    filters.params = {
      postoId: reportFilter.postoSelecionado ? reportFilter.postoSelecionado.id : null,
      dataInicial: reportFilter.dataInicial ? reportFilter.dataInicial.getTime() : null,
      dataFinal: reportFilter.dataFinal ? reportFilter.dataFinal.getTime() : null,
      descricaoRota: reportFilter.rotaSelecionada && reportFilter.rotaSelecionada.id !== 0 ? reportFilter.rotaSelecionada?.descricao : '',
    };

    this.contratoService.getRelatorioParcelasRecebidasAReceber(filters).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date(new Date());
        FileSaver.saveAs(
          response,
          `relatorio-de-cobrancas-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(-2)}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private gerarRelatorioDeContratosFinalizados(reportFilter: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        postoId: reportFilter.postoSelecionado.id !== 0 ? reportFilter.postoSelecionado.id : null,
        descricaoRota: reportFilter.rotaSelecionada.id !== 0 ? reportFilter.rotaSelecionada?.descricao : '',
        dataInicial: reportFilter.dataInicial?.getTime(),
        dataFinal: reportFilter.dataFinal?.getTime(),
      },
    });
    this.contratoService.imprimirRelatorioDeContratosFinalizados(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-de-contratos-finalizados-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private gerarRelatorioSituacaoContrato(reportFilter: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const filters = new QueryOptions();
    filters.params = {
      postoId: reportFilter.postoSelecionado ? reportFilter.postoSelecionado.id : null,
      dataInicial: reportFilter.dataInicial ? reportFilter.dataInicial.getTime() : null,
      dataFinal: reportFilter.dataFinal ? reportFilter.dataFinal.getTime() : null,
    };

    this.contratoService.gerarRelatorioSituacaoContrato(filters).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date(new Date());
        FileSaver.saveAs(
          response,
          `relatorio-de-situacao-contrato-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private gerarRelatorioReagendamentoContrato(reportFilter: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const filters = new QueryOptions();
    filters.params = {
      postoId: reportFilter.postoSelecionado ? reportFilter.postoSelecionado.id : null,
      dataInicial: reportFilter.dataInicial ? reportFilter.dataInicial.getTime() : null,
      dataFinal: reportFilter.dataFinal ? reportFilter.dataFinal.getTime() : null,
    };

    this.contratoService.relatorioReagendamentoContrato(filters).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date(new Date());
        FileSaver.saveAs(
          response,
          `relatorio-de-reagendamentos-contrato-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private gerarRelatorioAlteracaoValorContratos(reportFilter: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const filters = new QueryOptions();
    filters.params = {
      postoId: reportFilter.postoSelecionado ? reportFilter.postoSelecionado.id : null,
      dataInicial: reportFilter.dataInicial ? reportFilter.dataInicial.getTime() : null,
      dataFinal: reportFilter.dataFinal ? reportFilter.dataFinal.getTime() : null,
      filtro: reportFilter.opcaoGenerico.id === 0 ? 'ALTERACAO' : 'LIBERACAO',
    };

    this.contratoService.relatorioAlteracaoValorContratos(filters).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date(new Date());
        FileSaver.saveAs(
          response,
          `relatorio-de-alteracao-valores-contratos-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private despesasDiarias(reportFilter: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const filters = new QueryOptions();
    filters.params = {
      postoId: reportFilter.postoSelecionado ? reportFilter.postoSelecionado.id : null,
      dataInicial: reportFilter.dataInicial ? reportFilter.dataInicial.getTime() : null,
    };

    this.caixaService.relatorioDespesasDiarias(filters).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date(new Date());
        FileSaver.saveAs(
          response,
          `relatorio-de-despesa-diaria-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.zip`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioDeFaturamentoGeral(reportFilter: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        dataInicial: reportFilter.dataInicial?.getTime(),
        dataFinal: reportFilter.dataFinal?.getTime(),
      },
    });
    this.contratoService.imprimirRelatorioDeFaturamentoGeral(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-de-faturamento-geral-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioDeClientesInativos(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        boleto: false,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        opcao: reportFilterEmit.endereco && reportFilterEmit.endereco.id != null ? reportFilterEmit.endereco.id : 1,
        regular: reportFilterEmit.regularidade && reportFilterEmit.regularidade.id != null ? reportFilterEmit.regularidade.id : 0,
        posto: reportFilterEmit.postoSelecionado.id,
        rota: reportFilterEmit.rotaSelecionada && reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada?.descricao : '',
        quantidadeParcelas: reportFilterEmit.opcaoGenerico.id !== null ? (reportFilterEmit.opcaoGenerico.id === 0 ? 10 : 20) : 0,
        valorParcela: reportFilterEmit.inputMinimo,
        diasDeAtraso: reportFilterEmit.diasAtraso != null && reportFilterEmit.regularidade.id == 2 ? reportFilterEmit.diasAtraso : 0,
        inativacao: '' + reportFilterEmit.situacaoInativarRenovacaoAutomatica,
      },
    });
    this.contratoService.relatorioDeClientesInativos(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-de-clientes-inativos-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioDeNovosClientes(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id,
        rota: reportFilterEmit.rotaSelecionada && reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada?.descricao : '',

      },
    });

    this.contratoService.relatorioDeNovosClientes(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-clientes-novos-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioDeContratosJuridicosFinalizados(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id !== 0 ? reportFilterEmit.postoSelecionado.id : null,
        rota: reportFilterEmit.rotaSelecionada && reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada?.descricao : null,

      },
    });

    this.contratoService.relatorioDeContratosJuridicosFinalizados(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-contratos-juridicos-finalizados-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }


  private relatorioDeClientesReativados(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id,
        rota: reportFilterEmit.rotaSelecionada && reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada?.descricao : '',

      },
    });

    this.contratoService.relatorioDeClientesReativados(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-clientes-reativados-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }


  private relatorioDeClientesInadimplemtesIregulares(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        posto: reportFilterEmit.postoSelecionado.id,
        rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada?.descricao : '',
        valorMinimo: reportFilterEmit.inputMinimo,
        valorMaximo: reportFilterEmit.inputMaximo,
        dataInicial: reportFilterEmit.dataInicial?.getTime(),
        dataFinal: reportFilterEmit.dataFinal?.getTime(),
        exibirTelefone: reportFilterEmit.opcaoGenerico.id !== null ? (reportFilterEmit.opcaoGenerico.id === 0 ? true : false) : null,
        filtroInputData: false,
        regular: false,
        irregular: true,
        quantidadeParcelas: reportFilterEmit.multipleOpcaoGenerico.length
          ? reportFilterEmit.multipleOpcaoGenerico.toString().replace('[', '').replace(']', '')
          : null,
      },
    });
    this.contratoService.relatorioDeClientesInadimplemtesIregulares(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-de-clientes-inadimplemtes-irregulares-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioDeClientesInadimplentesRegulares(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        posto: reportFilterEmit.postoSelecionado.id,
        rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada?.descricao : '',
        dataInicial: reportFilterEmit.dataInicial?.getTime(),
        dataFinal: reportFilterEmit.dataFinal?.getTime(),
        regular: true,
        irregular: false,
        filtroInputData: false,
        exibirTelefone: reportFilterEmit.opcaoGenerico.id !== null ? (reportFilterEmit.opcaoGenerico.id === 0 ? true : false) : null,
        quantidadeParcelas: reportFilterEmit.multipleOpcaoGenerico.length
          ? reportFilterEmit.multipleOpcaoGenerico.toString().replace('[', '').replace(']', '')
          : null,
      },
    });
    this.contratoService.relatorioDeClientesInadimplentesRegulares(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-de-clientes-inadimplemtes-regulares-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioDeFaltasESobras(reportFilterEmit: ReportFilterEmit): void {
    if (reportFilterEmit.postoSelecionado.id === 0) {
      this.dialogService.feedbackInfo('Obrigatório escolher um posto!');
    } else {
      this.appState.isLoading = true;
      const options = new QueryOptions({
        params: {
          usuarioHeimdall: this.nomeUsuario,
          datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
          posto: reportFilterEmit.postoSelecionado.id,
          rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada?.descricao : '',
        },
      });
      this.contratoService.relatorioDeFaltasESobras(options).subscribe(
        (response: Blob) => {
          this.appState.isLoading = false;
          const hoje = new Date();
          FileSaver.saveAs(
            response,
            `relatorio-de-faltas-e-sobras-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
              -2,
            )}${hoje.getFullYear()}.pdf`,
          );
        },
        () => (this.appState.isLoading = false),
      );
    }
  }

  private relatorioDeCobrabcaSemanal(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        bloquearPostoPix: this.usuario.profile === 'ROLE_ADMIN' || this.usuario.profile === 'ROLE_DIRETOR' ? false : true,
        bloquearValoresFixo: this.usuario.profile === 'ROLE_ADMIN' ? false : true,
        data: reportFilterEmit.dataSemanal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id !== 0 ? reportFilterEmit.postoSelecionado.id : null,
        rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada?.descricao : null,
      },
    });
    this.contratoService.relatorioDecobrancaSemanal(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-de-cobranca-semanal-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioDeMediaDeRpasses(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id,
        rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada?.descricao : '',
      },
    });
    this.contratoService.relatorioMediaRepasses(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-de-medias-de-repasses-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioContratosRBM(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;

    if (reportFilterEmit.contratosRBM.id === 0) {
      this.options = new QueryOptions({
        params: {
          contratosRBM: reportFilterEmit.contratosRBM.id,
          usuarioHeimdall: this.nomeUsuario,
          dataLiberadoInicial: reportFilterEmit.dataInicial?.getTime(),
          dataLiberadoFinal: reportFilterEmit.dataFinal?.getTime(),
          descricaoRota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada?.descricao : '',
          postoId: reportFilterEmit.postoSelecionado.id,
          formatoDownload:
            reportFilterEmit.formatoDownload == null ? reportFilterEmit.formatoDownload.id === 0 : reportFilterEmit.formatoDownload.id,
        },
      });
    } else {
      this.options = new QueryOptions({
        params: {
          contratosRBM: reportFilterEmit.contratosRBM.id,
          usuarioHeimdall: this.nomeUsuario,
          dataFinalizadoInicial: reportFilterEmit.dataInicial?.getTime(),
          dataFinalizadoFinal: reportFilterEmit.dataFinal?.getTime(),
          descricaoRota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada?.descricao : '',
          postoId: reportFilterEmit.postoSelecionado.id,
          formatoDownload:
            reportFilterEmit.formatoDownload == null ? reportFilterEmit.formatoDownload.id === 0 : reportFilterEmit.formatoDownload.id,
        },
      });
    }

    if (reportFilterEmit.formatoDownload.id === 0) {
      this.contratoService.relatorioContratosRBM(this.options).subscribe(
        (response: Blob) => {
          this.appState.isLoading = false;
          const hoje = new Date();
          FileSaver.saveAs(
            response,
            `relatorio-de-contratos-rbm-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
              -2,
            )}${hoje.getFullYear()}.pdf`,
          );
        },
        () => (this.appState.isLoading = false),
      );
    } else {
      this.contratoService.relatorioContratosRBM(this.options).subscribe(
        (response: Blob) => {
          this.appState.isLoading = false;
          const hoje = new Date();
          FileSaver.saveAs(
            response,
            `relatorio-de-contratos-rbm-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
              -2,
            )}${hoje.getFullYear()}.xlsx`,
          );
        },
        () => (this.appState.isLoading = false),
      );
    }
  }

  private relatorioDeCobrancasSabado(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    let options;
    if (reportFilterEmit.dataSemanal !== null) {
      options = new QueryOptions({
        params: {
          usuarioHeimdall: this.nomeUsuario,
          posto: reportFilterEmit.postoSelecionado.id,
          rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada?.descricao : '',
          data: reportFilterEmit.dataSemanal.getTime(),
        },
      });
    } else {
      options = new QueryOptions({
        params: {
          usuarioHeimdall: this.nomeUsuario,
          posto: reportFilterEmit.postoSelecionado.id,
          rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada?.descricao : '',
        },
      });
    }
    this.contratoService.relatorioDeCobrancasSabado(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-de-cobrancas-sabado-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioEficiencia(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        postoId: reportFilterEmit.postoSelecionado.id !== 0 ? reportFilterEmit.postoSelecionado.id : null,
        rotaId: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada.id : null,
        rotaDescricao: reportFilterEmit.rotaSelecionada.descricao,
        quantidadeDeParcelas: reportFilterEmit.inputNumerico === null ? 0 : reportFilterEmit.inputNumerico,
        situacaoEficiencia: reportFilterEmit.situacaoEficiencia === null ? 0 : reportFilterEmit.situacaoEficiencia.id,
        situacaoCliente: reportFilterEmit.opcaoGenerico.id !== null ? reportFilterEmit.opcaoGenerico.descricao : 'TODOS',
        usuarioHeimdall: this.nomeUsuario,
        dataInicial: reportFilterEmit.dataInicial === null ? '' : reportFilterEmit.dataInicial.getTime(),
        dataFinal: reportFilterEmit.dataFinal === null ? '' : reportFilterEmit.dataFinal.getTime(),
      },
    });
    this.contratoService.relatorioEficiencia(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        FileSaver.saveAs(response, 'relatorio-de-eficiencia.pdf');
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioDeContratoParado(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        dataInicial: reportFilterEmit.dataInicial?.getTime(),
        dataFinal: reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id,
        rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada?.descricao : '',
      },
    });
    this.contratoService.relatorioDeContratoParado(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-de-contratos-parados-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }



  private relatorioDespesasFuncionarios(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id,
        rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada?.descricao : '',
      },
    });
    this.contratoService.relatorioDespesasFuncionarios(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-de-despesas-funcionarios-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioCaixaDivida(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataInicial?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id,
      },
    });
    this.contratoService.relatorioCaixaDivida(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-de-caixa-divida-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioClientesInadimplentesPorPosto(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id,
        rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada?.descricao : '',
      },
    });
    this.contratoService.relatorioClientesInadimplentesPorPosto(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-de-clientes-inadimplentes-por-posto-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioClientesInadimplentesPorRota(reportFilterEmit: ReportFilterEmit): void {
    if (reportFilterEmit.postoSelecionado.id === 0) {
      this.dialogService.feedbackInfo('Obrigatório escolher um posto!');
    } else {
      this.appState.isLoading = true;
      const options = new QueryOptions({
        params: {
          usuarioHeimdall: this.nomeUsuario,
          exibirTelefone: reportFilterEmit.opcaoGenerico.id !== null ? (reportFilterEmit.opcaoGenerico.id === 0 ? true : false) : null,
          posto: reportFilterEmit.postoSelecionado.id,
          rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada?.descricao : '',
        },
      });
      this.contratoService.relatorioClientesInadimplentesPorRota(options).subscribe(
        (response: Blob) => {
          this.appState.isLoading = false;
          const hoje = new Date();
          FileSaver.saveAs(
            response,
            `relatorio-de-situacao-de-clientes-por-rota-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
              -2,
            )}${hoje.getFullYear()}.pdf`,
          );
        },
        () => (this.appState.isLoading = false),
      );
    }
  }

  private relatorioCobrancaMensal(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        dataInicial: reportFilterEmit.dataInicial?.getTime(),
        postoId: reportFilterEmit.postoSelecionado.id !== 0 ? reportFilterEmit.postoSelecionado.id : null,
        rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada?.descricao : null,
      },
    });
    this.contratoService.relatorioDeCobrancaMensal(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-de-cobrancas-mensal-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioDespesasTipoEmpresa(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id,
        rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada?.descricao : '',
      },
    });
    this.contratoService.relatorioDespesaTipoEmpresa(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-de-despesa-tipo-empresa-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioProximosLiquidacao(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        postoId: reportFilterEmit.postoSelecionado.id === 0 ? null : reportFilterEmit.postoSelecionado.id,
        rota: reportFilterEmit.rotaSelecionada.id === 0 ? null : reportFilterEmit.rotaSelecionada.descricao,
        quantidade: reportFilterEmit.inputMinimo,
      },
    });
    this.contratoService.relatorioProximosLiquidacao(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-de-clientes-proximos-a-liquidacao-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioRepassesSemanal(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        dataInicial: reportFilterEmit.dataSemanal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id,
        rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada?.descricao : '',
      },
    });
    this.contratoService.relatorioRepassesSemanal(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-de-repasses-semanal-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioContratosPorCliente(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id,
        rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada?.descricao : '',
        opcao: reportFilterEmit.opcaoGenerico.id,
        valorMinimo: reportFilterEmit.inputMinimo,
        valorMaximo: reportFilterEmit.inputMaximo,
      },
    });
    this.contratoService.relatorioContratosPorCliente(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-de-contratos-por-cliente-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioCobrancasTurno(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        dataInicial: reportFilterEmit.dataSemanal?.getTime(),
        postoId: reportFilterEmit.postoSelecionado.id !== 0 ? reportFilterEmit.postoSelecionado.id : null,
        rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada?.descricao : null,
        turnos: reportFilterEmit.turnos.length ? reportFilterEmit.turnos.toString() : null,
      },
    });
    this.contratoService.relatorioCobrancasTurno(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-cobrancas-turno-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private gerarRelatorioDeAcaoDeVendas(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id,
        situacaoCliente: '' + reportFilterEmit.situacoesClienteSelecionada,
        situacaoAcaoVenda: '' + reportFilterEmit.situacoesAcaoVendaSelecionada,
        situacaoContrato: '' + reportFilterEmit.situacoesContratoSelecionada,
        situacaoVisita: '' + reportFilterEmit.situacoesVisitaSelecionada,
      },
    });
    this.acaoDeVendaService.gerarRelatorioDeAcaoDeVendas(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-de-acao-de-vendas-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private gerarRelatorioControleGeral(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        estado: reportFilterEmit.estadoId ? reportFilterEmit.estadoId : null,
        // posto: reportFilterEmit.postoSelecionado.id !== 0 ? reportFilterEmit.postoSelecionado.id : null,
        situacao: reportFilterEmit.multipleOpcaoGenerico.toString(),
      },
    });
    this.transferenciaService.gerarRelatorioControleGeral(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-controle-geral-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private gerarFichaCadastral(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        nome: reportFilterEmit.nomeCliente,
        pf: reportFilterEmit.opcaoGenerico.id === 0 ? true : false,
      },
    });
    this.visitaService.gerarFichaCadastral(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        FileSaver.saveAs(response, `ficha-cadastral.pdf`);
      },
      () => (this.appState.isLoading = false),
    );
  }

  private gerarRelatorioEficienciaCobrador(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const hoje = new Date();
    const options = new QueryOptions({
      params: {
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id !== 0 ? reportFilterEmit.postoSelecionado.id : null,
        rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada.descricao : null,
      },
    });
    this.contratoService.gerarRelatorioEficienciaCobrador(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        FileSaver.saveAs(
          response,
          `relatorio-eficiencia-cobrador-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private gerarRelatorioOrigemCaptacao(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id,
      },
    });
    this.transferenciaService.gerarRelatorioOrigemCapitacao(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-origem-captacoes-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private gerarRelatorioPeriodoRecebimentos(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        semanal: reportFilterEmit.checkSemanal,
        data: reportFilterEmit.checkSemanal ? reportFilterEmit?.dataSemanal.getTime() : reportFilterEmit?.dataInicial.getTime(),
        posto: reportFilterEmit.postoSelecionado.id !== 0 ? reportFilterEmit.postoSelecionado.id : null,
        rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada.descricao : null,
      },
    });
    this.contratoService.gerarRelatorioPeriodosRecebimento(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-periodos-recebimento-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private gerarRelatorioAcompanhamentoOperacional(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        dataInicial: reportFilterEmit.dataInicial.getTime(),
        dataFinal: reportFilterEmit.dataFinal.getTime(),
        filtrarContratosNaoOperados: reportFilterEmit.contratoNaoOperado,
        filtrarContratosRepassados: reportFilterEmit.contratoRepassado,
        filtrarSomenteClientesNovos: reportFilterEmit.somenteClientesNovos,
        filtrarSomenteClientesReativados: reportFilterEmit.somenteClientesReativados,
        filtrarSomenteClientesRenovados: reportFilterEmit.somenteClientesRenovados,
        filtrarSomenteContratosCancelados: reportFilterEmit.somenteContratosCancelados,
        filtrarTipoPessoa: reportFilterEmit.opcaoGenerico.id,
        filtrarValorMaximo: reportFilterEmit.inputMaximo,
        filtrarValorMinimo: reportFilterEmit.inputMinimo,
        idPosto: reportFilterEmit.postoSelecionado.id !== 0 ? reportFilterEmit.postoSelecionado.id : null,
        idRota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada.id : null,
      },
    });
    this.contratoService.gerarRelatorioAcompanhamentoOperacional(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-acompanhamento-operacional-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private gerarRelatorioDespesasNacional(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        dataInicial: reportFilterEmit.dataInicial.getTime(),
        dataFinal: reportFilterEmit.dataFinal.getTime(),
        estadoId: reportFilterEmit.estadoId !== 0 ? reportFilterEmit.estadoId : null,
        postoId: reportFilterEmit.postoSelecionado.id !== 0 ? reportFilterEmit.postoSelecionado.id : null,
      },
    });

    this.transferenciaService.gerarRelatorioDespesas(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-despesas-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(-2)}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private gerarRelatorioClientesVisitados(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        dataInicial: reportFilterEmit.dataInicial ? reportFilterEmit.dataInicial.getTime() : null,
        dataFinal: reportFilterEmit.dataFinal ? reportFilterEmit.dataFinal.getTime() : null,
        posto:
          reportFilterEmit.postoSelecionado && reportFilterEmit.postoSelecionado.id !== 0 ? reportFilterEmit.postoSelecionado.id : null,
        fiscal: reportFilterEmit.fiscal && reportFilterEmit.fiscal.id !== 0 ? reportFilterEmit.fiscal.id : null,
      },
    });
    this.visitaService.gerarRelatorioClientesVisitados(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `Relatório de Clientes Visitados - ${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private gerarRelatorioComissao(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        dataInicial: reportFilterEmit.dataInicial ? reportFilterEmit.dataInicial.getTime() : null,
        dataFinal: reportFilterEmit.dataFinal ? reportFilterEmit.dataFinal.getTime() : null,
        posto:
          reportFilterEmit.postoSelecionado && reportFilterEmit.postoSelecionado.id !== 0 ? reportFilterEmit.postoSelecionado.id : null,
        fiscal: reportFilterEmit.fiscal && reportFilterEmit.fiscal.id !== 0 ? reportFilterEmit.fiscal.id : null,
      },
    });
    this.visitaService.gerarRelatorioComissao(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `Relatório de ComissÕes - ${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioDeClientesCobrancaJudicial(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id,
      },
    });
    this.clienteService.getRelatorioDeClientesCobrancaJudicial(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `Relatório de Clientes em Cobrança Judicial ${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioDePagamentosCobrancaJudicial(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id,
      },
    });
    this.cobrancaJudicialService.getRelatorioDePagamentosCobrancaJudicial(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `Relatório de Pagamentos em Cobrança Judicial ${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioDeProdutividade(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id,
        funcionarioId: reportFilterEmit.cobrador ? reportFilterEmit.cobrador.id : null,
      },
    });
    this.contratoComissaoService.getRelatorioDeProdutividade(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `Relatório de Produtividade ${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioDeTransferenciaDeCliente(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id,
      },
    });
    this.auditoriaTransferenciaClienteService.gerarRelatorio(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `Relatório de Transferencia Clientes ${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioAssinaturasRepassesDigitais(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        dataInicial: reportFilterEmit.dataInicial?.getTime(),
        dataFinal: reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id !== 0 ? reportFilterEmit.postoSelecionado.id : null,
        rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada.descricao : null,
        situacaoContrato: '' + reportFilterEmit.situacoesContratoSelecionada,
      },
    });
    this.contratoService.relatorioAssinaturasRepassesDigitais(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `Relatório de Assinaturas e Repasses Digitais ${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  setParametrosDefault(): void {
    this.filho.resetarAtributos();
  }

  private relatorioDeClientesSpc(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id,
        rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada.descricao : null,
      },
    });
    this.contratoService.relatorioClientesSpc(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `Relatório de Clientes No SPC ${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioParcelasAlteradas(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id !== 0 ? reportFilterEmit.postoSelecionado.id : null,
        rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada.descricao : null,
      },
    });
    this.contratoService.relatorioParcelasAlteradas(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `Relatório de Parcelas Alteradas ${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioCancelamentoComplementos(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        postoId: reportFilterEmit.postoSelecionado.id !== 0 ? reportFilterEmit.postoSelecionado.id : null,
      },
    });
    this.transferenciaService.relatorioCancelamentoComplementos(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `Relatorio Cancelamento Complemento ${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }
  private relatorioCancelamentoContrato(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        postoId: reportFilterEmit.postoSelecionado.id !== 0 ? reportFilterEmit.postoSelecionado.id : null,
      },
    });
    this.contratoService.relatorioCancelamentoContrato(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `Relatorio Cancelamento Contrato ${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioAuditoriaDevedorDuvidoso(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        postoId: reportFilterEmit.postoSelecionado.id !== 0 ? reportFilterEmit.postoSelecionado.id : null,
      },
    });
    this.clienteService.getRelatorioAuditoriaClienteDuvidoso(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `Relatorio Auditoria Devedor Duvidoso ${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioAuditoriaComplementoEntrePostos(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        postoId: reportFilterEmit.postoSelecionado.id !== 0 ? reportFilterEmit.postoSelecionado.id : null,
      },
    });
    this.transferenciaService.relatorioAuditoriaComplementoEntrePostos(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `Relatorio Auditoria Complemento Entre Postos ${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioListaDePagamentosAprovadosConciliacao(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        estado: reportFilterEmit.estadoId !== 0 ? reportFilterEmit.estadoId : null,
      },
    });
    this.conciliacaoBancariaService.relatorioListaPagamentosAprovadosConciliacao(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `Relatório Lista de Pagamentos Aprovados da Conciliação ${('00' + hoje.getDate()).slice(-2)}${(
            '00' +
            (hoje.getMonth() + 1)
          ).slice(-2)}
          ${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioListaDePagamentosNaoProcessadosConciliacao(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        estado: reportFilterEmit.estadoId !== 0 ? reportFilterEmit.estadoId : null,
      },
    });
    this.conciliacaoBancariaService.relatorioListaDePagamentosNaoProcessadosConciliacao(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `Relatório Lista de Pagamentos Aprovados da Conciliação ${('00' + hoje.getDate()).slice(-2)}${(
            '00' +
            (hoje.getMonth() + 1)
          ).slice(-2)}
          ${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioListaGeralDePagamentosConciliacao(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
      },
    });
    this.conciliacaoBancariaService.relatorioListaGeralDePagamentosConciliacao(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `Relatório Lista Geral de Pagamentos Conciliação ${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(-2)}
          ${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioContratosLiquidados(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id !== 0 ? reportFilterEmit.postoSelecionado.id : null,
        rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada.descricao : null,
      },
    });
    this.contratoService.relatorioContratosLiquidados(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `Relatório de Contratos Liquidados ${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(-2)}
          ${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioContratosRepactuados(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id !== 0 ? reportFilterEmit.postoSelecionado.id : null,
        rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada.descricao : null,
      },
    });
    this.contratoService.relatorioContratosRepactuados(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `Relatório de Contratos Repactuados ${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(-2)}
      ${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioCobrancaRepactuacoes(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id !== 0 ? reportFilterEmit.postoSelecionado.id : null,
        rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada.descricao : null,
      },
    });

    this.contratoService.relatorioCobrancaRepactuacoes(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `Relatório de Cobrança das Repactuações ${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }

  private relatorioContratosGeradosRepactuacoes(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.id !== 0 ? reportFilterEmit.postoSelecionado.id : null,
        rota: reportFilterEmit.rotaSelecionada.id !== 0 ? reportFilterEmit.rotaSelecionada.descricao : null,
      },
    });
    this.contratoService.relatorioContratosGeradosRepactuacoes(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `Relatório de Contratos Gerados a Partir de Repactuações ${('00' + hoje.getDate()).slice(-2)}${(
            '00' +
            (hoje.getMonth() + 1)
          ).slice(-2)}
          ${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }
  private relatorioContratosPorPeriodo(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        posto: reportFilterEmit.postoSelecionado.descricao === 'Todos os Postos' ? 0 : reportFilterEmit.postoSelecionado.id,
        rota: reportFilterEmit.rotaSelecionada.descricao === 'Todas as Rotas' ? '' : reportFilterEmit.rotaSelecionada.descricao,
      },
    });
    this.contratoService.relatorioContratosPorPeriodo(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `Relatório de Contratos Por Período ${('00' + hoje.getDate()).slice(-2)}${(
            '00' +
            (hoje.getMonth() + 1)
          ).slice(-2)}
          ${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }
  private relatorioDeContratosNovosRenovadosReativados(reportFilter: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        dataInicial: reportFilter.dataInicial?.getTime(),
        dataFinal: reportFilter.dataFinal?.getTime(),
      },
    });
    this.contratoService.relatorioDeContratosNovosRenovadosReativados(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-de-contratos-novos-renovados-reativados-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }
  private relatorioDeCaptados(reportFilterEmit: ReportFilterEmit): void {
    this.appState.isLoading = true;
    const options = new QueryOptions({
      params: {
        usuarioHeimdall: this.nomeUsuario,
        datas: reportFilterEmit.dataInicial?.getTime() + ',' + reportFilterEmit.dataFinal?.getTime(),
        interesse: reportFilterEmit.opcaoGenerico.id !== null ? reportFilterEmit.opcaoGenerico.descricao : '',
        posto: reportFilterEmit.postoSelecionado.id,
      },
    });
    this.captadoService.relatorioDeCaptados(options).subscribe(
      (response: Blob) => {
        this.appState.isLoading = false;
        const hoje = new Date();
        FileSaver.saveAs(
          response,
          `relatorio-de-captados-${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
            -2,
          )}${hoje.getFullYear()}.pdf`,
        );
      },
      () => (this.appState.isLoading = false),
    );
  }
}
