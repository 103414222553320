import { AlterarValorContratoDTO } from './../domain/dto/alterar-valor-contrato-dto';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WEB_API } from 'src/app/global/services/web-api';
import { ResourceService } from 'src/app/global/services/resource.service';
import { Contrato } from '../domain/contrato.model';
import { ContratoSerializer } from '../serializables/contrato.serializer';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { Observable } from 'rxjs';
import { Page, ReturnAPI } from '../domain/return-api.model';
import { TransferirContrato } from '../../global/forms/transferir-responsavel-form/transferir-responsavel-form.component';
import { AuditoriaContrato } from '../domain/auditoria-contrato.model';
import { Produto } from '../domain/produto.model';
import { AlterarContratoDTO } from '../domain/dto/alterar-contrato-dto';
import { ValoresContratoRequestDTO } from '../domain/dto/valores-contrato-requesto.dto';
import { RelatorioContabilidadeDTO } from '../domain/dto/relatorio-contabilidade-dto';
import { ArquivoImportacaoNovo } from '../domain/arquivo-importacao-novo.model';
import { ArquivoImportacaoFinalizado } from '../domain/arquivo-importacao-finalizado.model';
import { FuncionarioService } from './funcionario.service';
import { CaixaService } from './caixa.service';
import { ResponsavelDTO } from 'src/app/global/forms/alterar-responsavel-rota-form/alterar-responsavel-rota-form.component';
import { TransferenciaService } from './transferencia.service';
import { DashboardRecebimentoSemanalDto } from '../domain/dto/dashboard-recebimento-semanal.dto';
import { CalculoValorLiquidoDTO } from '../domain/dto/calculo-valor-liquido.dto';
import { Parcela } from '../domain/parcela.model';
import { GerarPromocaoDTO } from '../domain/dto/gerar-promocao-dto';
import { AlterarParcelaDTO } from '../domain/dto/alterar-parcela-dto';

@Injectable()
export class ContratoService extends ResourceService<Contrato> {
  static RESOURCE = 'contrato';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${ContratoService.RESOURCE}`, new ContratoSerializer(Contrato));
  }

  findAll(queryOption: QueryOptions): Observable<ReturnAPI<Page<Contrato>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Contrato>>>(
      `${WEB_API}/${ContratoService.RESOURCE}?${queryOption.toQueryString()}&${this.produtoId}`,
    );
  }

  findOne(contratoID: number): Observable<ReturnAPI<Contrato>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Contrato>>(
      `${WEB_API}/${ContratoService.RESOURCE}/findOne?contratoId=${contratoID}&${this.produtoId}`,
    );
  }

  findContratosEncerrandoHojePorPosto(queryOption: QueryOptions): Observable<ReturnAPI<Page<Contrato>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Contrato>>>(
      `${WEB_API}/${ContratoService.RESOURCE}/contratos-encerrando-hoje?${queryOption.toQueryString()}&${this.produtoId}`,
    );
  }

  getValorBrutoContratosEncerrandoHojePorPosto(queryOption: QueryOptions): Observable<ReturnAPI<number>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<number>>(
      `${WEB_API}/${ContratoService.RESOURCE}/valor-bruto-encerrando-hoje?${queryOption.toQueryString()}&${this.produtoId}`,
    );
  }

  getValorLiquidoContratosEncerrandoHojePorPosto(queryOption: QueryOptions): Observable<ReturnAPI<number>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<number>>(
      `${WEB_API}/${ContratoService.RESOURCE}/valor-liquido-encerrando-hoje?${queryOption.toQueryString()}&${this.produtoId}`,
    );
  }

  aprovarRenovacaoContrato(contratoId: number, valorContrato: number, quantidadeParcelas: number): Observable<ReturnAPI<Contrato>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<Contrato>>(
      `${WEB_API}/${ContratoService.RESOURCE}/aprovar-renovacao-contrato/${contratoId}?valorContrato=${valorContrato}&quantidadeParcelas=${quantidadeParcelas}&${this.produtoId}`,
      null,
    );
  }

  imprimirRecebimentos(idContrato: number): Observable<Blob> {
    return this.httpClient.get(`${WEB_API}/${ContratoService.RESOURCE}/imprimir-relatorio-recebimento/${idContrato}?${this.produtoId}`, {
      responseType: 'blob',
    });
  }

  getValorDividaContrato(contratoID: number): Observable<ReturnAPI<number>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<number>>(
      `${WEB_API}/${ContratoService.RESOURCE}/valor-divida-contrato?contratoId=${contratoID}&${this.produtoId}`,
    );
  }

  cancelarContrato(auditoria: AuditoriaContrato): Observable<ReturnAPI<Contrato>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<Contrato>>(`${WEB_API}/${ContratoService.RESOURCE}/cancelar?${this.produtoId}`, auditoria);
  }

  cancelarContratos(ids: number[]): Observable<ReturnAPI<void>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<void>>(`${WEB_API}/${ContratoService.RESOURCE}/cancelar-contratos?${this.produtoId}`, ids);
  }

  cancelarContratoAdministrativo(auditoria: AuditoriaContrato): Observable<ReturnAPI<Contrato>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<Contrato>>(
      `${WEB_API}/${ContratoService.RESOURCE}/cancelar-administrativo?${this.produtoId}`,
      auditoria,
    );
  }

  alterarContrato(alterarContratoDTO: AlterarContratoDTO): Observable<ReturnAPI<Contrato>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<Contrato>>(
      `${WEB_API}/${ContratoService.RESOURCE}/alterar-contrato?${this.produtoId}`,
      alterarContratoDTO,
    );
  }

  renegociarContrato(renegociar: AuditoriaContrato): Observable<ReturnAPI<Contrato>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<Contrato>>(`${WEB_API}/${ContratoService.RESOURCE}/renegociar?${this.produtoId}`, renegociar);
  }

  repactuarContrato(repactuar: AuditoriaContrato): Observable<ReturnAPI<Contrato>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<Contrato>>(`${WEB_API}/${ContratoService.RESOURCE}/repactuar?${this.produtoId}`, repactuar);
  }

  liquidarContrato(contrato: AuditoriaContrato): Observable<ReturnAPI<Contrato>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<Contrato>>(`${WEB_API}/${ContratoService.RESOURCE}/liquidar?${this.produtoId}`, contrato);
  }

  finalizarContratoComDivida(contrato: AuditoriaContrato): Observable<ReturnAPI<Contrato>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<Contrato>>(
      `${WEB_API}/${ContratoService.RESOURCE}/finalizar-contrato-com-divida?${this.produtoId}`,
      contrato,
    );
  }

  transferirResponsavelContrato(transferirContrato: TransferirContrato): Observable<ReturnAPI<TransferirContrato>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<TransferirContrato>>(
      `${WEB_API}/${ContratoService.RESOURCE}/alterarresponsavel?${this.produtoId}`,
      transferirContrato,
    );
  }

  transferirResponsavelCobrancas(transferirContrato: TransferirContrato): Observable<ReturnAPI<TransferirContrato>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<TransferirContrato>>(
      `${WEB_API}/${ContratoService.RESOURCE}/alterarcobranca?${this.produtoId}`,
      transferirContrato,
    );
  }

  permitirRepasseBloqueadoPorInadimplencia(contratoId: number): Observable<ReturnAPI<void>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<void>>(
      `${WEB_API}/${ContratoService.RESOURCE}/permitir-liberacao-contrato-inadimplente/${contratoId}?${this.produtoId}`,
      null,
    );
  }

  getValorTotalBruto(valoresContrato: Partial<ValoresContratoRequestDTO>): Observable<ReturnAPI<number>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<number>>(
      `${WEB_API}/${ContratoService.RESOURCE}/valor-bruto-total-contratos?${this.produtoId}`,
      valoresContrato,
    );
  }

  getValorTotalLiquido(valoresContrato: Partial<ValoresContratoRequestDTO>): Observable<ReturnAPI<number>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<number>>(
      `${WEB_API}/${ContratoService.RESOURCE}/valor-liquido-total-contratos?${this.produtoId}`,
      valoresContrato,
    );
  }

  getValorTotalBrutoPorDataLiberacao(valoresContrato: Partial<ValoresContratoRequestDTO>): Observable<ReturnAPI<number>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<number>>(
      `${WEB_API}/${ContratoService.RESOURCE}/valor-bruto-total-contratos-por-data-liberacao?${this.produtoId}`,
      valoresContrato,
    );
  }

  getValorTotalLiquidoPorDataLiberacao(valoresContrato: Partial<ValoresContratoRequestDTO>): Observable<ReturnAPI<number>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<number>>(
      `${WEB_API}/${ContratoService.RESOURCE}/valor-liquido-total-contratos-por-data-liberacao?${this.produtoId}`,
      valoresContrato,
    );
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }

  gerarRelatorioContabilidade(relatorio: RelatorioContabilidadeDTO): Observable<Blob> {
    this.getProduto();
    return this.httpClient.post(`${WEB_API}/${ContratoService.RESOURCE}/relatorio-contabilidade?${this.produtoId}`, relatorio, {
      responseType: 'blob',
    });
  }

  getRelatorioContabilidade(arquivo: ArquivoImportacaoNovo): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/get-relatorio-contabilidade?${this.produtoId}&arquivoId=${arquivo.id}`,
      {
        responseType: 'blob',
      },
    );
  }

  gerarRelatorioFinalizado(relatorio: RelatorioContabilidadeDTO): Observable<Blob> {
    this.getProduto();
    return this.httpClient.post(`${WEB_API}/${ContratoService.RESOURCE}/relatorio-finalizado?${this.produtoId}`, relatorio, {
      responseType: 'blob',
    });
  }

  getRelatorioFinalizado(arquivo: ArquivoImportacaoFinalizado): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/get-relatorio-finalizado?${this.produtoId}&arquivoId=${arquivo.id}`,
      {
        responseType: 'blob',
      },
    );
  }

  imprimirRelatorioRepassesDiarios(posto: number, data: number): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/imprimir-relatorio-repasses-diarios?${this.produtoId}&posto=${posto}&datas=${data}`,
      {
        responseType: 'blob',
      },
    );
  }

  imprimirRelatorioDeFaturamento(params: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-de-faturamento?${this.produtoId}&${params.toQueryString(false)}`,
      {
        responseType: 'blob',
      },
    );
  }

  imprimirRelatorioContratoNovos(params: QueryOptions): Observable<Blob>{
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-contratos-novos?${this.produtoId}&${params.toQueryString(false)}`,
      {
        responseType: 'blob'
      },
    );
  }

  imprimirRelatorioClienteEndereco(params: QueryOptions): Observable<Blob> {
    this.getProduto();
    console.log(params);
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-cliente-endereco?${this.produtoId}&${params.toQueryString(false)}`,
      {
        responseType: 'blob'
      },
    );
  }

  imprimirRelatorioDeFaturamentoGeral(params: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-de-faturamento-geral?${this.produtoId}&${params.toQueryString(false)}`,
      {
        responseType: 'blob',
      },
    );
  }

  imprimirRelatorioDeContratosFinalizados(params: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-de-contratos-finalizados?${this.produtoId}&${params.toQueryString(false)}`,
      {
        responseType: 'blob',
      },
    );
  }

  getRelatorioContratoPorSituacao(queryOption: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/get-relatorio-contrato-por-situacao?${queryOption.toQueryString()}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  getRelatorioContratoProrrogacao(queryOption: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-contrato-prorrogacao?${queryOption.toQueryString()}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  getRelatorioHistoricoDeRepasses(params: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-historico-de-repasses?${this.produtoId}&${params.toQueryString(false)}`,
      {
        responseType: 'blob',
      },
    );
  }

  getRelatorioParcelasRecebidasAReceber(queryOption: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-sig-cobrancas?${queryOption.toQueryString()}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  gerarRelatorioSituacaoContrato(queryOption: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-situacao-contrato?${queryOption.toQueryString()}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioDeFaltasESobras(queryOption: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-sobras-e-faltas?${queryOption.toQueryString()}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioDeClientesInativos(queryOption: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-clientes-inativos?${queryOption.toQueryString()}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioDeNovosClientes(queryOption: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-clientes-novos?${queryOption.toQueryString()}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioDeContratosJuridicosFinalizados(queryOption: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-contratos-juridicos-finalizados?${queryOption.toQueryString()}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }
  
      relatorioDeClientesReativados(queryOption: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-clientes-reativados?${queryOption.toQueryString()}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }


  relatorioDeClientesInadimplemtesIregulares(queryOption: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-clientes-inadimplentes-irregulares?${queryOption.toQueryString()}&${this.produtoId
      }`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioDeClientesInadimplentesRegulares(queryOption: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-clientes-inadimplentes-regulares?${queryOption.toQueryString()}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioDecobrancaSemanal(queryOption: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-cobranca-semanal?${queryOption.toQueryString(false)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioMediaRepasses(queryOption: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-media-repasses?${queryOption.toQueryString()}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  // tslint:disable-next-line: no-any
  getMotivoDesistenciaContratosPorPeriodo(queryOption: QueryOptions): Observable<any> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-de-motivo-desistencia-todos?${queryOption.toQueryString(false)}&${this.produtoId}`,
    );
  }

  relatorioContratosRBM(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-contratos-rbm?${options.toQueryString()}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioDeCobrancasSabado(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-cobranca-sabado?${options.toQueryString()}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioEficiencia(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/gerar-relatorio-eficiencia?${options.toQueryString(false)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioDeContratoParado(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-contrato-parado?${options.toQueryString()}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioDespesasFuncionarios(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${FuncionarioService.RESOURCE}/gerar-relatorio-despesa-funcionario?${options.toQueryString(false)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioCaixaDivida(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${CaixaService.RESOURCE}/gerar-relatorio-caixa-divida?${options.toQueryString(false)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  alterarResponsavelRota(responsavelDTO: ResponsavelDTO): Observable<ReturnAPI<Contrato>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<Contrato>>(
      `${WEB_API}/${ContratoService.RESOURCE}/alterar-responsavel-rota?${this.produtoId}`,
      responsavelDTO,
    );
  }

  relatorioClientesInadimplentesPorPosto(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-de-clientes-inadimplentes-por-posto?${options.toQueryString(false)}&${this.produtoId
      }`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioClientesInadimplentesPorRota(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/gerar-relatorio-de-situacao-de-clientes-por-rota?${options.toQueryString(false)}&${this.produtoId
      }`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioDeCobrancaMensal(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/imprimir-relatorio-cobranca-mensal?${options.toQueryString(false)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioDespesaTipoEmpresa(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/imprimir-relatorio-despesa-tipo-empresa?${options.toQueryString(false)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioProximosLiquidacao(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/gerar-relatorio-aproximacao-de-liquidacao?${options.toQueryString(false)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioRepassesSemanal(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/imprimir-relatorio-repasses-semanal?${options.toQueryString(false)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioContratosPorCliente(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/imprimir-relatorio-contrato-por-cliente?${options.toQueryString(false)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioCobrancasTurno(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-cobrancas-turno?${options.toQueryString(false)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  gerarRelatorioEficienciaCobrador(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-eficiencia-cobrador?${options.toQueryString(false)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  gerarRelatorioPeriodosRecebimento(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-periodo-recebimentos?${options.toQueryString(false)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  listarCobrancasClientesRegularesfimDeSemana(): Observable<any> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<any>>(`${WEB_API}/${ContratoService.RESOURCE}/listar-cliente-regular-fds?${this.produtoId}`);
  }

  enviarCobrancaClienteFDS(idParcela: number): Observable<any> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<any>>(
      `${WEB_API}/${TransferenciaService.RESOURCE}/cobranca-cliente-regular-fds/${idParcela}?${this.produtoId}`,
      null,
    );
  }

  alterarValorContratoEmAndamento(options: QueryOptions, contratoAlterado: AlterarValorContratoDTO): Observable<ReturnAPI<Contrato>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<Contrato>>(
      `${WEB_API}/${ContratoService.RESOURCE}/alterar-valor-contrato-andamento?${options.toQueryString(false)}&${this.produtoId}`,
      contratoAlterado,
    );
  }

  gerarRelatorioAcompanhamentoOperacional(parametros: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.post(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-acompanhamento-operacional?${this.produtoId}`,
      parametros.params,
      { responseType: 'blob' },
    );
  }

  relatorioReagendamentoContrato(queryOption: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-reagendamento-contrato?${queryOption.toQueryString()}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  dashboardRecebimentoSemanalHeimdall(estadoId, data): Observable<ReturnAPI<DashboardRecebimentoSemanalDto[][]>> {
    return this.httpClient.get<ReturnAPI<DashboardRecebimentoSemanalDto[][]>>(
      `${WEB_API}/${ContratoService.RESOURCE}/dashboardRecebimentoSemanalHeimdall?data=${data}&estadoId=${estadoId}`,
    );
  }
  // tslint:disable-next-line:no-any
  dashboardRecebimentoSemanalListarRecebimentos(estado: string, posto: string, rota: string, dia: string): Observable<ReturnAPI<any[]>> {
    // tslint:disable-next-line:no-any
    return this.httpClient.get<ReturnAPI<any[]>>(
      `${WEB_API}/${ContratoService.RESOURCE}/dashboardRecebimentoSemanalListarRecebimentos?estado=${estado}&posto=${posto}&rota=${rota}&diaSemana=${dia}`,
    );
  }

  relatorioAlteracaoValorContratos(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.post(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-alteracao-valor-contrato?${options.toQueryString(false)}&${this.produtoId}`,
      null,
      {
        responseType: 'blob',
      },
    );
  }

  calcularValorLiquido(calculoValorLiquidoTO: CalculoValorLiquidoDTO): Observable<ReturnAPI<number>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<number>>(
      `${WEB_API}/${ContratoService.RESOURCE}/calculo-liquido-contrato?${this.produtoId}`,
      calculoValorLiquidoTO,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      },
    );
  }

  deletarLote(arquivo: ArquivoImportacaoNovo): Observable<ReturnAPI<any[]>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<any[]>>(
      `${WEB_API}/${ContratoService.RESOURCE}/deletar-lote?${this.produtoId}&arquivoId=${arquivo.id}`,
    );
  }

  relatorioAssinaturasRepassesDigitais(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-assinatura-repasse-pix?${options.toQueryString(false)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioClientesSpc(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-clientes-spc?${options.toQueryString(false)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioParcelasAlteradas(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-parcelas-alteradas?${options.toQueryString(false)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  gerarParcelaByContrato(options: QueryOptions): Observable<ReturnAPI<Parcela>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<Parcela>>(
      `${WEB_API}/parcela/gerar-pacela-contrato?${this.produtoId}&${options.toQueryString(false)}`,
      null,
    );
  }

  relatorioCancelamentoContrato(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-cancelamento-contrato?${options.toQueryString(false)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  gerarPromocaoDias(contrato: GerarPromocaoDTO): Observable<ReturnAPI<GerarPromocaoDTO>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<GerarPromocaoDTO>>(
      `${WEB_API}/${ContratoService.RESOURCE}/adicionar-promocao-dias?${this.produtoId}`,
      contrato,
    );
  }

  relatorioContratosLiquidados(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-contratos-liquidados?${options.toQueryString(false)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioContratosRepactuados(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-contratos-repactuados?${options.toQueryString(false)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioCobrancaRepactuacoes(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-cobranca-repactuacoes?${options.toQueryString(false)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioContratosGeradosRepactuacoes(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-contratos-gerados-repactuacoes?${options.toQueryString(false)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  alterarParcela(alterarParcelaDTO: AlterarParcelaDTO): Observable<ReturnAPI<Parcela>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<Parcela>>(
      `${WEB_API}/parcela/alterar-pacela-contrato?${this.produtoId}`, alterarParcelaDTO,
    );
  }

  relatorioContratosPorPeriodo(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorioPeriodo?${options.toQueryString(false)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  relatorioDeContratosNovosRenovadosReativados(params: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${ContratoService.RESOURCE}/relatorio-de-contratos-novos-renovados-reativados?${this.produtoId}&${params.toQueryString(false)}`,
      {
        responseType: 'blob',
      },
    );
  }

}
