import { Component, Input } from '@angular/core';
import { Contrato } from 'src/app/base/domain/contrato.model';
import { ContratoService } from 'src/app/base/services/contrato.service';
import { DialogService } from '../../services/dialog.service';
import { ReturnAPI } from 'src/app/base/domain/return-api.model';
import { AppState } from 'src/app/App.state';
import { AlterarContratoFormComponent } from '../../forms/alterar-contrato-form/alterar-contrato-form.component';
import { AlterarContratoDTO } from 'src/app/base/domain/dto/alterar-contrato-dto';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-analise-renovacao-contrato',
  templateUrl: './analise-renovacao-contrato.component.html',
  styleUrls: ['./analise-renovacao-contrato.component.css'],
})
export class AnaliseRenovacaoContratoComponent {
  @Input() contrato: Contrato;

  constructor(
    private contratoService: ContratoService,
    public dialogService: DialogService,
    public dialog: MatDialog,
    public appState: AppState,
  ) {}

  onAprovarRenovacao(): void {
    const dialogRef = this.dialog.open(AlterarContratoFormComponent, {
      data: {
        title: 'Confirmar Dados da Renovação',
        hideFields: ['dataEmissao', 'rota'],
        contrato: {
          ...this.contrato,
          valorBruto: this.contrato.analiseRenovacao?.valorContrato ?? 0,
          quantidadeParcelas: this.contrato.analiseRenovacao?.quantidadeParcelas ?? 0,
        },
      },
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((alterarContratoDTO: AlterarContratoDTO) => {
      if (alterarContratoDTO) {
        this.aprovarRenovacao(alterarContratoDTO.valorBruto, alterarContratoDTO.quantidadeParcelas);
      }
    });
  }

  private aprovarRenovacao(valorContrato: number, quantidadeParcelas: number): void {
    this.dialogService.confirmDialog('Deseja aprovar a renovação do contrato?', () => {
      this.appState.isLoading = true;
      this.contratoService.aprovarRenovacaoContrato(this.contrato.id, valorContrato, quantidadeParcelas).subscribe(
        (response: ReturnAPI<Contrato>) => {
          this.appState.isLoading = false;
          if (response.success) {
            this.contrato.analiseRenovacao.situacao = 'APROVADO';
          }
          this.dialogService.feedbackReturnAPI(response, 'Renovação aprovada com sucesso');
        },
        () => {
          this.appState.isLoading = false;
          this.dialogService.feedbackError('Houve um erro ao aprovar renovação!');
        },
      );
    });
  }

  onRelatorioRecebimentos(): void {
    this.appState.isLoading = true;
    this.contratoService.imprimirRecebimentos(this.contrato.id).subscribe(
      (data: Blob) => {
        this.appState.isLoading = false;
        const objectURL = URL.createObjectURL(data);
        window.open(objectURL, '_blank').print();
      },
      () => (this.appState.isLoading = false),
    );
  }

  generateMask(value: number = 0): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
