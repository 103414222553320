import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LazyLoadEvent } from 'primeng';
import { ConciliacaoBancariaDinheiro } from 'src/app/base/domain/conciliacao-bancaria-dinheiro.model';
import { EstadoSelectDTO } from 'src/app/base/domain/dto/estado-select.dto';
import { PostoSelectDTO } from 'src/app/base/domain/dto/posto-select.dto';
import { Page } from 'src/app/base/domain/return-api.model';
import { Rota } from 'src/app/base/domain/rota.model';
import { QueryOptions } from '../../domain/query.options';

export interface VisualizarConciliacaoBancariaDinheiroFilterOptions {
  dataInicial: Date;

  dataFinal: Date;

  estadoId: number;

  postoUID: string;

  processado: boolean;

  rotaId: number;
}

export interface ConciliacaoBancariaDinheiroEvent {
  acao: string;
  conciliacao: ConciliacaoBancariaDinheiro;
}

@Component({
  selector: 'app-visualizar-conciliacao-dinheiro-list',
  templateUrl: './visualizar-conciliacao-dinheiro-list.component.html',
  styleUrls: ['./visualizar-conciliacao-dinheiro-list.component.css'],
})
export class VisualizarConciliacaoDinheiroListComponent implements OnInit {
  @Output() filter = new EventEmitter<VisualizarConciliacaoBancariaDinheiroFilterOptions>();
  @Output() loadArquivosPage = new EventEmitter<number>();
  @Output() acaoClick = new EventEmitter<ConciliacaoBancariaDinheiroEvent>();
  @Output() menuAcaoClick = new EventEmitter<string>();
  @Output() novo = new EventEmitter<void>();

  filterOptions: VisualizarConciliacaoBancariaDinheiroFilterOptions;
  private readonly defaultFilterOptions: VisualizarConciliacaoBancariaDinheiroFilterOptions = {
    dataInicial: null,
    dataFinal: null,
    estadoId: null,
    postoUID: null,
    processado: false,
    rotaId: null,
  };

  @Input() page: Page<ConciliacaoBancariaDinheiro>;
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() loading: boolean;
  @Input() valorTotal: number;

  private currentPage = 0;
  queryOptions = new QueryOptions();

  public estados: EstadoSelectDTO[];
  public postos: PostoSelectDTO[];
  public rotas: Rota[];
  estadoId: number;

  constructor() {}

  ngOnInit(): void {
    this.onLimparFiltros();
  }

  loadArquivos(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber - 1;
    this.loadArquivosPage.emit(pageNumber);
  }

  onAtualizar(): void {
    this.loadArquivosPage.emit(this.currentPage);
  }

  onMenuAcaoClick(event: string): void {
    this.menuAcaoClick.emit(event);
  }

  onAcaoClick(acao: string, domain: ConciliacaoBancariaDinheiro): void {
    this.acaoClick.emit({ acao, conciliacao: domain });
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  onNovo(): void {
    this.novo.emit();
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
