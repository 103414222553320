import { Component, OnInit } from '@angular/core';
import { TipoDespesa } from 'src/app/base/domain/enum/tipo-despesa.enum';
import { Transferencia } from 'src/app/base/domain/transferencia.model';
import { AuditoriaDespesaDTO } from 'src/app/base/domain/dto/auditoria-despesa.dto';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import { HttpErrorResponse } from '@angular/common/http';
import {
  TransferenciaAcaoEvent,
  TransferenciaListFilterOptions,
} from 'src/app/global/lists/transferencia-list/transferencia-list.component';
import { Caixa } from 'src/app/base/domain/caixa.model';
import { Params, ActivatedRoute } from '@angular/router';
import { DialogService } from 'src/app/global/services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { TransferenciaService } from 'src/app/base/services/transferencia.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { CategoriaDespesa } from 'src/app/base/domain/enum/categoria-despesa.enum';
import { DespesaDTO } from 'src/app/base/domain/dto/despesa.dto';
import { DespesaRouboFormComponent } from 'src/app/global/forms/alterar-despesa-roubo-form/despesa-roubo-form.component';
import { ValoresTransferenciaRequestDTO } from 'src/app/base/domain/dto/valores-transferencia-request.dto';

@Component({
  selector: 'app-acompanhamento-casos-roubo-main',
  templateUrl: './acompanhamento-casos-roubo-main.component.html',
  styleUrls: ['./acompanhamento-casos-roubo-main.component.css'],
})
export class AcompanhamentoCasosRouboMainComponent implements OnInit {
  private static CONFIRMAR_CASO_ROUBO_ACTION = 'Confirmar Caso de Roubo';
  private static CANCELAR_CASO_ROUBO_ACTION = 'Cancelar Caso de Roubo';
  private static ALTERAR_CASO_ROUBO_ACTION = 'Alterar Caso de Roubo';
  private static DIVIDIR_CASO_ROUBO_ACTION = 'Dividir Despesa de Roubo';

  private queryOptions = new QueryOptions({ pageSize: 25 });

  caixaId: number;
  transferencias: Transferencia[];
  transferenciasCobrancaDespesaFuncionario: Transferencia[];
  transferenciasGeralDespesaFuncionario: Transferencia[];
  totalRecords = 0;
  loading = false;
  caixa: Caixa;
  private postoSelecionado: number = null;
  optionalColumns = ['descricao', 'categoria', 'funcionario', 'acoes', 'situacao'];

  totalizador = 0;

  constructor(
    private route: ActivatedRoute,
    private transferenciaService: TransferenciaService,
    public dialog: MatDialog,
    public dialogService: DialogService,
  ) {
    this.route.params.subscribe((params: Params) => {
      this.caixaId = params.id;
    });
  }

  ngOnInit(): void {
    this.queryOptions.params = {
      type: '',
      categoria: `in:ROUBO`,
      situacao: `in:CONFIRMADA_FINANCEIRO,REGULARIZADA,CONFIRMADA_NA_PENDENCIA`,
    };
  }

  onLoadTransferencias(event: number): void {
    this.list(event);
  }

  mapearAcoesDeTransferencias(transferencias: Transferencia[]): void {
    const mapearAcoes = (transferencia: Transferencia): Transferencia => {
      const acoes = [];
      if (transferencia.situacao === 'CONFIRMADA_FINANCEIRO') {
        acoes.push(AcompanhamentoCasosRouboMainComponent.CONFIRMAR_CASO_ROUBO_ACTION);
        acoes.push(AcompanhamentoCasosRouboMainComponent.CANCELAR_CASO_ROUBO_ACTION);
        acoes.push(AcompanhamentoCasosRouboMainComponent.ALTERAR_CASO_ROUBO_ACTION);
        acoes.push(AcompanhamentoCasosRouboMainComponent.DIVIDIR_CASO_ROUBO_ACTION);
      }
      if (transferencia.situacao === 'REGULARIZADA') {
        acoes.push(AcompanhamentoCasosRouboMainComponent.CANCELAR_CASO_ROUBO_ACTION);
        acoes.push(AcompanhamentoCasosRouboMainComponent.ALTERAR_CASO_ROUBO_ACTION);
      }
      return { ...transferencia, acoes };
    };

    this.transferencias = transferencias?.map(mapearAcoes) ?? [];
  }

  list(page: number = 0, valores: Partial<ValoresTransferenciaRequestDTO> = {}): void {
    this.queryOptions.pageNumber = page + 1;
    this.loading = true;
    this.queryOptions.pageSize = 15;

    this.transferenciaService.pageRouboGraphQL(this.queryOptions).subscribe((response: any) => {
      this.totalRecords = this.totalRecords + response.data.listarTransferenciasTipoRoubo.totalElements;
      this.loading = false;
      valores.dataInicial = valores.dataInicial ? valores.dataInicial : null;
      valores.dataFinal = valores.dataFinal ? valores.dataFinal : null;
      valores.nome = valores.nome ? valores.nome : '';
      valores.descricaoRota = valores.descricaoRota ? valores.descricaoRota : '';
      this.mapearAcoesDeTransferencias(response.data.listarTransferenciasTipoRoubo.content);
      this.getValorTotalPorTipoTransferencia({ ...valores, postoId: this.postoSelecionado });
    });
  }

  onAcaoClick(event: TransferenciaAcaoEvent): void {
    const actions = new Map<string, (transferencia: Transferencia) => void>()
      .set(AcompanhamentoCasosRouboMainComponent.CONFIRMAR_CASO_ROUBO_ACTION, (transferencia: Transferencia) => {
        this.onRegularizarDespesaRoubo(transferencia);
      })
      .set(AcompanhamentoCasosRouboMainComponent.CANCELAR_CASO_ROUBO_ACTION, (transferencia: Transferencia) => {
        this.onCancelarDespesaRoubo(transferencia);
      })
      .set(AcompanhamentoCasosRouboMainComponent.ALTERAR_CASO_ROUBO_ACTION, (transferencia: Transferencia) => {
        this.onAlterarDespesaRoubo(transferencia);
      })
      .set(AcompanhamentoCasosRouboMainComponent.DIVIDIR_CASO_ROUBO_ACTION, (transferencia: Transferencia) => {
        this.onDividirDespesaRoubo(transferencia);
      });

    actions.get(event.acao)(event.transferencia);
  }

  onRegularizarDespesaRoubo(transferencia: Transferencia): void {
    this.dialogService.inputDialog((response: TipoDespesa) => {
      this.dialogService.auditoriaDialog(() => {
        const auditoriaDespesa = {
          categoria: CategoriaDespesa.ROUBO,
          tipoDespesa: response,
          transferenciaId: transferencia.id,
          valor: transferencia.valorRealizado,
        };
        this.regularizarDespesaRoubo(auditoriaDespesa);
        return response;
      });

      return null;
    });
  }

  onAlterarDespesaRoubo(transferencia: Transferencia): void {
    this.dialogService.auditoriaDialog(() => {
      this.dialog
        .open(DespesaRouboFormComponent, {
          width: '400px',
          data: {
            transferencia: transferencia,
            action: 'alterar-despesa',
          },
        })
        .afterClosed()
        .subscribe((despesaDTO: DespesaDTO) => {
          if (despesaDTO) {
            this.transferenciaService.alterarDespesaRoubo(despesaDTO).subscribe(
              (response: ReturnAPI<void>) => {
                this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
                this.list();
              },
              (e: HttpErrorResponse) => {
                this.dialogService.feedbackError(`Houve um erro ao alterar Roubo.\n ${e.message}`);
              },
            );
          }
        });
    });
  }

  onDividirDespesaRoubo(transferencia: Transferencia): void {
    this.dialogService.auditoriaDialog(() => {
      this.dialog
        .open(DespesaRouboFormComponent, {
          width: '400px',
          data: {
            transferencia: transferencia,
            action: 'dividir-despesa',
          },
        })
        .afterClosed()
        .subscribe((despesaDTO: DespesaDTO) => {
          if (despesaDTO) {
            this.transferenciaService.dividirDespesaRoubo(despesaDTO).subscribe(
              (response: ReturnAPI<void>) => {
                this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
                this.list();
              },
              (e: HttpErrorResponse) => {
                this.dialogService.feedbackError(`Houve um erro ao dividir despesa roubo.\n ${e.message}`);
              },
            );
          }
        });
    });
  }

  onCancelarDespesaRoubo(transferencia: Transferencia): void {
    this.dialogService.confirmDialog('cancelar esse caso de roubo?', () => {
      this.dialogService.auditoriaDialog(() => {
        this.cancelarDespesaRoubo(transferencia.id);
      });
    });
  }

  cancelarDespesaRoubo(transferenciaId: number): void {
    this.transferenciaService.cancelarDespesaRoubo(transferenciaId).subscribe(
      (response: ReturnAPI<void>) => {
        this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
        this.list();
      },
      (e: HttpErrorResponse) => {
        this.dialogService.feedbackError(`Houve um erro ao cancelar Roubo.\n ${e.message}`);
      },
    );
  }

  regularizarDespesaRoubo(auditoriaDespesa: AuditoriaDespesaDTO): void {
    this.transferenciaService.regularizarDespesaRoubo(auditoriaDespesa).subscribe(
      (response: ReturnAPI<AuditoriaDespesaDTO>) => {
        this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
        this.list();
      },
      (e: HttpErrorResponse) => {
        this.dialogService.feedbackError(`Houve um erro ao regularizar Roubo.\n ${e.message}`);
      },
    );
  }

  onFilter(options: TransferenciaListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    const dataInicial = options.dataInicial || null;
    const dataFinal = options.dataFinal || null;

    this.queryOptions.params = {
      ...this.queryOptions.params,
      nomeFuncionario: options.nome || null,
      banco: options.banco || null,
      conta: options.conta || null,
      agencia: options.agencia || null,
      situacao: options.situacoesTransferencia?.length ? `in:${options.situacoesTransferencia.join(',')}` : null,
      dataInicio: dataInicial?.toLocaleString(),
      dataFinal: dataFinal?.toLocaleString(),
      postoId,
      rota: options.descricaoRota || null,
    };

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }

    this.list(0, {
      nome: options.nome,
      descricaoRota: options.descricaoRota,
      dataInicial: options?.dataInicial,
      dataFinal: options?.dataFinal,
    });
  }

  getValorTotalPorTipoTransferencia(valores: Partial<ValoresTransferenciaRequestDTO>): void {
    this.transferenciaService.getValorTotalCasosRoubo(valores).subscribe((response: ReturnAPI<number>) => {
      this.totalizador = response.object || 0;
    });
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
