import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { ArquivoConciliacaoBancaria } from '../domain/arquivo-conciliacao-bancaria.model';
import { ConciliacaoBancaria } from '../domain/conciliacao-bancaria.model';
import { Parcela } from '../domain/parcela.model';
import { ReturnAPI } from '../domain/return-api.model';
import { ParcelaSerializer } from '../serializables/parcela.serializer';

@Injectable({
  providedIn: 'root',
})
export class ParcelaService extends ResourceService<Parcela> {
  static RESOURCE: any = 'parcela';

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, 'parcela', new ParcelaSerializer(Parcela));
  }

  public corrigirPagamento(domain: any): Observable<ReturnAPI<Parcela>> {
    return this.httpClient.post<ReturnAPI<Parcela>>(`${WEB_API}/${ParcelaService.RESOURCE}/corrigir-pagamento`, domain);
  }

  public corrigirPagamentoGeral(data: Date): Observable<ReturnAPI<Parcela>> {
    const dataLong = data.getTime();
    return this.httpClient.post<ReturnAPI<Parcela>>(
      `${WEB_API}/${ParcelaService.RESOURCE}/corrigir-pagamento-geral?data=${dataLong}`,
      null,
    );
  }
}
