import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalModule } from 'src/app/global/global.module';
import { ClientesInativosMainComponent } from './clientes-inativos-main/clientes-inativos-main.component';
import { ClientesInativosDetailComponent } from './clientes-inativos-detail/clientes-inativos-detail.component';

@NgModule({
  declarations: [ClientesInativosMainComponent, ClientesInativosDetailComponent],
  imports: [CommonModule, GlobalModule],
})
export class ClientesInativosModule {}
