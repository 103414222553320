import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnaliseDeRiscoClienteMainComponent } from './analise-de-risco-cliente-main/analise-de-risco-cliente-main.component';
import { GlobalModule } from 'src/app/global/global.module';

@NgModule({
  declarations: [AnaliseDeRiscoClienteMainComponent],
  imports: [CommonModule, GlobalModule],
})
export class AnaliseDeRiscoClienteModule {}
