import { PostoService } from '../../../base/services/posto.service';
import { PostoSelectDTO } from '../../../base/domain/dto/posto-select.dto';
import { EstadoSelectDTO } from '../../../base/domain/dto/estado-select.dto';
import { EstadoService } from 'src/app/base/services/estado.service';
import { LazyLoadEvent } from 'primeng/api';
import { QueryOptions } from '../../domain/query.options';
import { Page } from '../../../base/domain/return-api.model';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { RotaService } from 'src/app/base/services/rota.service';
import { Rota } from 'src/app/base/domain/rota.model';
import { ConciliacaoBancariaTev } from '../../../base/domain/conciliacao-bancaria-tev.model';

export interface VisualizarConciliacaoBancariaTevFilterOptions {
  dataInicial: Date;

  dataFinal: Date;

  estadoId: number;

  postoUID: string;

  processado: boolean;

  rotaId: number;
}

export interface ConciliacaoBancariaTevEvent {
  acao: string;
  conciliacao: ConciliacaoBancariaTev;
}

@Component({
  selector: 'app-visualizar-conciliacao-tev-list',
  templateUrl: './visualizar-conciliacao-tev-list.component.html',
  styleUrls: ['./visualizar-conciliacao-tev-list.component.css'],
})
export class VisualizarConciliacaoTevListComponent implements OnInit {
  @Output() filter = new EventEmitter<VisualizarConciliacaoBancariaTevFilterOptions>();
  @Output() loadArquivosPage = new EventEmitter<number>();
  @Output() acaoClick = new EventEmitter<ConciliacaoBancariaTevEvent>();
  @Output() menuAcaoClick = new EventEmitter<string>();

  public static CORRIGIR_PAGAMENTO_DIA = 'Corrigir pagamento por dia';
  menuAcoes: string[] = ['Corrigir pagamento por dia'];

  filterOptions: VisualizarConciliacaoBancariaTevFilterOptions;
  private readonly defaultFilterOptions: VisualizarConciliacaoBancariaTevFilterOptions = {
    dataInicial: null,
    dataFinal: null,
    estadoId: null,
    postoUID: null,
    processado: false,
    rotaId: null,
  };

  @Input() page: Page<ConciliacaoBancariaTev>;
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() loading: boolean;
  @Input() valorTotalEntrada: number;
  @Input() valorTotalSaida: number;

  private currentPage = 0;
  queryOptions = new QueryOptions();

  public estados: EstadoSelectDTO[];
  public postos: PostoSelectDTO[];
  public rotas: Rota[];
  estadoId: number;

  @Output() novo = new EventEmitter<void>();

  constructor(private estadoService: EstadoService, private postoService: PostoService, private rotaService: RotaService) {}

  ngOnInit(): void {
    this.popularEstados();
    this.popularPostos();
    this.onLimparFiltros();
  }

  loadArquivos(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber - 1;
    this.loadArquivosPage.emit(pageNumber);
  }

  onAtualizar(): void {
    this.loadArquivosPage.emit(this.currentPage);
  }

  onMenuAcaoClick(event: string): void {
    this.menuAcaoClick.emit(event);
  }

  onAcaoClick(acao: string, domain: ConciliacaoBancariaTev): void {
    this.acaoClick.emit({ acao, conciliacao: domain });
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  estadoSelecionadoEvent(estadoId: number) {
    if (estadoId) {
      this.popularPostos(estadoId);
    } else {
      this.filterOptions.postoUID = null;
      this.postos = [];
    }
  }

  postoSelecionadoEvent(postoId: string) {
    if (postoId) {
      this.popularRotas(postoId);
    } else {
      this.filterOptions.rotaId = null;
      this.rotas = [];
    }
  }

  rotaSelecionadoEvent(rotaId: number) {
    if (!rotaId) {
      this.filterOptions.rotaId = null;
    }
  }

  private popularEstados() {
    this.estadoService.getSelect().subscribe((estados) => {
      this.estados = estados;
    });
  }

  private popularPostos(estadoId?: number) {
    this.estadoId = estadoId;
    this.postoService.getSelect(estadoId).subscribe((postos) => {
      this.filterOptions.postoUID = null;
      this.postos = postos;
    });
  }

  private popularRotas(postoUid?: string) {
    this.rotaService.listarRotasPorEstadoEPostoUID(this.estadoId, postoUid).subscribe((rotas) => {
      this.filterOptions.rotaId = null;
      this.rotas = rotas.object;
    });
  }
  
  onNovo(): void {
    this.novo.emit();
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
