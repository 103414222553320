import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppState } from 'src/app/App.state';
import { CarteiraCliente } from 'src/app/base/domain/carteira-cliente.model';
import { Funcionario } from 'src/app/base/domain/funcionario.model';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { CarteiraClienteService } from 'src/app/base/services/carteira-cliente.service';
import { FuncionarioService } from 'src/app/base/services/funcionario.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { CarteiraClienteFormComponent } from 'src/app/global/forms/carteira-cliente-form/carteira-cliente-form.component';
import { FuncionarioFormComponent } from 'src/app/global/forms/funcionario-form/funcionario-form.component';
import { CarteiraClienteAcaoEvent } from 'src/app/global/lists/carteira-cliente-list/carteira-cliente-list.component';
import { FuncionarioAcaoEvent, FuncionarioMenuAcaoEvent } from 'src/app/global/lists/funcionario-list/funcionario-list.component';
import { DialogService } from 'src/app/global/services/dialog.service';
import { AcompanhamentoVendedorModule } from '../acompanhamento-vendedor.module';

@Component({
  selector: 'app-acompanhamento-vendedor-externo-detail',
  templateUrl: './acompanhamento-vendedor-detail.component.html',
  styleUrls: ['./acompanhamento-vendedor-detail.component.css'],
})
export class AcompanhamentoVendedorExternoDetailComponent implements OnInit {
  static NOVA_CARTEIRA = 'Nova Carteira Cliente';
  static DISCLOSURE = 'disclosure';
  opcoes: string[] = [];
  funcionario: Funcionario;

  carteiras: CarteiraCliente[] = [];
  funcionarioId: number;

  menuAcoes = [AcompanhamentoVendedorExternoDetailComponent.NOVA_CARTEIRA];

  queryOptions = new QueryOptions({ pageSize: 10, pageSort: '-dataCadastro' });
  totalRecords = 0;

  constructor(
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private carteiraClienteService: CarteiraClienteService,
    private dialogService: DialogService,
    public appState: AppState,
    private funcionarioService: FuncionarioService,
    private router: Router,
  ) {
    this.route.params.subscribe((params: Params) => {
      this.funcionarioId = params.id;
    });
  }

  ngOnInit(): void {
    this.listarCarteirasCliente();
  }

  listarCarteirasCliente(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.appState.isLoading = true;
    this.queryOptions.params = { ...this.queryOptions.params, funcionarioId: this.funcionarioId };
    this.carteiraClienteService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<CarteiraCliente>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.mapearAcoes(response.object.content);
      } else {
        this.carteiras = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.appState.isLoading = false;
    });
  }

  mapearAcoes(carteiras: CarteiraCliente[]): void {
    const mapearAcoes = (carteiraCliente: CarteiraCliente): CarteiraCliente => {
      const acoes = [];
      acoes.push(AcompanhamentoVendedorExternoDetailComponent.DISCLOSURE);
      return { ...carteiraCliente, acoes };
    };

    this.carteiras = carteiras?.map(mapearAcoes) ?? [];
  }

  exibirFormularioParaNovoOperador(): void {
    const dialog = this.matDialog.open(CarteiraClienteFormComponent, {
      data: {
        titulo: 'Nova Carteira de Cliente',
        funcionario: {
          id: this.funcionarioId,
        },
      },
      width: '300px',
    });
    dialog.afterClosed().subscribe((carteira: CarteiraCliente) => {
      this.adicionarNovaCarteiraCliente(carteira);
    });
  }

  adicionarNovaCarteiraCliente(carteira: CarteiraCliente): void {
    this.appState.isLoading = true;
    if (carteira) {
      this.carteiraClienteService.save(carteira).subscribe(
        (response: ReturnAPI<CarteiraCliente>) => {
          this.dialogService.feedbackReturnAPI(response, 'Nova Carteira de Cliente cadastrada com sucesso');
          if (response.success) {
            this.listarCarteirasCliente();
          }
          this.appState.isLoading = false;
        },
        () => {
          this.dialogService.feedbackError('Erro ao cadastrar nova carteira de cliente');
          this.appState.isLoading = false;
        },
      );
    } else {
      this.appState.isLoading = false;
    }
  }

  onPage(page: number): void {
    this.listarCarteirasCliente(page);
  }

  onAcaoClick(event: CarteiraClienteAcaoEvent): void {
    const actions = new Map<string, (carteiraCliente: CarteiraCliente) => void>().set(
      AcompanhamentoVendedorExternoDetailComponent.DISCLOSURE,
      (carteiraCliente: CarteiraCliente) => {
        this.onDisclosure(carteiraCliente);
      },
    );
    actions.get(event.acao)(event.carteira);
  }

  onMenuAcaoClick(event: FuncionarioMenuAcaoEvent): void {
    const actions = new Map<string, () => void>().set(
      AcompanhamentoVendedorExternoDetailComponent.NOVA_CARTEIRA,
      this.exibirFormularioParaNovoOperador.bind(this),
    );

    actions.get(event.acao)();
  }

  onDisclosure(carteira: CarteiraCliente): void {
    this.router.navigate(['base/gestao/vendedores-externos/carteira-cliente/' + carteira.id]);
  }
}
