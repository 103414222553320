import { Posto } from '../domain/posto.model';

export const updateCounterDescription = (dataInicial?: Date, dataFinal?: Date, posto?: Posto) => {
  let description = '';
  if (dataInicial && dataFinal) {
    description += `Entre ${dataInicial.toLocaleDateString()} e ${dataFinal.toLocaleDateString()}`;
  } else if (dataInicial) {
    description += `A partir de ${dataInicial.toLocaleDateString()}`;
  } else if (dataFinal) {
    description += `Até ${dataFinal.toLocaleDateString()}`;
  }

  description = description || 'Todos as Datas';
  const subDescription = `${posto ? posto.descricao : 'Todos os Postos'}`;

  return { description, subDescription };
};
