import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from 'src/app/App.state';
import { SharedService } from 'src/app/auth/shared.service';
import { ArquivoImportacaoNovo } from 'src/app/base/domain/arquivo-importacao-novo.model';
import { ValoresArquivoNovoRequestDTO } from 'src/app/base/domain/dto/valores-arquivo-novo-request-dto';
import { MessageAPI, Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { ArquivoImportacaoNovoService } from 'src/app/base/services/arquivo-importacao-novo.service';
import { ContratoService } from 'src/app/base/services/contrato.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import {
  ArquivoImportacaoAcaoEvent,
  ArquivoImportacaoNovoListFilterOptions,
} from 'src/app/global/lists/arquivo-importacao-novo-list/arquivo-importacao-novo-list.component';
import { DialogService } from 'src/app/global/services/dialog.service';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';

@Component({
  selector: 'app-titulos-novos-arquivo',
  templateUrl: './titulos-novos-arquivo.component.html',
  styleUrls: ['./titulos-novos-arquivo.component.css'],
})
export class TitulosNovosArquivosComponent implements OnInit {
  private static IMPRIMIR_LOTE = 'Imprimir Lote';
  private static VISUALIZAR_LOTE = 'Visualizar Lote';
  private static CANCELAR_LOTE = 'Cancelar Lote';
  arquivos: ArquivoImportacaoNovo[] = [];
  menuAcoes: string[] = [];

  shared: SharedService;
  usuario: Usuario;

  queryOptions = new QueryOptions({ pageSize: 20, pageSort: '-id' });
  totalRecords = 0;
  loading = false;
  private postoSelecionado: number = null;

  constructor(
    private router: Router,
    private arquivoNovoService: ArquivoImportacaoNovoService,
    private contratoService: ContratoService,
    private dialogService: DialogService,
    public dialog: MatDialog,
    public appState: AppState,
  ) {
    const shared = SharedService.getInstance();
    this.usuario = shared.usuario;
  }

  ngOnInit(): void {}

  listarArquivos(pageNumber: number = 0, valoresArquivo: Partial<ValoresArquivoNovoRequestDTO> = {}): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.arquivoNovoService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<ArquivoImportacaoNovo>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.mapearAcoes(response.object.content);
      } else {
        this.arquivos = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
      valoresArquivo.dataInicial = valoresArquivo.dataInicial ? valoresArquivo.dataInicial : null;
      valoresArquivo.dataFinal = valoresArquivo.dataFinal ? valoresArquivo.dataFinal : null;
    });
  }

  onArquivosPage(page: number): void {
    this.listarArquivos(page);
  }

  onFilter(options: ArquivoImportacaoNovoListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    this.queryOptions.params = {
      postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
    };

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }

    this.listarArquivos(0, {
      dataInicial: options?.dataInicial,
      dataFinal: options?.dataFinal,
    });
  }

  mapearAcoes(arquivos: ArquivoImportacaoNovo[]): void {
    const mapearAcoes = (arquivo: ArquivoImportacaoNovo): ArquivoImportacaoNovo => {
      const acoes = [];
      acoes.push(TitulosNovosArquivosComponent.IMPRIMIR_LOTE);
      acoes.push(TitulosNovosArquivosComponent.VISUALIZAR_LOTE);
      if (this.usuario.profile === 'ROLE_GESTOR' || this.usuario.profile === 'ROLE_ADMIN') {
        acoes.push(TitulosNovosArquivosComponent.CANCELAR_LOTE);
      }
      return { ...arquivo, acoes };
    };

    this.arquivos = arquivos?.map(mapearAcoes) ?? [];
  }

  onAcaoClick(event: ArquivoImportacaoAcaoEvent): void {
    const actions = new Map<string, (arquivo: ArquivoImportacaoNovo) => void>()
      .set(TitulosNovosArquivosComponent.IMPRIMIR_LOTE, (arquivo: ArquivoImportacaoNovo) => {
        this.imprimirLote(event.arquivo);
      })
      .set(TitulosNovosArquivosComponent.VISUALIZAR_LOTE, (arquivo: ArquivoImportacaoNovo) => {
        this.visualizarLote(event.arquivo);
      })
      .set(TitulosNovosArquivosComponent.CANCELAR_LOTE, (arquivo: ArquivoImportacaoNovo) => {
        this.dialogService.confirmDialog('Deseja realmente deletar esse lote?', () => this.deletarLote(event.arquivo));
      });

    actions.get(event.acao)(event.arquivo);
  }

  imprimirLote(arquivo: ArquivoImportacaoNovo): void {
    this.appState.isLoading = true;
    this.contratoService.getRelatorioContabilidade(arquivo).subscribe((response) => {
      if (response) {
        this.appState.isLoading = false;
        const hoje = new Date(arquivo.dataCadastro);
        FileSaver.saveAs(
          response,
          `relatorio-contabilidade-${arquivo.posto.descricao}-${moment(arquivo.dataInicial).format('DDMMYY')}_${moment(
            arquivo.dataFinal,
          ).format('DDMMYY')}.zip`,
        );
        this.listarArquivos();
      } else {
        this.appState.isLoading = false;
        this.listarArquivos();
      }
    });
  }

  visualizarLote(arquivo: ArquivoImportacaoNovo): void {
    this.router.navigate(['base/exportacao/titulo-novos-arquivos/' + arquivo.id]);
  }

  deletarLote(arquivo: ArquivoImportacaoNovo): void {
    this.appState.isLoading = true;
    this.contratoService.deletarLote(arquivo).subscribe((response) => {
      if (response) {
        if (response.success) {
          this.appState.isLoading = false;
          this.dialogService.feedbackSuccess('Lote excluído com sucesso');
          this.listarArquivos();
        } else {
          this.appState.isLoading = false;
          this.dialogService.feedbackInfo(response.messages.reduce((prev: string, msg: MessageAPI) => `${prev}\n${msg.text}`, ''));
          this.listarArquivos();
        }
      } else {
        this.appState.isLoading = false;
        this.dialogService.feedbackInfo('Erro ao excluir lote');
        this.listarArquivos();
      }
    });
  }
}
