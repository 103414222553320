import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from 'src/app/App.state';
import { Funcionario } from 'src/app/base/domain/funcionario.model';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { FuncionarioService } from 'src/app/base/services/funcionario.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { FuncionarioFormComponent } from 'src/app/global/forms/funcionario-form/funcionario-form.component';
import {
  FuncionarioAcaoEvent,
  FuncionarioListFilterOptions,
  FuncionarioMenuAcaoEvent,
} from 'src/app/global/lists/funcionario-list/funcionario-list.component';
import { DialogService } from 'src/app/global/services/dialog.service';
import { AcompanhamentoVendedorModule } from '../acompanhamento-vendedor.module';

@Component({
  selector: 'app-acompanhamento-vendedor-externo-main',
  templateUrl: './acompanhamento-vendedor-externo-main.component.html',
  styleUrls: ['./acompanhamento-vendedor-externo-main.component.css'],
})
export class AcompanhamentoVendedorExternoMainComponent implements OnInit {
  static NOVO_VENDEDOR = 'Novo Vendedor';
  static DISCLOSURE = 'disclosure';

  vendedores: Funcionario[] = [];

  menuAcoes = [AcompanhamentoVendedorExternoMainComponent.NOVO_VENDEDOR];

  queryOptions = new QueryOptions({ pageSize: 10 });
  totalRecords = 0;

  private postoSelecionado: number = null;

  constructor(
    private matDialog: MatDialog,
    private funcionarioService: FuncionarioService,
    private dialogService: DialogService,
    public appState: AppState,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.listarVendedoresExternos();
  }

  listarVendedoresExternos(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.appState.isLoading = true;
    this.queryOptions.params = { ...this.queryOptions.params, cargosDescricao: 'VENDEDOR EXTERNO' };
    this.funcionarioService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Funcionario>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.mapearAcoes(response.object.content);
      } else {
        this.vendedores = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.appState.isLoading = false;
    });
  }

  mapearAcoes(funcionarios: Funcionario[]): void {
    const mapearAcoes = (funcionario: Funcionario): Funcionario => {
      const acoes = [];
      acoes.push(AcompanhamentoVendedorExternoMainComponent.DISCLOSURE);
      return { ...funcionario, acoes };
    };

    this.vendedores = funcionarios?.map(mapearAcoes) ?? [];
  }

  exibirFormularioParaNovoOperador(): void {
    const dialog = this.matDialog.open(FuncionarioFormComponent, {
      data: {
        titulo: 'Novo Vendedor',
        cargoFixo: 'VENDEDOR EXTERNO',
      },
      width: '1000px',
    });
    dialog.afterClosed().subscribe((funcionario: Funcionario) => {
      this.adicionarNovoVendedorExterno(funcionario);
    });
  }

  adicionarNovoVendedorExterno(funcionario: Funcionario): void {
    this.appState.isLoading = true;
    if (funcionario) {
      this.funcionarioService.insert(funcionario).subscribe(
        (response: ReturnAPI<Funcionario>) => {
          this.dialogService.feedbackReturnAPI(response, 'Novo Vendedor Externo cadastrado com sucesso');
          if (response.success) {
            this.listarVendedoresExternos();
          }
          this.appState.isLoading = false;
        },
        () => {
          this.dialogService.feedbackError('Erro ao cadastrar novo vendedor externo');
          this.appState.isLoading = false;
        },
      );
    } else {
      this.appState.isLoading = false;
    }
  }

  onPage(page: number): void {
    this.listarVendedoresExternos(page);
  }

  onAcaoClick(event: FuncionarioAcaoEvent): void {
    const actions = new Map<string, (funcionario: Funcionario) => void>().set(
      AcompanhamentoVendedorExternoMainComponent.DISCLOSURE,
      (funcionario: Funcionario) => {
        this.onDisclosure(funcionario);
      },
    );
    actions.get(event.acao)(event.funcionario);
  }

  onMenuAcaoClick(event: FuncionarioMenuAcaoEvent): void {
    const actions = new Map<string, () => void>().set(
      AcompanhamentoVendedorExternoMainComponent.NOVO_VENDEDOR,
      this.exibirFormularioParaNovoOperador.bind(this),
    );

    actions.get(event.acao)();
  }

  onDisclosure(funcionario: Funcionario): void {
    this.router.navigate(['base/gestao/vendedores-externos/' + funcionario.id]);
  }

  onFilter(options: FuncionarioListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    this.queryOptions.params = {
      postoId,
      nomeFuncionario: options.nome ? `${options.nome}` : null,
    };
    this.listarVendedoresExternos();
    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }
  }
}
