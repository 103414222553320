import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QueryOptions } from '../../domain/query.options';
import { LazyLoadEvent } from 'primeng/api';
import { PlanejamentoSemanaCaptacao } from 'src/app/base/domain/planejamento-semana-captacao.model';

export interface PlanejamentoSemanaCaptacaoAcaoEvent {
  acao: string;
  planejamento: PlanejamentoSemanaCaptacao;
}

@Component({
  selector: 'app-planejamento-semana-captacao-list',
  templateUrl: './planejamento-semana-captacao-list.component.html',
  styleUrls: ['./planejamento-semana-captacao-list.component.css'],
})
export class PlanejamentoSemanaCaptacaoListComponent implements OnInit {
  @Input() title: string;
  @Input() nomePlaceholder: string;
  @Input() rowsNumber: number;
  @Input() totalRecords: number;
  @Input() loading: boolean;
  @Input() filtros: string[];
  @Input() options: string[];
  @Input() planejamentos: PlanejamentoSemanaCaptacao[];

  @Output() novo = new EventEmitter<void>();
  @Output() loadPage = new EventEmitter<number>();
  @Output() acaoClick = new EventEmitter<PlanejamentoSemanaCaptacaoAcaoEvent>();

  private currentPage = 0;

  queryOptions = new QueryOptions();

  constructor() {}

  ngOnInit(): void {}

  load(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadPage.emit(pageNumber);
  }

  onAcaoClick(acao: string, domain: PlanejamentoSemanaCaptacao): void {
    this.acaoClick.emit({ acao, planejamento: domain });
  }

  onAtualizar(): void {
    this.loadPage.emit(this.currentPage);
  }

  onNovo(): void {
    this.novo.emit();
  }
}
