import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CategoriaPlanoConta } from 'src/app/base/domain/categoria-plano-conta.model';
import { Posto } from 'src/app/base/domain/posto.model';
import { QueryOptions } from '../../domain/query.options';
import { LazyLoadEvent } from 'primeng/api';

export interface CategoriaPlanoContaAcaoEvent {
  acao: string;
  categoriaPlanoConta: CategoriaPlanoConta;
}

export interface CategoriaPlanoContaListFilterOptions {
  codigo: string;
  descricao: string;
  descricaoPai: string;
}

@Component({
  selector: 'app-categoria-plano-conta-list',
  templateUrl: './categoria-plano-conta-list.component.html',
  styleUrls: ['./categoria-plano-conta-list.component.css'],
})
export class CategoriaPlanoContaListComponent implements OnInit {
  @Input() title: string;
  @Input() categorias: CategoriaPlanoConta[];
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() filtros: string[];
  @Input() options: string[];

  @Output() novo = new EventEmitter<void>();
  @Output() filter = new EventEmitter<CategoriaPlanoContaListFilterOptions>();
  @Output() loadCategoriaPlanoContasPage = new EventEmitter<number>();
  @Output() acaoClick = new EventEmitter<CategoriaPlanoContaAcaoEvent>();
  @Output() disclosureCategoriaPlanoConta = new EventEmitter<CategoriaPlanoConta>();

  private currentPage = 0;
  queryOptions = new QueryOptions();

  private readonly defaultFilterOptions: CategoriaPlanoContaListFilterOptions = {
    codigo: '',
    descricao: '',
    descricaoPai: '',
  };

  filterOptions: CategoriaPlanoContaListFilterOptions;

  ngOnInit(): void {
    this.onLimparFiltros();
  }

  loadCategoriaPlanoContas(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadCategoriaPlanoContasPage.emit(pageNumber);
  }

  onAcaoClick(acao: string, domain: CategoriaPlanoConta): void {
    this.acaoClick.emit({ acao, categoriaPlanoConta: domain });
  }

  onAtualizar(): void {
    this.loadCategoriaPlanoContasPage.emit(this.currentPage);
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  onNovo(): void {
    this.novo.emit();
  }
}
