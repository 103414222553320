import { AppState } from './../../../../App.state';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DashboardCrecimentoSustentavel } from 'src/app/base/domain/dashboard-crescimento-sustentavel.model';
import { DashBoardEstadoFaturamento } from 'src/app/base/domain/dashboard-estado-faturamento.model';
import { PostoDTO } from 'src/app/base/domain/dto/posto.dto';
import { DashboardCrescimentoSustentavelService } from 'src/app/base/services/dashboard-crescimento-sustentavel.service';
import { DashBoardCrescimentoSustentavelFilterOptions } from 'src/app/global/crescimento-sustentavel-dashboard/crescimento-sustentavel-dashboard.component';
import { QueryOptions } from 'src/app/global/domain/query.options';

@Component({
  selector: 'app-crescimento-sustentavel-estado',
  templateUrl: './crescimento-sustentavel-estado.component.html',
  styleUrls: ['./crescimento-sustentavel-estado.component.css']
})
export class CrescimentoSustentavelEstadoComponent implements OnInit {

  queryOptions = new QueryOptions({ pageSize: 10 });

  estadoId: number;

  dashboard: DashboardCrecimentoSustentavel = new DashboardCrecimentoSustentavel();
  list: PostoDTO[] = [];

  constructor(
    private service: DashboardCrescimentoSustentavelService,
    private router: Router, private route: ActivatedRoute,
    private appState: AppState) {
    this.route.params.subscribe((params: Params) => {
      this.estadoId = params.id;
    });
    this.queryOptions.params = {
      ...this.queryOptions.params,
      estadoId: this.estadoId
    };
  }

  ngOnInit(): void {
    this.getDashBoardWithoutRefresh();
    this.getPostoList();
  }

  getDashBoard(): void {
    this.appState.isLoading = true;
    this.service.getDashboard(this.queryOptions).subscribe(
      (response: DashboardCrecimentoSustentavel) => {
        this.dashboard = response;
        this.appState.isLoading = false;
      }
    );
  }

  getDashBoardWithoutRefresh(): void {
    this.appState.isLoading = true;
    this.service.getDashboardWithoutRefresh(this.queryOptions).subscribe(
      (response: DashboardCrecimentoSustentavel) => {
        this.dashboard = response;
        this.appState.isLoading = false;
      }
    );
  }

  getPostoList(): void {
    this.service.getDashboardEstado(this.estadoId).subscribe(
      (response: DashBoardEstadoFaturamento) => {
        this.list = response.postos;
      }
    );
  }

  onFilter(event: DashBoardCrescimentoSustentavelFilterOptions): void {
    this.queryOptions.params = {
      ...this.queryOptions.params,
      dataCadastro: event.dataInicial.getTime()
    };
    this.getDashBoardWithoutRefresh();
  }

  onReload(): void {
    this.getDashBoard();
  }

  onDisclosure(event: PostoDTO): void {
    this.router.navigate([`base/gestao/crescimento-sustentavel-posto/${this.estadoId}/${event.id}`]);
  }

}
