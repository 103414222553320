import { Resource } from "src/app/global/domain/resource.model";
import { Posto } from "./posto.model";
import { Rota } from "./rota.model";

export class AuditoriaTransferenciaCliente extends Resource {
    id: number;
    postoDestino: Posto;
    postoOrigem: Posto;
    rotaDestino: Rota;
    rotaOrigem: Rota;
    dataTransferencia: Date;
    usuarioHeimdall: string;
}