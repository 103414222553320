import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppState } from 'src/app/App.state';
import { SharedService } from 'src/app/auth/shared.service';
import { Cliente } from 'src/app/base/domain/cliente.model';
import { OrigemAcaoDeVendaEnum } from 'src/app/base/domain/enum/origem-acao-de-venda.enum';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { ClienteService } from 'src/app/base/services/cliente.service';
import { VisitaService } from 'src/app/base/services/visita.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ClienteFormComponent } from 'src/app/global/forms/cliente-form/cliente-form.component';
import { ClienteAcaoEvent, ClienteMenuAcaoEvent } from 'src/app/global/lists/cliente-list/cliente-list.component';
import { DialogService } from 'src/app/global/services/dialog.service';

@Component({
  selector: 'app-acompanhamento-cliente-carteira-main',
  templateUrl: './acompanhamento-cliente-carteira-main.component.html',
  styleUrls: ['./acompanhamento-cliente-carteira-main.component.css'],
})
export class AcompanhamentoClienteCarteiraMainComponent implements OnInit {
  static NOVO_CLIENTE = 'Novo Cliente';
  static NOVA_ACAO_DE_VENDA = 'Nova Ação de Venda';
  clientes: Cliente[] = [];
  queryOptions = new QueryOptions({ pageSize: 25 });
  totalRecords = 0;
  loading = false;
  private allSelected = false;
  carteiraId: number;
  menuAcoes = [AcompanhamentoClienteCarteiraMainComponent.NOVO_CLIENTE, AcompanhamentoClienteCarteiraMainComponent.NOVA_ACAO_DE_VENDA];
  private usuario: Usuario;

  constructor(
    private route: ActivatedRoute,
    public dialogService: DialogService,
    private matDialog: MatDialog,
    private clienteService: ClienteService,
    public dialog: MatDialog,
    private router: Router,
    public appState: AppState,
    private activatedRoute: ActivatedRoute,
    private visitaService: VisitaService,
  ) {
    this.route.params.subscribe((params: Params) => {
      this.carteiraId = params.id;
    });

    const shared = SharedService.getInstance();
    this.usuario = shared.usuario;
  }

  ngOnInit(): void {
    this.listarClientes();
  }

  listarClientes(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.queryOptions.params = { ...this.activatedRoute.snapshot.queryParams, carteiraClienteId: this.carteiraId };
    this.clienteService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Cliente>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.clientes = response.object.content;
        this.mapearAcoes(response.object.content);
      } else {
        this.clientes = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
    });
  }

  mapearAcoes(clientes: Cliente[]): void {
    const mapearAcoes = (cliente: Cliente): Cliente => {
      const acoes = [];

      return { ...cliente, acoes };
    };

    this.clientes = clientes?.map(mapearAcoes) ?? [];
  }

  onAcaoClick(event: ClienteAcaoEvent): void {
    const actions = new Map<string, (cliente: Cliente) => void>();
    actions.set(AcompanhamentoClienteCarteiraMainComponent.NOVA_ACAO_DE_VENDA, this.cadastrarAcoesDeVenda.bind(this));
    actions.get(event.acao)(event.cliente);
  }

  onMenuAcaoClick(event: ClienteMenuAcaoEvent): void {
    const actions = new Map<string, () => void>()
      .set(AcompanhamentoClienteCarteiraMainComponent.NOVO_CLIENTE, this.exibirFormularioParaNovoCliente.bind(this))
      .set(AcompanhamentoClienteCarteiraMainComponent.NOVA_ACAO_DE_VENDA, this.cadastrarAcoesDeVenda.bind(this));

    actions.get(event.acao)();
  }

  onLoadClientePage(page: number): void {
    this.listarClientes(page);
  }

  exibirFormularioParaNovoCliente(): void {
    const dialog = this.matDialog.open(ClienteFormComponent, {
      data: {
        titulo: 'Nova Carteira de Cliente',
        carteiraCliente: {
          id: this.carteiraId,
        },
        action: 'create',
      },
      width: '1000px',
    });
    dialog.afterClosed().subscribe((cliente: Cliente) => {
      this.adicionarNovaCarteiraCliente(cliente);
    });
  }

  adicionarNovaCarteiraCliente(cliente: Cliente): void {
    this.appState.isLoading = true;
    if (cliente) {
      this.clienteService.saveClienteVendedorExterno(cliente).subscribe(
        (response: ReturnAPI<Cliente>) => {
          this.dialogService.feedbackReturnAPI(response, 'Novo Cliente cadastrado com sucesso');
          if (response.success) {
            this.listarClientes();
          }
          this.appState.isLoading = false;
        },
        () => {
          this.dialogService.feedbackError('Erro ao cadastrar novo cliente');
          this.appState.isLoading = false;
        },
      );
    } else {
      this.appState.isLoading = false;
    }
  }

  async cadastrarAcoesDeVenda(_: never): Promise<void> {
    let quantidade = this.totalRecords;

    if (!this.allSelected) {
      quantidade = this.clientes.filter((capt: Cliente) => capt.selecionado).length;
    }

    const confirm = async (_preAprovar: boolean) => {
      this.appState.isLoading = true;

      try {
        const { joinRota, ...options } = this.queryOptions.params;
        const res = await this.visitaService
          .registrarAcoesDeVenda({
            allSelected: this.allSelected,
            captacao: false,
            preAprovar: _preAprovar,
            clientesIds: this.allSelected ? [] : this.clientes.filter((cap: Cliente) => cap.selecionado).map((cap: Cliente) => cap.id),
            captadosIds: null,
            params: this.allSelected
              ? JSON.stringify({
                  ...options,
                  page: '1',
                  size: this.totalRecords.toString(),
                })
              : '',
            usuarioHeimdallId: this.usuario.id,
            captadorNome: this.usuario.nome,
            origem: OrigemAcaoDeVendaEnum.VENDEDOR_EXTERNO,
          })
          .toPromise();
        this.dialogService.feedbackReturnAPI(res, 'Ações de Venda registradas com sucesso');
      } catch (error) {
        this.dialogService.feedbackError('Não foi possível fazer os registros');
      } finally {
        this.appState.isLoading = false;
      }
    };

    this.dialogService.checkboxDialog({
      title: 'Cadastrar Ações de Venda',
      message: `
        Deseja registrar uma ação de venda para os clientes selecionados?
        Quantidade de novas ações: ${quantidade}`,
      confirmfn: confirm.bind(this),
    });
  }
}
