import { Component, Inject, OnInit } from '@angular/core';
import { Endereco } from 'src/app/base/domain/endereco.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Posto } from 'src/app/base/domain/posto.model';
import { PostoService } from 'src/app/base/services/posto.service';
import { QueryOptions } from '../../domain/query.options';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import { Equipe } from 'src/app/base/domain/equipe.model';
import { Rota } from 'src/app/base/domain/rota.model';

export interface EquipeRotaFormData {
  endereco: Endereco;
}

@Component({
  selector: 'app-equipe-rota-form',
  templateUrl: './equipe-rota-form.component.html',
  styleUrls: ['./equipe-rota-form.component.css'],
})
export class EquipeRotaFormComponent implements OnInit {
  postos: Posto[] = [];
  equipes: Equipe[] = [];
  rotas: Rota[] = [];

  filteredPostos: Posto[] = [];
  filteredEquipes: Equipe[] = [];
  filteredRotas: Rota[] = [];

  postoInput = '';
  equipeInput = '';
  rotaInput = '';

  private postoSelecionado: Posto = null;
  private equipeSelecionada: Equipe = null;
  private rotaSelecionada: Rota = null;

  private readonly endereco: Endereco;

  constructor(
    public dialogRef: MatDialogRef<EquipeRotaFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EquipeRotaFormData,
    private postoService: PostoService,
  ) {
    this.endereco = data.endereco;
  }

  ngOnInit(): void {
    this.postoService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Posto>>) => {
      if (response.success) {
        this.postos = response.object.content;
        this.filteredPostos = this.postos;
      }
    });
  }

  onPostoSelected(descricaoPosto: string): void {
    const postoSelecionado = this.postos.find((posto: Posto) => posto.descricao === descricaoPosto);
    this.postoService.buscarEquipesPorPosto(postoSelecionado.id).subscribe((response: ReturnAPI<Page<Equipe>>) => {
      if (response.success) {
        this.postoSelecionado = postoSelecionado;
        this.equipes = response.object.content;
        this.rotas = [];
        this.filteredEquipes = this.equipes;
        this.filteredRotas = [];
        this.equipeInput = '';
        this.rotaInput = '';
      }
    });
  }

  onEquipeSelected(descricaoEquipe: string): void {
    this.equipeSelecionada = this.equipes.find((equipe: Equipe) => equipe.descricao === descricaoEquipe);
    this.rotas = this.equipeSelecionada.rotas;
    this.filteredRotas = this.rotas;
    this.rotaInput = '';
  }

  onRotaSelected(descricaoRota: string): void {
    this.rotaSelecionada = this.rotas.find((rota: Rota) => rota.descricao === descricaoRota);
  }

  doFilter(value: string): void {
    if (value === 'posto') {
      this.filteredPostos = this._filtrar(this.postoInput, this.postos, 'descricao');
    }
    if (value === 'equipe') {
      this.filteredEquipes = this._filtrar(this.equipeInput, this.equipes, 'descricao');
    }
    if (value === 'rota') {
      this.filteredRotas = this._filtrar(this.rotaInput, this.rotas, 'descricao');
    }
  }

  onConfirm(): void {
    const equipe = Object.assign({}, this.equipeSelecionada);
    equipe.rotas = null;
    this.rotaSelecionada.equipe = equipe;
    this.dialogRef.close(this.rotaSelecionada);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  private _filtrar<T>(value: string, elements: T[], key: string): T[] {
    const filterValue = value?.toLowerCase();
    return elements.filter((e: T) => e[key].toLowerCase().indexOf(filterValue) === 0);
  }

  get queryOptions(): QueryOptions {
    return new QueryOptions({
      pageNumber: 1,
      params: {
        municipioId: this.endereco.municipio.id,
      },
    });
  }
}
