import { Component, OnInit, Inject } from '@angular/core';
import { CategoriaPlanoConta } from 'src/app/base/domain/categoria-plano-conta.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CategoriaPlanoContaService } from 'src/app/base/services/categoria-plano-conta.service';
import { QueryOptions } from '../../domain/query.options';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import { PostoService } from 'src/app/base/services/posto.service';
import { Posto } from 'src/app/base/domain/posto.model';

export interface DataType {
  action: string;
  categoria: CategoriaPlanoConta;
}

@Component({
  selector: 'app-categoria-plano-conta-form',
  templateUrl: './categoria-plano-conta-form.component.html',
  styleUrls: ['./categoria-plano-conta-form.component.css'],
})
export class CategoriaPlanoContaFormComponent implements OnInit {
  categoria = new CategoriaPlanoConta();
  categorias: CategoriaPlanoConta[] = [];
  filteredPais: CategoriaPlanoConta[] = [];
  paiInput = '';
  isEdit = false;

  constructor(
    public dialogRef: MatDialogRef<CategoriaPlanoContaFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataType,
    private service: CategoriaPlanoContaService,
  ) {
    if (data.action === 'update') {
      this.categoria = data.categoria;
      this.paiInput = this.categoria.parent?.descricao ? this.categoria.parent?.descricao : '';
      this.isEdit = true;
    }
  }

  ngOnInit(): void {
    this.service.pageAll(this.queryOptions).subscribe((response: ReturnAPI<Page<CategoriaPlanoConta>>) => {
      if (response.success) {
        this.categorias = response.object.content;
        this.filteredPais = this.categorias;
      }
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(this.categoria);
  }

  onPaiSelected(descricaoPai: string): void {
    const paiSelecionado = this.categorias.find((categoria: CategoriaPlanoConta) => categoria.descricao === descricaoPai);
    this.categoria.parent = paiSelecionado;
  }

  doFilter(value: string): void {
    if (value === 'pai') {
      this.filteredPais = this._filtrar(this.paiInput, this.categorias, 'descricao');
    }
  }

  private _filtrar<T>(value: string, elements: T[], key: string): T[] {
    const filterValue = value?.toLowerCase();
    return elements.filter((e: T) => e[key].toLowerCase().indexOf(filterValue) === 0);
  }

  get queryOptions(): QueryOptions {
    return new QueryOptions({
      pageNumber: 1,
      pageSort: '-id',
    });
  }
}
