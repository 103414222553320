import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QueryOptions } from '../../domain/query.options';
import { LazyLoadEvent } from 'primeng/api';
import { Posto } from 'src/app/base/domain/posto.model';
import { Feriado } from 'src/app/base/domain/feriado.model';
import { TipoFeriado } from 'src/app/base/domain/enum/tipo-feriado.enum';
import { DuplicadoDTO } from 'src/app/base/domain/dto/duplicados.dto';

export interface ParcelaDuplicadaAcaoEvent {
  acao: string;
  duplicado: DuplicadoDTO;
}

@Component({
  selector: 'app-parcela-duplicada-list',
  templateUrl: './parcela-duplicada-list.component.html',
  styleUrls: ['./parcela-duplicada-list.component.css'],
})
export class ParcelaDuplicadaListComponent implements OnInit {
  @Input() title: string;
  @Input() nomePlaceholder: string;
  @Input() rowsNumber: number;
  @Input() totalRecords: number;
  @Input() loading: boolean;
  @Input() options: string[];
  @Input() duplicados: DuplicadoDTO[];

  @Output() loadDuplicadosPage = new EventEmitter<number>();
  @Output() acaoClick = new EventEmitter<ParcelaDuplicadaAcaoEvent>();

  private currentPage = 0;

  constructor() {
  }

  ngOnInit(): void { }

  loadDuplicados(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadDuplicadosPage.emit(pageNumber);
  }

  onAcaoClick(acao: string, domain: DuplicadoDTO): void {
    this.acaoClick.emit({ acao, duplicado: domain });
  }

  onAtualizar(): void {
    this.loadDuplicadosPage.emit(this.currentPage);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
