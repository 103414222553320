import { Component, OnInit } from '@angular/core';
import { EquipeService } from 'src/app/base/services/equipe.service';
import { DialogService } from 'src/app/global/services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from 'src/app/App.state';
import { ActivatedRoute, Params } from '@angular/router';
import { Equipe } from 'src/app/base/domain/equipe.model';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import { Rota } from 'src/app/base/domain/rota.model';
import { RotaService } from 'src/app/base/services/rota.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { RotaListFilterOptions, RotaAcaoEvent, MenuAcaoEvent } from 'src/app/global/lists/rota-list/rota-list.component';
import { HttpErrorResponse } from '@angular/common/http';
import { TransferenciaRotaFormComponent } from 'src/app/global/forms/transferencia-rota-form/transferencia-rota-form.component';
import {
  AlterarResponsavelRotaFormComponent,
  ResponsavelDTO,
} from 'src/app/global/forms/alterar-responsavel-rota-form/alterar-responsavel-rota-form.component';
import { ContratoService } from 'src/app/base/services/contrato.service';
import { Contrato } from 'src/app/base/domain/contrato.model';
import { AgendarAlteracaoResponsavelRotaFormComponent } from 'src/app/global/forms/agendar-alteracao-responsavel-rota-form/agendar-alteracao-responsavel-rota-form.component';
import { FuncionarioService } from 'src/app/base/services/funcionario.service';
import { Funcionario } from 'src/app/base/domain/funcionario.model';

@Component({
  selector: 'app-equipes-detail',
  templateUrl: './equipes-detail.component.html',
  styleUrls: ['./equipes-detail.component.css'],
})
export class EquipesDetailComponent implements OnInit {
  private static DELETAR_ROTA = 'Excluir Rota';
  private static TRANSFERIR_ROTAS = 'Transferir Rotas';
  private static ALTERAR_RESPONSAVEL_ROTA = 'Alterar Responsável Rota';
  private static AGENDAR_ALTERAR_RESPONSAVEL_ROTA = 'Agendar Alteração de Responsável da Rota';
  private static CANCELAR_AGENDAR_ALTERAR_RESPONSAVEL_ROTA = 'Cancelar Agendamento Alteração de Responsável da Rota';
  equipeId: number;
  rotas: Rota[];
  equipe: Equipe;
  totalRecords = 0;
  loading = false;
  titulo = '';
  menuAcoes = [
    EquipesDetailComponent.TRANSFERIR_ROTAS,
    EquipesDetailComponent.ALTERAR_RESPONSAVEL_ROTA,
    EquipesDetailComponent.AGENDAR_ALTERAR_RESPONSAVEL_ROTA,
    EquipesDetailComponent.CANCELAR_AGENDAR_ALTERAR_RESPONSAVEL_ROTA,
  ];

  private queryOptions = new QueryOptions({ pageSize: 10 });

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public dialogService: DialogService,
    private rotaService: RotaService,
    private equipeService: EquipeService,
    public appState: AppState,
    private contratoService: ContratoService,
    private funcionarioService: FuncionarioService,
  ) {
    this.route.params.subscribe((params: Params) => {
      this.equipeId = params.id;
    });
  }

  ngOnInit(): void {
    this.equipeService.findById(this.equipeId).subscribe((response: ReturnAPI<Equipe>) => {
      this.equipe = response.object;
      this.titulo = `Rotas da equipe: ${this.equipe.descricao}.`;
    });
    this.queryOptions.params = {
      equipeId: `${this.equipeId}`,
    };
  }

  listarRotas(page: number = 0): void {
    this.queryOptions.pageNumber = page + 1;
    this.loading = true;
    this.rotaService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Rota>>) => {
      this.totalRecords = response.object.totalElements;
      this.mapearAcoes(response.object.content);
      this.loading = false;
    });
  }

  mapearAcoes(rotas: Rota[]): void {
    const mapearAcoes = (rota: Rota): Rota => {
      const acoes = [];
      acoes.push(EquipesDetailComponent.DELETAR_ROTA);
      return { ...rota, acoes };
    };

    this.rotas = rotas?.map(mapearAcoes) ?? [];
  }

  onAcaoClick(event: RotaAcaoEvent): void {
    const actions = new Map<string, (rota: Rota) => void>().set(EquipesDetailComponent.DELETAR_ROTA, (rota: Rota) => {
      this.delete(rota);
    });

    actions.get(event.acao)(event.rota);
  }

  onMenuAcaoClick(event: MenuAcaoEvent): void {
    const actions = new Map<string, () => void>()
      .set(EquipesDetailComponent.TRANSFERIR_ROTAS, () => {
        this.onTransferirRotas();
      })
      .set(EquipesDetailComponent.ALTERAR_RESPONSAVEL_ROTA, () => {
        this.onAlterarResponsavelRota(false);
      })
      .set(EquipesDetailComponent.AGENDAR_ALTERAR_RESPONSAVEL_ROTA, () => {
        this.onAgendarAlteraçãoResponsavelRota(false);
      })
      .set(EquipesDetailComponent.CANCELAR_AGENDAR_ALTERAR_RESPONSAVEL_ROTA, () => {
        this.onAgendarAlteraçãoResponsavelRota(true);
      });

    actions.get(event.acao)();
  }

  delete(rota: Rota): void {
    this.dialogService.confirmDialog('Deseja realmente excluir essa rota?', () => {
      this.dialogService.auditoriaDialog(() => {
        this.rotaService.deletar(rota.id).subscribe(
          (response: ReturnAPI<void>) => {
            this.dialogService.feedbackReturnAPI(response, 'Rota deletada com sucesso.');
            this.listarRotas();
          },
          (e: HttpErrorResponse) => {
            this.dialogService.feedbackError(`Houve um erro ao deletar Rota.\n ${e.message}`);
          },
        );
      });
    });
  }

  onLoadRotasPage(page: number): void {
    this.listarRotas(page);
  }

  onFilter(options: RotaListFilterOptions): void {
    this.queryOptions.params = {
      postoSelecionado: null,
      equipeId: this.equipeId,
      descricao: options.nomeRota ? `like:${options.nomeRota}` : null,
      nomeCobrador: options.nomeCobrador ? `${options.nomeCobrador}` : null,
      postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
    };

    this.listarRotas();
  }

  onTransferirRotas(): void {
    this.dialog
      .open(TransferenciaRotaFormComponent, {
        data: {
          equipe: this.equipe,
        },
        width: '400px',
      })
      .afterClosed()
      .subscribe(() => {
        this.listarRotas();
      });
  }

  onAlterarResponsavelRota(cancelar: boolean): void {
    const dialogRef = this.dialog.open(AlterarResponsavelRotaFormComponent, {
      data: {
        equipe: this.equipe,
        cancelar: cancelar,
      },
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((responsavelDTO: ResponsavelDTO) => {
      if (responsavelDTO) {
        this.appState.isLoading = true;
        this.contratoService.alterarResponsavelRota(responsavelDTO).subscribe(
          (response: ReturnAPI<Contrato>) => {
            this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
            this.listarRotas();
            this.appState.isLoading = false;
          },
          (e: HttpErrorResponse) => {
            this.dialogService.feedbackError(`Houve um erro ao Alterar Responsável da Rota.\n ${e.message}`);
            this.appState.isLoading = false;
          },
        );
      }
    });
  }

  onAgendarAlteraçãoResponsavelRota(cancelar: boolean): void {
    const dialogRef = this.dialog.open(AgendarAlteracaoResponsavelRotaFormComponent, {
      data: {
        equipe: this.equipe,
        cancelar: cancelar,
      },
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((responsavelDTO: ResponsavelDTO) => {
      if (responsavelDTO) {
        this.appState.isLoading = true;
        if (cancelar) {
          this.cancelarAgendamentoAlteracaoResponsavelRota(responsavelDTO);
        } else {
          this.agendarAlteracaoResponsavelRota(responsavelDTO);
        }
      }
    });
  }

  agendarAlteracaoResponsavelRota(responsavelDTO: ResponsavelDTO): void {
    this.funcionarioService.agendarAlterarResponsavelRota(responsavelDTO).subscribe(
      (response: ReturnAPI<Funcionario>) => {
        this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
        this.listarRotas();
        this.appState.isLoading = false;
      },
      (e: HttpErrorResponse) => {
        this.dialogService.feedbackError(`Houve um erro ao Agendar Alteração de Responsável da Rota.\n ${e.message}`);
        this.appState.isLoading = false;
      },
    );
  }

  cancelarAgendamentoAlteracaoResponsavelRota(responsavelDTO: ResponsavelDTO): void {
    this.funcionarioService.cancelarAgendarAlterarResponsavelRota(responsavelDTO).subscribe(
      (response: ReturnAPI<Funcionario>) => {
        this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
        this.listarRotas();
        this.appState.isLoading = false;
      },
      (e: HttpErrorResponse) => {
        this.dialogService.feedbackError(`Houve um erro ao Cancelar Agendamento de Alteração de Responsável da Rota.\n ${e.message}`);
        this.appState.isLoading = false;
      },
    );
  }
}
