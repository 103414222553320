import { Component, OnInit } from '@angular/core';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { PendenciaFechamentoCaixa } from 'src/app/base/domain/pendencia-fechamento-caixa.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/global/services/dialog.service';
import { PendenciaFechamentoCaixaService } from 'src/app/base/services/pendencia-fechamento-caixa.service';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import {
  PendenciaFechamentoCaixaListFilterOptions,
  PendenciaFechamentoCaixaAcaoEvent,
} from 'src/app/global/lists/pendencia-fechamento-list/pendencia-fechamento-list.component';
import { AppState } from 'src/app/App.state';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pendencia-fechamento-caixa-main',
  templateUrl: './pendencia-fechamento-caixa-main.component.html',
  styleUrls: ['./pendencia-fechamento-caixa-main.component.css'],
})
export class PendenciaFechamentoCaixaMainComponent implements OnInit {
  private static CONFIRMAR_PENDENCIA_ACTION = 'Confirmar realização da atividade';
  private static IR_PARA_CONTRATOS_CANCELAR_ACTION = 'Ir Para Contratos a Cancelar';
  private static IR_PARA_CAIXA_FUNCIONARIO_ACTION = 'Ir Para Caixa do Funcionário';
  private static IR_PARA_ACOMPANHAMENTO_SANGRIA_ACTION = 'Ir Para Acompanhamento de Sangrias';

  pendenciasFechamentoCaixa: PendenciaFechamentoCaixa[] = [];
  queryOptions = new QueryOptions({ pageSize: 10 });
  totalRecords = 0;
  loading = false;
  private postoSelecionado: number = null;

  constructor(
    private router: Router,
    public dialogService: DialogService,
    public dialog: MatDialog,
    public service: PendenciaFechamentoCaixaService,
    public appState: AppState,
  ) {}

  ngOnInit(): void {
    const date = new Date();
    this.queryOptions.params = {
      dataInicial: date.getTime(),
      dataFinal: date.getTime(),
    };
  }

  listarPendenciasFechamentoCaixa(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.service.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<PendenciaFechamentoCaixa>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.pendenciasFechamentoCaixa = response.object.content;
        this.mapearAcoes(response.object.content);
      } else {
        this.pendenciasFechamentoCaixa = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
    });
  }

  mapearAcoes(pendenciasFechamentoCaixa: PendenciaFechamentoCaixa[]): void {
    const mapearAcoes = (pendencia: PendenciaFechamentoCaixa): PendenciaFechamentoCaixa => {
      const acoes = [];
      if (pendencia.situacao === 'CONFIRMACAO_PENDENTE') {
        acoes.push(PendenciaFechamentoCaixaMainComponent.CONFIRMAR_PENDENCIA_ACTION);
      }

      if (pendencia.situacao === 'CONFIRMACAO_PENDENTE' && pendencia.tipoPendenciaFechamentoCaixa === 'CANCELAMENTO_CONTRATO') {
        acoes.push(PendenciaFechamentoCaixaMainComponent.IR_PARA_CONTRATOS_CANCELAR_ACTION);
      }

      if (pendencia.situacao === 'CONFIRMACAO_PENDENTE' && pendencia.caixaFuncionario != null) {
        acoes.push(PendenciaFechamentoCaixaMainComponent.IR_PARA_CAIXA_FUNCIONARIO_ACTION);
      }

      if (pendencia.situacao === 'CONFIRMACAO_PENDENTE' && pendencia.tipoPendenciaFechamentoCaixa === 'SANGRIA_NAO_REALIZADA') {
        acoes.push(PendenciaFechamentoCaixaMainComponent.IR_PARA_ACOMPANHAMENTO_SANGRIA_ACTION);
      }

      return { ...pendencia, acoes };
    };

    this.pendenciasFechamentoCaixa = pendenciasFechamentoCaixa?.map(mapearAcoes) ?? [];
  }

  onAcaoClick(event: PendenciaFechamentoCaixaAcaoEvent): void {
    const actions = new Map<string, (pendenciaFechamentoCaixa: PendenciaFechamentoCaixa) => void>()
      .set(PendenciaFechamentoCaixaMainComponent.CONFIRMAR_PENDENCIA_ACTION, (pendenciaFechamentoCaixa: PendenciaFechamentoCaixa) => {
        this.onConfirmarPendencia(pendenciaFechamentoCaixa);
      })
      .set(PendenciaFechamentoCaixaMainComponent.IR_PARA_CONTRATOS_CANCELAR_ACTION, () => {
        this.irParaContratosACancelar();
      })
      .set(PendenciaFechamentoCaixaMainComponent.CONFIRMAR_PENDENCIA_ACTION, (pendenciaFechamentoCaixa: PendenciaFechamentoCaixa) => {
        this.irParaCaixaFuncionario(pendenciaFechamentoCaixa.caixaFuncionario.id);
      })
      .set(PendenciaFechamentoCaixaMainComponent.IR_PARA_ACOMPANHAMENTO_SANGRIA_ACTION, () => {
        this.irParaAcompanhamentoSangria();
      });

    actions.get(event.acao)(event.pendenciaFechamentoCaixa);
  }

  irParaContratosACancelar(): void {
    this.router.navigate(['base/contratos/a-cancelar']);
  }

  irParaAcompanhamentoSangria(): void {
    this.router.navigate(['base/gestao/acompanhamento-sangria']);
  }

  irParaCaixaFuncionario(caixaFuncionarioId: number): void {
    this.router.navigate(['base/caixas/funcionario-movimentacoes/' + caixaFuncionarioId]);
  }

  onLoadPendenciaFechamentoCaixaPage(page: number): void {
    this.listarPendenciasFechamentoCaixa(page);
  }

  onFilter(options: PendenciaFechamentoCaixaListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    this.queryOptions.params = {
      postoId,
      situacao: options.situacao?.length ? `in:${options.situacao.join(',')}` : null,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
    };
    this.listarPendenciasFechamentoCaixa();
    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }
  }

  onConfirmarPendencia(pendenciaFechamentoCaixa: PendenciaFechamentoCaixa): void {
    this.dialogService.confirmDialog('Confirmar realização da tarefa?', () => {
      this.confirmarPendencia(pendenciaFechamentoCaixa);
    });
  }

  private confirmarPendencia(pendenciaFechamentoCaixa: PendenciaFechamentoCaixa): void {
    this.appState.isLoading = true;
    this.service.confirmarPendencia(pendenciaFechamentoCaixa.id).subscribe(
      (response: ReturnAPI<void>) => {
        this.dialogService.feedbackReturnAPI(response, 'Operação efetuada com sucesso');
        this.appState.isLoading = false;
        this.listarPendenciasFechamentoCaixa();
      },
      () => {
        this.dialogService.feedbackError('Houve um erro na confirmação!');
        this.appState.isLoading = false;
        this.listarPendenciasFechamentoCaixa();
      },
    );
  }
}
