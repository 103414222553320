import { Pessoa } from './pessoa.model';
import { Posto } from './posto.model';
import { Resource } from 'src/app/global/domain/resource.model';
import { ContaCliente } from './conta-cliente.model';
import { Rota } from './rota.model';
import { Funcionario } from './funcionario.model';

export class CarteiraCliente extends Resource {
  descricao: string;
  funcionario: Funcionario;
  dataCadastro: Date;
}
