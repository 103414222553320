import { Produto } from '../domain/produto.model';
import { ResourceService } from 'src/app/global/services/resource.service';
import { ContratoComissao } from '../domain/contrato-comissao.model';
import { ContratoComissaoSerializer } from '../serializables/contrato-comissao.serializer';
import { HttpClient } from '@angular/common/http';
import { WEB_API } from 'src/app/global/services/web-api';
import { Injectable } from '@angular/core';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { Observable } from 'rxjs';
import { AuditoriaTransferenciaCliente } from '../domain/auditoria-transferencia-cliente.model';
import { AuditoriaTransferenciaClienteSerializer } from '../serializables/auditoria-transferencia-cliente.serializer';

@Injectable()
export class AuditoriaTransferenciaClienteService extends ResourceService<AuditoriaTransferenciaCliente> {
    static RESOURCE = 'auditoria-transferencia-cliente';
    produto: Produto;
    produtoId: string;

    constructor(private http: HttpClient) {
        super(http, `${WEB_API}`, `${AuditoriaTransferenciaClienteService.RESOURCE}`, new AuditoriaTransferenciaClienteSerializer(AuditoriaTransferenciaCliente));
    }

    getProduto(): void {
        if (sessionStorage.getItem('produto')) {
            this.produto = JSON.parse(sessionStorage.getItem('produto'));
            this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
        }
    }

    gerarRelatorio(options: QueryOptions): Observable<Blob> {
        this.getProduto();
        return this.httpClient.get(
            `${WEB_API}/${AuditoriaTransferenciaClienteService.RESOURCE}/gerar-relatorio?${options.toQueryString(false)}&${this.produtoId}`,
            { responseType: 'blob' },
        );
    }
}