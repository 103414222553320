import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { ArquivoExtrato } from 'src/app/base/domain/arquivo-extrato.model';
import { Page } from 'src/app/base/domain/return-api.model';
import { QueryOptions } from '../../domain/query.options';

export interface ExtratoFilterOptions {
  dataInicial: Date;
  dataFinal: Date;
}

export interface ExtratoEvent {
  acao: string;
  arquivo: ArquivoExtrato;
}

@Component({
  selector: 'app-arquivo-extrato-list',
  templateUrl: './arquivo-extrato-list.component.html',
  styleUrls: ['./arquivo-extrato-list.component.css'],
})
export class ArquivoExtratoListComponent implements OnInit {
  @Output() filter = new EventEmitter<ExtratoFilterOptions>();
  @Output() loadArquivosPage = new EventEmitter<number>();
  @Output() acaoClick = new EventEmitter<ExtratoEvent>();
  @Output() menuAcaoClick = new EventEmitter<string>();

  public static IMPORTAR_ARQUIVO = 'Importar Arquivo';
  menuAcoes: string[] = ['Importar Arquivo'];

  filterOptions: ExtratoFilterOptions;
  private readonly defaultFilterOptions: ExtratoFilterOptions = {
    dataInicial: null,
    dataFinal: null,
  };

  @Input() page: Page<ArquivoExtrato>;
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() loading: boolean;

  private currentPage = 0;
  queryOptions = new QueryOptions();

  constructor() {
    this.onLimparFiltros();
  }

  ngOnInit(): void {}

  loadArquivos(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadArquivosPage.emit(pageNumber);
  }

  onAtualizar(): void {
    this.loadArquivosPage.emit(this.currentPage);
  }

  onMenuAcaoClick(event: string): void {
    this.menuAcaoClick.emit(event);
  }

  onAcaoClick(acao: string, domain: ArquivoExtrato): void {
    this.acaoClick.emit({ acao, arquivo: domain });
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }
}
