import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalModule } from 'src/app/global/global.module';
import { AcoesDeVendaMainComponent } from './acoes-de-venda-main/acoes-de-venda.component';

@NgModule({
    declarations: [AcoesDeVendaMainComponent],
    imports: [CommonModule, GlobalModule],
})
export class AcoesDeVendaModule { }
