import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcompanhamentoFeriadoMainComponent } from './acompanhamento-feriado-main/acompanhamento-feriado-main.component';
import { GlobalModule } from 'src/app/global/global.module';

@NgModule({
  declarations: [AcompanhamentoFeriadoMainComponent],
  imports: [CommonModule, GlobalModule],
})
export class AcompanhamentoFeriadoModule {}
