import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LazyLoadEvent } from 'primeng';
import { ParcelaCobrancaJudicial } from 'src/app/base/domain/parcela-cobranca-judicial.model';
import { QueryOptions } from '../../domain/query.options';

export interface ParcelaCobrancaJudicialAcaoEvent {
  acao: string;
  parcelaCobranca: ParcelaCobrancaJudicial;
}

@Component({
  selector: 'app-parcela-cobranca-judicial-list',
  templateUrl: './parcela-cobranca-judicial-list.component.html',
  styleUrls: ['./parcela-cobranca-judicial-list.component.css']
})
export class ParcelaCobrancaJudicialListComponent implements OnInit {

  @Input() title: string;
  @Input() parcelas: ParcelaCobrancaJudicial[];
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() filtros: string[];
  @Input() options: string[];
  @Input() loading: boolean;

  @Output() loadPage = new EventEmitter<number>();
  @Output() acaoClick = new EventEmitter<ParcelaCobrancaJudicialAcaoEvent>();

  private currentPage = 0;

  ngOnInit(): void {
  }

  load(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadPage.emit(pageNumber);
  }

  onAcaoClick(acao: string, domain: ParcelaCobrancaJudicial): void {
    this.acaoClick.emit({ acao, parcelaCobranca: domain });
  }

  onAtualizar(): void {
    this.loadPage.emit(this.currentPage);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

}
