export enum SituacaoVisita {
  TODAS_AS_VISITAS = 'TODAS_AS_VISITAS',
  AGENDADA = 'AGENDADA',
  REAGENDADA = 'REAGENDADA',
  EM_VISITA = 'EM_VISITA',
  VISITADA = 'VISITADA',
  EM_ANALISE = 'EM_ANALISE',
  FINALIZADA = 'FINALIZADA',
  APROVADO = 'APROVADO',
  REPROVADO = 'REPROVADO',
  CANCELADO = 'CANCELADO',
  ENCERRADA = 'ENCERRADA',
  PRE_APROVADO = 'PRE_APROVADO',
  EM_ANALISE_REATIVACAO = 'EM_ANALISE_REATIVACAO',
}
