import { Component, OnInit } from '@angular/core';
import { Transferencia } from '../../../domain/transferencia.model';
import { ActivatedRoute, Params } from '@angular/router';
import { TransferenciaService } from '../../../services/transferencia.service';
import { ReturnAPI, Page } from '../../../domain/return-api.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { TransferenciaAcaoEvent } from 'src/app/global/lists/transferencia-list/transferencia-list.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmacaoDialogComponent } from 'src/app/global/components/confirmacao-dialog/confirmacao-dialog.component';
// tslint:disable-next-line: max-line-length
import { TransferenciaEntrePostosFormComponent } from 'src/app/global/forms/transferencia-entre-postos-form/transferencia-entre-postos-form.component';
import { TransferenciaDTO } from '../../../domain/dto/transferencia.dto';
import { DialogService } from 'src/app/global/services/dialog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DespesaDTO } from '../../../domain/dto/despesa.dto';
import { CaixaService } from '../../../services/caixa.service';
import { Caixa } from '../../../domain/caixa.model';
import { Posto } from 'src/app/base/domain/posto.model';
import {
  CheckboxSelectFormComponent,
  CheckboxSelectFormData,
  CheckboxSelectFormResultData,
} from 'src/app/global/forms/checkbox-select-form/checkbox-select-form.component';
import { PostoService } from 'src/app/base/services/posto.service';
import { AppState } from 'src/app/App.state';

@Component({
  selector: 'app-caixa-controle-movimentacoes',
  templateUrl: './caixa-controle-movimentacoes.component.html',
  styleUrls: ['./caixa-controle-movimentacoes.component.css'],
})
export class CaixaControleMovimentacoesComponent implements OnInit {
  private static CONFIRMAR_TRANSFERENCIA_ACTION = 'Confirmar Transferência';
  private static CANCELAR_TRANSFERENCIA_ACTION = 'Cancelar Transferência';
  private static REALIZAR_TRANSFERENCIA_ACTION = 'Realizar Complemento de Posto';
  private static REALIZAR_SANGRIA_ACTION = 'Realizar Sangria Odin';
  private static CANCELAR_COMPLEMENTO = 'Cancelar Complemento';

  private queryOptions = new QueryOptions({ pageSize: 10 });

  caixaId: number;
  transferencias: Transferencia[];
  tipoMovimentacoes: 'entradas' | 'saidas' = 'entradas';
  totalRecords: number;
  loading = false;
  caixa: Caixa;
  optionalColumns = ['descricao', 'natureza', 'acoes', 'origem', 'destino', 'situacao'];

  despesaDTO = new DespesaDTO();

  constructor(
    private route: ActivatedRoute,
    private transferenciaService: TransferenciaService,
    public dialog: MatDialog,
    public dialogService: DialogService,
    private caixaService: CaixaService,
    private postoService: PostoService,
    public appState: AppState,
  ) {
    this.route.params.subscribe((params: Params) => {
      this.caixaId = params.id;
    });
  }

  ngOnInit(): void {
    this.listarTransferencias();
    this.caixaService.findById(this.caixaId).subscribe((response: ReturnAPI<Caixa>) => {
      this.caixa = response.object;
    });
  }

  onLoadTransferencias(event: number): void {
    this.listarTransferencias(event);
  }

  mapearAcoesDeTransferencias(transferencias: Transferencia[]): void {
    const mapearAcoes = (transferencia: Transferencia): Transferencia => {
      const acoes = [];
      if (transferencia.natureza && transferencia.situacao !== 'COMPLEMENTO_ENTRE_POSTOS_TRANSFERIDO') {
        if (transferencia.situacao === 'CONFIRMACAO_PENDENTE') {
          acoes.push(CaixaControleMovimentacoesComponent.CONFIRMAR_TRANSFERENCIA_ACTION);
        }
        if (transferencia.natureza === 'DESPESA_ADMINISTRATIVA' && transferencia.situacao === 'CONFIRMACAO_PENDENTE') {
          acoes.push(CaixaControleMovimentacoesComponent.CANCELAR_TRANSFERENCIA_ACTION);
        }
        if (transferencia.natureza === 'COMPLEMENTO' && transferencia.situacao === 'CONFIRMADA') {
          acoes.push(CaixaControleMovimentacoesComponent.REALIZAR_TRANSFERENCIA_ACTION);
          acoes.push(CaixaControleMovimentacoesComponent.CANCELAR_COMPLEMENTO);
        }
        if (transferencia.natureza === 'SANGRIA' && transferencia.situacao === 'CONFIRMADA') {
          acoes.push(CaixaControleMovimentacoesComponent.REALIZAR_SANGRIA_ACTION);
        }
      }
      return { ...transferencia, acoes };
    };

    this.transferencias = transferencias?.map(mapearAcoes) ?? [];
  }

  async consultaPostos(name: string): Promise<Posto[]> {
    const options = new QueryOptions({ pageSize: 100, pageNumber: 1, params: { descricao: name } });
    const response = await this.postoService.findAll(options).toPromise();
    return response.success ? response.object.content : [];
  }

  async consultaPostosOutroEstado(name: string, estadoId: number): Promise<Posto[]> {
    const options = new QueryOptions({ pageSize: 1000, pageNumber: 1, params: { descricao: name } });
    options.params.codigoEstado = estadoId;
    const response = await this.postoService.findAllWithoutProduto(options).toPromise();
    return response.success ? response.object.content : [];
  }

  listarTransferencias(page: number = 0): void {
    this.queryOptions.pageNumber = page + 1;
    this.queryOptions.pageSort = '-id';
    this.loading = true;

    if (this.tipoMovimentacoes === 'entradas') {
      this.transferenciaService
        .pageTransferenciaByDestinoId(this.queryOptions, this.caixaId)
        .subscribe((response: ReturnAPI<Page<Transferencia>>) => {
          this.totalRecords = response.object.totalElements;
          this.mapearAcoesDeTransferencias(response.object.content);
          this.loading = false;
        });
    } else if (this.tipoMovimentacoes === 'saidas') {
      this.transferenciaService
        .pageTransferenciaByOrigemId(this.queryOptions, this.caixaId)
        .subscribe((response: ReturnAPI<Page<Transferencia>>) => {
          this.totalRecords = response.object.totalElements;
          this.mapearAcoesDeTransferencias(response.object.content);
          this.loading = false;
        });
    }
  }

  confimarTransferencia(transferencia: Transferencia): void {
    const dialogRef = this.dialog.open(ConfirmacaoDialogComponent, {
      width: '300px',
    });

    if (transferencia.natureza === 'SANGRIA') {
      this.confirmarSangriaOdin(dialogRef, transferencia);
    }

    if (transferencia.natureza === 'COMPLEMENTO') {
      this.confirmarComplemento(dialogRef, transferencia);
    }

    if (transferencia.natureza === 'DESPESA_ADMINISTRATIVA') {
      this.confirmarDespesaAdministrativa(dialogRef, transferencia);
    }
  }

  cancelarTransferencia(transferencia: Transferencia): void {
    this.dialog
      .open(ConfirmacaoDialogComponent, {
        width: '300px',
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.despesaDTO.postoId = transferencia.posto.id;
          this.despesaDTO.valor = transferencia.valorRealizado;
          this.despesaDTO.transferenciaId = transferencia.id;
          this.despesaDTO.categoria = transferencia.categoria;

          this.transferenciaService.cancelarDespesaAdministrativa(this.despesaDTO).subscribe(
            (response: ReturnAPI<void>) => {
              this.dialogService.feedbackReturnAPI(response, 'Cancelamento realizado com sucesso.');
              this.listarTransferencias();
            },
            (e: HttpErrorResponse) => {
              this.dialogService.feedbackError(`Houve um erro ao cancelar Despesa.\n ${e.message}`);
            },
          );
        }
      });
  }

  cancelarComplemento(transferencia: Transferencia): void {
    this.dialog
      .open(ConfirmacaoDialogComponent, {
        width: '300px',
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.transferenciaService.cancelarComplemento(transferencia.id).subscribe(
            (response: ReturnAPI<void>) => {
              this.dialogService.feedbackReturnAPI(response, 'Cancelamento realizado com sucesso.');
              this.listarTransferencias();
            },
            (e: HttpErrorResponse) => {
              this.dialogService.feedbackError(`Houve um erro ao cancelar Complemento.\n ${e.message}`);
            },
          );
        }
      });
  }

  private confirmarComplemento(dialogRef: MatDialogRef<ConfirmacaoDialogComponent>, transferencia: Transferencia): void {
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.transferenciaService.confirmarComplemento(transferencia.id).subscribe(
          (response: ReturnAPI<void>) => {
            this.dialogService.feedbackReturnAPI(response, 'Confirmação realizada com sucesso.');
            this.listarTransferencias();
          },
          (e: HttpErrorResponse) => {
            this.dialogService.feedbackError(`Houve um erro ao confirmar Complemento.\n ${e.message}`);
          },
        );
      }
    });
  }

  private confirmarDespesaAdministrativa(dialogRef: MatDialogRef<ConfirmacaoDialogComponent>, transferencia: Transferencia): void {
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.despesaDTO.postoId = transferencia.posto.id;
        this.despesaDTO.valor = transferencia.valorRealizado;
        this.despesaDTO.transferenciaId = transferencia.id;
        this.appState.isLoading = true;
        this.transferenciaService.confirmarDespesaAdministrativa(this.despesaDTO).subscribe(
          (response: ReturnAPI<void>) => {
            this.appState.isLoading = false;
            this.dialogService.feedbackReturnAPI(response, 'Confirmação realizada com sucesso.');
            this.listarTransferencias();
          },
          (e: HttpErrorResponse) => {
            this.appState.isLoading = false;
            this.dialogService.feedbackError(`Houve um erro ao confirmar Despesa.\n ${e.error.message}`);
          },
        );
      }
    });
  }

  private confirmarSangriaOdin(dialogRef: MatDialogRef<ConfirmacaoDialogComponent>, transferencia: Transferencia): void {
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.transferenciaService.confirmarSangriaOdin(transferencia.id).subscribe(
          (response: ReturnAPI<void>) => {
            this.dialogService.feedbackReturnAPI(response, 'Confirmação realizada com sucesso.');
            this.listarTransferencias();
          },
          (e: HttpErrorResponse) => {
            this.dialogService.feedbackError(`Houve um erro ao realizar sangria.\n ${e.message}`);
          },
        );
      }
    });
  }

  async realizarSangriaOdin(transferencia: Transferencia): Promise<void> {
    const dialogRef = this.dialog.open(ConfirmacaoDialogComponent, { width: '300px' });

    const result = await dialogRef.afterClosed().toPromise();
    if (result) {
      try {
        const transferenciaDTO = new TransferenciaDTO();

        transferenciaDTO.idPostoOrigem = transferencia.posto.id;
        transferenciaDTO.idTransferencia = transferencia.id;
        transferenciaDTO.valor = transferencia.valorRealizado;

        const _ = await this.transferenciaService.realizarSangriaOdin(transferenciaDTO).toPromise();
        this.dialogService.feedbackSuccess('Sangria para o caixa odin realizada com sucesso');
        this.listarTransferencias();
      } catch (e) {
        this.dialogService.feedbackError(e.error.message);
      }
    }
  }

  onChangeTabView(id: number): void {
    this.tipoMovimentacoes = id === 0 ? 'entradas' : 'saidas';
    this.listarTransferencias();
  }

  onAcaoClick(event: TransferenciaAcaoEvent): void {
    const actions = new Map<string, (transferencia: Transferencia) => void>()
      .set(CaixaControleMovimentacoesComponent.CONFIRMAR_TRANSFERENCIA_ACTION, (transferencia: Transferencia) => {
        this.confimarTransferencia(transferencia);
      })
      .set(CaixaControleMovimentacoesComponent.REALIZAR_TRANSFERENCIA_ACTION, (transferencia: Transferencia) => {
        this.realizarTransferenciaComplemento(transferencia);
      })
      .set(CaixaControleMovimentacoesComponent.REALIZAR_SANGRIA_ACTION, (transferencia: Transferencia) => {
        this.realizarSangriaOdin(transferencia);
      })
      .set(CaixaControleMovimentacoesComponent.CANCELAR_TRANSFERENCIA_ACTION, (transferencia: Transferencia) => {
        this.cancelarTransferencia(transferencia);
      })
      .set(CaixaControleMovimentacoesComponent.CANCELAR_COMPLEMENTO, (transferencia: Transferencia) => {
        this.cancelarComplemento(transferencia);
      });

    actions.get(event.acao)(event.transferencia);
  }

  async realizarTransferenciaComplemento(transferencia: Transferencia): Promise<void> {
    let postosEmBusca: Posto[] = [];
    let postoDestino: Posto = null;
    if (transferencia.postoDestino) {
      postosEmBusca = await this.consultaPostos('');
      postoDestino = transferencia.postoDestino;
    } else {
      postosEmBusca = await this.consultaPostosOutroEstado('', parseInt(transferencia.postoDestinoUID.slice(0, 2), 10));
      postoDestino = postosEmBusca.find((posto: Posto) => posto.uid === transferencia.postoDestinoUID);
      postosEmBusca = [postoDestino];
    }
    const valorTransferencia: string = this.generateMask(transferencia.valorRealizado);
    this.dialog
      .open<CheckboxSelectFormComponent, CheckboxSelectFormData, CheckboxSelectFormResultData>(CheckboxSelectFormComponent, {
        data: {
          title: `Selecionar posto de destino.`,
          infoText: `Transferir ${valorTransferencia} de ${transferencia.origem.posto.descricao} para: `,
          noCheckbox: true,
          inputPlaceholder: 'Posto',
          postoDestino: postoDestino,
          selectableOptions: postosEmBusca.map((posto: Posto) => posto.descricao),
          onAsyncSelectableFilter: async (value: string): Promise<string[]> => {
            postosEmBusca = transferencia.postoDestino ? await this.consultaPostos(value) : postosEmBusca;
            return postosEmBusca.map((posto: Posto) => posto.descricao);
          },
        },
        width: '500px',
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((result: CheckboxSelectFormResultData) => {
        this.appState.isLoading = true;
        if (result) {
          const transferenciaDTO: TransferenciaDTO = new TransferenciaDTO();
          transferenciaDTO.idPostoOrigem = transferencia.posto.id;
          transferenciaDTO.idPostoDestino = postosEmBusca[result.optionSelected].id;
          transferenciaDTO.postoOrigemUID = transferencia.posto.uid;
          transferenciaDTO.postoDestinoUID = postosEmBusca[result.optionSelected].uid;
          transferenciaDTO.idTransferencia = transferencia.id;
          transferenciaDTO.valor = transferencia.valorRealizado;

          this.transferenciaService.realizarTransferenciaComplemento(transferenciaDTO).subscribe(
            (response: ReturnAPI<void>) => {
              this.dialogService.feedbackReturnAPI(response, 'Transferencia realizada com sucesso.');
              this.listarTransferencias();
              this.appState.isLoading = false;
            },
            (e: HttpErrorResponse) => {
              this.dialogService.feedbackError(`Houve um erro ao transferir complemento.\n ${e.error.message}`);
              this.appState.isLoading = false;
            },
          );
        } else {
          this.appState.isLoading = false;
        }
      });
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
