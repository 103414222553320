import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { DashBoardEstadoFaturamento } from '../domain/dashboard-estado-faturamento.model';
import { DashboardProrrogado } from '../domain/dashboard-prorrogado.model';
import { DashBoardProrrogadoSerializer } from '../serializables/dashboard-prorrogado.serializer';

@Injectable()
export class DashBoardProrrogadoService extends ResourceService<DashboardProrrogado> {
  static RESOURCE = 'relatorio-prorrogado-view';

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${DashBoardProrrogadoService.RESOURCE}`, new DashBoardProrrogadoSerializer(DashboardProrrogado));
  }

  getDashboard(queryOption: QueryOptions): Observable<DashboardProrrogado> {
    return this.httpClient.get<DashboardProrrogado>(
      `${WEB_API}/${DashBoardProrrogadoService.RESOURCE}/dashboard-prorrogado?${queryOption.toQueryString()}`,
    );
  }

  getDashboardWithoutRefresh(queryOption: QueryOptions): Observable<DashboardProrrogado> {
    return this.httpClient.get<DashboardProrrogado>(
      `${WEB_API}/${DashBoardProrrogadoService.RESOURCE}/dashboard-prorrogado-without-refresh?${queryOption.toQueryString()}`,
    );
  }

  getDashboardEstado(estadoId: number): Observable<DashBoardEstadoFaturamento> {
    return this.httpClient.get<DashBoardEstadoFaturamento>(
      `${WEB_API}/${DashBoardProrrogadoService.RESOURCE}/dashboard-estado-prorrogado/${estadoId}`,
    );
  }

  getDashboardEstadoFaturamento(): Observable<DashBoardEstadoFaturamento[]> {
    return this.httpClient.get<DashBoardEstadoFaturamento[]>(
      `${WEB_API}/${DashBoardProrrogadoService.RESOURCE}/resumo-estado-prorrogado`,
    );
  }
}
