import { Component, OnInit } from '@angular/core';
import { Transferencia } from 'src/app/base/domain/transferencia.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { DespesaDTO } from 'src/app/base/domain/dto/despesa.dto';
import { ActivatedRoute } from '@angular/router';
import { TransferenciaService } from 'src/app/base/services/transferencia.service';
import { PostoService } from 'src/app/base/services/posto.service';
import { DialogService } from 'src/app/global/services/dialog.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppState } from 'src/app/App.state';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import { ConfirmacaoDialogComponent } from 'src/app/global/components/confirmacao-dialog/confirmacao-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import {
  TransferenciaAcaoEvent,
  TransferenciaListFilterOptions,
} from 'src/app/global/lists/transferencia-list/transferencia-list.component';
import { ValoresTransferenciaRequestDTO } from 'src/app/base/domain/dto/valores-transferencia-request.dto';

@Component({
  selector: 'app-acompanhamento-despesa-administrativa-main',
  templateUrl: './acompanhamento-despesa-administrativa-main.component.html',
  styleUrls: ['./acompanhamento-despesa-administrativa-main.component.css'],
})
export class AcompanhamentoDespesaAdministrativaMainComponent implements OnInit {
  private static CONFIRMAR_TRANSFERENCIA_ACTION = 'Confirmar Transferência';
  private static CANCELAR_TRANSFERENCIA_ACTION = 'Cancelar Transferência';

  private queryOptions = new QueryOptions({ pageSize: 10, pageSort: '-id', params: { type: 'tdect' } });

  transferencias: Transferencia[];
  totalRecords: number;
  loading = false;
  optionalColumns = ['descricao', 'natureza', 'posto', 'acoes', 'categoriaPlanoConta'];

  despesaDTO = new DespesaDTO();

  private postoSelecionado: number = null;
  private dataInicialSelecionada: Date = null;
  private dataFinalSelecionada: Date = null;

  totalizador = 0;

  constructor(
    private transferenciaService: TransferenciaService,
    public dialog: MatDialog,
    public dialogService: DialogService,
    public appState: AppState,
  ) {}

  ngOnInit(): void {
    this.listarTransferencias();
  }

  onLoadTransferencias(event: number): void {
    this.listarTransferencias(event);
  }

  mapearAcoesDeTransferencias(transferencias: Transferencia[]): void {
    const mapearAcoes = (transferencia: Transferencia): Transferencia => {
      const acoes = [];
      if (transferencia.natureza && transferencia.situacao !== 'COMPLEMENTO_ENTRE_POSTOS_TRANSFERIDO') {
        if (transferencia.situacao === 'CONFIRMACAO_PENDENTE') {
          acoes.push(AcompanhamentoDespesaAdministrativaMainComponent.CONFIRMAR_TRANSFERENCIA_ACTION);
        }
        if (transferencia.natureza === 'DESPESA_ADMINISTRATIVA' && transferencia.situacao === 'CONFIRMACAO_PENDENTE') {
          acoes.push(AcompanhamentoDespesaAdministrativaMainComponent.CANCELAR_TRANSFERENCIA_ACTION);
        }
      }
      return { ...transferencia, acoes };
    };

    this.transferencias = transferencias?.map(mapearAcoes) ?? [];
  }

  listarTransferencias(page: number = 0, valores: Partial<ValoresTransferenciaRequestDTO> = {}): void {
    this.queryOptions.pageNumber = page + 1;
    this.loading = true;
    this.transferenciaService.pageTransferencias(this.queryOptions).subscribe((response: ReturnAPI<Page<Transferencia>>) => {
      this.totalRecords = response.object.totalElements;
      this.mapearAcoesDeTransferencias(response.object.content);
      valores.tipo = 'TDECT';
      valores.dataInicial = valores.dataInicial ? valores.dataInicial : null;
      valores.dataFinal = valores.dataFinal ? valores.dataFinal : null;
      valores.nome = valores.nome ? valores.nome : '';
      valores.descricaoRota = valores.descricaoRota ? valores.descricaoRota : '';
      this.getValorTotalPorTipoTransferencia({ ...valores, postoId: this.postoSelecionado });
      this.loading = false;
    });
  }

  onAcaoClick(event: TransferenciaAcaoEvent): void {
    const actions = new Map<string, (transferencia: Transferencia) => void>()
      .set(AcompanhamentoDespesaAdministrativaMainComponent.CONFIRMAR_TRANSFERENCIA_ACTION, (transferencia: Transferencia) => {
        this.confimarTransferencia(transferencia);
      })
      .set(AcompanhamentoDespesaAdministrativaMainComponent.CANCELAR_TRANSFERENCIA_ACTION, (transferencia: Transferencia) => {
        this.cancelarTransferencia(transferencia);
      });

    actions.get(event.acao)(event.transferencia);
  }

  confimarTransferencia(transferencia: Transferencia): void {
    const dialogRef = this.dialog.open(ConfirmacaoDialogComponent, {
      width: '300px',
    });

    if (transferencia.natureza === 'DESPESA_ADMINISTRATIVA') {
      this.confirmarDespesaAdministrativa(dialogRef, transferencia);
    }
  }

  private confirmarDespesaAdministrativa(dialogRef: MatDialogRef<ConfirmacaoDialogComponent>, transferencia: Transferencia): void {
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.despesaDTO.postoId = transferencia.posto.id;
        this.despesaDTO.valor = transferencia.valorRealizado;
        this.despesaDTO.transferenciaId = transferencia.id;
        this.appState.isLoading = true;
        this.transferenciaService.confirmarDespesaAdministrativa(this.despesaDTO).subscribe(
          (response: ReturnAPI<void>) => {
            this.appState.isLoading = false;
            this.dialogService.feedbackReturnAPI(response, 'Confirmação realizada com sucesso.');
            this.listarTransferencias();
          },
          (e: HttpErrorResponse) => {
            this.appState.isLoading = false;
            this.dialogService.feedbackError(`Houve um erro ao confirmar Despesa.\n ${e.error.message}`);
          },
        );
      }
    });
  }

  cancelarTransferencia(transferencia: Transferencia): void {
    this.dialog
      .open(ConfirmacaoDialogComponent, {
        width: '300px',
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.despesaDTO.postoId = transferencia.posto.id;
          this.despesaDTO.valor = transferencia.valorRealizado;
          this.despesaDTO.transferenciaId = transferencia.id;
          this.despesaDTO.categoria = transferencia.categoria;

          this.transferenciaService.cancelarDespesaAdministrativa(this.despesaDTO).subscribe(
            (response: ReturnAPI<void>) => {
              this.dialogService.feedbackReturnAPI(response, 'Cancelamento realizado com sucesso.');
              this.listarTransferencias();
            },
            (e: HttpErrorResponse) => {
              this.dialogService.feedbackError(`Houve um erro ao cancelar Despesa.\n ${e.message}`);
            },
          );
        }
      });
  }

  onFilter(options: TransferenciaListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    const dataInicial = options.dataInicial || null;
    const dataFinal = options.dataFinal || null;

    this.queryOptions.params = {
      ...this.queryOptions.params,
      nomeFuncionario: options.nome || null,
      situacao: options.situacoesTransferencia?.length ? `in:${options.situacoesTransferencia.join(',')}` : null,
      dataInicio: dataInicial?.toLocaleString(),
      dataFinal: dataFinal?.toLocaleString(),
      postoId,
    };

    if (dataInicial !== this.dataInicialSelecionada || dataFinal !== this.dataFinalSelecionada) {
      this.dataInicialSelecionada = dataInicial;
      this.dataFinalSelecionada = dataFinal;
    }

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }

    this.listarTransferencias(0, {
      descricaoRota: options.descricaoRota,
      dataInicial: options?.dataInicial,
      dataFinal: options?.dataFinal,
    });
  }

  getValorTotalPorTipoTransferencia(valores: Partial<ValoresTransferenciaRequestDTO>): void {
    this.transferenciaService.getValorTotalPorTipoTransferencia(valores).subscribe((response: ReturnAPI<number>) => {
      this.totalizador = response.object || 0;
    });
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
