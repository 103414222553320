import { Component, Inject, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Moment } from 'moment';
import { CobrancaJudicial } from 'src/app/base/domain/cobranca-judicial.model';

export interface PequenaCausaFormData {
  cobranca: CobrancaJudicial;
  action: string;
}

@Component({
  selector: 'app-alterar-emissao-cobranca-judicial-form',
  templateUrl: './alterar-emissao-cobranca-judicial-form.component.html',
  styleUrls: ['./alterar-emissao-cobranca-judicial-form.component.css']
})
export class AlterarEmissaoCobrancaJudicialFormComponent implements OnInit {

  cobrancaJudicial: CobrancaJudicial;
  dataEmissao: Date;
  title: string;

  constructor(
    public dialogRef: MatDialogRef<AlterarEmissaoCobrancaJudicialFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PequenaCausaFormData
  ) {
    this.title = 'Alterar emissão da Cobrança Judicial';
    this.cobrancaJudicial = this.data.cobranca;
  }

  ngOnInit(): void { }

  onConfirm(): void {

    this.cobrancaJudicial.dataCadastro = this.dataEmissao;
    this.dialogRef.close(this.cobrancaJudicial);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onPickerEventEmissao(event: MatDatepickerInputEvent<Moment>): void {
    this.cobrancaJudicial.dataCadastro = event.value?.toDate() || null;
    this.dataEmissao = new Date(this.cobrancaJudicial.dataCadastro);
  }

  fdsFilter = (d: Date | null): boolean => {
    const date = d ? new Date(d) : new Date();
    const day = date.getDay();
    return day !== 0 && day !== 6;
  }

}
