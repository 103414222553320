import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PendenciaFechamentoCaixaMainComponent } from './pendencia-fechamento-caixa-main/pendencia-fechamento-caixa-main.component';
import { GlobalModule } from 'src/app/global/global.module';

@NgModule({
  declarations: [PendenciaFechamentoCaixaMainComponent],
  imports: [CommonModule, GlobalModule],
})
export class PendenciaFechamentoCaixaModule {}
