import { Resource } from 'src/app/global/domain/resource.model';
import { ArquivoAnaliseDeRisco } from './arquivo-analise-de-risco.model';
import { Cliente } from './cliente.model';
import { Rota } from './rota.model';

export class AnaliseDeRiscoCliente extends Resource {
  cliente: Cliente;

  rota: Rota;

  quantidadeDiasAtraso: number;

  quantidadeOperacoes: number;

  valorTotal: number;

  media: number;

  filtros: string;

  arquivoAnalise: ArquivoAnaliseDeRisco;

  arquivos: string[];
}
