import { Equipe } from 'src/app/base/domain/equipe.model';
import { DialogService } from 'src/app/global/services/dialog.service';
import { EquipeService } from 'src/app/base/services/equipe.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { AppState } from 'src/app/App.state';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import { Component } from '@angular/core';
import { EquipeListFilterOptions, EquipeAcaoEvent } from 'src/app/global/lists/equipe-list/equipe-list.component';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-equipes-main',
  templateUrl: './equipes-main.component.html',
  styleUrls: ['./equipes-main.component.css'],
})
export class EquipesMainComponent {
  private static DISCLOSURE = 'disclosure';
  private static DELETAR_EQUIPE = 'Excluir Equipe';

  equipes: Equipe[] = [];

  queryOptions = new QueryOptions({ pageSize: 10 });
  totalRecords = 0;

  private postoSelecionado: number = null;

  constructor(
    private router: Router,
    private equipeService: EquipeService,
    private dialogService: DialogService,
    public appState: AppState,
  ) {}

  listarEquipes(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.appState.isLoading = true;
    this.equipeService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Equipe>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.mapearAcoes(response.object.content);
      } else {
        this.equipes = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.appState.isLoading = false;
    });
  }

  mapearAcoes(equipes: Equipe[]): void {
    const mapearAcoes = (caixa: Equipe): Equipe => {
      const acoes = [];
      acoes.push(EquipesMainComponent.DISCLOSURE);
      acoes.push(EquipesMainComponent.DELETAR_EQUIPE);

      return { ...caixa, acoes };
    };

    this.equipes = equipes?.map(mapearAcoes) ?? [];
  }

  onEquipesPage(page: number): void {
    this.listarEquipes(page);
  }

  onAcaoClick(event: EquipeAcaoEvent): void {
    const actions = new Map<string, (equipe: Equipe) => void>()
      .set(EquipesMainComponent.DISCLOSURE, (equipe: Equipe) => {
        this.onDisclosure(equipe);
      })
      .set(EquipesMainComponent.DELETAR_EQUIPE, (equipe: Equipe) => {
        this.delete(equipe);
      });
    actions.get(event.acao)(event.equipe);
  }

  delete(equipe: Equipe): void {
    this.dialogService.confirmDialog('Deseja realmente excluir essa equipe?', () => {
      this.dialogService.auditoriaDialog(() => {
        this.equipeService.deletar(equipe.id).subscribe(
          (response: ReturnAPI<void>) => {
            this.dialogService.feedbackReturnAPI(response, 'Equipe deletada com sucesso.');
            this.listarEquipes();
          },
          (e: HttpErrorResponse) => {
            this.dialogService.feedbackError(`Houve um erro ao deletar Equipe.\n ${e.message}`);
          },
        );
      });
    });
  }

  onDisclosure(equipe: Equipe): void {
    this.router.navigate(['base/gestao/equipe-detail/' + equipe.id]);
  }

  onFilter(options: EquipeListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    this.queryOptions.params = {
      postoId,
      descricao: options.nomeEquipe ? `like:${options.nomeEquipe}` : null,
      nomeFiscal: options.nomeFiscal ? `${options.nomeFiscal}` : null,
    };
    this.listarEquipes();
    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }
  }
}
