import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConciliacaoDTO } from 'src/app/base/domain/dto/conciliacao.dto';
import { TipoConciliacaoBancaria } from '../../../base/domain/enum/tipo-conciliacao-bancaria.enum';
import { ContaTevService } from '../../../base/services/conta-tev.service';
import { QueryOptions } from '../../domain/query.options';
import { ContaTev } from '../../../base/domain/conta-tev.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';

export interface FormData {
  conciliacao: ConciliacaoDTO;
  type: string;
  action: string;
}

@Component({
  selector: 'app-conciliacao-form',
  templateUrl: './conciliacao-form.component.html',
  styleUrls: ['./conciliacao-form.component.css'],
})
export class ConciliacaoFormComponent implements OnInit {
  title: string;
  conciliacao = new ConciliacaoDTO();
  isTev = false;
  isTed = false;
  isDinheiro = false;
  isDoc = false;
  queryOptions = new QueryOptions({ pageSize: 1000 });
  contas: ContaTev[] = [];
  tipos: TipoConciliacaoBancaria[] = Object.values(TipoConciliacaoBancaria);

  constructor(
    public dialogRef: MatDialogRef<ConciliacaoFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormData,
    private contaTevService: ContaTevService,
  ) {
    this.contaTevService.list(this.queryOptions).subscribe((response) => {
      this.contas = response;
    });

    if (data.type == 'tev') {
      this.isTev = true;
    } else if (data.type == 'ted') {
      this.isTed = true;
    } else if (data.type == 'dinheiro') {
      this.isDinheiro = true;
    } else {
      this.isDoc = true;
    }

    if (data.action === 'update') {
      this.title = 'Editar';
      this.conciliacao = data.conciliacao;
    } else {
      this.title = 'Novo';
    }
  }

  ngOnInit(): void { }

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(this.conciliacao);
  }

  onPickerEvent(event: MatDatepickerInputEvent<Moment>): void {
    this.conciliacao.data = event.value?.toDate() || null;
  }
}
