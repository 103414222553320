import { Component, OnInit } from '@angular/core';
import { CobrancaVale } from 'src/app/base/domain/cobranca-vale.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { AppState } from 'src/app/App.state';
import { DialogService } from 'src/app/global/services/dialog.service';
import { CobrancaValeService } from 'src/app/base/services/cobranca-vale.service';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import {
  CobrancaValeListFilterOptions,
  CobrancaValeAcaoEvent,
} from 'src/app/global/lists/debitos-funcionario-list/debitos-funcionario-list.component';

@Component({
  selector: 'app-debitos-funcionario-main',
  templateUrl: './debitos-funcionario-main.component.html',
  styleUrls: ['./debitos-funcionario-main.component.css'],
})
export class DebitosFuncionarioMainComponent implements OnInit {
  cobrancas: CobrancaVale[] = [];

  queryOptions = new QueryOptions({
    pageSize: 25,
  });

  totalRecords = 0;
  loading = false;

  private postoSelecionado: number = null;

  menuAcoes: string[] = [];

  constructor(
    public appState: AppState,
    public dialogService: DialogService,
    private service: CobrancaValeService,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.snapshot.queryParams = this.queryOptions.params;
  }

  listar(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.queryOptions.params = { ...this.activatedRoute.snapshot.queryParams, joinRota: true };
    this.service.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<CobrancaVale>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.cobrancas = response.object.content;
        this.mapearAcoes(response.object.content);
      } else {
        this.cobrancas = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
    });
  }

  onLoadPage(page: number): void {
    this.listar(page);
  }

  mapearAcoes(cobrancas: CobrancaVale[]): void {
    const mapearAcoes = (cobranca: CobrancaVale): CobrancaVale => {
      const acoes = [];
      return { ...cobranca, acoes };
    };

    this.cobrancas = cobrancas?.map(mapearAcoes) ?? [];
  }

  onAcaoClick(event: CobrancaValeAcaoEvent): void {
    const actions = new Map<string, (cobranca: CobrancaVale) => void>();
    actions.get(event.acao)(event.cobranca);
  }

  onDisclosure(cobranca: CobrancaVale): void {
    this.router.navigate(['base/contratos/debitos-funcionarios-detail/' + cobranca.id]);
  }

  onFilter(options: CobrancaValeListFilterOptions): void {
    console.log(options);

    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    this.queryOptions.params = {
      funcionario: options.funcionario ? `${options.funcionario}` : null,
      postoId,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
    };

    this.router.navigate(['/base/contratos/debitos-funcionarios'], { queryParams: this.queryOptions.params }).then(() => this.listar());

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }
  }
}
