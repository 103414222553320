import { Component, OnInit } from '@angular/core';
import { CaptadoListFilterOptions } from 'src/app/global/lists/captado-list/captado-list.component';
import { Posto } from 'src/app/base/domain/posto.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { CaptadoService } from 'src/app/base/services/captado.service';
import { DialogService } from 'src/app/global/services/dialog.service';
import { Captado } from 'src/app/base/domain/captado.model';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';

import { CaptadoAcaoEvent } from 'src/app/global/lists/captado-list/captado-list.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmacaoDialogComponent } from 'src/app/global/components/confirmacao-dialog/confirmacao-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { AppState } from 'src/app/App.state';
import { VisitaService } from 'src/app/base/services/visita.service';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { SharedService } from 'src/app/auth/shared.service';
import { OrigemAcaoDeVendaEnum } from 'src/app/base/domain/enum/origem-acao-de-venda.enum';

@Component({
  selector: 'app-potencial-cliente-main',
  templateUrl: './potencial-cliente-main.component.html',
  styleUrls: ['./potencial-cliente-main.component.css'],
})
export class PotencialClienteMainComponent implements OnInit {
  private static RECAPTAR = 'Recaptar Cliente';
  static NOVA_ACAO_DE_VENDA = 'Nova Ação de Venda';
  menuAcoes: string[] = [PotencialClienteMainComponent.NOVA_ACAO_DE_VENDA];
  captados: Captado[] = [];
  queryOptions = new QueryOptions({ pageSize: 10 });
  totalRecords = 0;
  loading = false;
  private allSelected = false;
  usuario: Usuario;
  private postoSelecionado: number = null;
  view = 'POTENCIAIS_CLIENTES';
  constructor(
    private captadoService: CaptadoService,
    public dialogService: DialogService,
    public dialog: MatDialog,
    private appState: AppState,
    private visitaService: VisitaService,
  ) {}

  ngOnInit(): void {
    const shared = SharedService.getInstance();
    this.usuario = shared.usuario;
    this.queryOptions.params = {
      situacaoCaptado: `in:POTENCIAL_CLIENTE`,
    };
  }

  listarClientesCaptados(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.captadoService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Captado>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.captados = response.object.content;
        this.mapearAcoes(response.object.content);
      } else {
        this.captados = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
    });
  }

  mapearAcoes(captados: Captado[]): void {
    const mapearAcoes = (captado: Captado): Captado => {
      const acoes = [];
      acoes.push(PotencialClienteMainComponent.RECAPTAR);
      return { ...captado, acoes };
    };

    this.captados = captados?.map(mapearAcoes) ?? [];
  }

  onAcaoClick(event: CaptadoAcaoEvent): void {
    const actions = new Map<string, (captado: Captado) => void>()
      .set(PotencialClienteMainComponent.RECAPTAR, (captado: Captado) => {
        this.onRecaptar(captado);
      })
      .set(PotencialClienteMainComponent.NOVA_ACAO_DE_VENDA, this.cadastrarAcoesDeVenda.bind(this));
    actions.get(event.acao)(event.captado);
  }

  onRecaptar(captado: Captado): void {
    this.dialog
      .open(ConfirmacaoDialogComponent, {
        width: '300px',
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.captadoService.recaptar(captado.id).subscribe(
            (response: ReturnAPI<Captado>) => {
              this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
              this.listarClientesCaptados();
            },
            (e: HttpErrorResponse) => {
              this.dialogService.feedbackError(`Houve um erro ao realizar operação.\n ${e.message}`);
            },
          );
        }
      });
  }

  async cadastrarAcoesDeVenda(_: never): Promise<void> {
    let quantidade = this.totalRecords;

    if (!this.allSelected) {
      quantidade = this.captados.filter((capt: Captado) => capt.selecionado).length;
    }

    const confirm = async (_preAprovar: boolean) => {
      this.appState.isLoading = true;

      try {
        const { joinRota, ...options } = this.queryOptions.params;
        const res = await this.visitaService
          .registrarAcoesDeVenda({
            allSelected: this.allSelected,
            captacao: true,
            preAprovar: _preAprovar,
            clientesIds: null,
            captadosIds: this.allSelected ? [] : this.captados.filter((capt: Captado) => capt.selecionado).map((capt: Captado) => capt.id),
            params: this.allSelected
              ? JSON.stringify({
                  ...options,
                  page: '1',
                  size: this.totalRecords.toString(),
                })
              : '',
            usuarioHeimdallId: this.usuario.id,
            captadorNome: this.usuario.nome,
            origem: OrigemAcaoDeVendaEnum.POTENCIAIS_CLIENTES,
          })
          .toPromise();
        this.dialogService.feedbackReturnAPI(res, 'Ações de Venda registradas com sucesso');
      } catch (error) {
        this.dialogService.feedbackError('Não foi possível fazer os registros');
      } finally {
        this.appState.isLoading = false;
      }
    };

    this.dialogService.checkboxDialog({
      title: 'Cadastrar Ações de Venda',
      message: `
        Deseja registrar uma ação de venda para os captados selecionados?
        Quantidade de novas ações: ${quantidade}`,
      confirmfn: confirm.bind(this),
    });
  }

  loadCaptadosPage(page: number): void {
    this.listarClientesCaptados(page);
  }

  onFilter(options: CaptadoListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;

    this.queryOptions.params = {
      nomeCliente: options.nomeCaptado ? `like:${options.nomeCaptado}` : null,
      nomeCaptador: options.nomeCaptador ? `${options.nomeCaptador}` : null,
      situacoesCaptado: `in:POTENCIAL_CLIENTE`,
      dataInicio: options?.dataInicial?.toLocaleString(),
      dataFinal: options?.dataFinal?.toLocaleString(),
      postoId,
    };

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }

    this.listarClientesCaptados();
  }
}
