import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CarteiraCliente } from 'src/app/base/domain/carteira-cliente.model';
import { Funcionario } from 'src/app/base/domain/funcionario.model';
import { PublicService } from 'src/app/base/services/public.service';

export interface CarteiraClienteFormData {
  carteiraCliente: CarteiraCliente;
  action: string;
  funcionario: Funcionario;
}

@Component({
  selector: 'app-carteira-cliente-form',
  templateUrl: './carteira-cliente-form.component.html',
  styleUrls: ['./carteira-cliente-form.component.css'],
})
export class CarteiraClienteFormComponent implements OnInit {
  carteiraCliente = new CarteiraCliente();
  title = '';

  constructor(
    public dialogRef: MatDialogRef<CarteiraClienteFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CarteiraClienteFormData,
    private publicService: PublicService,
  ) {}

  ngOnInit(): void {}

  onConfirm(): void {
    this.carteiraCliente.funcionario = this.data.funcionario;
    this.carteiraCliente.dataCadastro = this.data.carteiraCliente ? this.data.carteiraCliente.dataCadastro : new Date();
    this.carteiraCliente.ativo = this.data.carteiraCliente ? this.data.carteiraCliente.ativo : true;
    this.dialogRef.close(this.carteiraCliente);
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
