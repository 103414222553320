import { Component, ElementRef, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import Chart from 'chart.js/auto';
import { registerables } from 'chart.js';

Chart.register(...registerables);
export interface MixedChartTemplate {
  canvas: string;
  elementIndex: number;
  heigth: number;
  maxHeigth: string;
  labels: string[];
  barData: number[];
  barChartLabel: string;
  barBorderColor: string;
  barBackgroundColor: string;
  lineData: number[];
  lineChartLabel: string;
  lineBorderColor: string;

  estadoId?: number;
  estado?: string;
  postoId?: number;
  posto?: string;
  rota?: string;
  funcionario?: string;
}

@Component({
  selector: 'app-mixed-chart',
  templateUrl: './mixed-chart.component.html',
  styleUrls: ['./mixed-chart.component.css']
})
export class MixedChartComponent implements OnInit, AfterViewInit {
  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;
  public context: CanvasRenderingContext2D;

  @Input() template: MixedChartTemplate;

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.context = this.canvas.nativeElement.getContext('2d');
    this.configureCanvasChart();
  }

  configureCanvasChart(): void {
    this.canvas.nativeElement.id = this.template.canvas;
    this.canvas.nativeElement.height = this.template.heigth;
    this.canvas.nativeElement.style.maxHeight = this.template.maxHeigth;

    const data = {
      labels: this.template.labels,
      datasets: [{
        type: 'bar',
        label: this.template.barChartLabel,
        data: this.template.barData,
        borderColor: this.template.barBorderColor,
        backgroundColor: this.template.barBackgroundColor
      },
      {
        type: 'line',
        label: this.template.lineChartLabel,
        data: this.template.lineData,
        fill: false,
        borderColor: this.template.lineBorderColor
      }
      ]
    };

    const config: any = {
      type: 'scatter',
      data: data,
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    };

    const chart = new Chart(this.context, config);
  }
}
