import { Resource } from 'src/app/global/domain/resource.model';

export class DashboardCobranca extends Resource {
  nomeEstado: string;
  descricaoPosto: string;
  
  valorTotalAReceber: number;
  valorTotalAReceberRegular: number;
  valorTotalAReceberIrregular: number;
  
  valorTotalRecebido: number;
  valorTotalRecebidoRegular: number;
  valorTotalRecebidoIrregular: number;
  
  valorTotalNaoRecebido: number;
  valorTotalNaoRecebidoRegular: number;
  valorTotalNaoRecebidoIrregular: number;
  
  quantidadeCobrado: number;
  quantidadeCobradoRegular: number;
  quantidadeCobradoIrregular: number;
  
  quantidadeNaoCobrado: number;
  quantidadeNaoCobradoRegular: number;
  quantidadeNaoCobradoIrregular: number;
  
  quantidadeTotal: number;
  quantidadeTotalRegular: number;
  quantidadeTotalIrregular: number;
  
  percentualCobrado: number;
  percentualNaoCobrado: number;
  percentualRecebido: number;
  percentualNaoRecebido: number;

  mediaQuantidadeCobrado: number;
  mediaQuantidadeNaoCobrado: number;
  mediaValorRecebido: number;
  mediaValorNaoRecebido: number
}
