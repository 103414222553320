import { Posto } from 'src/app/base/domain/posto.model';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { QueryOptions } from '../../domain/query.options';
import { VisitaService } from 'src/app/base/services/visita.service';
import { Visita } from 'src/app/base/domain/visita.model';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { AprovacoesDeCreditoPendentesDTO } from 'src/app/base/domain/dto/aprovacoes-de-credito-pendentes-dto';
import { LazyLoadEvent } from 'primeng/api';
import { AppState } from 'src/app/App.state';

export interface QueryFilter {
  dataInicial: Date;
  dataFinal: Date;
  posto: Posto;
  nome: string;
}

@Component({
  selector: 'app-aprovacao-de-credito-list',
  templateUrl: './aprovacao-de-credito-list.component.html',
  styleUrls: ['./aprovacao-de-credito-list.component.css']
})
export class AprovacaoDeCreditoListComponent implements OnInit {

  @Output() metaData = new EventEmitter<QueryFilter>();
  @Output() resultData = new EventEmitter<ReturnAPI<AprovacoesDeCreditoPendentesDTO[]>>();
  @Output() imgData = new EventEmitter<number>();
  @Output() visitaEdit = new EventEmitter<Visita>();

  view = false;

  rows = 10;
  aprovacoesTotalRecords = 0;
  preAprovacoesTotalRecords = 0;

  aprovacoes: Page<Visita>;
  preAprovacoes: Page<Visita>;

  defaultQueryFilter: QueryFilter = {
    posto: null,
    nome: null,
    dataInicial: null,
    dataFinal: null,
  };

  queryOptions: QueryOptions = new QueryOptions();
  filterOptions: QueryFilter = this.defaultQueryFilter;

  constructor(private visitaService: VisitaService, private appState: AppState) { }

  ngOnInit(): void {
    this.setDefaultFilter();
    this.setQueryOptions();
    this.load();
  }

  load(): void {
    this.appState.isLoading = true;
    this.visitaService.listarAprovacoesPendentes(this.queryOptions).subscribe(
      (response: ReturnAPI<AprovacoesDeCreditoPendentesDTO[]>) => {
        this.appState.isLoading = false;
        if (response.success) {
          this.firstPageLoad();
          this.resultData.emit(response);
        }
      }
    );
  }

  firstPageLoad(): void {
    const pageOptions: QueryOptions = new QueryOptions({pageNumber: 1, pageSize: 10, pageSort: 'id'});
    pageOptions.params = this.buildPageOptions('PRE_APROVADO').params;
    this.visitaService.pageAprovacoesPendentes(pageOptions).subscribe(
      (response: ReturnAPI<Page<Visita>>) => {
        this.aprovacoes = response.object;
        this.aprovacoesTotalRecords = response.object.totalElements;
      }
    );
    pageOptions.params = this.buildPageOptions('FINALIZADA').params;
    this.visitaService.pageAprovacoesPendentes(pageOptions).subscribe(
      (response: ReturnAPI<Page<Visita>>) => {
        this.preAprovacoes = response.object;
        this.preAprovacoesTotalRecords = response.object.totalElements;
      }
    );
  }

  loadAprovarPage(event: LazyLoadEvent): void {
    const page: number = (event.first / 10) + 1;
    const pageOptions: QueryOptions = new QueryOptions({pageNumber: page, pageSize: 10, pageSort: 'id'});
    pageOptions.params = this.buildPageOptions('PRE_APROVADO').params;
    this.visitaService.pageAprovacoesPendentes(pageOptions).subscribe(
      (response: ReturnAPI<Page<Visita>>) => {
        this.aprovacoes = response.object;
        this.aprovacoesTotalRecords = response.object.totalElements;
      }
    );
  }

  loadPreAprovarPage(event: LazyLoadEvent): void {
    const page: number = (event.first / 10) + 1;
    const pageOptions: QueryOptions = new QueryOptions({pageNumber: page , pageSize: 10, pageSort: 'id'});
    pageOptions.params = this.buildPageOptions('FINALIZADA').params;
    this.visitaService.pageAprovacoesPendentes(pageOptions).subscribe(
      (response: ReturnAPI<Page<Visita>>) => {
        this.preAprovacoes = response.object;
        this.preAprovacoesTotalRecords = response.object.totalElements;
      }
    );
  }

  onLimparFiltros(): void {
    this.setDefaultFilter();
    this.setQueryOptions();
    this.load();
  }

  onAplicarFiltro(): void {
    this.setQueryOptions();
    this.load();
  }

  setDefaultFilter(): void {
    this.filterOptions = Object.assign({}, this.defaultQueryFilter);
  }

  setQueryOptions(): void {
    this.queryOptions.params = {
      postoId: this.filterOptions.posto ? this.filterOptions.posto.id : null,
      nomeCliente: this.filterOptions.nome ? this.filterOptions.nome : null,
      dataInicial: this.filterOptions.dataInicial ? this.filterOptions.dataInicial.getTime() : new Date().getTime(),
      dataFinal: this.filterOptions.dataFinal ? this.filterOptions.dataFinal.getTime() : new Date().getTime(),
    };
    this.metaData.emit(this.filterOptions);
  }

  editAprovacao(aprovacao: Visita): void {
    this.visitaEdit.emit(aprovacao);
  }

  changeView(event: boolean): void {
    this.view = event;
  }

  openGallery(id: number): void {
    this.imgData.emit(id);
  }

  preAprovar(id: number): void {
    this.visitaService.preAprovarVisita(id).subscribe(
      (response: ReturnAPI<Visita>) => {
      if (response.success) {
        this.visitaService.gerarAuditoriaPreAprovacao(id).subscribe(() =>  this.load());
      }
    });
  }

  rejeitar(id: number): void {
    this.visitaService.rejeitarVisita(id).subscribe(
      (response: ReturnAPI<Visita>) => {
      if (response.success) {
        this.load();
      }
    });
  }

  buildPageOptions(situacao: string): QueryOptions {
    const d1 = this.filterOptions.dataInicial != null ? this.dateMask(this.filterOptions.dataInicial.getTime()) : this.dateMask(new Date().getTime());
    const d2 = this.filterOptions.dataFinal != null ? this.dateMask(this.filterOptions.dataFinal.getTime()) : this.dateMask(new Date().getTime());
    const qo: QueryOptions = new QueryOptions();
    qo.params = {
      dataVisita: `btn:${d1},${d2}`,
      situacao: `in:${situacao}`,
      somenteNaoAprovados: true,
      postoId: this.filterOptions.posto ? this.filterOptions.posto.id : null,
      nomeCliente: this.filterOptions.nome
     };
    return qo;
  }

  dateMask(value: number): string {
    return value ? new Date(value).toLocaleDateString('pt-BR') : new Date().toLocaleDateString('pt-BR');
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
