import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcompanhamentoReagendamentoMainComponent } from './acompanhamento-reagendamento-main/acompanhamento-reagendamento-main.component';
import { GlobalModule } from 'src/app/global/global.module';

@NgModule({
  declarations: [AcompanhamentoReagendamentoMainComponent],
  imports: [CommonModule, GlobalModule],
})
export class AcompanhamentoReagendamentoModule {}
