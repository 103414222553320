import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { Cliente } from 'src/app/base/domain/cliente.model';
import { LazyLoadEvent } from 'primeng/api';
import { Posto } from 'src/app/base/domain/posto.model';
import { SituacaoCliente } from 'src/app/base/domain/enum/situacao-cliente.enum';
import { SharedService } from 'src/app/auth/shared.service';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { MatCheckboxChange } from '@angular/material/checkbox';

export interface ClienteMenuAcaoEvent {
  acao: string;
}
export interface ClienteAcaoEvent {
  acao: string;
  cliente: Cliente;
}
export interface ClienteListFilterOptions {
  nomeCliente: string;
  situacoesCliente: string[];
  postoSelecionado: Posto;
  dataInicial: Date;
  dataFinal: Date;
  descricaoRota: string;
  documentoCliente: string;
  endereco: string;
  cep: string;
  valorInicial: number;
  valorFinal: number;
}

@Component({
  selector: 'app-cliente-list',
  templateUrl: './cliente-list.component.html',
  styleUrls: ['./cliente-list.component.css'],
})
export class ClienteListComponent implements OnInit {
  @Input() title: string;
  @Input() nomePlaceholder: string;
  @Input() valorInicialPlaceholder: string;
  @Input() valorFinalPlaceholder: string;
  @Input() clientes: Cliente[];
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() loading: boolean;
  @Input() filtros: string[];
  @Input() options: string[];
  @Input() optionalColumns: string[];
  @Input() menuAcoes: string[];

  shared: SharedService;
  usuario: Usuario;

  @Output() loadClientesPage = new EventEmitter<number>();
  @Output() filter = new EventEmitter<ClienteListFilterOptions>();
  @Output() acaoClick = new EventEmitter<ClienteAcaoEvent>();
  @Output() disclosureCliente = new EventEmitter<Cliente>();
  @Output() menuAcaoClickEvent = new EventEmitter<ClienteMenuAcaoEvent>();
  @Output() selectAll = new EventEmitter<boolean>();

  private currentPage = 0;

  isAllContratoSelected = false;
  selecionados: Cliente[] = [];

  private readonly defaultFilterOptions: ClienteListFilterOptions = {
    nomeCliente: '',
    situacoesCliente: [],
    postoSelecionado: null,
    dataInicial: null,
    dataFinal: null,
    descricaoRota: '',
    documentoCliente: '',
    endereco: '',
    cep: '',
    valorInicial: null,
    valorFinal: null,
  };
  filterOptions: ClienteListFilterOptions;

  situacoes: SituacaoCliente[] = Object.values(SituacaoCliente);

  constructor() {
    this.onLimparFiltros();
    const shared = SharedService.getInstance();
    this.usuario = shared.usuario;
  }

  ngOnInit(): void { }

  loadClientes(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadClientesPage.emit(pageNumber);
  }

  onSelectAll(): void {
    this.clientes.forEach((cliente: Cliente) => (cliente.selecionado = true));
    this.selecionados = this.clientes.filter((cliente: Cliente) => cliente.selecionado);
    this.isAllContratoSelected = true;
    this.selectAll.emit(true);
  }

  onUnselectAll(): void {
    this.clientes.forEach((cliente: Cliente) => (cliente.selecionado = false));
    this.selecionados = [];
    this.isAllContratoSelected = false;
    this.selectAll.emit(false);
  }

  public onMenuAcaoClick(acao: string): void {
    this.menuAcaoClickEvent.emit({ acao });
  }

  onAcaoClick(acao: string, domain: Cliente): void {
    this.acaoClick.emit({ acao, cliente: domain });
  }

  onCheckboxChange($event: MatCheckboxChange): void {
    this.selecionados = this.clientes.filter((cliente: Cliente) => cliente.selecionado);
    if (this.isAllContratoSelected) {
      this.isAllContratoSelected = false;
      this.selectAll.emit(false);
    }
  }

  onAtualizar(): void {
    this.loadClientesPage.emit(this.currentPage);
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  onDisclosure(cliente: Cliente): void {
    this.disclosureCliente.emit(cliente);
  }

  getNumeroTelefone(cliente: Cliente): string {
    const numero = cliente?.pessoa.listTelefone.length > 0 ? cliente?.pessoa.listTelefone[0].numero : 'sem telefone';
    return numero;
  }
}
