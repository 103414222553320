import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from 'src/app/App.state';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ImportarArquivoExtratoFormComponent } from 'src/app/global/forms/importar-arquivo-extrato-form/importar-arquivo-extrato-form.component';
import {
  ArquivoExtratoListComponent,
  ExtratoEvent,
  ExtratoFilterOptions,
} from 'src/app/global/lists/arquivo-extrato-list/arquivo-extrato-list.component';
import { DialogService } from 'src/app/global/services/dialog.service';
import { ArquivoExtrato } from '../../domain/arquivo-extrato.model';
import { Page } from '../../domain/return-api.model';
import { ArquivoExtratoService } from '../../services/arquivo-extrato.service';

@Component({
  selector: 'app-arquivo-extrato-main',
  templateUrl: './arquivo-extrato-main.component.html',
  styleUrls: ['./arquivo-extrato-main.component.css'],
})
export class ArquivoExtratoMainComponent implements OnInit {
  private static APAGAR_ARQUIVO = 'Apagar Arquivo';

  queryOptions = new QueryOptions({ pageSize: 25 });

  page: Page<ArquivoExtrato> = new Page();
  totalRecords: number = 0;

  loading: boolean = false;

  constructor(
    private service: ArquivoExtratoService,
    public appState: AppState,
    private dialog: MatDialog,
    private dialogService: DialogService,
  ) {
    const date = new Date();
    date.setHours(0, 0, 0);
    this.queryOptions.params = {
      dataInicio: date.getTime(),
      dataFim: new Date().getTime(),
    };
  }

  ngOnInit(): void {}

  list(pageNumber: number = 0): void {
    this.loading = true;
    this.appState.isLoading = true;
    this.queryOptions.pageNumber = pageNumber + 1;

    const dataInicio: Date = new Date(this.queryOptions.params.dataInicio as number);
    dataInicio.setHours(0, 0, 0);
    const dataFim = new Date(this.queryOptions.params.dataFim as number);
    dataFim.setHours(23, 59, 59);

    this.queryOptions.params = {
      dataInicio: dataInicio.getTime(),
      dataFim: dataFim.getTime(),
    };

    this.service.findAllPageable(this.queryOptions).subscribe((response: Page<ArquivoExtrato>) => {
      this.loading = false;
      this.appState.isLoading = false;

      this.totalRecords = response.totalElements;

      response.content = this.mapearAcoes(response.content);
      this.page = response;
    });
  }

  mapearAcoes(arquivos: ArquivoExtrato[]): ArquivoExtrato[] {
    const mapearAcoes = (arquivo: ArquivoExtrato): ArquivoExtrato => {
      const acoes = [];
      acoes.push(ArquivoExtratoMainComponent.APAGAR_ARQUIVO);
      return { ...arquivo, acoes };
    };

    return arquivos?.map(mapearAcoes) ?? [];
  }

  onFilter(options: ExtratoFilterOptions): void {
    const date = new Date();
    date.setHours(0, 0, 0, 0);

    this.queryOptions.params = {
      dataInicio: options.dataInicial ? options.dataInicial.getTime() : date.getTime(),
      dataFim: options.dataFinal ? options.dataFinal.getTime() : date.getTime(),
    };

    this.list();
  }

  onAcaoClick(event: ExtratoEvent): void {
    if (event.acao === ArquivoExtratoMainComponent.APAGAR_ARQUIVO) {
      this.dialogService.confirmDialog('Deseja realmente deletar esse arquivo?', () =>
        this.service.apagarArquivo(event.arquivo.id).subscribe((response) => {
          this.dialogService.feedbackInfo('Arquivo apagado.'), this.list();
          (error: HttpErrorResponse) => {
            this.dialogService.feedbackError('Ocorreu um erro ao apagar o arquivo: ' + error.message);
          };
        }),
      );
    }
  }

  onMenuAcaoClick(event: string) {
    if (event === ArquivoExtratoListComponent.IMPORTAR_ARQUIVO) {
      this.dialog
        .open(ImportarArquivoExtratoFormComponent, {
          height: '200px',
          width: '800px',
        })
        .afterClosed()
        .subscribe((documento) => {
          if (documento) {
            this.service.importFile(documento).subscribe(
              (response) => {
                if (response) {
                  this.dialogService.feedbackSuccess('Arquivo Importado com Sucesso!').then((_) => this.list(0));
                }
              },
              (error: HttpErrorResponse) => {
                this.dialogService.feedbackError('Ocorreu um erro ao importar arquivo: ' + error.error);
              },
            );
          }
        });
    }
  }

  onLoadArquivosPage(pageNumber: number): void {
    this.list(pageNumber);
  }
}
