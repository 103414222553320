import { Resource } from 'src/app/global/domain/resource.model';
import { Estado } from './estado.model';
import { Bairro } from './bairro.model';
import { Municipio } from './municipio.model';

export class Endereco extends Resource {

    public estado: Estado;

    public bairro: Bairro;

    public municipio: Municipio;

    public logradouroCompleto: String;

    public logradouro: String;

    public numero: String;

    public complemento: String;

    public pontoReferencia: String;

    public cep: String;

    public latitude: Number;

    public longitude: Number;

    constructor() {
        super();
    }

}
