import { Resource } from 'src/app/global/domain/resource.model';

export class AuditoriaDespesa extends Resource {
    usuarioId: number;
    usuarioNome: string;
    transferenciaId: number;
    categoria: string;
    tipo: string;
    dataOperacao: Date;
    valor: number;
    situacaoTransferencia: string;
}