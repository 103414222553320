import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WEB_API } from 'src/app/global/services/web-api';
import { ArquivoVisita } from '../domain/arquivo-visita.model';
import { Produto } from '../domain/produto.model';
import { ArquivoVisitaSerializer } from '../serializables/arquivo-visita.serializer';
import { ResourceService } from './../../global/services/resource.service';
import { ReturnAPI } from '../domain/return-api.model';
import { Observable } from 'rxjs';

@Injectable()
export class ArquivoVisitaService extends ResourceService<ArquivoVisita> {
    static RESOURCE = 'arquivo-visita';
    produto: Produto;
    produtoId: string;

    constructor(private http: HttpClient) {
        super(http, `${WEB_API}`, 'arquivo-visita', new ArquivoVisitaSerializer(ArquivoVisita));
    }

    findByVisita(id: number): Observable<ReturnAPI<ArquivoVisita[]>> {
        this.getProduto();
        return this.http.get<ReturnAPI<ArquivoVisita[]>>(`${WEB_API}/${ArquivoVisitaService.RESOURCE}/findbyvisita?visitaId=${id}&${this.produtoId}`);
    }

    findByCodigoInContext(codigo: string): string {
        this.getProduto();
        return `${WEB_API}/${ArquivoVisitaService.RESOURCE}/findbycodigoincontext?codigo=${codigo}&${this.produtoId}`;
    }

    getProduto(): void {
        if (sessionStorage.getItem('produto')) {
            this.produto = JSON.parse(sessionStorage.getItem('produto'));
            this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
        }
    }
}
