import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContratosACancelarMainComponent } from './contratos-a-cancelar-main/contratos-a-cancelar-main.component';
import { ContratosACancelarDetailComponent } from './contratos-a-cancelar-detail/contratos-a-cancelar-detail.component';
import { GlobalModule } from 'src/app/global/global.module';

@NgModule({
  declarations: [ContratosACancelarMainComponent, ContratosACancelarDetailComponent],
  imports: [CommonModule, GlobalModule],
})
export class ContratosACancelarModule {}
