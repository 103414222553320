import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { AtividadeComercial } from 'src/app/base/domain/atividade-comercial.model';
import { Bairro } from 'src/app/base/domain/bairro.model';
import { CarteiraCliente } from 'src/app/base/domain/carteira-cliente.model';
import { Cliente } from 'src/app/base/domain/cliente.model';
import { Endereco } from 'src/app/base/domain/endereco.model';
import { Estado } from 'src/app/base/domain/estado.model';
import { Funcionario } from 'src/app/base/domain/funcionario.model';
import { Municipio } from 'src/app/base/domain/municipio.model';
import { Pessoa } from 'src/app/base/domain/pessoa.model';
import { Posto } from 'src/app/base/domain/posto.model';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { Telefone } from 'src/app/base/domain/telefone.model';
import { AtividadeComercialService } from 'src/app/base/services/atividade-comercial.service';
import { ClienteService } from 'src/app/base/services/cliente.service';
import { EquipeService } from 'src/app/base/services/equipe.service';
import { FuncionarioService } from 'src/app/base/services/funcionario.service';
import { PlanejamentoSemanaCaptacaoService } from 'src/app/base/services/planejamento-semana-captacao.service';
import { PostoService } from 'src/app/base/services/posto.service';
import { PublicService } from 'src/app/base/services/public.service';
import { QueryOptions } from '../../domain/query.options';

export interface ClienteFormData {
  cliente: Cliente;
  action: string;
  carteiraCliente: CarteiraCliente;
}

@Component({
  selector: 'app-cliente-form',
  templateUrl: './cliente-form.component.html',
  styleUrls: ['./cliente-form.component.css'],
})
export class ClienteFormComponent implements OnInit {
  cliente = new Cliente();
  title = '';
  isPessoaFisisca = true;
  postos: Posto[] = [];
  filteredPostos: Posto[] = [];
  atividadesComercial: AtividadeComercial[] = [];
  idPostos: string;
  estados: Estado[] = [];
  municipios: Municipio[] = [];
  bairros: Bairro[] = [];
  telefone = new Telefone();
  endereco = new Endereco();

  queryOptions = new QueryOptions();
  queryOptionsLista = new QueryOptions({ pageSize: 1000, pageNumber: 1 });

  constructor(
    public dialogRef: MatDialogRef<ClienteFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ClienteFormData,
    private postoService: PostoService,
    private clienteService: ClienteService,
    private equipeService: EquipeService,
    private funcionarioService: FuncionarioService,
    private atividadeComercial: AtividadeComercialService,
    private publicService: PublicService,
  ) {
    if (data.action === 'update') {
      this.title = 'Editar';
      this.cliente = data.cliente;
      this.telefone = this.cliente.pessoa.listTelefone ? this.cliente.pessoa.listTelefone[0] : new Telefone();
      this.endereco = this.cliente.pessoa.listEndereco ? this.cliente.pessoa.listEndereco[0] : new Endereco();
      this.cliente.pessoa.listTelefone = this.cliente.pessoa.listTelefone ? this.cliente.pessoa.listTelefone : [];
      this.cliente.pessoa.listEndereco = this.cliente.pessoa.listEndereco ? this.cliente.pessoa.listEndereco : [];
    } else {
      this.title = 'Novo';
      this.cliente.pessoa = new Pessoa();
      this.cliente.pessoa.nome = '';
      this.cliente.pessoa.nomeFantasia = '';
      this.endereco.logradouro = '';
      this.endereco.complemento = '';
      this.cliente.pessoa.listTelefone = [];
      this.cliente.pessoa.listEndereco = [];
    }

    this.atividadeComercial.findAll(this.queryOptionsLista).subscribe((result: ReturnAPI<Page<AtividadeComercial>>) => {
      if (result.success) {
        this.atividadesComercial = result.object.content;
        this.filteredPostos = this.postos;
        if (data.action === 'update') {
          this.cliente.atividadeComercial = this.atividadesComercial.filter(
            (atividade_comercial: AtividadeComercial) => atividade_comercial.id === this.cliente.atividadeComercial.id,
          )[0];
        }
      }
    });

    this.postoService.findAll(this.queryOptionsLista).subscribe((ondata: ReturnAPI<Page<Posto>>) => {
      if (ondata.success) {
        this.postos = ondata.object.content;
        this.filteredPostos = this.postos;
        if (data.action === 'update') {
          this.cliente.posto = this.postos.filter((posto: Posto) => this.cliente.posto.id === posto.id)[0];
        }
      }
    });

    this.publicService.listarEstados().subscribe((response: ReturnAPI<Page<Estado>>) => {
      if (response.success) {
        this.estados = response.object.content;
        if (data.action === 'update') {
          this.endereco.estado = this.estados.filter((estado: Estado) => this.endereco.estado.id === estado.id)[0];
          const estadoBusca = this.endereco.estado;
          const queryOptions = new QueryOptions({
            pageNumber: 1,
            params: {
              estado: `eq:${estadoBusca.id}`,
            },
          });

          this.publicService.listarMunicipios(queryOptions).subscribe((responseMunicipio: ReturnAPI<Page<Municipio>>) => {
            if (response.success) {
              this.municipios = responseMunicipio.object.content;
              this.endereco.municipio = this.municipios.filter((municipio: Municipio) => this.endereco.municipio.id === municipio.id)[0];

              const cidade = this.endereco.municipio;
              const queryOptionsCidade = new QueryOptions({
                pageNumber: 1,
                params: {
                  municipio: `eq:${cidade.id}`,
                },
              });

              this.publicService.listarBairros(queryOptionsCidade).subscribe((responseBairro: ReturnAPI<Page<Bairro>>) => {
                if (responseBairro.success) {
                  this.bairros = responseBairro.object.content;
                  this.endereco.bairro = this.bairros.filter((bairro: Bairro) => this.endereco.bairro.id === bairro.id)[0];
                }
              });
            }
          });
        }
      }
    });
  }

  ngOnInit(): void {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.cliente.pessoa.listTelefone = [];
    this.cliente.pessoa.listEndereco = [];
    this.cliente.pessoa.listTelefone.push(this.telefone);
    this.cliente.pessoa.listEndereco.push(this.endereco);
    this.cliente.carteiraCliente = this.data.carteiraCliente;
    this.dialogRef.close(this.cliente);
  }

  private _filtrar<T>(value: string, list: T[], field: string): T[] {
    const filterValue = value?.toLowerCase();
    return list.filter((e: T) => e[field].toLowerCase().indexOf(filterValue) === 0);
  }

  onSelectTipo(select: MatSelectChange): void {
    if (select.value === 'pf') {
      this.cliente.pessoa.type = 'pf';
      this.cliente.pessoa.cpf = '';
      this.cliente.pessoa.nome = '';
    } else {
      this.cliente.pessoa.type = 'pj';
      this.cliente.pessoa.cnpj = '';
      this.cliente.pessoa.nomeFantasia = '';
    }
  }

  onSelectPosto(value: Posto): void {}

  onSelectEstado(value: Estado): void {
    const estado = value;
    const queryOptions = new QueryOptions({
      pageNumber: 1,
      params: {
        estado: `eq:${estado.id}`,
      },
    });

    this.publicService.listarMunicipios(queryOptions).subscribe((response: ReturnAPI<Page<Municipio>>) => {
      if (response.success) {
        this.municipios = response.object.content;
      }
    });
  }

  onSelectMunicipio(value: Municipio): void {
    const cidade = value;
    const queryOptions = new QueryOptions({
      pageNumber: 1,
      params: {
        municipio: `eq:${cidade.id}`,
      },
    });

    this.publicService.listarBairros(queryOptions).subscribe((response: ReturnAPI<Page<Bairro>>) => {
      if (response.success) {
        this.bairros = response.object.content;
      }
    });
  }
}
