import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acompanhamento-sangria-geral-detail',
  templateUrl: './acompanhamento-sangria-geral-detail.component.html',
  styleUrls: ['./acompanhamento-sangria-geral-detail.component.css']
})
export class AcompanhamentoSangriaGeralDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
