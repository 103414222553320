import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Table } from 'primeng/table';
import { CardListComponent } from '../../components/card-list/card-list.component';
import { AuditoriaContrato } from 'src/app/base/domain/auditoria-contrato.model';
import { QueryOptions } from '../../domain/query.options';
import { Posto } from 'src/app/base/domain/posto.model';
import { TipoAuditoriaContrato } from 'src/app/base/domain/enum/tipo-auditoria-contrato.enum';
import { LazyLoadEvent } from 'primeng/api';
import { TipoDespesa } from 'src/app/base/domain/enum/tipo-despesa.enum';

export interface AuditoriaDespesaListFilterOptions {
  tipoDespesaSelecionado: TipoDespesa[];
  postoSelecionado: Posto;
  dataInicial: Date;
  dataFinal: Date;
}

export interface AuditoriaContratoAcaoEvent {
  acao: string;
  auditoriaContrato: AuditoriaContrato;
}

@Component({
  selector: 'app-auditoria-despesa-list',
  templateUrl: './auditoria-despesa-list.component.html',
  styleUrls: ['./auditoria-despesa-list.component.css'],
})
export class AuditoriaDespesaListComponent implements OnInit {
  @ViewChild('searchForm', { static: false }) searchForm: NgForm;
  @ViewChild('dataTable', { static: false }) dataTable: Table;
  @ViewChild(CardListComponent, { static: true }) cardList: CardListComponent;

  @Input() title: string;
  @Input() auditorias: AuditoriaContrato[];
  @Input() type: string;
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() loading: boolean;
  @Input() filtros: string[];
  @Input() optionalColumns: string[];
  @Input() options: string[];

  @Output() filter = new EventEmitter<AuditoriaDespesaListFilterOptions>();
  @Output() loadAuditoriasDespesaPage = new EventEmitter<number>();
  @Output() acaoClick = new EventEmitter<AuditoriaContratoAcaoEvent>();

  private currentPage = 0;
  queryOptions = new QueryOptions();

  private readonly defaultFilterOptions: AuditoriaDespesaListFilterOptions = {
    tipoDespesaSelecionado: [],
    postoSelecionado: null,
    dataInicial: null,
    dataFinal: null,
  };

  filterOptions: AuditoriaDespesaListFilterOptions;

  tiposDespesa: TipoDespesa[] = Object.values(TipoDespesa);

  constructor() {
    this.onLimparFiltros();
  }

  ngOnInit(): void {}

  onAcaoClick(acao: string, domain: AuditoriaContrato): void {
    this.acaoClick.emit({ acao, auditoriaContrato: domain });
  }

  loadAuditoriasDespesa(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadAuditoriasDespesaPage.emit(pageNumber);
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  onAtualizar(): void {
    this.loadAuditoriasDespesaPage.emit(this.currentPage);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
