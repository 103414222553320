import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Contrato } from '../../../domain/contrato.model';
import { ContratoService } from '../../../services/contrato.service';
import { ReturnAPI } from '../../../domain/return-api.model';
import { ContratoEvent } from 'src/app/global/widgets/contrato-info-geral/contrato-info-geral.component';

@Component({
  selector: 'app-acompanhamento-contrato-detail',
  templateUrl: './acompanhamento-contrato-detail.component.html',
  styleUrls: ['./acompanhamento-contrato-detail.component.css'],
})
export class AcompanhamentoContratoDetailComponent implements OnInit {
  contratoID: number;
  contrato: Contrato;

  constructor(private route: ActivatedRoute, private contratoService: ContratoService) {
    this.route.params.subscribe((params: Params) => {
      this.contratoID = params.id;
    });
  }

  ngOnInit(): void {
    this.findContrato();
  }

  private findContrato(): void {
    this.contratoService.findOne(this.contratoID).subscribe((response: ReturnAPI<Contrato>) => {
      if (response.success) {
        this.contrato = response.object;
        this.contratoService.getValorDividaContrato(this.contrato.id).subscribe((resp: ReturnAPI<number>) => {
          this.contrato.valorDivida = resp.object;
        });
      }
    });
  }

  onAction(action: ContratoEvent): void {
    this.findContrato();
  }
}
