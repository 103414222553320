import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AppState } from 'src/app/App.state';
import { DashBoardFaturamento } from 'src/app/base/domain/dashboard-faturamento';
import { DashBoardFaturamentoService } from 'src/app/base/services/dashboard-faturamento.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { DashBoardFilterOptions } from 'src/app/global/faturamento-dashboard/faturamento-dashboard.component';

@Component({
  selector: 'app-faturamento-boleto-posto',
  templateUrl: './faturamento-boleto-posto.component.html',
  styleUrls: ['./faturamento-boleto-posto.component.css']
})
export class FaturamentoBoletoPostoComponent implements OnInit {
  dashboard: DashBoardFaturamento = new DashBoardFaturamento();

  data = new Date();
  postoId: number;
  estadoId: number;
  title = '';
  descricaoPosto = '';

  queryOptions = new QueryOptions({ pageSize: 10 });

  constructor(private service: DashBoardFaturamentoService, private route: ActivatedRoute, public appState: AppState) {
    this.descricaoPosto = this.route.snapshot.queryParamMap.get('descricaoPosto').toString();

    this.route.params.subscribe((params: Params) => {
      this.postoId = params.id;
      if (this.route.snapshot.queryParamMap.has('estadoId')) {
        this.estadoId = +this.route.snapshot.queryParamMap.get('estadoId');
      }
    });
  }

  ngOnInit(): void {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    this.data = date;
    this.queryOptions.params = {
      dataInicial: this.route.snapshot.queryParamMap.get('dataInicial')
        ? this.route.snapshot.queryParamMap.get('dataInicial')
        : date.getTime(),
      dataFinal: this.route.snapshot.queryParamMap.get('dataFinal') ? this.route.snapshot.queryParamMap.get('dataFinal') : date.getTime(),
      postoId: this.postoId,
      estadoId: this.estadoId,
    };

    this.getDashBoard();
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  onReload(): void {
    this.data.setHours(0, 0, 0, 0);
    this.appState.isLoading = true;
    this.service.getDashBoardBoleto(this.queryOptions).subscribe((response: DashBoardFaturamento) => {
      this.dashboard = response;
      this.title = `Faturamento Boletos ${this.descricaoPosto}.`;
      this.appState.isLoading = false;
    });
  }

  getDashBoard(): void {
    this.data.setHours(0, 0, 0, 0);
    this.appState.isLoading = true;
    this.service.getDashBoardWithoutRefreshBoleto(this.queryOptions).subscribe((response: DashBoardFaturamento) => {
      this.dashboard = response;
      this.title = `Faturamento Boletos ${this.descricaoPosto}.`;
      this.appState.isLoading = false;
    });
  }

  onFilter(options: DashBoardFilterOptions): void {
    this.queryOptions.params = {
      postoId: options.postoSelecionado ? options.postoSelecionado.id : this.postoId,
      estadoId: options.estadoSelecionado ? options.estadoSelecionado.id : this.estadoId,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
    };

    this.getDashBoard();
  }
}
