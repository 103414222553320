import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { GlobalModule } from "src/app/global/global.module";
import { AcompanhamentoProrrogadoMainComponent } from "./acompanhamento-prorrogado-main/acompanhamento-prorrogado-main.component";
import { AcompanhamentoEstadoProrrogadoComponent } from "./acompanhamento-estado-prorrogado/acompanhamento-estado-prorrogado.component";
import { AcompanhamentoPostoProrrogadoComponent } from "./acompanhamento-posto-prorrogado/acompanhamento-posto-prorrogado.component";

@NgModule({
    declarations: [AcompanhamentoProrrogadoMainComponent, AcompanhamentoEstadoProrrogadoComponent, AcompanhamentoPostoProrrogadoComponent],
    imports: [CommonModule, GlobalModule],
})
export class AcompanhamentoProrrogadoModule { }