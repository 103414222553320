import { Resource } from 'src/app/global/domain/resource.model';
import { Funcionario } from './funcionario.model';
import { Cliente } from './cliente.model';
import { Posto } from './posto.model';
import { ClientePlanejamento } from './planejamento-cliente.model';
import { SituacaoCaptado } from './enum/situacao-captado.enum';
import { Endereco } from './endereco.model';
import { Telefone } from './telefone.model';
import { AtividadeComercial } from './atividade-comercial.model';
import { Equipe } from './equipe.model';
import { Turno } from './enum/turno.enum';
import { TipoPessoaEnum } from './enum/tipo-pessoa.enum';

export class Captado extends Resource {
  email: string;
  cpf: string;
  rg: string;
  cnpj: string;
  nome: string;
  razaoSocial: string;
  nomeFantasia: string;
  inscricao: string;
  dataNascimento: Date;
  dataCadastroEmpresa: Date;
  situacaoCaptado: SituacaoCaptado;
  turno: Turno;
  parecer: string;
  dataHoraCadastro: Date;
  atividadeComercial: AtividadeComercial; // AtividadeComercial
  possuiIndicador: boolean;
  // indicador: Indicador;
  visitador: Funcionario;
  captador: Funcionario;
  indicadorFuncionario: Funcionario;
  indicadorCliente: Cliente;
  cliente: Cliente;
  listEndereco: Endereco[];
  listTelefone: Telefone[];
  // produto: Produto;
  posto: Posto;
  equipe: Equipe;
  tipoPessoa: TipoPessoaEnum; // TipoPessoaEnum
  planejamento: ClientePlanejamento;
  idUsuarioHeimdall: number;
  cpfUsuarioHeimdall: string;
}
