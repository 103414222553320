import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConciliacaoBancariaDoc } from 'src/app/base/domain/conciliacao-bancaria-doc.model';
import { ConciliacaoDTO } from 'src/app/base/domain/dto/conciliacao.dto';
import { ParcelaClienteDOCView } from 'src/app/base/domain/parcela-cliente-doc-view.model';
import { ConciliacaoBancariaDocService } from 'src/app/base/services/conciliacao-bancaria-doc.service';

export interface ConciliacaoFormData {
  conciliacao: ConciliacaoDTO;
}

@Component({
  selector: 'app-vincular-conciliacao-doc',
  templateUrl: './vincular-conciliacao-doc.component.html',
  styleUrls: ['./vincular-conciliacao-doc.component.css'],
})
export class VincularConciliacaoDocComponent implements OnInit {
  parcelas: ParcelaClienteDOCView[];
  parcelaSelecionado: ParcelaClienteDOCView;
  exibirMensagem: boolean = false;
  parcela: ParcelaClienteDOCView;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConciliacaoFormData,
    private service: ConciliacaoBancariaDocService,
    public dialogRef: MatDialogRef<VincularConciliacaoDocComponent>,
  ) {}

  ngOnInit(): void {
    this.service.findAllParcela(this.data.conciliacao.data).subscribe((resource) => {
      if (resource && resource.length > 0) {
        this.parcelas = resource;
      } else {
        this.exibirMensagem = true;
      }
    });
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  confirmar(): void {
    const parcelaDTO = {
      conciliacaoBancariaDocId: this.data.conciliacao.id,
      parcelaClienteDOCViewId: this.parcela.id,
    };
    this.dialogRef.close(Object.assign(parcelaDTO));
  }
}
