import { Component, OnInit, Inject } from '@angular/core';
import { Cliente } from 'src/app/base/domain/cliente.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditarTelefoneDTO } from 'src/app/base/domain/dto/editar-telefone.dto';
import { Telefone } from 'src/app/base/domain/telefone.model';

export interface ClienteFormData {
  cliente: Cliente;
}

@Component({
  selector: 'app-editar-telefone-form',
  templateUrl: './editar-telefone-form.component.html',
  styleUrls: ['./editar-telefone-form.component.css'],
})
export class EditarTelefoneFormComponent implements OnInit {
  dto = new EditarTelefoneDTO();
  telefone = new Telefone();

  constructor(@Inject(MAT_DIALOG_DATA) public data: ClienteFormData, public dialogRef: MatDialogRef<EditarTelefoneFormComponent>) {
    console.log(data.cliente.pessoa.listTelefone);
    this.dto.clienteId = data.cliente.id;
    this.dto.telefone = data.cliente.pessoa.listTelefone.length > 0 ? data.cliente.pessoa.listTelefone[0] : this.telefone;
  }

  ngOnInit(): void {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(this.dto);
  }
}
