import { Injectable } from '@angular/core';
import { CategoriaPlanoConta } from '../domain/categoria-plano-conta.model';
import { ResourceService } from 'src/app/global/services/resource.service';
import { HttpClient } from '@angular/common/http';
import { WEB_API } from 'src/app/global/services/web-api';
import { CategoriaPlanoContaSerializer } from '../serializables/categoria-plano-conta.serializer';
import { Observable } from 'rxjs';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ReturnAPI, Page } from '../domain/return-api.model';
import { Produto } from '../domain/produto.model';

@Injectable()
export class CategoriaPlanoContaService extends ResourceService<CategoriaPlanoConta> {
  static RESOURCE = 'categoria-plano-conta';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${CategoriaPlanoContaService.RESOURCE}`, new CategoriaPlanoContaSerializer(CategoriaPlanoConta));
  }

  pageAll(queryOptions: QueryOptions): Observable<ReturnAPI<Page<CategoriaPlanoConta>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<CategoriaPlanoConta>>>(
      `${WEB_API}/${CategoriaPlanoContaService.RESOURCE}?${queryOptions.toQueryString()}&${this.produtoId}`,
    );
  }

  public create(item: CategoriaPlanoConta): Observable<CategoriaPlanoConta> {
    this.getProduto();
    return this.httpClient.post<CategoriaPlanoConta>(`${WEB_API}/${CategoriaPlanoContaService.RESOURCE}?${this.produtoId}`, item);
  }

  public update(item: CategoriaPlanoConta): Observable<CategoriaPlanoConta> {
    this.getProduto();
    return this.httpClient.put<CategoriaPlanoConta>(`${WEB_API}/${CategoriaPlanoContaService.RESOURCE}?${this.produtoId}`, item);
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }
}
