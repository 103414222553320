import { ComponentsModule } from 'src/app/global/components/components.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MotivoContratoCanceladoMainComponent } from './motivo-contrato-cancelado-main/motivo-contrato-cancelado-main.component';
import { GlobalModule } from 'src/app/global/global.module';



@NgModule({
  declarations: [MotivoContratoCanceladoMainComponent],
  imports: [
    CommonModule, GlobalModule, ComponentsModule
  ]
})
export class MotivoContratoCanceladoModule { }
