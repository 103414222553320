import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { GlobalModule } from 'src/app/global/global.module';
import { ComponentsModule } from 'src/app/global/components/components.module';
import { GraficosModule } from 'src/app/global/components/graficos/graficos.module';
import { TableModule } from 'primeng/table';

import { AcompanhamentoCobrancaTurnoRotaComponent } from './acompanhamento-cobranca-turno/acompanhamento-cobranca-turno-rota/acompanhamento-cobranca-turno-rota.component';
import { AcompanhamentoCobrancaTurnoEstadualComponent } from './acompanhamento-cobranca-turno/acompanhamento-cobranca-turno-estadual/acompanhamento-cobranca-turno-estadual.component';
import { AcompanhamentoCobrancaTurnoNacionalComponent } from './acompanhamento-cobranca-turno/acompanhamento-cobranca-turno-nacional/acompanhamento-cobranca-turno-nacional.component';
import { AcompanhamentoCobrancaMainComponent } from './acompanhamento-cobranca-main/acompanhamento-cobranca-main.component';
import { AcompanhamentoEstadoCobrancaComponent } from './acompanhamento-estado-cobranca/acompanhamento-estado-cobranca.component';
import { AcompanhamentoPostoCobrancaComponent } from './acompanhamento-posto-cobranca/acompanhamento-posto-cobranca.component';
import { DashboardCobrancaSemanalComponent } from './dashboard-cobranca-semanal/dashboard-cobranca-semanal.component';
import { DashboardCobrancaSemanalListComponent } from './dashboard-cobranca-semanal/dashboard-cobranca-semanal-list/dashboard-cobranca-semanal-list.component';
import { AccordionModule, ButtonModule, CardModule, CarouselModule, TreeTableModule } from 'primeng';
import {ChartModule} from 'primeng/chart';
import { ListaClientesRotaComponent } from './dashboard-cobranca-semanal/lista-clientes-rota/lista-clientes-rota.component';

@NgModule({
  declarations: [
    AcompanhamentoCobrancaMainComponent,
    AcompanhamentoPostoCobrancaComponent,
    AcompanhamentoEstadoCobrancaComponent,
    AcompanhamentoCobrancaMainComponent,
    AcompanhamentoCobrancaTurnoNacionalComponent,
    AcompanhamentoCobrancaTurnoEstadualComponent,
    AcompanhamentoCobrancaTurnoRotaComponent,
    DashboardCobrancaSemanalComponent,
    DashboardCobrancaSemanalListComponent,
    ListaClientesRotaComponent
  ],
  imports: [
    CommonModule,
    GlobalModule,
    ComponentsModule,
    GraficosModule,
    MatExpansionModule,
    TableModule,
    ButtonModule,
    CarouselModule,
    ChartModule,
    TreeTableModule,
    CardModule,
    AccordionModule,
  ],
})
export class AcompanhamentoCobrancaModule { }
