import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { Produto } from '../domain/produto.model';
import { Page, ReturnAPI } from '../domain/return-api.model';
import { CobrancaValeSerializer } from '../serializables/cobranca-vale.serializer';
import { CobrancaVale } from '../domain/cobranca-vale.model';

@Injectable()
export class CobrancaValeService extends ResourceService<CobrancaVale> {
  static RESOURCE = 'cobranca-vale';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${CobrancaValeService.RESOURCE}`, new CobrancaValeSerializer(CobrancaVale));
  }

  findAll(queryOption: QueryOptions): Observable<ReturnAPI<Page<CobrancaVale>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<CobrancaVale>>>(
      `${WEB_API}/${CobrancaValeService.RESOURCE}?${queryOption.toQueryString()}&${this.produtoId}`,
    );
  }

  cadastrarCobrancaVale(cobrancaVale: CobrancaVale): Observable<ReturnAPI<CobrancaVale>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<CobrancaVale>>(`${WEB_API}/${CobrancaValeService.RESOURCE}?${this.produtoId}`, cobrancaVale);
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }
}
