import { Component, OnInit } from '@angular/core';
import { AuditoriaContrato } from 'src/app/base/domain/auditoria-contrato.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { Router } from '@angular/router';
import { AuditoriaContratoSerializer } from 'src/app/base/serializables/auditoria-contrato.serializer';
import { AuditoriaContratoService } from 'src/app/base/services/auditoria-contrato.service';
import { DialogService } from 'src/app/global/services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import {
  AuditoriaContratoAcaoEvent,
  AuditoriaContratoListFilterOptions,
} from 'src/app/global/lists/auditoria-contrato-list/auditoria-contrato-list.component';

@Component({
  selector: 'app-auditoria-contrato-main',
  templateUrl: './auditoria-contrato-main.component.html',
  styleUrls: ['./auditoria-contrato-main.component.css'],
})
export class AuditoriaContratoMainComponent implements OnInit {
  auditorias: AuditoriaContrato[] = [];

  queryOptions = new QueryOptions({ pageSize: 10 });
  totalRecords = 0;
  loading = false;

  constructor(
    private router: Router,
    private auditoriaContratoService: AuditoriaContratoService,
    private dialogService: DialogService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {}

  listarAuditorias(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.auditoriaContratoService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<AuditoriaContrato>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.mapearAcoes(response.object.content);
      } else {
        this.auditorias = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
    });
  }

  mapearAcoes(auditorias: AuditoriaContrato[]): void {
    const mapearAcoes = (auditoriaContrato: AuditoriaContrato): AuditoriaContrato => {
      const acoes = [];
      return { ...auditoriaContrato, acoes };
    };

    this.auditorias = auditorias?.map(mapearAcoes) ?? [];
  }

  onAcaoClick(event: AuditoriaContratoAcaoEvent): void {
    const actions = new Map<string, (auditoriaContrato: AuditoriaContrato) => void>();
    actions.get(event.acao)(event.auditoriaContrato);
  }

  onAuditoriasPage(page: number): void {
    this.listarAuditorias(page);
  }

  onDisclosure(contratoId: number): void {
    this.router.navigate(['base/contratos/acompanhamento-contrato-detail/' + contratoId]);
  }

  onFilter(options: AuditoriaContratoListFilterOptions): void {
    this.queryOptions.params = {
      tipoAuditoria: options.tipoAuditoriaContratoSelecionado?.length ? `in:${options.tipoAuditoriaContratoSelecionado.join(',')}` : null,
      postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
    };
    this.listarAuditorias();
  }
}
