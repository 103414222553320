import { Resource } from 'src/app/global/domain/resource.model';
import { Posto } from './posto.model';
import { Funcionario } from './funcionario.model';
import { Rota } from './rota.model';

export class Equipe extends Resource {
  descricao: string;
  turno: string;
  posto: Posto;
  rotas: Rota[];
  fiscal: Funcionario;
}
