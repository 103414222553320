import { Injectable } from '@angular/core';
import { ClientePlanejamento } from '../domain/planejamento-cliente.model';
import { ResourceService } from 'src/app/global/services/resource.service';
import { ClientePlanejamentoSerializer } from '../serializables/cliente-planejamento.serializer';
import { WEB_API } from 'src/app/global/services/web-api';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReturnAPI, Page } from '../domain/return-api.model';
import { AmostraPlanejamentoClienteAnoDTO } from '../domain/dto/amostra-planejamento-cliente-ano.dto';
import { QuantidadePlanejamentoDTO } from '../domain/dto/quantidade-planejamento.dto';
import { Produto } from '../domain/produto.model';

@Injectable()
export class ClientePlanejamentoService extends ResourceService<ClientePlanejamento> {
  static RESOURCE = 'cliente-planejamento';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, ClientePlanejamentoService.RESOURCE, new ClientePlanejamentoSerializer(ClientePlanejamento));
  }

  findAll(queryOption: QueryOptions): Observable<ReturnAPI<Page<ClientePlanejamento>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<ClientePlanejamento>>>(
      `${WEB_API}/${ClientePlanejamentoService.RESOURCE}?${queryOption.toQueryString()}&${this.produtoId}`,
    );
  }

  findOne(clientePlanejamentoID: number): Observable<ReturnAPI<ClientePlanejamento>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<ClientePlanejamento>>(
      `${WEB_API}/${ClientePlanejamentoService.RESOURCE}/findOne?clienteId=${clientePlanejamentoID}&${this.produtoId}`,
    );
  }

  amostrarPlanejamentosDoAno(postoID?: number): Observable<ReturnAPI<AmostraPlanejamentoClienteAnoDTO>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<AmostraPlanejamentoClienteAnoDTO>>(
      `${WEB_API}/${ClientePlanejamentoService.RESOURCE}/amostragem-planejamento-ano?${this.produtoId}${
        postoID ? `&postoId=${postoID}` : ''
      }`,
    );
  }

  planejamentoPeriodoAgrupadosPorSituacao(options: QueryOptions): Observable<ReturnAPI<QuantidadePlanejamentoDTO>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<QuantidadePlanejamentoDTO>>(
      `${WEB_API}/${ClientePlanejamentoService.RESOURCE}/planejamento-periodo-situacao?${options.toQueryString(false)}&${this.produtoId}`,
    );
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }
}
