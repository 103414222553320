import { Funcionario } from './funcionario.model';
import { Contrato } from './contrato.model';
import { Posto } from './posto.model';
import { Cliente } from './cliente.model';
import { TitulacaoCCB } from './titulacaoCCB.model';
import { Resource } from 'src/app/global/domain/resource.model';
import { Rota } from './rota.model';
import { ArquivoVisita } from './arquivo-visita.model';

export class Visita extends Resource {
  quantidadeParcelas: number;
  tipoContrato: string; // TipoContratoEnum
  situacao: string; // SituacaoVisitaEnum
  listArquivoVisita: ArquivoVisita[];
  dataVisita: Date;
  valorSugerido: number;
  limiteCreditoSugerido: number;
  parecerVisita: string;
  valorAprovado: number;
  limiteCreditoAprovado: number;
  dataAprovacaoCredito: Date;
  turno: string; // TurnoEnum
  cliente: Cliente;
  atendente: Funcionario;
  visitador: Funcionario;
  fiscal: Funcionario;
  // indicador: Indicador;
  indicadorFuncionario: Funcionario;
  indicadorCliente: Cliente[];
  listContrato: Contrato[];
  pagamentoCartao: boolean;
  pagamentoBoleto: boolean;
  pagamentoDinheiro: boolean;
  pagamentoPix: boolean;
  // produto: Produto;
  dataVencimento: Date;
  taxaJuros: number;
  taxaJurosMora: number;
  periodicidadeParcela: string; // PeriodicidadeEnum
  diasAntecipacao: number;
  antecipacao: boolean;
  tipoCobranca: string; // TipoCobrancaEnum
  // diaSemana: DiaSemana;
  posto: Posto;
  // equipe: Equipe;
  rota: Rota;
  modalidadeEstabelecimento: string; // TipoModalidadeEstabelecimentoEnum
  titulacaoCCB: TitulacaoCCB;
}
