import { Component, Inject, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Moment } from 'moment';
import { Contrato } from 'src/app/base/domain/contrato.model';
import { AlterarContratoDTO } from 'src/app/base/domain/dto/alterar-contrato-dto';
import { Funcionario } from 'src/app/base/domain/funcionario.model';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { Rota } from 'src/app/base/domain/rota.model';
import { FuncionarioService } from 'src/app/base/services/funcionario.service';
import { RotaService } from 'src/app/base/services/rota.service';
import { QueryOptions } from '../../domain/query.options';

interface DataType {
  title?: string;
  contrato: Contrato;
  hideFields?: string[];
}

@Component({
  selector: 'app-alterar-contrato-form',
  templateUrl: './alterar-contrato-form.component.html',
  styleUrls: ['./alterar-contrato-form.component.css'],
})
export class AlterarContratoFormComponent implements OnInit {
  rotas: Rota[] = [];
  filteredRotas: Rota[] = [];
  rotaInput = '';
  clienteNome = '';

  dataEmissao: Date = new Date();

  title: string;
  hideFields: string[];
  alterarContratoDTO: AlterarContratoDTO = new AlterarContratoDTO();

  contratoId: number;
  contratoPixOuDinheiro = false;

  queryOptions = new QueryOptions({ pageNumber: 1 });

  constructor(
    public dialogRef: MatDialogRef<AlterarContratoFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataType,
    private rotaService: RotaService,
  ) {
    this.title = data.title ?? '';
    this.hideFields = data.hideFields ?? [];
    this.contratoId = data.contrato.id;
    this.alterarContratoDTO.contratoId = this.contratoId;
    this.alterarContratoDTO.quantidadeParcelas = data.contrato.visita.quantidadeParcelas;
    this.alterarContratoDTO.valorBruto = data.contrato.valorBruto;
    this.alterarContratoDTO.rota = data.contrato.visita.rota;
    this.alterarContratoDTO.uidContrato = data.contrato.uid;
    this.rotaInput = this.alterarContratoDTO.rota.descricao;
    if (data.contrato.dataEmissao != null && data.contrato.dataEmissao !== undefined) {
      this.alterarContratoDTO.dataEmissao = new Date(data.contrato.dataEmissao);
    }

    if (data.contrato.visita.pagamentoPix) {
      this.contratoPixOuDinheiro = data.contrato.visita.pagamentoPix;
      this.hideFields.push('quantidadeParcelas', 'rota');
    }

    if (data.contrato.visita.pagamentoDinheiro) {
      this.contratoPixOuDinheiro = data.contrato.visita.pagamentoDinheiro;
      this.hideFields.push('quantidadeParcelas');
    }

    this.doFilter(this.rotaInput);
    this.rotaService.findAll(this.queryOptions).subscribe((resp: ReturnAPI<Page<Rota>>) => {
      this.rotas = resp.object.content;
      this.filteredRotas = this.rotas;
    });
  }

  ngOnInit(): void {}

  onConfirm(): void {
    this.dialogRef.close(Object.assign(this.alterarContratoDTO));
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSelected(descricao: string): void {
    const rotaSelecionado = this.rotas.find((rota: Rota) => rota.descricao === descricao);
    this.alterarContratoDTO.rota = rotaSelecionado;
  }

  doFilter(value: string): void {
    if (value === 'rota') {
      this.filteredRotas = this._filtrar(this.rotaInput, this.rotas, 'descricao');
    }
  }

  onPickerEvent(event: MatDatepickerInputEvent<Moment>): void {
    this.alterarContratoDTO.dataEmissao = event.value?.toDate() || null;
    this.dataEmissao = new Date(this.alterarContratoDTO.dataEmissao);
  }

  private _filtrar<T>(value: string, list: T[], field: string): T[] {
    const filterValue = value?.toLowerCase();
    return list.filter((e: T) => e[field].toLowerCase().indexOf(filterValue) === 0);
  }
}
