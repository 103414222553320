import { Component, OnInit } from '@angular/core';
import { Transferencia } from '../../../domain/transferencia.model';
import { ActivatedRoute, Params } from '@angular/router';
import { TransferenciaService } from '../../../services/transferencia.service';
import { ReturnAPI } from '../../../domain/return-api.model';
import { DialogService } from 'src/app/global/services/dialog.service';
import { AppState } from 'src/app/App.state';
import { WEB_API } from 'src/app/global/services/web-api';
import { Produto } from 'src/app/base/domain/produto.model';

@Component({
  selector: 'app-acompanhamento-sangria-detail',
  templateUrl: './acompanhamento-sangria-detail.component.html',
  styleUrls: ['./acompanhamento-sangria-detail.component.css'],
})
export class AcompanhamentoSangriaDetailComponent implements OnInit {
  private static STORAGE_URL = `${WEB_API}/storage/sgf/`;

  sangriaID: number;
  sangria: Transferencia;
  produto: Produto;
  produtoId: string;

  constructor(
    public appState: AppState,
    public route: ActivatedRoute,
    public dialogService: DialogService,
    private transferenciaService: TransferenciaService,
  ) {
    this.getProduto();
    this.route.params.subscribe((params: Params) => {
      this.sangriaID = params['id'];
    });
  }

  ngOnInit(): void {
    this.appState.isLoading = true;
    this.transferenciaService.transferencia(this.sangriaID).subscribe((response: ReturnAPI<Transferencia>) => {
      if (response.success) {
        this.sangria = response.object;
        if (this.sangria.urlComprovante) {
          const StringArray = this.sangria.urlComprovante?.split('/');
          this.sangria.urlComprovante = StringArray[StringArray.length - 1];
          this.sangria.urlComprovante = `${AcompanhamentoSangriaDetailComponent.STORAGE_URL}${this.produto.id}/${this.sangria.id}`;
        }
      }
      this.appState.isLoading = false;
    });
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }

  onConfirmarDeposito(): void {
    this.dialogService.confirmDialog('Confirmar realização da sangria?', () => {
      this.confirmarRealizacaoSangria();
    });
  }

  private confirmarRealizacaoSangria(): void {
    this.appState.isLoading = true;
    this.sangria.situacao = '';
    this.transferenciaService.confirmarRealizacaoSangria(this.sangriaID).subscribe(
      (response: ReturnAPI<Transferencia>) => {
        this.sangria.situacao = response.success ? 'CONFIRMADA' : 'PENDENTE_ANALISE_SANGRIA';
        this.dialogService.feedbackReturnAPI(response, 'Realização da sangria efetuado com sucesso');
        this.appState.isLoading = false;
      },
      () => {
        this.sangria.situacao = 'PENDENTE_ANALISE_SANGRIA';
        this.dialogService.feedbackError('Houve um erro na confirmação!');
        this.appState.isLoading = false;
      },
    );
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
