import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Contrato } from '../../../base/domain/contrato.model';

interface DataType {
  contrato: Contrato;
  action: String;
}


@Component({
  selector: 'app-liquidar-contrato-form',
  templateUrl: './liquidar-contrato-form.component.html',
  styleUrls: ['./liquidar-contrato-form.component.css']
})
export class LiquidarContratoFormComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LiquidarContratoFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataType
  ) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(Object.assign(this.data.contrato));
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
