import { Injectable } from '@angular/core';
import { Captado } from '../domain/captado.model';
import { HttpClient } from '@angular/common/http';
import { WEB_API } from 'src/app/global/services/web-api';
import { ResourceService } from 'src/app/global/services/resource.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { Observable } from 'rxjs';
import { ReturnAPI, Page } from '../domain/return-api.model';
import { CaptadoSerializer } from '../serializables/captado.serializer';
import { AmostraCaptadosVisitadosAnoDTO } from '../domain/dto/amostra-captados-visitados-ano.dto';
import { QuantidadeCaptadosVisitadosDTO } from '../domain/dto/quantidade-captados-visitados.dto';
import { QuantidadeContratacaoCaptadoDTO } from '../domain/dto/quantidade-contratacao-captado.dto';
import { AmostraContratacaoCaptacaoDTO } from '../domain/dto/amostra-contratacao-captacao.dto';
import { Produto } from '../domain/produto.model';

@Injectable()
export class CaptadoService extends ResourceService<Captado> {
  static RESOURCE = 'captado';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, CaptadoService.RESOURCE, new CaptadoSerializer(Captado));
  }

  findAll(queryOption: QueryOptions): Observable<ReturnAPI<Page<Captado>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Captado>>>(
      `${WEB_API}/${CaptadoService.RESOURCE}?${queryOption.toQueryString()}&${this.produtoId}`,
    );
  }

  findOne(captadoID: number): Observable<ReturnAPI<Captado>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Captado>>(
      `${WEB_API}/${CaptadoService.RESOURCE}/findOne?captadoId=${captadoID}&${this.produtoId}`,
    );
  }

  recaptar(captadoID: number): Observable<ReturnAPI<Captado>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<Captado>>(
      `${WEB_API}/${CaptadoService.RESOURCE}/recaptar?captadoId=${captadoID}&${this.produtoId}`,
      null,
    );
  }

  saveCaptado(captado: Captado): Observable<ReturnAPI<Captado>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<Captado>>(
      `${WEB_API}/${CaptadoService.RESOURCE}/save-captado-heimdall?${this.produtoId}`,
      captado,
    );
  }

  amostrarCaptadosDoAno(postoID?: number): Observable<ReturnAPI<AmostraCaptadosVisitadosAnoDTO>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<AmostraCaptadosVisitadosAnoDTO>>(
      `${WEB_API}/${CaptadoService.RESOURCE}/amostragem-captado-ano?${this.produtoId}${postoID ? `&postoId=${postoID}` : ''}`,
    );
  }

  captadoPeriodoAgrupadosPorSituacao(options: QueryOptions): Observable<ReturnAPI<QuantidadeCaptadosVisitadosDTO>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<QuantidadeCaptadosVisitadosDTO>>(
      `${WEB_API}/${CaptadoService.RESOURCE}/captado-periodo-situacao?${options.toQueryString(false)}&${this.produtoId}`,
    );
  }

  amostrarContratacoesDoAno(postoID?: number): Observable<ReturnAPI<AmostraContratacaoCaptacaoDTO>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<AmostraContratacaoCaptacaoDTO>>(
      `${WEB_API}/${CaptadoService.RESOURCE}/amostragem-contratacao-captados?${this.produtoId}${postoID ? `&postoId=${postoID}` : ''}`,
    );
  }

  quantidadeContratacaoCaptados(options: QueryOptions): Observable<ReturnAPI<QuantidadeContratacaoCaptadoDTO>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<QuantidadeContratacaoCaptadoDTO>>(
      `${WEB_API}/${CaptadoService.RESOURCE}/quantidade-contratacao-captados?${options.toQueryString(false)}&${this.produtoId}`,
    );
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }
  relatorioDeCaptados(queryOption: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${CaptadoService.RESOURCE}/relatorio-de-captados?${queryOption.toQueryString()}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }
}
