import { Injectable } from '@angular/core';
import { ResourceService } from 'src/app/global/services/resource.service';
import { Feriado } from '../domain/feriado.model';
import { WEB_API } from 'src/app/global/services/web-api';
import { FeriadoSerializer } from '../serializables/feriado.serializer';
import { HttpClient } from '@angular/common/http';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { Observable } from 'rxjs';
import { ReturnAPI, Page } from '../domain/return-api.model';
import { Produto } from '../domain/produto.model';

@Injectable()
export class FeriadoService extends ResourceService<Feriado> {
  static RESOURCE = 'feriado';

  produto: Produto;
  produtoId: string;

  private urlPath: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, FeriadoService.RESOURCE, new FeriadoSerializer(Feriado));
    this.urlPath = `${this.url}/${this.endpoint}`;
  }

  findAll(queryOption: QueryOptions): Observable<ReturnAPI<Page<Feriado>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Feriado>>>(`${this.urlPath}?${queryOption.toQueryString()}&${this.produtoId}`);
  }

  save(feriado: Feriado): Observable<ReturnAPI<Feriado>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<Feriado>>(`${this.urlPath}?${this.produtoId}`, feriado);
  }

  saveBulk(feriados: Feriado[]): Observable<ReturnAPI<Feriado[]>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<Feriado[]>>(`${this.urlPath}/bulk?${this.produtoId}`, feriados);
  }

  deleteById(feriadoID: number): Observable<ReturnAPI<Feriado>> {
    this.getProduto();
    return this.httpClient.delete<ReturnAPI<Feriado>>(`${this.urlPath}/?${this.produtoId}&feriadoId=${feriadoID}`);
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }
}
