import { Component, OnInit } from '@angular/core';
import { BooleanGereric, ReportFilterEmit } from '../../../report/report-filters/report-filters.component';
import { ContratoService } from '../../../services/contrato.service';
import { ReturnAPI } from '../../../domain/return-api.model';
import { TreeNode } from 'primeng';
import { DashboardRecebimentoSemanalDto } from 'src/app/base/domain/dto/dashboard-recebimento-semanal.dto';
import { RadarChartTemplate } from 'src/app/global/components/graficos/radar-chart/radar-chart.component';

interface Report {
  filters: string[];
  id: number;
  descricao: string;
  filtersRequired: boolean;
  dataInicialPlaceholder?: string;
  dataFinalPlaceholder?: string;
  inputMinimoPlaceholder?: string;
  inputMaximoPlaceholder?: string;
  inputNumericoPlaceholder?: string;
  opcaoGenerico?: string;
  opcao_1?: string;
  opcao_2?: string;
  opcaoDescricao?: string;
  opcoes?: BooleanGereric[];
  multipleOpcaoDescricao?: string;
  diaSemana?: number;
}

export interface DashboardCobrancaSemanalTotalizadorEstado {
  estado: string;

  recebidoSegunda: number;
  recebidoTerca: number;
  recebidoQuarta: number;
  recebidoQuinta: number;
  recebidoSexta: number;

  totalAReceber: number;
  totalRecebido: number;
}

@Component({
  selector: 'app-dashboard-cobranca-semanal',
  templateUrl: './dashboard-cobranca-semanal.component.html',
  styleUrls: ['./dashboard-cobranca-semanal.component.css'],
})
export class DashboardCobrancaSemanalComponent implements OnInit {
  rgbaColors: string[] = [
    'rgba(255, 0, 0, 0.4)',
    'rgba(255, 100, 0, 0.4)',
    'rgba(255, 220, 0, 0.4)',
    'rgba(110, 225, 0, 0.4)',
    'rgba(95, 210, 215, 0.4)',
    'rgba(10, 55, 235, 0.4)'
  ];

  rgbColors: string[] = [
    'rgb(255, 0, 0)',
    'rgb(255, 100, 0)',
    'rgb(255, 220, 0)',
    'rgb(110, 225, 0)',
    'rgb(95, 210, 215)',
    'rgb(10, 55, 235)'
  ];

  filtros: Report[] = [];
  filtrar: boolean;
  recebimentos: TreeNode[];
  reportSelecionado: Report;
  relatorios: Report = {
    id: 1,
    descricao: 'Dashboard Cobrança Semanal',
    filters: ['estados', 'datas', 'dataSemanal'],
    filtersRequired: false,
    diaSemana: 1,
  };
  dados: any[];

  estados: DashboardCobrancaSemanalTotalizadorEstado[] = [];
  chartsSemanalTemplates: RadarChartTemplate[] = [];

  constructor(private service: ContratoService) {
    this.filtrar = false;
    this.dados = [];
  }

  ngOnInit(): void {
    this.reportSelecionado = this.relatorios;
  }

  consultarCobrancaSemanalPosto(estadoId: number, data: number): void {
    this.service.dashboardRecebimentoSemanalHeimdall(estadoId, data).subscribe(
      // tslint: disable-next-line: no-any
      (data: ReturnAPI<DashboardRecebimentoSemanalDto[][]>) => {
        let arrayDados = [];

        data.object.forEach(f => {
          arrayDados = arrayDados.concat(f);
        });

        this.recebimentos = arrayDados;

        this.configurarGrafitosEstados(data.object);
      }
    );
  }

  configurarGrafitosEstados(postos: DashboardRecebimentoSemanalDto[][]): void {
    const todosEstados: string[] = [];
    postos.forEach((produto: DashboardRecebimentoSemanalDto[]) => {
      produto.forEach((dado: DashboardRecebimentoSemanalDto) => {
        todosEstados.push(dado.estado)
      });
    });

    const matrizEmArray = postos.reduce((list, sub) => list.concat(sub), []);

    const estados = new Set(todosEstados);
    const totalEstados: DashboardCobrancaSemanalTotalizadorEstado[] = [];

    estados.forEach((estado: string) => {
      const dadosPostos = matrizEmArray.filter((posto: DashboardRecebimentoSemanalDto) => posto.estado === estado);
      const totalizador: DashboardCobrancaSemanalTotalizadorEstado = {
        estado: estado,
        recebidoSegunda: dadosPostos.map((posto: DashboardRecebimentoSemanalDto) => posto.recebidoSegunda).reduce((previous, current) => previous + current),
        recebidoTerca: dadosPostos.map((posto: DashboardRecebimentoSemanalDto) => posto.recebidoTerca).reduce((previous, current) => previous + current),
        recebidoQuarta: dadosPostos.map((posto: DashboardRecebimentoSemanalDto) => posto.recebidoQuarta).reduce((previous, current) => previous + current),
        recebidoQuinta: dadosPostos.map((posto: DashboardRecebimentoSemanalDto) => posto.recebidoQuinta).reduce((previous, current) => previous + current),
        recebidoSexta: dadosPostos.map((posto: DashboardRecebimentoSemanalDto) => posto.recebidoSexta).reduce((previous, current) => previous + current),
        totalRecebido: dadosPostos.map((posto: DashboardRecebimentoSemanalDto) => posto.totalRecebido).reduce((previous, current) => previous + current),
        totalAReceber: dadosPostos.map((posto: DashboardRecebimentoSemanalDto) => posto.totalAReceber).reduce((previous, current) => previous + current)
      }
      totalEstados.push(totalizador);
    });
    this.estados = totalEstados;

    const chartsSemanais: RadarChartTemplate[] = [];

    totalEstados.forEach((estado: DashboardCobrancaSemanalTotalizadorEstado, index: number) => {
      const chartTemplate: RadarChartTemplate = {
        canvas: `estado-chart-${estado.estado}`,
        label: `${estado.estado}`,
        dataLabels: [
          'Segunda',
          'Terça',
          'Quarta',
          'Quinta',
          'Sexta'
        ],
        data: [
          {
            label: `Recebido ${estado.estado}`,
            data: [
              estado.recebidoSegunda,
              estado.recebidoTerca,
              estado.recebidoQuarta,
              estado.recebidoQuinta,
              estado.recebidoSexta
            ],
            fill: true,
            index: index,
            backgroundColor: 'rgba(0, 188, 212, 0.3)',
            borderColor: 'rgba(0, 188, 212, 0.7)',
            pointBackgroundColor: 'rgba(0, 188, 212, 1)',
            pointBorderColor: 'rgba(0, 188, 212, 1)',
            pointHoverBackgroundColor: 'rgba(0, 188, 212, 0)',
            pointHoverBorderColor: 'rgba(0, 188, 212, 0.5)'
          },
        ],
        elementIndex: index,
        heigth: 80,
        maxHeigth: '200px'
      }
      chartsSemanais.push(chartTemplate);

    });

    this.chartsSemanalTemplates = chartsSemanais;
  }


  eventEmitter(evento: ReportFilterEmit): void {
    const idEstado = evento.estadoId === null ? 0 : evento.estadoId;
    const data = evento.dataSemanal === null ? new Date().getTime() : evento.dataSemanal.getTime();
    this.consultarCobrancaSemanalPosto(idEstado, data);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
