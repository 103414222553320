import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { DashBoardEstadoFaturamento } from '../domain/dashboard-estado-faturamento.model';
import { DashBoardFaturamento } from '../domain/dashboard-faturamento';
import { Produto } from '../domain/produto.model';
import { ReturnAPI } from '../domain/return-api.model';
import { DashBoardFaturamentoSerializer } from '../serializables/dashboard-faturamento.serializer';

@Injectable()
export class DashBoardFaturamentoService extends ResourceService<DashBoardFaturamento> {
  
  static RESOURCE = 'relatorio-faturamento-view';

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${DashBoardFaturamentoService.RESOURCE}`, new DashBoardFaturamentoSerializer(DashBoardFaturamento));
  }

  getDashBoard(queryOption: QueryOptions): Observable<DashBoardFaturamento> {
    return this.httpClient.get<DashBoardFaturamento>(
      `${WEB_API}/${DashBoardFaturamentoService.RESOURCE}/dashboard-faturamento?${queryOption.toQueryString()}`,
    );
  }

  getDashBoardWithoutRefresh(queryOption: QueryOptions): Observable<DashBoardFaturamento> {
    return this.httpClient.get<DashBoardFaturamento>(
      `${WEB_API}/${DashBoardFaturamentoService.RESOURCE}/dashboard-faturamento-without-refresh?${queryOption.toQueryString()}`,
    );
  }

  getDashboardEstado(estadoId: number): Observable<DashBoardEstadoFaturamento> {
    return this.httpClient.get<DashBoardEstadoFaturamento>(
      `${WEB_API}/${DashBoardFaturamentoService.RESOURCE}/dashboard-estado-faturamento/${estadoId}`,
    );
  }

  getDashboardEstadoFaturamento(): Observable<DashBoardEstadoFaturamento[]> {
    return this.httpClient.get<DashBoardEstadoFaturamento[]>(
      `${WEB_API}/${DashBoardFaturamentoService.RESOURCE}/resumo-estado-faturamento`,
    );
  }

  // **************************** DIGITAL ****************************************** //

  findAllDigital(queryOption: QueryOptions): Observable<DashBoardFaturamento> {
    return this.httpClient.get<DashBoardFaturamento>(
      `${WEB_API}/${DashBoardFaturamentoService.RESOURCE}/find-all-digital?${queryOption.toQueryString()}`,
    );
  }

  getDashBoardDigital(queryOption: QueryOptions): Observable<DashBoardFaturamento> {
    return this.httpClient.get<DashBoardFaturamento>(
      `${WEB_API}/${DashBoardFaturamentoService.RESOURCE}/dashboard-faturamento-digital?${queryOption.toQueryString()}`,
    );
  }

  getDashBoardWithoutRefreshDigital(queryOption: QueryOptions): Observable<DashBoardFaturamento> {
    return this.httpClient.get<DashBoardFaturamento>(
      `${WEB_API}/${DashBoardFaturamentoService.RESOURCE}/dashboard-faturamento-without-refresh-digital?${queryOption.toQueryString()}`,
    );
  }

  getDashboardEstadoDigital(estadoId: number): Observable<DashBoardEstadoFaturamento> {
    return this.httpClient.get<DashBoardEstadoFaturamento>(
      `${WEB_API}/${DashBoardFaturamentoService.RESOURCE}/dashboard-estado-faturamento-digital/${estadoId}`,
    );
  }

  getDashboardEstadoFaturamentoDigital(): Observable<DashBoardEstadoFaturamento[]> {
    return this.httpClient.get<DashBoardEstadoFaturamento[]>(
      `${WEB_API}/${DashBoardFaturamentoService.RESOURCE}/resumo-estado-faturamento-digital`,
    );
  }

  // **************************** NACIONAL ****************************************** //

  findAllNacional(queryOption: QueryOptions): Observable<DashBoardFaturamento> {
    return this.httpClient.get<DashBoardFaturamento>(
      `${WEB_API}/${DashBoardFaturamentoService.RESOURCE}/find-all-nacional?${queryOption.toQueryString()}`,
    );
  }

  getDashBoardNacional(queryOption: QueryOptions): Observable<DashBoardFaturamento> {
    return this.httpClient.get<DashBoardFaturamento>(
      `${WEB_API}/${DashBoardFaturamentoService.RESOURCE}/dashboard-faturamento-nacional?${queryOption.toQueryString()}`,
    );
  }

  getDashBoardWithoutRefreshNacional(queryOption: QueryOptions): Observable<DashBoardFaturamento> {
    return this.httpClient.get<DashBoardFaturamento>(
      `${WEB_API}/${DashBoardFaturamentoService.RESOURCE}/dashboard-faturamento-without-refresh-nacional?${queryOption.toQueryString()}`,
    );
  }

  getDashboardEstadoNacional(estadoId: number): Observable<DashBoardEstadoFaturamento> {
    return this.httpClient.get<DashBoardEstadoFaturamento>(
      `${WEB_API}/${DashBoardFaturamentoService.RESOURCE}/dashboard-estado-faturamento-nacional/${estadoId}`,
    );
  }

  getDashboardEstadoFaturamentoNacional(): Observable<DashBoardEstadoFaturamento[]> {
    return this.httpClient.get<DashBoardEstadoFaturamento[]>(
      `${WEB_API}/${DashBoardFaturamentoService.RESOURCE}/resumo-estado-faturamento-nacional`,
    );
  }

  // **************************** BOLETO ****************************************** //

  findallBoleto(queryOption: QueryOptions): Observable<DashBoardFaturamento>{
    return this.httpClient.get<DashBoardFaturamento>(
      `${WEB_API}/${DashBoardFaturamentoService.RESOURCE}/find-all-boleto?${queryOption.toQueryString()}`,
    );
  }

  getDashBoardBoleto(queryOption: QueryOptions): Observable<DashBoardFaturamento> {
    return this.httpClient.get<DashBoardFaturamento>(
      `${WEB_API}/${DashBoardFaturamentoService.RESOURCE}/dashboard-faturamento-boleto?${queryOption.toQueryString()}`,
    );
  }
  
  getDashBoardWithoutRefreshBoleto(queryOption: QueryOptions): Observable<DashBoardFaturamento> {
    return this.httpClient.get<DashBoardFaturamento>(
      `${WEB_API}/${DashBoardFaturamentoService.RESOURCE}/dashboard-faturamento-without-refresh-boleto?${queryOption.toQueryString()}`,
    );
  }

  getDashboardEstadoBoleto(estadoId: number): Observable<DashBoardEstadoFaturamento> {
    return this.httpClient.get<DashBoardEstadoFaturamento>(
      `${WEB_API}/${DashBoardFaturamentoService.RESOURCE}/dashboard-estado-faturamento-boleto/${estadoId}`,
    );
  }

  getDashboardEstadoFaturamentoBoleto(): Observable<DashBoardEstadoFaturamento[]> {
    return this.httpClient.get<DashBoardEstadoFaturamento[]>(
      `${WEB_API}/${DashBoardFaturamentoService.RESOURCE}/resumo-estado-faturamento-boleto`,
    );
  }
}
