import { ContaTev } from '../../../base/domain/conta-tev.model';
import { QueryOptions } from '../../domain/query.options';
import { EventEmitter, Output } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';

export interface AcaoEvent {
  acao: string;
  contaTev: ContaTev;
}

@Component({
  selector: 'app-conta-tev-list',
  templateUrl: './conta-tev-list.component.html',
  styleUrls: ['./conta-tev-list.component.css']
})
export class ContaTevListComponent implements OnInit {

  @Input() title: string;
  @Input() nomePlaceholder: string;
  @Input() rowsNumber: number;
  @Input() totalRecords: number;
  @Input() loading: boolean;
  @Input() filtros: string[];
  @Input() options: string[];
  @Input() contas: ContaTev[];

  @Output() novo = new EventEmitter<void>();
  @Output() loadPage = new EventEmitter<number>();
  @Output() acaoClick = new EventEmitter<AcaoEvent>();

  private currentPage = 0;

  queryOptions = new QueryOptions();

  constructor() {}

  ngOnInit(): void {}

  load(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadPage.emit(pageNumber);
  }

  onAcaoClick(acao: string, domain: ContaTev): void {
    this.acaoClick.emit({ acao, contaTev: domain });
  }

  onAtualizar(): void {
    this.loadPage.emit(this.currentPage);
  }

  onNovo(): void {
    this.novo.emit();
  }

}
