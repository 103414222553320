import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { LazyLoadEvent } from 'primeng/api';
import { AnaliseDeRiscoCliente } from 'src/app/base/domain/analise-de-risco-cliente.model';
import { Posto } from 'src/app/base/domain/posto.model';
import { DialogService } from '../../services/dialog.service';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { AnaliseDeRiscoClienteService } from 'src/app/base/services/analise-de-risco-cliente.service';
import { AppState } from 'src/app/App.state';
import { QueryOptions } from '../../domain/query.options';
import { ArquivoAnaliseDeRiscoService } from 'src/app/base/services/arquivo-analise-de-risco.service';
import { ArquivoAnaliseDeRisco } from 'src/app/base/domain/arquivo-analise-de-risco.model';
import { ReturnAPI } from 'src/app/base/domain/return-api.model';
import { Router } from '@angular/router';
import { UpdateArquivoAnaliseRiscoDTO } from 'src/app/base/domain/dto/update-arquivo-analise.dto';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface AnaliseDeRiscoAcaoEvent {
  acao: string;
  selecionados: AnaliseDeRiscoCliente[];
}

export interface FilterOptions {
  postoSelecionado: Posto;
  dataInicial: Date;
  dataFinal: Date;
  valorInicial: string;
  valorFinal: string;
}

@Component({
  selector: 'app-analise-de-risco-cliente-list',
  templateUrl: './analise-de-risco-cliente-list.component.html',
  styleUrls: ['./analise-de-risco-cliente-list.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
  ],
})
export class AnaliseDeRiscoClienteListComponent implements OnInit {
  @Input() itens: AnaliseDeRiscoCliente[];
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() loading: boolean;
  @Input() title: string;
  @Input() nomePlaceholder: string;
  @Input() dataPlaceholder: string;
  @Input() filtros: string[];
  @Input() options: string[];
  @Input() optionalColumns: string[];
  @Input() menuAcoes: string[];
  @Input() lazy: boolean;
  @Input() id: number;
  @Input() action: string;

  @Output() loadEvent = new EventEmitter<number>();
  @Output() filter = new EventEmitter<FilterOptions>();
  @Output() acaoClick = new EventEmitter<AnaliseDeRiscoAcaoEvent>();
  @Output() novo = new EventEmitter<void>();

  date = new FormControl(moment());

  mesVigente: any;

  private currentPage = 0;

  queryOptions = new QueryOptions();

  itensSelecionados: AnaliseDeRiscoCliente[] = [];

  arquivoAnalise: ArquivoAnaliseDeRisco = new ArquivoAnaliseDeRisco();

  private readonly defaultFilterOptions: FilterOptions = {
    postoSelecionado: null,
    valorInicial: null,
    valorFinal: null,
    dataInicial: null,
    dataFinal: null,
  };

  filterOptions: FilterOptions;

  constructor(
    public dialog: MatDialog,
    public dialogService: DialogService,
    public appState: AppState,
    public service: ArquivoAnaliseDeRiscoService,
    public analiseService: AnaliseDeRiscoClienteService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.onLimparFiltros();
    this.service.findOne(this.id).subscribe((resp: ReturnAPI<ArquivoAnaliseDeRisco>) => {
      this.arquivoAnalise = resp.object;
    });
  }

  load(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadEvent.emit(pageNumber);
  }

  onAtualizar(): void {
    this.loadEvent.emit(this.currentPage);
  }

  onLimparFiltros(): void {
    if (this.filterOptions) {
      this.filterOptions.dataInicial = null;
      this.filterOptions.dataFinal = null;
    }
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  async onSave(): Promise<void> {
    if (this.action === 'update') {
      this.onCheckboxChange(null);
    }

    if (this.action === 'create') {
      let postoId = this.filterOptions.postoSelecionado ? this.filterOptions.postoSelecionado.id : null;
      let dataInicial = this.filterOptions.dataInicial ? this.filterOptions.dataInicial.toLocaleDateString('pt-BR') : null;
      let dataFinal = this.filterOptions.dataFinal ? this.filterOptions.dataFinal.toLocaleDateString('pt-BR') : null;
      let valorInicial = this.filterOptions.valorInicial ? this.filterOptions.valorInicial : null;
      let valorFinal = this.filterOptions.valorFinal ? this.filterOptions.valorFinal : null;
      let mesVigente = this.mesVigente ? new Date(this.mesVigente).toLocaleDateString('pt-BR') : null;

      this.itensSelecionados.forEach((item: AnaliseDeRiscoCliente) => {
        item.filtros =
          `postoId=${postoId}&dataInicial=${dataInicial}&dataFinal=${dataFinal}` +
          `&valorInicial=${valorInicial}&valorFinal=${valorFinal}&mesVigente=${mesVigente}`;
        item.arquivoAnalise = this.arquivoAnalise;
      });
    }

    let arquivoDTO = new UpdateArquivoAnaliseRiscoDTO();
    arquivoDTO.analises = this.itensSelecionados;
    arquivoDTO.arquivoId = this.id;
    arquivoDTO.action = this.action;

    this.onSaveArquivo(arquivoDTO);
  }

  async onSaveArquivo(analises: UpdateArquivoAnaliseRiscoDTO): Promise<void> {
    this.appState.isLoading = true;
    try {
      const response = await this.analiseService.saveBulk(analises).toPromise();
      this.appState.isLoading = false;
      this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso!');
      if (response.success) {
        this.router.navigate(['base/clientes/analise-de-risco']);
      }
    } catch {
      this.appState.isLoading = false;
      this.dialogService.feedbackError('Erro ao Salvar Analise!');
    }
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  onCheckboxChange($event: MatCheckboxChange): void {
    this.itensSelecionados = this.itens
      .filter((item: AnaliseDeRiscoCliente) => item.selecionado)
      .map((item: AnaliseDeRiscoCliente) => item);
  }

  selectAll(): void {
    this.itens.forEach((item: AnaliseDeRiscoCliente) => {
      item.selecionado = true;
    });
    this.itensSelecionados = this.itens
      .filter((item: AnaliseDeRiscoCliente) => item.selecionado)
      .map((item: AnaliseDeRiscoCliente) => item);
  }

  unselectAll(): void {
    this.itens.forEach((item: AnaliseDeRiscoCliente) => {
      item.selecionado = false;
    });
    this.itensSelecionados = [];
  }

  onAcaoClick(acao: string, domain: AnaliseDeRiscoAcaoEvent[]): void {
    this.acaoClick.emit({ acao, selecionados: this.itensSelecionados });
  }

  chosenYearHandler(normalizedYear: Moment): any {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>): any {
    this.mesVigente = normalizedMonth;

    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }
}
