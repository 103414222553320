import { Component, OnInit, Inject, Input, OnChanges, SimpleChanges, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Endereco } from 'src/app/base/domain/endereco.model';
import { Estado } from 'src/app/base/domain/estado.model';
import { Municipio } from 'src/app/base/domain/municipio.model';
import { Bairro } from 'src/app/base/domain/bairro.model';
import { PublicService } from 'src/app/base/services/public.service';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import { QueryOptions } from '../../domain/query.options';
import { FormControl, FormArray, FormBuilder } from '@angular/forms';

export type EnderecoSelectable = Estado | Municipio | Bairro;
export type EnderecoSelectableElement = 'estado' | 'cidade' | 'bairro';

export interface EnderecoSelectableOption {
  lista: EnderecoSelectable[];
  filtrados: EnderecoSelectable[];
  busca: string;
  buscaProp: string;
}

export interface EnderecoFormData {
  endereco: Endereco;
  toEdit: boolean;
  isMutating?: boolean;
}

@Component({
  selector: 'app-endereco-form',
  templateUrl: './endereco-form.component.html',
  styleUrls: ['./endereco-form.component.css'],
})
export class EnderecoFormComponent implements OnInit, OnChanges {
  @Input() endereco: Endereco;
  @Input() toEdit = false;

  private enderecoControl = new FormBuilder().group({
    cep: new FormControl(),
  });

  private mutableEndereco: Partial<Endereco>;

  selectableOptions: { [key: string]: EnderecoSelectableOption } = {
    estado: {
      lista: [],
      filtrados: [],
      busca: '',
      buscaProp: 'nome',
    },
    cidade: {
      lista: [],
      filtrados: [],
      busca: '',
      buscaProp: 'nomeMunicipio',
    },
    bairro: {
      lista: [],
      filtrados: [],
      busca: '',
      buscaProp: 'descricao',
    },
  };

  constructor(
    public dialogRef: MatDialogRef<EnderecoFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EnderecoFormData,
    private publicService: PublicService,
  ) {
    this.endereco = data?.endereco;
    this.toEdit = data?.toEdit || false;
    this.mutableEndereco = Object.assign({}, this.endereco || {});
  }

  ngOnInit(): void {
    this.fillFilledSelections();
    this.listarEstados();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  private fillFilledSelections(): void {
    if (this.mutableEndereco.estado) {
      this.selectableOptions.estado.busca = this.mutableEndereco.estado.nome;
    }
    if (this.mutableEndereco.municipio) {
      this.selectableOptions.cidade.busca = this.mutableEndereco.municipio.nomeMunicipio;
    }
    if (this.mutableEndereco.bairro) {
      this.selectableOptions.bairro.busca = this.mutableEndereco.bairro.descricao;
    }
  }

  private cleanSelectableOption(element: EnderecoSelectableElement): void {
    this.selectableOptions[element].busca = '';
    this.onSelect(element, '');
  }

  private listarEstados(): void {
    this.publicService.listarEstados().subscribe((response: ReturnAPI<Page<Estado>>) => {
      if (response.success) {
        this.selectableOptions.estado.lista = response.object.content;
        this.doFilter('estado');
        if (this.mutableEndereco.estado) {
          this.selectableOptions.estado.busca = this.mutableEndereco.estado.nome;
          this.onSelect('estado', this.mutableEndereco.estado.nome);
        }
      }
    });
  }

  private listarCidades(): void {
    const estado = this.mutableEndereco.estado;
    const queryOptions = new QueryOptions({
      pageNumber: 1,
      params: {
        estado: `eq:${estado.id}`,
      },
    });
    this.publicService.listarMunicipios(queryOptions).subscribe((response: ReturnAPI<Page<Municipio>>) => {
      if (response.success) {
        this.selectableOptions.cidade.lista = response.object.content;
        this.selectableOptions.cidade.busca = '';
        this.doFilter('cidade');
        if (this.mutableEndereco.municipio && this.mutableEndereco.municipio.estado?.id === estado.id) {
          this.selectableOptions.cidade.busca = this.mutableEndereco.municipio.nomeMunicipio;
          this.onSelect('cidade', this.mutableEndereco.municipio.nomeMunicipio);
        } else {
          this.cleanSelectableOption('cidade');
          this.cleanSelectableOption('bairro');
          this.selectableOptions.bairro.lista = [];
          this.doFilter('bairro');
        }
      }
    });
  }

  private listarBairros(): void {
    const cidade = this.mutableEndereco.municipio;
    const queryOptions = new QueryOptions({
      pageNumber: 1,
      params: {
        municipio: `eq:${cidade.id}`,
      },
    });
    this.publicService.listarBairros(queryOptions).subscribe((response: ReturnAPI<Page<Bairro>>) => {
      if (response.success) {
        this.selectableOptions.bairro.lista = response.object.content;
        this.selectableOptions.bairro.busca = '';
        this.doFilter('bairro');
        if (this.mutableEndereco.bairro && this.mutableEndereco.bairro.municipio?.id === cidade.id) {
          this.selectableOptions.bairro.busca = this.mutableEndereco.bairro?.descricao;
          this.onSelect('bairro', this.mutableEndereco.bairro.descricao);
        } else {
          this.cleanSelectableOption('bairro');
        }
      }
    });
  }

  doFilter(element: EnderecoSelectableElement): void {
    this.selectableOptions[element].filtrados = this._filtrar(
      this.selectableOptions[element].busca,
      this.selectableOptions[element].lista,
      this.selectableOptions[element].buscaProp,
    );
  }

  onSelect(element: EnderecoSelectableElement, value: string): void {
    this.mutableEndereco[element === 'cidade' ? 'municipio' : element] =
      (this.selectableOptions[element].lista.find(
        (e: EnderecoSelectable) => e[this.selectableOptions[element].buscaProp] === value,
      ) as Estado & Municipio & Bairro) || null;

    if (element === 'estado') {
      this.listarCidades();
    }
    if (element === 'cidade' && value) {
      this.listarBairros();
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    const result = this.data.isMutating ? Object.assign(this.endereco || {}, this.mutableEndereco) : this.mutableEndereco;
    this.dialogRef.close(result);
  }

  private _filtrar<T>(value: string, list: T[], field: string): T[] {
    const filterValue = value?.toLowerCase();
    return list.filter((e: T) => e[field].toLowerCase().indexOf(filterValue) === 0);
  }
}
