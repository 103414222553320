import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConciliacaoBancariaTed } from 'src/app/base/domain/conciliacao-bancaria-ted.model';
import { ConciliacaoDTO } from 'src/app/base/domain/dto/conciliacao.dto';
import { ParcelaClienteTEDView } from 'src/app/base/domain/parcela-cliente-ted-view.model';
import { ConciliacaoBancariaTedService } from 'src/app/base/services/conciliacao-bancaria-ted.service';

export interface ConciliacaoFormData {
  conciliacao: ConciliacaoDTO;
}

@Component({
  selector: 'app-vincular-conciliacao-ted',
  templateUrl: './vincular-conciliacao-ted.component.html',
  styleUrls: ['./vincular-conciliacao-ted.component.css'],
})
export class VincularConciliacaoTedComponent implements OnInit {
  parcelas: ParcelaClienteTEDView[];
  parcelaSelecionado: ParcelaClienteTEDView;
  exibirMensagem: boolean = false;
  parcela: ParcelaClienteTEDView;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConciliacaoFormData,
    private service: ConciliacaoBancariaTedService,
    public dialogRef: MatDialogRef<VincularConciliacaoTedComponent>,
  ) {}

  ngOnInit(): void {
    this.service.findAllParcela(this.data.conciliacao.data).subscribe((resource) => {
      if (resource && resource.length > 0) {
        this.parcelas = resource;
      } else {
        this.exibirMensagem = true;
      }
    });
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  confirmar(): void {
    const parcelaDTO = {
      conciliacaoBancariaTedId: this.data.conciliacao.id,
      parcelaClienteTEDViewId: this.parcela.id,
    };
    this.dialogRef.close(Object.assign(parcelaDTO));
  }
}
