export enum SituacaoContrato {
  TODOS_OS_CONTRATOS = 'TODOS_OS_CONTRATOS',
  NOVO = 'NOVO',
  RENOVADO = 'RENOVADO',
  REPACTUADO = 'REPACTUADO',
  LIBERADO = 'LIBERADO',
  EM_ANDAMENTO = 'EM_ANDAMENTO',
  FINALIZADO = 'FINALIZADO',
  CANCELADO = 'CANCELADO',
  FINALIZADO_COM_DIVIDA = 'FINALIZADO_COM_DIVIDA',
  FINALIZADO_COM_ATRASO = 'FINALIZADO_COM_ATRASO',
  FINALIZADO_COM_ANTECIPACAO = 'FINALIZADO_COM_ANTECIPACAO',
  EM_ATRASO = 'EM_ATRASO',
  PENDENTE = 'PENDENTE',
  ANTECIPADO_BORDERO = 'ANTECIPADO_BORDERO',
  LIQUIDADO_ADMINISTRACAO = 'LIQUIDADO_ADMINISTRACAO',
  RENEGOCIADO = 'RENEGOCIADO',
  AGUARDANDO_CANCELAMENTO = 'AGUARDANDO_CANCELAMENTO',
  GERADO = 'GERADO',
}
