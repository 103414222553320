import { OnInit, Component } from '@angular/core';
import { Captado } from '../../../domain/captado.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { CaptadoListFilterOptions } from 'src/app/global/lists/captado-list/captado-list.component';
import { CaptadoService } from '../../../services/captado.service';
import { DialogService } from 'src/app/global/services/dialog.service';
import { ReturnAPI, Page } from '../../../domain/return-api.model';
import { Posto } from '../../../domain/posto.model';
import { QuantidadeContratacaoCaptadoDTO } from '../../../domain/dto/quantidade-contratacao-captado.dto';
import { updateCounterDescription } from '../../../utils/conter-description';
import { AmostraContratacaoCaptacaoDTO } from '../../../domain/dto/amostra-contratacao-captacao.dto';

@Component({
  selector: 'app-contratacao-captacao',
  templateUrl: './contratacao-captacao-main.component.html',
  styleUrls: ['./contratacao-captacao-main.component.css'],
})
export class ContratacaoCaptacaoMainComponent implements OnInit {
  captados: Captado[] = [];

  labels: string[] = [];
  series: number[][] = [];
  quantidadeCaptados = 0;
  quantidadeContratados = 0;

  counterMetadata = {
    description: 'Todos as Datas',
    subDescription: 'Todos os Postos',
  };

  queryOptions = new QueryOptions({ pageSize: 10, params: { contratacao: 'all' } });
  totalRecords = 0;
  loading = false;

  private postoSelecionado: number = null;
  private dataInicialSelecionada: Date = null;
  private dataFinalSelecionada: Date = null;

  constructor(private captadoService: CaptadoService, public dialogService: DialogService) {}

  ngOnInit(): void {
    this.amostrarContratacaoCaptados();
    this.consultarQuantidadeContratacaoCaptados();
  }

  listarCaptados(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.captadoService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Captado>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.captados = response.object.content;
      } else {
        this.captados = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
    });
  }

  amostrarContratacaoCaptados(postoID?: number): void {
    this.captadoService.amostrarContratacoesDoAno(postoID).subscribe((response: ReturnAPI<AmostraContratacaoCaptacaoDTO>) => {
      if (response.success) {
        this.labels = response.object.meses;
        this.series = [response.object.captados, response.object.contratados];
      }
    });
  }

  consultarQuantidadeContratacaoCaptados(dataInicial?: Date, dataFinal?: Date, posto?: Posto): void {
    const options = new QueryOptions({
      params: {
        dataInicio: dataInicial?.toLocaleString(),
        dataFinal: dataFinal?.toLocaleString(),
        postoId: posto?.id,
      },
    });
    this.captadoService.quantidadeContratacaoCaptados(options).subscribe((response: ReturnAPI<QuantidadeContratacaoCaptadoDTO>) => {
      if (response.success) {
        this.quantidadeCaptados = response.object.captados;
        this.quantidadeContratados = response.object.contratados;
        this.counterMetadata = updateCounterDescription(dataInicial, dataFinal, posto);
      }
    });
  }

  onLoadCaptadosPage(page: number): void {
    this.listarCaptados(page);
  }

  onFilter(options: CaptadoListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    const dataInicial = options.dataInicial || null;
    const dataFinal = options.dataFinal || null;

    this.queryOptions.params = {
      ...this.queryOptions.params,
      nomeCliente: options.nomeCaptado ? `like:${options.nomeCaptado}` : null,
      situacaoCaptado: options.situacoesCaptado?.length ? `in:${options.situacoesCaptado.join(',')}` : null,
      contratacao: options.contratacaoSituacao || 'all',
      dataInicio: dataInicial?.toLocaleString(),
      dataFinal: dataFinal?.toLocaleString(),
      postoId,
    };

    this.listarCaptados();

    if (dataInicial !== this.dataInicialSelecionada || dataFinal !== this.dataFinalSelecionada || postoId !== this.postoSelecionado) {
      this.consultarQuantidadeContratacaoCaptados(options.dataInicial, options.dataFinal, options.postoSelecionado);
      this.dataInicialSelecionada = dataInicial;
      this.dataFinalSelecionada = dataFinal;
    }

    if (postoId !== this.postoSelecionado) {
      this.amostrarContratacaoCaptados(postoId);
      this.postoSelecionado = postoId;
    }
  }
}
