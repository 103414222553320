import { Resource } from 'src/app/global/domain/resource.model';
import { PostoDTO } from './dto/posto.dto';

export class DashBoardEstadoFaturamento extends Resource {
  nomeEstado: string;

  estadoId: number;

  postos: PostoDTO[];

  idsPostos: number[];
}
