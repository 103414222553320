import { PostoSelectDTO } from './../domain/dto/posto-select.dto';
import { Injectable } from '@angular/core';
import { ResourceService } from 'src/app/global/services/resource.service';
import { Posto } from '../domain/posto.model';
import { PostoSerializer } from '../serializables/posto.serializer';
import { HttpClient } from '@angular/common/http';
import { WEB_API } from 'src/app/global/services/web-api';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { Observable } from 'rxjs';
import { Page, ReturnAPI } from '../domain/return-api.model';
import { Equipe } from '../domain/equipe.model';
import { Produto } from '../domain/produto.model';
import { TransferirRotaDTO } from '../domain/dto/transferir-rota.dto';
import { PostoDTO } from '../domain/dto/posto.dto';

@Injectable()
export class PostoService extends ResourceService<Posto> {
  static RESOURCE = 'posto';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, 'caixa', new PostoSerializer(Posto));
  }

  findAll(options: QueryOptions): Observable<ReturnAPI<Page<Posto>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Posto>>>(`${WEB_API}/posto?${options.toQueryString()}&${this.produtoId}`);
  }

  findAllWithoutProduto(options: QueryOptions): Observable<ReturnAPI<Page<Posto>>> {
    return this.httpClient.get<ReturnAPI<Page<Posto>>>(`${WEB_API}/posto?${options.toQueryString()}`);
  }

  findPostosGeralNaoAberto(options: QueryOptions): Observable<ReturnAPI<Page<Posto>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Posto>>>(
      `${WEB_API}/posto/find-postos-geral-nao-aberto?${options.toQueryString()}&${this.produtoId}`,
    );
  }

  listarPostos(): Observable<ReturnAPI<Posto[]>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Posto[]>>(`${WEB_API}/posto/listar-postos?${this.produtoId}`);
  }

  listarPostosSemEletro(): Observable<ReturnAPI<Posto[]>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Posto[]>>(`${WEB_API}/posto/listar-postos-sem-eletro?${this.produtoId}`);
  }

  listarPostosDigitais(): Observable<ReturnAPI<Posto[]>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Posto[]>>(`${WEB_API}/posto/listar-postos-digitais?${this.produtoId}`);
  }

  listarPostosEstado(estado: string): Observable<ReturnAPI<Posto[]>> {
    return this.httpClient.get<ReturnAPI<Posto[]>>(`${WEB_API}/posto/listar-postos?${this.produtoId}&uf=${estado}`);
  }

  listarPostosEstadoRelatorioRespesas(estado: string): Observable<PostoDTO[]> {
    return this.httpClient.get<PostoDTO[]>(`${WEB_API}/relatorio-despesas/buscar-postos-por-estado?${this.produtoId}&uf=${estado}`);
  }

  buscarEquipesPorPosto(postoId: number): Observable<ReturnAPI<Page<Equipe>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Equipe>>>(`${WEB_API}/posto/equipes-posto?${this.produtoId}&postoId=${postoId}`);
  }

  transferirRota(transferirRotaDTO: TransferirRotaDTO): Observable<ReturnAPI<void>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<void>>(
      `${WEB_API}/posto/transferir-rotas?${this.produtoId}&${this.produtoId}`,
      transferirRotaDTO,
    );
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }

  public getSelect(estadoId?: number): Observable<PostoSelectDTO[]> {
    const estadoParameter = estadoId ? estadoId : '';
    return this.httpClient.get<PostoSelectDTO[]>(`${WEB_API}/${PostoService.RESOURCE}/select/${estadoParameter}`);
  }
}
