import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Funcionario } from 'src/app/base/domain/funcionario.model';
import { Posto } from 'src/app/base/domain/posto.model';
import { Telefone } from 'src/app/base/domain/telefone.model';
import { QueryOptions } from '../../domain/query.options';

export interface FuncionarioAcaoEvent {
  acao: string;
  funcionario: Funcionario;
}

export interface FuncionarioMenuAcaoEvent {
  acao: string;
}

export interface FuncionarioListFilterOptions {
  nome: string;
  postoSelecionado: Posto;
}

@Component({
  selector: 'app-funcionario-list',
  templateUrl: './funcionario-list.component.html',
  styleUrls: ['./funcionario-list.component.css'],
})
export class FuncionarioListComponent implements OnInit {
  @Input() titulo: string;
  @Input() funcionarios: Funcionario[];
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() filtros: string[];
  @Input() menuAcoes: string[];
  @Input() excludeColumns: string[];

  @Output() filter = new EventEmitter<FuncionarioListFilterOptions>();
  @Output() loadFuncionarioPage = new EventEmitter<number>();
  @Output() acaoClick = new EventEmitter<FuncionarioAcaoEvent>();
  @Output() menuAcaoClickEvent = new EventEmitter<FuncionarioMenuAcaoEvent>();
  @Output() disclosureFuncionario = new EventEmitter<Funcionario>();

  private currentPage = 0;
  queryOptions = new QueryOptions();

  private readonly defaultFilterOptions: FuncionarioListFilterOptions = {
    nome: '',
    postoSelecionado: null,
  };

  filterOptions: FuncionarioListFilterOptions;

  ngOnInit(): void {
    this.onLimparFiltros();
  }

  loadFuncionarios(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadFuncionarioPage.emit(pageNumber);
  }

  onDisclosure(funcionario: Funcionario): void {
    this.disclosureFuncionario.emit(funcionario);
  }

  onAcaoClick(acao: string, domain: Funcionario): void {
    this.acaoClick.emit({ acao, funcionario: domain });
  }

  onAtualizar(): void {
    this.loadFuncionarioPage.emit(this.currentPage);
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  public onMenuAcaoClick(acao: string): void {
    this.menuAcaoClickEvent.emit({ acao });
  }

  mapearTelefones(funcionario: Funcionario): string {
    return funcionario.pessoa.listTelefone.map((telefone: Telefone) => telefone.numero).join(', ');
  }
}
