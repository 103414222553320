import { PendenciaFechamentoCaixa } from '../domain/pendencia-fechamento-caixa.model';
import { Injectable } from '@angular/core';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { HttpClient } from '@angular/common/http';
import { PendenciaFechamentoCaixaSerializer } from 'src/app/base/serializables/pendencia-fechamento-caixa.serializer';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { Observable } from 'rxjs';
import { ReturnAPI, Page } from '../domain/return-api.model';
import { Produto } from '../domain/produto.model';

@Injectable()
export class PendenciaFechamentoCaixaService extends ResourceService<PendenciaFechamentoCaixa> {
  static RESOURCE = 'pendencia-fechamento-caixa';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, PendenciaFechamentoCaixaService.RESOURCE, new PendenciaFechamentoCaixaSerializer(PendenciaFechamentoCaixa));
  }

  findAll(queryOption: QueryOptions): Observable<ReturnAPI<Page<PendenciaFechamentoCaixa>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<PendenciaFechamentoCaixa>>>(
      `${WEB_API}/${PendenciaFechamentoCaixaService.RESOURCE}?${queryOption.toQueryString()}&${this.produtoId}`,
    );
  }

  confirmarPendencia(pendenciaId: number): Observable<ReturnAPI<void>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<void>>(
      `${WEB_API}/${PendenciaFechamentoCaixaService.RESOURCE}/confirmar-pendencia?pendenciaId=${pendenciaId}&${this.produtoId}`,
      null,
    );
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }
}
