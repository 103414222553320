import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GlobalModule } from 'src/app/global/global.module';
import { CaixaGeralMainComponent } from './caixa-geral-main/caixa-geral-main.component';
import { CaixaGeralMovimentacoesComponent } from './caixa-geral-movimentacoes/caixa-geral-movimentacoes.component';

@NgModule({
  declarations: [CaixaGeralMainComponent, CaixaGeralMovimentacoesComponent],
  imports: [CommonModule, GlobalModule],
})
export class CaixaGeralModule { }
