import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalModule } from 'src/app/global/global.module';
import { ContratosEmFinalizacaoMainComponent } from './contrato-em-finalizacao-main/contratos-em-finalizacao-main.component';
import { ContratoEmFinalizacaoDetailComponent } from './contrato-em-finalizacao-detail/contrato-em-finalizacao-detail.component';

@NgModule({
  declarations: [ContratosEmFinalizacaoMainComponent, ContratoEmFinalizacaoDetailComponent],
  imports: [CommonModule, GlobalModule],
})
export class ContratosEmFinalizacaoModule {}
