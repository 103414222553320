import { Resource } from 'src/app/global/domain/resource.model';
import { Funcionario } from './funcionario.model';
import { Cliente } from './cliente.model';
import { Caixa } from './caixa.model';
import { Contrato } from './contrato.model';
import { Posto } from './posto.model';

export class Reagendamento extends Resource {
  dataOperacao: Date;
  funcionario: Funcionario;
  cliente: Cliente;
  caixaFuncionario: Caixa;
  caixaRepasse: Caixa;
  contrato: Contrato;
  contratoOrigem: Contrato;
  posto: Posto;
}
