import { Resource } from 'src/app/global/domain/resource.model';
import { SituacaoParcela } from './enum/situacao-parcela.enum';
import { CobrancaVale } from './cobranca-vale.model';

export class ParcelaCobrancaVale extends Resource {
  ordem: number;

  valor: number;

  valorPago: number;

  dataVencimento: Date;

  dataBaixa: Date;

  situacao: SituacaoParcela;

  cobrancaVale: CobrancaVale;
}
