import { Component, OnInit } from '@angular/core';
import { Contrato } from '../../../domain/contrato.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { Router } from '@angular/router';
import { ContratoService } from '../../../services/contrato.service';
import { DialogService } from 'src/app/global/services/dialog.service';
import { Page, ReturnAPI } from '../../../domain/return-api.model';
import { ContratoAcaoEvent, ContratoListFilterOptions } from 'src/app/global/lists/contrato-list/contrato-list.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { CancelarContratoFormComponent } from 'src/app/global/forms/cancelar-contrato-form/cancelar-contrato-form.component';
import { AuditoriaContrato } from 'src/app/base/domain/auditoria-contrato.model';
import { ConfirmacaoDialogComponent } from 'src/app/global/components/confirmacao-dialog/confirmacao-dialog.component';
import { ValoresContratoRequestDTO } from 'src/app/base/domain/dto/valores-contrato-requesto.dto';
import { SharedService } from 'src/app/auth/shared.service';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { RenegociarContratoFormComponent } from 'src/app/global/forms/renegociar-contrato-form/renegociar-contrato-form.component';

@Component({
  selector: 'app-contratos-renegociar-main',
  templateUrl: './contratos-renegociar-main.component.html',
  styleUrls: ['./contratos-renegociar-main.component.css'],
})
export class ContratosRenegociarMainComponent implements OnInit {
  private static RENEGOCIAR_CONTRATO_ACTION = 'Renegociar Contrato';
  contratos: Contrato[] = [];

  queryOptions = new QueryOptions({ pageSize: 25 });
  totalRecords = 0;
  loading = false;

  shared: SharedService;
  usuario: Usuario;

  totalizadorBruto: number;
  totalizadorLiquido: number;
  menuAcoes: string[] = [];

  private postoSelecionado: number = null;

  constructor(
    private router: Router,
    private contratoService: ContratoService,
    private dialogService: DialogService,
    public dialog: MatDialog,
  ) {
    const shared = SharedService.getInstance();
    this.usuario = shared.usuario;

    this.queryOptions.params = {
      nomeCliente: '',
      situacaoContrato: `in:EM_ATRASO,EM_ANDAMENTO,RENEGOCIADO`,
    };
  }

  ngOnInit(): void {
    this.listarContratos();
  }

  listarContratos(pageNumber: number = 0, valoresContrato: Partial<ValoresContratoRequestDTO> = {}): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.contratoService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Contrato>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.mapearAcoes(response.object.content);
      } else {
        this.contratos = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
      valoresContrato.dataInicial = valoresContrato.dataInicial ? valoresContrato.dataInicial : null;
      valoresContrato.dataGeradoInicial = valoresContrato.dataGeradoInicial ? valoresContrato.dataGeradoInicial : null;
      valoresContrato.dataGeradoFinal = valoresContrato.dataGeradoFinal ? valoresContrato.dataGeradoFinal : null;
      valoresContrato.dataInicial = valoresContrato.dataInicial ? valoresContrato.dataInicial : null;
      valoresContrato.dataFinal = valoresContrato.dataFinal ? valoresContrato.dataFinal : null;
      valoresContrato.nome = valoresContrato.nome ? valoresContrato.nome : '';
      valoresContrato.descricaoRota = valoresContrato.descricaoRota ? valoresContrato.descricaoRota : '';
      valoresContrato.situacoesContrato = ['EM_ATRASO,EM_ANDAMENTO,RENEGOCIADO'];
    });
  }

  mapearAcoes(contratos: Contrato[]): void {
    const mapearAcoes = (contrato: Contrato): Contrato => {
      const acoes = [];
      if (
        (this.usuario.profile === 'ROLE_GESTOR' || this.usuario.profile === 'ROLE_ADMIN') &&
        contrato.situacaoContrato !== 'RENEGOCIADO'
      ) {
        acoes.push(ContratosRenegociarMainComponent.RENEGOCIAR_CONTRATO_ACTION);
      }
      return { ...contrato, acoes };
    };

    this.contratos = contratos?.map(mapearAcoes) ?? [];
  }

  onAcaoClick(event: ContratoAcaoEvent): void {
    const actions = new Map<string, (contrato: Contrato) => void>().set(
      ContratosRenegociarMainComponent.RENEGOCIAR_CONTRATO_ACTION,
      (contrato: Contrato) => {
        this.renegociarContrato(contrato);
      },
    );

    actions.get(event.acao)(event.contrato);
  }

  onContratosPage(page: number): void {
    this.listarContratos(page);
  }

  onFilter(options: ContratoListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    this.queryOptions.params = {
      nomeCliente: options.nomeCliente,
      descricaoRota: options.descricaoRota,
      situacaoContrato: `in:EM_ATRASO,EM_ANDAMENTO,RENEGOCIADO`,
      postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
      dataGeradoInicial: options.dataGeradoInicial ? options.dataGeradoInicial.getTime() : null,
      dataGeradoFinal: options.dataGeradoFinal ? options.dataGeradoFinal.getTime() : null,
      dataCanceladoInicial: options.dataCanceladoInicial ? options.dataCanceladoInicial.getTime() : null,
      dataCanceladoFinal: options.dataCanceladoFinal ? options.dataCanceladoFinal.getTime() : null,
    };

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }

    this.listarContratos(0, {
      nome: options.nomeCliente,
      descricaoRota: options.descricaoRota,
      situacoesContrato: ['EM_ATRASO,EM_ANDAMENTO,RENEGOCIADO'],
      dataInicial: options?.dataInicial,
      dataFinal: options?.dataFinal,
      dataGeradoInicial: options?.dataGeradoInicial,
      dataGeradoFinal: options?.dataGeradoFinal,
    });
    this.listarContratos();
  }

  renegociarContrato(contrato: Contrato): void {
    const dialogRef = this.dialog.open(RenegociarContratoFormComponent, {
      data: {
        contrato: contrato,
        action: 'RENEGOCIAR_CONTRATO',
      },
      width: '350px',
    });

    dialogRef.afterClosed().subscribe((auditoria: AuditoriaContrato) => {
      if (auditoria) {
        this.contratoService.renegociarContrato(auditoria).subscribe(
          (response: ReturnAPI<Contrato>) => {
            this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
            this.listarContratos();
          },
          (e: HttpErrorResponse) => {
            this.dialogService.feedbackError(`Houve um erro ao renegociar contrato.\n ${e.message}`);
          },
        );
      }
    });
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
