import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from 'src/app/App.state';
import { DashboardCobranca } from 'src/app/base/domain/dashboard-cobranca.model';
import { DashBoardEstadoFaturamento } from 'src/app/base/domain/dashboard-estado-faturamento.model';
import { DashBoardCobrancaService } from 'src/app/base/services/dashboard-cobranca.service';
import { DashboardCobrancaFilterOptions } from 'src/app/global/cobranca-dashboard/cobranca-dashboard.component';
import { QueryOptions } from 'src/app/global/domain/query.options';

@Component({
  selector: 'app-acompanhamento-cobranca-main',
  templateUrl: './acompanhamento-cobranca-main.component.html',
  styleUrls: ['./acompanhamento-cobranca-main.component.css'],
})
export class AcompanhamentoCobrancaMainComponent implements OnInit {
  dashboard: DashboardCobranca = new DashboardCobranca();

  data = new Date();
  dashboardEstadoList: DashBoardEstadoFaturamento[] = [];

  queryOptions = new QueryOptions({ pageSize: 10 });

  constructor(private service: DashBoardCobrancaService, private router: Router, public appState: AppState) { }

  ngOnInit(): void {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    this.data = date;
    this.queryOptions.params = {
      dataInicial: date.getTime(),
      dataFinal: date.getTime(),
    };
    this.getDashboard();
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  onReload(): void {
    this.getDashboard();
  }

  getDashboard(): void {
    this.data.setHours(0, 0, 0, 0);
    this.appState.isLoading = true;
    this.service.getDashboard(this.queryOptions).subscribe((response: DashboardCobranca) => {
      this.getDashboardEstado();
      this.dashboard = response;
    });
  }

  getDashboardEstado(): void {
    this.service.getDashboardEstadoFaturamento().subscribe((response: DashBoardEstadoFaturamento[]) => {
      this.dashboardEstadoList = response;
      this.appState.isLoading = false;
    });
  }

  onDisclosure(dashboard: DashBoardEstadoFaturamento): void {
    this.queryOptions.params = {
      ...this.queryOptions.params,
      nomeEstado: dashboard.nomeEstado ? dashboard.nomeEstado : null,
    };

    this.router.navigate(['base/gestao/acompanhamento-cobranca/geral-cobranca/acompanhamento-estado-cobranca/' + dashboard.estadoId], {
      queryParams: this.queryOptions.params,
    });
  }

  onFilter(options: DashboardCobrancaFilterOptions): void {
    this.queryOptions.params = {
      postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
      estadoId: options.estadoSelecionado ? options.estadoSelecionado.id : null,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
      tipos: options.situacoes.length ? options.situacoes.toString() : null
    };

    this.getDashboard();
  }
}
