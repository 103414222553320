import { RealocarFuncionarioDTO } from './../../../base/domain/dto/realocar-funcionario.dto';
import { NgForm } from '@angular/forms';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { Funcionario } from 'src/app/base/domain/funcionario.model';
import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation, ViewChild } from '@angular/core';
import { Posto } from 'src/app/base/domain/posto.model';
import { Cargo } from 'src/app/base/domain/cargo.model';
import { EquipeService } from 'src/app/base/services/equipe.service';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { Equipe } from 'src/app/base/domain/equipe.model';
import { TipoRealocacao } from 'src/app/base/domain/enum/tipo-realocacao.enum';

export interface FilterForm {
  posto: Posto;
  cargo: Cargo;
}

@Component({
  selector: 'app-realocar-funcionario-form',
  templateUrl: './realocar-funcionario-form.component.html',
  styleUrls: ['./realocar-funcionario-form.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class RealocarFuncionarioFormComponent implements OnInit {
  @ViewChild('formRealocacao', { static: true }) formRealocacao: NgForm;

  @Input() postos: Posto[];
  @Input() funcionariosRealocar: Funcionario[];
  @Input() funcionariosTroca: Funcionario[];

  cargos: Partial<Cargo>[] = [
    {
      id: 3,
      descricao: 'COBRADOR',
    },
    {
      id: 7,
      descricao: 'FISCAL',
    },
  ];

  private readonly defaultFilterForm: FilterForm = { posto: null, cargo: null };

  private readonly defaultDTO: RealocarFuncionarioDTO = {
    funcionarioRealocado: null,
    funcionarioTroca: null,
    tipoRealocacao: null,
    postoId: null,
    equipeId: null,
  };

  funcionarioValido = false;
  fiscalValido = true;
  @Output() findRealocar: EventEmitter<FilterForm> = new EventEmitter<FilterForm>();
  @Output() findTroca: EventEmitter<FilterForm> = new EventEmitter<FilterForm>();
  @Output() realocarDTO: EventEmitter<RealocarFuncionarioDTO> = new EventEmitter<RealocarFuncionarioDTO>();

  funcionarioRealocado: Funcionario;
  filterRealocado: FilterForm = Object.assign({}, this.defaultFilterForm);
  postoRealocadoSelecionado = false;

  funcionarioTroca: Funcionario;
  filterTroca: FilterForm = Object.assign({}, this.defaultFilterForm);
  postoTrocaSelecionado = false;

  troca = false;
  tipoRealocacao: TipoRealocacao;

  postoAlvo: Posto;
  equipes: Equipe[];
  equipeAlvo: Equipe;

  validForm = false;
  disabledtroca = false;
  private idCargo: number;
  ocultarEquipeAlvo = true;

  constructor(private equipeService: EquipeService) {
  }

  ngOnInit(): void {
    this.formRealocacao.valueChanges.subscribe(() => {
      this.validForm = this.validateForm();
    });
  }

  selecionarTroca(): void {
    this.troca = !this.troca;
    if (this.troca) {
      this.postoAlvo = null;
      this.equipeAlvo = null;
      this.tipoRealocacao = TipoRealocacao.TROCA_FUNCIONARIO;
      this.verifyFuncionario(this.funcionarioRealocado);
    } else {

      this.tipoRealocacao = null;
      this.funcionarioTroca = null;
      this.filterTroca = Object.assign({}, this.defaultFilterForm);
      this.postoTrocaSelecionado = false;
      this.funcionariosTroca = [];
      this.verifyFuncionario(this.funcionarioRealocado);
    }
  }

  selectPosto(posto: Posto): void {
    if (posto != null) {
      this.postoRealocadoSelecionado = true;
    }
  }

  selectPostoTroca(posto: Posto): void {
    if (posto != null) {
      this.postoTrocaSelecionado = true;
    }
  }

  buscarFuncionariosRealocar(cargo: Cargo): void {
    if (cargo != null) {
      this.findRealocar.emit(this.filterRealocado);

      if (cargo.id == 3) {

        this.idCargo = 3;
        this.troca = true;
        this.disabledtroca = true;
        this.ocultarEquipeAlvo = false
        this.funcionarioValido = true;
      } else {

        this.troca = false;
        this.disabledtroca = false;
        this.ocultarEquipeAlvo = true
        this.funcionarioValido = false;
      }
      this.selecionarTroca();
    }

  }

  buscarFuncionariosTroca(cargo: Cargo): void {
    if (cargo != null) {
      this.findTroca.emit(this.filterTroca);
    }
  }

  verifyFuncionario(funcionario: Funcionario): void {
    if (this.filterRealocado.cargo.id === 7) {
      const options = new QueryOptions();
      options.params = { idFiscal: funcionario.id };
      this.equipeService.buscarPorFiscal(options).subscribe((response: ReturnAPI<Equipe>) => {
        if (response.object != null && this.tipoRealocacao !== TipoRealocacao.TROCA_FUNCIONARIO) {
          this.fiscalValido = false;
          this.funcionarioValido = false;
        } else {
          this.fiscalValido = true;
          this.funcionarioValido = true;
        }
      });
    }
  }

  selecionarPostoAlvo(posto: Posto): void {
    const options = new QueryOptions({ pageNumber: 1 });
    options.params = { postoId: posto.id };
    this.equipeService.findAll(options).subscribe((response: ReturnAPI<Page<Equipe>>) => {
      this.equipes = response.object.content;
    });
  }

  validateForm(): boolean {
    const realocado = this.formRealocacao.controls?.funcionarioRealocado?.value;
    const troca = this.formRealocacao.controls?.funcionarioTroca?.value;
    const alvo = this.formRealocacao.controls?.postoAlvo?.value;

    if (this.tipoRealocacao === TipoRealocacao.TROCA_FUNCIONARIO) {
      if (realocado && troca && realocado?.id !== troca?.id) {
        return true;
      }
      return false;
    } else {
      if (realocado && alvo && this.funcionarioValido) {
        return true;
      }
      return false;
    }
  }

  realocar(): void {
    const realocacaoDTO: RealocarFuncionarioDTO = Object.assign({}, this.defaultDTO);
    if (this.tipoRealocacao !== TipoRealocacao.TROCA_FUNCIONARIO) {
      this.tipoRealocacao = TipoRealocacao.FUNCIONARIO_POSTO;
      realocacaoDTO.funcionarioRealocado = this.funcionarioRealocado;
      realocacaoDTO.funcionarioTroca = new Funcionario();
      realocacaoDTO.tipoRealocacao = this.tipoRealocacao;
      realocacaoDTO.postoId = this.postoAlvo.id;
      realocacaoDTO.equipeId = this.equipeAlvo ? this.equipeAlvo.id : null;
    } else {
      realocacaoDTO.funcionarioRealocado = this.funcionarioRealocado;
      realocacaoDTO.funcionarioTroca = this.funcionarioTroca;
      realocacaoDTO.tipoRealocacao = this.tipoRealocacao;
      realocacaoDTO.postoId = null;
      realocacaoDTO.equipeId = null;
    }

    this.realocarDTO.emit(realocacaoDTO);
  }
}
