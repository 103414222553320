import { Component, OnInit, Inject } from '@angular/core';
import { Cliente } from 'src/app/base/domain/cliente.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuditoriaClienteDTO } from 'src/app/base/domain/dto/auditoria-cliente.dto';

interface DataType {
  cliente: Cliente;
}

@Component({
  selector: 'app-desbloquear-cliente-form',
  templateUrl: './desbloquear-cliente-form.component.html',
  styleUrls: ['./desbloquear-cliente-form.component.css'],
})
export class DesbloquearClienteFormComponent implements OnInit {
  desbloqueioClienteDTO = new AuditoriaClienteDTO();

  constructor(public dialogRef: MatDialogRef<DesbloquearClienteFormComponent>, @Inject(MAT_DIALOG_DATA) public data: DataType) {
    this.desbloqueioClienteDTO.clienteId = data.cliente.id;
  }

  ngOnInit(): void { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(this.desbloqueioClienteDTO);
  }
}
