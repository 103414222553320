import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Transferencia } from 'src/app/base/domain/transferencia.model';
import { DespesaDTO } from 'src/app/base/domain/dto/despesa.dto';

interface DataType {
  transferencia: Transferencia;
  action: string;
}

@Component({
  selector: 'app-despesa-roubo-form',
  templateUrl: './despesa-roubo-form.component.html',
  styleUrls: ['./despesa-roubo-form.component.css'],
})
export class DespesaRouboFormComponent implements OnInit {
  despesaDTO: DespesaDTO;
  action: string;

  constructor(public dialogRef: MatDialogRef<DespesaRouboFormComponent>, @Inject(MAT_DIALOG_DATA) public data: DataType) {
    this.despesaDTO = {
      postoId: data.transferencia.posto.id,
      transferenciaId: data.transferencia.id,
      funcionarioId: data.transferencia.funcionario.id,
      tipoDespesa: 'FUNCIONARIO',
      categoria: 'ROUBO',
      valor: data.transferencia.valorRealizado,
      segundoValor: 0.0,
    };
    this.action = data.action;
  }

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(this.despesaDTO);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
