import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges, SimpleChanges, AfterViewChecked, AfterViewInit } from '@angular/core';
import Chart from 'chart.js/auto';
import { registerables } from 'chart.js';
import { T } from '@angular/cdk/keycodes';

Chart.register(...registerables);

export interface DoughnutChartTemplate {
  canvas: string;
  elementIndex: number;
  heigth: number;
  maxHeigth: string;
  label: string;
  dataLabels: string[];
  data: number[];
}

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.css']
})
export class DoughnutChartComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;
  public context: CanvasRenderingContext2D;

  @Input() template: DoughnutChartTemplate;

  chart: Chart;

  rgbaColors: string[] = [
    'rgba(255, 0, 0, 0.7)',
    'rgba(255, 100, 0, 0.7)',
    'rgba(255, 220, 0, 0.7)',
    'rgba(110, 225, 0, 0.7)',
    'rgba(95, 210, 215, 0.7)',
    'rgba(10, 55, 235, 0.7)'
  ];

  rgbColors: string[] = [
    'rgb(255, 0, 0)',
    'rgb(255, 100, 0)',
    'rgb(255, 220, 0)',
    'rgb(110, 225, 0)',
    'rgb(95, 210, 215)',
    'rgb(10, 55, 235)'
  ];

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.template && this.template) {
      if (this.chart) {
        this.chart.destroy();
      }
      if (this.canvas) {
        this.context = this.canvas.nativeElement.getContext('2d');
        this.configureCanvasChart();
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.chart) {
      this.chart.destroy();
    }
    if (this.canvas) {
      this.context = this.canvas.nativeElement.getContext('2d');
      this.configureCanvasChart();
    }
  }

  configureCanvasChart(): void {
    this.canvas.nativeElement.id = this.template.canvas;
    this.canvas.nativeElement.height = this.template.heigth;
    this.canvas.nativeElement.style.maxHeight = this.template.maxHeigth;

    const dataSize = this.template.data.length;
    const backgroundColors = this.rgbaColors.filter((o: string, index: number) => index < dataSize);

    const data = {
      labels: this.template.dataLabels,
      datasets: [{
        label: this.template.label,
        data: this.template.data,
        backgroundColor: backgroundColors
      }]
    };

    const config: any = {
      type: 'doughnut',
      data: data,
    };

    this.chart = new Chart(this.context, config);
  }
}
