import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { AreaAtuacao } from '../domain/area-atuacao.model';
import { Produto } from '../domain/produto.model';
import { Page, ReturnAPI } from '../domain/return-api.model';
import { AreaAtuacaoSerializer } from '../serializables/area-atuacao.serializer';

@Injectable()
export class AreaAtuacaoService extends ResourceService<AreaAtuacao> {
  static RESOURCE = 'area-atuacao';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${AreaAtuacaoService.RESOURCE}`, new AreaAtuacaoSerializer(AreaAtuacao));
  }

  findAll(options: QueryOptions): Observable<ReturnAPI<Page<AreaAtuacao>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<AreaAtuacao>>>(
      `${WEB_API}/${AreaAtuacaoService.RESOURCE}?${options.toQueryString()}&${this.produtoId}`,
    );
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }
}
