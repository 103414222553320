import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-importar-arquivo-extrato-form',
  templateUrl: './importar-arquivo-extrato-form.component.html',
  styleUrls: ['./importar-arquivo-extrato-form.component.css'],
})
export class ImportarArquivoExtratoFormComponent implements OnInit {
  documento: File;
  nomeDocumento = '';

  constructor(public dialogRef: MatDialogRef<ImportarArquivoExtratoFormComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {}

  onCancel(): void {
    this.dialogRef.close();
  }
  onConfirm(): void {
    this.dialogRef.close(this.documento);
  }

  private handleFileInput(files: FileList): void {
    this.documento = files.item(0);
    this.nomeDocumento = this.documento.name;
  }
}
