import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcompanhamentoVendedorExternoMainComponent } from './acompanhamento-vendedor-externo-main/acompanhamento-vendedor-externo-main.component';
import { GlobalModule } from 'src/app/global/global.module';
import { AcompanhamentoVendedorExternoDetailComponent } from './acompanhamento-vendedor-detail/acompanhamento-vendedor-detail.component';
import { AcompanhamentoClienteCarteiraMainComponent } from './acompanhamento-cliente-carteira-main/acompanhamento-cliente-carteira-main.component';

@NgModule({
  declarations: [
    AcompanhamentoVendedorExternoMainComponent,
    AcompanhamentoVendedorExternoDetailComponent,
    AcompanhamentoClienteCarteiraMainComponent,
  ],
  imports: [CommonModule, GlobalModule],
})
export class AcompanhamentoVendedorModule {}
