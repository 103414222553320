import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EstadoService } from '../../services/estado.service';
import { PessoaService } from '../../services/pessoa.service';
import { Estado } from './../../domain/estado.model';
import { Pessoa } from '../../domain/pessoa.model';
import { MessageService } from 'src/app/global/components/message/message.service';
import { QueryOptions } from 'src/app/global/domain/query.options';

@Component({
  selector: 'app-pessoa-create',
  templateUrl: './pessoa-create.component.html',
  styleUrls: ['./pessoa-create.component.css'],
})
export class PessoaCreateComponent implements OnInit {
  @ViewChild(NgForm, { static: true }) form: NgForm;
  pessoa = new Pessoa();
  estados: Estado[] = [];

  constructor(
    private pessoaService: PessoaService,
    private estadoService: EstadoService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    let id = this.route.params.subscribe((params) => {
      id = params['id'];
      if (!id) {
        return;
      }
      this.pessoaService.read(+id).subscribe(
        (pessoa) => (this.pessoa = pessoa),
        (response) => {},
        () => {
        }
      );
    });

    this.estadoService
      .list(new QueryOptions())
      .subscribe((estados) => (this.estados = estados));
  }

  save() {
    if (this.form.valid) {
      const result = this.pessoaService.createOrUpdate(this.pessoa);
      result.subscribe(
        (data) => {
          this.messageService.messageSuccess('Operação realizada com sucesso!');
          this.clear();
          this.search();
        },
        (err) => this.messageService.messageErro('Ocorreu um erro ao inserir!')
      );
    } else {
      Object.keys(this.form.controls).forEach((k) =>
        this.form.controls[k].markAsTouched()
      );
    }
  }

  clear() {
    this.form.resetForm();
  }

  search() {
    this.router.navigate(['/basico/pessoa']);
  }

  compare(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  compareCidade(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }
}
