import { Resource } from 'src/app/global/domain/resource.model';
import { Posto } from './posto.model';

export class AuditoriaContrato extends Resource {
  dataOperacao: Date;

  idContrato: number;

  uidContrato: string;

  valorAcordado: number;

  valorMinimoSimulado: number;

  dataVencimento: Date;

  dataLiberacao: Date;

  parecer: string;

  periodicidade: string;

  quantidadeParcelas: number;

  idFuncionario: number;

  nomeFuncionario: string;

  tipoAuditoria: string;

  posto: Posto;

  cancelarSemRetornoSaldo: boolean;

  dataProrrogacao: Date;

  valorFinal: number;

  taxaJuros: number;

  situacaoContrato: string;

  repactuacaoDD: boolean;
}
