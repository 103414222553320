import { OrchestratorCliente } from './../../../domain/orchestrator-cliente.model';
import { ClienteListFilterOptions } from './../../../../global/lists/cliente-list/cliente-list.component';
import { VerificacaoClienteDetailComponent } from './../verificacao-cliente-detail/verificacao-cliente-detail.component';
import { ReturnAPI, Page } from './../../../domain/return-api.model';
import { AppState } from './../../../../App.state';
import { MatDialog } from '@angular/material/dialog';
import { ClienteService } from './../../../services/cliente.service';
import { DialogService } from './../../../../global/services/dialog.service';
import { Component, OnInit } from '@angular/core';
import { Cliente } from 'src/app/base/domain/cliente.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ClienteAcaoEvent } from 'src/app/global/lists/cliente-list/cliente-list.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-verificacao-cliente-main',
  templateUrl: './verificacao-cliente-main.component.html',
  styleUrls: ['./verificacao-cliente-main.component.css']
})
export class VerificacaoClienteMainComponent implements OnInit {
  private static VERIFICAR = 'Verificar situação cliente - Inovamind ';
  private static PROCESSAR_PEP = 'Processar situação PEP';
  private static PROCESSAR_INTERPOL = 'Processar situação INTERPOL';

  clientes: Cliente[] = [];
  queryOptions = new QueryOptions({ pageSize: 25 });
  totalRecords = 0;
  loading = false;

  private postoSelecionado: number = null;

  constructor(
    public dialogService: DialogService,
    private clienteService: ClienteService,
    public dialog: MatDialog,
    public appState: AppState
  ) { }

  ngOnInit(): void { }

  listarClientes(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.clienteService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Cliente>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.clientes = response.object.content;
        this.mapearAcoes(response.object.content);
      } else {
        this.clientes = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
    });
  }

  mapearAcoes(clientes: Cliente[]): void {
    const mapearAcoes = (cliente: Cliente): Cliente => {
      const acoes = [];
      acoes.push(VerificacaoClienteMainComponent.VERIFICAR);
      if (cliente.pessoa.cpf) {
        acoes.push(VerificacaoClienteMainComponent.PROCESSAR_PEP);
      }
      if (cliente.pessoa.nome) {
        acoes.push(VerificacaoClienteMainComponent.PROCESSAR_INTERPOL);
      }
      if (cliente.pessoa.type === 'pf') {
        return { ...cliente, acoes };
      } else {
        return { ...cliente };
      }
    };
    this.clientes = clientes?.map(mapearAcoes) ?? [];
  }

  onAcaoClick(event: ClienteAcaoEvent): void {
    const actions = new Map<string, (cliente: Cliente) => void>()
      .set(VerificacaoClienteMainComponent.VERIFICAR, (cliente: Cliente) => {
        this.verificarCliente(cliente);
      })
      .set(VerificacaoClienteMainComponent.PROCESSAR_PEP, (cliente: Cliente) => {
        this.processarPEP(cliente);
      })
      .set(VerificacaoClienteMainComponent.PROCESSAR_INTERPOL, (cliente: Cliente) => {
        this.processarINTERPOL(cliente);
      });
    actions.get(event.acao)(event.cliente);
  }

  verificarCliente(cliente: Cliente): void {
    const dialogRef = this.dialog.open(VerificacaoClienteDetailComponent, {
      data: {
        cliente: cliente,
        action: 'VERIFICACAO_INOVAMIND',
      },
      width: '550px',
    });

    dialogRef.afterClosed().subscribe((response: OrchestratorCliente[] | HttpErrorResponse) => {
      if (response instanceof HttpErrorResponse && response.error) {
        this.dialogService.feedbackError(`${response.error}`);
      }
      if (response instanceof Array) {
        this.dialogService.feedbackInfo('Solicitação Encaminhada, consulte novamente em alguns instantes!');
      }
    });
  }

  processarPEP(cliente: Cliente): void {
    const options = new QueryOptions({
      params: {
        clienteId: cliente.id,
        services: 'pep_search_v2'
      }
    });
    this.clienteService.requestOrchestrator(options).subscribe((response: OrchestratorCliente[]) => {
      this.dialogService.feedbackSuccess(`
      <p><strong>Verificação encaminhada com sucesso!</strong></p>
      <p><strong>Codigo de verificação: ${response[0].requestToken}</strong></p>
      `);
    },
      (error: HttpErrorResponse) => {
        this.dialogService.feedbackError(`${error.message}`);
      }
    );
  }

  processarINTERPOL(cliente: Cliente): void {
    const options = new QueryOptions({
      params: {
        clienteId: cliente.id,
        services: 'red_notice_interpol'
      }
    });
    this.clienteService.requestOrchestrator(options).subscribe((response: OrchestratorCliente[]) => {
      this.dialogService.feedbackSuccess(`
      <p><strong>Verificação encaminhada com sucesso!</strong></p>
      <p><strong>Codigo de verificação: ${response[0].requestToken}</strong></p>
      `);
    },
      (error: HttpErrorResponse) => {
        this.dialogService.feedbackError(`${error.message}`);
      }
    );
  }

  onLoadClientePage(page: number): void {
    this.listarClientes(page);
  }

  onFilter(options: ClienteListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    this.queryOptions.params = {
      postoId,
      nomeCliente: options.nomeCliente ? `${options.nomeCliente}` : null,
      situacaoCliente: options.situacoesCliente?.length ? `in:${options.situacoesCliente.join(',')}` : null,
      rota: options.descricaoRota || null,
      documentoCliente: options.documentoCliente ? options.documentoCliente : null,
      dataCadastroInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataCadastroFinal: options.dataFinal ? options.dataFinal.getTime() : null,
    };
    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }
    this.listarClientes();
  }
}
