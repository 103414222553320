import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClienteCobrancaJudicialMainComponent } from './cliente-cobranca-judicial-main/cliente-cobranca-judicial-main.component';
import { GlobalModule } from 'src/app/global/global.module';

@NgModule({
  declarations: [ClienteCobrancaJudicialMainComponent],
  imports: [CommonModule, GlobalModule],
})
export class ClienteCobrancaJudicialModule {}
