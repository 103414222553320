import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcompanhamentoTransferenciaEntrePostosMainComponent } from './acompanhamento-transferencia-entre-postos-main/acompanhamento-transferencia-entre-postos-main.component';
import { GlobalModule } from 'src/app/global/global.module';

@NgModule({
  declarations: [AcompanhamentoTransferenciaEntrePostosMainComponent],
  imports: [CommonModule, GlobalModule],
})
export class AcompanhamentoTransferenciaEntrePostosModule {}
