import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlterarSobraDTO } from 'src/app/base/domain/dto/alterar-sobra.dto';
import { ValoresTransferenciaRequestDTO } from 'src/app/base/domain/dto/valores-transferencia-request.dto';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { Transferencia } from 'src/app/base/domain/transferencia.model';
import { TransferenciaService } from 'src/app/base/services/transferencia.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { CancelarSobraFormComponent } from 'src/app/global/forms/cancelar-sobra-form/cancelar-sobra-form.component';
import {
  TransferenciaAcaoEvent,
  TransferenciaListFilterOptions,
} from 'src/app/global/lists/transferencia-list/transferencia-list.component';
import { DialogService } from 'src/app/global/services/dialog.service';

@Component({
  selector: 'app-acompanhamento-sobra',
  templateUrl: './acompanhamento-sobra.component.html',
  styleUrls: ['./acompanhamento-sobra.component.css'],
})
export class AcompanhamentoSobraComponent {
  sobras: Transferencia[] = [];

  queryOptions = new QueryOptions({ pageSize: 10, pageSort: '-id', params: { type: 'tprcontrato', natureza: 'eq:CREDITO_SOBRA' } });

  loading = false;
  totalRecords = 0;

  private postoSelecionado: number = null;
  private dataInicialSelecionada: Date = null;
  private dataFinalSelecionada: Date = null;

  totalizador = 0;

  constructor(private transferenciaService: TransferenciaService, private dialog: MatDialog, public dialogService: DialogService) {}

  mapearAcoesDeSobras(transferencias: Transferencia[]): void {
    const mapearAcoes = (transferencia: Transferencia): Transferencia => {
      const acoes = [];
      if (transferencia.situacao === 'CONFIRMADA') {
        acoes.push('Alterar Sobra');
      }
      return { ...transferencia, acoes };
    };

    this.sobras = transferencias?.map(mapearAcoes) ?? [];
  }

  listarSobras(page: number = 0, valores: Partial<ValoresTransferenciaRequestDTO> = {}): void {
    this.queryOptions.pageNumber = page + 1;
    this.loading = true;
    this.transferenciaService.pageTransferencias(this.queryOptions).subscribe((response: ReturnAPI<Page<Transferencia>>) => {
      if (response.success) {
        this.mapearAcoesDeSobras(response.object.content);
        this.totalRecords = response.object.totalElements;
        this.loading = false;
        valores.tipo = 'TPRCONTRATO';
        valores.dataInicial = valores.dataInicial ? valores.dataInicial : null;
        valores.dataFinal = valores.dataFinal ? valores.dataFinal : null;
        valores.nome = valores.nome ? valores.nome : '';
        valores.descricaoRota = valores.descricaoRota ? valores.descricaoRota : '';
        this.getValorTotalPorTipoTransferencia({ ...valores, postoId: this.postoSelecionado });
      }
    });
  }

  onLoadSobras(pageNumber: number): void {
    this.listarSobras(pageNumber);
  }

  onAcaoClick(event: TransferenciaAcaoEvent): void {
    this.dialog
      .open(CancelarSobraFormComponent, {
        width: '500px',
        data: {
          transferencia: event.transferencia,
          action: 'ALTERAR_SOBRA',
        },
      })
      .afterClosed()
      .subscribe((sobraTO: AlterarSobraDTO) => {
        if (sobraTO) {
          this.transferenciaService.alterarSobra(sobraTO).subscribe(
            (response: ReturnAPI<AlterarSobraDTO>) => {
              this.dialogService.feedbackReturnAPI(response, 'Divergência lançada com sucesso!');
            },
            () => this.dialogService.feedbackError('Houve um erro na solicitacao!'),
          );
        }
      });
  }

  onFilter(options: TransferenciaListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    const dataInicial = options.dataInicial || null;
    const dataFinal = options.dataFinal || null;

    this.queryOptions.params = {
      ...this.queryOptions.params,
      nomeFuncionario: options.nome || null,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
      postoId,
      rota: options.descricaoRota || null,
    };

    if (dataInicial !== this.dataInicialSelecionada || dataFinal !== this.dataFinalSelecionada /* || postoId !== this.postoSelecionado */) {
      this.dataInicialSelecionada = dataInicial;
      this.dataFinalSelecionada = dataFinal;
    }

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }

    this.listarSobras(0, {
      nome: options.nome,
      descricaoRota: options.descricaoRota,
      dataInicial: options?.dataInicial,
      dataFinal: options?.dataFinal,
    });
  }

  getValorTotalPorTipoTransferencia(valores: Partial<ValoresTransferenciaRequestDTO>): void {
    this.transferenciaService.getValorTotalPorTipoTransferencia(valores).subscribe((response: ReturnAPI<number>) => {
      this.totalizador = response.object || 0;
    });
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
