import { DialogService } from './../../../../global/services/dialog.service';
import { RealocarFuncionarioFormComponent } from './../../../../global/forms/realocar-funcionario-form/realocar-funcionario-form.component';
import { RealocarFuncionarioDTO } from './../../../domain/dto/realocar-funcionario.dto';

import { Component, OnInit, ViewChild } from '@angular/core';
import { Posto } from 'src/app/base/domain/posto.model';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { FuncionarioService } from 'src/app/base/services/funcionario.service';
import { PostoService } from 'src/app/base/services/posto.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { Funcionario } from 'src/app/base/domain/funcionario.model';
import { FilterForm } from 'src/app/global/forms/realocar-funcionario-form/realocar-funcionario-form.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-realocar-funcionario-main',
  templateUrl: './realocar-funcionario-main.component.html',
  styleUrls: ['./realocar-funcionario-main.component.css']
})
export class RealocarFuncionarioMainComponent implements OnInit {

  @ViewChild(RealocarFuncionarioFormComponent) formComponent: RealocarFuncionarioFormComponent;

  postos = [];
  funcionariosRealocar = [];
  funcionariosTroca = [];

  constructor(
    private postoService: PostoService,
    private funcionarioService: FuncionarioService,
    public dialog: MatDialog,
    public dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.postoService.findAll(new QueryOptions({pageNumber: 1})).subscribe(
      (response: ReturnAPI<Page<Posto>>) => {
        this.postos = response.object.content;
      }
    );
  }

  findRealocar(event: FilterForm): void {
    const postoId = event.posto.id;
    const cargoId = event.cargo.id;
    this.funcionarioService.listFuncionarioByCargoAndPosto(cargoId, postoId).subscribe(
      (response: ReturnAPI<Funcionario[]>) => {
        this.funcionariosRealocar = response.object;
      }
    );
  }

  findTroca(event: FilterForm): void {
    const postoId = event.posto.id;
    const cargoId = event.cargo.id;
    this.funcionarioService.listFuncionarioByCargoAndPosto(cargoId, postoId).subscribe(
      (response: ReturnAPI<Funcionario[]>) => {
        this.funcionariosTroca = response.object;
      }
    );
  }

  realocar(event: RealocarFuncionarioDTO): void {
    this.funcionarioService.realocarFuncionario(event).subscribe(
      (response: ReturnAPI<Funcionario[]>) => {
        if (response.success) {
          console.log(response);
          this.dialogService.feedbackSuccess('Realocação feita com sucesso!');
          this.formComponent.formRealocacao.reset();
        } else {
          this.dialogService.feedbackInfo('Não foi possivel efetuar a solicitação:' + response.messages);
        }
      },
      () => {
        this.dialogService.feedbackError('Não foi possivel efetuar a operação!');
      }
    );
  }
}
