import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalModule } from 'src/app/global/global.module';
import { FaturamentoBoletoMainComponent } from './faturamento-boleto-main/faturamento-boleto-main.component';
import { FaturamentoBoletoEstadoComponent } from './faturamento-boleto-estado/faturamento-boleto-estado.component';
import { FaturamentoBoletoPostoComponent } from './faturamento-boleto-posto/faturamento-boleto-posto.component';



@NgModule({
  declarations: [FaturamentoBoletoMainComponent, FaturamentoBoletoEstadoComponent, FaturamentoBoletoPostoComponent],
  imports: [CommonModule,GlobalModule]
})
export class AcompanhamentoFaturamentoBoletoModule { }
