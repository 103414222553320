export enum SituacaoParcela {

    GERADA = 'GERADA',
    COBRADA = 'COBRADA',
    LIQUIDADA = 'LIQUIDADA',
    CANCELADA = 'CANCELADA',
    PRAZO_ENCERRADO = 'PRAZO_ENCERRADO',
    QUITADA = 'QUITADA',
    CONFIRMADA = 'CONFIRMADA',
    AGENDADA = 'AGENDADA',
    LIQUIDADA_ADMINISTRACAO = 'LIQUIDADA_ADMINISTRACAO',
    PENDENTE_CONFIRMAR_REPASSE_CLIENTE = 'PENDENTE_CONFIRMAR_REPASSE_CLIENTE',

}
