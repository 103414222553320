import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { Produto } from '../domain/produto.model';
import { Page } from '../domain/return-api.model';
import { ConciliacaoBancariaSerializer } from '../serializables/conciliacao-bancaria.serializer';
import { ConciliacaoBancaria } from './../domain/conciliacao-bancaria.model';
import { DashboardConciliacaoDTO } from '../domain/dto/dashboard-conciliacao.dto';
@Injectable()
export class ConciliacaoBancariaService extends ResourceService<ConciliacaoBancaria> {
  private static RESOURCE = 'conciliacao-bancaria';

  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${ConciliacaoBancariaService.RESOURCE}`, new ConciliacaoBancariaSerializer(ConciliacaoBancaria));
  }

  findAllPageable(queryOptions: QueryOptions): Observable<Page<ConciliacaoBancaria>> {
    return this.http.get<Page<ConciliacaoBancaria>>(
      `${WEB_API}/${ConciliacaoBancariaService.RESOURCE}/pageable?${queryOptions.toQueryString()}`,
    );
  }

  associar(data: {}): Observable<any> {
    return this.http.post<any>(`${WEB_API}/${ConciliacaoBancariaService.RESOURCE}/associar`, data);
  }

  getValorTotal(queryOptions: QueryOptions): Observable<any> {
    return this.http.get<number>(`${WEB_API}/${ConciliacaoBancariaService.RESOURCE}/get-valor-total?${queryOptions.toQueryString()}`);
  }

  getDashboard(queryOptions: QueryOptions): Observable<DashboardConciliacaoDTO> {
    return this.http.get<DashboardConciliacaoDTO>(`${WEB_API}/${ConciliacaoBancariaService.RESOURCE}/get-dashboard-conciliacao?${queryOptions.toQueryString()}`);
  }
}
