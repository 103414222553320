import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { ArquivoAnaliseDeRisco } from '../domain/arquivo-analise-de-risco.model';
import { Produto } from '../domain/produto.model';
import { Page, ReturnAPI } from '../domain/return-api.model';
import { ArquivoAnaliseDeRiscoSerializer } from '../serializables/arquivo-analise-de-risco.serializer';

@Injectable()
export class ArquivoAnaliseDeRiscoService extends ResourceService<ArquivoAnaliseDeRisco> {
  static RESOURCE = 'arquivo-analise-risco';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${ArquivoAnaliseDeRiscoService.RESOURCE}`, new ArquivoAnaliseDeRiscoSerializer(ArquivoAnaliseDeRisco));
  }

  save(arquivoAnalise: ArquivoAnaliseDeRisco): Observable<ReturnAPI<ArquivoAnaliseDeRisco>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<ArquivoAnaliseDeRisco>>(
      `${WEB_API}/${ArquivoAnaliseDeRiscoService.RESOURCE}/save?${this.produtoId}`,
      arquivoAnalise,
    );
  }

  findOne(id: number): Observable<ReturnAPI<ArquivoAnaliseDeRisco>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<ArquivoAnaliseDeRisco>>(
      `${WEB_API}/${ArquivoAnaliseDeRiscoService.RESOURCE}/findOne?id=${id}&${this.produtoId}`,
    );
  }

  findAll(queryOptions: QueryOptions): Observable<ReturnAPI<Page<ArquivoAnaliseDeRisco>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<ArquivoAnaliseDeRisco>>>(
      `${WEB_API}/${ArquivoAnaliseDeRiscoService.RESOURCE}?${queryOptions.toQueryString()}&${this.produtoId}`,
    );
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }
}
