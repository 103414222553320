import { ReturnAPI } from './../domain/return-api.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { ArquivoComprovantePix } from '../domain/arquivo-comprovante-pix.model';
import { Produto } from '../domain/produto.model';
import { ArquivoComprovantePixSerializer } from '../serializables/arquivo-comprovante-pix.serializer';
import { ParcelaComprovantePixDTO } from '../domain/dto/parcela-comprovante-pix.dto';
import { Page } from '../domain/return-api.model';

@Injectable()
export class ArquivoComprovantePixService extends ResourceService<ArquivoComprovantePix> {
  static RESOURCE = 'arquivocomprovantepix';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, 'arquivocomprovantepix', new ArquivoComprovantePixSerializer(ArquivoComprovantePix));
  }

  findByCodigoInContext(transferenciaId: number, dataCriacao: Date): Observable<Blob> {
    this.getProduto();
    return this.http.get(
      `${WEB_API}/${ArquivoComprovantePixService.RESOURCE}/storage/${transferenciaId}?${this.produtoId}&data=${dataCriacao}`,
      {
        responseType: 'blob',
      },
    );
  }

  findDaoPageable(options: QueryOptions): Observable<ReturnAPI<Page<ParcelaComprovantePixDTO>>> {
    this.getProduto();
    return this.http.get<ReturnAPI<Page<ParcelaComprovantePixDTO>>>(
      `${WEB_API}/${ArquivoComprovantePixService.RESOURCE}/find-dao-pageable?${options.toQueryString(true)}&${this.produtoId}`,
    );
  }

  downloadAll(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.http.get(
      `${WEB_API}/${ArquivoComprovantePixService.RESOURCE}/download-all?${options.toQueryString(true)}&${this.produtoId}`,
      {
        responseType: 'blob',
      },
    );
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }
}
