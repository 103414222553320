export enum CategoriaDespesa {
  ALIMENTACAO = 'ALIMENTACAO',
  COMBUSTIVEL = 'COMBUSTIVEL',
  BORRACHARIA = 'BORRACHARIA',
  IMPRESSAO_PAPELARIA = 'IMPRESSAO_PAPELARIA',
  VALE = 'VALE',
  OFICINA = 'OFICINA',
  SANGRIA_ESTORNO = 'SANGRIA_ESTORNO',
  JUROS_SOBRA = 'JUROS_SOBRA',
  OUTRO = 'OUTRO',
  ROUBO = 'ROUBO',
}
