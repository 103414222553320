import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalModule } from 'src/app/global/global.module';
import { PlanejamentoClienteMainComponent } from './planejamento-cliente-main/planejamento-cliente-main.component';

@NgModule({
  declarations: [PlanejamentoClienteMainComponent],
  imports: [CommonModule, GlobalModule],
})
export class PlanejamentoClienteModule {}
