import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from 'src/app/App.state';
import { DashboardDespesa } from 'src/app/base/domain/dashboard-despesa.model';
import { DashBoardEstadoFaturamento } from 'src/app/base/domain/dashboard-estado-faturamento.model';
import { DashBoardDespesaService } from 'src/app/base/services/dashboard-despesa.service';
import { DashboardCobrancaFilterOptions } from 'src/app/global/cobranca-dashboard/cobranca-dashboard.component';
import { QueryOptions } from 'src/app/global/domain/query.options';

@Component({
    selector: 'app-acompanhamento-despesa-main',
    templateUrl: './acompanhamento-despesa-main.component.html',
    styleUrls: ['./acompanhamento-despesa-main.component.css'],
})
export class AcompanhamentoDespesaMainComponent implements OnInit {
    dashboard: DashboardDespesa = new DashboardDespesa();

    data = new Date();
    dashboardEstadoList: DashBoardEstadoFaturamento[] = [];

    queryOptions = new QueryOptions({ pageSize: 10 });

    constructor(private service: DashBoardDespesaService, private router: Router, public appState: AppState) { }

    ngOnInit(): void {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        this.data = date;
        this.queryOptions.params = {
            dataInicial: date.getTime(),
            dataFinal: date.getTime(),
        };
        this.getDashboardWithoutRefresh();
    }

    generateMask(value: number): string {
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    onReload(): void {
        this.getDashboard();
    }

    getDashboard(): void {
        this.data.setHours(0, 0, 0, 0);
        this.appState.isLoading = true;
        this.service.getDashboard(this.queryOptions).subscribe((response: DashboardDespesa) => {
            this.getDashboardEstado();
            this.dashboard = response;
        });
    }

    getDashboardWithoutRefresh(): void {
        this.data.setHours(0, 0, 0, 0);
        this.appState.isLoading = true;
        this.service.getDashboardWithoutRefresh(this.queryOptions).subscribe((response: DashboardDespesa) => {
            this.getDashboardEstado();
            this.dashboard = response;
        });
    }

    getDashboardEstado(): void {
        this.service.getDashboardEstadoFaturamento().subscribe((response: DashBoardEstadoFaturamento[]) => {
            this.dashboardEstadoList = response;
            this.appState.isLoading = false;
        });
    }

    onDisclosure(dashboard: DashBoardEstadoFaturamento): void {
        this.queryOptions.params = {
            ...this.queryOptions.params,
            nomeEstado: dashboard.nomeEstado ? dashboard.nomeEstado : null,
        };

        this.router.navigate(['base/gestao/acompanhamento-estado-despesas/' + dashboard.estadoId], {
            queryParams: this.queryOptions.params,
        });
    }

    onFilter(options: DashboardCobrancaFilterOptions): void {
        this.queryOptions.params = {
            postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
            estadoId: options.estadoSelecionado ? options.estadoSelecionado.id : null,
            dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
            dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
        };

        this.getDashboardWithoutRefresh();
    }
}
