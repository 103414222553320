import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AppState } from "src/app/App.state";
import { SharedService } from "src/app/auth/shared.service";
import { ArquivoImportacaoNovo } from "src/app/base/domain/arquivo-importacao-novo.model";
import { ValoresArquivoNovoRequestDTO } from "src/app/base/domain/dto/valores-arquivo-novo-request-dto";
import { Page, ReturnAPI } from "src/app/base/domain/return-api.model";
import { Usuario } from "src/app/base/domain/usuario.model";
import { ArquivoImportacaoNovoService } from "src/app/base/services/arquivo-importacao-novo.service";
import { ContratoService } from "src/app/base/services/contrato.service";
import { QueryOptions } from "src/app/global/domain/query.options";
import { ArquivoImportacaoAcaoEvent, ArquivoImportacaoNovoListFilterOptions } from "src/app/global/lists/arquivo-importacao-novo-list/arquivo-importacao-novo-list.component";
import { DialogService } from "src/app/global/services/dialog.service";
import * as FileSaver from 'file-saver';
import { Contrato } from "src/app/base/domain/contrato.model";
import { RelatorioContabilidadeDTO } from "src/app/base/domain/dto/relatorio-contabilidade-dto";
import { ContratoListFilterOptions } from "src/app/global/lists/contrato-list/contrato-list.component";

@Component({
    selector: 'app-contas-encerradas-arquivo-detail',
    templateUrl: './contas-encerradas-arquivo-detail.component.html',
    styleUrls: ['./contas-encerradas-arquivo-detail.component.css'],
})
export class ContasEncerradasArquivosDetailComponent implements OnInit {

    arquivoID: number;

    contratos: Contrato[] = [];
    menuAcoes: String[] = [];
    selectAllContrato: boolean = false;

    shared: SharedService;
    usuario: Usuario;
    dataFinal: number;
    dataInicial: number;

    queryOptions = new QueryOptions({
        pageSize: 25,
        params: {
            situacaoContrato: 'in:FINALIZADO,FINALIZADO_COM_ANTECIPACAO,FINALIZADO_COM_ATRASO',
            exportadoFinalizado: 'eq: true',
        }
    });
    totalRecords = 0;
    loading = false;

    private postoSelecionado: number = null;

    constructor(
        private router: Router,
        private contratoService: ContratoService,
        private dialogService: DialogService,
        public dialog: MatDialog,
        public appState: AppState,
        private route: ActivatedRoute,
    ) {
        const shared = SharedService.getInstance();
        this.usuario = shared.usuario;
        this.route.params.subscribe((params: Params) => {
            this.arquivoID = params.id;
        });
    }

    ngOnInit(): void {
        this.queryOptions.params = {
            arquivoFinalizado: this.arquivoID,
        };
        this.listarContratos();
    }

    listarContratos(pageNumber: number = 0): void {
        this.queryOptions.pageNumber = pageNumber + 1;
        this.loading = true;
        this.contratoService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Contrato>>) => {
            if (response.success) {
                this.totalRecords = response.object.totalElements;
                this.contratos = response.object.content;
            } else {
                this.contratos = [];
                this.dialogService.feedbackReturnAPI(response, '');
            }
            this.loading = false;
        });

    }

    onFilter(options: ContratoListFilterOptions): void {
        const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
        this.queryOptions.params = {
            postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
            dataInicialLiberacao: options.dataInicial ? options.dataInicial.getTime() : null,
            dataFinalLiberacao: options.dataFinal ? options.dataFinal.getTime() : null,
            nomeCliente: options.nomeCliente ? options.nomeCliente : null,
            situacaoContrato: options.situacoesContrato?.length ? `in:${options.situacoesContrato.join(',')}` : null,
            arquivoFinalizado: this.arquivoID,
        };

        this.dataFinal = options.dataFinal ? options.dataFinal.getTime() : null;
        this.dataInicial = options.dataInicial ? options.dataInicial.getTime() : null;

        if (postoId !== this.postoSelecionado) {
            this.postoSelecionado = postoId;
        }

        this.listarContratos(0);
    }

    onContratosPage(page: number): void {
        this.listarContratos(page);
    }

    generateMask(value: number): string {
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    onSelectAll(value: boolean): void {
        this.selectAllContrato = value;
    }

    onDisclosure(contrato: Contrato): void {
        this.router.navigate(['base/contratos/acompanhamento-contrato-detail/' + contrato.id]);
    }

}