import { RadarChartTemplate } from './../../../../../global/components/graficos/radar-chart/radar-chart.component';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashBoardCobrancaService } from 'src/app/base/services/dashboard-cobranca.service';
import { AppState } from 'src/app/App.state';
import { DialogService } from 'src/app/global/services/dialog.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { AcompanhamentoCobrancasTurnoDTO } from 'src/app/base/domain/dto/acompanhamento-cobrancas-turno.dto';
import { ClienteService } from 'src/app/base/services/cliente.service';
import { FuncionarioService } from 'src/app/base/services/funcionario.service';
import { AcompanhamentoCobrancaTurnoCaixaDTO } from 'src/app/base/domain/dto/acompanhamento-cobrancas-turno-caixa.dto';
import { CobrancaCaixaFuncionarioDTO } from 'src/app/base/domain/dto/cobranca-caixa-funcionario.dto';
import { AcompanhamentoCobrancasTurno } from 'src/app/base/domain/acompanhamento-cobrancas-turno.model';

@Component({
  selector: 'app-acompanhamento-cobranca-turno-rota',
  templateUrl: './acompanhamento-cobranca-turno-rota.component.html',
  styleUrls: ['./acompanhamento-cobranca-turno-rota.component.css']
})
export class AcompanhamentoCobrancaTurnoRotaComponent implements OnInit {

  filterOptions = { dataInicial: new Date(), dataFinal: new Date() };

  estadoId: number;
  estado: string;
  postoId: number;
  posto: string;
  rota: string;
  funcionario: string;

  rotaData: AcompanhamentoCobrancasTurnoDTO;
  template: RadarChartTemplate;
  templateCobrancas: RadarChartTemplate = {
    canvas: 'radar',
    elementIndex: 0,
    dataLabels: ['Manhã', 'Tarde', 'Noite'],
    heigth: 80,
    maxHeigth: '250px',
    label: '',
    data: [
      {
        label: 'Qtd. Cobranças',
        data: [
          null,
          null,
          null
        ],
        fill: true,
        index: null,
        backgroundColor: 'rgba(245, 220, 50, 0.3)',
        borderColor: 'rgba(245, 220, 50, 0.7)',
        pointBackgroundColor: 'rgba(245, 220, 50, 1)',
        pointBorderColor: 'rgba(245, 220, 50, 1)',
        pointHoverBackgroundColor: 'rgba(245, 220, 50, 0)',
        pointHoverBorderColor: 'rgba(245, 220, 50, 0.5)'
      }
    ]
  };

  caixas: AcompanhamentoCobrancaTurnoCaixaDTO[] = [];

  clientesManha = 0;
  clientesTarde = 0;
  clientesNoite = 0;
  cobradosManha = 0;
  cobradosTarde = 0;
  cobradosNoite = 0;
  total = 0;

  cobrancasManha = 0;
  cobrancasTarde = 0;
  cobrancasNoite = 0;
  totalCobrancas = 0;

  constructor(
    route: ActivatedRoute,
    private service: DashBoardCobrancaService,
    private dialogService: DialogService,
    private appState: AppState) {
    route.params.subscribe((params: { [key: string]: string }) => {
      if ('estadoId' in params) {
        this.estadoId = parseInt(params['estadoId'], null);
      }
      if ('postoId' in params) {
        this.postoId = parseInt(params['postoId'], null);
      }
      if ('rota' in params) {
        this.rota = params['rota'];
      }
    });
    route.queryParams.subscribe((params: { [key: string]: string }) => {
      if ('datas' in params) {
        const datas = params['datas'].split(',');
        this.filterOptions.dataInicial = new Date(parseInt(datas[0], null));
        this.filterOptions.dataFinal = new Date(parseInt(datas[1], null));
      }
    });
  }

  ngOnInit(): void {
    this.loadDashboardRotas();
  }

  loadDashboardRotas(): void {
    this.appState.isLoading = true;
    const options: QueryOptions = new QueryOptions({
      params: {
        estado: this.estadoId,
        posto: this.postoId,
        rota: this.rota,
        datas: `${this.filterOptions.dataInicial.getTime()},${this.filterOptions.dataFinal.getTime()}`
      }
    });
    this.service.getDashBoardTurnoRota(options, this.estadoId, this.postoId, this.rota).subscribe((response: AcompanhamentoCobrancasTurnoDTO) => {
      if (response) {
        this.rotaData = response;
        this.funcionario = response.funcionario;
        this.clientesManha = response.clientesManha;
        this.clientesTarde = response.clientesTarde;
        this.clientesNoite = response.clientesNoite;
        this.cobradosManha = response.cobradosManha;
        this.cobradosTarde = response.cobradosTarde;
        this.cobradosNoite = response.cobradosNoite;
        this.total = response.total;
      }
      this.getEfetivoDashboard();
      this.loadDashboardCobrancas();
    });
  }

  getEfetivoDashboard(): void {
    this.template = {
      canvas: 'radar',
      elementIndex: 0,
      dataLabels: ['Manhã', 'Tarde', 'Noite'],
      heigth: 80,
      maxHeigth: '250px',
      label: '',
      data: [
        {
          label: 'Qtd. Clientes',
          data: [
            this.rotaData.clientesManha,
            this.rotaData.clientesTarde,
            this.rotaData.clientesNoite,
          ],
          fill: true,
          index: null,
          backgroundColor: 'rgba(0, 188, 212, 0.3)',
          borderColor: 'rgba(0, 188, 212, 0.7)',
          pointBackgroundColor: 'rgba(0, 188, 212, 1)',
          pointBorderColor: 'rgba(0, 188, 212, 1)',
          pointHoverBackgroundColor: 'rgba(0, 188, 212, 0)',
          pointHoverBorderColor: 'rgba(0, 188, 212, 0.5)'
        },
        {
          label: 'Qtd. Cobrados',
          data: [
            this.rotaData.cobradosManha,
            this.rotaData.cobradosTarde,
            this.rotaData.cobradosNoite,
          ],
          fill: true,
          index: null,
          backgroundColor: 'rgba(255, 0, 0, 0.3)',
          borderColor: 'rgba(255, 0, 0, 0.7)',
          pointBackgroundColor: 'rgba(255, 0, 0, 1)',
          pointBorderColor: 'rgba(255, 0, 0, 1)',
          pointHoverBackgroundColor: 'rgba(255, 0, 0, 0)',
          pointHoverBorderColor: 'rgba(255, 0, 0, 0.5)'
        }
      ]
    };
  }

  loadDashboardCobrancas(): void {
    const options: QueryOptions = new QueryOptions({
      params: {
        estado: this.estadoId,
        posto: this.postoId,
        rota: this.rota,
        datas: `${this.filterOptions.dataInicial.getTime()},${this.filterOptions.dataFinal.getTime()}`
      }
    });
    this.service.getCobrancasTurno(options).subscribe((response: AcompanhamentoCobrancaTurnoCaixaDTO[]) => {
      this.caixas = response;
      this.caixas.sort((a: AcompanhamentoCobrancaTurnoCaixaDTO, b: AcompanhamentoCobrancaTurnoCaixaDTO) => {
        if (a.dataAbertura > b.dataAbertura) {
          return 1;
        } else {
          return -1;
        }
      });
      this.configureCobrancas();
    });
  }

  configureCobrancas(): void {
    this.cobrancasManha = this.caixas.map((o: AcompanhamentoCobrancaTurnoCaixaDTO) => o.cobrancasManha).reduce((p: number, c: number) => p + c);
    this.cobrancasTarde = this.caixas.map((o: AcompanhamentoCobrancaTurnoCaixaDTO) => o.cobrancasTarde).reduce((p: number, c: number) => p + c);
    this.cobrancasNoite = this.caixas.map((o: AcompanhamentoCobrancaTurnoCaixaDTO) => o.cobrancasNoite).reduce((p: number, c: number) => p + c);
    this.totalCobrancas = this.caixas.map((o: AcompanhamentoCobrancaTurnoCaixaDTO) => o.total).reduce((p: number, c: number) => p + c);

    this.templateCobrancas = {
      canvas: 'radar',
      elementIndex: 0,
      dataLabels: ['Manhã', 'Tarde', 'Noite'],
      heigth: 80,
      maxHeigth: '250px',
      label: '',
      data: [
        {
          label: 'Qtd. Cobranças',
          data: [
            this.cobrancasManha,
            this.cobrancasTarde,
            this.cobrancasNoite
          ],
          fill: true,
          index: null,
          backgroundColor: 'rgba(245, 220, 50, 0.3)',
          borderColor: 'rgba(245, 220, 50, 0.7)',
          pointBackgroundColor: 'rgba(245, 220, 50, 1)',
          pointBorderColor: 'rgba(245, 220, 50, 1)',
          pointHoverBackgroundColor: 'rgba(245, 220, 50, 0)',
          pointHoverBorderColor: 'rgba(245, 220, 50, 0.5)'
        }
      ]
    };

    this.caixas.forEach((ca: AcompanhamentoCobrancaTurnoCaixaDTO) => {
      const comparativo = this.rotaData.data;
      const caixa = ca.cobrancas;
      caixa.forEach((cob: CobrancaCaixaFuncionarioDTO) => {
        if (comparativo.some((o: AcompanhamentoCobrancasTurno) => o.parcela === cob.parcela)) {
          const ref = comparativo.find((o: AcompanhamentoCobrancasTurno) => o.parcela === cob.parcela);
          cob.turnoCobrado = ref.horaRecebimentoString.length ? ref.horaRecebimentoString : '';
          if (ref.assiduo) {
            cob.correto = true;
          }
        }
      });
    });
    this.appState.isLoading = false;
  }

  onAplicarFiltro(): void {
    this.loadDashboardRotas();
  }

  onLimparFiltros(): void {
    this.filterOptions.dataInicial = new Date();
    this.filterOptions.dataFinal = new Date();
    this.onAplicarFiltro();
  }

  onAtualizar(): void {
    this.service.refreshViewCobrancasTurno().subscribe((response: boolean) => {
      if (response) {
        this.onAplicarFiltro();
      } else {
        this.dialogService.feedbackError(
          'Ocorreu um erro ao atualizar dados'
          + ' Tente novamente mais tarde!'
        );
      }
    });
  }

  hourMask(ref: string): string {
    const refFront = parseInt(ref, null) + 1 < 24 ? parseInt(ref, null) + 1 : 0;
    if (ref) {
      return (ref.length === 1 ? `0${ref}:00` : `${ref}:00`) + ' ~ '
        + (refFront.toString().length === 1 ? `0${refFront}:00` : `${refFront}:00`);
    } else {
      return 'N/C';
    }
  }

  dateMask(date: number): string {
    return new Date(date).toLocaleDateString();
  }
}
