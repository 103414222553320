import { ContaTev } from '../conta-tev.model';
import { TipoConciliacaoBancaria } from '../enum/tipo-conciliacao-bancaria.enum';
import { ConciliacaoBancariaDoc } from '../conciliacao-bancaria-doc.model';
import { Resource } from '../../../global/domain/resource.model';
import { ConciliacaoBancariaTev } from '../conciliacao-bancaria-tev.model';
import { ConciliacaoBancariaDinheiro } from '../conciliacao-bancaria-dinheiro.model';
import { ConciliacaoBancariaTed } from '../conciliacao-bancaria-ted.model';
export class ConciliacaoDTO extends Resource {
  data: Date;
  name: string;
  identificador: string;
  tipoConciliacaoBancaria: TipoConciliacaoBancaria;
  banco: number;
  valor: number;
  agencia: string;
  conta: ContaTev;
  processado: boolean;
  valorPago: number;
  responsavel: string;
  contaOrigem: string;
  contaDestino: string;

  public static toConciliacaoDoc(dto: ConciliacaoDTO): ConciliacaoBancariaDoc {
    let conciliacao: ConciliacaoBancariaDoc = {
      id: dto.id,
      version: dto.version,
      ativo: dto.ativo,
      selecionado: dto.selecionado,
      acoes: dto.acoes,
      data: dto.data,
      name: dto.name,
      identificador: dto.identificador,
      tipoConciliacaoBancaria: dto.tipoConciliacaoBancaria,
      banco: dto.banco,
      valor: dto.valor,
      conta: null,
      agencia: dto.agencia,
      processado: dto.processado,
      valorPago: dto.valorPago,
    };

    return conciliacao;
  }

  public static toConciliacaoTev(dto: ConciliacaoDTO): ConciliacaoBancariaTev {
    let conciliacao: ConciliacaoBancariaTev = {
      id: dto.id,
      version: dto.version,
      ativo: dto.ativo,
      selecionado: dto.selecionado,
      acoes: dto.acoes,
      data: dto.data,
      name: dto.name,
      identificador: dto.identificador,
      tipoConciliacaoBancaria: dto.tipoConciliacaoBancaria,
      banco: dto.banco,
      valor: dto.valor,
      conta: null,
      processado: dto.processado,
      valorPago: dto.valorPago,
    };

    return conciliacao;
  }

  public static toConciliacaoTed(dto: ConciliacaoDTO): ConciliacaoBancariaTed {
    let conciliacao: ConciliacaoBancariaTed = {
      id: dto.id,
      version: dto.version,
      ativo: dto.ativo,
      selecionado: dto.selecionado,
      acoes: dto.acoes,
      data: dto.data,
      name: dto.name,
      identificador: dto.identificador,
      tipoConciliacaoBancaria: dto.tipoConciliacaoBancaria,
      banco: dto.banco,
      valor: dto.valor,
      contaDestino: dto.contaDestino,
      contaOrigem: dto.contaOrigem,
      processado: dto.processado,
      valorPago: dto.valorPago,
    };

    return conciliacao;
  }

  public static toConciliacaoDinheiro(dto: ConciliacaoDTO): ConciliacaoBancariaDinheiro {
    let conciliacao: ConciliacaoBancariaDinheiro = {
      id: dto.id,
      version: dto.version,
      ativo: dto.ativo,
      selecionado: dto.selecionado,
      acoes: dto.acoes,
      data: dto.data,
      name: dto.name,
      identificador: dto.identificador,
      tipoConciliacaoBancaria: dto.tipoConciliacaoBancaria,
      banco: dto.banco,
      valor: dto.valor,
      processado: dto.processado,
      valorPago: dto.valorPago,
      responsavel: dto.responsavel,
    };

    return conciliacao;
  }
}
