import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { GlobalModule } from "src/app/global/global.module";
import { ContasEncerradasArquivosDetailComponent } from "./contas-encerradas/contas-encerradas-arquivo-detail/contas-encerradas-arquivo-detail.component";
import { ContasEncerradasArquivosComponent } from "./contas-encerradas/contas-encerradas-arquivo/contas-encerradas-arquivo.component";
import { ContasEncerradasContratosComponent } from "./contas-encerradas/contas-encerradas-contratos/contas-encerradas-contratos.component";
import { TitulosNovosArquivosDetailComponent } from "./titulos-novos/titulos-novos-arquivo-detail/titulos-novos-arquivo-detail.component";
import { TitulosNovosArquivosComponent } from "./titulos-novos/titulos-novos-arquivo/titulos-novos-arquivo.component";
import { TitulosNovosContratosComponent } from "./titulos-novos/titulos-novos-contratos/titulos-novos-contratos.component";

@NgModule({
    declarations: [TitulosNovosContratosComponent, TitulosNovosArquivosComponent, ContasEncerradasContratosComponent, TitulosNovosArquivosDetailComponent, ContasEncerradasArquivosComponent, ContasEncerradasArquivosDetailComponent],
    imports: [CommonModule, GlobalModule]
})
export class IntegracaoModule { }