import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CobrancaJudicialMainComponent } from './cobranca-judicial-main/cobranca-judicial-main.component';
import { GlobalModule } from 'src/app/global/global.module';
import { CobrancaJudicialDetailComponent } from './cobranca-judicial-detail/cobranca-judicial-detail.component';



@NgModule({
  declarations: [CobrancaJudicialMainComponent, CobrancaJudicialDetailComponent],
  imports: [
    CommonModule, GlobalModule
  ]
})
export class CobrancaJudicialModule { }
