import { Component, OnInit, Inject } from '@angular/core';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Estado } from 'src/app/base/domain/estado.model';
import { QueryOptions } from '../../domain/query.options';
import { PlanejamentoSemanaCaptacao } from 'src/app/base/domain/planejamento-semana-captacao.model';
import { UserService } from 'src/app/base/services/user.service';
import { Moment } from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Posto } from 'src/app/base/domain/posto.model';
import { PostoService } from 'src/app/base/services/posto.service';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';

export interface PlanejamentoSemanaCaptacaoFormData {
  planejamento: PlanejamentoSemanaCaptacao;
  action: string;
}

@Component({
  selector: 'app-planejamento-semana-captacao-form',
  templateUrl: './planejamento-semana-captacao-form.component.html',
  styleUrls: ['./planejamento-semana-captacao-form.component.css'],
})
export class PlanejamentoSemanaCaptacaoFormComponent implements OnInit {
  planejamento = new PlanejamentoSemanaCaptacao();
  title = '';
  estados: Estado[] = [];
  queryOptions = new QueryOptions();
  usuarios: Usuario[] = [];
  postos: Posto[];
  filteredPostos: Posto[] = [];
  posto: Posto;

  constructor(
    public dialogRef: MatDialogRef<PlanejamentoSemanaCaptacaoFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PlanejamentoSemanaCaptacaoFormData,
    private postoService: PostoService,
    private userService: UserService,
  ) {
    this.userService.findByProfile().subscribe((response: Usuario[]) => {
      this.usuarios = response;
      if (data.action === 'update') {
        this.planejamento.usuario = this.usuarios.filter((usuario: Usuario) => usuario.id === this.planejamento.usuario.id)[0];
      }
    });

    this.postoService.findAll(this.queryOptionsPosto).subscribe((response: ReturnAPI<Page<Posto>>) => {
      if (response.success) {
        this.postos = response.object.content;
        this.filteredPostos = this.postos;
        if (data.action === 'update') {
          this.title = 'Editar';
          this.planejamento.posto = this.postos.filter((posto: Posto) => posto.id === this.planejamento.idPosto)[0];
          this.planejamento.idPosto = this.planejamento.posto.id;
          this.planejamento.nomePosto = this.planejamento.posto.descricao;
        }
      }
    });

    if (data.action === 'update') {
      this.title = 'Editar';
      this.planejamento = data.planejamento;
      this.planejamento.dataInicial = new Date(this.planejamento.dataInicial);
    } else {
      this.title = 'Novo';
    }
  }

  ngOnInit(): void {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.planejamento.idPosto = this.planejamento.posto ? this.planejamento.posto.id : null;
    this.planejamento.nomePosto = this.planejamento.posto ? this.planejamento.posto.descricao : null;
    this.planejamento.dataCadastro = new Date();
    this.dialogRef.close(this.planejamento);
  }

  onFinalPickerEvent(event: MatDatepickerInputEvent<Moment>): void {
    this.planejamento.dataFinal = event.value?.toDate() || null;
  }

  onInicialPickerEvent(event: MatDatepickerInputEvent<Moment>): void {
    this.planejamento.dataInicial = event.value?.toDate() || null;
  }

  get queryOptionsPosto(): QueryOptions {
    return new QueryOptions({
      pageNumber: 1,
    });
  }
}
