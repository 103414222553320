import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Caixa } from 'src/app/base/domain/caixa.model';
import { PublicService } from 'src/app/base/services/public.service';

export interface CaixaFormData {
  caixa: Caixa;
  toEdit: boolean;
}

@Component({
  selector: 'app-conferir-caixa-form',
  templateUrl: './conferir-caixa-form.component.html',
  styleUrls: ['./conferir-caixa-form.component.css'],
})
export class ConferirCaixaFormComponent implements OnInit, OnChanges {
  @Input() caixa: Caixa;

  private mutableCaixa: Partial<Caixa>;

  constructor(
    public dialogRef: MatDialogRef<ConferirCaixaFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CaixaFormData,
    private publicService: PublicService,
  ) {
    this.caixa = data.caixa;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(Object.assign(this.caixa));
  }
}
