import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-importar-arquivo-conciliacao-bancaria-form',
  templateUrl: './importar-arquivo-conciliacao-bancaria-form.component.html',
  styleUrls: ['./importar-arquivo-conciliacao-bancaria-form.component.css'],
})
export class ImportarArquivoConciliacaoBancariaFormComponent implements OnInit {
  documento: File;
  nomeDocumento = '';
  banco: number;


  constructor(public dialogRef: MatDialogRef<ImportarArquivoConciliacaoBancariaFormComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void { }

  onCancel(): void {
    this.dialogRef.close();
  }
  onConfirm(): void {
    console.log(this.banco)
    this.dialogRef.close({
      'banco': this.banco,
      'documento': this.documento

    });
  }

  private handleFileInput(files: FileList): void {
    this.documento = files.item(0);
    this.nomeDocumento = this.documento.name;
  }

  getBanco(banco: number) {
    console.log(banco);
    this.banco = banco;
  }
}
