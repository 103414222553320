import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DebitosFuncionarioMainComponent } from './debitos-funcionario-main/debitos-funcionario-main.component';
import { DebitosFuncionarioDetailComponent } from './debitos-funcionario-detail/debitos-funcionario-detail.component';
import { GlobalModule } from 'src/app/global/global.module';

@NgModule({
  declarations: [DebitosFuncionarioMainComponent, DebitosFuncionarioDetailComponent],
  imports: [CommonModule, GlobalModule],
})
export class DebitosFuncionarioModule {}
