import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppState } from 'src/app/App.state';
import { AreaAtuacao } from 'src/app/base/domain/area-atuacao.model';
import { TransferirRotaDTO } from 'src/app/base/domain/dto/transferir-rota.dto';
import { Equipe } from 'src/app/base/domain/equipe.model';
import { Posto } from 'src/app/base/domain/posto.model';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { Rota } from 'src/app/base/domain/rota.model';
import { AreaAtuacaoSerializer } from 'src/app/base/serializables/area-atuacao.serializer';
import { AreaAtuacaoService } from 'src/app/base/services/area-atuacao.service';
import { EquipeService } from 'src/app/base/services/equipe.service';
import { PostoService } from 'src/app/base/services/posto.service';
import { RotaService } from 'src/app/base/services/rota.service';
import { QueryOptions } from '../../domain/query.options';
import { DialogService } from '../../services/dialog.service';

interface DataType {
  equipe: Equipe;
}

@Component({
  selector: 'app-transferencia-rota-form',
  templateUrl: './transferencia-rota-form.component.html',
  styleUrls: ['./transferencia-rota-form.component.css'],
})
export class TransferenciaRotaFormComponent implements OnInit {
  descricaoPostoDestino: string;
  listPostoDesc: string[] = [];
  transferirRotaDTO: TransferirRotaDTO = new TransferirRotaDTO();

  equipesDestino: Equipe[];
  listEquipeDestinoDesc: string[] = [];
  descricaoEquipeDestino: string;

  postos: Posto[];

  rotas: Rota[];
  rotasSelecionadas: Rota[];

  areas: AreaAtuacao[];
  listAreaDestinoDesc: string[] = [];
  descricaoAreaDestino: string;
  areaAtuacaoSelecionada: AreaAtuacao;

  queryOptions = new QueryOptions({ pageNumber: 1 });

  constructor(
    public dialogRef: MatDialogRef<TransferenciaRotaFormComponent>,
    private postoService: PostoService,
    private equipeService: EquipeService,
    private dialogService: DialogService,
    private areaAtuacaoService: AreaAtuacaoService,
    @Inject(MAT_DIALOG_DATA) public data: DataType,
    public appState: AppState,
  ) {
    this.transferirRotaDTO.postoOrigem = data.equipe.posto;
    this.transferirRotaDTO.equipeOrigem = data.equipe;
    this.transferirRotaDTO.migracaoCompleta = false;
    this.rotas = data.equipe.rotas;

    this.postoService.listarPostos().subscribe((response: ReturnAPI<Posto[]>) => {
      if (response.success) {
        this.postos = response.object;
        this.postos = this.postos.filter((posto) => posto.id !== data.equipe.posto.id);
        this.postos.forEach((posto: Posto) => {
          this.listPostoDesc.push(posto.descricao);
        });
      }
    });
  }

  ngOnInit(): void {}

  confirmar(): void {
    this.appState.isLoading = true;
    this.transferirRotaDTO.rotas.forEach((rota: Rota) => {
      rota.listAreaAtuacao = [];
      rota.listAreaAtuacao.push(this.areaAtuacaoSelecionada);
    });

    this.postoService.transferirRota(this.transferirRotaDTO).subscribe(
      (response: ReturnAPI<void>) => {
        this.appState.isLoading = false;
        this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
        this.dialogRef.close();
      },
      (e: HttpErrorResponse) => {
        this.appState.isLoading = false;
        this.dialogService.feedbackError(`Houve um erro ao transferir rotas.\n ${e.message}`);
      },
    );
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  onPostoDestinoSelected(value: string): void {
    const postoDestinoSelecionado = this.postos.find((posto: Posto) => posto.descricao === value);
    this.transferirRotaDTO.postoDestino = postoDestinoSelecionado;

    this.queryOptions.params = {
      postoId: postoDestinoSelecionado.id,
    };

    this.equipeService.buscarPorPosto(postoDestinoSelecionado.id).subscribe((response: ReturnAPI<Equipe[]>) => {
      this.equipesDestino = response.object;
      this.listEquipeDestinoDesc = this.equipesDestino.map((equipe: Equipe) => equipe.descricao);
    });

    this.areaAtuacaoService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<AreaAtuacao>>) => {
      this.areas = response.object.content;
      this.listAreaDestinoDesc = this.areas.map((areaAt: AreaAtuacao) => areaAt.nome);
    });
  }

  onEquipeDestinoSelected(value: string): void {
    const equipeSelecionado = this.equipesDestino.find((equipe: Equipe) => equipe.descricao === value);
    this.transferirRotaDTO.equipeDestino = equipeSelecionado;
  }

  onAreaDestinoSelected(value: string): void {
    const areaSelecionado = this.areas.find((area: AreaAtuacao) => area.nome === value);
    this.areaAtuacaoSelecionada = areaSelecionado;
  }
}
