import { Component, OnInit } from '@angular/core';
import { Caixa } from '../../../domain/caixa.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/global/services/dialog.service';
import { CaixaService } from '../../../services/caixa.service';
import { ReturnAPI, Page } from '../../../domain/return-api.model';
import { CaixaAcaoEvent, CaixaListFilterOptions } from 'src/app/global/lists/caixa-list/caixa-list.component';
import { AppState } from 'src/app/App.state';
import { ValoresCaixaRequestDTO } from 'src/app/base/domain/dto/valores-caixa-request.dto';
import { TipoCaixa } from 'src/app/base/domain/enum/tipo-caixa.enum';

@Component({
  selector: 'app-caixa-funcionario-main',
  templateUrl: './caixa-funcionario-main.component.html',
  styleUrls: ['./caixa-funcionario-main.component.css'],
})
export class CaixaFuncionarioMainComponent implements OnInit {

  private static DISCLOSURE = 'disclosure';

  caixas: Caixa[] = [];
  queryOptions = new QueryOptions({ pageSize: 10 });
  totalRecords = 0;
  type = 'cfu';
  totalizador: number;

  private postoSelecionado: number = null;

  constructor(private router: Router,
              public dialogService: DialogService,
              private caixaService: CaixaService,
              public appState: AppState,
              private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.queryOptions.params = {
      type: `${this.type}`,
      sort: '-dataHoraAbertura',
      ...this.activatedRoute.snapshot.queryParams
    };

  }

  list(pageNumber: number = 0, valores: Partial<ValoresCaixaRequestDTO> = {}): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.appState.isLoading = true;
    this.caixaService.pageAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Caixa>>) => {
      if (response.success) {
        this.caixas = response.object.content;
        this.totalRecords = response.object.totalElements;
        this.mapearAcoes(response.object.content);
      } else {
        this.caixas = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }

      valores.dataInicial = valores.dataInicial ? valores.dataInicial : null;
      valores.dataFinal = valores.dataFinal ? valores.dataFinal : null;

      this.getValorTotalPorTipoCaixa({ ...valores, postoId: this.postoSelecionado, tipo: TipoCaixa.CAIXA_FUNCIONARIO });

      this.appState.isLoading = false;
    });
  }

  mapearAcoes(caixas: Caixa[]): void {
    const mapearAcoes = (caixa: Caixa): Caixa => {
      const acoes = [];
      acoes.push(CaixaFuncionarioMainComponent.DISCLOSURE);
      return { ...caixa, acoes };
    };

    this.caixas = caixas?.map(mapearAcoes) ?? [];
  }

  onAcaoClick(event: CaixaAcaoEvent): void {
    const actions = new Map<string, (caixa: Caixa) => void>().set(CaixaFuncionarioMainComponent.DISCLOSURE, (caixa: Caixa) => {
      this.caixaDetail(caixa);
    });

    actions.get(event.acao)(event.caixa);
  }

  caixaDetail(caixa: Caixa): void {
    this.router.navigate(['base/caixas/funcionario-movimentacoes/' + caixa.id]);
  }

  onLoadCaixaPage(page: number): void {
    this.list(page);
  }

  onFilter(options: CaixaListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    this.queryOptions.params = {
      nomeFuncionario: options.nomeFuncionario ? `${options.nomeFuncionario}` : null,
      situacao: options.situacaoCaixa?.length ? `in:${options.situacaoCaixa.join(',')}` : null,
      postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
      fiscalId: options.fiscalSelecionado ? options.fiscalSelecionado.id : null,
      type: `${this.type}`,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
      sort: '-dataHoraAbertura',
    };

    this.router.navigate(['/base/caixas/funcionarios'], {queryParams: this.queryOptions.params}).then(() => this.list());

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }
  }

  getValorTotalPorTipoCaixa(valoresCaixa: Partial<ValoresCaixaRequestDTO>): void {
    this.caixaService.getValorTotalPorTipoCaixa(valoresCaixa).subscribe((response: ReturnAPI<number>) => {
      this.totalizador = response.object || 0;
    });
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
