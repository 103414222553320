import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-adicionar-promocao',
  templateUrl: './adicionar-promocao.component.html',
  styleUrls: ['./adicionar-promocao.component.css']
})
export class AdicionarPromocaoComponent implements OnInit {

  title = 'Adicionar Promoção';
  quantidadeParcelas = 0;
  
  constructor(public dialogRef: MatDialogRef<AdicionarPromocaoComponent>
    ) {}

  ngOnInit(): void {
  }

  onConfirm(): void {
    this.dialogRef.close(this.quantidadeParcelas);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

}
