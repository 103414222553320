import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuditoriaDespesa } from 'src/app/base/domain/auditoria-despesa.model';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { AuditoriaDespesaService } from 'src/app/base/services/auditoria-despesa.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { AuditoriaDespesaListFilterOptions } from 'src/app/global/lists/auditoria-despesa-list/auditoria-despesa-list.component';
import { DialogService } from 'src/app/global/services/dialog.service';

@Component({
    selector: 'app-auditoria-despesa-main',
    templateUrl: './auditoria-despesa-main.component.html',
    styleUrls: ['./auditoria-despesa-main.component.css'],
})
export class AuditoriaDespesaMainComponent implements OnInit {
    auditorias: AuditoriaDespesa[] = [];
    queryOptions = new QueryOptions({ pageSize: 10 });
    totalRecords = 0;
    loading = false;

    constructor(private router: Router, public dialogService: DialogService, private auditoriaDespesaService: AuditoriaDespesaService) { }

    ngOnInit(): void {
        this.listarAuditorias();
    }

    listarAuditorias(pageNumber: number = 0): void {
        this.queryOptions.pageNumber = pageNumber + 1;
        this.loading = true;
        this.auditoriaDespesaService.findAll(this.queryOptions).subscribe((response: Page<AuditoriaDespesa>) => {
            if (response.content) {
                this.totalRecords = response.totalElements;
                this.auditorias = response.content;
            } else {
                this.auditorias = [];
                this.dialogService.feedbackError('Erro ao listar');
            }
            this.loading = false;
        });
    }

    onAuditoriasPage(page: number): void {
        this.listarAuditorias(page);
    }

    onFilter(options: AuditoriaDespesaListFilterOptions): void {
        this.queryOptions.params = {
            tipoDespesaSelecionado: options.tipoDespesaSelecionado?.length ? `in:${options.tipoDespesaSelecionado.join(',')}` : null,
            postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
            dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
            dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
        };
        this.listarAuditorias();
    }
}
