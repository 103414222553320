import { Component, OnInit } from '@angular/core';
import { Caixa } from 'src/app/base/domain/caixa.model';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { CaixaService } from 'src/app/base/services/caixa.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { CaixaAcaoEvent, CaixaListFilterOptions } from 'src/app/global/lists/caixa-list/caixa-list.component';
import { DialogService } from 'src/app/global/services/dialog.service';

@Component({
  selector: 'app-caixa-cobranca-main',
  templateUrl: './caixa-cobranca-main.component.html',
  styleUrls: ['./caixa-cobranca-main.component.css']
})
export class CaixaCobrancaMainComponent implements OnInit {
  private static ZERAR_SALDO_COBRANCA = 'Zerar Saldo do Caixa';

  private readonly INDICE_MINIMO_PAGINACAO = 1;
  private readonly defaultParams = { type: 'cco', caixaAberto: 'true' };
  queryOptions: QueryOptions = new QueryOptions({ pageNumber: this.INDICE_MINIMO_PAGINACAO, params: Object.assign({}, this.defaultParams) });

  caixas: Caixa[] = [];
  totalRecords = 0;

  constructor(private caixaService: CaixaService, public dialogService: DialogService) { }

  ngOnInit(): void {
    this.list();
  }

  onLoadCaixaPage(page: number): void {
    this.list(page);
  }

  list(page: number = 0): void {
    const options = this.queryOptions;
    options.pageNumber += page;

    this.caixaService.pageAll(this.queryOptions).subscribe(
      (response: ReturnAPI<Page<Caixa>>) => {
        if (response.success) {
          this.caixas = response.object.content;
          this.totalRecords = response.object.totalElements;
          this.mapearAcoes(response.object.content);
        }
      }
    );
  }

  mapearAcoes(caixas: Caixa[]): void {
    const mapearAcoes = (caixa: Caixa): Caixa => {
      const acoes = [];
      acoes.push(CaixaCobrancaMainComponent.ZERAR_SALDO_COBRANCA);
      return { ...caixa, acoes };
    };

    this.caixas = caixas?.map(mapearAcoes) ?? [];
  }

  onAcaoClick(event: CaixaAcaoEvent): void {
    const actions = new Map<string, (caixa: Caixa) => void>()
      .set(CaixaCobrancaMainComponent.ZERAR_SALDO_COBRANCA, (caixa: Caixa) => {
        this.confirmZerarSaldo(caixa);
      });

    actions.get(event.acao)(event.caixa);
  }

  async confirmZerarSaldo(caixa: Caixa): Promise<void> {
    this.dialogService.parecerDialog('Digite o codigo de confirmação',
      (response: string) => {
        if (response) {
          const options = new QueryOptions({ params: { caixaId: caixa.id, key: response } });
          this.caixaService.zerarSaldoCaixaCobranca(options).subscribe(
            (resp: ReturnAPI<Caixa>) => {
              if (resp.success) {
                this.dialogService.feedbackReturnAPI(resp, 'Saldo do Caixa Atualizado com Sucesso!');
                this.list();
              } else {
                this.dialogService.feedbackError('Erro ao alterar Saldo do Caixa Cobrança!');
              }
            },
          );
        }
        return '';
      }
    );
  }

  onFilter(options: CaixaListFilterOptions): void {
    this.queryOptions.params = {
      ...this.queryOptions.params,
      postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
    };

    this.list();
  }

}
