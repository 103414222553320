import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MixedChartComponent } from './mixed-chart/mixed-chart.component';
import { DoughnutChartComponent } from './doughnut-chart/doughnut-chart.component';
import { RadarChartComponent } from './radar-chart/radar-chart.component';

@NgModule({
  declarations: [
    MixedChartComponent,
    DoughnutChartComponent,
    RadarChartComponent
  ],
  exports: [
    MixedChartComponent,
    DoughnutChartComponent,
    RadarChartComponent
  ],
  imports: [
    CommonModule,
  ]
})
export class GraficosModule { }
