import { Resource } from "src/app/global/domain/resource.model";

export class AcompanhamentoVenda extends Resource {
    tipo: string;
    total: number;
    concluido: number;
    percentual: number;
    totalBruto: number;
    totalBrutoEntregue: number;
    totalLiquido: number;
    totalLiquidoEntregue: number;
}