import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PessoaListComponent } from './pessoa-list/pessoa-list.component';
import { PessoaDetailComponent } from './pessoa-detail/pessoa-detail.component';
import { PessoaCreateComponent } from './pessoa-create/pessoa-create.component';
import { ComponentsModule } from 'src/app/global/components/components.module';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        ComponentsModule,
        TableModule,
        PaginatorModule
    ],
    declarations: [
        PessoaListComponent,
        PessoaDetailComponent,
        PessoaCreateComponent
    ],
    exports: [PessoaListComponent]
})
export class PessoaModule {}
