import { Component, OnInit, Inject } from '@angular/core';
import { Funcionario } from 'src/app/base/domain/funcionario.model';
import { Posto } from 'src/app/base/domain/posto.model';
import { QueryOptions } from '../../domain/query.options';
import { Contrato } from 'src/app/base/domain/contrato.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PostoService } from 'src/app/base/services/posto.service';
import { FuncionarioService } from 'src/app/base/services/funcionario.service';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import { Rota } from 'src/app/base/domain/rota.model';
import { Equipe } from 'src/app/base/domain/equipe.model';

export interface ResponsavelDTO {
  idAtualResponsavel: number;
  idNovoResponsavel: number;
}

export interface AlterarResponsavelRotaFormData {
  equipe: Equipe;
  cancelar: boolean;
}

@Component({
  selector: 'app-alterar-responsavel-rota-form',
  templateUrl: './alterar-responsavel-rota-form.component.html',
  styleUrls: ['./alterar-responsavel-rota-form.component.css'],
})
export class AlterarResponsavelRotaFormComponent {
  selectableFilteredOptions: Funcionario[] = [];
  selectableInputOrigem = '';
  selectableInputDestino = '';
  selectableInputPosto = '';

  optionSelectedOrigem?: Funcionario;
  optionSelectedDestino?: Funcionario;
  optionSelectedPosto?: Posto;
  isBloqueadoSaida: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AlterarResponsavelRotaFormData,
    public dialogRef: MatDialogRef<AlterarResponsavelRotaFormComponent>,
    private funcionarioService: FuncionarioService,
  ) {
    this.onSelectPosto(this.data.equipe.posto);
    this.isBloqueadoSaida = this.data.cancelar;
  }

  private responsavelDTO: ResponsavelDTO = {
    idAtualResponsavel: null,
    idNovoResponsavel: null,
  };

  async doFilterOrigem(): Promise<void> {
    this.selectableFilteredOptions = await this._filtrar(this.selectableInputOrigem);
  }

  async doFilterDestino(): Promise<void> {
    this.selectableFilteredOptions = await this._filtrar(this.selectableInputDestino);
  }

  onSelectOrigem(value: Funcionario): void {
    this.optionSelectedOrigem = value;
    const descRota = value.rota ? value.rota.descricao : '';
    this.selectableInputOrigem = descRota + ' ' + value.pessoa.nomePessoa;
  }

  onSelectDestino(value: Funcionario): void {
    this.optionSelectedDestino = value;
    const descRota = value.rota ? value.rota.descricao : '';
    this.selectableInputDestino = descRota + ' ' + value.pessoa.nomePessoa;
  }

  onSelectPosto(value: Posto): void {
    this.optionSelectedPosto = value;
    this.selectableInputPosto = value.descricao;
    const postoId = this.optionSelectedPosto.id;
    const options = new QueryOptions({ pageSize: 50, pageNumber: 1, params: { postoId: postoId }, pageSort: '-id' });
    this.funcionarioService.findAll(options).subscribe((response: ReturnAPI<Page<Funcionario>>) => {
      if (response.success) {
        this.selectableFilteredOptions = response.object.content;
        this.selectableFilteredOptions = this.selectableFilteredOptions.filter(
          (funcionario: Funcionario) => funcionario.bloquearLiberarSaida === this.isBloqueadoSaida,
        );
      }
    });
  }

  onConfirm(): void {
    this.responsavelDTO.idAtualResponsavel = this.optionSelectedOrigem ? this.optionSelectedOrigem.id : null;
    this.responsavelDTO.idNovoResponsavel = this.optionSelectedDestino ? this.optionSelectedDestino.id : null;
    this.dialogRef.close(Object.assign(this.responsavelDTO));
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  private async _filtrar(value: string): Promise<Funcionario[]> {
    return this.consultaFuncionarios(value, this.optionSelectedPosto.id);
  }

  async consultaFuncionarios(name: string, postoId: number): Promise<Funcionario[]> {
    const options = new QueryOptions({ pageSize: 50, pageNumber: 1, params: { nomeFuncionario: name, postoId: postoId }, pageSort: '-id' });

    const response = await this.funcionarioService.findAll(options).toPromise();
    let list = [];
    if (response.success) {
      list = response.object.content.filter((funcionario: Funcionario) => funcionario.bloquearLiberarSaida !== this.isBloqueadoSaida);
    }
    return list;
  }
}
