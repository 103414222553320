import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QueryOptions } from '../../domain/query.options';
import { Transferencia } from 'src/app/base/domain/transferencia.model';
import { LazyLoadEvent } from 'primeng/api';
import { Posto } from 'src/app/base/domain/posto.model';
import { SituacaoTransferencia } from 'src/app/base/domain/enum/situacao-transferencia.enum';
import { PostoService } from 'src/app/base/services/posto.service';
import { ReturnAPI } from 'src/app/base/domain/return-api.model';

export interface TransferenciaAcaoEvent {
  acao: string;
  transferencia: Transferencia;
}
export interface TransferenciaListFilterOptions {
  nome: string;
  banco: string;
  conta: string;
  agencia: string;
  situacoesTransferencia: string[];
  postoSelecionado: Posto;
  dataInicial: Date;
  dataFinal: Date;
  postoDestino: Posto;
  postoOrigem: Posto;
  descricaoRota: string;
}

@Component({
  selector: 'app-transferencia-list',
  templateUrl: './transferencia-list.component.html',
  styleUrls: ['./transferencia-list.component.css'],
})
export class TransferenciaListComponent implements OnInit {
  @Input() tipoTransferencia: string;

  @Input() nomePlaceholder: string;
  @Input() optionalColumns: string[];
  @Input() rowsNumber: number;
  @Input() totalRecords: number;
  @Input() loading: boolean;
  @Input() filtros: string[];
  @Input() transferencias: Transferencia[];
  @Input() situacoes: string[];

  @Output() loadTransferenciasPage = new EventEmitter<number>();
  @Output() acaoClick = new EventEmitter<TransferenciaAcaoEvent>();
  @Output() filter = new EventEmitter<TransferenciaListFilterOptions>();

  private currentPage = 0;
  private readonly defaultFilterOptions: TransferenciaListFilterOptions = {
    nome: '',
    banco: '',
    conta: '',
    agencia: '',
    situacoesTransferencia: [],
    postoSelecionado: null,
    dataInicial: null,
    dataFinal: null,
    postoDestino: null,
    postoOrigem: null,
    descricaoRota: '',
  };

  filterOptions: TransferenciaListFilterOptions;

  queryOptions = new QueryOptions();
  title: string;

  postos: Posto[] = [];

  constructor(private postoService: PostoService) {
    this.onLimparFiltros();
    this.postoService.listarPostos().subscribe((response: ReturnAPI<Posto[]>) => {
      if (response.success) {
        this.postos = response.object;
      }
    });
  }

  ngOnInit(): void {}

  loadTransferencias(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadTransferenciasPage.emit(pageNumber);
  }

  onAcaoClick(acao: string, domain: Transferencia): void {
    this.acaoClick.emit({ acao, transferencia: domain });
  }

  onAtualizar(): void {
    this.loadTransferenciasPage.emit(this.currentPage);
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
