import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcompanhamentoConferenciaCaixaGeralMainComponent } from './acompanhamento-conferencia-caixa-geral-main/acompanhamento-conferencia-caixa-geral-main.component';
import { GlobalModule } from 'src/app/global/global.module';

@NgModule({
  declarations: [AcompanhamentoConferenciaCaixaGeralMainComponent],
  imports: [CommonModule, GlobalModule],
})
export class AcompanhamentoConferenciaCaixaGeralModule {}
