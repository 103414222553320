import { Usuario } from './../../../domain/usuario.model';
import { async } from '@angular/core/testing';
import { AppState } from './../../../../App.state';
import { VisitaService } from './../../../services/visita.service';
import { Component, OnInit } from '@angular/core';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Cliente } from 'src/app/base/domain/cliente.model';
import { DialogService } from 'src/app/global/services/dialog.service';
import { ClienteService } from 'src/app/base/services/cliente.service';
import { MatDialog } from '@angular/material/dialog';
import { ClienteListFilterOptions, ClienteAcaoEvent, ClienteMenuAcaoEvent } from 'src/app/global/lists/cliente-list/cliente-list.component';
import { SharedService } from 'src/app/auth/shared.service';
import { OrigemAcaoDeVendaEnum } from 'src/app/base/domain/enum/origem-acao-de-venda.enum';
import { Captado } from 'src/app/base/domain/captado.model';

@Component({
  selector: 'app-clientes-inativos-main',
  templateUrl: './clientes-inativos-main.component.html',
  styleUrls: ['./clientes-inativos-main.component.css'],
})
export class ClientesInativosMainComponent implements OnInit {
  static NOVA_ACAO_DE_VENDA = 'Nova Ação de Venda';

  clientes: Cliente[] = [];
  queryOptions = new QueryOptions({
    pageSize: 25,
    params: {
      situacaoCliente: `in:INATIVO`,
      sort: '-dataInativacao',
    },
  });
  totalRecords = 0;
  loading = false;

  private postoSelecionado: number = null;
  private allSelected = false;

  private usuario: Usuario;

  menuAcoes: string[] = [ClientesInativosMainComponent.NOVA_ACAO_DE_VENDA];

  constructor(
    public appState: AppState,
    public dialogService: DialogService,
    private clienteService: ClienteService,
    private visitaService: VisitaService,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    const shared = SharedService.getInstance();
    this.usuario = shared.usuario;
  }

  ngOnInit(): void {
    this.activatedRoute.snapshot.queryParams = this.queryOptions.params;
  }

  listarClientes(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.queryOptions.params = { ...this.activatedRoute.snapshot.queryParams, joinRota: true };
    this.clienteService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Cliente>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.clientes = response.object.content;
      } else {
        this.clientes = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
    });
  }

  async cadastrarAcoesDeVenda(_: never): Promise<void> {
    let quantidade = this.totalRecords;
    if (!this.allSelected) {
      quantidade = this.clientes.filter((cliente: Cliente) => cliente.selecionado).length;
    }

    const confirm = async (_preAprovar: boolean) => {
      this.appState.isLoading = true;
      try {
        const { joinRota, ...options } = this.queryOptions.params;
        if ('valorInicial' in options) {
          options.valorInicial = options.valorInicial.toString();
        }
        if ('valorFinal' in options) {
          options.valorFinal = options.valorFinal.toString();
        }
        const res = await this.visitaService
          .registrarAcoesDeVenda({
            allSelected: this.allSelected,
            captacao: false,
            preAprovar: _preAprovar,
            clientesIds: this.allSelected
              ? []
              : this.clientes.filter((cliente: Cliente) => cliente.selecionado).map((cliente: Cliente) => cliente.id),
            captadosIds: null,
            params: this.allSelected
              ? JSON.stringify({
                ...options,
                page: '1',
                size: this.totalRecords.toString(),
              })
              : '',
            usuarioHeimdallId: this.usuario.id,
            captadorNome: this.usuario.nome,
            origem: OrigemAcaoDeVendaEnum.INATIVOS,
          })
          .toPromise();
        this.dialogService.feedbackReturnAPI(res, 'Ações de Venda registradas com sucesso');
      } catch (error) {
        this.dialogService.feedbackError('Não foi possível fazer os registros');
      } finally {
        this.appState.isLoading = false;
      }
    };

    this.dialogService.checkboxDialog({
      title: 'Cadastrar Ações de Venda',
      message: `
        Deseja registrar uma ação de venda para os clientes selecionados?
        Quantidade de novas ações: ${quantidade}`,
      confirmfn: confirm.bind(this),
    });
  }

  onLoadClientePage(page: number): void {
    this.listarClientes(page);
  }

  onAcaoClick(event: ClienteAcaoEvent): void {
    const actions = new Map<string, (cliente: Cliente) => void>().set(
      ClientesInativosMainComponent.NOVA_ACAO_DE_VENDA,
      this.cadastrarAcoesDeVenda.bind(this),
    );
    actions.get(event.acao)(event.cliente);
  }

  onMenuAcaoClick(event: ClienteMenuAcaoEvent): void {
    const actions = new Map<string, () => void>().set(
      ClientesInativosMainComponent.NOVA_ACAO_DE_VENDA,
      this.cadastrarAcoesDeVenda.bind(this),
    );

    actions.get(event.acao)();
  }

  onFilter(options: ClienteListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    this.queryOptions.params = {
      nomeCliente: options.nomeCliente ? `${options.nomeCliente}` : null,
      postoId,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
      rota: options.descricaoRota || null,
      documentoCliente: options.documentoCliente ? options.documentoCliente : null,
      situacaoCliente: `in:INATIVO`,
      sort: '-dataInativacao',
      valorInicial: options.valorInicial || null,
      valorFinal: options.valorFinal || null,
    };
    this.router.navigate(['/base/clientes/inativos'], { queryParams: this.queryOptions.params }).then(() => this.listarClientes());
    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }
  }

  onDisclosure(cliente: Cliente): void {
    this.router.navigate(['base/clientes/inativos/detail/' + cliente.id]);
  }

  onSelectAll(param: boolean): void {
    this.allSelected = param;
  }
}
