import { Resource } from 'src/app/global/domain/resource.model';

export class DashboardProrrogado extends Resource {
  nomeEstado: string;

  descricaoPosto: string;

  valorProrrogado: number;

  quantidadeProrrogado: number;

}
