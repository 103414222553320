import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { AcompanhamentoVenda } from "src/app/base/domain/acompanhamento-venda.model";
import { Posto } from 'src/app/base/domain/posto.model';
import { AcompanhamentoVendaService } from 'src/app/base/services/acompanhamento-venda.service';

export interface ListFilterOptions {
  postoSelecionado: Posto;
  dataInicial: Date;
  dataFinal: Date;
}

@Component({
  selector: 'app-acompanhamento-venda-main',
  templateUrl: './acompanhamento-venda-main.component.html',
  styleUrls: ['./acompanhamento-venda-main.component.css'],
})
export class AcompanhamentoVendaMainComponent implements OnInit {
  alternate: boolean = false;
  toggle: boolean = false;
  color: boolean = true;
  size: number = 70;
  expandEnabled: boolean = true;
  contentAnimation: boolean = true;
  dotAnimation: boolean = true;
  side = 'right';
  items: AcompanhamentoVenda[] = [];
  showPercentual: boolean = true;

  private readonly defaultFilterOptions: ListFilterOptions = {
    postoSelecionado: null,
    dataInicial: null,
    dataFinal: null,
  };

  filterOptions: ListFilterOptions;

  queryOptions = new QueryOptions();

  constructor(private router: Router, private acompanhamentoVendaService: AcompanhamentoVendaService) { }

  ngOnInit(): void {
    this.load(this.queryOptions);

    this.onLimparFiltros();
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  onDotClick(event) {
    if (!this.expandEnabled) {
      event.stopPropagation();
    }
  }

  onExpandEntry(expanded, index) {
    console.log(`Expand status of entry #${index} changed to ${expanded}`)
  }

  onHeaderClick(event) {
    if (!this.expandEnabled) {
      event.stopPropagation();
    }
  }

  onAplicarFiltro(): void {
    const postoId = this.filterOptions.postoSelecionado ? this.filterOptions.postoSelecionado.id : null;
    this.queryOptions.params = {
      postoId,
      dataInicio: this.filterOptions.dataInicial ? this.filterOptions.dataInicial?.toLocaleString() : null,
      dataFinal: this.filterOptions.dataFinal ? this.filterOptions.dataFinal?.toLocaleString() : null,
    };

    this.load(this.queryOptions);
  }

  onAtualizar() {
    this.load(this.queryOptions);
  }

  load(queryOptions) {
    this.acompanhamentoVendaService.findAll(queryOptions).subscribe(data => {
      this.items = data;
    });
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }
}
