import { Resource } from 'src/app/global/domain/resource.model';

export class DashboardDespesa extends Resource {
  nomeEstado: string;
  descricaoPosto: string;

  quantidadeDespesaEmpresa: number;
  valorTotalDespesaEmpresa: number;

  quantidadeDespesaFuncionario: number;
  valorTotalDespesaFuncionario: number;

  quantidadeDespesaAdministrativa: number;
  valorTotalDespesaAdministrativa: number;

  quantidadeDespesaFalta: number;
  valorTotalDespesaFalta: number;

  quantidadeDespesaTotal: number;
  valorTotalDespesa: number;
}
