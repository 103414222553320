import { AppState } from './../../../../App.state';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DashboardCrecimentoSustentavel } from 'src/app/base/domain/dashboard-crescimento-sustentavel.model';
import { DashboardCrescimentoSustentavelService } from 'src/app/base/services/dashboard-crescimento-sustentavel.service';
import { DashBoardCrescimentoSustentavelFilterOptions } from 'src/app/global/crescimento-sustentavel-dashboard/crescimento-sustentavel-dashboard.component';
import { QueryOptions } from 'src/app/global/domain/query.options';

@Component({
  selector: 'app-crescimento-sustentavel-posto',
  templateUrl: './crescimento-sustentavel-posto.component.html',
  styleUrls: ['./crescimento-sustentavel-posto.component.css']
})
export class CrescimentoSustentavelPostoComponent implements OnInit {

  queryOptions = new QueryOptions({ pageSize: 10 });

  estadoId: number;
  postoId: number;

  dashboard: DashboardCrecimentoSustentavel = new DashboardCrecimentoSustentavel();

  constructor(
    private service: DashboardCrescimentoSustentavelService,
    private route: ActivatedRoute,
    private appState: AppState) {
    this.route.params.subscribe((params: Params) => {
      this.estadoId = params.estadoId;
      this.postoId = params.id;
    });
    this.queryOptions.params = {
      ...this.queryOptions.params,
      estadoId: this.estadoId,
      postoId: this.postoId
    };
  }

  ngOnInit(): void {
    this.getDashBoardWithoutRefresh();
  }

  getDashBoard(): void {
    this.appState.isLoading = true;
    this.service.getDashboard(this.queryOptions).subscribe(
      (response: DashboardCrecimentoSustentavel) => {
        this.dashboard = response;
        this.appState.isLoading = false;
      }
    );
  }

  getDashBoardWithoutRefresh(): void {
    this.appState.isLoading = true;
    this.service.getDashboardWithoutRefresh(this.queryOptions).subscribe(
      (response: DashboardCrecimentoSustentavel) => {
        this.dashboard = response;
        this.appState.isLoading = false;
      }
    );
  }

  onFilter(event: DashBoardCrescimentoSustentavelFilterOptions): void {
    this.queryOptions.params = {
      ...this.queryOptions.params,
      dataCadastro: event.dataInicial.getTime()
    };
    this.getDashBoardWithoutRefresh();
  }

  onReload(): void {
    this.getDashBoard();
  }
}
