import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuditoriaContratoMainComponent } from './auditoria-contrato-main/auditoria-contrato-main.component';
import { GlobalModule } from 'src/app/global/global.module';

@NgModule({
  declarations: [AuditoriaContratoMainComponent],
  imports: [CommonModule, GlobalModule],
})
export class AuditoriaContratoModule {}
