import { Component } from '@angular/core';
import { AppState } from 'src/app/App.state';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/global/services/dialog.service';
import { DuplicadoDTO } from 'src/app/base/domain/dto/duplicados.dto';
import { TransferenciaService } from 'src/app/base/services/transferencia.service';
import { ParcelaDuplicadaAcaoEvent } from 'src/app/global/lists/parcela-duplicada-list/parcela-duplicada-list.component';
import { QueryOptions } from 'src/app/global/domain/query.options';

@Component({
  selector: 'app-acompanhamento-parcela-duplicada',
  templateUrl: './acompanhamento-parcela-duplicada-main.component.html',
  styleUrls: ['./acompanhamento-parcela-duplicada-main.component.css'],
})
export class AcompanhamentoParcelaDuplicadaMainComponent {
  private static DELETE_ACTION = 'Deletar Duplicadas';

  duplicados: DuplicadoDTO[];
  totalRecords = 0;
  queryOptions = new QueryOptions({ pageSize: 10, pageSort: '' });

  constructor(
    private transferenciaService: TransferenciaService,
    public dialog: MatDialog,
    public appState: AppState,
    public dialogService: DialogService,
  ) {}

  mapearAcoesDeDuplicados(duplicados: DuplicadoDTO[]): void {
    const mapearAcoes = (duplicado: DuplicadoDTO): DuplicadoDTO => {
      const acoes = [];
      if (duplicado.duplicado) {
        acoes.push(AcompanhamentoParcelaDuplicadaMainComponent.DELETE_ACTION);
      }
      return { ...duplicado, acoes };
    };
    this.duplicados = duplicados?.map(mapearAcoes) ?? [];
  }

  listarDuplicados(page: number = 0): void {
    this.queryOptions.pageNumber = page + 1;
    this.appState.isLoading = true;
    this.transferenciaService.pagePostoParcelaDuplicada(this.queryOptions).subscribe(
      (response: ReturnAPI<Page<DuplicadoDTO>>) => {
        if (response.success) {
          this.mapearAcoesDeDuplicados(response.object.content);
          this.totalRecords = response.object.totalElements;
        }
        this.appState.isLoading = false;
      },
      () => (this.appState.isLoading = false),
    );
  }

  onLoadDuplicados(pageNumber: number): void {
    this.listarDuplicados(pageNumber);
  }

  async onCancelar(duplicado: DuplicadoDTO): Promise<void> {
    this.appState.isLoading = true;
    try {
      const response = await this.transferenciaService.cancelarDuplicadas(duplicado).toPromise();
      this.appState.isLoading = false;
      this.dialogService.feedbackReturnAPI(response, 'Parcelas duplicadas canceladas com sucesso!');
      if (response.success) {
        this.listarDuplicados();
      }
    } catch {
      this.appState.isLoading = false;
      this.dialogService.feedbackError('Ocorreu um erro ao cancelar as duplicadas!');
    }
  }

  onAcaoClick(event: ParcelaDuplicadaAcaoEvent): void {
    const actions = new Map<string, (duplicado: DuplicadoDTO) => void>().set(
      AcompanhamentoParcelaDuplicadaMainComponent.DELETE_ACTION,
      (duplicado: DuplicadoDTO) => {
        this.dialogService.confirmDialog('Deseja realmente cancelar as parcelas duplicadas?', () => this.onCancelar(duplicado));
      },
    );

    actions.get(event.acao)(event.duplicado);
  }
}
