import { CrescimentoSustentavelMainComponent } from './crescimento-sustentavel-main/crescimento-sustentavel-main.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalModule } from 'src/app/global/global.module';
import { CrescimentoSustentavelEstadoComponent } from './crescimento-sustentavel-estado/crescimento-sustentavel-estado.component';
import { CrescimentoSustentavelPostoComponent } from './crescimento-sustentavel-posto/crescimento-sustentavel-posto.component';
import { ComponentsModule } from 'src/app/global/components/components.module';



@NgModule({
  declarations: [
    CrescimentoSustentavelMainComponent,
    CrescimentoSustentavelEstadoComponent,
    CrescimentoSustentavelPostoComponent,
  ],
  imports: [
    CommonModule,
    GlobalModule,
    ComponentsModule
  ]
})
export class CrescimentoSustentavelModule { }
