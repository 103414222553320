import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DashboardDespesa } from 'src/app/base/domain/dashboard-despesa.model';
import { DashBoardEstadoFaturamento } from 'src/app/base/domain/dashboard-estado-faturamento.model';
import { PostoDTO } from 'src/app/base/domain/dto/posto.dto';
import { Estado } from 'src/app/base/domain/estado.model';
import { Posto } from 'src/app/base/domain/posto.model';

export interface DashBoardFilterOptions {
  postoSelecionado: Posto;
  estadoSelecionado: Estado;
  dataInicial: Date;
  dataFinal: Date;
}

@Component({
  selector: 'app-despesa-dashboard',
  templateUrl: './despesa-dashboard.component.html',
  styleUrls: ['./despesa-dashboard.component.css'],
})
export class DespesaDashboardComponent implements OnInit {
  @Input() title: string;
  @Input() dashboard: DashboardDespesa;
  @Input() list: DashBoardEstadoFaturamento[];
  @Input() filtros: string[];
  @Input() typeDashboard: string;

  @Output() filter = new EventEmitter<DashBoardFilterOptions>();
  @Output() reloadEvent = new EventEmitter<number>();

  @Output() disclosure = new EventEmitter<DashBoardEstadoFaturamento>();
  @Output() disclosureDashBoardEstado = new EventEmitter<PostoDTO>();

  private readonly defaultFilterOptions: DashBoardFilterOptions = {
    postoSelecionado: null,
    estadoSelecionado: null,
    dataInicial: null,
    dataFinal: null,
  };

  filterOptions: DashBoardFilterOptions;

  constructor() {
    this.onLimparFiltros();
  }

  ngOnInit(): void { }

  onAtualizar(): void {
    this.reloadEvent.emit();
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  onDisclosure(dashboard: DashBoardEstadoFaturamento): void {
    this.disclosure.emit(dashboard);
  }
}
