import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Pessoa } from '../../domain/pessoa.model';
import { PessoaService } from '../../services/pessoa.service';
import { MessageService } from 'src/app/global/components/message/message.service';

@Component({
    selector: 'app-pessoa-detail',
    templateUrl: './pessoa-detail.component.html',
    styleUrls: ['./pessoa-detail.component.css']
})
export class PessoaDetailComponent implements OnInit {

    pessoa: Pessoa;

    constructor(
        private pessoaService: PessoaService,
        private activeRoute: ActivatedRoute,
        private router: Router,
        private messageService: MessageService) { }

    ngOnInit() {
        const id = this.activeRoute.snapshot.params['id'];
        this.pessoaService.read(id)
            .subscribe(
                pessoa => this.pessoa = pessoa,
                response => {
                 });
    }

    goToEdit() {
        this.router.navigate([`/basico/pessoa/${this.pessoa.id}`]);
    }

    remover() {
        this.pessoaService.delete(this.pessoa).subscribe(() =>
            this.router.navigate([`/basico/pessoa`]));
        this.messageService.messageSuccess('Remoção realizada com sucesso');
    }
}
