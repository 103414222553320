import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerificacaoClienteMainComponent } from './verificacao-cliente-main/verificacao-cliente-main.component';
import { GlobalModule } from 'src/app/global/global.module';
import { VerificacaoClienteDetailComponent } from './verificacao-cliente-detail/verificacao-cliente-detail.component';



@NgModule({
  declarations: [VerificacaoClienteMainComponent, VerificacaoClienteDetailComponent],
  imports: [CommonModule, GlobalModule, MatCheckboxModule]
})
export class VerificacaoClienteModule { }
