import {
  AfterViewChecked,
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
  AfterContentInit,
} from '@angular/core';
import { Contrato } from 'src/app/base/domain/contrato.model';
import { MatDialog } from '@angular/material/dialog';
import { EnderecoFormComponent, EnderecoFormData } from '../../forms/endereco-form/endereco-form.component';
import { VisitaService } from 'src/app/base/services/visita.service';
import { ReturnAPI } from 'src/app/base/domain/return-api.model';
import { Visita } from 'src/app/base/domain/visita.model';
import { DialogService } from '../../services/dialog.service';
import { ContratoService } from '../../../base/services/contrato.service';
import { RepactuacaoContratoFormComponent } from '../../forms/repactuacao-contrato-form/repactuacao-contrato-form.component';
import { LiquidarContratoFormComponent } from '../../forms/liquidar-contrato-form/liquidar-contrato-form.component';
import { Endereco } from 'src/app/base/domain/endereco.model';
import { AuditoriaContrato } from 'src/app/base/domain/auditoria-contrato.model';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/auth/shared.service';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { GerarParcelaFormComponent } from '../../forms/gerar-parcela-form/gerar-parcela-form.component';
import { QueryOptions } from '../../domain/query.options';
import { Parcela } from 'src/app/base/domain/parcela.model';
import { AdicionarPromocaoComponent } from '../../forms/adicionar-promocao/adicionar-promocao.component';
import { GerarPromocaoDTO } from 'src/app/base/domain/dto/gerar-promocao-dto';
import { timestamp } from 'rxjs/operators';
import { AlterarParcelaComponent } from '../../forms/alterar-parcela-contrato/alterar-parcela-contrato.component';
import { AlterarParcelaDTO } from 'src/app/base/domain/dto/alterar-parcela-dto';
import { RenegociarContratoFormComponent } from '../../forms/renegociar-contrato-form/renegociar-contrato-form.component';
import { HttpErrorResponse } from '@angular/common/http';

type ContratoAction = [string, string];
export type ContratoEvent = [string, Contrato];

@Component({
  selector: 'app-contrato-info-geral',
  templateUrl: './contrato-info-geral.component.html',
  styleUrls: ['./contrato-info-geral.component.css'],
})
export class ContratoInfoGeralComponent implements OnInit, AfterContentInit, OnChanges {
  private static ALTERAR_TITULACAO_ACTION = 'Alterar Endereço';
  private static RENEGOCIAR_CONTRATO = 'Prorrogação de contrato';
  private static REPACTUAR_DIVIDA = 'Repactuar Divida';
  private static LIQUIDAR_CONTRATO = 'Liquidar Contrato';
  private static GERAR_PARCELA = 'Gerar Parcela';
  private static ADICIONAR_PROMOCAO = 'Adicionar Promoção';
  private static ALTERAR_PARCELA = 'Alterar Parcela';

  @Input() contrato: Contrato;
  @Output() actionEvent = new EventEmitter<ContratoEvent>();

  valorPago = 0;
  diasEmAtraso: number;
  contratoId: number;

  auditoriaContrato = new AuditoriaContrato();
  contratoPromocao = new GerarPromocaoDTO();

  shared: SharedService;
  usuario: Usuario;
  email: string;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public dialogService: DialogService,
    private visitaService: VisitaService,
    private contratoService: ContratoService,
  ) {
    const shared = SharedService.getInstance();
    this.usuario = shared.usuario;
  }

  contratoActions: ContratoAction[] = [
    [ContratoInfoGeralComponent.ALTERAR_TITULACAO_ACTION, 'Aplica um novo endereço ao cliente para gerar uma nova CCB'],
    [ContratoInfoGeralComponent.RENEGOCIAR_CONTRATO, 'Prorrogação de contrato'],
    [ContratoInfoGeralComponent.REPACTUAR_DIVIDA, 'Gerar novo contrato'],
    [ContratoInfoGeralComponent.ADICIONAR_PROMOCAO, 'Adicionar uma promoção em dias aumentando o vencimento do contrato'],
  ];

  ngOnInit(): void {
    if (this.checkUsuarioAdmin(this.email)) {
      this.contratoActions.push(
        [ContratoInfoGeralComponent.LIQUIDAR_CONTRATO, 'Liquidar divida do contrato'],
        [ContratoInfoGeralComponent.ALTERAR_PARCELA, 'Altera uma parcela existente de um contrato'],
      );
    }
  }

  ngAfterContentInit(): void {
    // tslint:disable-next-line:typedef
    this.valorPago = this.contrato?.listParcela?.reduce((acumulador, parcela) => {
      return acumulador + parcela.valorPago;
    }, 0);

    const hoje = new Date().setHours(0, 0, 0);
    const dataVencimento = new Date(this.contrato.dataVencimento).setHours(0, 0, 0);
    this.diasEmAtraso = Math.floor((hoje - dataVencimento) / 1000 / 60 / 60 / 24);

    this.contratoId = this.contrato.id;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contrato && changes.contrato.currentValue) {
      if (this.contrato.situacaoContrato === 'EM_ATRASO') {
        this.contratoActions.push([ContratoInfoGeralComponent.GERAR_PARCELA, 'Gerar Parcela']);
      }
    }
  }

  async renegociarContrato(): Promise<void> {
    const dialogRef = await this.dialog.open(RenegociarContratoFormComponent, {
      data: {
        contrato: this.contrato,
        action: 'RENEGOCIAR_CONTRATO',
      },
      width: '350px',
    });

    dialogRef.afterClosed().subscribe((auditoria: AuditoriaContrato) => {
      if (auditoria) {
        this.contratoService.renegociarContrato(auditoria).subscribe(
          (response: ReturnAPI<Contrato>) => {
            this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
          },
          (e: HttpErrorResponse) => {
            this.dialogService.feedbackError(`Houve um erro ao renegociar contrato.\n ${e.message}`);
          },
        );
      }
    });
  }

  async alterarTitulacaoContrato(): Promise<void> {
    const endereco = await this.dialog
      .open<EnderecoFormComponent, EnderecoFormData, Endereco>(EnderecoFormComponent, {
        width: '800px',
        data: {
          isMutating: true,
          endereco: this.contrato.visita.titulacaoCCB?.endereco,
          toEdit: true,
        },
      })
      .afterClosed()
      .toPromise();

    if (endereco) {
      this.visitaService.alterarTitulacaoCCB(this.contrato.visita.id, { ...this.contrato.visita.titulacaoCCB, endereco }).subscribe(
        (response: ReturnAPI<Visita>) => {
          this.dialogService.feedbackReturnAPI(response, 'Titulação alterado com sucesso!');
          if (response.success) {
            this.contrato.visita = response.object;
          }
        },
        () => this.dialogService.feedbackError('Houve um erro na alteração!'),
      );
    }
  }

  onAction(action: ContratoAction): void {
    let actions = new Map<string, () => void>()
      .set(ContratoInfoGeralComponent.ALTERAR_TITULACAO_ACTION, () => this.alterarTitulacaoContrato())
      .set(ContratoInfoGeralComponent.RENEGOCIAR_CONTRATO, () => this.renegociarContrato())
      .set(ContratoInfoGeralComponent.REPACTUAR_DIVIDA, () => this.repactuarDivida())
      .set(ContratoInfoGeralComponent.GERAR_PARCELA, () => this.gerarParcela())
      .set(ContratoInfoGeralComponent.ADICIONAR_PROMOCAO, () => this.gerarPromocao());

    if (this.checkUsuarioAdmin(this.email)) {
      actions.set(ContratoInfoGeralComponent.LIQUIDAR_CONTRATO, () => this.liquidarContrato());
      actions.set(ContratoInfoGeralComponent.ALTERAR_PARCELA, () => this.alterarParcela());
    }

    actions.get(action[0])();
  }

  get formaPagamento(): string {
    if (this.contrato?.visita.pagamentoBoleto) {
      return 'BOLETO';
    }
    if (this.contrato?.visita.pagamentoCartao) {
      return 'CARTAO';
    }
    if (this.contrato?.visita.pagamentoDinheiro) {
      return 'DINHEIRO';
    }
    return '';
  }

  checkUsuarioAdmin(email: string): boolean {
    email = this.usuario.email;
    if (email == 'admin@gmail.com') {
      return true;
    } else {
      return false;
    }
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  async repactuarDivida(): Promise<void> {
    const repactuar = await this.dialog
      .open(RepactuacaoContratoFormComponent, {
        width: '400px',
        data: {
          contrato: this.contrato,
          action: 'REPACTUAR_CONTRATO',
        },
      })
      .afterClosed()
      .toPromise();

    if (repactuar) {
      this.auditoriaContrato.idContrato = this.contrato.id;
      this.auditoriaContrato.uidContrato = this.contrato.uid;
      this.auditoriaContrato.valorAcordado = repactuar.valorAcordado;
      this.auditoriaContrato.quantidadeParcelas = repactuar.qtdParcelas;
      this.auditoriaContrato.dataLiberacao = repactuar.dataLiberacao;
      this.auditoriaContrato.periodicidade = repactuar.periodicidade;
      this.auditoriaContrato.valorMinimoSimulado = this.contrato.saldoDevedor;
      this.auditoriaContrato.dataVencimento = repactuar.dataVencimento;
      this.auditoriaContrato.taxaJuros = repactuar.taxaJuros;
      this.auditoriaContrato.repactuacaoDD = repactuar.repactuacaoDD;

      this.contratoService.repactuarContrato(this.auditoriaContrato).subscribe(
        (response: ReturnAPI<Contrato>) => {
          this.dialogService.feedbackReturnAPI(response, 'Repactuação feita com sucesso!');
          if (response.success) {
            this.contrato = response.object;
          }
        },
        () => this.dialogService.feedbackError('Houve um erro na solicitacao!'),
      );
    }
  }

  async liquidarContrato(): Promise<void> {
    const contrato = await this.dialog
      .open(LiquidarContratoFormComponent, {
        width: '400px',
        data: {
          contrato: this.contrato,
          action: 'LIQUIDAR_CONTRATO',
        },
      })
      .afterClosed()
      .toPromise();

    if (contrato && ['admin@gmail.com'].some((email: string) => this.usuario.email === email)) {
      this.auditoriaContrato.idContrato = contrato.id;
      this.auditoriaContrato.uidContrato = contrato.uid;
      this.auditoriaContrato.valorAcordado = contrato.valorLiquidacao;
      this.auditoriaContrato.parecer = contrato.parecerLiquidacao;
      this.auditoriaContrato.situacaoContrato = contrato.situacaoContrato;

      this.contratoService.liquidarContrato(this.auditoriaContrato).subscribe(
        (response: ReturnAPI<Contrato>) => {
          this.dialogService.feedbackReturnAPI(response, 'Liquidação feita com sucesso!');
          if (response.success) {
            this.contrato = response.object;
          }
        },
        () => this.dialogService.feedbackError('Houve um erro na solicitacao!'),
      );
    }
  }

  gerarPromocao(): void {
    this.dialog
      .open(AdicionarPromocaoComponent, {
        width: '400px',
      })
      .afterClosed()
      .subscribe((response: number) => {
        if (response) {
          (this.contratoPromocao.contratoId = this.contratoId),
            (this.contratoPromocao.dataPromo = new Date()),
            (this.contratoPromocao.quantidadeParcelas = response);
          this.contratoService.gerarPromocaoDias(this.contratoPromocao).subscribe((contrato: ReturnAPI<GerarPromocaoDTO>) => {
            if (contrato.success) {
              this.dialogService.feedbackReturnAPI(
                contrato,
                `Contrato: ${this.contratoPromocao.contratoId} - teve uma promoção adicionada de ${this.contratoPromocao.quantidadeParcelas} dias`,
              );
            } else {
              this.dialogService.feedbackError(`Contrato: ${this.contratoPromocao.contratoId} - já particica de uma promoção.`);
            }
          });
        }
      });
  }

  gerarParcela(): void {
    this.dialog
      .open(GerarParcelaFormComponent, {
        width: '400px',
      })
      .afterClosed()
      .subscribe((response: Date) => {
        if (response) {
          const options = new QueryOptions();
          options.params = {
            contratoId: this.contrato.id,
            data: response.getTime(),
          };

          this.contratoService.gerarParcelaByContrato(options).subscribe((parcela: ReturnAPI<Parcela>) => {
            if (parcela.success) {
              this.dialogService.feedbackReturnAPI(parcela, `Parcela: ${parcela.object.id} - Gerada com Sucesso!`);
            } else {
              this.dialogService.feedbackError(parcela.messages[0].text);
            }
          });
        }
      });
  }

  alterarParcela(): void {
    this.dialog
      .open(AlterarParcelaComponent, {
        width: '400px',
      })
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          let alterarParcelaDTO = new AlterarParcelaDTO();
          alterarParcelaDTO.contratoId = this.contrato.id;
          alterarParcelaDTO.dataParcela = response.dataParcela.getTime();
          alterarParcelaDTO.valorParcela = response.valor;
          alterarParcelaDTO.usuario = this.usuario.nome;

          this.contratoService.alterarParcela(alterarParcelaDTO).subscribe((parcela: ReturnAPI<Parcela>) => {
            if (parcela.success) {
              this.dialogService.feedbackReturnAPI(parcela, `Parcela: ${parcela.object.id} - Gerada com Sucesso!`);
            } else {
              this.dialogService.feedbackError(parcela.messages[0].text);
            }
          });
        }
      });
  }
}
