import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { PlanejamentoSemanaCaptacao } from '../domain/planejamento-semana-captacao.model';
import { PlanejamentoSemanaCaptacaoSerializer } from '../serializables/planejamento-semana-captacao.serializer';
import { Observable } from 'rxjs';

@Injectable()
export class PlanejamentoSemanaCaptacaoService extends ResourceService<PlanejamentoSemanaCaptacao> {
  static RESOURCE = 'planejamento-semana-captacao';

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, 'planejamento-semana-captacao', new PlanejamentoSemanaCaptacaoSerializer(PlanejamentoSemanaCaptacao));
  }

  public findPostoCaptacaoHoje(usuarioId: number): Observable<number[]> {
    return this.httpClient.get<number[]>(`${WEB_API}/planejamento-semana-captacao/find-posto-captacao-hoje/${usuarioId}`);
  }
}
