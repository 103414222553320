import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from 'src/app/App.state';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ConciliacaoBancariaFilterOptions } from 'src/app/global/lists/arquivo-conciliacao-list/arquivo-conciliacao-list.component';
import { ArquivoConciliacaoBancaria } from '../../domain/arquivo-conciliacao-bancaria.model';
import { Page } from '../../domain/return-api.model';
import { ArquivoConciliacaoBancariaService } from '../../services/arquivo-conciliacao-bancaria.service';
import { ImportarArquivoConciliacaoBancariaFormComponent } from './../../../global/forms/importar-arquivo-conciliacao-bancaria-form/importar-arquivo-conciliacao-bancaria-form.component';
import {
  ArquivoConciliacaoListComponent,
  ConciliacaoBancariaEvent,
} from './../../../global/lists/arquivo-conciliacao-list/arquivo-conciliacao-list.component';
import { DialogService } from './../../../global/services/dialog.service';

@Component({
  selector: 'app-conciliacao-main',
  templateUrl: './conciliacao-main.component.html',
  styleUrls: ['./conciliacao-main.component.css'],
})
export class ConciliacaoMainComponent implements OnInit {
  private static CONFIRMAR_PAGAMENTOS = 'Confirmar Pagamentos';
  private static APAGAR_ARQUIVO = 'Apagar Arquivo';

  queryOptions = new QueryOptions({ pageSize: 25 });

  page: Page<ArquivoConciliacaoBancaria> = new Page();
  totalRecords: number = 0;

  loading: boolean = false;

  constructor(
    private service: ArquivoConciliacaoBancariaService,
    public appState: AppState,
    private dialog: MatDialog,
    private dialogService: DialogService,
  ) {
    const date = new Date();
    date.setHours(0, 0, 0);
    this.queryOptions.params = {
      dataInicio: date.getTime(),
      dataFim: new Date().getTime(),
    };
  }

  ngOnInit(): void { }

  list(pageNumber: number = 0): void {
    this.loading = true;
    this.appState.isLoading = true;
    this.queryOptions.pageNumber = pageNumber + 1;

    const dataInicio: Date = new Date(this.queryOptions.params.dataInicio as number);
    dataInicio.setHours(0, 0, 0);
    const dataFim = new Date(this.queryOptions.params.dataFim as number);
    dataFim.setHours(23, 59, 59);

    this.queryOptions.params = {
      dataInicio: dataInicio.getTime(),
      dataFim: dataFim.getTime(),
    };

    this.service.findAllPageable(this.queryOptions).subscribe((response: Page<ArquivoConciliacaoBancaria>) => {
      this.loading = false;
      this.appState.isLoading = false;

      this.totalRecords = response.totalElements;

      response.content = this.mapearAcoes(response.content);
      this.page = response;
    });
  }

  mapearAcoes(arquivos: ArquivoConciliacaoBancaria[]): ArquivoConciliacaoBancaria[] {
    const mapearAcoes = (arquivo: ArquivoConciliacaoBancaria): ArquivoConciliacaoBancaria => {
      const acoes = [];
      acoes.push(ConciliacaoMainComponent.CONFIRMAR_PAGAMENTOS, ConciliacaoMainComponent.APAGAR_ARQUIVO);
      return { ...arquivo, acoes };
    };

    return arquivos?.map(mapearAcoes) ?? [];
  }

  onFilter(options: ConciliacaoBancariaFilterOptions): void {
    const date = new Date();
    date.setHours(0, 0, 0, 0);

    this.queryOptions.params = {
      dataInicio: options.dataInicial ? options.dataInicial.getTime() : date.getTime(),
      dataFim: options.dataFinal ? options.dataFinal.getTime() : date.getTime(),
    };

    this.list();
  }

  onAcaoClick(event: ConciliacaoBancariaEvent): void {
    if (event.acao === ConciliacaoMainComponent.CONFIRMAR_PAGAMENTOS) {
      this.appState.isLoading = true;
      this.service.requestToSgf(event.arquivo.id).subscribe(
        (response) => {
          this.dialogService.feedbackSuccess('Finalizado o processamento do arquivo!');
          this.appState.isLoading = false;
          this.list();
        },
        (error: HttpErrorResponse) => {
          this.dialogService.feedbackError('Ocorreu um erro ao importar arquivo: ' + error.message);
          this.appState.isLoading = false;
        },
      );
    }

    if (event.acao === ConciliacaoMainComponent.APAGAR_ARQUIVO) {
      this.dialogService.confirmDialog('Deseja realmente deletar esse arquivo?', () =>
        this.service.apagarArquivo(event.arquivo.id).subscribe((response) => {
          this.dialogService.feedbackInfo('Arquivo apagado.'), this.list();
          (error: HttpErrorResponse) => {
            this.dialogService.feedbackError('Ocorreu um erro ao apagar o arquivo: ' + error.message);
          };
        }),
      );
    }
  }

  onMenuAcaoClick(event: string) {
    if (event === ArquivoConciliacaoListComponent.IMPORTAR_ARQUIVO) {
      this.dialog
        .open(ImportarArquivoConciliacaoBancariaFormComponent, {
          height: '300px',
          width: '800px',
        })
        .afterClosed()
        .subscribe((form) => {
          if (form) {
            this.service.importFile(form).subscribe(
              (response) => {
                if (response) {
                  this.dialogService.feedbackSuccess('Arquivo Importado com Sucesso!').then((_) => this.list(0));
                }
              },
              (error: HttpErrorResponse) => {
                this.dialogService.feedbackError('Ocorreu um erro ao importar arquivo: ' + error.error);
              },
            );
          }
        });
    }
  }

  onLoadArquivosPage(pageNumber: number): void {
    this.list(pageNumber);
  }
}
