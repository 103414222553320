import { Component, OnInit, Inject } from '@angular/core';
import { Contrato } from 'src/app/base/domain/contrato.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DataType {
  contrato: Contrato;
  action: String;
}

@Component({
  selector: 'app-finalizar-contrato-com-divida',
  templateUrl: './finalizar-contrato-com-divida.component.html',
  styleUrls: ['./finalizar-contrato-com-divida.component.css'],
})
export class FinalizarContratoComDividaComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<FinalizarContratoComDividaComponent>, @Inject(MAT_DIALOG_DATA) public data: DataType) {}

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(Object.assign(this.data.contrato));
  }
}
