import { Component, OnInit } from '@angular/core';
import { ParcelaCobrancaVale } from 'src/app/base/domain/parcela-cobranca-vale.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ActivatedRoute, Params } from '@angular/router';
import { ParcelaCobrancaValeService } from 'src/app/base/services/parcela-cobranca-vale.service';
import { DialogService } from 'src/app/global/services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from 'src/app/App.state';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import { BaixaParcelaCobrancaJudicialFormComponent } from 'src/app/global/forms/baixa-parcela-cobranca-judicial-form/baixa-parcela-cobranca-judicial-form.component';
import { HttpErrorResponse } from '@angular/common/http';
import { SituacaoParcela } from 'src/app/base/domain/enum/situacao-parcela.enum';
import { ParcelaCobrancaValeAcaoEvent } from 'src/app/global/lists/parcela-cobranca-vale-list/parcela-cobranca-vale-list.component';

@Component({
  selector: 'app-debitos-funcionario-detail',
  templateUrl: './debitos-funcionario-detail.component.html',
  styleUrls: ['./debitos-funcionario-detail.component.css'],
})
export class DebitosFuncionarioDetailComponent implements OnInit {
  private static BAIXA_PARCELA_ACTION = 'Dar Baixa Na Parcela';

  cobrancaId: number;
  parcela: ParcelaCobrancaVale;
  title = 'Parcelas Cobranca ValeParcelaCobrancaVale';

  parcelas: ParcelaCobrancaVale[] = [];

  queryOptions = new QueryOptions({ pageSize: 10 });
  totalRecords = 0;
  loading = false;

  constructor(
    private route: ActivatedRoute,
    private service: ParcelaCobrancaValeService,
    private dialogService: DialogService,
    public dialog: MatDialog,
    public appState: AppState,
  ) {
    this.route.params.subscribe((params: Params) => {
      this.cobrancaId = params.id;
    });

    this.queryOptions.params = {
      cobrancaValeParcelaCobrancaValeId: this.cobrancaId || null,
    };
  }

  ngOnInit(): void {}

  listar(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.service.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<ParcelaCobrancaVale>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.parcelas = response.object.content;
        this.mapearAcoes(this.parcelas);
      } else {
        this.parcelas = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
    });
  }

  onAcaoClick(event: ParcelaCobrancaValeAcaoEvent): void {
    const actions = new Map<string, (parcela: ParcelaCobrancaVale) => void>().set(
      DebitosFuncionarioDetailComponent.BAIXA_PARCELA_ACTION,
      (parcela: ParcelaCobrancaVale) => {
        this.baixaParcela(parcela);
      },
    );

    actions.get(event.acao)(event.parcelaCobranca);
  }

  baixaParcela(parcela: ParcelaCobrancaVale): void {
    const dialogRef = this.dialog.open(BaixaParcelaCobrancaJudicialFormComponent, {
      data: {
        parcela,
        action: 'novo',
      },
      width: '750px',
    });

    dialogRef.afterClosed().subscribe((parcelaVale: ParcelaCobrancaVale) => {
      this.appState.isLoading = true;
      if (parcelaVale) {
        this.service.baixaParcela(parcelaVale).subscribe(
          (response: ReturnAPI<ParcelaCobrancaVale>) => {
            this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
            this.appState.isLoading = false;
            this.listar();
          },
          (e: HttpErrorResponse) => {
            this.appState.isLoading = false;
            this.dialogService.feedbackError(`Houve um erro ao dar baixa na parcela.\n ${e.message}`);
          },
        );
      } else {
        this.appState.isLoading = false;
      }
    });
  }

  mapearAcoes(parcelas: ParcelaCobrancaVale[]): void {
    const mapearAcoes = (parcela: ParcelaCobrancaVale): ParcelaCobrancaVale => {
      const acoes = [];
      if (parcela.situacao === SituacaoParcela.GERADA) {
        acoes.push(DebitosFuncionarioDetailComponent.BAIXA_PARCELA_ACTION);
      }
      return { ...parcela, acoes };
    };

    this.parcelas = parcelas?.map(mapearAcoes) ?? [];
  }

  onLoadPage(page: number): void {
    this.listar(page);
  }
}
