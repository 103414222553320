import { Resource } from 'src/app/global/domain/resource.model';

export class ConciliacaoBancaria extends Resource {
  date: Date;

  name: string;

  cpfCnpj: string;

  transactionId: string;

  documento: number;

  identificador: string;

  tipoConciliacaoBancaria: string;

  valor: number;

  origem: string;

  processado: boolean;

  postoUId: string;

  descricaoPosto: string;

  clienteUId: string;

  nomeCliente: string;

  rotaId: number;

  descricaoRota: string;

  valorPago: number;
}
