import { Component, OnInit } from '@angular/core';
import { Cliente } from '../../../domain/cliente.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ClienteListFilterOptions, ClienteAcaoEvent } from 'src/app/global/lists/cliente-list/cliente-list.component';
import { DialogService } from 'src/app/global/services/dialog.service';
import { ClienteService } from '../../../services/cliente.service';
import { ReturnAPI, Page } from '../../../domain/return-api.model';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { AuditoriaClienteDTO } from '../../../domain/dto/auditoria-cliente.dto';
import { SituacaoCliente } from '../../../domain/enum/situacao-cliente.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { AlterarPostoClienteFormComponent } from 'src/app/global/forms/alterar-posto-cliente-form/alterar-posto-cliente-form.component';
import { ClientePostoDTO } from 'src/app/base/domain/dto/cliente-posto.dto';
import { AppState } from 'src/app/App.state';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { SharedService } from 'src/app/auth/shared.service';

@Component({
  selector: 'app-acompanhamento-cliente-main',
  templateUrl: './acompanhamento-cliente-main.component.html',
  styleUrls: ['./acompanhamento-cliente-main.component.css'],
})
export class AcompanhamentoClienteMainComponent implements OnInit {
  private static NORMALIZAR_CLIENTE_ACTION = 'Reativar Cliente';
  private static DUVIDOSO_CLIENTE_ACTION = 'Mudar situação para devedor duvidoso';
  private static TROCA_POSTO_CLIENTE_ACTION = 'Mudar posto do cliente';
  private static SET_SITUACAO_BLOQUEADO = 'Bloquear Cliente';
  private static SET_COBRANCA_JUDICIAL_ACTION = 'Colocar cliente em Cobrança Judicial';
  private static SET_EM_TRANSFERENCIA_ACTION = 'Colocar cliente em Transferência';
  private static DESBLOQUEAR_DIVIDA_ACTION = 'Desbloqueia cliente com dívida';

  clientes: Cliente[] = [];
  queryOptions = new QueryOptions({ pageSize: 25 });
  totalRecords = 0;
  loading = false;
  usuario: Usuario;
  emails = ['ronaldo@gjbl.com.br', 'admin@gmail.com'];

  private postoSelecionado: number = null;

  constructor(
    public dialogService: DialogService,
    private clienteService: ClienteService,
    public dialog: MatDialog,
    private router: Router,
    public appState: AppState,
    private activatedRoute: ActivatedRoute,
  ) {
    const shared = SharedService.getInstance();
    this.usuario = shared.usuario;
  }

  ngOnInit(): void { }

  listarClientes(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.queryOptions.params = { ...this.activatedRoute.snapshot.queryParams, joinRota: true };
    this.clienteService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Cliente>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.clientes = response.object.content;
        this.mapearAcoes(response.object.content);
      } else {
        this.clientes = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
    });
  }

  mapearAcoes(clientes: Cliente[]): void {
    const mapearAcoes = (cliente: Cliente): Cliente => {
      const acoes = [];

      if (cliente.situacaoCliente === SituacaoCliente.ATIVO || cliente.situacaoCliente === SituacaoCliente.NOVO) {
        acoes.push(AcompanhamentoClienteMainComponent.DUVIDOSO_CLIENTE_ACTION);
      } else {
        acoes.push(AcompanhamentoClienteMainComponent.NORMALIZAR_CLIENTE_ACTION);
      }

      if (this.emails.includes(this.usuario.email) && cliente.situacaoCliente === SituacaoCliente.BLOQUEADO_DIVIDA) {
        acoes.push(AcompanhamentoClienteMainComponent.DESBLOQUEAR_DIVIDA_ACTION);
      }

      if (this.emails.includes(this.usuario.email)) {
        acoes.push(AcompanhamentoClienteMainComponent.SET_EM_TRANSFERENCIA_ACTION);
        acoes.push(AcompanhamentoClienteMainComponent.TROCA_POSTO_CLIENTE_ACTION);
      }

      acoes.push(AcompanhamentoClienteMainComponent.SET_SITUACAO_BLOQUEADO);
      acoes.push(AcompanhamentoClienteMainComponent.SET_COBRANCA_JUDICIAL_ACTION);

      return { ...cliente, acoes };
    };

    this.clientes = clientes?.map(mapearAcoes) ?? [];
  }

  onAcaoClick(event: ClienteAcaoEvent): void {
    const actions = new Map<string, (cliente: Cliente) => void>()
      .set(AcompanhamentoClienteMainComponent.NORMALIZAR_CLIENTE_ACTION, (cliente: Cliente) => {
        this.onReativarCliente(cliente);
      })
      .set(AcompanhamentoClienteMainComponent.DUVIDOSO_CLIENTE_ACTION, (cliente: Cliente) => {
        this.onSetClienteDuvidoso(cliente);
      })
      .set(AcompanhamentoClienteMainComponent.SET_SITUACAO_BLOQUEADO, (cliente: Cliente) => {
        this.onSetClienteBloqueado(cliente);
      })
      .set(AcompanhamentoClienteMainComponent.TROCA_POSTO_CLIENTE_ACTION, (cliente: Cliente) => {
        this.onChangePostoCliente(cliente);
      })
      .set(AcompanhamentoClienteMainComponent.SET_COBRANCA_JUDICIAL_ACTION, (cliente: Cliente) => {
        this.onSetClienteCobrancaJudicial(cliente);
      })
      .set(AcompanhamentoClienteMainComponent.SET_EM_TRANSFERENCIA_ACTION, (cliente: Cliente) => {
        this.onSetClienteEmTransferencia(cliente);
      });

    actions.get(event.acao)(event.cliente);
  }

  onReativarCliente(cliente: Cliente): void {
    this.dialogService.parecerDialog(
      `Informe o parecer dessa ação abaixo para reativar o cliente ${cliente.pessoa.nomePessoa}.`,
      (response: string) => {
        this.dialogService.auditoriaDialog(() => {
          const auditoriaClienteDTO = new AuditoriaClienteDTO();
          auditoriaClienteDTO.clienteId = cliente.id;
          auditoriaClienteDTO.parecer = response;
          auditoriaClienteDTO.situacao = SituacaoCliente.ATIVO;
          auditoriaClienteDTO.postoId = cliente.posto.id;
          auditoriaClienteDTO.postoNome = cliente.posto.descricao;
          auditoriaClienteDTO.nomeCliente = cliente.nomeCliente;
          this.atualizarSituacaoCliente(auditoriaClienteDTO);
          return response;
        });

        return null;
      },
    );
  }

  onSetClienteDuvidoso(cliente: Cliente): void {
    this.dialogService.parecerDialog(
      `Informe o parecer para colocar o cliente ${cliente.pessoa.nomePessoa} na situação de devedor duvidoso.`,
      (response: string) => {
        this.dialogService.auditoriaDialog(() => {
          const auditoriaClienteDTO = new AuditoriaClienteDTO();
          auditoriaClienteDTO.clienteId = cliente.id;
          auditoriaClienteDTO.parecer = response;
          auditoriaClienteDTO.situacao = SituacaoCliente.DEVEDOR_DUVIDOSO;
          auditoriaClienteDTO.postoId = cliente.posto.id;
          auditoriaClienteDTO.postoNome = cliente.posto.descricao;
          auditoriaClienteDTO.nomeCliente = cliente.nomeCliente;
          this.atualizarSituacaoCliente(auditoriaClienteDTO);
          return response;
        });

        return null;
      },
    );
  }

  onSetClienteCobrancaJudicial(cliente: Cliente): void {
    this.dialogService.parecerDialog(
      `Informe o parecer para colocar o cliente ${cliente.pessoa.nomePessoa} na situação de cobrança judicial.`,
      (response: string) => {
        this.dialogService.auditoriaDialog(() => {
          const auditoriaClienteDTO = new AuditoriaClienteDTO();
          auditoriaClienteDTO.clienteId = cliente.id;
          auditoriaClienteDTO.parecer = response;
          auditoriaClienteDTO.situacao = SituacaoCliente.COBRANCA_JUDICIAL;
          auditoriaClienteDTO.postoId = cliente.posto.id;
          auditoriaClienteDTO.postoNome = cliente.posto.descricao;
          auditoriaClienteDTO.nomeCliente = cliente.nomeCliente;
          this.atualizarSituacaoCliente(auditoriaClienteDTO);
          return response;
        });

        return null;
      },
    );
  }

  onSetClienteEmTransferencia(cliente: Cliente): void {
    this.dialogService.parecerDialog(
      `Informe o parecer para colocar o cliente ${cliente.pessoa.nomePessoa} na situação de em transferência.`,
      (response: string) => {
        this.dialogService.auditoriaDialog(() => {
          const auditoriaClienteDTO = new AuditoriaClienteDTO();
          auditoriaClienteDTO.clienteId = cliente.id;
          auditoriaClienteDTO.parecer = response;
          auditoriaClienteDTO.situacao = SituacaoCliente.EM_TRANSFERENCIA;
          auditoriaClienteDTO.postoId = cliente.posto.id;
          auditoriaClienteDTO.postoNome = cliente.posto.descricao;
          auditoriaClienteDTO.nomeCliente = cliente.nomeCliente;
          this.atualizarSituacaoCliente(auditoriaClienteDTO);
          return response;
        });

        return null;
      },
    );
  }

  onChangePostoCliente(cliente: Cliente): void {
    const dialogRef = this.dialog.open(AlterarPostoClienteFormComponent, {
      data: {
        cliente: cliente,
        action: 'ALTERAR_POSTO',
      },
      width: '350px',
    });

    dialogRef.afterClosed().subscribe((dto: ClientePostoDTO) => {
      if (dto) {
        this.appState.isLoading = true;
        this.clienteService.atualizarPostoCliente(dto).subscribe(
          (response: ReturnAPI<Cliente>) => {
            this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
            this.appState.isLoading = false;
            this.listarClientes();
          },
          (e: HttpErrorResponse) => {
            this.appState.isLoading = false;
            this.dialogService.feedbackError(`Houve um erro ao atualizar cliente.\n ${e.message}`);
          },
        );
      }
    });
  }

  atualizarSituacaoCliente(auditoriaClienteDTO: AuditoriaClienteDTO): void {
    this.clienteService.atualizarSituacaoCliente(auditoriaClienteDTO).subscribe(
      (response: ReturnAPI<Cliente>) => {
        this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
        this.listarClientes();
      },
      (e: HttpErrorResponse) => {
        this.dialogService.feedbackError(`Houve um erro ao cancelar contrato.\n ${e.message}`);
      },
    );
  }

  onLoadClientePage(page: number): void {
    this.listarClientes(page);
  }

  onFilter(options: ClienteListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    this.queryOptions.params = {
      postoId,
      nomeCliente: options.nomeCliente ? `${options.nomeCliente}` : null,
      situacaoCliente: options.situacoesCliente?.length ? `in:${options.situacoesCliente.join(',')}` : null,
      rota: options.descricaoRota || null,
      documentoCliente: options.documentoCliente ? options.documentoCliente : null,
      endereco: options.endereco ? options.endereco : null,
      cep: options.cep ? options.cep : null,
      dataCadastroInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataCadastroFinal: options.dataFinal ? options.dataFinal.getTime() : null,
    };
    this.router.navigate(['base/clientes/acompanhamento'], { queryParams: this.queryOptions.params }).then(() => this.listarClientes());
    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }
  }

  onDisclosure(cliente: Cliente): void {
    this.router.navigate(['base/clientes/detail/' + cliente.id]);
  }

  private async onSetClienteBloqueado(cliente: Cliente): Promise<void> {
    let pareceres = [
      'Sem comércio',
      'Comércio incompatível',
      'Crédito não liberado pela análise (Corte)',
      'Cliente não é o proprietário do comércio',
    ];
    let parecer = await this.dialogService.selectDialog(
      `Informe o parecer dessa ação abaixo para bloquear o cliente ${cliente.pessoa.nomePessoa}.`,
      pareceres,
    );

    this.dialogService.auditoriaDialog(() => {
      const auditoriaClienteDTO = new AuditoriaClienteDTO();
      auditoriaClienteDTO.clienteId = cliente.id;
      auditoriaClienteDTO.codigoSituacaoInativacao = parecer[1];
      auditoriaClienteDTO.situacao = SituacaoCliente.BLOQUEADO;
      auditoriaClienteDTO.postoId = cliente.posto.id;
      auditoriaClienteDTO.postoNome = cliente.posto.descricao;
      auditoriaClienteDTO.nomeCliente = cliente.nomeCliente;
      this.atualizarSituacaoCliente(auditoriaClienteDTO);
    });
  }
}
