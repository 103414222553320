import { Resource } from 'src/app/global/domain/resource.model';
import { Caixa } from './caixa.model';

export class AuditoriaConferenciaCaixaGeral extends Resource {
  data: Date;

  valor: number;

  saldoAtualCaixa: number;

  caixa: Caixa;

  funcionarioId: number;

  postoId: number;
}
