import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from 'src/app/App.state';
import { SharedService } from 'src/app/auth/shared.service';
import { Contrato } from 'src/app/base/domain/contrato.model';
import { RelatorioContabilidadeDTO } from 'src/app/base/domain/dto/relatorio-contabilidade-dto';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { ContratoService } from 'src/app/base/services/contrato.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ContratoListFilterOptions } from 'src/app/global/lists/contrato-list/contrato-list.component';
import { DialogService } from 'src/app/global/services/dialog.service';
import * as FileSaver from 'file-saver';
import { Posto } from 'src/app/base/domain/posto.model';
import * as moment from 'moment';

@Component({
  selector: 'app-titulos-novos-contratos',
  templateUrl: './titulos-novos-contratos.component.html',
  styleUrls: ['./titulos-novos-contratos.component.css'],
})
export class TitulosNovosContratosComponent implements OnInit {
  contratos: Contrato[] = [];
  menuAcoes: String[] = [];
  selectAllContrato: boolean = false;

  shared: SharedService;
  usuario: Usuario;
  dataFinal: number;
  dataInicial: number;
  posto: Posto;

  maxDate: Date = null;
  dateReadOnly = true;

  queryOptions = new QueryOptions({
    pageSize: 25,
    params: {
      situacaoContrato:
        'in:EM_ANDAMENTO,FINALIZADO,FINALIZADO_COM_DIVIDA,FINALIZADO_COM_ATRASO,FINALIZADO_COM_ANTECIPACAO,EM_ATRASO,PENDENTE,ANTECIPADO_BORDERO,LIQUIDADO_ADMINISTRACAO,RENEGOCIADO,FINALIZADO_RENEGOCIADO,FINALIZADO_REPACTUADO,LIQUIDADO_ADMINISTRACAO',
      exportado: 'eq: false',
    },
  });
  totalRecords = 0;
  loading = false;

  private postoSelecionado: number = null;

  constructor(
    private router: Router,
    private contratoService: ContratoService,
    private dialogService: DialogService,
    public dialog: MatDialog,
    public appState: AppState,
  ) {
    const shared = SharedService.getInstance();
    this.usuario = shared.usuario;
    this.menuAcoes.push('Exportar Lote');
    this.menuAcoes.push('Visualizar Lote');
  }

  ngOnInit(): void {
    this.getMaxDate();
  }

  listarContratos(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.contratoService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Contrato>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.contratos = response.object.content;
      } else {
        this.contratos = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
    });
  }

  onFilter(options: ContratoListFilterOptions): void {
    this.posto = options.postoSelecionado ? options.postoSelecionado : null;
    const postoId = this.posto ? this.posto.id : null;
    this.queryOptions.params = {
      postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
      dataInicialLiberacao: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinalLiberacao: options.dataFinal ? options.dataFinal.getTime() : null,
      nomeCliente: options.nomeCliente ? options.nomeCliente : null,
      situacaoContrato:
        'in:EM_ANDAMENTO,FINALIZADO,FINALIZADO_COM_DIVIDA,FINALIZADO_COM_ATRASO,FINALIZADO_COM_ANTECIPACAO,EM_ATRASO,PENDENTE,ANTECIPADO_BORDERO,RENEGOCIADO,FINALIZADO_RENEGOCIADO,FINALIZADO_REPACTUADO,LIQUIDADO_ADMINISTRACAO',
      exportado: 'eq: false',
    };

    this.dataFinal = options.dataFinal ? options.dataFinal.getTime() : null;
    this.dataInicial = options.dataInicial ? options.dataInicial.getTime() : null;

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }

    this.listarContratos(0);
  }

  onContratosPage(page: number): void {
    this.listarContratos(page);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  onSelectAll(value: boolean): void {
    this.selectAllContrato = value;
  }

  onExportarLote(contratosSelecionado: Contrato[]): void {
    if (!this.postoSelecionado) {
      this.dialogService.feedbackInfo('Nenhum posto foi selecionado');
    } else if (!this.dataFinal || !this.dataInicial) {
      this.dialogService.feedbackInfo('Data inicial ou final não preenchida');
    } else if (contratosSelecionado.length == 0) {
      this.dialogService.feedbackInfo('Nenhum contrato foi selecionado');
    } else {
      const relatorio: RelatorioContabilidadeDTO = {
        postoId: this.postoSelecionado,
        datas: `${this.dataInicial},${this.dataFinal}`,
        contratos: contratosSelecionado.map((contrato: Contrato) => contrato.id),
        todosContrato: this.selectAllContrato,
      };
      this.appState.isLoading = true;
      this.contratoService.gerarRelatorioContabilidade(relatorio).subscribe((response) => {
        if (response) {
          this.appState.isLoading = false;
          FileSaver.saveAs(
            response,
            `relatorio-contabilidade-${this.posto.descricao}-${moment(new Date(this.dataInicial)).format('DDMMYY')}_${moment(
              new Date(this.dataFinal),
            ).format('DDMMYY')}.zip`,
          );

          this.listarContratos();
        } else {
          this.appState.isLoading = false;
          this.listarContratos();
        }
      });
    }
  }

  onDisclosure(contrato: Contrato): void {
    this.router.navigate(['base/contratos/acompanhamento-contrato-detail/' + contrato.id]);
  }

  onVisualizarLote(): void {
    this.router.navigate(['base/exportacao/titulo-novos-arquivos/']);
  }

  getMaxDate(): void {
    const dayAtMilis = 86400000;
    const todayAtWeek = new Date().getDay();

    const getDays = (quantity) => {
      return quantity * dayAtMilis;
    };

    const dateSubtracted = (milisToSubtract) => {
      return new Date(Date.now() - milisToSubtract);
    };

    switch (todayAtWeek) {
      case 0: // DOM -3
        this.maxDate = dateSubtracted(getDays(3));
        break;
      case 1: // SEG -4
        this.maxDate = dateSubtracted(getDays(4));
        break;
      case 2: // TER -2
      case 3: // QUAR -2
      case 4: // QUIN -2
      case 5: // SEX -2
      case 6: // SAB -2
        this.maxDate = dateSubtracted(getDays(2));
        break;
    }
  }
}
