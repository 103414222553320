import { Component, OnInit } from '@angular/core';
import { CaptadoListFilterOptions } from 'src/app/global/lists/captado-list/captado-list.component';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { CaptadoService } from 'src/app/base/services/captado.service';
import { DialogService } from 'src/app/global/services/dialog.service';
import { Captado } from 'src/app/base/domain/captado.model';
import { MessageAPI, Page, ReturnAPI } from 'src/app/base/domain/return-api.model';

import { CaptadoAcaoEvent } from 'src/app/global/lists/captado-list/captado-list.component';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from 'src/app/auth/shared.service';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { AppState } from 'src/app/App.state';
import { CaptacaoFormComponent } from 'src/app/global/forms/captacao-form/captacao-form.component';
import { VisitaService } from 'src/app/base/services/visita.service';
import { OrigemAcaoDeVendaEnum } from 'src/app/base/domain/enum/origem-acao-de-venda.enum';
import { SituacaoGenerico } from 'src/app/global/widgets/global-filter/global-filter.component';

@Component({
  selector: 'app-acompanhamento-captacao-main',
  templateUrl: './acompanhamento-captacao-main.component.html',
  styleUrls: ['./acompanhamento-captacao-main.component.css'],
})
export class AcompanhamentoCaptacaoMainComponent implements OnInit {
  private static EDIT_CAPTADO = 'Editar Captado';
  static NOVA_ACAO_DE_VENDA = 'Nova Ação de Venda';
  menuAcoes: string[] = [];
  opcoes: string[] = [];
  captados: Captado[] = [];
  queryOptions = new QueryOptions({ pageSize: 10, pageSort: '-dataHoraCadastro' });
  totalRecords = 0;
  loading = false;
  private allSelected = false;
  usuario: Usuario;
  private postoSelecionado: number = null;
  view = 'ACOMPANHAMENTO_CAPTACAO';
  naturezas: SituacaoGenerico[] = [
    {
      valor: 0,
      descricao: 'Normal',
    },
    {
      valor: 1,
      descricao: 'Operação Externa',
    },
  ];

  constructor(
    private captadoService: CaptadoService,
    public dialogService: DialogService,
    public dialog: MatDialog,
    private appState: AppState,
    private visitaService: VisitaService,
  ) {}

  ngOnInit(): void {
    const shared = SharedService.getInstance();
    this.usuario = shared.usuario;

    if (this.usuario.profile === 'ROLE_GESTOR' || this.usuario.profile === 'ROLE_ADMIN') {
      this.queryOptions.params = {
        isUsuarioAdminHeimdall: `true`,
        situacoesCaptado: `in:CAPTADO`,
      };
    } else {
      this.queryOptions.params = {
        idUsuarioHeimdall: `eq:${this.usuario.id}`,
        situacoesCaptado: `in:CAPTADO`,
      };
    }

    if (this.usuario.profile === 'ROLE_GESTOR' || this.usuario.profile === 'ROLE_ADMIN') {
      this.opcoes = ['atualizar', 'filtros', 'select', 'novo', 'menuAcao'];
      this.menuAcoes = [AcompanhamentoCaptacaoMainComponent.NOVA_ACAO_DE_VENDA];
    } else {
      this.opcoes = ['atualizar', 'filtros', 'novo'];
    }
  }

  listarClientesCaptados(pageNumber: number = 0): void {
    this.appState.isLoading = true;
    this.queryOptions.pageNumber = pageNumber + 1;
    this.captadoService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Captado>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.captados = response.object.content;
        this.mapearAcoes(response.object.content);
      } else {
        this.captados = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.appState.isLoading = false;
    });
  }

  mapearAcoes(captados: Captado[]): void {
    const mapearAcoes = (captado: Captado): Captado => {
      const acoes = [];
      acoes.push(AcompanhamentoCaptacaoMainComponent.EDIT_CAPTADO);
      return { ...captado, acoes };
    };

    this.captados = captados?.map(mapearAcoes) ?? [];
  }

  onAcaoClick(event: CaptadoAcaoEvent): void {
    const actions = new Map<string, (captado: Captado) => void>().set(
      AcompanhamentoCaptacaoMainComponent.EDIT_CAPTADO,
      (captado: Captado) => {
        this.onEdit(captado);
      },
    );

    if (this.usuario.profile === 'ROLE_GESTOR' || this.usuario.profile === 'ROLE_ADMIN') {
      actions.set(AcompanhamentoCaptacaoMainComponent.NOVA_ACAO_DE_VENDA, this.cadastrarAcoesDeVenda.bind(this));
    }
    actions.get(event.acao)(event.captado);
  }

  onEdit(captado: Captado): void {
    this.dialog
      .open(CaptacaoFormComponent, {
        data: {
          action: 'update',
          captado: captado,
          usuario: this.usuario,
        },
        width: '40%',
      })
      .afterClosed()
      .subscribe((result: Captado) => {
        if (result) {
          this.captadoService.saveCaptado(result).subscribe(
            (data: ReturnAPI<Captado>) => {
              if (data.success) {
                this.appState.isLoading = false;
                this.dialogService.feedbackSuccess('Operação realizada com sucesso!');
                this.listarClientesCaptados();
              } else {
                this.appState.isLoading = false;
                this.dialogService.feedbackError(data.messages.map((msg: MessageAPI) => msg.text).join(','));
              }
            },
            // tslint:disable-next-line: typedef
            (error) => {
              this.appState.isLoading = false;
              console.error(error);
              this.dialogService.feedbackError('Ocorreu um erro ao editar captado!');
              this.listarClientesCaptados();
            },
          );
        }
      });
  }

  loadCaptadosPage(page: number): void {
    this.listarClientesCaptados(page);
  }

  onFilter(options: CaptadoListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;

    this.queryOptions.params = {
      nomeCliente: options.nomeCaptado ? `like:${options.nomeCaptado}` : null,
      nomeCaptador: options.nomeCaptador ? `${options.nomeCaptador}` : null,
      situacoesCaptado: `in:CAPTADO`,
      dataInicio: options?.dataInicial?.toLocaleString(),
      dataFinal: options?.dataFinal?.toLocaleString(),
      postoId,
      natureza: options.natureza != null && options.natureza.length ? options.natureza.toString() : null,
    };

    if (this.usuario.profile === 'ROLE_GESTOR' || this.usuario.profile === 'ROLE_ADMIN') {
      this.queryOptions.params = { ...this.queryOptions.params, isUsuarioAdminHeimdall: `true` };
    } else {
      this.queryOptions.params = { ...this.queryOptions.params, idUsuarioHeimdall: `eq:${this.usuario.id}` };
    }

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }

    this.listarClientesCaptados();
  }

  onNovo(): void {
    this.dialog
      .open(CaptacaoFormComponent, {
        data: {
          action: 'novo',
          planejamento: null,
          usuario: this.usuario,
        },
        width: '40%',
      })
      .afterClosed()
      .subscribe((result: Captado) => {
        if (result) {
          this.captadoService.saveCaptado(result).subscribe(
            (data: ReturnAPI<Captado>) => {
              if (data.success) {
                this.appState.isLoading = false;
                this.dialogService.feedbackSuccess('Operação realizada com sucesso!');
                this.listarClientesCaptados();
              } else {
                this.appState.isLoading = false;
                this.dialogService.feedbackError(data.messages.map((msg: MessageAPI) => msg.text).join(','));
                this.listarClientesCaptados();
              }
            },
            // tslint:disable-next-line: typedef
            (error) => {
              this.appState.isLoading = false;
              console.error(error);
              this.dialogService.feedbackError('Ocorreu um erro ao criar captado!');
              this.listarClientesCaptados();
            },
          );
        }
      });
  }

  onSelectAll(param: boolean): void {
    this.allSelected = param;
  }

  async cadastrarAcoesDeVenda(_: never): Promise<void> {
    let quantidade = this.totalRecords;

    if (!this.allSelected) {
      quantidade = this.captados.filter((capt: Captado) => capt.selecionado).length;
    }

    const confirm = async (_preAprovar: boolean) => {
      this.appState.isLoading = true;

      try {
        const { joinRota, ...options } = this.queryOptions.params;
        const res = await this.visitaService
          .registrarAcoesDeVenda({
            allSelected: this.allSelected,
            captacao: true,
            preAprovar: _preAprovar,
            clientesIds: null,
            captadosIds: this.allSelected ? [] : this.captados.filter((cap: Captado) => cap.selecionado).map((cap: Captado) => cap.id),
            params: this.allSelected
              ? JSON.stringify({
                  ...options,
                  page: '1',
                  size: this.totalRecords.toString(),
                })
              : '',
            usuarioHeimdallId: this.usuario.id,
            captadorNome: this.usuario.nome,
            origem: OrigemAcaoDeVendaEnum.CAPTADOS,
          })
          .toPromise();
        this.dialogService.feedbackReturnAPI(res, 'Ações de Venda registradas com sucesso');
      } catch (error) {
        this.dialogService.feedbackError('Não foi possível fazer os registros');
      } finally {
        this.appState.isLoading = false;
      }
    };

    this.dialogService.checkboxDialog({
      title: 'Cadastrar Ações de Venda',
      message: `
        Deseja registrar uma ação de venda para os captados selecionados?
        Quantidade de novas ações: ${quantidade}`,
      confirmfn: confirm.bind(this),
    });
  }
}
