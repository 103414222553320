import { Page, ReturnAPI } from './../../../../domain/return-api.model';
import { FuncionarioService } from 'src/app/base/services/funcionario.service';
import { SharedService } from 'src/app/auth/shared.service';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { DialogService } from 'src/app/global/services/dialog.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashBoardCobrancaService } from 'src/app/base/services/dashboard-cobranca.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { DoughnutChartTemplate } from './../../../../../global/components/graficos/doughnut-chart/doughnut-chart.component';
import { AcompanhamentoCobrancasTurnoDTO } from 'src/app/base/domain/dto/acompanhamento-cobrancas-turno.dto';
import { AcompanhamentoCobrancaTurnoUnitarioDTO } from 'src/app/base/domain/dto/acompanhamento-cobrancas-turno-unitario.dto';
import { AppState } from 'src/app/App.state';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { Funcionario } from 'src/app/base/domain/funcionario.model';

@Component({
  selector: 'app-acompanhamento-cobranca-turno-estadual',
  templateUrl: './acompanhamento-cobranca-turno-estadual.component.html',
  styleUrls: ['./acompanhamento-cobranca-turno-estadual.component.css']
})
export class AcompanhamentoCobrancaTurnoEstadualComponent implements OnInit {
  @ViewChild('acordion') acordion: MatAccordion;

  filterOptions = { dataInicial: new Date(), dataFinal: new Date() };

  postos: AcompanhamentoCobrancasTurnoDTO[] = [];

  rgbaColors: string[] = [
    'rgba(255, 0, 0, 0.4)',
    'rgba(255, 100, 0, 0.4)',
    'rgba(255, 220, 0, 0.4)',
    'rgba(110, 225, 0, 0.4)',
    'rgba(95, 210, 215, 0.4)',
    'rgba(10, 55, 235, 0.4)'
  ];

  rgbColors: string[] = [
    'rgb(255, 0, 0)',
    'rgb(255, 100, 0)',
    'rgb(255, 220, 0)',
    'rgb(110, 225, 0)',
    'rgb(95, 210, 215)',
    'rgb(10, 55, 235)'
  ];

  estadoId: number;
  estado: string;

  selecionado: string;

  colorIndex: number;
  datas: string[];

  templateClientes: DoughnutChartTemplate;
  clientes: { total: number, manha: number, tarde: number, noite: number } = { total: 0, manha: 0, tarde: 0, noite: 0 };

  templateCobrados: DoughnutChartTemplate;
  cobrados: { total: number, manha: number, tarde: number, noite: number } = { total: 0, manha: 0, tarde: 0, noite: 0 };

  usuario: Usuario;

  constructor(
    route: ActivatedRoute,
    private service: DashBoardCobrancaService,
    private router: Router,
    private appState: AppState,
    private dialogService: DialogService,
    private funcionarioService: FuncionarioService) {
    this.usuario = SharedService.getInstance().usuario;

    route.queryParams.subscribe((params: { [key: string]: string }) => {
      if ('idx' in params) {
        this.colorIndex = parseInt(params['idx'], null);
      }
      if ('datas' in params) {
        this.datas = params['datas'].split(',');
        this.filterOptions.dataInicial = new Date(parseInt(this.datas[0], null));
        this.filterOptions.dataFinal = new Date(parseInt(this.datas[1], null));
      }
      if ('estado' in params) {
        this.estado = params['estado'];
      }
    });
    route.params.subscribe((params: { [key: string]: string }) => {
      if ('estadoId' in params) {
        this.estadoId = parseInt(params['estadoId'], null);
      }
    });
  }

  ngOnInit(): void {
    this.openStarterDashboard(true);
  }

  ngAfterViewChecked(): void {
    document.querySelector<HTMLDivElement>('div.card-icon').style.background = this.rgbColors[this.colorIndex];
  }

  openStarterDashboard(initial: boolean): void {
    if (initial) {
      this.appState.isLoading = true;
    }
    const options: QueryOptions = new QueryOptions({
      params: {
        estado: this.estadoId,
        datas: `${this.filterOptions.dataInicial.getTime()},${this.filterOptions.dataFinal.getTime()}`
      }
    });
    this.service.getDashBoardTurnoSintetico(options).subscribe((response: AcompanhamentoCobrancasTurnoDTO) => {
      if (response) {
        this.templateClientes = {
          canvas: 'Clientes por Turno',
          elementIndex: 0,
          label: 'Clientes por Turno',
          heigth: 80,
          maxHeigth: '150px',
          data: [response.clientesManha, response.clientesTarde, response.clientesNoite],
          dataLabels: ['Manhã', 'Tarde', 'Noite'],
        };

        this.clientes.total = response.clientesManha + response.clientesTarde + response.clientesNoite;
        this.clientes.manha = response.clientesManha;
        this.clientes.tarde = response.clientesTarde;
        this.clientes.noite = response.clientesNoite;

        this.templateCobrados = {
          canvas: 'Cobranças por Turno',
          elementIndex: 0,
          label: 'Cobranças por Turno',
          heigth: 80,
          maxHeigth: '150px',
          data: [response.cobradosManha, response.cobradosTarde, response.cobradosNoite],
          dataLabels: ['Manhã', 'Tarde', 'Noite'],
        };

        this.cobrados.total = response.cobradosManha + response.cobradosTarde + response.cobradosNoite;
        this.cobrados.manha = response.cobradosManha;
        this.cobrados.tarde = response.cobradosTarde;
        this.cobrados.noite = response.cobradosNoite;
      }

      if (initial) {
        this.dashboardEstadual();
      }
    });
  }

  dashboardEstadual(): void {
    const options: QueryOptions = new QueryOptions({
      params: {
        estado: this.estadoId,
        datas: `${this.filterOptions.dataInicial.getTime()},${this.filterOptions.dataFinal.getTime()}`
      }
    });
    this.service.getDashBoardTurnoEstadual(options, this.estadoId).subscribe((response: AcompanhamentoCobrancasTurnoDTO[]) => {
      if (response) {
        if (this.usuario.profile === 'ROLE_FISCAL') {
          const formatedCpf = `${this.usuario.cpf.substr(0, 3)}.${this.usuario.cpf.substr(3, 3)}.${this.usuario.cpf.substr(6, 3)}-${this.usuario.cpf.substr(9, 2)}`;
          const opt = new QueryOptions({ pageNumber: 1, params: { cpf: formatedCpf } });
          this.funcionarioService.findAll(opt).subscribe((funcionarioPage: ReturnAPI<Page<Funcionario>>) => {
            const postoId = funcionarioPage.object.content[0].posto.id;
            this.postos = response.filter((o: AcompanhamentoCobrancasTurnoDTO) => o.postoId === postoId);
            this.openPosto(this.postos[0]);
          });
        } else {
          this.postos = response;
        }
      }
      this.appState.isLoading = false;
    });
  }

  openPosto(posto: AcompanhamentoCobrancasTurnoDTO): void {
    this.selecionado = posto.posto;

    this.templateClientes = {
      canvas: 'Clientes por Turno',
      elementIndex: 0,
      label: 'Clientes por Turno',
      heigth: 80,
      maxHeigth: '150px',
      data: [posto.clientesManha, posto.clientesTarde, posto.clientesNoite],
      dataLabels: ['Manhã', 'Tarde', 'Noite'],
    };
    this.templateCobrados = {
      canvas: 'Cobranças por Turno',
      elementIndex: 0,
      label: 'Cobranças por Turno',
      heigth: 80,
      maxHeigth: '150px',
      data: [posto.cobradosManha, posto.cobradosTarde, posto.cobradosNoite],
      dataLabels: ['Manhã', 'Tarde', 'Noite'],
    };

    this.clientes.total = posto.clientesManha + posto.clientesTarde + posto.clientesNoite;
    this.clientes.manha = posto.clientesManha;
    this.clientes.tarde = posto.clientesTarde;
    this.clientes.noite = posto.clientesNoite;

    this.cobrados.total = posto.cobradosManha + posto.cobradosTarde + posto.cobradosNoite;
    this.cobrados.manha = posto.cobradosManha;
    this.cobrados.tarde = posto.cobradosTarde;
    this.cobrados.noite = posto.cobradosNoite;
  }

  closePosto(posto: AcompanhamentoCobrancasTurnoDTO): void {
    if (this.selecionado === posto.posto && this.usuario.profile !== 'ROLE_FISCAL') {
      this.selecionado = null;
      this.openStarterDashboard(false);
    }
  }

  onAplicarFiltro(): void {
    if (this.selecionado) {
      this.acordion.multi = true;
      this.acordion.closeAll();
      this.acordion.multi = false;
    }
    this.selecionado = null;
    this.openStarterDashboard(true);
  }

  onLimparFiltros(): void {
    this.filterOptions.dataInicial = new Date();
    this.filterOptions.dataFinal = new Date();

    this.onAplicarFiltro();
  }

  onAtualizar(): void {
    this.appState.isLoading = true;
    this.service.refreshViewCobrancasTurno().subscribe((response: boolean) => {
      if (response) {
        this.onAplicarFiltro();
      } else {
        this.dialogService.feedbackError(
          'Ocorreu um erro ao atualizar dados'
          + ' Tente novamente mais tarde!'
        );
      }
      this.appState.isLoading = false;
    });
  }

  navigateToDetails(rota: AcompanhamentoCobrancaTurnoUnitarioDTO): void {
    this.router.navigate([`base/gestao/acompanhamento-cobranca/turno-cobranca/${rota.estadoId}/${rota.postoId}/${rota.rota}`],
      {
        queryParams:
        {
          datas: `${this.filterOptions.dataInicial.getTime()},${this.filterOptions.dataFinal.getTime()}`
        }
      }
    );
  }
}
