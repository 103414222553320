import { Component, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { CardListComponent } from '../../components/card-list/card-list.component';
import { NgForm } from '@angular/forms';
import { Table } from 'primeng/table';
import { Parcela } from 'src/app/base/domain/parcela.model';

@Component({
  selector: 'app-parcela-list',
  templateUrl: './parcela-list.component.html',
  styleUrls: ['./parcela-list.component.css'],
})
export class ParcelaListComponent implements OnChanges {
  @ViewChild('searchForm', { static: false }) searchForm: NgForm;
  @ViewChild('dataTable', { static: false }) dataTable: Table;
  @ViewChild(CardListComponent, { static: true }) cardList: CardListComponent;

  @Input() parcelas: Parcela[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['parcelas']) {
      this.parcelas?.sort((p1: Parcela, p2: Parcela) => p1.ordem - p2.ordem);
    }
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
