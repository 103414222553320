import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppState } from 'src/app/App.state';
import { DashBoardEstadoFaturamento } from 'src/app/base/domain/dashboard-estado-faturamento.model';
import { DashBoardFaturamento } from 'src/app/base/domain/dashboard-faturamento';
import { PostoDTO } from 'src/app/base/domain/dto/posto.dto';
import { DashBoardFaturamentoService } from 'src/app/base/services/dashboard-faturamento.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { DashBoardFilterOptions } from 'src/app/global/faturamento-dashboard/faturamento-dashboard.component';

@Component({
  selector: 'app-acompanhamento-estado-faturamento',
  templateUrl: './acompanhamento-estado-faturamento.component.html',
  styleUrls: ['./acompanhamento-estado-faturamento.component.css'],
})
export class AcompanhamentoEstadoFaturamentoComponent implements OnInit {
  dashboard: DashBoardFaturamento = new DashBoardFaturamento();

  data = new Date();
  estadoId: number;
  nomeEstado = '';
  title = '';

  dashboardEstado: DashBoardEstadoFaturamento = new DashBoardEstadoFaturamento();
  postoList: PostoDTO[] = [];

  queryOptions = new QueryOptions({ pageSize: 10 });

  constructor(
    private service: DashBoardFaturamentoService,
    private router: Router,
    private route: ActivatedRoute,
    public appState: AppState,
  ) {
    this.route.params.subscribe((params: Params) => {
      this.estadoId = params.id;
    });

    this.nomeEstado = this.route.snapshot.queryParamMap.get('nomeEstado').toString();
  }

  ngOnInit(): void {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    this.data = date;
    this.queryOptions.params = {
      dataInicial: this.route.snapshot.queryParamMap.get('dataInicial')
        ? this.route.snapshot.queryParamMap.get('dataInicial')
        : date.getTime(),
      dataFinal: this.route.snapshot.queryParamMap.get('dataFinal') ? this.route.snapshot.queryParamMap.get('dataFinal') : date.getTime(),
      estadoId: this.estadoId,
    };

    this.getDashBoard();
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  onReload(): void {
    this.data.setHours(0, 0, 0, 0);
    this.appState.isLoading = true;
    this.service.getDashBoard(this.queryOptions).subscribe((response: DashBoardFaturamento) => {
      this.dashboard = response;
      this.title = `Acompanhamento do Faturamento do ${this.nomeEstado}.`;
      this.getDashBoardEstado();
    });
  }

  getDashBoard(): void {
    this.data.setHours(0, 0, 0, 0);
    this.appState.isLoading = true;
    this.service.getDashBoardWithoutRefresh(this.queryOptions).subscribe((response: DashBoardFaturamento) => {
      this.dashboard = response;
      this.title = `Acompanhamento do Faturamento do ${this.nomeEstado}.`;
      this.getDashBoardEstado();
    });
  }

  getDashBoardEstado(): void {
    this.service.getDashboardEstado(this.estadoId).subscribe((response: DashBoardEstadoFaturamento) => {
      this.dashboardEstado = response;
      this.postoList = this.dashboardEstado.postos;
      this.appState.isLoading = false;
    });
  }

  onDisclosure(dashboard: PostoDTO): void {
    this.queryOptions.params = {
      ...this.queryOptions.params,
      descricaoPosto: dashboard.descricaoPosto ? dashboard.descricaoPosto : null,
    };

    this.router.navigate(['base/gestao/faturamento/acompanhamento-posto-faturamento/' + dashboard.id], { queryParams: this.queryOptions.params });
  }

  onFilter(options: DashBoardFilterOptions): void {
    this.queryOptions.params = {
      postoId: options.postoSelecionado ? options.postoSelecionado.id : null,
      estadoId: options.estadoSelecionado ? options.estadoSelecionado.id : this.estadoId,
      dataInicial: options.dataInicial ? options.dataInicial.getTime() : null,
      dataFinal: options.dataFinal ? options.dataFinal.getTime() : null,
    };

    this.getDashBoard();
  }
}
