import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { DashboardDespesa } from '../domain/dashboard-despesa.model';
import { DashBoardEstadoFaturamento } from '../domain/dashboard-estado-faturamento.model';
import { DashBoardDespesaSerializer } from '../serializables/dashboard-despesa.serializer';

@Injectable()
export class DashBoardDespesaService extends ResourceService<DashboardDespesa> {
  static RESOURCE = 'dashboard-despesas';

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${DashBoardDespesaService.RESOURCE}`, new DashBoardDespesaSerializer(DashboardDespesa));
  }

  getDashboard(queryOption: QueryOptions): Observable<DashboardDespesa> {
    return this.httpClient.get<DashboardDespesa>(
      `${WEB_API}/${DashBoardDespesaService.RESOURCE}/dashboard?${queryOption.toQueryString()}`,
    );
  }

  getDashboardWithoutRefresh(queryOption: QueryOptions): Observable<DashboardDespesa> {
    return this.httpClient.get<DashboardDespesa>(
      `${WEB_API}/${DashBoardDespesaService.RESOURCE}/dashboard-without-refresh?${queryOption.toQueryString()}`,
    );
  }

  getDashboardEstado(estadoId: number): Observable<DashBoardEstadoFaturamento> {
    return this.httpClient.get<DashBoardEstadoFaturamento>(
      `${WEB_API}/${DashBoardDespesaService.RESOURCE}/dashboard/estado/${estadoId}`,
    );
  }

  getDashboardEstadoFaturamento(): Observable<DashBoardEstadoFaturamento[]> {
    return this.httpClient.get<DashBoardEstadoFaturamento[]>(
      `${WEB_API}/${DashBoardDespesaService.RESOURCE}/resumo-estado`,
    );
  }
}
