import { CommonModule } from '@angular/common';
import { GlobalModule } from 'src/app/global/global.module';
import { NgModule } from '@angular/core';
import { EquipesMainComponent } from './equipes-main/equipes-main.component';
import { EquipesDetailComponent } from './equipes-detail/equipes-detail.component';

@NgModule({
  declarations: [EquipesMainComponent, EquipesDetailComponent],
  imports: [CommonModule, GlobalModule],
})
export class EquipeModule {}
