import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker/datepicker-input-base';
import { Moment } from 'moment';
import { Periodicidade } from '../../../base/domain/enum/periodocidade.enum';

interface DataType {
  contrato: any;
  qtdParcelas: number;
  valorAcordado: number;
  periodicidade: Periodicidade;
  dataLiberacao: Date;
  dataVencimento: Date;
  taxaJuros: number;
  repactuacaoDD: boolean;
}

@Component({
  selector: 'app-repactuacao-contrato-form',
  templateUrl: './repactuacao-contrato-form.component.html',
  styleUrls: ['./repactuacao-contrato-form.component.css'],
})
export class RepactuacaoContratoFormComponent implements OnInit {
  title = '';
  valorMinimo: number;
  minDate = new Date();

  periodicidade = Object.values(Periodicidade);

  constructor(public dialogRef: MatDialogRef<RepactuacaoContratoFormComponent>, @Inject(MAT_DIALOG_DATA) public data: DataType) {
    this.minDate.setDate(new Date().getDate() + 1);
    this.data.taxaJuros =
      this.data.contrato && this.data.contrato.visita && this.data.contrato.visita.taxaJuros ? this.data.contrato.visita.taxaJuros : 25;
  }

  ngOnInit(): void {
    this.valorMinimo = this.data.contrato.saldoDevedor;
  }

  onConfirm(): void {
    this.dialogRef.close(Object.assign(this.data));
  }

  onPickerEvent(event: MatDatepickerInputEvent<Moment>): void {
    this.data.dataLiberacao = event.value?.toDate() || null;
  }

  onVencimentoPickerEvent(event: MatDatepickerInputEvent<Moment>): void {
    this.data.dataVencimento = event.value?.toDate() || null;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  weekendsDatesFilter = (d: Date | null): boolean => {
    const date = d ? new Date(d) : new Date();
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };
}
