import { Resource } from 'src/app/global/domain/resource.model';
import { Cargo } from './cargo.model';
import { Pessoa } from './pessoa.model';
import { Posto } from './posto.model';
import { Rota } from './rota.model';

export class Funcionario extends Resource {
  public id: number;
  public apelido: string;

  public pessoa: Pessoa;
  public posto: Posto;
  public rota?: Rota;
  public cargo?: Cargo;

  public bloquearLiberarSaida: boolean;

  constructor() {
    super();
  }
}
