import { AppState } from './../../../../App.state';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardCrecimentoSustentavel } from 'src/app/base/domain/dashboard-crescimento-sustentavel.model';
import { DashBoardEstadoFaturamento } from 'src/app/base/domain/dashboard-estado-faturamento.model';
import { DashboardCrescimentoSustentavelService } from 'src/app/base/services/dashboard-crescimento-sustentavel.service';
import { DashBoardCrescimentoSustentavelFilterOptions } from 'src/app/global/crescimento-sustentavel-dashboard/crescimento-sustentavel-dashboard.component';
import { QueryOptions } from 'src/app/global/domain/query.options';

@Component({
  selector: 'app-crescimento-sustentavel-main',
  templateUrl: './crescimento-sustentavel-main.component.html',
  styleUrls: ['./crescimento-sustentavel-main.component.css']
})
export class CrescimentoSustentavelMainComponent implements OnInit {

  queryOptions = new QueryOptions({ pageSize: 10 });

  dashboard: DashboardCrecimentoSustentavel = new DashboardCrecimentoSustentavel();
  list: DashBoardEstadoFaturamento[] = [];

  constructor(
    private service: DashboardCrescimentoSustentavelService,
    private router: Router,
    private appState: AppState
  ) { }

  ngOnInit(): void {
    this.getDashBoard();
    this.getDashBoardEstado();
  }

  getDashBoard(): void {
    this.appState.isLoading = true;
    this.service.getDashboard(this.queryOptions).subscribe(
      (response: DashboardCrecimentoSustentavel) => {
        this.dashboard = response;
        this.appState.isLoading = false;
      }
    );
  }

  getDashBoardWithoutRefresh(): void {
    this.appState.isLoading = true;
    this.service.getDashboardWithoutRefresh(this.queryOptions).subscribe(
      (response: DashboardCrecimentoSustentavel) => {
        this.dashboard = response;
        this.appState.isLoading = false;
      }
    );
  }

  getDashBoardEstado(): void {
    this.service.getDashboardEstadoSustentavel().subscribe(
      (response: DashBoardEstadoFaturamento[]) => {
        this.list = response;
      }
    );
  }

  onFilter(event: DashBoardCrescimentoSustentavelFilterOptions): void {
    this.queryOptions.params = {
      ...this.queryOptions.params,
      dataCadastro: event.dataInicial.getTime()
    };
    this.getDashBoardWithoutRefresh();
  }

  onReload(): void {
    this.getDashBoard();
  }

  onDisclosure(event: DashBoardEstadoFaturamento): void {
    this.router.navigate(['base/gestao/crescimento-sustentavel-estado/' + event.estadoId]);
  }
}
