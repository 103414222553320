import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatMenuModule } from '@angular/material/menu';
import { GalleriaModule } from 'primeng/galleria';
import { LoginComponent } from '../../auth/login/login.component';
import { ButtonsComponent } from './buttons/buttons.component';
import { CardBoxToolsComponent } from './card-box/card-box-tools/card-box-tools.component';
import { CardBoxComponent } from './card-box/card-box.component';
import { ToolsActionsComponent } from './card-box/tools-actions/tools-actions.component';
import { CardListComponent } from './card-list/card-list.component';
import { CheckboxSnComponent } from './checkbox-sn/checkbox-sn.component';
import { ComponentsRoutes } from './components.routing';
import { GaleryComponent } from './galery/galery.component';
import { GridSystemComponent } from './grid/grid.component';
import { IconsComponent } from './icons/icons.component';
import { InputComponent } from './input/input.component';
import { InputDirective } from './input/input.directive';
import { MessageContainerComponent } from './message/message-container/message-container.component';
import { MessageComponent } from './message/message.component';
import { MessageService } from './message/message.service';
import { NotificationsComponent } from './notifications/notifications.component';
import { PanelsComponent } from './panels/panels.component';
import { SmallBoxComponent } from './small-box/small-box.component';
import { TypographyComponent } from './typography/typography.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ComponentsRoutes),
    FormsModule,
    MatMenuModule,
    GalleriaModule
  ],
  declarations: [
    ButtonsComponent,
    GridSystemComponent,
    IconsComponent,
    NotificationsComponent,
    PanelsComponent,
    TypographyComponent,
    SmallBoxComponent,
    CardBoxComponent,
    CardBoxToolsComponent,
    MessageContainerComponent,
    MessageComponent,
    InputDirective,
    InputComponent,
    ToolsActionsComponent,
    CardListComponent,
    CheckboxSnComponent,
    LoginComponent,
    GaleryComponent
  ],
  exports: [
    FormsModule,
    SmallBoxComponent,
    CardBoxComponent,
    CardBoxToolsComponent,
    MessageContainerComponent,
    InputComponent,
    InputDirective,
    ToolsActionsComponent,
    CardListComponent,
    CheckboxSnComponent,
    LoginComponent,
    CardListComponent,
    GaleryComponent
  ],
  providers: [MessageService],
})
export class ComponentsModule { }
