import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalModule } from 'src/app/global/global.module';
import { AcompanhamentoSangriaComponent } from './acompanhamento-sangria-main/acompanhamento-sangria.component';
import { AcompanhamentoSangriaDetailComponent } from './acompanhamento-sangria-detail/acompanhamento-sangria-detail.component';

@NgModule({
  declarations: [AcompanhamentoSangriaComponent, AcompanhamentoSangriaDetailComponent],
  imports: [CommonModule, GlobalModule],
})
export class AcompanhamentoSangriaModule {}
