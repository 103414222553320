import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalModule } from 'src/app/global/global.module';
import { AuditoriaDespesaMainComponent } from './auditoria-despesa-main/auditoria-despesa-main.component';

@NgModule({
  declarations: [AuditoriaDespesaMainComponent],
  imports: [CommonModule, GlobalModule],
})
export class AuditoriaDespesaModule {}
