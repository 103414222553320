import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { ConciliacaoBancariaDinheiro } from '../domain/conciliacao-bancaria-dinheiro.model';
import { Produto } from '../domain/produto.model';
import { Page } from '../domain/return-api.model';
import { ConciliacaoBancariaDinheiroSerializer } from '../serializables/conciliacao-bancaria-dinheiro.serializer';
@Injectable()
export class ConciliacaoBancariaDinheiroService extends ResourceService<ConciliacaoBancariaDinheiro> {
  private static RESOURCE = 'conciliacao-bancaria-dinheiro';

  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(
      http,
      `${WEB_API}`,
      `${ConciliacaoBancariaDinheiroService.RESOURCE}`,
      new ConciliacaoBancariaDinheiroSerializer(ConciliacaoBancariaDinheiro),
    );
  }

  findAllPageable(queryOptions: QueryOptions): Observable<Page<ConciliacaoBancariaDinheiro>> {
    return this.http.get<Page<ConciliacaoBancariaDinheiro>>(
      `${WEB_API}/${ConciliacaoBancariaDinheiroService.RESOURCE}/pageable?${queryOptions.toQueryString()}`,
    );
  }

  associar(data: {}): Observable<any> {
    return this.http.post<any>(`${WEB_API}/${ConciliacaoBancariaDinheiroService.RESOURCE}/associar`, data);
  }

  getValorTotal(queryOptions: QueryOptions): Observable<any> {
    return this.http.get<number>(
      `${WEB_API}/${ConciliacaoBancariaDinheiroService.RESOURCE}/get-valor-total?${queryOptions.toQueryString()}`,
    );
  }
}
