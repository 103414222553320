import { Component, OnInit } from '@angular/core';
import { ClienteListFilterOptions, ClienteMenuAcaoEvent, ClienteAcaoEvent } from 'src/app/global/lists/cliente-list/cliente-list.component';
import { Cliente } from 'src/app/base/domain/cliente.model';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { AppState } from 'src/app/App.state';
import { DialogService } from 'src/app/global/services/dialog.service';
import { ClienteService } from 'src/app/base/services/cliente.service';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { CadastrarPequenaCausaFormComponent } from 'src/app/global/forms/cadastrar-pequena-causa-form/cadastrar-pequena-causa-form.component';
import { CobrancaJudicial } from 'src/app/base/domain/cobranca-judicial.model';
import { HttpErrorResponse } from '@angular/common/http';
import { CobrancaJudicialService } from 'src/app/base/services/cobranca-judicial.service';

@Component({
  selector: 'app-cliente-cobranca-judicial-main',
  templateUrl: './cliente-cobranca-judicial-main.component.html',
  styleUrls: ['./cliente-cobranca-judicial-main.component.css'],
})
export class ClienteCobrancaJudicialMainComponent implements OnInit {

  private static CADASTRAR_PEQUENA_CAUSA_ACTION = 'Cadastrar pequena causa';

  clientes: Cliente[] = [];
  queryOptions = new QueryOptions({
    pageSize: 25,
    params: {
      situacaoCliente: `in:COBRANCA_JUDICIAL`,
    },
  });
  totalRecords = 0;
  loading = false;

  private postoSelecionado: number = null;

  menuAcoes: string[] = [];

  constructor(
    public appState: AppState,
    public dialogService: DialogService,
    private clienteService: ClienteService,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cobrancaJudicialService: CobrancaJudicialService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.snapshot.queryParams = this.queryOptions.params;
  }

  listarClientes(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.queryOptions.pageSort = 'nomeCliente';
    this.loading = true;
    this.queryOptions.params = { ...this.activatedRoute.snapshot.queryParams, joinRota: true };
    this.clienteService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<Cliente>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.clientes = response.object.content;
        this.mapearAcoes(response.object.content);
      } else {
        this.clientes = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
    });
  }

  onLoadClientePage(page: number): void {
    this.listarClientes(page);
  }

  mapearAcoes(clientes: Cliente[]): void {
    const mapearAcoes = (cliente: Cliente): Cliente => {
      const acoes = [];
      acoes.push(ClienteCobrancaJudicialMainComponent.CADASTRAR_PEQUENA_CAUSA_ACTION);
      return { ...cliente, acoes };
    };

    this.clientes = clientes?.map(mapearAcoes) ?? [];
  }

  onDisclosure(cliente: Cliente): void {
    this.router.navigate(['base/clientes/detail/' + cliente.id]);
  }

  onAcaoClick(event: ClienteAcaoEvent): void {
    const actions = new Map<string, (cliente: Cliente) => void>()
      .set(ClienteCobrancaJudicialMainComponent.CADASTRAR_PEQUENA_CAUSA_ACTION, (cliente: Cliente) => {
        this.cadastrarPequenaCausa(cliente);
      });
    actions.get(event.acao)(event.cliente);
  }

  onMenuAcaoClick(event: ClienteMenuAcaoEvent): void {
    const actions = new Map<string, () => void>();
    actions.get(event.acao)();
  }


  cadastrarPequenaCausa(cliente: Cliente): void {
    const dialogRef = this.dialog.open(CadastrarPequenaCausaFormComponent, {
      data: {
        cliente,
        action: 'novo'
      },
      width: '750px',
    });

    dialogRef.afterClosed().subscribe((cobrancaJudicial: CobrancaJudicial) => {
      this.appState.isLoading = true;
      if (cobrancaJudicial) {
        this.cobrancaJudicialService.cadastrarCobrancaJudicial(cobrancaJudicial).subscribe(
          (response: ReturnAPI<CobrancaJudicial>) => {
            this.dialogService.feedbackReturnAPI(response, 'Operação realizada com sucesso.');
            this.appState.isLoading = false;
            this.listarClientes();
          },
          (e: HttpErrorResponse) => {
            this.appState.isLoading = false;
            this.dialogService.feedbackError(`Houve um erro ao cadastrar pequena causa.\n ${e.message}`);
          },
        );
      } else {
        this.appState.isLoading = false;
      }
    });
  }

  onFilter(options: ClienteListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    this.queryOptions.params = {
      nomeCliente: options.nomeCliente ? `${options.nomeCliente}` : null,
      postoId,
      rota: options.descricaoRota || null,
      documentoCliente: options.documentoCliente ? options.documentoCliente : null,
      situacaoCliente: `in:COBRANCA_JUDICIAL`,
    };

    this.router.navigate(['/base/clientes/cliente-cobranca-judicial'], { queryParams: this.queryOptions.params }).then(() => this.listarClientes());

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }
  }
}
