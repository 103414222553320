import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { AtividadeComercial } from '../domain/atividade-comercial.model';
import { Produto } from '../domain/produto.model';
import { Page, ReturnAPI } from '../domain/return-api.model';
import { AtividadeComercialSerializer } from '../serializables/atividade-comercial.serializer';

@Injectable()
export class AtividadeComercialService extends ResourceService<AtividadeComercial> {
  static RESOURCE = 'atividade-comercial';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, AtividadeComercialService.RESOURCE, new AtividadeComercialSerializer(AtividadeComercial));
  }

  findAll(queryOption: QueryOptions): Observable<ReturnAPI<Page<AtividadeComercial>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<AtividadeComercial>>>(
      `${WEB_API}/${AtividadeComercialService.RESOURCE}/findAll?${queryOption.toQueryString()}&${this.produtoId}`,
    );
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }
}
