import { Component } from '@angular/core';
import { Contrato } from 'src/app/base/domain/contrato.model';
import { ActivatedRoute, Params } from '@angular/router';
import { ContratoService } from 'src/app/base/services/contrato.service';
import { ReturnAPI } from 'src/app/base/domain/return-api.model';

@Component({
  selector: 'app-contrato-em-finalizacao-detail',
  templateUrl: './contrato-em-finalizacao-detail.component.html',
  styleUrls: ['./contrato-em-finalizacao-detail.component.css'],
})
export class ContratoEmFinalizacaoDetailComponent {
  contratoID: number;
  contrato: Contrato;

  constructor(private route: ActivatedRoute, private contratoService: ContratoService) {
    this.route.params.subscribe((params: Params) => {
      this.contratoID = params.id;
    });
  }

  ngOnInit(): void {
    this.contratoService.findOne(this.contratoID).subscribe((response: ReturnAPI<Contrato>) => {
      if (response.success) {
        this.contrato = response.object;
      }
    });
  }
}
