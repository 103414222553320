import { Component, OnInit } from '@angular/core';
import { Posto } from 'src/app/base/domain/posto.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/global/services/dialog.service';
import { AppState } from 'src/app/App.state';
import { PostoService } from 'src/app/base/services/posto.service';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ReturnAPI, Page } from 'src/app/base/domain/return-api.model';
import { ListFilterOptions } from 'src/app/global/lists/posto-list/posto-list.component';

@Component({
  selector: 'app-acompanhamento-caixas-postos-main',
  templateUrl: './acompanhamento-caixas-postos-main.component.html',
  styleUrls: ['./acompanhamento-caixas-postos-main.component.css'],
})
export class AcompanhamentoCaixasPostosMainComponent implements OnInit {
  private queryOptions = new QueryOptions({ pageSize: 10, pageSort: '-id' });

  postos: Posto[];
  totalRecords: number;
  loading = false;

  postoSelecionado: number;

  constructor(private service: PostoService, public dialog: MatDialog, public dialogService: DialogService, public appState: AppState) {}

  ngOnInit(): void {
    this.list();
  }

  onLoad(event: number): void {
    this.list(event);
  }

  list(page: number = 0): void {
    this.queryOptions.pageNumber = page + 1;
    this.loading = true;
    this.service.findPostosGeralNaoAberto(this.queryOptions).subscribe((response: ReturnAPI<Page<Posto>>) => {
      this.totalRecords = response.object.totalElements;
      this.postos = response.object.content;
      this.loading = false;
    });
  }

  onFilter(options: ListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;

    this.queryOptions.params = {
      ...this.queryOptions.params,
      postoId,
    };

    this.list();

    if (postoId !== this.postoSelecionado) {
      this.postoSelecionado = postoId;
    }
  }
}
