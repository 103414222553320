import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '../../services/dialog.service';
import { ContaCliente } from 'src/app/base/domain/conta-cliente.model';

@Component({
  selector: 'app-conta-cliente-info',
  templateUrl: './conta-cliente-info.component.html',
  styleUrls: ['./conta-cliente-info.component.css'],
})
export class ContaClienteInfoComponent implements OnInit {
  @Input() contaCorrente: ContaCliente;
  @Input() tipoContas: string[];

  constructor(public dialog: MatDialog, public dialogService: DialogService) {}

  ngOnInit(): void {}

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
