import { Equipe } from '../equipe.model';
import { Posto } from '../posto.model';
import { Rota } from '../rota.model';

export class TransferirRotaDTO {
  rotas: Rota[];
  postoDestino: Posto;
  postoOrigem: Posto;
  equipeDestino: Equipe;
  equipeOrigem: Equipe;
  migracaoCompleta: boolean;
}
