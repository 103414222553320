import { DialogService } from './../../services/dialog.service';
import { Component, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QueryOptions } from '../../domain/query.options';
import { Captado } from 'src/app/base/domain/captado.model';
import { MatSelectChange } from '@angular/material/select';
import { Posto } from 'src/app/base/domain/posto.model';
import { PostoService } from 'src/app/base/services/posto.service';
import { PlanejamentoSemanaCaptacaoService } from 'src/app/base/services/planejamento-semana-captacao.service';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { EquipeService } from 'src/app/base/services/equipe.service';
import { Equipe } from 'src/app/base/domain/equipe.model';
import { Funcionario } from 'src/app/base/domain/funcionario.model';
import { FuncionarioService } from 'src/app/base/services/funcionario.service';
import { AtividadeComercialService } from 'src/app/base/services/atividade-comercial.service';
import { AtividadeComercial } from 'src/app/base/domain/atividade-comercial.model';
import { Telefone } from 'src/app/base/domain/telefone.model';
import { Endereco } from 'src/app/base/domain/endereco.model';
import { PublicService } from 'src/app/base/services/public.service';
import { Estado } from 'src/app/base/domain/estado.model';
import { Municipio } from 'src/app/base/domain/municipio.model';
import { Bairro } from 'src/app/base/domain/bairro.model';
import { NgForm, NgModel } from '@angular/forms';
import { EnderecoAPIDTO } from 'src/app/base/domain/dto/endereco-api.dto';

export interface CaptadoFormData {
  captado: Captado;
  action: string;
  usuario: Usuario;
}

@Component({
  selector: 'app-captacao-form',
  templateUrl: './captacao-form.component.html',
  styleUrls: ['./captacao-form.component.css'],
})
export class CaptacaoFormComponent implements OnInit, AfterViewInit {
  @ViewChild('form', { static: true }) form: NgForm;
  @ViewChild('cep', { static: true }) cep: NgModel;

  captado = new Captado();
  title = '';
  isPessoaFisisca = true;
  postos: Posto[] = [];
  filteredPostos: Posto[] = [];
  equipes: Equipe[] = [];
  funcionarios: Funcionario[] = [];
  atividadesComercial: AtividadeComercial[] = [];
  idPostos: string;
  estados: Estado[] = [];
  municipios: Municipio[] = [];
  bairros: Bairro[] = [];
  telefone = new Telefone();
  endereco = new Endereco();
  cepComplete: string;

  queryOptions = new QueryOptions();
  queryOptionsLista = new QueryOptions({ pageSize: 1000, pageNumber: 1 });

  constructor(
    public dialogRef: MatDialogRef<CaptacaoFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CaptadoFormData,
    private postoService: PostoService,
    private planejamentoSemanaCaptacaoService: PlanejamentoSemanaCaptacaoService,
    private equipeService: EquipeService,
    private funcionarioService: FuncionarioService,
    private atividadeComercial: AtividadeComercialService,
    private publicService: PublicService,
    private dialog: DialogService,
  ) {
    if (data.action === 'update') {
      this.title = 'Editar';
      this.captado = data.captado;
      this.telefone = this.captado.listTelefone ? this.captado.listTelefone[0] : new Telefone();
      this.endereco = this.captado.listEndereco ? this.captado.listEndereco[0] : new Endereco();
      this.captado.listTelefone = this.captado.listTelefone ? this.captado.listTelefone : [];
      this.captado.listEndereco = this.captado.listEndereco ? this.captado.listEndereco : [];
    } else {
      this.title = 'Novo';
      this.captado.listTelefone = [];
      this.captado.listEndereco = [];
    }
    this.atividadeComercial.findAll(this.queryOptionsLista).subscribe((result: ReturnAPI<Page<AtividadeComercial>>) => {
      if (result.success) {
        this.atividadesComercial = result.object.content;
        this.filteredPostos = this.postos;
        if (data.action === 'update') {
          this.captado.atividadeComercial = this.atividadesComercial.filter(
            (atividade_comercial: AtividadeComercial) => atividade_comercial.id === this.captado.atividadeComercial.id,
          )[0];
        }
      }
    });

    this.planejamentoSemanaCaptacaoService.findPostoCaptacaoHoje(this.data.usuario.id).subscribe((result: number[]) => {
      if (result !== undefined && result.length > 0) {
        this.idPostos = result.join(',');

        this.queryOptionsLista.params = {
          id: `in:${this.idPostos}`,
        };

        this.postoService.findAll(this.queryOptionsLista).subscribe((ondata: ReturnAPI<Page<Posto>>) => {
          if (ondata.success) {
            this.postos = ondata.object.content;
            this.filteredPostos = this.postos;
            if (data.action === 'update') {
              this.captado.posto = this.postos.filter((posto: Posto) => this.captado.posto.id === posto.id)[0];
              this.equipeService.buscarPorPosto(this.captado.posto.id).subscribe((dataEquipe: ReturnAPI<Equipe[]>) => {
                this.equipes = dataEquipe.object;
                this.captado.equipe = this.equipes.filter((equipe: Equipe) => this.captado.equipe.id === equipe.id)[0];

                const cargoVisitadorFiscal = '7,14';
                const queryOptionsFunc = new QueryOptions({
                  pageSize: 100,
                  pageNumber: 1,
                  params: { cargoIn: cargoVisitadorFiscal, postoId: this.captado.posto.id },
                });

                this.funcionarioService.findAll(queryOptionsFunc).subscribe((dataFuncionario: ReturnAPI<Page<Funcionario>>) => {
                  this.funcionarios = dataFuncionario.object.content;

                  this.captado.visitador = this.funcionarios.filter(
                    (funcionario: Funcionario) => this.captado.visitador.id === funcionario.id,
                  )[0];
                });
              });
            }
          }
        });

        this.publicService.listarEstados().subscribe((response: ReturnAPI<Page<Estado>>) => {
          if (response.success) {
            this.estados = response.object.content;
            if (data.action === 'update') {
              this.endereco.estado = this.estados.filter((estado: Estado) => this.endereco.estado.id === estado.id)[0];
              const estadoBusca = this.endereco.estado;
              const queryOptions = new QueryOptions({
                pageNumber: 1,
                params: {
                  estado: `eq:${estadoBusca.id}`,
                },
              });

              this.publicService.listarMunicipios(queryOptions).subscribe((responseMunicipio: ReturnAPI<Page<Municipio>>) => {
                if (response.success) {
                  this.municipios = responseMunicipio.object.content;
                  this.endereco.municipio = this.municipios.filter(
                    (municipio: Municipio) => this.endereco.municipio.id === municipio.id,
                  )[0];

                  const cidade = this.endereco.municipio;
                  const queryOptionsCidade = new QueryOptions({
                    pageNumber: 1,
                    params: {
                      municipio: `eq:${cidade.id}`,
                    },
                  });

                  this.publicService.listarBairros(queryOptionsCidade).subscribe((responseBairro: ReturnAPI<Page<Bairro>>) => {
                    if (responseBairro.success) {
                      this.bairros = responseBairro.object.content;
                      this.endereco.bairro = this.bairros.filter((bairro: Bairro) => this.endereco.bairro.id === bairro.id)[0];
                    }
                  });
                }
              });
            }
          }
        });
      }
    });
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.cep.valueChanges.subscribe((value: string) => {
      if (value && value.length === 8 && value !== this.cepComplete) {
        this.cepComplete = value;
        this.publicService.findEnderecoByCep(value).subscribe((response: ReturnAPI<EnderecoAPIDTO>) => {
          this.autoCompleteEndereco(response.object);
        });
      }
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.captado.listTelefone = [];
    this.captado.listEndereco = [];
    this.captado.listTelefone.push(this.telefone);
    this.captado.listEndereco.push(this.endereco);
    this.dialogRef.close(this.captado);
  }

  private _filtrar<T>(value: string, list: T[], field: string): T[] {
    const filterValue = value?.toLowerCase();
    return list.filter((e: T) => e[field].toLowerCase().indexOf(filterValue) === 0);
  }

  onSelectTipo(select: MatSelectChange): void {
    if (select.value === 'PESSOA_FISICA') {
      this.isPessoaFisisca = true;
      this.captado.cpf = '';
      this.captado.nome = '';
    } else {
      this.isPessoaFisisca = false;
      this.captado.cnpj = '';
      this.captado.nomeFantasia = '';
    }
  }

  onSelectPosto(value: Posto): void {
    const postoId = value.id;
    this.equipeService.buscarPorPosto(postoId).subscribe((data: ReturnAPI<Equipe[]>) => {
      this.equipes = data.object;
    });

    const cargoVisitadorFiscal = '7,14';
    const queryOptionsFunc = new QueryOptions({
      pageSize: 100,
      pageNumber: 1,
      params: { cargoIn: cargoVisitadorFiscal, postoId: postoId },
    });

    this.funcionarioService.findAll(queryOptionsFunc).subscribe((data: ReturnAPI<Page<Funcionario>>) => {
      this.funcionarios = data.object.content;
    });
  }

  onSelectEstado(value: Estado): void {
    const estado = value;
    const queryOptions = new QueryOptions({
      pageNumber: 1,
      params: {
        estado: `eq:${estado.id}`,
      },
    });

    this.publicService.listarMunicipios(queryOptions).subscribe((response: ReturnAPI<Page<Municipio>>) => {
      if (response.success) {
        this.municipios = response.object.content;
      }
    });
  }

  onSelectMunicipio(value: Municipio): void {
    const cidade = value;
    const queryOptions = new QueryOptions({
      pageNumber: 1,
      params: {
        municipio: `eq:${cidade.id}`,
      },
    });

    this.publicService.listarBairros(queryOptions).subscribe((response: ReturnAPI<Page<Bairro>>) => {
      if (response.success) {
        this.bairros = response.object.content;
      }
    });
  }

  autoCompleteEndereco(endereco: EnderecoAPIDTO): void {
    const options = new QueryOptions({ pageNumber: 1 });
    if (endereco.cep !== null) {
      const estado = this.estados.find((o: Estado) => o.sigla === endereco.uf);
      this.endereco.estado = estado;
      options.params = { estado: `eq:${this.endereco.estado.id}` };
      this.publicService.listarMunicipios(options).subscribe((ra: ReturnAPI<Page<Municipio>>) => {
        if (ra.success) {
          this.municipios = ra.object.content;
          const municipio = this.municipios.find((o: Municipio) => o.nomeMunicipio.toUpperCase() === endereco.cidade.toUpperCase());
          this.endereco.municipio = municipio;
          options.params = { municipio: `eq:${municipio.id}` };
          this.publicService.listarBairros(options).subscribe((rb: ReturnAPI<Page<Bairro>>) => {
            if (rb.success) {
              this.bairros = rb.object.content;
              const bairro = this.bairros.find((o: Bairro) => o.descricao.toUpperCase() === endereco.bairro.toUpperCase());
              this.endereco.bairro = bairro;
            }
          });
        }
      });

      this.endereco.cep = endereco.cep;
      this.endereco.complemento = endereco.complemento;
      this.endereco.logradouro = endereco.logradouro;
    } else {
      this.endereco = new Endereco();
      this.dialog.feedbackInfo('Cep não cadastrado!');
      this.cepComplete = '';
    }

  }
}
