import { Resource } from 'src/app/global/domain/resource.model';
import { AnaliseDeRiscoCliente } from './analise-de-risco-cliente.model';
import { CategoriaAnaliseDeRisco } from './enum/categoria-analise-risco.enum';

export class ArquivoAnaliseDeRisco extends Resource {
  nomeArquivo: string;
  categoria: CategoriaAnaliseDeRisco;
  inseridos: AnaliseDeRiscoCliente[];
  dataCadastro: Date;
}
