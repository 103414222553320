import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { LazyLoadEvent } from "primeng/api";
import { Table } from "primeng/table";
import { ArquivoImportacaoFinalizado } from "src/app/base/domain/arquivo-importacao-finalizado.model";
import { Posto } from "src/app/base/domain/posto.model";
import { ArquivoImportacaoFinalizadoService } from "src/app/base/services/arquivo-importacao-finalizado.service ";
import { CardListComponent } from "../../components/card-list/card-list.component";
import { DialogService } from "../../services/dialog.service";

export interface ArquivoImportacaoFinalizadoAcaoEvent {
    acao: string;
    arquivo: ArquivoImportacaoFinalizado;
}

export interface ArquivoImportacaoFinalizadoListFilterOptions {
    postoSelecionado: Posto;
    dataInicial: Date;
    dataFinal: Date;
}

@Component({
    selector: 'app-arquivo-importacao-finalizado-list',
    templateUrl: './arquivo-importacao-finalizado-list.component.html',
    styleUrls: ['./arquivo-importacao-finalizado-list.component.css'],
})
export class ArquivoImportacaoFinalizadoListComponent implements OnInit {

    @ViewChild('searchForm', { static: false }) searchForm: NgForm;
    @ViewChild('dataTable', { static: false }) dataTable: Table;
    @ViewChild(CardListComponent, { static: true }) cardList: CardListComponent;

    @Input() arquivos: ArquivoImportacaoFinalizado[];
    @Input() totalRecords: number;
    @Input() rowsNumber: number;
    @Input() loading: boolean;
    @Input() title: string;
    @Input() nomePlaceholder: string;
    @Input() dataPlaceholder: string;
    @Input() filtros: string[];
    @Input() options: string[];
    @Input() optionalColumns: string[];
    @Input() menuAcoes: string[];

    @Output() loadArquivosPage = new EventEmitter<number>();
    @Output() disclosureArquivo = new EventEmitter<ArquivoImportacaoFinalizado>();
    @Output() filter = new EventEmitter<ArquivoImportacaoFinalizadoListFilterOptions>();
    @Output() acaoClick = new EventEmitter<ArquivoImportacaoFinalizadoAcaoEvent>();

    @Output() imprimirLote = new EventEmitter<ArquivoImportacaoFinalizado[]>();

    private currentPage = 0;

    private readonly defaultFilterOptions: ArquivoImportacaoFinalizadoListFilterOptions = {
        postoSelecionado: null,
        dataInicial: null,
        dataFinal: null
    };

    filterOptions: ArquivoImportacaoFinalizadoListFilterOptions;

    constructor(private ArquivoImportacaoFinalizadoService: ArquivoImportacaoFinalizadoService,
        public dialog: MatDialog,
        public dialogService: DialogService) { }

    ngOnInit(): void {
        this.onLimparFiltros();
    }

    loadArquivos(event: LazyLoadEvent): void {
        const pageNumber = Math.floor(event.first / event.rows);
        this.currentPage = pageNumber;
        this.loadArquivosPage.emit(pageNumber);
    }

    onDisclosure(arquivo: ArquivoImportacaoFinalizado): void {
        this.disclosureArquivo.emit(arquivo);
    }

    onAtualizar(): void {
        this.loadArquivosPage.emit(this.currentPage);
    }

    onLimparFiltros(): void {
        if (this.filterOptions) {
            this.filterOptions.dataInicial = null;
            this.filterOptions.dataFinal = null;
        }
        this.filterOptions = Object.assign({}, this.defaultFilterOptions);
    }

    onAplicarFiltro(): void {
        this.filter.emit(this.filterOptions);
    }

    generateMask(value: number): string {
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    onAcaoClick(acao: string, domain: ArquivoImportacaoFinalizado): void {
        this.acaoClick.emit({ acao, arquivo: domain });
    }
}