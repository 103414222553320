import { Injectable } from '@angular/core';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { HttpClient } from '@angular/common/http';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { Observable } from 'rxjs';
import { Transferencia, TransferenciaOdin } from '../domain/transferencia.model';
import { TransferenciaSerializer } from '../serializables/transferencia.serializer';
import { TransferenciaDTO } from '../domain/dto/transferencia.dto';
import { ReturnAPI, Page } from '../domain/return-api.model';
import { DespesaDTO } from '../domain/dto/despesa.dto';
import { DivergenciaCaixaGeralTO } from '../domain/dto/divergencia-caixa-geral.dto';
import { Caixa } from '../domain/caixa.model';
import { AuditoriaDespesaDTO } from '../domain/dto/auditoria-despesa.dto';
import { AlterarSobraDTO } from '../domain/dto/alterar-sobra.dto';
import { Produto } from '../domain/produto.model';
import { ValoresTransferenciaRequestDTO } from '../domain/dto/valores-transferencia-request.dto';
import { DuplicadoDTO } from '../domain/dto/duplicados.dto';
import { CaptadoService } from './captado.service';

@Injectable()
export class TransferenciaService extends ResourceService<Transferencia> {
  static RESOURCE = 'transferencia';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${TransferenciaService.RESOURCE}`, new TransferenciaSerializer(Transferencia));
  }

  transferencia(id: number): Observable<ReturnAPI<Transferencia>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Transferencia>>(`${WEB_API}/transferencia/findOne?transferenciaId=${id}&${this.produtoId}`);
  }

  getTotalRepasseByFuncionarioId(id: number): Observable<ReturnAPI<number>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<number>>(
      `${WEB_API}/${TransferenciaService.RESOURCE}/total-repasse-funcionario?transferenciaId=${id}&${this.produtoId}`,
    );
  }

  getTotalCobrancaByFuncionarioId(id: number): Observable<ReturnAPI<number>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<number>>(
      `${WEB_API}/${TransferenciaService.RESOURCE}/total-cobranca-funcionario?transferenciaId=${id}&${this.produtoId}`,
    );
  }

  getValorTotalPorTipoTransferencia(valores: Partial<ValoresTransferenciaRequestDTO>): Observable<ReturnAPI<number>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<number>>(
      `${WEB_API}/${TransferenciaService.RESOURCE}/valor-total-por-tipo-transferencia?${this.produtoId}`,
      valores,
    );
  }

  getValorTotalCasosRoubo(valores: Partial<ValoresTransferenciaRequestDTO>): Observable<ReturnAPI<number>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<number>>(
      `${WEB_API}/${TransferenciaService.RESOURCE}/valor-total-casos-roubo?${this.produtoId}`,
      valores,
    );
  }

  getValorTotalSangria(valores: Partial<ValoresTransferenciaRequestDTO>): Observable<ReturnAPI<number>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<number>>(
      `${WEB_API}/${TransferenciaService.RESOURCE}/valor-total-sangria?${this.produtoId}`,
      valores,
    );
  }

  pageTransferencias(queryOption: QueryOptions): Observable<ReturnAPI<Page<Transferencia>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Transferencia>>>(
      `${WEB_API}/transferencia/pageable?${queryOption.toQueryString()}&${this.produtoId}`,
    );
  }

  pageTransferenciaByDestinoId(queryOption: QueryOptions, caixaId: number): Observable<ReturnAPI<Page<Transferencia>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Transferencia>>>(
      `${WEB_API}/transferencia/pageable?destino=eq:${caixaId}&${queryOption.toQueryString()}&${this.produtoId}&sort=-id`,
    );
  }

  pageTransferenciaByOrigemId(queryOption: QueryOptions, caixaId: number): Observable<ReturnAPI<Page<Transferencia>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Transferencia>>>(
      `${WEB_API}/transferencia/pageable?origem=eq:${caixaId}&${queryOption.toQueryString()}&${this.produtoId}&sort=-id`,
    );
  }

  confirmarComplemento(transferenciaId: number): Observable<ReturnAPI<void>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<void>>(
      `${WEB_API}/transferencia-controle/confirmar-complemento?transferenciaId=${transferenciaId}&${this.produtoId}`,
      null,
    );
  }

  confirmarDespesaAdministrativa(despesaAdministrativaTO: DespesaDTO): Observable<ReturnAPI<void>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<void>>(
      `${WEB_API}/transferencia-controle/confirmar-despesa-administrativa?${this.produtoId}&${this.produtoId}`,
      despesaAdministrativaTO,
    );
  }

  realizarTransferenciaComplemento(transferenciaDTO: TransferenciaDTO): Observable<ReturnAPI<void>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<void>>(
      `${WEB_API}/transferencia-controle/transferir-complemento?${this.produtoId}`,
      transferenciaDTO,
    );
  }

  confirmarSangriaOdin(transferenciaId: number): Observable<Object> {
    this.getProduto();
    return this.httpClient.put(
      `${WEB_API}/transferencia-controle/confirmar-sangria-odin?transferenciaId=${transferenciaId}&${this.produtoId}`,
      null,
    );
  }

  realizarSangriaOdin(transferenciaDTO: TransferenciaDTO): Observable<TransferenciaOdin> {
    this.getProduto();
    return this.httpClient.put<TransferenciaOdin>(
      `${WEB_API}/transferencia-controle/realizar-sangria-odin?${this.produtoId}`,
      transferenciaDTO,
    );
  }

  confirmarRealizacaoSangria(transferenciaId: number): Observable<ReturnAPI<Transferencia>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<Transferencia>>(
      `${WEB_API}/transferencia/confirmar-realizacao-sangria?transferenciaId=${transferenciaId}&${this.produtoId}`,
      null,
    );
  }

  regularizarDespesaRoubo(auditoriaDespesa: AuditoriaDespesaDTO): Observable<ReturnAPI<AuditoriaDespesaDTO>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<AuditoriaDespesaDTO>>(
      `${WEB_API}/transferencia/regularizar-despesa-roubo?${this.produtoId}`,
      auditoriaDespesa,
    );
  }

  alterarSobra(sobraTO: AlterarSobraDTO): Observable<ReturnAPI<AlterarSobraDTO>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<AlterarSobraDTO>>(`${WEB_API}/transferencia/alterar-sobra?${this.produtoId}`, sobraTO);
  }

  cancelarDespesaAdministrativa(despesaAdministrativaTO: DespesaDTO): Observable<ReturnAPI<void>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<void>>(
      `${WEB_API}/transferencia-controle/cancelar-despesa-administrativa?${this.produtoId}`,
      despesaAdministrativaTO,
    );
  }

  cancelarComplemento(transferenciaId: number): Observable<ReturnAPI<void>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<void>>(
      `${WEB_API}/transferencia-controle/cancelar-complemento-entre-postos?transferenciaId=${transferenciaId}&${this.produtoId}`,
      null,
    );
  }

  cancelarDespesaRoubo(transferenciaId: number): Observable<ReturnAPI<void>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<void>>(
      `${WEB_API}/transferencia/cancelar-despesa-roubo?transferenciaId=${transferenciaId}&${this.produtoId}`,
      null,
    );
  }

  alterarDespesaRoubo(despesaDTO: DespesaDTO): Observable<ReturnAPI<void>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<void>>(`${WEB_API}/transferencia/alterar-despesa-roubo?${this.produtoId}`, despesaDTO);
  }

  dividirDespesaRoubo(despesaDTO: DespesaDTO): Observable<ReturnAPI<void>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<void>>(`${WEB_API}/transferencia/dividir-despesa-roubo?${this.produtoId}`, despesaDTO);
  }

  pageSangriaGraphQL(queryOption: QueryOptions): Observable<ReturnAPI<Page<Transferencia>>> {
    this.getProduto();

    const query = `{
      listarTransferenciasTipoSangria(args: "${queryOption.toQueryString()}") {
        totalElements,
        totalPages,
        content { id, descricao, dataHoraCadastro, nomeFuncionario, descricaoRota, valorRealizado, urlComprovante, descricaoPosto, situacao }
      }
    }`;

    return this.httpClient.post<ReturnAPI<Page<Transferencia>>>(`${WEB_API}/transferencia/graphql?${this.produtoId}`, { query });
  }

  pageRouboGraphQL(queryOption: QueryOptions): Observable<ReturnAPI<Page<Transferencia>>> {
    this.getProduto();

    const query = `{
      listarTransferenciasTipoRoubo(args: "${queryOption.toQueryString()}") {
        totalElements,
        totalPages,
        content { id, descricao, dataHoraCadastro, situacao, categoria, nomeFuncionario, descricaoRota, valorRealizado }
      }
    }`;

    return this.httpClient.post<ReturnAPI<Page<Transferencia>>>(`${WEB_API}/transferencia/graphql?${this.produtoId}`, { query });
  }

  lancarPendenciaCaixaGeral(divergencia: DivergenciaCaixaGeralTO): Observable<ReturnAPI<Caixa>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<Caixa>>(`${WEB_API}/transferencia/lancar-pendencia-caixa-geral?${this.produtoId}`, divergencia);
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }

  pagePostoParcelaDuplicada(queryOptions: QueryOptions): Observable<ReturnAPI<Page<DuplicadoDTO>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<DuplicadoDTO>>>(
      `${WEB_API}/transferencia/page-posto-parcela-duplicada?${this.produtoId}&${queryOptions.toQueryString()}`,
    );
  }

  cancelarDuplicadas(duplicado: DuplicadoDTO): Observable<ReturnAPI<void>> {
    const parcelaDuplicada = {
      postoId: duplicado.postoId,
      postoNome: duplicado.postoNome,
      duplicado: duplicado.duplicado,
    };
    this.getProduto();
    return this.httpClient.post<ReturnAPI<void>>(
      `${WEB_API}/transferencia/cancelar-parcela-duplicadas?${this.produtoId}`,
      parcelaDuplicada,
    );
  }

  adicionarComprovanteParaTransferenciaOdin(transferenciaId: bigint, file: File): Observable<ReturnAPI<TransferenciaOdin>> {
    const form = new FormData();
    form.append('file', file, file.name);
    this.getProduto();
    return this.httpClient.put<ReturnAPI<TransferenciaOdin>>(
      `${WEB_API}/transferencia/comprovante-transferencia-odin/${transferenciaId}?${this.produtoId}`,
      form,
    );
  }

  monitoramentoDeRepassesTipoCliente(number: number): Observable<any> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<void>>(
      `${WEB_API}/transferencia/buscar-transferencia-repasse-funcionario/${number}/${this.produto.id}`,
    );
  }

  gerarRelatorioControleGeral(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(`${WEB_API}/relatorio-controle-geral/gerar-relatorio?${options.toQueryString(false)}`, {
      responseType: 'blob',
    });
  }

  relatorioCancelamentoComplementos(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(`${WEB_API}/${TransferenciaService.RESOURCE}/relatorio-cancelamento-complemento?${options.toQueryString(false)}&${this.produtoId}`, {
      responseType: 'blob',
    });
  }

  gerarRelatorioOrigemCapitacao(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(
      `${WEB_API}/${CaptadoService.RESOURCE}/relatorio-origem-captacao/?${this.produtoId}&${options.toQueryString(false)}`,
      {
        responseType: 'blob',
      },
    );
  }

  gerarRelatorioDespesas(options: QueryOptions): Observable<Blob> {
    return this.httpClient.get(`${WEB_API}/relatorio-despesa-nacional/gerar-relatorio?${options.toQueryString(false)}`, {
      responseType: 'blob',
    });
  }

  relatorioAuditoriaComplementoEntrePostos(options: QueryOptions): Observable<Blob> {
    this.getProduto();
    return this.httpClient.get(`${WEB_API}/${TransferenciaService.RESOURCE}/relatorio-complemento-entre-postos?${options.toQueryString(false)}&${this.produtoId}`, {
      responseType: 'blob',
    });
  }


}
