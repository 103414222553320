import { Resource } from 'src/app/global/domain/resource.model';

export class DashboardCrecimentoSustentavel extends Resource {

    estado: string;
    posto: string;
    rota: string;

    quantidadeCobrancas: number;
    quantidadeCobrancasRealizadas: number;
    quantidadeCobrancasNaoRealizadas: number;
    diferencaCobrancas: number;
    valorCobrancas: number;
    valorCobrancasRealizadas: number;
    valorCobrancasNaoRealizadas: number;
    diferencaValorCobrancas: number;
    valorPropagadoCobrancas: number;
    percentualQuantidadeCobrada: number;
    percentualValorRecebido: number;
    percentualEficienciaCobrancas: number;

    quantidadeRepasses: number;
    quantidadeRepassesRealizados: number;
    quantidadeRepassesReagendados: number;
    quantidadeRepassesDesistentes: number;
    diferencaRepasses: number;
    valorRepasses: number;
    valorRepassesRealizados: number;
    valorRepassesReagendados: number;
    valorRepassesDesistentes: number;
    diferencaValorRepasses: number;
    valorPropagadoRepasses: number;
    percentualQuantidadeRepassados: number;

    estimadoComTodosRepassados: number;
    estimadoAtual: number;
    crescimento: number;
    perda: number;
}
