import { Rota } from '../domain/rota.model';
import { ResourceService } from 'src/app/global/services/resource.service';
import { Injectable } from '@angular/core';
import { WEB_API } from 'src/app/global/services/web-api';
import { Page, ReturnAPI } from '../domain/return-api.model';
import { Observable } from 'rxjs';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { RotaSerializer } from '../serializables/rota.serializer';
import { HttpClient } from '@angular/common/http';
import { Produto } from '../domain/produto.model';
import { RotaDTO } from '../domain/dto/rota.dto';

@Injectable()
export class RotaService extends ResourceService<Rota> {
  static RESOURCE = 'rota';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${RotaService.RESOURCE}`, new RotaSerializer(Rota));
  }

  deletar(rotaId: number): Observable<ReturnAPI<void>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<void>>(`${WEB_API}/${RotaService.RESOURCE}/deletar-rota?rotaId=${rotaId}&${this.produtoId}`, null);
  }

  findById(id: number): Observable<ReturnAPI<Rota>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Rota>>(`${WEB_API}/${RotaService.RESOURCE}/findOne?rotaId=${id}&${this.produtoId}`);
  }

  findAll(queryOption: QueryOptions): Observable<ReturnAPI<Page<Rota>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Rota>>>(
      `${WEB_API}/${RotaService.RESOURCE}?${queryOption.toQueryString()}&${this.produtoId}`,
    );
  }

  buscarPorEquipe(id: number): Observable<ReturnAPI<Rota[]>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Rota[]>>(`${WEB_API}/${RotaService.RESOURCE}/buscar-por-equipe?equipeId=${id}&${this.produtoId}`);
  }

  private getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }

  listarRotasPorPosto(id: number): Observable<ReturnAPI<Rota[]>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Rota[]>>(
      `${WEB_API}/${RotaService.RESOURCE}/listar-rotas-por-posto?posto=${id}&${this.produtoId}`,
    );
  }

  listarRotasPorEstadoEPostoUID(estadoId: number, postoUid: string): Observable<ReturnAPI<Rota[]>> {
    return this.httpClient.get<ReturnAPI<Rota[]>>(
      `${WEB_API}/${RotaService.RESOURCE}/buscar-rotas-por-posto-estado?postoUid=${postoUid}&estado=${estadoId}`,
    );
  }
}
