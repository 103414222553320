import { Component, OnInit } from '@angular/core';
import { QueryFilter } from '../../motivo-contrato-cancelado/motivo-contrato-cancelado-main/motivo-contrato-cancelado-main.component';
import { CaixaService } from '../../../services/caixa.service';
import { TransferenciaService } from '../../../services/transferencia.service';
import { Funcionario } from '../../../domain/funcionario.model';

@Component({
  selector: 'app-monitoramento-repasse-tipo-cliente-main',
  templateUrl: './monitoramento-repasse-tipo-cliente-main.component.html',
  styleUrls: ['./monitoramento-repasse-tipo-cliente-main.component.css']
})
export class MonitoramentoRepasseTipoClienteMainComponent implements OnInit {

  private readonly defaultQueryFilter: QueryFilter = {
    dataFinal: null,
    dataInicial: null,
    posto: null,
    funcionario: null
  };

  monitoramento: any[];
  filterOptions: QueryFilter = this.defaultQueryFilter;
  qtd = 1;
  qtdNovos: 0;
  qtdReativados: 0;

  clientesNovos: any[];

  constructor(
    private transferenciaService: TransferenciaService,
  ) {  }

  ngOnInit(): void {  }

  reloadPage(): void {
    if (this.filterOptions.funcionario.id != null) {
      this.transferenciaService.monitoramentoDeRepassesTipoCliente(this.filterOptions.funcionario.id).subscribe(data => {
        this.monitoramento = data.object.content;
        this.qtd = this.monitoramento.length;

        this.qtdReativados = this.monitoramento.filter((monitomamento) => {
          return monitomamento.contrato.visita.cliente.situacaoCliente !== 'NOVO';
        })
          .reduce((sum, repasses) => {
            return sum + repasses.valorRealizado;
          }, 0);

        this.qtdNovos = this.monitoramento.filter((monitomamento) => {
          return monitomamento.contrato.visita.cliente.situacaoCliente === 'NOVO';
        })
          .reduce((sum, repasses) => {
            return sum + repasses.valorRealizado;
          }, 0);
      });
    }
  }

}
