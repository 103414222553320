import { Equipe } from './../equipe.model';
import { Funcionario } from './../funcionario.model';
import { AcaoDeVenda } from '../acao-de-venda.model';

export class VisitaAgendamentoAcaoDeVendaDTO {
  acaoDeVendaId: number;
  data: Date;
  visitador: Funcionario;
  equipe: Equipe;
}
