import { Component, Inject, OnInit } from '@angular/core';
import { Feriado } from 'src/app/base/domain/feriado.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { PostoService } from 'src/app/base/services/posto.service';
import { Posto } from 'src/app/base/domain/posto.model';
import { ReturnAPI } from 'src/app/base/domain/return-api.model';
import { TipoFeriado } from 'src/app/base/domain/enum/tipo-feriado.enum';

export interface FeriadoFormData {
  feriado?: Feriado;
  toEdit?: boolean;
}

@Component({
  selector: 'app-feriado-form',
  templateUrl: './feriado-form.component.html',
  styleUrls: ['./feriado-form.component.css'],
})
export class FeriadoFormComponent implements OnInit {
  feriado: Feriado = new Feriado({});
  toEdit: boolean;

  postos: Posto[] = [];
  postosSelecionados: Posto[] = [];

  tipos: TipoFeriado[] = Object.values(TipoFeriado);

  constructor(
    private postoService: PostoService,
    public dialogRef: MatDialogRef<FeriadoFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FeriadoFormData,
  ) {
    this.toEdit = data.toEdit || false;
    if (data.feriado) {
      this.feriado = Object.assign({}, data.feriado);
    }
  }

  ngOnInit(): void {
    this.postoService.listarPostos().subscribe((response: ReturnAPI<Posto[]>) => {
      if (response.success) {
        this.postos = response.object;
      }
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(this.postosSelecionados.map((posto: Posto): Feriado => ({ ...this.feriado, posto })));
  }

  onPickerEvent(event: MatDatepickerInputEvent<Moment>): void {
    this.feriado.data = event.value?.toDate() || null;
  }

  postoSelecionadoEvent(posto: Posto): void {
    this.feriado.posto = posto;
  }
}
