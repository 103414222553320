import { Resource } from 'src/app/global/domain/resource.model';
import { Contrato } from './contrato.model';
import { Funcionario } from './funcionario.model';

export class Parcela extends Resource {
  turno: string; // TurnoEnum
  ordem: number;
  valor: number;
  valorPago: number;
  juros: number;
  situacaoParcela: string; // SituacaoParcelaEnum
  tipoAgendamento: string; // TipoAgendamento
  dataVencimento: Date;
  contrato: Contrato;
  responsavel: Funcionario;
  valorExcedente: number;
  tipoCobranca: string; // TipoCobrancaEnum;
  agendamento: boolean;
  dataAgendamento: Date;
  parcelaUnica: number;
  quantidadeParcela: number;
}
