import { Component, ElementRef, OnInit, ViewChild, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import Chart from 'chart.js/auto';
import { registerables } from 'chart.js';

Chart.register(...registerables);

export interface RadarChartTemplate {
  canvas: string;
  elementIndex: number;
  heigth: number;
  maxHeigth: string;
  label: string;
  dataLabels: string[];
  data: RadarChartTemplateDatasets[];
}

export interface RadarChartTemplateDatasets {
  label: string;
  data: number[];
  fill: boolean;
  index: number;
  backgroundColor: string;
  borderColor: string;
  pointBackgroundColor: string;
  pointBorderColor: string;
  pointHoverBackgroundColor: string;
  pointHoverBorderColor: string;
}

@Component({
  selector: 'app-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.css']
})
export class RadarChartComponent implements OnInit, OnChanges, AfterViewInit {
  static readonly DEFAULT_DATA = {
    label: '',
    data: [],
    fill: false,
    index: null,
    backgroundColor: 'rgba(255, 99, 132, 0.2)',
    borderColor: 'rgb(255, 99, 132)',
    pointBackgroundColor: 'rgb(255, 99, 132)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgb(255, 99, 132)'
  };

  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;
  public context: CanvasRenderingContext2D;
  chart: Chart;

  @Input() template: RadarChartTemplate;

  rgbaColors: string[] = [
    'rgba(255, 0, 0, 0.7)',
    'rgba(255, 100, 0, 0.7)',
    'rgba(255, 220, 0, 0.7)',
    'rgba(110, 225, 0, 0.7)',
    'rgba(95, 210, 215, 0.7)',
    'rgba(10, 55, 235, 0.7)'
  ];

  rgbColors: string[] = [
    'rgb(255, 0, 0)',
    'rgb(255, 100, 0)',
    'rgb(255, 220, 0)',
    'rgb(110, 225, 0)',
    'rgb(95, 210, 215)',
    'rgb(10, 55, 235)'
  ];

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.template && this.template) {
      if (this.chart) {
        this.chart.destroy();
      }
      if (this.canvas) {
        this.context = this.canvas.nativeElement.getContext('2d');
        this.configureCanvasChart();
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.chart) {
      this.chart.destroy();
    }
    if (this.canvas) {
      this.context = this.canvas.nativeElement.getContext('2d');
      this.configureCanvasChart();
    }
  }

  configureCanvasChart(): void {
    this.canvas.nativeElement.id = this.template.canvas;
    this.canvas.nativeElement.height = this.template.heigth;
    this.canvas.nativeElement.style.maxHeight = this.template.maxHeigth;

    const dataSize = this.template.data.length;
    const datasets: RadarChartTemplateDatasets[] = [];
    for (let i = 0; i < dataSize; i++) {
      const templateData = this.template.data[i];
      const pattern = Object.assign({}, RadarChartComponent.DEFAULT_DATA);

      pattern.label = templateData.label ? templateData.label : pattern.label;
      pattern.data = templateData.data ? templateData.data : pattern.data;
      pattern.fill = templateData.fill ? templateData.fill : pattern.fill;

      // BACKGROUND
      pattern.backgroundColor = templateData.backgroundColor ? templateData.backgroundColor
        : (templateData.index ? this.rgbaColors[templateData.index] : pattern.backgroundColor);

      // BORDER
      pattern.borderColor = templateData.borderColor ? templateData.borderColor
        : (templateData.index ? this.rgbColors[templateData.index] : pattern.borderColor);

      // POINT
      pattern.pointBackgroundColor = templateData.pointBackgroundColor ? templateData.pointBackgroundColor
        : (templateData.index ? this.rgbaColors[templateData.index] : pattern.pointBackgroundColor);

      pattern.pointBorderColor = templateData.pointBorderColor ? templateData.pointBorderColor
        : (templateData.index ? this.rgbaColors[templateData.index] : pattern.pointBorderColor);

      pattern.pointHoverBackgroundColor = templateData.pointHoverBackgroundColor ? templateData.pointHoverBackgroundColor
        : (templateData.index ? this.rgbaColors[templateData.index] : pattern.pointHoverBackgroundColor);

      pattern.pointHoverBorderColor = templateData.pointHoverBorderColor ? templateData.pointHoverBorderColor
        : (templateData.index ? this.rgbaColors[templateData.index] : pattern.pointHoverBorderColor);

      datasets.push(pattern);
    }

    const data = {
      labels: this.template.dataLabels,
      datasets: datasets
    };

    const config: any = {
      type: 'radar',
      data: data,
      options: {
        elements: {
          line: {
            borderWidth: 3
          }
        }
      },
    };

    this.chart = new Chart(this.context, config);
  }
}
