import { RealocarFuncionarioDTO } from './../domain/dto/realocar-funcionario.dto';
import { Funcionario } from '../domain/funcionario.model';
import { ResourceService } from 'src/app/global/services/resource.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WEB_API } from 'src/app/global/services/web-api';
import { FuncionarioSerializer } from '../serializables/funcionario.serializer';
import { ReturnAPI, Page } from '../domain/return-api.model';
import { Observable } from 'rxjs';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { Produto } from '../domain/produto.model';
import { ResponsavelDTO } from 'src/app/global/forms/alterar-responsavel-rota-form/alterar-responsavel-rota-form.component';

@Injectable()
export class FuncionarioService extends ResourceService<Funcionario> {
  static RESOURCE = 'funcionario';
  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, FuncionarioService.RESOURCE, new FuncionarioSerializer(Funcionario));
  }

  listFuncionarioByCargo(cargoId: number): Observable<ReturnAPI<Funcionario[]>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Funcionario[]>>(
      `${WEB_API}/${FuncionarioService.RESOURCE}/lista-funcionario-cargo?cargoId=${cargoId}&${this.produtoId}`,
    );
  }

  listFuncionarioByCargoAndPosto(cargoId: number, postoId: number): Observable<ReturnAPI<Funcionario[]>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Funcionario[]>>(
      `${WEB_API}/${FuncionarioService.RESOURCE}/lista-funcionario-cargo-posto?cargoId=${cargoId}&postoId=${postoId}&${this.produtoId}`,
    );
  }

  listarVisitadoresPorPosto(postoId: number): Observable<ReturnAPI<Funcionario[]>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Funcionario[]>>(
      `${WEB_API}/${FuncionarioService.RESOURCE}/visitadores-do-posto?postoId=${postoId}&${this.produtoId}`,
    );
  }

  findAll(queryOption: QueryOptions): Observable<ReturnAPI<Page<Funcionario>>> {
    this.getProduto();
    return this.httpClient.get<ReturnAPI<Page<Funcionario>>>(
      `${WEB_API}/${FuncionarioService.RESOURCE}?${queryOption.toQueryString()}&${this.produtoId}`,
    );
  }

  insert(funcionario: Funcionario): Observable<ReturnAPI<Funcionario>> {
    this.getProduto();
    return this.httpClient.post<ReturnAPI<Funcionario>>(`${WEB_API}/${FuncionarioService.RESOURCE}?${this.produtoId}`, funcionario);
  }

  realocarFuncionario(realocarFuncionarioDTO: RealocarFuncionarioDTO): Observable<ReturnAPI<Funcionario[]>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<Funcionario[]>>(
      `${WEB_API}/${FuncionarioService.RESOURCE}/realocar-funcionario?${this.produtoId}`,
      realocarFuncionarioDTO,
    );
  }

  getProduto(): void {
    if (sessionStorage.getItem('produto')) {
      this.produto = JSON.parse(sessionStorage.getItem('produto'));
      this.produtoId = Number.isInteger(this.produto.id) ? 'produtoId=' + this.produto.id : '';
    }
  }

  agendarAlterarResponsavelRota(responsavelDTO: ResponsavelDTO): Observable<ReturnAPI<Funcionario>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<Funcionario>>(
      `${WEB_API}/${FuncionarioService.RESOURCE}/agendar-alterar-responsavel-rota?${this.produtoId}`,
      responsavelDTO,
    );
  }

  cancelarAgendarAlterarResponsavelRota(responsavelDTO: ResponsavelDTO): Observable<ReturnAPI<Funcionario>> {
    this.getProduto();
    return this.httpClient.put<ReturnAPI<Funcionario>>(
      `${WEB_API}/${FuncionarioService.RESOURCE}/cancelar-agendar-alterar-responsavel-rota?${this.produtoId}`,
      responsavelDTO,
    );
  }
}
