import { SituacaoCliente } from '../enum/situacao-cliente.enum';

export class AuditoriaClienteDTO {
  clienteId: number;
  parecer: string;
  situacao: SituacaoCliente;
  nomeCliente?: string;
  postoId?: number;
  postoNome?: string;
  codigoSituacaoInativacao: number;
}
