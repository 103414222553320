import { Component, OnInit } from '@angular/core';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { DialogService } from 'src/app/global/services/dialog.service';
import { ReturnAPI, Page } from '../../../domain/return-api.model';
import { ClientePlanejamento } from '../../../domain/planejamento-cliente.model';
import { ClientePlanejamentoService } from '../../../services/cliente-planejamento.service';
import { ClientePlanejamentoListFilterOptions } from 'src/app/global/lists/cliente-planejamento-list/cliente-planejamento-list.component';
import { AmostraPlanejamentoClienteAnoDTO } from '../../../domain/dto/amostra-planejamento-cliente-ano.dto';
import { QuantidadePlanejamentoDTO } from '../../../domain/dto/quantidade-planejamento.dto';
import { Posto } from '../../../domain/posto.model';
import { updateCounterDescription } from '../../../utils/conter-description';

@Component({
  selector: 'app-planejamento-cliente-main',
  templateUrl: './planejamento-cliente-main.component.html',
  styleUrls: ['./planejamento-cliente-main.component.css'],
})
export class PlanejamentoClienteMainComponent implements OnInit {
  clientePlanejamentos: ClientePlanejamento[] = [];

  labels: string[] = [];
  series: number[][] = [];
  quantidadePlanejados = 0;
  quantidadeCaptados = 0;

  counterMetadata = {
    description: 'Todos as Datas',
    subDescription: 'Todos os Postos',
  };

  queryOptions = new QueryOptions({ pageSize: 25 });
  totalRecords = 0;
  loading = false;

  private postoSelecionado: number = null;
  private dataInicialSelecionada: Date = null;
  private dataFinalSelecionada: Date = null;

  constructor(private clientePlanejamentoService: ClientePlanejamentoService, public dialogService: DialogService) { }

  ngOnInit(): void {
    this.amostrarClientesPlanejados();
    this.consultarPlanejamentoPeriodoAgrupadosPorSituacao();
  }

  listarPlanejamentosDeClientes(pageNumber: number = 0): void {
    this.queryOptions.pageNumber = pageNumber + 1;
    this.loading = true;
    this.clientePlanejamentoService.findAll(this.queryOptions).subscribe((response: ReturnAPI<Page<ClientePlanejamento>>) => {
      if (response.success) {
        this.totalRecords = response.object.totalElements;
        this.clientePlanejamentos = response.object.content;
      } else {
        this.clientePlanejamentos = [];
        this.dialogService.feedbackReturnAPI(response, '');
      }
      this.loading = false;
    });
  }

  amostrarClientesPlanejados(postoID?: number): void {
    this.clientePlanejamentoService
      .amostrarPlanejamentosDoAno(postoID)
      .subscribe((response: ReturnAPI<AmostraPlanejamentoClienteAnoDTO>) => {
        if (response.success) {
          this.labels = response.object.meses;
          this.series = [response.object.planejados, response.object.captados];
        }
      });
  }

  consultarPlanejamentoPeriodoAgrupadosPorSituacao(dataInicial?: Date, dataFinal?: Date, posto?: Posto): void {
    const options = new QueryOptions({
      params: {
        dataInicio: dataInicial?.toLocaleString(),
        dataFinal: dataFinal?.toLocaleString(),
        postoId: posto?.id,
      },
    });
    this.clientePlanejamentoService
      .planejamentoPeriodoAgrupadosPorSituacao(options)
      .subscribe((response: ReturnAPI<QuantidadePlanejamentoDTO>) => {
        if (response.success) {
          this.quantidadePlanejados = response.object.quantidadePlanejados;
          this.quantidadeCaptados = response.object.quantidadeCaptados;
          this.counterMetadata = updateCounterDescription(dataInicial, dataFinal, posto);
        }
      });
  }

  onLoadClientePlanejamentosPage(page: number): void {
    this.listarPlanejamentosDeClientes(page);
  }

  onFilter(options: ClientePlanejamentoListFilterOptions): void {
    const postoId = options.postoSelecionado ? options.postoSelecionado.id : null;
    const dataInicial = options.dataInicial || null;
    const dataFinal = options.dataFinal || null;

    this.queryOptions.params = {
      nomeCliente: options.nomeClientePlanejamento ? `like:${options.nomeClientePlanejamento}` : null,
      situacao: options.situacoesClientePlanejamento?.length ? `in:${options.situacoesClientePlanejamento.join(',')}` : null,
      dataInicio: dataInicial?.toLocaleString(),
      dataFinal: dataFinal?.toLocaleString(),
      postoId,
    };

    this.listarPlanejamentosDeClientes();

    if (dataInicial !== this.dataInicialSelecionada || dataFinal !== this.dataFinalSelecionada || postoId !== this.postoSelecionado) {
      this.consultarPlanejamentoPeriodoAgrupadosPorSituacao(options.dataInicial, options.dataFinal, options.postoSelecionado);
      this.dataInicialSelecionada = dataInicial;
      this.dataFinalSelecionada = dataFinal;
    }

    if (postoId !== this.postoSelecionado) {
      this.amostrarClientesPlanejados(postoId);
      this.postoSelecionado = postoId;
    }
  }
}
