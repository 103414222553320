import { Pessoa } from './../../../base/domain/pessoa.model';
import { Page, ReturnAPI } from 'src/app/base/domain/return-api.model';
import { PostoService } from 'src/app/base/services/posto.service';
import { Endereco } from 'src/app/base/domain/endereco.model';
import { Posto } from 'src/app/base/domain/posto.model';
import { Cargo } from 'src/app/base/domain/cargo.model';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Funcionario } from 'src/app/base/domain/funcionario.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { QueryOptions } from '../../domain/query.options';
import { CargoService } from 'src/app/base/services/cargo.service';
import { EnderecoSelectable, EnderecoSelectableElement, EnderecoSelectableOption } from '../endereco-form/endereco-form.component';
import { Estado } from 'src/app/base/domain/estado.model';
import { Municipio } from 'src/app/base/domain/municipio.model';
import { Bairro } from 'src/app/base/domain/bairro.model';
import { PublicService } from 'src/app/base/services/public.service';

export interface FuncionarioFormInput {
  id: number;
  nome: string;
  cpf: string;
  email: string;
  sexo: 'MASCULINO' | 'FEMININO';
  dataNascimento: Date;
  telefone: string;
  cargo: Cargo;
  posto: Posto;
  endereco: Partial<Endereco>;
  ativo: boolean;
}

export interface FuncionarioFormData {
  titulo: string;
  cargoFixo: string;
  funcionario: Funcionario;
}

@Component({
  selector: 'app-funcionario-form',
  templateUrl: './funcionario-form.component.html',
  styleUrls: ['./funcionario-form.component.css'],
})
export class FuncionarioFormComponent implements OnInit {
  funcionarioForm: Partial<FuncionarioFormInput> = {
    endereco: {},
  };

  postos: Posto[] = [];
  cargos: Cargo[] = [];

  selectableOptions: { [key: string]: EnderecoSelectableOption } = {
    estado: {
      lista: [],
      filtrados: [],
      busca: '',
      buscaProp: 'nome',
    },
    cidade: {
      lista: [],
      filtrados: [],
      busca: '',
      buscaProp: 'nomeMunicipio',
    },
    bairro: {
      lista: [],
      filtrados: [],
      busca: '',
      buscaProp: 'descricao',
    },
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FuncionarioFormData,
    public dialogRef: MatDialogRef<FuncionarioFormComponent>,
    private postoService: PostoService,
    private cargoService: CargoService,
    private publicService: PublicService,
  ) {}

  ngOnInit(): void {

    this.listarEstados();

    this.postoService.listarPostos().subscribe((response: ReturnAPI<Posto[]>) => {
      if (response.success) {
        this.postos = response.object;

        // carrega o posto corretamente quando usado feature para edicao de um funcionario
        if(this.data.titulo === 'Editar Operador') {
          this.funcionarioForm.posto = this.postos.filter((posto: Posto) => this.data.funcionario.posto.id === posto.id)[0];
        }
      }
    });
    this.cargoService.listarCargos().subscribe((response: ReturnAPI<Cargo[]>) => {
      if (response.success) {
        this.cargos = response.object;
        if (this.data.cargoFixo) {
          this.cargos = this.cargos.filter((cargo: Cargo) => cargo.descricao === this.data.cargoFixo);
          this.funcionarioForm.cargo = this.cargos[0];
        }
      }
    });

    if(this.data.titulo === 'Editar Operador') {
      this.buildFuncionarioParaEdicao();
    }
  }

  private parseInputParaFuncionario(input: Partial<FuncionarioFormInput>): Partial<Funcionario> {
    const pessoa: Partial<Pessoa> = {
      id: this.data?.funcionario ? this.data.funcionario.pessoa.id : null,
      type: 'pf',
      dataNascimento: this.funcionarioForm.dataNascimento,
      cpf: this.funcionarioForm.cpf,
      email: this.funcionarioForm.email,
      listTelefone: [{ numero: this.funcionarioForm.telefone, id: null }],
      listEndereco: [this.funcionarioForm.endereco as Endereco],
      listContaBancaria: [],
      sexo: this.funcionarioForm.sexo,
      nome: this.funcionarioForm.nome,
    };
    return {
      id: input.id,
      pessoa: pessoa as Pessoa,
      cargo: this.funcionarioForm.cargo,
      posto: this.funcionarioForm.posto,
    };
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.funcionarioForm.ativo = true;
    this.dialogRef.close(this.parseInputParaFuncionario(this.funcionarioForm));
  }

  onDatePickerEvent(picker: 'dataNascimento', event: MatDatepickerInputEvent<Moment>): void {
    if (picker === 'dataNascimento') {
      this.funcionarioForm.dataNascimento = event.value?.toDate();
    }
  }

  private cleanSelectableOption(element: EnderecoSelectableElement): void {
    this.selectableOptions[element].busca = '';
    this.onSelect(element, '');
  }

  private listarEstados(): void {
    this.publicService.listarEstados().subscribe((response: ReturnAPI<Page<Estado>>) => {
      if (response.success) {
        this.selectableOptions.estado.lista = response.object.content;
        this.doFilter('estado');
        if (this.funcionarioForm.endereco.estado) {
          this.selectableOptions.estado.busca = this.funcionarioForm.endereco.estado.nome;
          this.onSelect('estado', this.funcionarioForm.endereco.estado.nome);
        }
      }
    });
  }

  private listarCidades(): void {
    const estado = this.funcionarioForm.endereco.estado;
    const queryOptions = new QueryOptions({
      pageNumber: 1,
      params: {
        estado: `eq:${estado.id}`,
      },
    });
    this.publicService.listarMunicipios(queryOptions).subscribe((response: ReturnAPI<Page<Municipio>>) => {
      if (response.success) {
        this.selectableOptions.cidade.lista = response.object.content;
        this.selectableOptions.cidade.busca = '';
        this.doFilter('cidade');
        if (this.funcionarioForm.endereco.municipio && this.funcionarioForm.endereco.municipio.estado?.id === estado.id) {
          this.selectableOptions.cidade.busca = this.funcionarioForm.endereco.municipio.nomeMunicipio;
          this.onSelect('cidade', this.funcionarioForm.endereco.municipio.nomeMunicipio);
        } else {
          this.cleanSelectableOption('cidade');
          this.cleanSelectableOption('bairro');
          this.selectableOptions.bairro.lista = [];
          this.doFilter('bairro');
        }
      }
    });
  }

  private listarBairros(): void {
    const cidade = this.funcionarioForm.endereco.municipio;
    const queryOptions = new QueryOptions({
      pageNumber: 1,
      params: {
        municipio: `eq:${cidade.id}`,
      },
    });
    this.publicService.listarBairros(queryOptions).subscribe((response: ReturnAPI<Page<Bairro>>) => {
      if (response.success) {
        this.selectableOptions.bairro.lista = response.object.content;
        this.selectableOptions.bairro.busca = '';
        this.doFilter('bairro');
        if (this.funcionarioForm.endereco.bairro && this.funcionarioForm.endereco.bairro.municipio?.id === cidade.id) {
          this.selectableOptions.bairro.busca = this.funcionarioForm.endereco.bairro?.descricao;
          this.onSelect('bairro', this.funcionarioForm.endereco.bairro.descricao);
        } else {
          this.cleanSelectableOption('bairro');
        }
      }
    });
  }

  onSelect(element: EnderecoSelectableElement, value: string): void {
    this.funcionarioForm.endereco[element === 'cidade' ? 'municipio' : element] =
      (this.selectableOptions[element].lista.find(
        (e: EnderecoSelectable) => e[this.selectableOptions[element].buscaProp] === value,
      ) as Estado & Municipio & Bairro) || null;

    if (element === 'estado') {
      this.listarCidades();
    }
    if (element === 'cidade' && value) {
      this.listarBairros();
    }
  }

  doFilter(element: EnderecoSelectableElement): void {
    this.selectableOptions[element].filtrados = this._filtrar(
      this.selectableOptions[element].busca,
      this.selectableOptions[element].lista,
      this.selectableOptions[element].buscaProp,
    );
  }

  private _filtrar<T>(value: string, list: T[], field: string): T[] {
    const filterValue = value?.toLowerCase();
    return list.filter((e: T) => e[field].toLowerCase().indexOf(filterValue) === 0);
  }

  private buildFuncionarioParaEdicao(): void {
    this.funcionarioForm.id =           this.data.funcionario.id;
    this.funcionarioForm.nome =         this.data.funcionario.pessoa.nome;
    this.funcionarioForm.email =        this.data.funcionario.pessoa.email;
    this.funcionarioForm.cpf =          this.data.funcionario.pessoa.cpf;
    this.funcionarioForm.endereco =     this.data.funcionario.pessoa.listEndereco[0];
    this.funcionarioForm.dataNascimento = new Date(this.data.funcionario.pessoa.dataNascimento);
    this.funcionarioForm.telefone =     this.data.funcionario.pessoa.listTelefone[0].numero;
    this.funcionarioForm.posto =        this.postos.filter((posto: Posto) => this.data.funcionario.posto.id === posto.id)[0];
    // @ts-ignore
    this.funcionarioForm.sexo =         this.data.funcionario.pessoa.sexo;
  }

}
