import { VisitaService } from './../../../services/visita.service';
import { Component, Input, OnInit, OnChanges, Output, EventEmitter, SimpleChanges, AfterViewInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Visita } from 'src/app/base/domain/visita.model';
import { ReturnAPI } from 'src/app/base/domain/return-api.model';
import { AppState } from 'src/app/App.state';

@Component({
  selector: 'app-aprovacao-de-credito-details',
  templateUrl: './aprovacao-de-credito-details.component.html',
  styleUrls: ['./aprovacao-de-credito-details.component.css'],
})
export class AprovacaoDeCreditoDetailsComponent implements OnInit, OnChanges {
  private quantidadeParcelasOriginal: number;
  private periodicidadeoriginal: string;

  items: MenuItem[] = [
    {
      id: '0',
      label: 'Resumo',
      command: () => {
        this.activeItem = this.items[0];
      },
    },
    {
      id: '1',
      label: 'Aprovação de Crédito',
      command: () => {
        this.activeItem = this.items[1];
      },
    },
  ];

  activeItem = this.items[0];

  @Input() showView: boolean;
  @Input() visita: Visita;

  @Output() rejeitarVisita: EventEmitter<number> = new EventEmitter<number>();

  display: boolean;
  formData: Visita;

  parecerAprovacao = '';
  tipoPagamento: number;
  valorAprovado: number;
  limiteCreditoAprovado: number;

  tiposDeContrato: string[] = ['DINHEIRO', 'ELETRO', 'BOLETO', 'CARTAO_KIT_1', 'CARTAO_KIT_2', 'CARTAO_KIT_3'];
  periodicidades: string[] = ['DIARIA', 'SEMANAL', 'QUINZENAL', 'MENSAL'];
  diasDaSemana: string[] = ['SEGUNDA', 'TERÇA', 'QUARTA', 'QUINTA', 'SEXTA'];

  @Output() setDetailsFalse = new EventEmitter<boolean>();
  @Output() savedEvent = new EventEmitter<boolean>();

  constructor(private visitaService: VisitaService, public appState: AppState) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showView.currentValue) {
      this.display = this.showView;
    }
    if (changes.visita) {
      this.formData = this.visita;
      this.valorAprovado = this.visita.valorSugerido;
      this.limiteCreditoAprovado = this.visita.limiteCreditoSugerido;
      this.tipoPagamento = this.getTipoPagamento(this.visita);
      this.setTipoPagamento(this.tipoPagamento);

      this.quantidadeParcelasOriginal = this.visita.quantidadeParcelas;
      this.periodicidadeoriginal = this.visita.periodicidadeParcela;
    }
  }

  getTipoPagamento(visita: Visita): number {
    if (visita.pagamentoBoleto) {
      return 0;
    } else if (visita.pagamentoCartao) {
      return 1;
    } else {
      return 2;
    }
  }

  setTipoPagamento(flag: number): void {
    if (flag === 0) {
      this.formData.pagamentoBoleto = true;
      this.formData.pagamentoCartao = false;
      this.formData.pagamentoDinheiro = false;
      this.formData.pagamentoPix = false;
    }
    if (flag === 1) {
      this.formData.pagamentoBoleto = false;
      this.formData.pagamentoCartao = true;
      this.formData.pagamentoDinheiro = false;
      this.formData.pagamentoPix = false;
    }
    if (flag === 2) {
      this.formData.pagamentoBoleto = false;
      this.formData.pagamentoCartao = false;
      this.formData.pagamentoDinheiro = true;
      this.formData.pagamentoPix = false;
    }
    if (flag === 3) {
      this.formData.pagamentoBoleto = false;
      this.formData.pagamentoCartao = false;
      this.formData.pagamentoDinheiro = false;
      this.formData.pagamentoPix = true;
    }
  }

  pagamentoChange(value: number) {
    if (value === 0) {
      this.formData.quantidadeParcelas = 2;
      this.formData.periodicidadeParcela = 'SEMANAL';
    } else if (value === 3) {
      this.formData.quantidadeParcelas = 10;
      this.formData.periodicidadeParcela = 'DIARIA';
    } else {
      this.formData.quantidadeParcelas = this.quantidadeParcelasOriginal;
      this.formData.periodicidadeParcela = this.periodicidadeoriginal;
    }
  }

  mounthVisita(): void {
    this.setTipoPagamento(this.tipoPagamento);
    this.formData.parecerVisita = this.parecerAprovacao;
    this.formData.valorAprovado = this.valorAprovado;
    this.formData.limiteCreditoAprovado = this.limiteCreditoAprovado;
    this.formData.listArquivoVisita = null;
  }

  fechar(): void {
    this.display = false;
    this.parecerAprovacao = '';
    this.valorAprovado = null;
    this.limiteCreditoAprovado = null;
    this.visita = new Visita();
    this.tipoPagamento = this.getTipoPagamento(this.visita);
    this.setDetailsFalse.emit(false);
  }

  aprovar(): void {
    this.appState.isLoading = true;
    this.mounthVisita();
    this.visitaService.aprovarCredito(this.formData).subscribe((response: ReturnAPI<Visita>) => {
      if (response.success) {
        this.appState.isLoading = false;
        this.fechar();
        this.savedEvent.emit(true);
      } else {
        this.appState.isLoading = false;
        this.fechar();
        this.savedEvent.emit(false);
      }
    });
  }

  rejeitar(visitaId: number): void {
    this.rejeitarVisita.emit(visitaId);
  }
}
