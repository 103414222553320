import { Component, OnInit } from '@angular/core';
import { CaixaService } from '../../../services/caixa.service';
import { QueryFilter } from '../../motivo-contrato-cancelado/motivo-contrato-cancelado-main/motivo-contrato-cancelado-main.component';

@Component({
  selector: 'app-monitoramento-repasses-main',
  templateUrl: './monitoramento-repasses-main.component.html',
  styleUrls: ['./monitoramento-repasses-main.component.css'],
})
export class MonitoramentoRepassesMainComponent implements OnInit {

  private readonly defaultQueryFilter: QueryFilter = {
    dataFinal: null,
    dataInicial: null,
    posto: null,
    funcionario: null
  };

  monitoramento: any[];
  filterOptions: QueryFilter = this.defaultQueryFilter;

  somatorioRepassado = 0;
  qtd = 0;
  somatorioNaoRepassado = 0;

  constructor(
    private caixaService: CaixaService,
  ) {
  }

  ngOnInit(): void {

  }

  reloadPage(): void {
    if (this.filterOptions.posto.id != null) {
      this.caixaService.monitoramentoDeRepasses(this.filterOptions.posto.id).subscribe(data => {
        this.monitoramento = data.object;

        this.somatorioRepassado = 0;
        this.somatorioRepassado =  data.object.map(m => m.valorRepassados).reduce((soma, valor) => soma + valor, 0  );

        this.somatorioNaoRepassado = 0;
        this.somatorioNaoRepassado =  data.object.map(m => m.valorNaoRepassados).reduce((soma, valor) => soma + valor, 0  );

        this.qtd = 0;
        this.qtd = data.object.length;
      });
    }
  }

}
