import { Resource } from 'src/app/global/domain/resource.model';
import { AreaAtuacao } from './area-atuacao.model';
import { Equipe } from './equipe.model';
import { Funcionario } from './funcionario.model';

export class Rota extends Resource {
  descricao: string;
  turno: string;
  equipe: Equipe;
  cobrador: Funcionario;
  listAreaAtuacao: AreaAtuacao[];
}
