import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface CurrencyFormData {
  title?: string;
  message?: string;
  value?: number;
  comArquivo?: boolean;
  arquivoDescricao?: boolean;
}

export type CurrencyFormReturn = [number, File];

@Component({
  selector: 'app-currency-form',
  templateUrl: './currency-form.component.html',
  styleUrls: ['./currency-form.component.css'],
})
export class CurrencyFormComponent {
  value: number;
  fileToUpload: File = null;

  constructor(
    public dialogRef: MatDialogRef<CurrencyFormComponent, CurrencyFormReturn>,
    @Inject(MAT_DIALOG_DATA) public data: CurrencyFormData,
  ) {
    this.value = data.value || 0;
  }

  private handleFileInput(files: FileList): void {
    this.fileToUpload = files.item(0);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close([this.value, this.fileToUpload]);
  }
}
