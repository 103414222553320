import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PaginatorModule, TableModule } from 'primeng';
import { GlobalModule } from 'src/app/global/global.module';
import { ComponentsModule } from './../../global/components/components.module';
import { ConciliacaoBancariaService } from './../services/conciliacao-bancaria.service';
import { ConciliacaoMainComponent } from './conciliacao-main/conciliacao-main.component';
import { VisualizarConciliacaoBancariaComponent } from './visualizar-conciliacao-bancaria/visualizar-conciliacao-bancaria.component';
import { ConciliacaoDocComponent } from './conciliacao-doc/conciliacao-doc.component';
import { ConciliacaoTevComponent } from './conciliacao-tev/conciliacao-tev.component';
import { ConciliacaoBancariaTevService } from '../services/conciliacao-bancaria-tev.service';
import { ContaTevComponent } from './conta-tev/conta-tev.component';
import { ContaTevService } from '../services/conta-tev.service';
import { ConciliacaoBancariaDocService } from '../services/conciliacao-bancaria-doc.service';
import { ConciliacaoTedComponent } from './conciliacao-ted/conciliacao-ted.component';
import { ConciliacaoBancariaTedService } from '../services/conciliacao-bancaria-ted.service';
import { DashboardConciliacaoComponent } from './dashboard-conciliacao/dashboard-conciliacao.component';
import { ConciliacaoDinheiroComponent } from './conciliacao-dinheiro/conciliacao-dinheiro.component';
import { ConciliacaoBancariaDinheiro } from '../domain/conciliacao-bancaria-dinheiro.model';
import { ConciliacaoBancariaDinheiroService } from '../services/conciliacao-bancaria-dinheiro.service';

@NgModule({
  declarations: [
    ConciliacaoMainComponent,
    VisualizarConciliacaoBancariaComponent,
    ConciliacaoDocComponent,
    ConciliacaoTevComponent,
    ContaTevComponent,
    ConciliacaoTedComponent,
    DashboardConciliacaoComponent,
    ConciliacaoDinheiroComponent,
  ],
  imports: [CommonModule, GlobalModule, TableModule, PaginatorModule, ComponentsModule],
  providers: [
    ConciliacaoBancariaService,
    ConciliacaoBancariaDocService,
    ConciliacaoBancariaTevService,
    ConciliacaoBancariaTedService,
    ConciliacaoBancariaDinheiroService,
    ContaTevService,
  ],
})
export class ConciliacaoModule {}
