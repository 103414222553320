import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalModule } from 'src/app/global/global.module';
import { ContratosRenegociarMainComponent } from './contratos-renegociar-main/contratos-renegociar-main.component';

@NgModule({
  declarations: [ContratosRenegociarMainComponent],
  imports: [CommonModule, GlobalModule],
})
export class ContratosRenegociarModule {}
